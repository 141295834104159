import {ServiceCaseStatus} from '../store/cases/types';
import {request} from './request';

export default async function getImageUrlBase64AsFile(
  imageUrl: string | undefined,
  fileName: string
): Promise<File> {
  if (
    imageUrl === null ||
    imageUrl === undefined ||
    fileName === null ||
    fileName === undefined
  ) {
    return new File([new Blob()], '', {type: 'image/png'});
  }

  const isDev = process.env.REACT_APP_IS_DEVELOPMENT;
  if (isDev !== undefined) {
    if (isDev === 'true') {
      const port = process.env.REACT_APP_PORT;
      const res: Response = await fetch(
        imageUrl.replace('8282', port !== undefined ? port : '8282')
      );
      const blob: Blob = await res.blob();
      return new File([blob], fileName, {type: 'image/png'});
    }
  }
  const res: Response = await fetch(imageUrl);
  const blob: Blob = await res.blob();
  return new File([blob], fileName, {type: 'image/png'});
}
