import {DeepPartial} from 'redux';

import {CompanyInput, CustomerInput} from '../store/companies/types';

import {request, generateEndpoint} from '../lib/request';

import {ENDPOINTS} from '../endpoints';

import {ListMeta} from './types';

const methods = {

  createCustomer(payload: DeepPartial<CustomerInput>) {
    return request({
      endpoint: ENDPOINTS.ACCOUNT_MANAGEMENT_CREATE_CUSTOMER,
      method: 'POST',
      payload,
    });
  },

  updateCompany(companyId: string, payload: DeepPartial<CompanyInput>) {
    return request({
      endpoint: ENDPOINTS.ACCOUNT_MANAGEMENT_UPDATE_COMPANY,
      method: 'PUT',
      params: {companyId},
      payload,
    });
  },

  updateCustomer(customerId: string, payload: DeepPartial<CustomerInput>) {
    return request({
      endpoint: ENDPOINTS.ACCOUNT_MANAGEMENT_UPDATE_CUSTOMER,
      method: 'PUT',
      params: {customerId},
      payload,
    });
  },

  getCompany(companyId: string) {
    return request({
      endpoint: ENDPOINTS.ACCOUNT_MANAGEMENT_READ_SPECIFIC_COMPANY,
      method: 'GET',
      params: {companyId},
    });
  },

  getCustomer(customerId: string) {
    return request({
      endpoint: ENDPOINTS.ACCOUNT_MANAGEMENT_READ_SPECIFIC_CUSTOMER,
      method: 'GET',
      params: {customerId},
    });
  },

  changeCustomerStatus(customerId: string) {
    return request({
      endpoint: '/mock/companies/:customerId',
      method: 'PATCH',
      params: {customerId},
    });
  },

  inactivateCompany(id: string) {
    return request({
      endpoint: '/mock/companies/:id',
      method: 'PATCH',
      params: {id},
    });
  },

  getCompanies({page, filter, textSearch}: ListMeta) {
    return request({
      endpoint: ENDPOINTS.ACCOUNT_MANAGEMENT_READ_COMPANIES,
      method: 'GET',
      query: {
        _page: page,
        _limit: 10,
        ...(filter ? {status: filter} : null),
        ...(textSearch ? {q: textSearch} : null),
      },
    });
  },

  getCustomers(filter: string | null | undefined) {
    return request({
      endpoint: ENDPOINTS.ACCOUNT_MANAGEMENT_READ_CUSTOMERS + `${filter ? "?filter=" + filter.toLowerCase() : ""}`,
      method: 'GET'
    });
  },


  deleteCompanyFile(companyId: string, fileId: string) {
    return request({
      endpoint: ENDPOINTS.ACCOUNT_MANAGEMENT_CUSTOMER_DELETE_FILE,
      method: 'DELETE',
      params: {companyId, fileId},
    });
  },

  generateCompanyFileDownloadLink(companyId: string, fileId: string) {
    return generateEndpoint(ENDPOINTS.ACCOUNT_MANAGEMENT_CUSTOMER_REQUEST_FILE, {
      companyId,
      fileId,
    });
  },

  getCompanyUsers(companyId: string, {page, filter, textSearch}: ListMeta) {
    return request({
      endpoint: ENDPOINTS.ACCOUNT_MANAGEMENT_READ_CUSTOMER_USERS,
      method: 'GET',
      params: {companyId}
    });
  },

  getCompanyProjects() {
    return request({
      endpoint: ENDPOINTS.ACCOUNT_MANAGEMENT_READ_PROJECTS,
      method: 'GET',
    });
  },

  getCompanyOrderLimits() {
    return request({
      endpoint: ENDPOINTS.ACCOUNT_MANAGEMENT_READ_ORDER_LIMITS,
      method: 'GET',
    });
  },

  getCompanySalesAccounts() {
    return request({
      endpoint: ENDPOINTS.ACCOUNT_MANAGEMENT_READ_PREDEFINED_ACCOUNTS,
      method: 'GET',
    });
  },

  getCompanyCostCenters() {
    return request({
      endpoint: ENDPOINTS.ACCOUNT_MANAGEMENT_READ_COST_CENTERS,
      method: 'GET',
    });
  },

  getCompanyPriceList() {
    return request({
      endpoint: ENDPOINTS.ACCOUNT_MANAGEMENT_READ_PRICE_LIST,
      method: 'GET',
    });
  },

  getCompanyCurrencies() {
    return request({
      endpoint: ENDPOINTS.ACCOUNT_MANAGEMENT_READ_CURRENCY,
      method: 'GET',
    });
  },

  signDocument(userId:string,fileId:string) {
    return request({
      endpoint: ENDPOINTS.ACCOUNT_MANAGEMENT_SIGN_AGREEMENT,
      method: 'GET',
      params: {userId,fileId}
    });
  },

  getTermsOfDeliveries(){
    return request({
      endpoint: ENDPOINTS.ACCOUNT_MANAGEMENT_TERMS_OF_DELIVERIES,
      method: 'GET',
    })
  },

  getTermsOfPayment(){
    return request({
      endpoint: ENDPOINTS.ACCOUNT_MANAGEMENT_TERMS_OF_PAYMENTS,
      method: 'GET',
    })
  },

  getWayOfDeliveries(){
    return request({
      endpoint: ENDPOINTS.ACCOUNT_MANAGEMENT_WAY_OF_DELIVERIES,
      method: 'GET',
    })
  }
};

export default methods
