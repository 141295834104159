import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

import {List} from '../../store/types';
import {Customer} from '../../store/companies/types';

import Table from '../Table';
import Pager from '../Pager';
import Modal from '../Modal';

export default function CompanySelectModal({
  companies,
  page,
  modalVisible,
  onPagination,
  onSelect,
  setModalVisible,
}: {
  companies: List<Customer>;
  page: number;
  modalVisible: boolean;
  onPagination: (p: number) => void;
  onSelect: (company: Customer) => React.MouseEventHandler;
  setModalVisible: (f: boolean) => void;
}) {
  const {t} = useTranslation();
    const [searchStr, setSearchStr] = useState('');
    const onSearchChange = (e:any)=> {
        setSearchStr(e.target.value);
    }

  const {data, loading} = companies;

    const filteredItems = data.filter((data)=>{
        if(!searchStr)
            return data
        else if(data.Customer?.Name.toLowerCase().includes(searchStr.toLowerCase())
            || data.Customer?.Email.toLowerCase().includes(searchStr.toLowerCase())
        ){
            return data
        }
    })

  return (
    <Modal
      name="companies-list"
      modalVisible={modalVisible}
      setModalVisible={setModalVisible}
      title={t('common.Associate user with company')}
    >
      <Table
        loading={loading}
        useLoader={true}
        title={t('common.Select company')}
        searchField={{
          name: 'userSearch',
          text: t('common.Search company'),
          onChange: onSearchChange,
          value: searchStr
        }}
        header={[
          t('common.Name'),
          t('common.Organization number'),
          t('common.Phone number'),
          t('common.E-mail address'),
          {
            key: 'pager',
            component: (
              <Pager
                className="float-right"
                page={page}
                totalItems={companies.data.length}
                onPagination={onPagination}
                limit={companies.data.length}
              />
            ),
          },
        ]}
        row={(row) => (
          <>
            <td>{row.Customer.Name}</td>
            <td>{row.Customer.OrganisationNumber}</td>
            <td>{row.Customer.Phone}</td>
            <td>{row.Customer.Email}</td>
            <td className="text-right">
              <span
                className="text-primary cursor-pointer"
                onClick={onSelect(row)}
              >
                {t('common.Associate')}
              </span>
            </td>
          </>
        )}
        list={filteredItems}
        extractKey={(el) => `${el.InternalCustomer.id}`}
      />
    </Modal>
  );
}
