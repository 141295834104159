import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Pager from '../../components/Pager'
import Table from '../../components/Table'
import TabNav from '../../components/TabNav'
import Debounce from '../../lib/Debounce'
import { useQuery } from '../../lib/hooks'
import { ErrorResponseResolver } from '../../lib/responseHelper'
import { formatTimeFromDate } from '../../lib/timeHelper'
import { PATH_CASES_BACK_OFFICE_CREATE } from '../../router/paths'
import { AppState } from '../../store'
import { deleteServiceCase, getAllCases, getAllCustomerRelatedCases, getAllUserRelatedCases } from '../../store/cases'
import { ServiceCaseCategory, ServiceCaseStatus } from '../../store/cases/types'
import { toggleToast } from '../../store/components'
import { getActiveUser } from '../../store/system'
import { JocularUser, UserType } from '../../store/users/types'
import CaseManagementDeleteCaseModal from './CaseManagementDeleteCaseModal'
import CaseManagementListRowDropDown from './CaseManagementListRow'

const connector = connect(
	(state: AppState) => ({
		cases: state.cases.list,
		activeUser: state.system.sessionUser,
		userType: state.system.sessionUserType
	}),
	{ getAllCases, deleteServiceCase, toggleToast, getActiveUser, getAllCustomerRelatedCases, getAllUserRelatedCases }
);

type CaseManagementProps = ConnectedProps<typeof connector> & {};

export function CaseManagement({
	cases,
	activeUser,
	userType,
	getAllCases,
	deleteServiceCase,
	toggleToast,
	getActiveUser,
	getAllCustomerRelatedCases,
	getAllUserRelatedCases,
}: CaseManagementProps) {
	const { t } = useTranslation();
	const history = useHistory();
	const query = useQuery()
	const paramFilter = query.get('filter') ?? undefined
	const paramSearch = query.get('search') ?? undefined

	const handleSearch = (v: string) => {
		query.set('search', v)
		history.push(`?${query}`)
	}
	const [user, setUser] = useState<JocularUser>();

	useEffect(() => {
		getActiveUser()
		.then(u => setUser(u.payload as JocularUser))
	}, [getActiveUser])

	const [deleteModalVisible, setDeleteModalVisible] = useState(false)
	const [pickedServiceCaseToDelete, setPickedServiceCaseToDelete] = useState('')

	useEffect(() => {
		if(user == undefined) return
		[ UserType.Admin, UserType.SuperAdmin ].includes(user.sessionUserType)
			? getAllCases()
			: getAllCustomerRelatedCases()
	}, [user, getAllCases, getAllCustomerRelatedCases, getAllUserRelatedCases])

	const createNewCase = () => history.push(PATH_CASES_BACK_OFFICE_CREATE)

	const deleteConfirm = (async () => {
		await deleteServiceCase({
			serviceCaseId: pickedServiceCaseToDelete,
			callback: (errorResponseResolver: ErrorResponseResolver) => {
				toggleToast({
					shouldToggle: true,
					message: errorResponseResolver.message,
					status: errorResponseResolver.status
				})
			}
		})
		setDeleteModalVisible(false)
		setPickedServiceCaseToDelete("")
		getAllCases()
	});
	
	const getStatus = (status: ServiceCaseStatus) =>
		status === ServiceCaseStatus.ONCOING ? "Pågående"
		: status === ServiceCaseStatus.NEW ? "Ny"
		: status === ServiceCaseStatus.COMPLETED ? "Avslutad"
		: status === ServiceCaseStatus.FEEDBACK_REQUIRED ? "Återkoppling krävs"
		: "Okänd"
	

	const getCategory = (category: ServiceCaseCategory) =>
		category === ServiceCaseCategory.SERVICE_AND_SUPPORT ? "Service och Support"
		: category === ServiceCaseCategory.ORDER ? "Order"
		: category === ServiceCaseCategory.INVOICE ? "Faktura"
		: category === ServiceCaseCategory.KUNDPLATTFORM ? "Kundplattformen"
		: "Övrigt"

	const navLinks: {
		isActive: boolean
		path: string
		component: JSX.Element
	}[] = [
		ServiceCaseStatus.NEW,
		ServiceCaseStatus.ONCOING,
		ServiceCaseStatus.COMPLETED,
		ServiceCaseStatus.FEEDBACK_REQUIRED
	].map(s => ({
		isActive: paramFilter === s,
		path: `?filter=${s}&search=${paramSearch?.replace(":",'')}`,
		component: (
			<>
				{getStatus(s)}
				<span className="badge badge-info">
					{cases.data.map(c => c.serviceCaseStatus).filter(status => status.includes(s)).length ?? 0} st
				</span>
			</>
		),
	}))
	navLinks.unshift({
		isActive: !paramFilter,
		path: `?filter=&search=${paramSearch?.replace(":",'')}`,
		component: (
			<>
				{t('common.All')}
				<span className="badge badge-info">
					{cases.data.length} st
				</span>
			</>
		),
	})

	const filteredCases = (paramSearch ? cases.data.filter(c => 
		[
			c.id, c.caseSubject, getCategory(c.serviceCaseCategory), c.caseDescription, 
			c.caseOwnerResolved, formatTimeFromDate(c.createdAt)
		].some(v => (v+'').toLowerCase().includes(paramSearch.toLowerCase()))
	) : cases.data).filter(c => paramFilter ? c.serviceCaseStatus === paramFilter : true)
	

	return (
		<>
			<h1 data-testid="manage_companies_headline">
				Ärendehantering
			</h1>
			<TabNav links={navLinks} />
			<Table
				newButton={{
					onClick: createNewCase,
					text: 'Skapa nytt ärende',
				}}
				loading={cases.loading}
				title=""
				searchField={{
					name: 'search',
					text: 'Sök ärende',
					onChange: (e: any) => handleSearch(e.target.value),
					value: paramSearch,
					withInternalState: true
				}}
				header={[
					'Nummer',
					'Prioritet',
					'Ärende',
					'Kategori',
					'Beskrivning',
					'Tilldelad',
					'Skapad',
					'Status',
					'',
				]}
				row={(row) => (
					<>
						<td>{row.id}</td>
						<td>{row.casePriority}</td>
						<td>{row.caseSubject}</td>
						<td>{getCategory(row.serviceCaseCategory)}</td>
						<td>{row.caseDescription}</td>
						<td>{row.caseOwnerResolved}</td>
						<td>{formatTimeFromDate(row.createdAt)}</td>
						<td>{getStatus(row.serviceCaseStatus)}</td>
						<td className="text-center">
							<CaseManagementListRowDropDown
								row={row}
								setDeleteModalVisible={setDeleteModalVisible}
								setPickedServiceCaseToDelete={setPickedServiceCaseToDelete}
								user={user}
							/>
						</td>
					</>
				)}
				list={filteredCases}
				extractKey={el => el.id}
			/>
			<CaseManagementDeleteCaseModal
				modalVisible={deleteModalVisible}
				onConfirmation={deleteConfirm}
				setModalVisible={setDeleteModalVisible}
			/>
		</>
	);
}

export default connector(CaseManagement)

const debounce = Debounce()
