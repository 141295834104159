import { useTranslation } from "react-i18next"
import formatTime from "../../lib/timeHelper"
import { Order } from "../../store/orders/types"
import { EntityData } from "../../store/types"


export interface CustomerCardProps {
    userAdminType: boolean
    data: EntityData<Order>
    readOnly: boolean
} 

const CustomerCard: React.FC<CustomerCardProps> = ({
    userAdminType,
    data,
    readOnly
}) => {
    const { t } = useTranslation()

    return (
        <>
            <div className="col-md-12 col-sm-12">
                <div className="row">
                    <div className="col-12 mb-2" hidden={!readOnly && !userAdminType}>
                        <div className="card">
                            <div className='card-header' data-toggle="collapse" data-target="#products">
                                {t('order.Customer')}
                                <i className="float-right arrow down"></i>
                                <i className="float-right arrow up"></i>
                            </div>
                            <div className="collapse show" id="products">
                                <div className='card-body'>
                                    <div className="row">
                                        <div className="col-4">
                                            <div className='col-12'>
                                                <label>
                                                    {t('order.Customernumber')}
                                                </label>
                                                <p>
                                                    {data?.shopOrderViewResolver.customerNumber}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className='col-12'>
                                                <label>
                                                    {t('order.Company')}
                                                </label>
                                                <p>
                                                    {data?.shopOrderViewResolver.customerReference}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <label>
                                                        {t('order.Orderdate')}
                                                    </label>
                                                    <p>
                                                        {data?.orderCreated ? formatTime(data.orderCreated) : undefined}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CustomerCard