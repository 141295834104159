import React, {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import classnames from 'classnames';

import {UploadedFile, UploadedFilesDictionary} from '../../types';

import {useTranslation} from 'react-i18next';
import {FormContextValues} from 'react-hook-form';

import {extractErrorText, getErrorTestId, getErrorObj} from '../../lib/form';

import {InputDefaultProps} from './types';
import InputField from './InputField';
import {formatDate} from '../../lib/format';

interface FileInputFieldProps<T> extends InputDefaultProps {
  handler: FormContextValues<T>;
  fileSelected: boolean;
  uploadedFile: UploadedFile | null;
  metaInfo?: string | null;
  placeholder?: string;
  onView: (f: UploadedFile) => void;
  onDelete: (f: UploadedFile | null) => void;
}

export function FileInputField<T>({
  name,
  handler,
  rules,
  metaInfo,
  label,
  placeholder,
  uploadedFile,
  fileSelected,
  onView,
  onDelete,
}: FileInputFieldProps<T>) {
  const {t} = useTranslation();
  const id = `file__eon-ui`;

  const elementError = getErrorObj(handler.errors, name);
  const errorText = extractErrorText(elementError, rules, t);

  const currentValue = handler.watch(name) as FileList | undefined;

  const filled =
    uploadedFile || // file existing in the db
    (currentValue &&
      currentValue instanceof FileList &&
      currentValue.length > 0);

  const display = filled
    ? uploadedFile
      ? uploadedFile.originalName
      : currentValue
      ? currentValue[0].name
      : ''
    : placeholder ?? label ?? '';

  const fileCustomElem = (
    <span
      className={classnames('file-custom', {
        border: elementError,
        'border-danger': elementError,
      })}
    >
      {display}
      {metaInfo ? <span className="file-meta">{metaInfo}</span> : null}
    </span>
  );

  return (
    <div className="form-group col mb-0">
      {label === undefined ? null : (
        <label htmlFor={uploadedFile ? `com.eon.ui:eon-ui:war:1.0-SNAPSHOT_noop` : id}>{label}</label>
      )}

      <div>
        <div className="input-group">
          {uploadedFile ? (
            <span className="file cursor-initial file__selected">
              {fileCustomElem}
            </span>
          ) : (
            <label
              className={classnames('file', {
                file__selected: filled,
                'cursor-initial': fileSelected,
              })}
              htmlFor={fileSelected ? `com.eon.ui:eon-ui:war:1.0-SNAPSHOT_noop` : id}
            >
              <input
                name={name}
                type="file"
                id={id}
                data-testid={id}
                aria-label="File browser"
                ref={rules ? handler.register(rules) : handler.register}
              />
              {fileCustomElem}
            </label>
          )}
          {filled && (
            <div className="input-group-append">
              <span className="input-group-text bg-white">
                <div className="dropdown d-inline-block">
                  <button
                    className="dropdown-text-button dropdown-toggle"
                    type="button"
                    id={`com.eon.ui:eon-ui:war:1.0-SNAPSHOT__dropdown`}
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {t('common.Options')}
                  </button>
                  <div
                    className="dropdown-menu"
                    aria-labelledby={`com.eon.ui:eon-ui:war:1.0-SNAPSHOT__dropdown`}
                  >
                    {uploadedFile && (
                      <span
                        className="dropdown-item"
                        onClick={() => uploadedFile && onView(uploadedFile)}
                      >
                        {t('common.View document')}
                      </span>
                    )}
                    <span
                      className="dropdown-item text-danger"
                      onClick={() => onDelete(uploadedFile)}
                    >
                      {t('common.Delete')}
                    </span>
                  </div>
                </div>
              </span>
            </div>
          )}
        </div>
      </div>

      {elementError ? (
        <span
          className="form-control-error text-danger"
          data-testid={getErrorTestId(elementError)}
        >
          {errorText}
        </span>
      ) : null}
    </div>
  );
}

type FileInput = FileList | undefined;

export function UploaderField({
  id,
  file,
  placeholder,
  fieldClassNames,
  onFileSelect,
  onDelete,
}: {
  id: string;
  file: UploadedFile | null;
  placeholder: string;
  fieldClassNames?: string;
  onFileSelect: (id: string, fileList: undefined | FileList) => void;
  onDelete: (id: string, file: UploadedFile | null) => void;
}) {
  const fileFieldId = `file_com.eon.ui:eon-ui:war:1.0-SNAPSHOT`;
  const fileNameFieldId = `file_name_com.eon.ui:eon-ui:war:1.0-SNAPSHOT`;

  const handleInput = useForm<{[k: string]: FileInput}>({
    mode: 'onChange',
  });

  const watchedFiles = handleInput.watch(fileFieldId);
  /**
   * hack for FF bug
   * useEffect can't detect the changes due to some weird FF optimizations
   */
  const numberOfFiles = watchedFiles?.length;
  const firstFile = watchedFiles?.[0];
  useEffect(() => {
    onFileSelect(id, watchedFiles);
  }, [watchedFiles, firstFile, numberOfFiles, id, onFileSelect]);
  
  const fileSelected = firstFile !== undefined;
  return (
    <div className={classnames('form-row', fieldClassNames)}>
      <FileInputField
        name={fileFieldId}
        handler={handleInput}
        uploadedFile={file}
        placeholder={placeholder}
        metaInfo={file ? formatDate(file.uploadedAt) : undefined}
        fileSelected={fileSelected}
        onView={(f) => {
          // TODO
          // if (f.filePath) {
          //   window.open(f.filePath, '_blank')?.focus();
          // }
        }}
        onDelete={(file) => onDelete(id, file)}
      />

      {/*<InputField name={fileNameFieldId} className="col-4 mb-0" />*/}
    </div>
  );
}

type MultipleFileInputState = {
  files: UploadedFilesDictionary;
 // setAttachments: (test: String) => string;
};

type MultipleFileInputProps = {
  files: UploadedFilesDictionary;
  setAttachments: (fileList: FileList, id: string) => void;
  deleteAttachments: (id: string) => void;
  placeholder: string;
  label: string;
  fieldClassNames?: string;
};

let id = 0;
const nextId = () => {
  return `tmp-id-${id++}`;
};

export default class MultipleFileInput extends React.Component<
  MultipleFileInputProps,
  MultipleFileInputState
> {
  state: MultipleFileInputState = {
    files: this.props.files,
  }

  setUploadedFile = (id: string, data: any) => {
    this.setState((state) => ({
      ...state,
      files: {
        ...state.files,
        [id]: data as UploadedFile,
      },
    }));
  };

  onFileSelect = (id: string, fileList: FileInput) => {
    if (!fileList || fileList.length === 0) {
      return;
    }

    this.props.setAttachments(fileList, id)
  };

  removeField = (id: string) => {
    this.setState((state) => {
      const copy = {...state.files};
      delete copy[id];

      return {
        ...state,
        files: copy,
      };
    });
  };

  onDelete = async (id: string, file: UploadedFile | null) => {
    this.removeField(id);
    this.props.deleteAttachments(id)
  };

  render() {
    const {files} = this.state;
    const {fieldClassNames, placeholder, label} = this.props;
    const {onFileSelect, onDelete} = this;
    const entries = Object.entries({
        ...files,
        [`${nextId()}`]: null,
      });  
    
    return (
      <>
        {label && <div>
          <label>{label}</label>
        </div>}

        {entries.map(([fileId, file], index) => {
          return (
            <UploaderField
              key={fileId}
              id={fileId}
              file={file}
              placeholder={placeholder}
              onFileSelect={onFileSelect}
              onDelete={onDelete}
              fieldClassNames={classnames(fieldClassNames, {
                'mb-2': index < entries.length - 1,
                'mb-4': index === entries.length - 1,
              })}
            />
          );
        })}
      </>
    );
  }
}
