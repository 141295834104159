import React, { useContext, useEffect, useState } from 'react'

import Card from '@material-ui/core/Card/Card'
import Box from '@material-ui/core/Box/Box'
import CardMedia from '@material-ui/core/CardMedia/CardMedia'
import CardContent from '@material-ui/core/CardContent/CardContent'
import Typography from '@material-ui/core/Typography/Typography'
import Grid from '@material-ui/core/Grid/Grid'
import CardActions from '@material-ui/core/CardActions/CardActions'
import Skeleton from '@material-ui/lab/Skeleton/Skeleton'
import CardHeader from '@material-ui/core/CardHeader/CardHeader'
import IconButton from '@material-ui/core/IconButton/IconButton'
import { AddShoppingCart, Close } from '@material-ui/icons'

import { MonitorProduct, ProductInformationDocument } from '../../../store/products/types'
import { useTranslation } from 'react-i18next'
import { contextEShopProduct } from '../Providers/EShopProductProvider'
import { contextEShopCategory } from '../Providers/EShopCategoryProvider'
import { contextEShopCart } from '../Providers/EShopCartProvider'
import StockTextWithCircle from './StockTextWithCircle'
import PriceWithDiscount from './PriceWithDiscount'
import NoneStockProductText from './NoneStockProductText'
import NumberInput from '../../NumberInput/NumberInput'
import { User, UserType } from '../../../store/users/types'
import { Alert } from '@material-ui/lab'

const ProductCardFullPage = () => {
	const { productInformation, activeUser, company, getProductFile, setMonitorProduct } = useContext(contextEShopProduct)
	const { gotoProduct } = useContext(contextEShopCategory)
	const { cart } = useContext(contextEShopCart)
	const [emailToMonitor, setEmailToMonitor] = useState<string>("");
	const setQuantityOfProductInCartWithId = cart?.productMethods.setQuantity
	const [numberToBuy, setNumberToBuy] = useState(0)
	const { t } = useTranslation()
	const [emailFeedback, setEmailFeedback] = useState<boolean>(false);

	useEffect(() => { setNumberToBuy(0) }, [productInformation])
	useEffect(() => { setEmailToMonitor(""); setEmailFeedback(false) }, [])
	const [ product, setProduct ] = useState(productInformation)

	useEffect(() => {
		productInformation && setProduct(productInformation)
	}, [productInformation])

	const inStock = product?.stockAmount !== undefined ? product?.stockAmount > 0 : false;
	let b2COrder = cart?.b2COrder !== undefined ? cart.b2COrder : false;

	const addMonitorToProduct = () => {
		if(emailToMonitor === undefined || emailToMonitor === ""){
			setEmailFeedback(true)
		}else{
			let data = {
				email: emailToMonitor,
				identity: product?.articleNumber,
				information: product?.title + " - " + product?.description,
				type: "PRODUCT",
				userId: activeUser?.id
			} as MonitorProduct
			
			if(setMonitorProduct)
				setMonitorProduct(data);

			setEmailFeedback(false)
		}
	}

  	return (
		<Box style={{ height: '100%', maxWidth: 'calc(400px + 80ch)', width: "100%", position: "relative" }}> 
			<Card elevation={0}>
				<Box position="absolute" right={0}>
					<CardHeader
						action={
							<IconButton aria-label="Close" size="small" onClick={() => gotoProduct?.(undefined)}>
								<Close />
							</IconButton>
						}
					/>
				</Box>

				<Box display="flex" height="100%" pr={4}>
					<CardMedia style={{ height: 400, minWidth: 400 }}>
						{ product?.image ? 
							<img alt="product" src={product.image} style={{ height: '100%' }} />
							: <Skeleton variant="rect" width="100%" height="100%" />
						}
					</CardMedia>

					<Box ml={2}>
						<Grid container justify="space-between" direction="column" style={{ height: '100%' }}>

							<CardContent>
								<Typography variant="caption" color="textSecondary">
									{ product?.articleNumber ? 
										<>{t("common.articleNr")}: {product.articleNumber}</>
										: <Skeleton width="100%" />
									}
								</Typography>
								<Typography variant="h5" color="textPrimary">
									{ product?.title ?? <Skeleton width="100%" /> }
								</Typography>
								{product?.manufacturer !== null && 
									<Typography variant="subtitle2" color="textSecondary">
										{ product?.manufacturer ?? <Skeleton width="100%" /> }
									</Typography>
								}
								<Box mt={2}>
									<Typography variant="body2" style={{ color: 'rgba(0,0,0, .80)'}}>
										{ product?.stockAmount !== undefined ? 
											<StockTextWithCircle 
												noneStockProduct={product?.noneStockProduct}
												stockAmount={product?.stockAmount}
												inDeliveryOfStock={product?.inDeliveryOfStock}
											/>
											: <Skeleton width="100%" />
										}
									</Typography>
								</Box>
							</CardContent>

							{ product?.noneStockProduct && !inStock &&
								<CardContent>
									<Typography variant="body1">
										<NoneStockProductText />
									</Typography>
								</CardContent>
							}
							
							{ !product?.noneStockProduct && !inStock &&
								<CardContent>
									<Typography variant="body1">
										<p>
											{t('Eshop.Fill the field with your email to watch product.')}
											
										</p>
										<small>{t('common.E-mail address')}</small>
										<input
											disabled={false}
											id='monitorEmail'
											className='form-control'
											onChange={(e: any) => setEmailToMonitor(e.target.value)}
										/>
										<small style={{color: "red"}} hidden={!emailFeedback}>
											{t('Eshop.You need to fill email.')}
										</small>
										<button
											className="btn btn-primary mt-1 btn-block w-50"
											onClick={ () => addMonitorToProduct() }
										>
											{t('Eshop.Watch')}
										</button>
									</Typography>
								</CardContent>
							}
							
							<CardContent>
								<Typography variant="h6" color="textPrimary" component="h6">
									<Grid container direction="column-reverse" spacing={0}>
										{ product?.price !== undefined ?
											<PriceWithDiscount 
												price={product.price}
												priceWithoutDiscount={product.priceWithoutDiscount}
												currency={product.currency}
												discount={product.priceGroupDiscount}
												discountProps={{ variant: "body2", color: "textSecondary", style: { height: 12 } }}
												priceProps={{ variant: "h6" }}
											/>
											: <Skeleton width={80} />
										}
									</Grid>
								</Typography>
							</CardContent>

							<CardActions>
								<Grid>
									<Box width="10%" minWidth={200}>
										<NumberInput 
											disabled={product?.noneStockProduct || !inStock}
											value={numberToBuy} 
											setValue={setNumberToBuy} 
											inputHeight={25.75} 
										/>
										{(!b2COrder && !!product?.maxOrderQuantity && numberToBuy > product?.maxOrderQuantity) &&
											<Alert className='mt-2 mb-2' severity="info" style={{fontSize: "0.75rem"}}>												
												{t('Eshop.You cant order more. Please contact your seller after you put your order', {maxOrderQuantity: product?.maxOrderQuantity})}
											</Alert>
										}

										{(!!product?.stockAmount && product?.stockAmount < numberToBuy) && 
											<Alert className='mt-2 mb-2' severity="info" style={{fontSize: "0.75rem"}}>
												{t('Eshop.You cant order more than.', {maxOrderQuantity: product?.stockAmount})}
											</Alert>
										}

										<button
											className="btn btn-primary btn-sm mt-1 btn-block"
											onClick={ 
												product ? () => {
													setQuantityOfProductInCartWithId?.(product?.id, numberToBuy)
													setNumberToBuy(0)
												} : undefined
											}
											disabled={product?.noneStockProduct || !inStock || (!!product?.stockAmount && product?.stockAmount < numberToBuy)}
										>
											<AddShoppingCart />{' '+t('common.Add')}
										</button>
									</Box>
									
								</Grid>
							</CardActions>

						</Grid>
					</Box>
				</Box>
				{ product && (!!product.documents?.length || product.description) &&
					<CardContent>
						<Box px={3} py={2}>
							<Grid container spacing={2}>
								{ product.description &&
									<Grid item xs={12}>
										<Box mb={1}>
											<Typography variant="h6">
												{t("common.Description")}
											</Typography>
										</Box>
										<Typography variant="body1" style={{ whiteSpace: 'pre-wrap' }}>
											{product.description}
										</Typography>
									</Grid>
								}
								{ !!product.documents?.length && 
									<Grid item xs={12}>
										<Box mb={1}>
											<Typography variant="h6">
												{t("common.Documents and files")}
											</Typography>
										</Box>
										<Typography variant="body1">
											<DocumentAndFile {...{documents: product.documents, getProductFile, activeUser}} />
										</Typography>
									</Grid>
								}
							</Grid>
						</Box>
					</CardContent>
				}
			</Card>
		</Box>
	)
}

const DocumentAndFile: React.FC<{ 
	documents?: ProductInformationDocument[], getProductFile?: any, activeUser?: User 
}> = ({ 
	documents, getProductFile, activeUser
}) => {
	const requestFile = (id: string) => {
		getProductFile({
			fileId: id,
			customerId: activeUser?.userPermission.belongsToCustomer ?? '',
		})
	}

	return (
		<>
			{ documents?.map((subItem, index) =>
				<div
					key={index}
					className="col-4 cursor-pointer text-center"
					onClick={() => requestFile(subItem.id)}
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="30"
						height="30"
						fill="currentColor"
						className="bi bi-archive"
						viewBox="0 0 16 16"
					>
					<path
						fillRule="evenodd"
						d="M0 2a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1v7.5a2.5 2.5 0 0 1-2.5 2.5h-9A2.5 2.5 0 0 1 1 12.5V5a1 1 0 0 1-1-1V2zm2 3v7.5A1.5 1.5 0 0 0 3.5 14h9a1.5 1.5 0 0 0 1.5-1.5V5H2zm13-3H1v2h14V2zM5 7.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z"
					/>
					</svg>
					<p>{subItem.originalName}</p>
				</div>
			)}
		</>
	)
}

export default ProductCardFullPage
