import { Remove } from "@material-ui/icons";
import { useState } from "react";
import { FormContextValues } from "react-hook-form";
import { CompanyLogo } from "../../store/offers/CompanyLogo";
import { FileInputField } from "./InstantMultipleFileUploader";
import { InputDefaultProps } from "./types";
import IconButton from "@material-ui/core/IconButton/IconButton"
import { Close } from "@material-ui/icons"
import { emptyString } from "react-select/src/utils";

interface FileInputFieldProps<T> extends InputDefaultProps {
    placeholder?: string;
    label?: string;
    handler: FormContextValues<T>;
    companyLogo?: CompanyLogo;
    onImageChangeValue: (f: boolean) => void;
    create?: boolean
}

export function ViewOrFileInput<T>({
    placeholder,
    label,
    handler,
    companyLogo,
    onImageChangeValue,
    create,
}: FileInputFieldProps<T>){
    const [imageChange, setimageChange] = useState<boolean>(false)
    
    return(
    <>
        <div>
        {!imageChange && !create && companyLogo?.publicLink !== undefined ? (
            <div className={"img-thumbnail"}>
                <IconButton 
                    style={{float: "right"}}
                    aria-label="remove"
                    title="remove"
                    size="small" 
                    onClick={() => (onImageChangeValue(true), setimageChange(true))}
                >
                    <Close className={"btn-outline-danger"} fontSize="small" />
                </IconButton>
               
                <div className={"text-center"}>
                    <img className={"img-fluid"} style={{maxHeight: "50%", maxWidth: "50%"}} src={companyLogo?.publicLink} alt={companyLogo?.originalName} />
                </div>
            </div>
        ): (
            <FileInputField 
                name="companyLogo"
                handler={handler}
                uploadedFile={null}
                uploading={false}
                onDelete={() => {}}
                onView={() => {}}
                placeholder={placeholder}
                label={label}
                readOnly={false}
                disabled={false}
            />
        )}
        </div>
    </>
    )
}


  