import {request} from '../lib/request';
import {ENDPOINTS} from '../endpoints';
import {Action} from '../types/actions/action';
import { AxiosPromise } from 'axios';

const methods = {
  getActionsNeeded(): AxiosPromise<Action[]> {
    return request({
      endpoint: ENDPOINTS.ACTION_NEEDED,
      method: 'GET'
    });
  }
}

export default methods;