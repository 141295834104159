import {DeepPartial} from 'redux';
import {generatePath} from 'react-router-dom';

import {NewsInput} from '../store/news/types';

import axiosInstance, {request} from '../lib/request';

import {ENDPOINTS} from '../endpoints';

import {ListMeta} from './types';

const methods = {
  getNewsList({page, filter, textSearch}: ListMeta) {
    return request({
      endpoint: ENDPOINTS.ACCOUNT_MANAGEMENT_READ_NEWS,
      method: 'GET',
      query: {
        _page: page,
        _limit: 10,
        ...(filter ? {status: filter} : null),
        ...(textSearch ? {q: textSearch} : null),
      },
    });
  },

  getNews(newsId: string) {
    return request({
      endpoint: ENDPOINTS.ACCOUNT_MANAGEMENT_READ_SPECIFIC_NEWS,
      method: 'GET',
      params: {newsId},
    });
  },

  createNews(payload: NewsInput) {
    const formData = new FormData();

    const { data: _, ...data } = payload;

    const newsBlob = new Blob([JSON.stringify(data)], {
      type: 'application/json',
    });

    formData.append('file', payload.data, 'image.jpg');
    formData.append('data', newsBlob);
    return axiosInstance.post(
      ENDPOINTS.ACCOUNT_MANAGEMENT_CREATE_NEWS,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  },

  updateNewsById(newsId: string, payload: DeepPartial<NewsInput>) {
    const formData = new FormData();
    const data = {...payload};
    delete data.data;

    const newsBlob = new Blob([JSON.stringify(data)], {
      type: 'application/json',
    });

    const {data: imageBlob} = payload;

    if (imageBlob instanceof Blob) {
      formData.append('file', imageBlob, 'image.jpg');
    }

    formData.append('data', newsBlob);

    return axiosInstance.put(
      generatePath(ENDPOINTS.ACCOUNT_MANAGEMENT_EDIT_NEWS, {
        newsId,
      }),
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  },

  deleteNews(newsId: string) {
    return request({
      endpoint: ENDPOINTS.ACCOUNT_MANAGEMENT_DELETE_NEWS,
      method: 'DELETE',
      params: {newsId},
    });
  },
};

export default methods
