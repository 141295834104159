import React, {useEffect, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {AppState} from '../../store';
import {getCustomer} from '../../store/companies';
import {
    getCompanyResellerAgreements,
} from '../../store/companies/selectors';
import {setShowLoader} from '../../store/system';

import MainAccountManageCompanyTabNav from '../../components/Company/MainAccountManageCompanyTabNav';
import {useHistory, useParams} from "react-router-dom";
import {formatDate} from '../../lib/format';
import companiesService from "../../services/companies";
import DropdownButton from "../../components/DropdownButton";
import SignAgreementsModal from "../../components/Company/SignAgreementsModal";
import { isCustomerAccount } from '../../lib/permissions';
import Alert from '@material-ui/lab/Alert/Alert';
import Box from '@material-ui/core/Box/Box';

const connector = connect(
    (state: AppState) => ({
		userType: state.system.sessionUserType,
        sessionUser: state.system.sessionUser,
        companyLoading: state.companies.selected.loading,
        companyResellerAgreements: getCompanyResellerAgreements(state),
    }),
    {setShowLoader, getCustomer}
);

type EditCompanyDocumentsProps = ConnectedProps<typeof connector> & {};


export function EditCompanyDocumentsMainAccount({
	userType,
	sessionUser,
	companyLoading,
	setShowLoader,
	getCustomer,
	companyResellerAgreements
}: EditCompanyDocumentsProps) {
    const {t} = useTranslation();
    const {companyId} = useParams<{companyId:string}>();
    const history = useHistory();
    const showLoader = companyLoading;

    const [signAgreementsModalVisible, setSignAgreementsModalVisible] = useState(false);
    const [selectedFile, setSelectedFile] = useState('');

    useEffect(() => {
        setShowLoader(showLoader);
    }, [showLoader, setShowLoader]);

    useEffect(() => {
        if(companyId){
            getCustomer(companyId);
        }
    }, [companyId, getCustomer]);

    const onView = (id: string) => () => {
        const generateCompanyFileDownloadLink = companiesService.generateCompanyFileDownloadLink.bind(
            null,
            companyId
        );
        window.open(generateCompanyFileDownloadLink(id), '_blank')?.focus();
    }

    const onSign = (id: string) => () => {
        setSignAgreementsModalVisible(true)
        setSelectedFile(id);
    }


    const signAgreement = async () =>{
        if(selectedFile && sessionUser){
            await companiesService.signDocument(sessionUser.id,selectedFile)
            setSignAgreementsModalVisible(false);
            history.go(0) // :)))))))))))
        }
    }

	const isCustomer = isCustomerAccount(userType)

    return (
        <div>
            <h1 data-testid="edit_company_main_account_documents_headline">
                {t('navigation.Customers__nav.Manage companies')}
            </h1>
            <MainAccountManageCompanyTabNav companyId={companyId}/>
            <legend>{t('common.Reseller agreement')}</legend>
            {
                Object.keys(companyResellerAgreements).map(id => {
                    const uploadedAt = companyResellerAgreements[id]?.uploadedAt
                    const signedDate = companyResellerAgreements[id]?.signedDate
                    const originalName = companyResellerAgreements[id]?.originalName
                    return (
                        <div className="alert alert-secondary bg-white p-3 col-6" role="alert" key={id}>
                            <span className="font-weight-bold">{originalName ? originalName : null}</span>
                            <div className="float-right">
                                {
                                    signedDate &&
                                    <span
                                        className="mr-3">{t('common.Signed')}: {formatDate(signedDate)}
                                    </span>
                                }
                                <span
                                    className="mr-3">{t('common.Date')}: {uploadedAt ? formatDate(uploadedAt) : null}
                                </span>
                                <DropdownButton
                                    id={`company__option__dropdown`}
                                    label={t('common.Options')}
                                    dropdownList={[
                                        {text: t('common.Download'), onClick: onView(id)},
                                        {text: t('common.Sign agreements'), onClick:onSign(id)}
                                    ]}
                                />
                            </div>
                        </div>
                    )
                })
            }

			<Alert severity="info" className="col-6">
				Obs. Avtal kan tecknas under alternativ
			</Alert>

            <SignAgreementsModal
                modalVisible={signAgreementsModalVisible}
                setModalVisible={setSignAgreementsModalVisible}
                onConfirm={signAgreement}
            />
        </div>
    );
}

export default connector(EditCompanyDocumentsMainAccount);
