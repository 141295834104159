import React from 'react';
import {useTranslation} from 'react-i18next';

import ConfirmationModal from '../Modal/ConfirmationModal';

export default function DeleteUserConfirmationModal(props: {
  modalVisible: boolean;
  setModalVisible: (f: boolean) => void;
  onConfirmation: () => void;
}) {
  const {t} = useTranslation();

  return (
    <ConfirmationModal
      title={t('common.Delete user')}
      message={t('common.Are you sure you want to delete the selected user?')}
      buttonMessage={t('common.Delete')}
      {...{
        ...props,
      }}
    />
  );
}
