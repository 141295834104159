// Source: https://stackoverflow.com/questions/1186414/whats-the-algorithm-to-calculate-aspect-ratio

function gcd(a: number, b: number): number {
  return b === 0 ? a : gcd(b, a % b);
}

/**
 *
 */
export default function calcAspectRatio(width: number, height: number): string {
  const r = gcd(width, height);

  return `${width / r}:${height / r}`;
}
