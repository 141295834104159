import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

import priceGroupsService, {PriceGroupRequest} from '../../services/priceGroup';
import {PriceGroup, PriceGroupState} from './types';

import {ListData} from '../types';
import {
  entityFulfilled,
  entityPending,
  entityRejected,
  listFulfilled,
  listPending,
  listRejected,
} from '../utils';

const name = 'priceGroups';

export const getPriceGroups = createAsyncThunk<ListData<PriceGroup>>(
  `eon-ui/getPriceGroups`,
  async function () {
    const {data, headers} = await priceGroupsService.getPriceGroups();

    return {
      data: data as PriceGroup[],
      total: headers['x-total-count'],
    };
  }
);

export const getPriceGroup = createAsyncThunk<PriceGroup, string>(
  `eon-ui/getPriceGroup`,
  async function (priceGroupId) {
    const {data} = await priceGroupsService.getPriceGroup(priceGroupId);
    return data as PriceGroup;
  }
);

export const updatePriceGroup = createAsyncThunk<
  PriceGroup,
  {payload: PriceGroupRequest; priceGroupId: string}
>(`eon-ui/updatePriceGroup`, async function ({payload, priceGroupId}) {
  const {data} = await priceGroupsService.updatePriceGroup(
    priceGroupId,
    payload
  );
  return data as PriceGroup;
});

export const deletePriceGroup = createAsyncThunk<PriceGroup, string>(
  `eon-ui/deletePriceGroup`,
  async function (priceGroupId) {
    const {data} = await priceGroupsService.deletePriceGroup(priceGroupId);
    return data as PriceGroup;
  }
);

const initialState: PriceGroupState = {
  list: {data: [], total: 0, loading: false, requestId: ''},
  selected: {data: null, loading: false, requestId: ''},
};

const priceListSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPriceGroups.pending, (store, action) => {
      listPending(store.list, action);
    });
    builder.addCase(getPriceGroups.fulfilled, (store, action) => {
      listFulfilled(store.list, action);
    });
    builder.addCase(getPriceGroups.rejected, (store, action) => {
      listRejected(store.list, action);
    });
    builder.addCase(getPriceGroup.pending, (state, action) => {
      entityPending(state.selected, action);
    });
    builder.addCase(getPriceGroup.fulfilled, (state, action) => {
      entityFulfilled(state.selected, action);
    });
    builder.addCase(getPriceGroup.rejected, (state, action) => {
      entityRejected(state.selected, action);
    });

    builder.addCase(deletePriceGroup.pending, (state, action) => {
      entityPending(state.selected, action);
    });
    builder.addCase(deletePriceGroup.fulfilled, (state, action) => {
      entityFulfilled(state.selected, action);
    });
    builder.addCase(deletePriceGroup.rejected, (state, action) => {
      entityRejected(state.selected, action);
    });

    builder.addCase(updatePriceGroup.pending, (state, action) => {
      entityPending(state.selected, action);
    });
    builder.addCase(updatePriceGroup.fulfilled, (state, action) => {
      entityFulfilled(state.selected, action);
    });
    builder.addCase(updatePriceGroup.rejected, (state, action) => {
      entityRejected(state.selected, action);
    });
  },
});

export default priceListSlice.reducer;
