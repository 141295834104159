import {createSlice, createAsyncThunk, DeepPartial} from '@reduxjs/toolkit';

import {ListMeta} from '../../services/types';
import companiesService from '../../services/companies';

import {
    listPending,
    listFulfilled,
    listRejected,
    entityPending,
    entityFulfilled,
    entityRejected,
} from '../utils';
import {ListData} from '../types';


import {CustomersState, Customer, CustomerInput} from './types';
import errorResponse, { ErrorResponseResolver } from '../../lib/responseHelper';


const name = 'companies';

export const getCompanies = createAsyncThunk<ListData<Customer>, ListMeta>(
    `eon-ui/getCompanies`,
    async function (meta) {
        const {data, headers} = await companiesService.getCompanies(meta);
        return {
            data: data as Customer[],
            total: +headers['x-total-count'],
        };
    }
);

export const getCustomers = createAsyncThunk<ListData<Customer>, string | null | undefined>(
    `eon-ui/getCustomers`,
    async function (filter) {
        const {data, headers} = await companiesService.getCustomers(filter);
        return {
            data: data as Customer[],
            total: +headers['x-total-count'],
        };
    }
);

export const getCustomer = createAsyncThunk<Customer, string>(
    `eon-ui/getCustomer`,
    async function (customerId) {
        const {data} = await companiesService.getCustomer(customerId);
        return data as Customer;
    }
);

export const changeCustomerStatus = createAsyncThunk<Customer, string>(
    `eon-ui/getCustomer`,
    async function (customerId) {
        const {data} = await companiesService.changeCustomerStatus(customerId);
        return data as Customer;
    }
);


export const updateCustomer = createAsyncThunk<Customer,
    { customerId: string; payload: DeepPartial<CustomerInput> 
    	callback?: (errorResponseResolver: ErrorResponseResolver) => void;
    }>(
        `eon-ui/updateCustomer`, 
        async function ({callback, customerId, payload}) {
            try {
                const {data} = await companiesService.updateCustomer(customerId, payload);
                return data as Customer;
            } catch (error) {
                callback?.(errorResponse(error))
              return {} as Customer   
            }
});

export const createCustomer = createAsyncThunk<Customer,
    { payload: DeepPartial<CustomerInput> 
    	callback?: (errorResponseResolver: ErrorResponseResolver) => void;
    }>(
        `eon-ui/createCustomer`, 
        async function ({callback, payload}) {
            try {
                const {data} = await companiesService.createCustomer(payload);
                return data as Customer;
            } catch (error) {
                callback?.(errorResponse(error))
              return {} as Customer   
            }
});

export const resetCustomer = createAsyncThunk<Customer | null>(`eon-ui/resetCustomer`, async function () {
    return null;
});

export const initialState: CustomersState = {
    list: {
        data: [],
        loading: false,
        requestId: '',
        total: 0,
    },
    selected: {
        data: null,
        loading: false,
        requestId: '',
    },
};

const companiesSlice = createSlice({
    name,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getCustomers.pending, (state, action) => {
            listPending(state.list, action);
        });
        builder.addCase(getCustomers.fulfilled, (state, action) => {
            listFulfilled(state.list, action);
        });
        builder.addCase(getCustomers.rejected, (state, action) => {
            listRejected(state.list, action);
        });

        builder.addCase(getCustomer.pending, (state, action) => {
            entityPending(state.selected, action);
        });
        builder.addCase(getCustomer.fulfilled, (state, action) => {
            entityFulfilled(state.selected, action);
        });
        builder.addCase(getCustomer.rejected, (state, action) => {
            entityRejected(state.selected, action);
        });

        builder.addCase(updateCustomer.pending, (state, action) => {
          entityPending(state.selected, action);
        });
        builder.addCase(updateCustomer.fulfilled, (state, action) => {
          entityFulfilled(state.selected, action);
        });
        builder.addCase(updateCustomer.rejected, (state, action) => {
          entityRejected(state.selected, action);
        });
        builder.addCase(resetCustomer.pending, (state, action) => {
          entityPending(state.selected, action);
        });
        builder.addCase(resetCustomer.fulfilled, (state, action) => {
          entityFulfilled(state.selected, action);
        });
        builder.addCase(resetCustomer.rejected, (state, action) => {
          entityRejected(state.selected, action);
        });
    },
});

export default companiesSlice.reducer;
