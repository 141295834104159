import React, {useEffect, useState} from 'react';
import { CheckoutInformation, checkoutInformationReq } from '../store/checkout/types';
import cartService from '../services/cart';
import { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';


const useEshop = () => {
    const [loading, setLoading] = useState(false)
    const [checkoutInformation, setCheckoutInformation] = useState<CheckoutInformation>()
    const [error, setError] = useState<string>()
    const [response, setResponse] = useState<AxiosResponse>()
    const {t} = useTranslation()

    useEffect(() =>{

    }, [response, setResponse])

    const getCheckoutInformation = async (): Promise<void> => {
        if (!loading) {
            setLoading(true)
            setError(undefined)
            try{
                let response = await cartService.getCheckoutInformation();

                if(response?.data)
                    setCheckoutInformation(response?.data as CheckoutInformation)
            }catch (e: any) {
                setError(e)
                setResponse({statusText: "Something whent wrong", status: 400} as AxiosResponse)
            } finally {
                setLoading(false)
            }
        }
    }
    

    const updateCheckoutInformation = async (checkoutInformationReq : checkoutInformationReq): Promise<void> => {
        if(!loading){
            try{
                setLoading(true)
                let response = await cartService.updateCheckoutInformation(checkoutInformationReq);

                if(response.statusText === "OK")
                    response.statusText = t('common.Saved')

                setResponse(response)

                if(response.data) 
                    setCheckoutInformation(response?.data as CheckoutInformation)
                   
            }catch(e: any) {
                setError(e)
                setResponse({statusText: "Something whent wrong", status: 400} as AxiosResponse)
            }finally {
                setLoading(false)
            }
        }
    }

    const destroyCart =  async () => {
        if(!loading){
            try{
                await cartService.destroyCart()
            }catch(e: any){
                setError(e)
                setResponse({statusText: "Something whent wrong", status: 400} as AxiosResponse)
            }finally{
                setLoading(false)
            }
        }
    }

    return {
        loading,
        getCheckoutInformation,
        updateCheckoutInformation,
        checkoutInformation,
        error,
        response,
        destroyCart
      }

}

export default useEshop