import TextField from '@mui/material/TextField';
import { useCallback, useMemo, useEffect, useRef, useState } from "react"
import { Button } from "react-bootstrap"
import { Controller, FieldValues, FormContextValues } from "react-hook-form"
import { useSelector } from "react-redux"
import DatePicker from "../../../../components/Form/DatePicker"
import InputField from "../../../../components/Form/InputField"
import SelectField, { Option } from "../../../../components/Form/SelectField"
import useObject from "../../../../hooks/useObject"
import priceFormatter from "../../../../lib/priceFormatter"
import { getNextMonth } from "../../../../lib/utils"
import { AppState } from "../../../../store"
import { OfferProduct } from "../../../../store/offers/types"
import { UserType, User } from '../../../../store/users/types';
import { Alert } from '@material-ui/lab';
import { useTranslation } from "react-i18next"
import { PATH_OFFERS_BACK_OFFICE_LIST, PATH_OFFERS_CUSTOMER_OFFERS_LIST, PATH_OFFERS_MY_OFFERS_LIST } from "../../../../router/paths"
import { useHistory } from "react-router";
import { Countries } from "../../../../components/Form/types"
import { CompanyLogo } from "../../../../store/offers/CompanyLogo"
import { ViewOrFileInput } from "../../../../components/Form/ViewOrFileInput"
import { addDaysToDate, formatTimeFromDate } from "../../../../lib/timeHelper"
import { Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Typography } from "@material-ui/core"
import UserPicker from "../../../../components/User/UserPicker"
import useUsers from "../../../../hooks/useUsers"
import OfferToolTable from "./OfferToolTable"
import { resolveOfferProductValues, resolveOfferValues, roundToTwoDecimals } from "./OfferToolHelpers"
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@material-ui/core/Box/Box'
import { Checkbox } from '@material-ui/core'
import Modal from '../../../../components/Modal';

export interface OfferToolFormProps {
	handler: FormContextValues<FieldValues>
	isEdit?: boolean
	isCreate?: boolean
	actionDisabled?: boolean
	onSubmit: (value: FieldValues, type: 'preview' | 'save' | 'accept' | 'download' | 'copy' | 'convertToOrder', userId?: string) => void
	defaultOfferProducts?: OfferProduct[]
	transportCost?: number
	otherCost1?: number
	otherCost2?: number
	otherCost3?: number
	userType?: UserType,
	user: User,
	totalExTaxCostAdjusted?: number,
	countries: Countries[],
	companyLogoInformation?: CompanyLogo,
	onImageChange: (f: boolean) => void,
	discountCost?: number,
	discountCost2?: number,
	discountCost3?: number,
	projectId?: number,
	surcharge?: number,
	currency?: string,
	deliveryOptions: Option[],
}
const theme = createTheme({
	palette: {
		primary: {
		  main: '#EF7B1E',
		},
	},
	components:{
		 // Inputs
		 MuiOutlinedInput:{
			styleOverrides: {
				root: {
					borderRadius: 1,
						"&.Mui-focused": {
							"& .MuiOutlinedInput-notchedOutline": {
								border: `1px solid #EF7B1E`
							},
							"& .MuiInput-underline": {
								border: `1px solid #EF7B1E`
							}
						},
				}
			}
		 },
	}
  });
  
const OfferToolForm: React.FC<OfferToolFormProps> = ({
	handler,
	isEdit,
	isCreate,
	actionDisabled,
	onSubmit,
	defaultOfferProducts,
	children,
	transportCost,
	otherCost1,
	otherCost2,
	otherCost3,
	userType,
	user,
	totalExTaxCostAdjusted,
	countries,
	companyLogoInformation,
	onImageChange,
	discountCost,
	discountCost2,
	discountCost3,
	surcharge,
	projectId,
	currency,
	deliveryOptions,
}) => {
	const readOnly = !isEdit && !isCreate
	const products = useSelector((state: AppState) => state.products.customerproducts)

	const [ values, setValues ] = useObject(resolveOfferValues({
		products: defaultOfferProducts?.map(resolveOfferProductValues)??[],
		taxValue: 25,
		transportCost: 0,
		otherCost1: 0,
		otherCost2: 0,
		otherCost3: 0,
		totalExTaxCostAdjusted: 0,
		discountCost: 0,
		discountCost2: 0,
		discountCost3: 0,
		surcharge: 0,
	}))
	const [offerLimitExceeded, setOfferLimitExceeded] = useState<boolean>(false)
	const [firsRender, setFirstRender] = useState<boolean | undefined >(isCreate)
	const [dateToday, setDateToday] = useState(new Date())
	const [wayOfDeliveries, setWayOfDeliveries] = useState<Option[]>([])
	const [proposedDeliveryDate, setProposedDeliveryDate] = useState(new Date())
	const history = useHistory()

	const {getUsers, users} = useUsers();

	useEffect(() =>{
		if(users !== undefined) return;
		getUsers({page: 1,filter: "", textSearch: "", onlyAdmins: 0})
	},[getUsers, users])
	const [ userId, setUserId ] = useState<string>()

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => {
		setFirstRender(false)
	})

	useEffect(()=> {
		//Reset values when state is change.
		if(products.loading)
			handleSetValues({ products: defaultOfferProducts?.map(resolveOfferProductValues)??[] })
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[products.loading])

	useEffect(() => {
		// If no offer limitation exists for this user, skip the check.
		if (user.userClause.offerLimitation == null) return;
		// Do not render any warning for super admins.
		if (user.userPermission.userType === "SUPER_ADMIN") return;

		// If total cost ex tax is greater than offer limitation on a user, show warning.
		if (values.totalExTaxCost >= user.userClause.offerLimitation) {
			setOfferLimitExceeded(true);
		} else {
			setOfferLimitExceeded(false);
		}
	},[user.userClause.offerLimitation, user.userPermission.userType, values.totalExTaxCost])

	const handleSetValues = useCallback(
		<T extends Partial<typeof values>>(callbackOrValues: ((state: typeof values) => T) | T) =>
			setValues(state => resolveOfferValues({ ...state, ...typeof callbackOrValues === 'function' ? callbackOrValues(state) : callbackOrValues}))
	, [setValues])

	useEffect(() => {
		if(transportCost !== undefined && otherCost1 !== undefined){
			handleSetValues({
				products: defaultOfferProducts?.map(resolveOfferProductValues)??[],
				transportCost: transportCost,
				otherCost1: otherCost1,
				otherCost2: otherCost2,
				otherCost3: otherCost3,
				totalExTaxCostAdjusted: totalExTaxCostAdjusted,
				discountCost: discountCost,
				discountCost2: discountCost2,
				discountCost3: discountCost3,
				surcharge: surcharge
			})
		}else{
			handleSetValues({ products: defaultOfferProducts?.map(resolveOfferProductValues)??[] })
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [defaultOfferProducts, handleSetValues])

	const mergeRef = useRef(false)

	const handleAddProduct = (opt: Option) => {
		const product = products.data.find(p => p.product.fnArticleId === opt.value)
		if(!product) return
		handler.setValue('product', undefined)
		const offerProduct = resolveOfferProductValues({
			id: product.product.id,
			fnArticleId: product.product.fnArticleId,
			productId: product.product.id,
			name: product.product.productInformation.name,
			quantity: 1,
			purchasePrice: product.product.productInformation.articlePrice.purchasePrice,
			priceEach: product.product.productInformation.articlePrice.displayPrice,
			discount: product.product.productInformation.articlePrice.discount ?? 0,
			disposableQuantity: product.product.disposableQuantity,
		})
		handleSetValues(v => ({ products: [ ...v.products, offerProduct ] }))
	}

	const handleUpdateProduct = ({ id, articleId, name, value }: { id: string, articleId: string, name: keyof OfferProduct, value: number }) => {
		const product = products.data.find(p => p.product.fnArticleId === articleId)
		handleSetValues(v => ({ products: v.products.map(p => {
			if(p.fnArticleId !== articleId) return p
			p.disposableQuantity = product?.product.disposableQuantity ?? 0
			return resolveOfferProductValues({ ...p, [name]: value })
		})}))
	}

	const handleListChange = ({row, dropIndex}:{row: number, dropIndex: number}) =>{
		const getObj = values.products[row]
		values.products.splice(row, 1);
		values.products.splice(dropIndex, 0, getObj);

		handleSetValues(v => ({ products: values.products }))
	}

	const handleRemoveProduct = (articleId: string) => handleSetValues(v => ({ products: v.products.filter(p => p.fnArticleId !== articleId) }))

	const handleSetOfferValues = ({
		discountCost,
		discountCost2,
		discountCost3,
		otherCost1,
		otherCost2,
		otherCost3
	} : {  
		discountCost?: number,
		discountCost2?: number,
		discountCost3?: number,
		otherCost1?: number,
		otherCost2?: number,
		otherCost3?: number
	}) => {
		handleSetValues({
			discountCost: discountCost ?? 0,
			discountCost2: discountCost2 ?? 0,
			discountCost3: discountCost3 ?? 0,
			otherCost1: otherCost1 ?? 0,
			otherCost2: otherCost2 ?? 0,
			otherCost3: otherCost3 ?? 0,
		})
	}

	const availableProductOptions = useMemo(() =>
		products.data?.filter(p => !values.products.map(v => v.fnArticleId).includes(p.product.fnArticleId))
		.map(p => ({
			label: p.product.productInformation?.name ? `${p.product.fnArticleId} - ${p.product.productInformation.name} - ${p.product.disposableQuantity}st` : p.product.fnArticleId,
			value: p.product.fnArticleId
		}))
	, [values.products, products.data])

	const overCapacityOfferProductList = useMemo(() =>
		values.products.reduce<OfferProduct[]>((p,v) =>
			v.quantity <= products.data.filter(pd => pd.product.fnArticleId === v.fnArticleId)[0]?.product?.disposableQuantity ? [] : [...p,v], []
		), [products.data, values.products])

	const gap = '1rem'
	const {t} = useTranslation();
	const formatWithouSek = priceFormatter(currency ?? 'SEK', { removeSEK: false })

	const backNavigationUrl = () => {
		switch(localStorage.getItem('type')){
			case "my":
				return PATH_OFFERS_MY_OFFERS_LIST;
			case "customer":
				return PATH_OFFERS_CUSTOMER_OFFERS_LIST;
			default:
				return PATH_OFFERS_BACK_OFFICE_LIST;
		}
	}

	let expirationDateFiveWeeksMax = formatTimeFromDate(addDaysToDate(new Date(), 35));
	const [ modal, setModal ] = useState<{id?: number, type: 'copy', confirm?: boolean, error?: boolean,}>()
	
	const [addDiscountPercentProducts, setAddDiscountPercentProducts] = useState<number>(0)
	const [addTt, setAddTt] = useState<number>(0)

	const AddDiscountPercentToAll = (value : number) =>{
		if(value > 100 || isNaN(value)) return
		setAddDiscountPercentProducts(value)
		values.products?.forEach(product => {
			handleUpdateProduct({id: product.id, articleId: product.fnArticleId, name: 'discount', value})
		})
	}

	const [confirmationModalVisible, setConfirmationModalVisible] = useState(false);

	const showConfirmModal = () => {
		setConfirmationModalVisible(true);
	};

	const onConfirmation = async () => {
		setConfirmationModalVisible(false);

		onSubmit({...handler.getValues(), ...values}, 'convertToOrder');
	};

	const onCancel = () => {
		setConfirmationModalVisible(false);
	};

	return (
		<ThemeProvider theme={theme}>
			<Dialog
			scroll={'body'}
			open={!!modal}
			onClose={() => { setModal(undefined)}}>
			<DialogTitle>
				{t('common.Copy')}
			</DialogTitle>
			<DialogContent style={{overflow: "hidden"}}>
				{ userType === UserType.SuperAdmin || userType === UserType.Admin ? (
					<div style={{minWidth: "330px", minHeight:"150px", overflow: "hidden", padding: "10px"}}>
						<UserPicker Users={users?? []} setSelectedUserId={setUserId}></UserPicker>
					</div>
				):(
					<>
						<span>{t('offer.Do you whant to copy this offer?')}</span>
					</>
				)}
			</DialogContent>
			<DialogActions>
				<button
					className="btn btn-light"
					onClick={() => {setModal(undefined)}}
				>
					{t('common.Close')}
				</button>
				{ (userType === UserType.SuperAdmin || userType === UserType.Admin) && modal? (
					<button
						className="btn btn-primary btn-block"
						onClick={() =>{onSubmit({...handler.getValues(), ...values}, 'copy', userId); setModal(undefined)}}
						style={{ minWidth: 150 }}
						disabled={!userId}
					>
							{t('common.Copy')}
					</button>
				):(
					<button
						className="btn btn-primary btn-block"
						onClick={() =>{onSubmit({...handler.getValues(), ...values}, 'copy', userId); setModal(undefined)}}
						style={{ minWidth: 150 }}
					>
							{t('common.Copy')}
					</button>
				)}
			</DialogActions>
		</Dialog>
		<form className="row offer-management" onSubmit={e => e.preventDefault()}>
			<div className="col-md-12 col-sm-12">
				<div className="row">
					<div className="col-6 col-sm-6 col-lg-8">
						<h3>
						{t('offer.Offer')} {isCreate ? "" :  (projectId)}
						</h3>
					</div>
					<div className="col-6 col-sm-6 col-lg-4">
						<div className="row">
							<div className="p-2">
								{(isEdit !== undefined && isEdit) &&
									<Button
										onClick={() => setModal({ id: projectId, type: 'copy' }) } >
										{t('offer.Copy offer')}
									</Button>
								}
							</div>
							<div className="p-2">
								{(isEdit !== undefined && isEdit) &&
									<Button
										onClick={showConfirmModal} >
										{t('offer.Create order')}
									</Button>
								}
							</div>
							<div className="p-2 bd-highlight">
								{((isEdit !== undefined && isEdit) || (isCreate !== undefined && isCreate)) &&
									<Button
										onClick={() => (onSubmit({...handler.getValues(), ...values}, 'save'))}
										variant={isCreate ? "primary" : "outline-primary"}
										disabled={actionDisabled || readOnly} >
											{t('offer.Save')}
									</Button>
								}
							</div>
						</div>
					</div>
				</div>
			</div>{/* offer information */}
			{!!overCapacityOfferProductList?.length &&
				<div className="col-md-12 mb-2 float-right">
					<Alert severity='warning' variant='outlined'>
						{t('offer.Offer to order need to verify by staff')}
							<Box mt={1}>
								Relaterade produkter:
								<ul>
									{overCapacityOfferProductList.map((v, key) => <li key={key} style={{ margin: '.5em 0' }}>{`${v.fnArticleId} - ${v.name}`}</li>)}
								</ul>
							</Box>

					</Alert>
				</div>
			}
			<div className="col-md-12"> {/* offer information */}
                <div className="card mt-2">
                    <div className="card-header" data-toggle="collapse" data-target="#offerinfo">
                        {t('offer.Offer Information')}
                        <i className="float-right arrow down"></i>
                        <i className="float-right arrow up"></i>
                    </div>
                    <div className="collapse show" id="offerinfo">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12">
                                    {children}
                                </div>
                            </div>
                            <div className="row" style={{ gap }}>
                                <div className="col-md-3 col-sm-12">
                                    <DatePicker
                                        name="expirationDate"
                                        handler={handler}
                                        minDate={new Date()}
                                        defaultValue={getNextMonth()}
                                        label="Giltig t.om"
                                        dateFormat="yyyy-MM-dd"
                                        maxDate={new Date(expirationDateFiveWeeksMax)}
                                        readOnly={readOnly}
                                    />
                                </div>
                                <div className="col-md-3 col-sm-12">
                                    {isEdit && !isCreate &&
                                        <InputField
                                            name="offerId"
                                            handler={handler}
                                            label="Offertnr"
                                            disabled={readOnly}
                                            readOnly
                                        />
                                    }
                                </div>
                            </div>
                            <div className="row" style={{ gap }}>
                                <div className="col-md-3 col-sm-12">
                                    <InputField
                                        name="yourOrderNumber"
                                        handler={handler}
                                        label="Ert ordernummer"
                                        type="text"
                                        readOnly={readOnly}
                                    />
                                </div>
                                <div className="col-md-3 col-sm-12">
                                    <InputField
                                        name="ourReference"
                                        handler={handler}
                                        label="Vår referens"
                                        type="text"
                                        readOnly={readOnly}
                                    />
                                </div>
                                <div className="col-md-3 col-sm-12">
                                    <InputField
                                        name="yourReference"
                                        handler={handler}
                                        label="Er referens"
                                        type="text"
                                        readOnly={readOnly}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>{/* offer information */}

			<div className="col-md-12"> {/* Customer information */}
				<div className="card mt-2">
					<div className="card-header" data-toggle="collapse" data-target="#customerinfo">
						{t('offer.Customer information')}
						<i className="float-right arrow down"></i>
						<i className="float-right arrow up"></i>
					</div>
					<div className="collapse show" id="customerinfo">
						<div className="card-body">
							<div className="row" style={{ gap}}>
								{/* Comment */}
								<div className="col-12">
									<div className="col-4 p-0">
										<InputField
											name="deliveryCustomerName"
											handler={handler}
											label={"Namn"}
											type="text"
											readOnly={readOnly}
										/>
									</div>
								</div>
								<div className="col-4">
									<InputField
										name="deliveryPhoneNumber"
										handler={handler}
										label="Telefonnummer"
										type="text"
										readOnly={readOnly}
									/>
								</div>

								<div className="col-6">
									<InputField
										name="deliveryEmail"
										handler={handler}
										label="Email"
										type="text"
										readOnly={readOnly}
									/>
								</div>
								<div className="col-12">
									<div className="col-6">
										<InputField
											name="deliveryAddress"
											handler={handler}
											label="Leveransadress"
											type="text"
											readOnly={readOnly}
										/>
									</div>
								</div>
								<div className="col-3">
									<InputField
										name="deliveryPostalNo"
										handler={handler}
										label="Postnummer"
										type="text"
										readOnly={readOnly}
									/>
								</div>
								<div className="col-4">
									<InputField
										name="deliveryCity"
										handler={handler}
										label="Ort"
										type="text"
										readOnly={readOnly}
									/>
								</div>
								<div className="col-4">
									<SelectField
										name="deliveryCountryCode"
										handler={handler}
										label="Land"
										disabled={readOnly}
										placeholder="Välj"
										options={countries}
										style={{ minWidth: 150 }}
										isSearchable={true}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div> {/* Customer information */}

			<div className="col-md-12"> {/* Delivery information */}
				<div className="card mt-2">
					<div className="card-header" data-toggle="collapse" data-target="#deliveryinfo">
						{t('offer.Delivery information')}
						<i className="float-right arrow down"></i>
						<i className="float-right arrow up"></i>
					</div>
					<div className="collapse show" id="deliveryinfo">
						<div className="card-body">
							<div className="row" style={{ gap }}>
								<div className="col-md-4 col-sm-12">
									<InputField
										name="driverDeliveryInstruction"
										handler={handler}
										label="Information till Föraren"
										type="text"
										readOnly={readOnly}
									/>
								</div>
								<div className="col-md-4 col-sm-12">
									<InputField
										name="customerDeliveryInstruction"
										handler={handler}
										label="Information till Kund"
										type="text"
										readOnly={readOnly}
									/>
								</div>
								<div className="col-md-4 col-sm-12">
									<DatePicker
										name="proposedDeliveryDate"
										handler={handler}
										defaultValue={new Date(Date.UTC(dateToday.getUTCFullYear(), dateToday.getUTCMonth(), dateToday.getUTCDate()+7))}
										label="Önskat leveransdatum"
										dateFormat="yyyy-MM-dd"
										minDate={new Date(Date.UTC(dateToday.getUTCFullYear(), dateToday.getUTCMonth(), dateToday.getUTCDate()+7))}
										readOnly={readOnly}
										isWeekdayInActive={true}
									/>
								</div>
								<div className="col-md-4 col-sm-12">
									<label htmlFor="wayOfDeliveryCode">Leveranssätt</label>
									<SelectField
										handler={handler}
										name="wayOfDeliveryCode"
										disabled={readOnly}
										options={deliveryOptions}
										isClearable={true}
									/>
								</div>
								<div className="col-md-4 col-sm-12">
									<label htmlFor="sendEmail">
										<Controller
											name={'sendEmail'}
											control={handler.control}
											disabled={readOnly}
											defaultValue={true}
											as={<Checkbox
												id="sendEmail"
												color="primary"
												disableRipple />}  /> Skicka uppdateringsemail
									</label>
									<Typography variant="caption" color="textSecondary" style={{ fontStyle: 'italic' }}>
										Obs. oavsett så skickas mail med orderbekräftelse och eventuella orderförseningar.
									</Typography>
								</div>
								<div className='col-md-4 col-sm-12'>
									<Controller
										name={'comment'}
										control={handler.control}
										disabled={readOnly}
										as={ 
											<TextField
												label={t('common.Comment')}   
												rows='4'
												fullWidth
												multiline
												InputLabelProps={{shrink: true}}
												InputProps={{style: { fontSize: '0.75rem'}}}
											/>}
									/>
								</div>
							</div>
							<div className="row" style={{ gap }}>

							</div>
						</div>
					</div>
				</div>
			</div>{/* offer information */}

			<div className="col-md-12"> {/* products */}
				<div className="card mt-2">
					<div className="card-header" data-toggle="collapse" data-target="#products">
						{t('offer.Products')}
						<i className="float-right arrow down"></i>
						<i className="float-right arrow up"></i>
					</div>
					<div className="collapse show" id="products">
						<div className="card-body">
							<div className="row">
								<OfferToolTable
									handleUpdateProduct={handleUpdateProduct}
									handleRemoveProduct={handleRemoveProduct}
									handleSetValues={handleSetOfferValues}
									Products={values.products}
									HandleDragEnd={handleListChange}
									readOnly={readOnly}
									handler={handler}
									values={values}
									isCreate={isCreate}
									isEdit={isEdit}
									currency={currency}
									availableProductOptions={availableProductOptions}
									handleAddProduct={handleAddProduct}
									addProductsReadOnly={products.total <= 0}
								/>
								<div className="col-12 mt-2">
									<div className="row">
										<div className="col-12">
											<div style={{ display: 'inline-flex', flexDirection: 'column', float: 'right' }}>
												<div className="form-row" style={{ gap }}>
													<InputField
														name="addDiscountAll"
														label="Fakturarabatt (%)"
														disabled={readOnly}
														value={addDiscountPercentProducts}
														onChange={((e: any) => AddDiscountPercentToAll(+e.target.value))}
													/>
													<InputField
														name="totalExTaxCost"
														label="Tot. pris"
														disabled={readOnly}
														readOnly
														value={formatWithouSek(roundToTwoDecimals(values.totalExTaxCost) || 0)}
													/>
													<InputField
														name="totalExTaxTG"
														label="Tot. TG"
														disabled={readOnly}
														readOnly
														value={`${values.totalExTaxTG||0} %`}
													/>
													<InputField
														name="totalExTaxMargin"
														label="Tot. marginal"
														disabled={readOnly}
														readOnly
														value={formatWithouSek(values.totalExTaxMargin)}
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>{/* products */}

			<div className="col-md-12"> {/* price */}
				<div className="row mt-6">
					<div className="col-6">
						<div className="row">
							<div className="col-12">

								<Controller 
									name={'informationText'}  
									control={handler.control}  
									as={ 
										<TextField
											label={'Fritext'}   
											rows='10'
											fullWidth
											multiline
											InputLabelProps={{shrink: true}}
											InputProps={{style: { fontSize: '0.75rem'}}}
										/>}
									/>
							</div>
						</div>
					</div>

					<div className="col-6">
						<div className="row">
							<div className="col-4">
								<TextField 
									label="Tot. avrundat pris"
									variant="outlined" 
									value={values.totalExTaxCostAdjusted !== undefined && values.totalExTaxCostAdjusted >= 0 ? values.totalExTaxCostAdjusted : 0}
									name="totalExTaxCostAdjusted"								
									onChange={(e) => handleSetValues({ totalExTaxCostAdjusted:parseInt(e.target.value) >= 0 ? parseInt(e.target.value) : 0})}
								/>
							</div>
							{/* <div className="col-4"> NOTE!! We will inactivate this function for now (customer request).
								<div style={{ display:
										user.userPermission.userType === UserType.SuperAdmin ||
										user.userPermission.userType === UserType.Admin ||
										user.userPermission.userType === UserType.MainAccount
										? "" : "none" }}
									>
									<InputField
										name="surcharge"
										label="Påslag (%)"
										type="number"
										readOnly={readOnly}
										value={values.surcharge}
										onChange={(e: any) => {
											if(e.target.value > 100) return
											handleSetValues({ surcharge: +e.target.value })
										}}
									/>
								</div>
							</div> */}
							
							<div className="col-4">
								<TextField 
									label="Fraktavgift"
									variant="outlined" 
									value={values.transportCost !== undefined && values.transportCost >= 0 ? values.transportCost : 0}
									name="transportCost"								
									onChange={(e) => handleSetValues({ transportCost:parseInt(e.target.value) >= 0 ? parseInt(e.target.value) : 0})}
								/>
							</div>
							<div className="col-4">
								<TextField 
									label="Moms (%)"
									variant="outlined" 
									value={values.taxValue !== undefined && values.taxValue >= 0 ? values.taxValue : 0}
									name="taxValue"								
									onChange={(e) => handleSetValues({ taxValue:parseInt(e.target.value) >= 0 ? parseInt(e.target.value) : 0})}
								/>
							</div>
							<div className="col-12 mt-2">
								<Alert severity="info">
									{t('common.When choosing a totally rounded price, only this price will be visible.')}
								</Alert>
							</div>
							<div className="col-md-12 mt-2">
								<div style={{ marginBottom: '20px'}}>
									{offerLimitExceeded ? (
										<Alert severity="warning" variant="outlined" style={{ marginTop: '20px'}}>
												<span>
													{t('common.Offer price is over your limit')}
												</span>
										</Alert>
									) : (
										<span></span>
									)}
								</div>
							</div>
						</div>
					</div>

					<div className="col-12">
						<div className="row mt-4">
							<div className="col-6">
								<ViewOrFileInput
									name="companyLogo"
									handler={handler}
									placeholder="Ladda upp logo"
									label="Ladda upp logo"
									onImageChangeValue={(e) => onImageChange(e)}
									companyLogo={companyLogoInformation}
									create={isCreate}
								/>
							</div>
							<div className="col-2">
								<span className="float-right mt-2">
									<h6>{t('offer.Tot. ex tax')}</h6>
									<h5>{formatWithouSek(values.totalCostExTaxOnOffer)}</h5>
								</span>
							</div>

							<div className="col-2">
								<span className="float-right mt-2">
									<h6 style={{color:"#ef7b10", fontWeight: 500}}>
										{t('offer.To pay')}
									</h6>
									<h4 style={{color:"#ef7b10", fontWeight: 700}}>
										{formatWithouSek(values.totalCostInkTaxOnOffer)}
									</h4>
								</span>
							</div>
							<div className="col-2">
										{/* This is for hide stuff */}
							</div>
						</div>
					</div>
				
				</div>
			</div>{/* price */}

			<div className="col-md-12">
				<div className="form-row mt-5" style={{ justifyContent: 'end', gap }}>
					{!(isCreate !== undefined && isCreate) &&
						<button className="btn btn-primary" onClick={() => history.push(backNavigationUrl())}>Gå tillbaka</button>
					}

					{((isEdit !== undefined && isEdit) || (isCreate !== undefined && isCreate)) &&
						<Button onClick={() => onSubmit({...handler.getValues(), ...values}, 'preview')} variant={readOnly ? "primary" : "outline-primary"} disabled={actionDisabled}>Granska</Button>
					}

					{((isEdit !== undefined && isEdit) || (isCreate !== undefined && isCreate)) &&
						<Button
							onClick={() => (onSubmit({...handler.getValues(), ...values}, 'save'))}
							variant={isCreate ? "primary" : "outline-primary"}
							disabled={actionDisabled || readOnly} >
								Spara
							</Button>
					}

					{(isEdit !== undefined && isEdit) &&
						<Button
							onClick={showConfirmModal} >
							{t('offer.Create order')}
						</Button>
					}

					{isEdit &&
						<Button
							onClick={() => onSubmit({...handler.getValues(), ...values}, 'accept')}
							disabled={actionDisabled || offerLimitExceeded}>Godkänn</Button>
					}

					{isEdit !== undefined && !isEdit &&
						<Button
							onClick={() => onSubmit({...handler.getValues(), ...values}, 'download')}
							variant="outline-primary"
							disabled={actionDisabled}>Ladda ner</Button>
					}
				</div>
			</div>
		</form>
		<Modal
			name="confirmation"
			modalVisible={confirmationModalVisible}
			setModalVisible={setConfirmationModalVisible}
			title={t('offer.Create order')}
			dialogClassName="modal-mw-small modal-dialog-centered"
			>
			<form>
				<legend>{t('offer.Are you sure you want to create an order from offer document?', {
				document: projectId,
			})}</legend>
				<div className="float-right mt-7">
				<button
					type="button"
					className="btn btn-info mr-2"
					data-dismiss="modal"
					onClick={onCancel}
				>
					{t('common.Cancel')}
				</button>
				<Button
					type="button"
					style={{backgroundColor: "#2e7d32", borderColor: "#2e7d32"}}
					className="btn"
					onClick={onConfirmation}
				>
					{t('common.Create')}
				</Button>
				</div>
			</form>
		</Modal>
		</ThemeProvider>
	)
}

export default OfferToolForm