import React, { useEffect, useCallback, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DateTime, LocaleOptions } from 'luxon';
import jQuery from 'jquery';

import './NewsList.scss';

import { AppState } from '../../store';
import { getNews, getNewsList } from '../../store/news';
import { setShowLoader } from '../../store/system';

import Table from '../../components/Table';
import Pager from '../../components/Pager';
import { useHistory } from 'react-router-dom';
import { useQuery } from '../../lib/hooks';
import PreviewModal from "../../components/News/PreviewModal";

const connector = connect(
    (state: AppState) => ({
        news: state.news.selected,
        newsList: state.news.list,
    }),
    { getNews, getNewsList, setShowLoader }
);

type NewsArchiveProps = ConnectedProps<typeof connector> & {};

export function NewsArchive({
    news,
    newsList,
    getNews,
    getNewsList,
    setShowLoader,
}: NewsArchiveProps) {
    const { t } = useTranslation();
    const history = useHistory();
    const query = useQuery();

    const page = query.get('page');
    const search = query.get('search');

    const { data: list, loading: newsLoading } = newsList;

    const showLoader = newsLoading;

    const onPagination = useCallback(
        (p) => {
            query.set('page', `${p}`);
            history.push(`?${query}`);
        },
        [query, history]
    );

    const [searchStr, setSearchStr] = useState('');
    const onSearchChange = (e: any) => {
        setSearchStr(e.target.value);
    }
    const [previewModalVisible, setPreviewModalVisible] = useState(false);

    useEffect(() => {
        setShowLoader(showLoader);
        (jQuery('[data-toggle="tooltip"]') as any).tooltip();
    }, [showLoader, setShowLoader]);

    useEffect(() => {
        getNewsList({
            page: page ? +page : 1,
            textSearch: search,
        });
    }, [page, search, getNewsList]);

    const previewNews = (newsId: string) => {
        getNews(newsId);
        setPreviewModalVisible(true)
    }

    let filteredItems = list.filter((data) => {
        if (!searchStr)
            return data
        else if (
            data.name.toLowerCase().includes(searchStr.toLowerCase()) ||
            data.text.toLowerCase().includes(searchStr.toLowerCase()) ||
            data.headline.toLowerCase().includes(searchStr.toLowerCase()
            )
        ) {
            return data
        }
    })

    return (
        <div>
            <h1 data-testid="news_archive_headline">
                {t('navigation.Home__nav.News archive')}
            </h1>
            <Table
                loading={newsLoading}
                title={t('common.News')}
                searchField={{
                    name: 'newsSearch',
                    text: t('common.Search news'),
                    onChange: onSearchChange,
                    value: searchStr,
                }}
                header={[
                    t('common.Picture'),
                    t('common.Name'),
                    t('common.Headline'),
                    t('common.Description'),
                    t('common.Publication date'),
                    t('common.Status'),
                    {
                        key: 'search',
                        component: (
                            <Pager
                                page={page ? +page : 1}
                                totalItems={list.length}
                                onPagination={onPagination}
                            />
                        ),
                    },
                ]}
                row={(row) => {
                    const text = row.text?.split('\n').map((t, i) => (
                        <React.Fragment key={`${i}-${t.substr(10)}`}>
                            {t}
                            <br />
                        </React.Fragment>
                    ));

                    return (
                        <>
                            <td className="table__fit-content">
                                <img src={row.url} style={{ width: '132px' }} alt={row.name} />
                            </td>
                            <td className="table__fit-content">{row.name}</td>
                            <td className="table__fit-content">{row.headline}</td>
                            <td>
                                <div
                                    className="news-text"
                                    data-toggle="tooltip"
                                    data-trigger="hover"
                                    data-html="true"
                                    data-placement="auto"
                                    data-delay={`{"show": 500, "hide": 100}`}
                                    title={row.text?.split('\n').join('<br>')}
                                >
                                    {text}
                                </div>
                            </td>
                            <td>
                                {row.publicationDate
                                    ? DateTime.fromISO(row.publicationDate)
                                        .setLocale('sv')
                                        .toLocaleString(DateTime.DATE_SHORT as LocaleOptions)
                                    : '-'}
                            </td>
                            <td>
                                {row.isActive ? 'Aktiv' : 'Inaktiv'}
                            </td>
                            <td className="text-center">
                                <button type="button" className="btn btn-info" onClick={() => previewNews(row.id)}>
                                    {t('common.View')}
                                </button>
                            </td>
                        </>
                    );
                }}
                list={filteredItems}
                extractKey={(el) => el.id}
            />
            <PreviewModal
                isCustomer={true}
                setModalVisible={setPreviewModalVisible}
                modalVisible={previewModalVisible}
                carouselItem={news.data}
                onPublish={() => { }}
            />
        </div>
    );
}

export default connector(NewsArchive);
