import React, { useEffect, useState } from 'react'
import { useForm } from "react-hook-form"
import { connect, ConnectedProps } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import InputField from '../../../components/Form/InputField'
import { Option } from '../../../components/Form/SelectField'
import { ErrorResponseResolver } from '../../../lib/responseHelper'
import { AppState } from '../../../store'
import { deleteServiceCaseAttachment, downloadServiceCaseAttachment, getSpecificServiceCase, updateServiceCase } from '../../../store/cases'
import { CreateCaseFormType } from '../../../store/cases/types'
import { toggleToast } from '../../../store/components'
import { getCaseManamagementCategoriesOptions } from '../../../store/genericListOption'
import { getActiveUser } from '../../../store/system'
import { JocularUser } from "../../../store/users/types"
import CaseManagementTabNav from './../CaseManagementTabNav'
import ViewCreateManagementCaseForm from './ViewCaseManagementCaseForm'

const connector 
= connect(
    (state: AppState) => ({
        genericListOption: state.genericListOption.selected,
        selectedCase: state.cases.selected,
        activeUser: state.system.sessionUser,
    }),
    { 
        getCaseManamagementCategoriesOptions,
        updateServiceCase, 
        toggleToast, 
        getSpecificServiceCase, 
        deleteServiceCaseAttachment, 
        downloadServiceCaseAttachment,
        getActiveUser
    }
);

type  ViewCaseProps = ConnectedProps<typeof connector> & {};

export function ViewCase({
    genericListOption,
    selectedCase,
    activeUser,
    getCaseManamagementCategoriesOptions,
    updateServiceCase,
    toggleToast,
    getSpecificServiceCase,
    deleteServiceCaseAttachment,
    downloadServiceCaseAttachment,
    getActiveUser
}: ViewCaseProps) {
    const history = useHistory();
    const { serviceCaseId } = useParams() as {
        serviceCaseId: string;
    
    }
    
    const [categoriesOptionsData, setCategoriesOptionsData] = useState<Option[] | []>([]);
    const [priorityOptionsData, setPriorityOptionsData] = useState<Option[] | []>([
        {
            label: "1",
            value: "1"
        },
        {
            label: "2",
            value: "2"
        },
        {
            label: "3",
            value: "3"
        },
        {
            label: "4",
            value: "4"
        },
    ]);
    const [user, setUser] = useState<JocularUser>();
    
    useEffect(() => {
        getActiveUser()
            .then(u => setUser(u.payload as JocularUser))
    },[serviceCaseId, getActiveUser])

    type Attachment = {
        id: string;
        fileList: FileList;
    };
    const [attached, setAttached] = useState<Attachment[] | Attachment[]>([]);

    const setAttachments = function (fileList: FileList, id: string) {
        let ob = {
            fileList: fileList,
            id: id,
        } as Attachment;
        attached.push(ob);
        setAttached(attached);
    };

    const deleteAttachments = (async (id: string) => {
      
    })

    const { data: getCaseManamagementCategoriesOptionsData, loading: getCaseManamagementCategoriesOptionsDataLoading } = genericListOption;
    const { data: selectedCaseData, loading: selectedCaseLoading } = selectedCase;
    
    useEffect(() => {
        getCaseManamagementCategoriesOptions()
    }, [getCaseManamagementCategoriesOptions])

    useEffect(() => {
        getSpecificServiceCase({serviceCaseId: serviceCaseId })
    }, [getSpecificServiceCase])

    useEffect(() => {
        let categoryOptions: Option[] = [];
        getCaseManamagementCategoriesOptionsData?.serviceCaseCategoriesUiList.forEach(val => categoryOptions.push({
            label: val.key,
            value: val.value
        }))
        setCategoriesOptionsData(categoryOptions)
    }, [getCaseManamagementCategoriesOptionsData])

    const handler = useForm<CreateCaseFormType>({ mode: 'onChange' });
    const { reset, watch } = handler;

    useEffect(() => {
        if (selectedCaseData !== undefined && selectedCaseData !== null) {
            let cate = selectedCaseData?.serviceCaseCategory === undefined ? "" : selectedCaseData?.serviceCaseCategory
            const pickedCategory = categoriesOptionsData?.find(opt => opt.value
                === cate);
                const prio = selectedCaseData?.casePriority as any as string;
                const pickedPrio = priorityOptionsData?.find(opt => opt.value 
                    === prio.toString());
                reset({
                    ...pickedCategory,
                    caseDescription: selectedCaseData?.caseDescription,
                    serviceCaseCategory: pickedCategory,
                    caseSubject: selectedCaseData?.caseSubject,
                    facility: selectedCaseData?.facility,
                    serviceCaseStatus: selectedCaseData?.serviceCaseStatus,
                    caseCreatorResolved: selectedCaseData?.caseCreatorResolved,
                    customerIdResolved: selectedCaseData?.customerIdResolved,
                    casePriority: pickedPrio
                })
            
        }
    }, [selectedCaseData, getCaseManamagementCategoriesOptionsData])


    const handleSubmit = (async (createCaseFormType: CreateCaseFormType) => {
       
    })

    const requestFile = (async (fileId: string) => {
        await downloadServiceCaseAttachment({
            fileId: fileId,
            serviceCaseId: serviceCaseId,
            callback: (errorResponseResolver: ErrorResponseResolver) => {
                toggleToast({
                    shouldToggle: true,
                    message: errorResponseResolver.message,
                    status: errorResponseResolver.status
                })
            }})
    })

    return (
        <div>
            <h1 data-testid="manage_companies_headline">Ärendehantering</h1>
            <CaseManagementTabNav 
                isEdit={false}
                sessionUser={user}
                serviceCaseId={serviceCaseId} 
			/>
            <h4>Granska ärende</h4>
            <hr/>
            <InputField
                handler={handler}
                name="caseCreatorResolved"
                label={"Skapad av"}
                className={"col-md-6 col-lg-6"}
                placeholder={"Skapad av"}
                disabled={true}
                readOnly={true}
            />
             <InputField
                handler={handler}
                name="customerIdResolved"
                label={"Företag"}
                className={"col-md-6 col-lg-6"}
                placeholder={"Företag"}
                disabled={true}
                readOnly={true}
            />
            <ViewCreateManagementCaseForm
                handler={handler}
                priorityOptionsData={priorityOptionsData}
                handleSubmit={(handleSubmit)}
                categorys={categoriesOptionsData}
                setAttachments={setAttachments}
                deleteAttachments={deleteAttachments}
                addedFiles={selectedCaseData?.serviceCaseCommentFiles}
                isEdit={false}
                requestFile={requestFile}
            />
        </div>
    );
}

export default connector(ViewCase);