import React from 'react';
import Croppie from 'croppie';
import {useTranslation} from 'react-i18next';

import 'croppie/croppie.css';
import './ImageCrop.scss';

import calcAspectRatio from '../../lib/aspectRatio';

type CropArea = {
  cropAreaSize: {width: number; height: number};
};

type ImageCropProps = {
  sourcePicture: string | File | null;
  required?: boolean;
  // no better idea to get Croppie generate an image for us than
  // getting the instance of it a higher component
  setCroppieInstance: (cr: Croppie) => void;
} & Partial<CropArea>;

export default function ImageCrop({
  sourcePicture,
  setCroppieInstance,
  cropAreaSize,
  required,
}: ImageCropProps) {
  const {t} = useTranslation();

  const finalCropAreaSize =
    cropAreaSize !== undefined ? cropAreaSize : {width: 480, height: 270};
  const ratio = calcAspectRatio(
    finalCropAreaSize.width,
    finalCropAreaSize.height
  );

  const a = sourcePicture === null && required ? 'border border-danger' : '';

  return (
    <div
      className={`upload__container ${a}`}
      style={{
        minHeight: finalCropAreaSize.height,
      }}
    >
      {sourcePicture !== null && (
        <Crop
          sourcePicture={sourcePicture}
          setCroppieInstance={setCroppieInstance}
          cropAreaSize={finalCropAreaSize}
        />
      )}
      {sourcePicture === null && (
        <div
          className={`d-flex justify-content-center align-items-center w-100`}
        >
          <div className="upload__description">
            <ul>
              <li>
                {t('common.image_crop_description.aspect ratio', {ratio})}
              </li>
              <li>{t('common.image_crop_description.file formats')}</li>
              <li>
                {t('common.image_crop_description.min resolution', {
                  ...finalCropAreaSize,
                })}
              </li>
              <li>{t('common.image_crop_description.max size')}</li>
            </ul>
          </div>
          <div className="upload__description_h1">
            {t('common.Upload image to view and crop')}
          </div>
        </div>
      )}
      {sourcePicture === null && required && (
        <span className="text-danger">{t('validation.required')}</span>
      )}
    </div>
  );
}

type CropProps = {
  sourcePicture: File | string;
  setCroppieInstance: (cr: Croppie) => void;
} & CropArea;

export class Crop extends React.Component<CropProps> {
  imgRef: HTMLDivElement | null = null;
  croppie: Croppie | null = null;
  cachedFile: File | string | null = null;

  onError = (error: Error | DOMError | DOMException | null) => {
    // TODO
    console.error('Error loading image:', error);
  };

  updateCroppie = async () => {
    try {
      const {sourcePicture} = this.props;
      this.cachedFile = sourcePicture;

      if (sourcePicture instanceof File) {
        const reader = new FileReader();

        reader.readAsDataURL(sourcePicture);
        reader.onload = () => {
          this.croppie
            ?.bind({url: reader.result as string, zoom: 0})
            .catch(this.onError);
        };
        reader.onerror = () => this.onError(reader.error);
      } else {
        if (sourcePicture === '') {
          return;
        }

        await this.croppie?.bind({url: sourcePicture, zoom: 0});
      }
    } catch (error) {
      this.onError(error);
    }
  };

  componentDidMount() {
    if (this.imgRef === null) {
      return;
    }

    const {height, width} = this.props.cropAreaSize;

    this.croppie = new Croppie(this.imgRef, {
      viewport: {
        width,
        height,
      },
	  mouseWheelZoom: true,
    });

    this.props.setCroppieInstance(this.croppie);
    this.updateCroppie();
  }

  componentDidUpdate() {
    this.updateCroppie();
  }

  // make sure we don't update if the file didn't change
  shouldComponentUpdate(nextProps: CropProps) {
    if (this.cachedFile === null) {
      return true;
    }

    if (typeof this.cachedFile !== typeof nextProps.sourcePicture) {
      return true;
    }

    if (
      this.cachedFile instanceof File &&
      nextProps.sourcePicture instanceof File // make the compiler happy
    ) {
      return (
        this.cachedFile.name !== nextProps.sourcePicture.name ||
        this.cachedFile.size !== nextProps.sourcePicture.size
      );
    } else {
      return this.cachedFile !== nextProps.sourcePicture;
    }
  }

  render() {
    return <div ref={(ref) => (this.imgRef = ref)} data-testid="image_crop" />;
  }
}
