import { request } from '../lib/request';
import { PriceGroupInputNew } from "../store/priceGroups/types";
import { ENDPOINTS } from "../endpoints";

export type PriceGroupRequest = {
  name: string,
  priceGroupArticles: PriceGroupRequestArticles[]
}

export type PriceGroupRequestArticles = {
  internalArticleId: string
  percentageDiscount: string
}

const methods = {
  getPriceGroups() {
    return request({
      endpoint: ENDPOINTS.ESHOP_BACK_PRICE_GROUPS_READ,
      method: 'GET',
    });
  },

  getPriceGroup(priceGroupId: string) {
    return request({
      endpoint: ENDPOINTS.ESHOP_BACK_PRICE_GROUP_READ,
      method: 'GET',
      params: {
        priceGroupId
      }
    });
  },

  updatePriceGroup(priceGroupId: string, payload: PriceGroupRequest) {
    return request({
      endpoint: ENDPOINTS.ESHOP_BACK_PRICE_GROUPS_UPDATE
        .replace(":internalArticleId", priceGroupId),
      method: 'PUT',
      payload: payload
    });
  },

  isNumber(value: string | number): boolean {
    return ((value != null) &&
      (value !== '') &&
      !isNaN(Number(value.toString())));
  },

  createPriceGroups(payload: PriceGroupInputNew) {
    if (payload.row !== null && payload.row !== undefined &&
      payload.name !== null && payload.name !== undefined) {
      payload.row = payload.row.filter(r => r.percentage !== undefined &&
        r.rowSelect !== undefined);
      if (payload.row.length > 0) {
        payload.row = payload.row.filter(p => p.rowSelect !== "")
        payload.row = payload.row.filter(p => this.isNumber(p.percentage))
        if (payload.row.length > 0) {
          let priceGroupRequestArticles: PriceGroupRequestArticles[] =
            payload.row.map(p => { return { 
              percentageDiscount: p.percentage, 
              internalArticleId: p.internalArticleId,
              fortnoxProductId: p.fortnoxProductId,
              productName: p.productName
            } })

          let ob = {
            name: payload.name,
            priceGroupArticles: priceGroupRequestArticles
          } as PriceGroupRequest

          return request({
            endpoint: ENDPOINTS.ESHOP_BACK_PRICE_GROUPS_CREATE,
            method: 'POST',
            payload: ob
          });
        }
      }
    }
  },


  deletePriceGroup(priceGroupId: string) {
    return request({
      endpoint: ENDPOINTS.ESHOP_BACK_PRICE_GROUP_DELETE,
      method: 'DELETE',
      params: { priceGroupId },
    });
  },

};

export default methods
