import { ENDPOINTS } from '../endpoints';
import {request} from '../lib/request';
import { EditOrderRequest } from '../store/orders/types';

const methods = {

  getOrdersFilter() {
    return request({
      endpoint: ENDPOINTS.ORDER_MANAGEMENT_READ_ORDERS_FILTER,
      method: 'GET'
    });
  },

  getOrdersWithLimits(
    query?: { 
    limit?: number, 
    offset?: number, 
    all?: boolean, 
    received?: boolean, 
    orderStatus?: string, 
    search?: string,
    columnName?: string,
    direction?: string 
  }) {
		return request({
			endpoint: '/ordermanagement/orders/payment/read',
			method: 'GET',
			query,
		});
	},

  getSpecificOrder(orderId: string) {
    return request({
      endpoint: `/ordermanagement/order/${orderId}/read`,
      method: 'GET',
    });
  },

  getSpecificOrders(orderId: string) {
    return request({
      endpoint: `/ordermanagement/orders/${orderId}/read`,
      method: 'GET',
    });
  },

  changeStatus(orderId: string, status: string){
        return request({
          endpoint: `/ordermanagement/order/${orderId}/update`,
          method: 'PUT',
          payload:{
            status: status
          }
        })
  },

  changeDeliveryDate(orderId: string, date: string){
    return request({
      endpoint: `/ordermanagement/order/${orderId}/update`,
      method: 'PUT',
      payload:{
        preliminaryDeliveryDate: date
      }
    })
  },

  updateOrder(orderId: string, editOrderRequest: EditOrderRequest){
    return request({
      endpoint: `/ordermanagement/order/${orderId}/update`,
      method: 'PUT',
      payload:{
        preliminaryDeliveryDate: editOrderRequest.preliminaryDeliveryDate,
        deliveryAddress: editOrderRequest.deliveryAddress,
        deliveryAddress2: editOrderRequest.deliveryAddress2,
        customerDeliveryInformation: editOrderRequest.customerDeliveryInformation,
        customerReferenceEmail: editOrderRequest.customerReferenceEmail,
        deliveryCity: editOrderRequest.deliveryCity,
        deliveryCountry: editOrderRequest.deliveryCountry,
        deliveryCountryCode: editOrderRequest.deliveryCountryCode,
        deliveryCustomerName: editOrderRequest.deliveryCustomerName,
        deliveryDate: editOrderRequest.deliveryDate,
        deliveryPhone: editOrderRequest.deliveryPhone,
        deliveryPostalNo: editOrderRequest.deliveryPostalNo,
        driverDeliveryInstuction: editOrderRequest.driverDeliveryInstuction,
        ourReference: editOrderRequest.ourReference,
        orderComment: editOrderRequest.orderComment,
        yourReference: editOrderRequest.yourReference,
        customerReference: editOrderRequest.customerReference,
        yourOrderNumber: editOrderRequest.yourOrderNumber,
        partlyDelivery: editOrderRequest.partlyDelivery,
        shopOrderCartItems: editOrderRequest.shopOrderCartItems,
        assignedUserId: editOrderRequest.assignedUserId,
        assignedUserName: editOrderRequest.assignedUserName,
        internalComment: editOrderRequest.internalComment,
        wareHouseDeliveryInformation: editOrderRequest.wareHouseDeliveryInformation,
        wayOfDeliveryCode: editOrderRequest.wayOfDeliveryCode,
      }
    })
  },

  addArticleToOrder(orderId: string, articleId: string, addedToOrder: Date){
    return request({
      endpoint: ENDPOINTS.ACCOUNT_MANAGEMENT_READ_ADD_ARTICLE_TO_ORDER
        .replace(":orderId", orderId)
        .replace(":fortnoxArticleId", articleId),
      method: 'PUT',
      payload: {addedToOrder: addedToOrder}
    })

  },

  removeArticleFromOrder(orderId: string, articleId: string){
    return request({
     endpoint: ENDPOINTS.ORDER_MANAGEMENT_REMOVE_ARTICLE_FROM_ORDER
        .replace(":orderId", orderId)
        .replace(":fortnoxArticleId", articleId),
      method: 'DELETE',
    })

  },

  getOrderStats() {
    return Promise.all([
      request({
        endpoint: '/mock/orders',
        method: 'GET',
        query: {
          _page: 1,
          _limit: 1,
        },
      }),
      request({
        endpoint: '/mock/orders',
        method: 'GET',
        query: {
          _page: 1,
          _limit: 1,
          status: 'active',
        },
      }),
      request({
        endpoint: '/mock/orders',
        method: 'GET',
        query: {
          _page: 1,
          _limit: 1,
          status: 'completed',
        },
      }),
    ]);
  },
};

export default methods
