
import React from 'react'


type EditOrderProps = {
    value: string | undefined
    name: string
}

export function EditOrderInput({
    value,
    name
}: EditOrderProps) {


    return (
        <>
            <div className="form-group">
                <label>
                    {name}
                </label>
                <input 
					type="text" 
					className="form-control" 
					value={value !== undefined && value !== null ? value : "-" } 
					placeholder={name} 
					aria-label={name} 
					aria-describedby="basic-addon1"
					readOnly 
				/>
            </div>
        </>
    )
}

export default EditOrderInput;