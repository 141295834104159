import React, {useEffect} from 'react';
import {FormContextValues} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

import {DEFAULT_RULES} from '../../lib/form';

import InputField from '../Form/InputField';
import {User} from "../../store/users/types";
import ToggleField from "../Form/ToggleField";
const formColumnClass = 'col-12';

type UserProfileProps = {
  handleInput: FormContextValues<UserProfileFormInput>;
  onSubmit: (input: UserProfileFormInput) => void;
  sessionUser: User|null
};

export type UserProfileFormInput = {
  name: string;
  phoneNumber: string;
  email: string;
  address: string;
  position: string;
  receiveNotification: boolean;
};

function UserProfileForm({handleInput, onSubmit, sessionUser}: UserProfileProps) {
  const {t} = useTranslation();

  const {handleSubmit, reset} = handleInput;

    useEffect(() => {
        if(sessionUser){
            const {userInformation, userCredential} = sessionUser
            reset({
                name:userInformation?.name,
                phoneNumber:userInformation?.phoneNumber,
                email:userCredential?.email,
                position:userInformation?.position,
                receiveNotification:sessionUser?.receiveNotification,
            })
        }
    }, [sessionUser]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={'col-10 offset-1'}>
      <legend className="primary" data-testid="form__edit_profile_form">{t('common.Edit profile')}</legend>
        <InputField
            name="name"
            handler={handleInput}
            label={t('common.Name')}
            className={formColumnClass}
            rules={{
                required: true,
            }}
        />
        <InputField
            name="phoneNumber"
            handler={handleInput}
            label={t('common.Phone number')}
            className={formColumnClass}
            rules={{
                required: true,
            }}
        />
        <InputField
            name="email"
            handler={handleInput}
            label={t('common.E-mail address')}
            className={formColumnClass}
            readOnly={true}
            rules={{
                required: true,
                minLength: DEFAULT_RULES.email.minLength,
                pattern: {
                    value: DEFAULT_RULES.email.pattern,
                    message: 'invalid email',
                },
            }}
        />
        <InputField
            name="position"
            handler={handleInput}
            label={t('common.Position')}
            className={formColumnClass}
            rules={{
                required: true,
            }}
        />
        <ToggleField
            switchText={[t('common.No'), t('common.Yes')]}
            name="receiveNotification"
            handler={handleInput}
            label={t('common.Notification when order update')}
            className={formColumnClass}
        />
        <button
            type="submit"
            className="btn btn-primary mr-2"
            data-testid="submit__user_form"
        >
            {t('common.Save data')}
        </button>
    </form>
  );
}

export default UserProfileForm;
