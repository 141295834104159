import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';

import productsService, {ProductsFilter} from '../../services/products';
import {ListMeta} from '../../services/types';

import {
  listFulfilled,
  listPending,
  listRejected,
  entityFulfilled,
  entityRejected,
  entityPending,
} from '../utils';
import {ListData} from '../types';

import {
  Product,
  ProductsState,
  MergedArticleProduct,
  InternalProduct,
  ProductInput,
  MonitorProduct,
} from './types'
import errorResponse, { ErrorResponseResolver } from '../../lib/responseHelper';
import {CallbackOnFail, CallbackOnSuccess} from '../components/types';
import { headerFilenameExtension } from '../../extensions/FilenameExtension';

const name = 'products';

export const getProduct = createAsyncThunk<MergedArticleProduct, string>(
  `eon-ui/getProduct`,
  async function (productId) {
    const {data} = await productsService.getProduct(productId);
    return data as MergedArticleProduct;
  }
);

export const createMonitorProduct = createAsyncThunk<MonitorProduct, {
  monitorProduct: MonitorProduct,
  callback: (errorResponseResolver: ErrorResponseResolver) => void;
}>(
  `monitor/create`,
  async function ({ monitorProduct, callback }) {
      try {
          const { data } = await productsService.createMonitorProduct(monitorProduct)
          return data as MonitorProduct;
      }
      catch (error) {
          callback(errorResponse(error))
          return {} as MonitorProduct;
      }
  }
);

export const deleteProduct = createAsyncThunk<Product, string>(
  `eon-ui/deleteProduct`,
  async function (internalArticleId) {
    const {data} = await productsService.deleteProduct(internalArticleId);
    return data as Product;
  }
);

export const deleteProductDocument = createAsyncThunk<
  Product,
  {articleId: string; fileId: string}
>(`eon-ui/deleteProductDocument`, async function ({articleId, fileId}) {
  const {data} = await productsService.deleteProductImage(articleId, fileId);
  return data as Product;
});

export const editProduct = createAsyncThunk<
  Product,
  {
    internalArticleId: string;
    internalProduct: InternalProduct;
    callbackOnSuccess: CallbackOnSuccess;
    callbackOnFail: CallbackOnFail;
  }
>(
  `eon-ui/editProduct`,
  async function ({
    internalArticleId,
    internalProduct,
    callbackOnSuccess,
    callbackOnFail,
  }) {
    try {
	  internalProduct.image || await productsService.deleteInternalArticleImage(internalArticleId)
      const {data, status} = await productsService.editProduct(
        internalArticleId,
        internalProduct
      );
      callbackOnSuccess(status, data);
      return data as Product;
    } catch (error) {
      callbackOnFail(errorResponse(error));
      return {} as Product;
    }
  }
);

export const getProductsWithoutFilters = createAsyncThunk<
  ListData<MergedArticleProduct>
>(`eon-ui/getProducts`, async function (meta) {
  const {data, headers} = await productsService.getProductsWithoutFilter();
  return {
    data: data as MergedArticleProduct[],
    total: headers['x-total-count'],
  };
});

export const getCustomerProducts = createAsyncThunk<
  ListData<MergedArticleProduct>, {customerId: string}
>(`eon-ui/getCustomerProducts`, 
  async function ({customerId}) {
    const {data, headers} = await productsService.getCustomerProducts(customerId);
    return {
      data: data as MergedArticleProduct[],
      total: headers['x-total-count'],
    };
});

export const resetCustomerProducts = createAsyncThunk<
  ListData<MergedArticleProduct>>(`eon-ui/resetCustomerProducts`, 
  async function () {
    return {
      data: [],
      total:0,
    };;
});

export const getProductsIncremental = createAsyncThunk<
  ListData<MergedArticleProduct>,
  {
    listMeta: ListMeta<ProductsFilter>;
    previousProducts: MergedArticleProduct[];
  }
>(
  `eon-ui/getProductsIncremental`,
  async function ({listMeta, previousProducts}) {
    const {data, headers} = await productsService.getProducts(listMeta);
    const mergedData = previousProducts.concat(data as MergedArticleProduct[]);
    return {
      data: mergedData,
      total: headers['x-total-count'],
    };
  }
);

export const getRecommendedProducts = createAsyncThunk<ListData<MergedArticleProduct>>(
  `eon-ui/getRecommendedProducts`,
  async function () {
    const {data, headers} = await productsService.getRecommendedProducts()
    return {
      data: data,
      total: headers['x-total-count'],
    };
  }
);

export const getProductsManufacturers = createAsyncThunk<
	ListData<string>
>(
	`eon-ui/getProductsManufacturers`, 
	async function() {
		const {data, headers} = await productsService.getProductsManufacturers();
		return {
			data: data as string[],
			total: headers['x-total-count'],
		}
	}
)

export const getProductsWithFilters = createAsyncThunk<
  ListData<MergedArticleProduct>,
  ListMeta<ProductsFilter>
>(`eon-ui/getProductsWithFilters`, async function (meta) {
  const {data, headers} = await productsService.getProducts(meta);
  return {
    data: data as MergedArticleProduct[],
    total: headers['x-total-count'],
  };
});

export const getProductFile = createAsyncThunk<
  void,
  {fileId: string; customerId: string}
>(`eon-ui/getProductFile`, async function ({fileId, customerId}) {
  await productsService.getProductFile(fileId, customerId).then((r) => {
    var filename = headerFilenameExtension(r);
    if (r.status === 200) {
      const url = window.URL.createObjectURL(new Blob([r.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        filename
      ); //or any other extension
      document.body.appendChild(link);
      link.click();
    }
  });
});

export const downloadPriceList = createAsyncThunk<void, {customerId: string}>(
  `eon-ui/downloadPriceList`,
  async function ({customerId}) {
    await productsService.downloadPriceList(customerId).then((r) => {
      let extension: string = r.headers['file-extension'];
      if (r.status === 200) {
        const url = window.URL.createObjectURL(new Blob([r.data]));
        const link = document.createElement('a');
        link.href = url;
        link.download = 'prislista'.concat('.').concat(extension.split('/')[1])
        link.click();
      }
    });
  }
);

export const downloadPriceListCSV = createAsyncThunk<void, {customerId: string}>(
  `eon-ui/downloadPriceListCSV`,
  async function ({customerId}) {
    await productsService.downloadPriceListCSV(customerId).then((r) => {
      if (r.status === 200) {
        const url = window.URL.createObjectURL(new Blob([r.data]))
        const link = document.createElement('a')
        link.href = url;
        link.download = 'prislista.csv'
        link.click();
      }
    });
  }
);

export const createAndMapProduct = createAsyncThunk<
  MergedArticleProduct,
  {
    fortNoxArticleId: string;
    payload: ProductInput;
    callbackOnSuccess: CallbackOnSuccess;
    callbackOnFail: CallbackOnFail;
  }
>(
  `eon-ui/createAndMapProduct`,
  async function ({
    fortNoxArticleId,
    payload,
    callbackOnFail,
    callbackOnSuccess,
  }) {
    try {
      const {data, status} = await productsService.createProduct(
        fortNoxArticleId,
        payload
      );
      callbackOnSuccess(status, data);
      return data as MergedArticleProduct;
    } catch (error) {
      callbackOnFail(errorResponse(error));
      return {} as MergedArticleProduct;
    }
  }
);

export const initialState: ProductsState = {
  list: {
    data: [],
    loading: false,
    requestId: '',
    total: 0,
  },
  customerproducts: {
    data: [],
    loading: false,
    requestId: '',
    total: 0,
  },
  products: {
    data: [],
    loading: false,
    requestId: '',
    total: 0,
  },
  recommended: {
    data: [],
    loading: false,
    requestId: '',
    total: 0,
  },
  manufacturers: {
    data: [],
    loading: false,
    requestId: '',
    total: 0,
  },
  articles: {
    data: [],
    loading: false,
    requestId: '',
    total: 0,
  },
  category: {
    data: [],
    loading: false,
    requestId: '',
    total: 0,
  },
  selected: {
    data: null,
    loading: false,
    requestId: '',
  },
  solConfigProducts: {
    data: null,
    loading: false,
    requestId: '',
  },
  dynamicSolConfigProducts: {
    data: null,
    loading: false,
    requestId: '',
  },
  defaultPanel: {
    data: null,
    loading: false,
    requestId: '',
  },
  invalidProducts: {
    data: null,
    loading: false,
    requestId: '',
  }
};

const productsSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProductsWithoutFilters.pending, (state, action) => {
      listPending(state.list, action);
    });

    builder.addCase(getProductsWithoutFilters.fulfilled, (state, action) => {
      listFulfilled(state.list, action);
    });
    builder.addCase(getProductsWithoutFilters.rejected, (state, action) => {
      listRejected(state.list, action);
    });

    builder.addCase(getCustomerProducts.pending, (state, action) => {
      listPending(state.customerproducts, action);
    });

    builder.addCase(getCustomerProducts.fulfilled, (state, action) => {
      listFulfilled(state.customerproducts, action);
    });
    builder.addCase(getCustomerProducts.rejected, (state, action) => {
      listRejected(state.customerproducts, action);
    });

    builder.addCase(resetCustomerProducts.pending, (state, action) => {
      listPending(state.customerproducts, action);
    });

    builder.addCase(resetCustomerProducts.fulfilled, (state, action) => {
      listFulfilled(state.customerproducts, action);
    });
    builder.addCase(resetCustomerProducts.rejected, (state, action) => {
      listRejected(state.customerproducts, action);
    });

    builder.addCase(getProductsManufacturers.pending, (state, action) => {
      listPending(state.manufacturers, action);
    });
    builder.addCase(getProductsManufacturers.fulfilled, (state, action) => {
      listFulfilled(state.manufacturers, action);
    });
    builder.addCase(getProductsManufacturers.rejected, (state, action) => {
      listRejected(state.manufacturers, action);
    });

    builder.addCase(getProductsWithFilters.pending, (state, action) => {
      listPending(state.list, action);
    });

    builder.addCase(getProductsWithFilters.fulfilled, (state, action) => {
      listFulfilled(state.list, action);
    });
    builder.addCase(getProductsWithFilters.rejected, (state, action) => {
      listRejected(state.list, action);
    });

    builder.addCase(getProductsIncremental.pending, (state, action) => {
      listPending(state.list, action);
    });
    builder.addCase(getProductsIncremental.fulfilled, (state, action) => {
      listFulfilled(state.list, action);
    });
    builder.addCase(getProductsIncremental.rejected, (state, action) => {
      listRejected(state.list, action);
    });

    builder.addCase(getRecommendedProducts.pending, (state, action) => {
      listPending(state.recommended, action);
    });
    builder.addCase(getRecommendedProducts.fulfilled, (state, action) => {
      listFulfilled(state.recommended, action);
    });
    builder.addCase(getRecommendedProducts.rejected, (state, action) => {
      listRejected(state.recommended, action);
    });

    builder.addCase(getProduct.fulfilled, (state, action) => {
      entityFulfilled(state.selected, action);
    });
    builder.addCase(getProduct.rejected, (state, action) => {
      entityRejected(state.selected, action);
    });
    builder.addCase(getProduct.pending, (state, action) => {
      entityPending(state.selected, action);
    });

    builder.addCase(createAndMapProduct.fulfilled, (state, action) => {
      entityFulfilled(state.selected, action);
    });
    builder.addCase(createAndMapProduct.rejected, (state, action) => {
      entityRejected(state.selected, action);
    });
    builder.addCase(createAndMapProduct.pending, (state, action) => {
      entityPending(state.selected, action);
    });
  },
});

export default productsSlice.reducer;
