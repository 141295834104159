import React from 'react';
import {useTranslation} from 'react-i18next';
import Icon from '@mdi/react';
import {mdiMenuDown} from '@mdi/js';
import Select, { Props } from 'react-select';
import {Controller, FormContextValues} from 'react-hook-form';
import classnames from 'classnames';

import {extractErrorText, getErrorTestId, getErrorObj} from '../../lib/form';

import {InputDefaultProps} from './types';

export type Option<T extends string = string> = {
  label: string;
  value: T;
};

interface SelectFieldProps<T> extends InputDefaultProps {
  options: Option[];
  placeholder?: string;
  handler: FormContextValues<T>;
  handleChange?: (value: any) => void;
  isClearable?: boolean;
  style?: React.CSSProperties
	isOptionSelected?: Props['isOptionSelected']
  selectClassName?: string
}

export default function SelectField<T>({
  name,
  handler,
  rules,
  className,
  selectClassName,
  label,
  options,
  placeholder,
  disabled,
  readOnly,
  isSearchable,
  isClearable,
  handleChange,
  style,
	value,
	defaultValue,
	isOptionSelected,
}: SelectFieldProps<T>) {
  if (handler === undefined) {
    throw new Error('missing controller');
  }

  const {t} = useTranslation();

  const id = `select__eon-ui`;

  const elementError = getErrorObj(handler.errors, name);
  const errorText = extractErrorText(elementError, rules, t);

  placeholder =
    placeholder === null
      ? undefined
      : placeholder === undefined && typeof label === 'string'
      ? label
      : placeholder;

  const isDisabled =
    (typeof readOnly === 'object' ? readOnly[name] : readOnly) ||
    (typeof disabled === 'object' ? disabled[name] : disabled);

  return (
    <div className={classnames('form-group', className)}
	style={style}>
      {label && <label htmlFor={id}>{label}</label>}
      <Controller
        control={handler.control}
        onChange={handleChange}
        rules={rules}
        name={name}
        as={
          <Select
						isOptionSelected={isOptionSelected}
            id={id}
            options={options}
						value={value}
						defaultValue={defaultValue}
            classNamePrefix="form__select"
            isSearchable={!!isSearchable}
            placeholder={placeholder}
            className={classnames('form__select', {
              'form__select--has-error': elementError,
            }, selectClassName)}
            isDisabled={isDisabled}
            isClearable={isClearable}
            components={{
              IndicatorSeparator: () => null,
              DropdownIndicator: () => (
                <Icon
                  path={mdiMenuDown}
                  size="24"
                  color="rgba(0, 0, 0, 0.35)"
                  className="icon-24"
                />
              ),
            }}
          />
        }
      />
      {elementError ? (
        <span
          className="form-control-error text-danger"
          data-testid={getErrorTestId(elementError)}
        >
          {errorText}
        </span>
      ) : null}
    </div>
  );
}
