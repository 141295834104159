import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import genericListOption from "../../services/genericListOption";
import { entityFulfilled, entityPending, entityRejected } from "../utils";
import { GenericListOption } from "./types";
import { GenericListOptionState } from './types';




const name = 'genericListOption';


export const getCaseManamagementCategoriesOptions = createAsyncThunk<GenericListOption>(
    `eon-ui/getCaseManamagementCategoriesOptions`,
    async function () {
      const { data } = await genericListOption.getCaseManamagementCategoriesOptions();
      return data as GenericListOption
    }
  );

export const getAccountManagementTermsOfDeliveriesOptions = createAsyncThunk<GenericListOption>(
    `eon-ui/getAccountManagementTermsOfDeliveriesOptions`,
    async function () {
      const { data } = await genericListOption.getCaseManamagementCategoriesOptions();
      return data as GenericListOption
    }
  );


  
  const initialState: GenericListOptionState = {
    list: {
      data: [],
      loading: false,
      requestId: '',
      total: 0,
    },
    selected: {
      data: null,
      loading: false,
      requestId: '',
    },
  };


  const genericListOptionSlice = createSlice({
    name,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      //delete from cart 
      builder.addCase(getCaseManamagementCategoriesOptions.fulfilled, (state, action) => {
        entityFulfilled(state.selected, action);
      });
      builder.addCase(getCaseManamagementCategoriesOptions.pending, (state, action) => {
        entityPending(state.selected, action);
      });
      builder.addCase(getCaseManamagementCategoriesOptions.rejected, (state, action) => {
        entityRejected(state.selected, action);
      });

    }
  });
  
  export default genericListOptionSlice.reducer;
  