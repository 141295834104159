import React, { useEffect, useState } from 'react'
import { AppState } from "../../store"
import { ConnectedProps, connect } from 'react-redux'
import { addToCartB2CFile } from '../../store/cart2'
import { B2Cupload } from "../../store/cart2/types"
import { PATH_E_SHOP } from "../../router/paths"
import { useHistory } from "react-router-dom"
import { ErrorResponseResolver } from "../../lib/responseHelper"
import { Alert } from "@material-ui/lab"
import Link from "@material-ui/core/Link/Link"
import { Fade } from "@material-ui/core"
import { useTranslation } from 'react-i18next';
import {destroyCart, getCheckoutInformation} from "../../store/checkout";

const connector = connect(
    (state: AppState) => ({
        cartItems: state.cart2.list
    }),
    {addToCartB2CFile, destroyCart, getCheckoutInformation}
);

type B2CProps = ConnectedProps<typeof connector> & {};
export function B2C({
    addToCartB2CFile,
	destroyCart,
	getCheckoutInformation
} : B2CProps) {
    const history = useHistory();
    const [file, setFile] = useState<HTMLInputElement | null>()
    const [disabled, setDisabled] = useState(true);
    const [uploadSuccessful, setUploadSuccessful] = useState(false);
    const [uploadInputKey, setUploadInputKey] = useState("")
    const [error, setError] = useState({
        message: "",
        hasError: false
    });
	const { t } = useTranslation()

    const uploadB2CFile = async () => {
        if(file?.files === undefined || file?.files === null) return

		const ob = {
			file: file.files[0]
		} as B2Cupload

		await destroyCart()
		await addToCartB2CFile({
			b2cupload: ob,
			callback: (errorResponseResolver: ErrorResponseResolver) => {
				setUploadSuccessful(false)
				setError({
					hasError:true,
					message: errorResponseResolver.message
				})
				setUploadInputKey(Math.random().toString(36))
			},
			callbackOnSuccess: async () => {
				await getCheckoutInformation()
				setUploadSuccessful(true)
				setError({
					hasError:false,
					message: ""
				})
				setUploadInputKey(Math.random().toString(36))
			}
		})
    }

    const navigateToEShop = () => history.push(PATH_E_SHOP)

    useEffect(() => { setDisabled(file === undefined) }, [file, setFile])
	
    return (
        <div className="container-fluid">
			<h1> {t('B2C.Upload order')} </h1>
            <form>
				<div className="form-group">
					<label>{t('B2C.Upload order (Allowed format .csv)')}</label>
					<input 
						type="file"
						key={uploadInputKey || ''}
						onChange={evt => setFile(evt.target)}
						onDragEnter={evt => {
							evt.currentTarget.style.backgroundColor = "rgba(239,123,16,0.1"
							evt.currentTarget.style.transition = "ease-in-out 0.2s"
						}}
						onDragLeave={evt => {
							evt.currentTarget.style.backgroundColor = "#fbfcfe"
						}}
						style={{ padding: "3em 1em 3em 3em", border: "1px solid rgba(0,0,0,0.1)", borderRadius: "5px", width: "50%" }}
					/>
				</div>
				{!disabled &&
					<Fade in={!disabled}>
						<Alert severity='warning'>
							{t('B2C.When you upload the file, your shopping cart will be deleted and replaced with this order')}
						</Alert>
					</Fade>
				}
                <button className="btn btn-primary btn-sm mt-2 mb-4" type="button" disabled={disabled} onClick={uploadB2CFile}>{t('B2C.Upload order')}</button>
				<Fade in={error.hasError || uploadSuccessful}>
					<Alert severity={ error.hasError && !uploadSuccessful ? 'error' : 'success' }>
						{ uploadSuccessful ?
						<>	
							{t('B2C.Your order have been added to cart')} <br />
							<Link onClick={navigateToEShop}>{t('B2C.Go to E-shop')}</Link>
						</>
						: error.hasError && !uploadSuccessful ? 
						<>	
							{t('B2C.The following error occurred:')} {error.message}
						</>
						: <></>
						}
					</Alert>
				</Fade>
            </form>
        </div>
    );
}

export default connector(B2C);