import {request} from '../lib/request';
import {ENDPOINTS} from '../endpoints';
import {ProductCategoryCard, ProductCategoryInput} from "../store/productCategories/types";
import {DeepPartial} from "redux";


const methods = {
  getProductCategories() {
    return request({
      endpoint: ENDPOINTS.E_SHOP_FRONT_PRODUCT_CATEGORIES_READ,
      method: 'GET',
    });
  },

  getProductCategory(categoryId: string) {
    return request({
      endpoint: ENDPOINTS.ESHOP_BACK_PRODUCT_CATEGORY_READ,
      method: 'GET',
      params: {categoryId},
    });
  },

  createProductCategory(payload: DeepPartial<ProductCategoryInput>) {
    return request({
      endpoint: ENDPOINTS.ESHOP_BACK_PRODUCT_CATEGORY_CREATE,
      method: 'POST',
      payload: payload,
    });
  },

  editProductCategory(categoryId:string, payload:DeepPartial<ProductCategoryInput>) {
    return request({
      endpoint: ENDPOINTS.ESHOP_BACK_PRODUCT_CATEGORY_EDIT,
      method: 'PUT',
      payload: payload,
      params: {categoryId},
    });
  },

  deleteProductCategory(categoryId: string) {
    return request({
      endpoint: ENDPOINTS.ESHOP_BACK_PRODUCT_CATEGORY_DELETE,
      method: 'DELETE',
      params: {categoryId},
    });
  },
  getProductCategoryResolved() {
    return request({
      endpoint: ENDPOINTS.E_SHOP_FRONT_RESOLVE_CATEGORY_NAMES,
      method: 'GET',
    });
  },
  updateProductCategoryOrder(payload: ProductCategoryCard[]) {
    return request({
      endpoint: ENDPOINTS.E_SHOP_BACK_UPDATE_CATEGORY_ORDER,
      method: 'POST',
      payload: payload,
    });
  },
};

export default methods
