

/* eslint-disable no-useless-concat */
import {request} from '../lib/request';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {ENDPOINTS} from '../endpoints';

const methods = {
  getCaseManamagementCategoriesOptions() {
    return request({
      endpoint: ENDPOINTS.CASE_MANAGEMENT_GET_CATEGORIES,
      method: 'GET'
    });
  }
}

export default methods
