import { Dialog, DialogTitle, DialogContent, DialogActions, Box, Color } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import React from 'react'

interface DialogWarningProps {
	open: boolean
	subject: string
	title: string
	content?: string
	onClose: () => void, 
	onConfirm: () => void,
	acceptText?: string
	declineText?: string
}

const DialogWarning: React.FC<DialogWarningProps> = ({ 
	open, 
	subject,
	title,
	content,
	onClose, 
	onConfirm,
	acceptText,
	declineText,
}) => (
	<Dialog
		open={open}
		onClose={onClose}
		aria-labelledby="confirm-reset-dialog"
	>
		<DialogTitle>{subject}</DialogTitle>
		<DialogContent>
			<Box>
				<Alert severity="warning">
					<AlertTitle>{title}</AlertTitle>
					{content}
				</Alert>
			</Box>
		</DialogContent>
		<DialogActions>
			<button 
				className="btn btn-light"
				onClick={onClose}
			>
				{declineText ?? 'Nej'}
			</button>
			<button 
				className="btn btn-outline-danger"
				onClick={() => {
					onClose()
					onConfirm()
				}}
			>
				{acceptText ?? 'Ja'}
			</button>
		</DialogActions>
	</Dialog>
)


export default DialogWarning
