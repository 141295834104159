import React, {useEffect, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';

import uploadService from '../../services/upload';
import companiesService from '../../services/companies';

import {AppState} from '../../store';
import {getCustomer} from '../../store/companies';
import {
  getCompanyDocuments,
  getCompanyResellerAgreements,
} from '../../store/companies/selectors';
import {setShowLoader} from '../../store/system';

import ManageCompanyTabNav from '../../components/Company/ManageCompanyTabNav';
import InstantMultipleFileUploader from '../../components/Form/InstantMultipleFileUploader';
import { isCustomerAccount } from '../../lib/permissions';

const connector = connect(
  (state: AppState) => ({
	userType: state.system.sessionUserType,
    companyLoading: state.companies.selected.loading,
    companyDocuments: getCompanyDocuments(state),
    companyResellerAgreements: getCompanyResellerAgreements(state),
  }),
  {setShowLoader, getCustomer}
);

type EditCompanyDocumentsProps = ConnectedProps<typeof connector> & {};

const formColumnClass = 'col-md-12 col-lg-6';

export function EditCompanyDocuments({
  companyLoading,
  companyDocuments,
  companyResellerAgreements,
  setShowLoader,
  getCustomer,
  userType
}: EditCompanyDocumentsProps) {
  const {t} = useTranslation();
  const {companyId} = useParams<{companyId:string}>();
  const [deleteInProgress, setDeleteInProgress] = useState(false);

  const showLoader = companyLoading || deleteInProgress;
  
  useEffect(() => {
    setShowLoader(showLoader);
  }, [showLoader, setShowLoader]);

  const onDeleteCompanyFile = async (fileId: string) => {
    setDeleteInProgress(true);
    await companiesService.deleteCompanyFile(companyId, fileId);
    setDeleteInProgress(false);
  };

  const uploadDocumentService = uploadService.uploadCompanyDocuments(companyId);
  const uploadResellerAgreement = uploadService.uploadCompanyResellerAgreement(
    companyId
  );

  const generateCompanyFileDownloadLink = companiesService.generateCompanyFileDownloadLink.bind(
    null,
    companyId
  );

  useEffect(() => {
    getCustomer(companyId);
  }, [companyId, getCustomer]);

  return (
    <div>
      <h1 data-testid="edit_company_documents_headline">
        {t('navigation.Customers__nav.Manage companies')}
      </h1>
      <ManageCompanyTabNav companyId={companyId} />

      <form>
        <legend>{t('common.Document')}</legend>
        
		<InstantMultipleFileUploader
			placeholder={t('common.Upload new document')}
			files={companyDocuments}
			onView={generateCompanyFileDownloadLink}
			onDelete={onDeleteCompanyFile}
			fieldClassNames={formColumnClass}
			uploadService={uploadDocumentService}
		/>

        <hr className="form-delimiter" />
        <legend>{t('common.Reseller agreement')}</legend>
		<InstantMultipleFileUploader
			placeholder={t('common.Upload new reseller agreement')}
			files={companyResellerAgreements}
			onView={generateCompanyFileDownloadLink}
			onDelete={onDeleteCompanyFile}
			fieldClassNames={formColumnClass}
			uploadService={uploadResellerAgreement}
		/>
      </form>
    </div>
  );
}

export default connector(EditCompanyDocuments);
