import React, { useContext } from 'react'
import ProductList from './ProductCards/ProductList'
import { contextEShopCart } from '../EShop/Providers/EShopCartProvider'
import useHover from '../../hooks/useHover'
import Box from '@material-ui/core/Box/Box'
import Badge from '@material-ui/core/Badge/Badge'
import Typography from '@material-ui/core/Typography/Typography'
import Divider from '@material-ui/core/Divider/Divider'
import CardActions from '@material-ui/core/CardActions/CardActions'
import Card from '@material-ui/core/Card/Card'
import makeStyles from '@material-ui/core/styles/makeStyles'
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery'
import NonIdealState from '../NonIdealState/NonIdealState'
import { RemoveShoppingCart, ShoppingCart, ShoppingCartOutlined } from '@material-ui/icons'
import CardContent from '@material-ui/core/CardContent/CardContent'
import Collapse from '@material-ui/core/Collapse/Collapse'
import { contextEShopProduct } from './Providers/EShopProductProvider'
import { UserType } from '../../store/users/types'

const Cart = () => {
	const { cart, goToCheckout } = useContext(contextEShopCart)
	const { productMethods, total, items } = cart ?? {}
	const classes = useStyles()
	const smallScreen = useMediaQuery('(max-width:600px)')
	const [ isCartHovered, { onClick, ...bind } ] = useHover()
	const { activeUser, company } = useContext(contextEShopProduct)
	const isEmpty = !items || items.length < 1  

 	return (
		<div className={smallScreen? '' : 'dropdown'} {...bind} style={{ height: 'min-content' }}>
			<button className="btn btn-outline-default border-0" id="#shoppingCart" style={{ boxShadow: 'none' }} onClick={onClick}>
				<Box display="grid" justifyItems="center" className={classes.nonClickable}>
					<Badge badgeContent={total?.amount} style={{ width: 'min-content' }} classes={{ badge: classes.orangeBadge }}>
						{ !isEmpty ? <ShoppingCart fontSize="large" /> : <ShoppingCartOutlined fontSize="large" />}
					</Badge>
					<Typography variant="body2" color="textPrimary" ><b>VARUKORG</b></Typography>
				</Box>
			</button>
			<Collapse in={isCartHovered}>
				<Card
					style={{ width: smallScreen ? '100%' : 425, overflowX: 'hidden', position: 'absolute', right: 0, zIndex: 10 }}
					elevation={3}
				>
					{ !isEmpty ?
						<>
							<ProductList 
								products={items?.map(item => ({
									id: item.articleId,
									name: item.articleName,
									image: item.publicImage,
									articlePrice: item.articlePrice,
									quantity: item.amountOfInCart,
									disposableQuantity: item.disposableQuantity,
									amountOfInCart: item.amountOfInCart,
									maxOrderQuantity: item.maxOrderQuantity,
								}))}
								totalPrice={total?.price}
								{...productMethods??{}}
								limitItems={3}
								// visit={goToSingleProduct}
								showImage={!smallScreen}
								transparent
							/>
							<Divider />
							
							{ items?.length !== 0 &&
								<CardActions>
									<button
										className="btn btn-primary btn-block"
										onClick={goToCheckout}
									>
										TILL KASSAN
									</button>
								</CardActions>
							}
						</>
						:
						<CardContent>
							<NonIdealState 
								icon={<RemoveShoppingCart fontSize="large" />}
								title="Din varukorg är tom"
							/>
						</CardContent>
					}
				</Card>
			</Collapse>
		</div>
	)
}

const useStyles = makeStyles(()=>({
	orangeBadge: {
		backgroundColor: '#ef7b10',
		color: '#fff'
	},
	nonClickable: {
		textDecoration: 'none',
		pointerEvents: 'none'
	}
}))

export default Cart
