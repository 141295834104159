import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { NavbarConfiguration } from '../../../components/EShop/Types'
import useObject from '../../../hooks/useObject'
import { useQuery } from '../../../lib/hooks'
import { PATH_E_SHOP2_PRODUCT_FROM_CATEGORY } from '../../../router/paths'
import { AppState } from '../../../store'
import { getProductCategories } from '../../../store/productCategories'
import { ProductCategory } from '../../../store/productCategories/types'
import { List } from '../../../store/types'
import { contextEShopProducts } from './EShopProductsProvider'

const connector = connect(
    (state: AppState) => ({
        productCategories: state.productCategories.list
    }),
    { getProductCategories }
);

type EShopCategoryProviderProps = ConnectedProps<typeof connector> & {
	children: React.ReactNode
}

interface ContextEShopCategory{
	configuration?: NavbarConfiguration
	setConfiguration?: (ids?: { 
		id1?: string, id2?: string, id3?: string, articleId?: string
	}) => void
	gotoProduct?: (id?: string) => void
	productCategories?: List<ProductCategory>
	resetConfiguration?: () => void
	hasConfiguration?: boolean
	showRecommendedProducts?: () => void
	selectedManufacturer?: string
	setManufacturer?: (term: string) => void
	resetManufacturer?: () => void
}

export const contextEShopCategory = createContext<ContextEShopCategory>({})

const EShopCategoryProvider = ({
	productCategories,
	getProductCategories,

	children
}: EShopCategoryProviderProps) => {
	const modal = !window.location.href.includes('e-shop')
	useEffect(() => { getProductCategories() }, [getProductCategories])
	const { nextProducts, modalParams, setModalParams } = useContext(contextEShopProducts)
	const query = useQuery()
	const history = useHistory()
	const params = useParams<{
        categoryFirstId: string,
        categorySecondId: string,
        categoryThirdId: string,
        articleId: string
    }>()

	const showRecommendedProducts = () => { 
		query.set('filter', 'recommended')
		modal && setModalParams?.({ filter: 'recommended' })
		handleSetConfiguration({ withFilter: true }) 
	}
	
	const paramManufacturer = modal ? modalParams?.manufacturer : query.get('manufacturer') ?? undefined
	const handleSetManufacturer = (term: string) => {
		query.set('manufacturer',term)
		modal && setModalParams?.({ manufacturer: term })
		handleSetConfiguration({ manufacturer: term }) 
	}
	const resetManufacturer = () => handleSetManufacturer('')
	
	const [ configuration, , { add: setConfiguration }] = useObject<NavbarConfiguration>(getConfigurationWithIdsFromProductCategoriesData({
		category1: params.categoryFirstId,
		category2: params.categorySecondId,
		category3: params.categoryThirdId,
		articleId: params.articleId,
    }, productCategories.data))

	useEffect(() => {
		if(!productCategories.data || productCategories.loading) return
		const config = getConfigurationWithIdsFromProductCategoriesData({
			category1: params.categoryFirstId,
			category2: params.categorySecondId,
			category3: params.categoryThirdId,
			articleId: params.articleId,
		}, productCategories.data)
		setConfiguration(config)
		nextProducts?.({
			reset: true, 
			searchCategoryId: getSearchCategoryIdFromConfiguration(config), 
			offset: 0
		})
	}, [productCategories, params])

	
	
	const handleSetConfiguration = useCallback(({ clean, withFilter, manufacturer, ...ids }: { 
		id1?: string, id2?: string, id3?: string, articleId?: string, clean?: boolean, withFilter?: boolean, manufacturer?: string
	} = {}) => {
		const configuration = getConfigurationWithIdsFromProductCategoriesData({
			category1: ids.id1, category2: ids.id2, category3: ids.id3, articleId: ids.articleId,
		}, productCategories.data)
		if(modal) {
			clean 
				? setModalParams?.({
					filter: '',
					manufacturer: '',
					search: ''
				})
				: setModalParams?.({
					...!withFilter?{filter:''}:null,
					...!manufacturer?{manufacturer:''}:null,
				})
		} else {
			!withFilter && query.delete('filter')
			!manufacturer && query.delete('manufacturer')
			history.push(PATH_E_SHOP2_PRODUCT_FROM_CATEGORY
				.replace(":categoryFirstId", configuration.category1.id)
				.replace(":categorySecondId", configuration.category2.id)
				.replace(":categoryThirdId", configuration.category3.id)
				.replace(":articleId", configuration.articleId)
				+ (!clean ? ('?'+query) : '')
			)
		}
		

        setConfiguration(configuration)
		nextProducts?.({ 
			reset: true, 
			searchCategoryId: getSearchCategoryIdFromConfiguration(configuration), 
			offset: 0, 
			manufacturer,
			...modal && clean ? { searchTerm: '', manufacturer: '' } : null
		})
    }, [history, productCategories.data, setConfiguration, query, nextProducts, modalParams])

	const hasConfiguration = !!(
		configuration.category1.name ||
		configuration.category2.name ||
		configuration.category3.name
	)

	return (
		<contextEShopCategory.Provider value={{
			configuration,
			setConfiguration: handleSetConfiguration,
			resetConfiguration: () => handleSetConfiguration({ clean: true }),
			gotoProduct: (id?: string) => handleSetConfiguration({ id1: configuration.category1.id, id2: configuration.category2.id, id3: configuration.category3.id, articleId: id }),
			productCategories,
			hasConfiguration,
			showRecommendedProducts,
			selectedManufacturer: paramManufacturer,
			setManufacturer: handleSetManufacturer,
			resetManufacturer
		}}>
			{children}
		</contextEShopCategory.Provider>
	)
}

const getConfigurationWithIdsFromProductCategoriesData = (ids: { [K in keyof NavbarConfiguration]?: string }, data: ProductCategory[]) => {
	const categories: NavbarConfiguration = {
		category1: { id: "0", name: "" },
		category2: { id: "0", name: "" },
		category3: { id: "0", name: "" },
		articleId: ids.articleId ?? "0",
	}

	let firstCat: ProductCategory | undefined = data.find(ob => ob.id === ids.category1);
	if (firstCat == undefined) return categories
	categories.category1 = { id: firstCat.id, name: firstCat.categoryName }

	let secondCat = firstCat.categorySeconds.find(ob => ob.id === ids.category2)
	if (secondCat == undefined) return categories
	categories.category2 = { id: secondCat.id, name: secondCat.categoryName }

	let thirdCat = secondCat.categoryThirds.find(ob => ob.id === ids.category3)
	if (thirdCat == undefined) return categories
	categories.category3 = { id: thirdCat.id, name: thirdCat.categoryName }

	return categories
}

const getSearchCategoryIdFromConfiguration = (configuration: NavbarConfiguration) =>
	configuration.category3.id !== "0" ? configuration.category3.id
	: configuration.category2.id !== "0" ? configuration.category2.id
	: configuration.category1.id !== "0" ? configuration.category1.id
	: undefined


export default connector(EShopCategoryProvider)
