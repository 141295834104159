import React from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from '../../components/Modal/ConfirmationModal';

export default function InvalidateOrderConfirmation(props: {
  modalVisible: boolean;
  setModalVisible: (f: boolean) => void;
  onConfirmation: () => void;
}) {
  const {t} = useTranslation();

  return (
    <ConfirmationModal
      title={t('order.Invalidate order')}
      message={t('order.Are you sure you want to invalidate this order?')}
      buttonMessage={t('order.Invalidate')}
      {...{
        ...props,
      }}
    />
  );
}