import React, { useContext } from 'react'
import Grid from '@material-ui/core/Grid/Grid'
import EShopCategoryTree from './EShopCategoryTree'
import ProductCardFullPage from '../../ProductCards/ProductCardFullPage'
import Box from '@material-ui/core/Box/Box'
import EShopCatalog from './EShopCatalog'
import { Collapse, Fade } from '@material-ui/core'
import { contextEShopProduct } from '../../../EShop/Providers/EShopProductProvider'
import EShopSearch from './EShopSearch'
import EShopCategoryBreadcrumbs from './EShopCategoryBreadcrumbs'

const EShopMainContent = () => {
	const { productInformation } = useContext(contextEShopProduct)
	
	const showProduct = !!productInformation

	return (
		<>
			<Collapse in={showProduct} timeout={200}>
				<Box>
					<ProductCardFullPage />
				</Box>
			</Collapse>

			<Fade
				in={!showProduct} 
				onEnter={(node) => node.classList.remove('d-none')}
				onExited={(node) => node.classList.add('d-none')}
				timeout={100}
			>
				<Grid container justify="space-around">

					<Grid item sm={12} md={3}>
						<div style={{backgroundColor: "#f1f1f1"}} className="mt-2">
							<h4 className="text-center pt-2 pb-2"><b>Kategorier</b></h4>
							<EShopCategoryTree />
						</div>
					</Grid>

					<Grid item sm={1} md={1}></Grid>

					<Grid item sm={10} md={8}>
						<EShopCategoryBreadcrumbs />
						<EShopSearch />
						<Box mt={3}>
							<EShopCatalog />
						</Box>
					</Grid>

					<Grid item sm={1} md={1}></Grid>

				</Grid>
			</Fade>
		</>
	)
}

export default EShopMainContent
