import React, {useCallback, useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';

import {AppState} from '../../store';
import {setShowLoader} from '../../store/system';
import {TimeUnit} from '../../store/news/types';
import {getNews, updateNews} from '../../store/news';
import {getProductCategories} from '../../store/productCategories';

import {NewsFrom, NewsFormInput} from '../../components/News/NewsForm';

const connector = connect(
  (state: AppState) => ({
    news: state.news.selected,
    productCategories: state.productCategories.list,
  }),
  {
    setShowLoader,
    getNews,
    updateNews,
    getProductCategories
  }
);

type CreateNewsProps = ConnectedProps<typeof connector> & {};

export function CreateNews({
  news,
  setShowLoader: _setShowLoader,
  getNews,
  updateNews,
  productCategories,
  getProductCategories
}: CreateNewsProps) {
  const {t} = useTranslation();
  const {newsId} = useParams<{[key: string]:string}>();
  
  const {data, loading} = news;

  const isLoading = loading;

  useEffect(() => {
    _setShowLoader(isLoading);
  }, [_setShowLoader, isLoading]);

  useEffect(() => {
    getNews(newsId);
  }, [newsId, getNews]);

  useEffect(() => {
    getProductCategories();
  }, [getProductCategories]);

  const setShowLoader = useCallback(
    (set: boolean) => {
      _setShowLoader(set);
    },
    [_setShowLoader]
  );

  const onSubmit = useCallback(
    (input: NewsFormInput, data: Blob) => {
      return updateNews({
        newsId,
        payload: {
          name: input.name,
          numberOf: +input.numberOf,
          unit: input.unit.value as TimeUnit,
          publicationDate: input.publicationDate.toISOString(),
          position: input.position,
          headline: input.headline,
          headlineSize: +input.headlineSize.value,
          text: input.text,
          visibleOnHomepage: input.visibleOnHomepage,
          linkTo:input.category.value,
          data: data,
        },
      });
    },
    [newsId, updateNews]
  );

  return (
    <div>
      <h1 data-testid="edit_news_headline">
        {t('navigation.Home__nav.Edit news')}
      </h1>
      <NewsFrom onSubmit={onSubmit} setShowLoader={setShowLoader} news={data} categories={productCategories}/>
    </div>
  );
}

export default connector(CreateNews);
