import { Customer, CustomerDetails } from "../companies/types";
import { Entity, List } from "../types";
import { CompanyLogo } from "./CompanyLogo";

export interface OffersState {
	offers: List<Offer>
	offerStatuses: Entity<Record<OfferStatus, number | null> & { ALL: number | null }>
  offer: Entity<Offer>
  customers: List<Customer>
}

export interface Offer {	
	companyLogo: CompanyLogo,
	customer: CustomerDetails,
	customerId: string
	customerIdBelonging: string
	customerName: string
	customerNumber: string
	deliveryAddress: string,
	deliveryCity: string,
	deliveryCountryCode: string,
	deliveryCustomerName: string,
	deliveryEmail: string,
	deliveryDate: string,
	deliveryPostalNo: string,
	estimatedEffect: number,
	expirationDate: string,
	id: number,
	informationText: string,
	installedEffect: number,
	needSupervision:boolean,
	offerCreated: string,
	offerCurrency: string,
	offerFilePath: string,
	offerFilePathPhysical: string,
	offerPdfFile: {
		fileCategory: string,
		fileExtension: string,
		filePath: string,
		id: string
		mimeType: string,
		originalName: string,
		physicalPublicUrl: string,
		physicalPublicUrlThumbnail: string,
		physicalUrl: string,
		physicalUrlThumbnail: string,
		publicLink: string,
		publicThumbnailLink: string,
		signedByUserId: string
		signedDate: string,
		uploadedAt: string,
		uploadedByUserId: string
	},
	offerProducts: OfferProduct[],
	offerUpdated: string,
	otherCost: number,
	otherText: string,
	otherCost2: number,
	otherText2: string,
	otherCost3: number,
	otherText3: string,
	ourReference: string,
	proposedDeliveryDate: string,
	resolvedUser: string,
	status: OfferStatus,
	taxValue: number,
	totalCostExTaxOnOffer: number,
	totalCostInkTaxOnOffer: number,
	totalExTaxCost: number,
	totalExTaxMargin: number,
	totalExTaxTG: number,
	totalExTaxCostAdjusted: number,
	totalExTaxText: string,
	transportCost: number,
	transportText: string,
	userId: string
	yourReference: string
	projectNumber: number,
	imagechanged?: boolean,
	discountCost: number,
  	discountCost2: number,
  	discountCost3: number,
 	discountText: string,
  	discountText2: string,
  	discountText3: string,
	surcharge: number,
	yourOrderNumber: string,
	driverDeliveryInstruction: string,
	customerDeliveryInstruction: string,
	wayOfDeliveryCode: string,
	sendEmail: boolean,
	comment: string,
}

export interface CreateOfferPayload {
	userId: string,
	customerId: string,
	expirationDate: string,
	offerCurrency: string,
	totalExTaxText: string,
	totalExTaxCost: number,
	totalExTaxTG: number,
	totalExTaxMargin: number,
	transportText: string,
	transportCost: number,
	otherText: string,
	otherCost: number,
	otherText2: string,
	otherCost2: number,
	otherText3: string,
	otherCost3: number,
	totalCostExTaxOnOffer: number,
	taxValue: number,
	totalCostInkTaxOnOffer: number,
	totalExTaxCostAdjusted: number,
	informationText: null,
	companyLogo: File | undefined,
	offerProducts: OfferProduct[],
	installedEffect?: number,
	estimatedEffect?: number,
	projectNumber?: number,
	status: OfferStatusValues,
	deliveryCustomerName: string, 
	deliveryAddress: string, 
	deliveryPostalNo: string, 
	deliveryCity: string, 
	deliveryCountryCode: string,
	deliveryEmail: string,
	deliveryCustomerId:string,
	deliveryPhoneNumber: string,
	imagechanged?: boolean,
	id: number,
	discountCost: number,
  	discountCost2: number,
  	discountCost3: number,
 	discountText: string,
  	discountText2: string,
  	discountText3: string,
	surcharge: number,
	yourReference: string,
	ourReference: string,
	proposedDeliveryDate: string,
	yourOrderNumber: string,
	driverDeliveryInstruction: string,
	customerDeliveryInstruction: string,
	wayOfDeliveryCode: string,
	sendEmail: boolean,
	comment: string,
}

export interface OfferProduct {
	id: string,
	productId: string,
	fnArticleId: string,
	name: string,
	quantity: number,
	purchasePrice: number,
	priceEach: number,
	totalPrice: number,
	tg: number,
	marginPrice: number,
	discount: number,
	disposableQuantity: number,
}

export enum OfferStatus {
	NEW = 'NEW', 
	LOCKED = 'LOCKED', 
	SENT = 'SENT', 
	APPROVED = 'APPROVED', 
	REJECTED = 'REJECTED', 
	CANCELED = 'CANCELED', 
	ORDERED = 'ORDERED'
}

export interface CopyOffer{
	OfferId: number 
	userId?: string
}

export type OfferStatusValues = OfferStatus | `${OfferStatus}`
