import { Checkbox, Collapse, FormControlLabel, Radio } from '@material-ui/core'
import Box from '@material-ui/core/Box/Box'
import Divider from '@material-ui/core/Divider/Divider'
import Fade from '@material-ui/core/Fade/Fade'
import Grid from '@material-ui/core/Grid/Grid'
import Typography from '@material-ui/core/Typography/Typography'
import { Alert, AlertTitle } from '@material-ui/lab'
import React, { useContext, useEffect, useRef, useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import { useForm } from 'react-hook-form'
import useBoolean from '../../../../hooks/useBoolean'
import { formatTimeFromDate } from '../../../../lib/timeHelper'
import SelectField, { Option } from '../../../Form/SelectField'
import { contextEShopCart } from '../../../EShop/Providers/EShopCartProvider'
import { requiredCheckoutFormFields } from './CheckoutFormContainer'
import FieldGroup from './FieldGroup'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

export interface CheckoutFields {
	customerReference: string
	customerReferenceEmail: string

	deliveryName: string
	deliveryAddress: string
	deliveryPostalNr: string
	deliveryCity: string
	deliveryCountryCode: string
	deliveryPhone: string

	ourReference: string
	yourReference: string
	yourOrderNumber: string
	driverDeliveryInstruction: string
	orderComment: string
	customerDeliveryInformation: string
	partlyDelivery: boolean
	preliminaryDeliveryDate?: Date
	partlyDeliveryProposedDeliveryDate?: Date
	proposedDeliveryDate?: Date

	userConsent: boolean
	wayOfDeliveryCode: string | null
	sendEmail: boolean
}

interface CheckoutFormProps { 
	fields: CheckoutFields
	onFieldChange: (obj: Partial<CheckoutFields>) => void
	showWarning: boolean
	loading: boolean
	deliveryOptions: Option[]
	countries: Option[]
	onSave?: () => void;
	restrictedToOffer?: boolean
	
}

const CheckoutForm: React.FC<CheckoutFormProps> = ({ 
	fields, 
	onFieldChange, 
	showWarning,
	loading,
	deliveryOptions,
	countries,
	onSave,
	restrictedToOffer = false,
	
}) => {
	const {t} = useTranslation();
	const { overCapacityProductList } = useContext(contextEShopCart)
	const handleInput = useForm()
	const [ isChangeInformation, setIsChangeInformation ] = useBoolean()
	const orgInfo = useRef<undefined | false | CheckoutFields>(undefined)
	const [dateToday, setDateToday] = useState(new Date())
	const history = useHistory();

	useEffect(() => {
		if(loading || orgInfo.current !== undefined || orgInfo.current === false) return

		// locks the initial state to allow radio button functionality
		if(
			fields.deliveryCountryCode 
			&& fields.deliveryCity 
			&& fields.deliveryAddress 
			&& fields.deliveryPostalNr
		) orgInfo.current = fields 
		// if any of the fields are empty, locks the state to not allow radio button functionality
		else if(
			fields.deliveryCountryCode 
			|| fields.deliveryCity 
			|| fields.deliveryAddress 
			|| fields.deliveryPostalNr
		) orgInfo.current = false
	}, [ fields ])

	useEffect(() => {
		if(isChangeInformation || !orgInfo.current || loading) return
		onFieldChange({
			deliveryCountryCode: orgInfo.current.deliveryCountryCode,
			deliveryCity: orgInfo.current.deliveryCity,
			deliveryAddress: orgInfo.current.deliveryAddress, 
			deliveryPostalNr: orgInfo.current.deliveryPostalNr
		})
	}, [ isChangeInformation, loading ])

	const initial = useRef(true)
	useEffect(() => {
		if(!orgInfo.current || loading) return
		
		if(initial.current && (fields.deliveryName || fields.customerReferenceEmail)) {
			handleInput.reset({ deliveryCountryCode:  countries.find(c=> c.value === fields.deliveryCountryCode) ?? {} })
			initial.current = false
		} else if(fields.deliveryName || fields.customerReferenceEmail) {
			initial.current = false
		}
	}, [countries, fields.customerReferenceEmail, fields.deliveryCountryCode, fields.deliveryName, handleInput])
	
	const isWeekday = (date: Date) => {
		const day = date.getDay()
		return day !== 0 && day !== 6
	  }

	function isSelectedDateLaterThanThreeMonthsFromNow(): boolean {
		const now = new Date();
		var threeMonthsFromNow = new Date();
		threeMonthsFromNow.setMonth(now.getMonth() + 3);
		return !!fields.proposedDeliveryDate && fields.proposedDeliveryDate.getTime() > threeMonthsFromNow.getTime();
	}

	return (
		<form>
			<Box maxWidth="80ch" mt={3}>
				<Grid container direction="column" spacing={5}>
					<Grid item className="mt-1" container direction="row" spacing={2}>
						<Grid item xs={12}>
							<Typography variant="h6" color="textSecondary">Leveransinformation</Typography>
						</Grid>

						<FieldGroup
							label="Skapad av"
							value={fields.customerReference}
							loading={loading}
							required={requiredCheckoutFormFields.some(f => f === 'customerReference')}
						/>
						<Grid item xs={false} sm={6}></Grid>

						<FieldGroup 
							label="Namn" 
							value={fields.deliveryName} 
							onChange={ event => onFieldChange({deliveryName: event.target.value}) }
							loading={loading}
							required={requiredCheckoutFormFields.some(f => f === 'deliveryName')}
						/>
						<FieldGroup
							label="Mobilnummer (för avisering / track and trace)"
							value={fields.deliveryPhone}
							onChange={ event => onFieldChange({deliveryPhone: event.target.value}) }
							loading={loading}
							required={requiredCheckoutFormFields.some(f => f === 'deliveryPhone')}
						/>
						
						<Grid item xs={12} style={{ padding: 0 }}>
							<Collapse in={!!orgInfo.current}>
								<Box p={2} style={{ display: 'grid' }}>
									<FormControlLabel
										checked={!isChangeInformation}
										onClick={() => setIsChangeInformation(false)}
										control={<Radio size="small" color="primary" disableRipple />}
										label={
											<Typography className="form-check-label" component="label" variant="caption" style={{ marginTop: 1 }} noWrap>
												{orgInfo.current && [
													countries.find(opt => orgInfo.current && opt.value === orgInfo.current.deliveryCountryCode)?.label ?? '',
													orgInfo.current.deliveryCity,
													orgInfo.current.deliveryAddress,
													orgInfo.current.deliveryPostalNr
												].join(', ')}
											</Typography>
										}
										labelPlacement="end"
									/>
									<Box style={{ margin: '-8px 0 0 4px' }}>
										<Typography variant="caption" color="textSecondary">or</Typography>
									</Box>
									<FormControlLabel
										checked={isChangeInformation}
										onClick={() => setIsChangeInformation(true)}
										control={<Radio size="small" color="primary" disableRipple />}
										label={
											<Typography className="form-check-label" component="label" variant="caption" style={{ marginTop: 2 }} noWrap>
												Ändra leveransadress
											</Typography>
										}
										labelPlacement="end"
										style={{ fontStyle: 'italic' }}
									/>
								</Box>
							</Collapse>
						</Grid>

						<Grid item xs={12}>
							<Collapse in={!orgInfo.current || isChangeInformation}>
								<Grid container direction="row" spacing={2}>
									<FieldGroup 
										label="Land"
										loading={loading}
										required={requiredCheckoutFormFields.some(f => f === 'deliveryCountryCode')}
									>
										<SelectField
											handler={handleInput}
											name="deliveryCountryCode"
											handleChange={([opt]: [Option, unknown]) => onFieldChange({ deliveryCountryCode: opt.value })}
											placeholder="Välj"
											options={countries}
											style={{ minWidth: 150 }}
											isSearchable={true}
										/>
									</FieldGroup>
									<FieldGroup
										label="Stad"
										value={fields.deliveryCity}
										onChange={ event => onFieldChange({deliveryCity: event.target.value}) }
										loading={loading}
										required={requiredCheckoutFormFields.some(f => f === 'deliveryCity')}
									/>
									<FieldGroup
										label="Adress"
										value={fields.deliveryAddress}
										onChange={event => onFieldChange({deliveryAddress: event.target.value}) }
										loading={loading}
										required={requiredCheckoutFormFields.some(f => f === 'deliveryAddress')}
									/>
									<FieldGroup
										label="Postnummer"
										value={fields.deliveryPostalNr}
										onChange={ event => onFieldChange({deliveryPostalNr: event.target.value}) }
										loading={loading}
										required={requiredCheckoutFormFields.some(f => f === 'deliveryPostalNr')}
									/>
								</Grid>
							</Collapse>
							<Grid hidden={loading}>
								<span
									hidden={restrictedToOffer || onSave === undefined}
									className="btn btn-primary float-right"
									onClick={onSave}
								>
									{t('common.Save')}
								</span>
								<span
									className="btn mr-2 float-right"
									onClick={() => history.goBack()}
								>
									{t('common.Cancel')}
								</span>
								
								
							</Grid>
						</Grid>
					</Grid>

					<Divider />

					<Grid item className="mt-1" container direction="row" spacing={2}>
						<Grid item xs={12}>
							<Typography variant="h6" color="textSecondary">Referenser</Typography>
						</Grid>

						<FieldGroup 
							label="Vår Referens"
							hint="(kundansvarig)"
							value={fields.ourReference} 
							onChange={ event => onFieldChange({ourReference: event.target.value}) }
							loading={loading} 
							required={requiredCheckoutFormFields.some(f => f === 'ourReference')}
						/>
						<FieldGroup 
							label="Er Referens" 
							value={fields.yourReference} 
							onChange={ event => onFieldChange({yourReference: event.target.value}) } 
							loading={loading}
							required={requiredCheckoutFormFields.some(f => f === 'yourReference')}
						/>
						<FieldGroup 
							label="Ert Ordernummer" 
							value={fields.yourOrderNumber} 
							onChange={ event => onFieldChange({yourOrderNumber: event.target.value}) } 
							loading={loading}
							required={requiredCheckoutFormFields.some(f => f === 'yourOrderNumber')}
						/>
					</Grid>

					<Divider />
					
					<Grid item container direction="row" spacing={2} className="mt-2">
						<Grid item xs={12}>
							<Typography variant="h6" color="textSecondary">Leverans</Typography>
						</Grid>

						<FieldGroup 
							label="Information till Föraren" 
							value={fields.driverDeliveryInstruction} 
							onChange={ event => onFieldChange({driverDeliveryInstruction: event.target.value}) } 
							loading={loading}
							required={requiredCheckoutFormFields.some(f => f === 'driverDeliveryInstruction')}
						/>
						<FieldGroup 
							label="Information till Kund" 
							value={fields.customerDeliveryInformation} 
							onChange={ event => onFieldChange({customerDeliveryInformation: event.target.value}) } 
							loading={loading}
							required={requiredCheckoutFormFields.some(f => f === 'customerDeliveryInformation')}
						/>
						<FieldGroup
							label="Önskat leveransdatum"
							extraInformation={
								<Grid container>
									<Typography variant="caption" color="textSecondary" className="mt-1">
										
										{fields.preliminaryDeliveryDate ? 'Tidigast: ' + formatTimeFromDate(fields.preliminaryDeliveryDate) : 
										<span style={{color: 'red'}}>
											De produkter som är slut i lager kommer att levereras så fort vi får påfyllning i lager.
										</span>
										}
									</Typography>
									<Typography variant="caption" color="textSecondary" className="mt-1" style={{ fontStyle: 'italic' }}>
										Obs. vid/kring helgdagar kan längre leveranstid förekomma
									</Typography>
								</Grid>
							}
							loading={loading}
							required={requiredCheckoutFormFields.some(f => f === 'proposedDeliveryDate')}
						>
							<ReactDatePicker
								autoComplete={"off"}
								minDate={fields.preliminaryDeliveryDate??new Date(Date.UTC(dateToday.getUTCFullYear(), dateToday.getUTCMonth(), dateToday.getUTCDate()+7))}
								selected={fields.proposedDeliveryDate}
								onChange={date => onFieldChange({
									proposedDeliveryDate: (date as Date | undefined) ?? fields.preliminaryDeliveryDate
								})}
								className="form-control"
								dateFormat="yyyy-MM-dd"
								locale="sv"
								filterDate={isWeekday}
							/>
						</FieldGroup>
						{fields.partlyDelivery && 
							<FieldGroup 
								label="Önskat delleveransdatum"
								loading={loading}
								required={requiredCheckoutFormFields.some(f => f === 'partlyDeliveryProposedDeliveryDate')}
								extraInformation={
									<Typography variant="caption" color="textSecondary" className="mt-1">
										Tidigast: {fields.preliminaryDeliveryDate ? formatTimeFromDate(fields.preliminaryDeliveryDate) : 'Okänd'}
									</Typography>
								}
							>
								<ReactDatePicker
									autoComplete={"off"}
									minDate={fields.preliminaryDeliveryDate??new Date(Date.UTC(dateToday.getUTCFullYear(), dateToday.getUTCMonth(), dateToday.getUTCDate()+7))}
									selected={fields.partlyDeliveryProposedDeliveryDate}
									onChange={date => onFieldChange({
										partlyDeliveryProposedDeliveryDate: (date as Date | undefined) ?? fields.preliminaryDeliveryDate
									})}
									className="form-control"
									dateFormat="yyyy-MM-dd"
									locale="sv"
									filterDate={isWeekday}
								/>
							</FieldGroup>
						}
						<FieldGroup label="Leveranssätt" required={requiredCheckoutFormFields.some(f => f === 'wayOfDeliveryCode')} loading={loading}>
							<SelectField
								handler={handleInput}
								name="wayOfDelivery"
								options={deliveryOptions}
								handleChange={([opt]: [Option, unknown]): void => onFieldChange({ wayOfDeliveryCode: opt && opt.value })}
								isClearable={true}
							/>
						</FieldGroup>
					</Grid>

					{ isSelectedDateLaterThanThreeMonthsFromNow() &&
					<Alert severity="warning" style={{ backgroundColor: 'rgb(255, 244, 229)' }}>
						Vid leverans inom tre månader efter lagd beställning erhåller kund pris enligt EONs gällande prislista då beställningen genomfördes. Om kund väljer leveransdatum som överstiger tre månader eller om produkten inte finns i lager, har EON rätt att justera priset i enlighet med gällande prislista vid leverans.
					</Alert>
					}
					<Grid item container direction="row" spacing={2}>
						<FieldGroup 
							required={requiredCheckoutFormFields.some(f => f === 'partlyDelivery')} 
							loading={loading}
							extraInformation={
								<Typography variant="caption" color="textSecondary" style={{ fontStyle: 'italic' }}>
									Ordern splittras beroende på valda produkter eller vilka produkter som finns tillgängliga i lagret.
								</Typography>
							}
						>
							<FormControlLabel
								checked={fields.partlyDelivery}
								control={<Checkbox color="primary" disableRipple />}
								onChange={() => onFieldChange({partlyDelivery: !fields.partlyDelivery})}
								label={
									<Typography component="label" variant="body2" noWrap>
										Delleverans
									</Typography>
								}
								labelPlacement="end"
							/>
						</FieldGroup>
						<FieldGroup 
							required={requiredCheckoutFormFields.some(f => f === 'sendEmail')} 
							loading={loading}
							extraInformation={
								<Typography variant="caption" color="textSecondary" style={{ fontStyle: 'italic' }}>
									Obs. oavsett så skickas mail med orderbekräftelse och eventuella orderförseningar.
								</Typography>
							}
						>
							<FormControlLabel
								checked={fields.sendEmail}
								control={<Checkbox color="primary" disableRipple />}
								onChange={() => onFieldChange({sendEmail: !fields.sendEmail})}
								label={
									<Typography component="label" variant="body2" noWrap>
										Skicka uppdateringsemail
									</Typography>
								}
								labelPlacement="end"
							/>
						</FieldGroup>
					</Grid>

					<Collapse in={fields.preliminaryDeliveryDate == undefined}>
						<Alert severity="warning" variant="outlined">
							Tidigast leveransdatum finns inte och behöver kontrolleras av personal
							{!!overCapacityProductList?.length && 
								<Box mt={1}>
									Relaterade produkter:
									<ul>
										{overCapacityProductList.map((v, key) => <li key={key} style={{ margin: '.5em 0' }}>{`${v.fnArticleId} - ${v.articleName}`}</li>)}
									</ul>
								</Box>
							}
						</Alert>
					</Collapse>
					<Box className='pl-4 pr-4'>
						<div className='form-group'>
							<label>{t('common.Comment')}</label>
								<textarea
									className='form-control'
									rows={3}
									value={fields.orderComment}
									onChange={ event => onFieldChange({orderComment: event.target.value}) }
							/>
						</div>
					</Box>
					<Fade in={showWarning}> 
						<Box my={2}>
							<Alert severity="error" variant="outlined">
								<AlertTitle>Obligatoriska fält</AlertTitle>
								Fält markerade med "{<Typography className="text-danger" variant="caption">*</Typography>}" 
								måste vara ifyllda för att slutföra ordern
							</Alert>
							<Divider />
						</Box>
					</Fade>

				</Grid>
			</Box>
		</form>
	)
}

export default CheckoutForm
