/* eslint-disable no-useless-concat */
import {request} from '../lib/request';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {ENDPOINTS} from '../endpoints';

const methods = {
  getFolders(parentId: string | null) {
    return request({
      endpoint: ENDPOINTS.DOCUMENTS_DIRECTORIES_READ,
      method: 'GET',
      query: {
        folderPath: parentId?parentId:'/'
      }
    });
  },

  getFiles(parentId: string | null) {
    return request({
      endpoint: ENDPOINTS.DOCUMENTS_DIRECTORIES_READ,
      method: 'GET',
      query: {
        folderPath: parentId?parentId:'/'
      }
    });
  },

  resolveFolder(parentId: string | null) {
    return request({
      endpoint: ENDPOINTS.DOCUMENTS_DIRECTORIES_READ,
      method: 'GET',
      query: {
        folderPath: parentId?parentId:'/'
      }
    });
  },

  createFolder(parentId: string | null, name: string) {
    let payload;
    if(parentId !== null){
      payload = {"folderPath": `${parentId?.slice(1)}/eon-ui`}
    }else{
      payload = {"folderPath": `/eon-ui`}
    }
    return request({
      endpoint: ENDPOINTS.DOCUMENTS_DIRECTORIES_CREATE,
      method: 'POST',
      payload
    });
  },

  deleteFolders(folderIds: string[]) {
    folderIds.forEach(folderId=>{
      return request({
        endpoint: ENDPOINTS.DOCUMENTS_DIRECTORIES_DELETE,
        method: 'DELETE',
        payload: {
          "folderPath": folderId
        },
      });
    })

  },

  deleteFiles(fileIds: string[]) {
    return request({
      endpoint: ENDPOINTS.DOCUMENTS_FILES_DELETE,
      method: 'DELETE',
      payload: {
        files:fileIds},
    });
  },

  renameFolder(folderId: string, name: string) {
    const dir = folderId.slice(0,folderId.lastIndexOf('/'));
    return request({
      endpoint: ENDPOINTS.DOCUMENTS_DIRECTORIES_RENAME,
      method: 'PUT',
      payload: {
        "folderPath": folderId,
        "renameFolderPath": dir+"/"+name
      },
    });
  },

  renameFile(fileId: string, name: string) {
    return request({
      endpoint: ENDPOINTS.DOCUMENTS_FILES_RENAME,
      method: 'PUT',
      payload: {
        renameFilePath: fileId,
        "renameFileName": name
      },
    });
  }
};

export default methods
