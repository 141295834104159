import {createSelector} from 'reselect';

import {UploadedFilesDictionary, UploadedFile, FileCategory} from '../../types';

import {AppState} from '..';

const getSelectCompany = (state: AppState) =>
    state.companies.selected.data?.InternalCustomer.customerFiles
const getSelectCompanyAgreements = (state: AppState) =>
    state.companies.selected.data?.InternalCustomer.agreementFiles

export const getCompanyDocuments = createSelector(
  [getSelectCompany],
  (companyFiles) => {
    const files: UploadedFilesDictionary = {};

    if (!companyFiles) {
      return files;
    }

    companyFiles.forEach((file: UploadedFile) => {
      if (file.fileCategory === FileCategory.DOCUMENT) {
        files[file.id] = file;
      }
    });

    return files;
  }
);

export const getCompanyResellerAgreements = createSelector(
  [getSelectCompanyAgreements],
  (companyFiles) => {
    const files: UploadedFilesDictionary = {};

    if (!companyFiles) {
      return files;
    }

    companyFiles.forEach((file: UploadedFile) => {
      if (file.fileCategory === FileCategory.RESALE_TERMS) {
        files[file.id] = file;
      }
    });

    return files;
  }
);
