import React, { createContext, useCallback, useEffect, useRef, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { useHistory } from 'react-router';
import useObject from '../../../hooks/useObject';
import { useQuery } from '../../../lib/hooks';
import { AppState } from '../../../store';
import { getProductsIncremental, getProductsManufacturers, getRecommendedProducts } from '../../../store/products'
import { MergedArticleProduct } from '../../../store/products/types';
import { List } from '../../../store/types';

const connector = connect(
	(state: AppState) => ({
	  	products: state.products.list,
	  	recommendedProducts: state.products.recommended,
		manufacturers: state.products.manufacturers
	}),
	{ getProductsIncremental, getProductsManufacturers, getRecommendedProducts }
)
  
type EShopProductsProviderProps = ConnectedProps<typeof connector> & {
	children: React.ReactNode
}

export type NextProductsFunc = (obj?: { 
	offset?: number,
	limit?: number,
	searchCategoryId?: string,
	reset?: boolean,
	manufacturer?: string,
	searchTerm?: string
}) => void

interface ContextEShopProductsProvider {
	filter?: string
	searchTerm?: string
	setSearchTerm?: (term: string) => void
	resetProductsFilters?: () => void
	products?: List<MergedArticleProduct>
	nextProducts?: NextProductsFunc
	isLoading?: boolean
	isSearching?: boolean
	hasMore?: boolean
	recommendedProducts?: List<MergedArticleProduct>
	manufacturers?: List<string>
	modalParams?: { 
		filter: string
		search: string
		manufacturer: string
	}
	setModalParams?: (val: Partial<{
		filter: string;
		search: string;
		manufacturer: string;
	}>) => void
}

export const contextEShopProducts = createContext<ContextEShopProductsProvider>({})

export const numberOfProductsToFetchAtATime = 50

const getOffset = (numberOfProducts: number) => Math.ceil((numberOfProducts/numberOfProductsToFetchAtATime))*numberOfProductsToFetchAtATime

const EShopProductsProvider = ({
	products,
	getProductsIncremental,

	recommendedProducts: _recommendedProducts,
	getRecommendedProducts,

	manufacturers,
	getProductsManufacturers,

	children
}: EShopProductsProviderProps) => {
	const modal = !window.location.href.includes('e-shop')
	const query = useQuery()
	const history = useHistory()
	const paramFilter = query.get('filter') ?? undefined
	const paramSearch = query.get('search') ?? undefined
	const paramManufacturer = !modal ? query.get('manufacturer') ?? undefined : undefined
	const [ modalParams, , { add: setModalParams } ] = useObject({ filter: '', search: '', manufacturer: '' })
	const recommendedProducts = (paramFilter ?? modalParams.filter) === 'recommended' ? _recommendedProducts : undefined
	const [currentCategory, setCurrentCategory] = useState("")
	const previousProductFetch = useRef<{ limit: number, offset: number, textSearch?: string, searchCategoryId?: string, manufacturer?: string }>({ 
		limit: NaN, offset: NaN, textSearch: undefined, searchCategoryId: undefined, manufacturer: undefined
	})
	// needed to differentiate the initial loading from search loading
	const hasSearched = useRef(false)

	useEffect(() => { 
		if(paramFilter !== 'recommended') return
		getRecommendedProducts()
	}, [getRecommendedProducts, paramFilter])

	useEffect(() => { getProductsManufacturers() }, [ getProductsManufacturers ])

	useEffect(() => {
		if(products.data.length) return
		hasSearched.current = false
	}, [products.data])
	const handleSetSearchTerm = (term: string) => {
		hasSearched.current ||= true
		query.set('search', term)
		modal && setModalParams({ search: term })
		!modal && history.push(`?${query}`)
		nextProducts({ offset: 0, reset: true, searchTerm: term })
	}

	const resetProductsFilters = () => {
		query.delete('filter')
		query.delete('search')
		query.delete('manufacturer')
		modal && setModalParams({ filter: '', search: '', manufacturer: '' })
	}

	const [hasProductsReset, setHasProductsReset] = useState(false)
	useEffect(() => {
		if(!hasProductsReset) return
		setHasProductsReset(false)
	}, [products.data])

	const nextProducts: NextProductsFunc = useCallback((
		{ 
		offset = getOffset(products.data.length), 
		limit = numberOfProductsToFetchAtATime, 
		reset, 
		searchCategoryId = reset ? "" : currentCategory, 
		manufacturer, 
		searchTerm
	} = {}) => { 
		if(products.loading)
			return
		
		manufacturer ??= (paramManufacturer ?? modalParams.manufacturer)
		searchTerm ??= (paramSearch ?? modalParams.search)

		if(
			previousProductFetch.current.limit === limit &&
			previousProductFetch.current.offset === offset &&
			previousProductFetch.current.textSearch === searchTerm &&
			previousProductFetch.current.searchCategoryId === searchCategoryId &&
			previousProductFetch.current.manufacturer === manufacturer
		) return
		
		setCurrentCategory(searchCategoryId)

		previousProductFetch.current = {limit,offset,searchCategoryId, textSearch: searchTerm, manufacturer}
		
		reset && setHasProductsReset(true)

		getProductsIncremental({
			listMeta: {
				filters: { limit, offset },
				textSearch: searchTerm,
				manufacturer,
				searchCategoryId,
			},
			previousProducts: !reset ? products.data : []
		})
	}, [paramManufacturer, paramSearch, products.loading, modalParams])
	
	/** Initial fetch for products */
	useEffect(() => {
		nextProducts({ offset: 0, limit: numberOfProductsToFetchAtATime, reset: true })
	}, [])

	const isLoading = !!products?.loading && hasProductsReset
	
	const isSearching = isLoading && hasSearched.current

	const hasMore = (!!products && (products.data.length < +products.total)) || isLoading
	
	return (
		<contextEShopProducts.Provider value={{
			filter: !modal ? paramFilter : modalParams.filter,
			searchTerm: !modal ? paramSearch : modalParams.search,
			setSearchTerm: handleSetSearchTerm,
			resetProductsFilters,
			products: { ...products, data: !hasProductsReset ? products.data : [] },
			manufacturers,
			nextProducts,
			isLoading,
			isSearching,
			hasMore,
			recommendedProducts,
			modalParams,
			setModalParams,
		}}>
			{children}
		</contextEShopProducts.Provider>
	)
}

export default connector(EShopProductsProvider)
