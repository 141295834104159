// TODO: refactor, don't import this
import {ACCESS_MODULE_MAP} from '../router/paths';
import {UserType, Module, AccessModules} from '../store/users/types';

export const INHERIT_MODULES = 'INHERIT_MODULES';

export type RequiredPermission = {
  module: RequiredModule;
  accountType: UserType[] | null;
};

export type AccessModuleMap = {[k: string]: RequiredPermission};

export type RequiredModule =
  | null
  | typeof INHERIT_MODULES
  | Module
  | Module[]
  | undefined;

export function hasEveryModule(
  accessModules: AccessModules,
  module: Module | Module[]
) {
  if (typeof module === 'string') {
    return accessModules[module] === true;
  }

  return module.every((s) => accessModules[s] === true);
}

export function hasAccess(
  sessionUserType: UserType | null,
  accessModules: AccessModules,
  requiredPermission: RequiredPermission,
  childPath: string[] = []
): boolean {
	const {
		module: requiredModule,
		accountType: requiredAccountType,
	} = requiredPermission;
	
  if (requiredModule === undefined || requiredAccountType === undefined) {
    console.warn(
      'Undefined `requiredModule` or `requiredAccountType`',
      requiredPermission
    );

    return false;
  }

  if (requiredAccountType === null && requiredModule === null) {
    return true;
  }

  if (!hasNeededAccountType(sessionUserType, requiredAccountType)) {
    return false;
  }

  if (requiredModule === null) {
    return true;
  }

  if (requiredModule === INHERIT_MODULES) {
    return childPath.some((path) => {
      const requiredPermission = ACCESS_MODULE_MAP[path];

      if (requiredPermission.module === INHERIT_MODULES) {
        throw new Error('recursive inherit modules are not supported');
      }
      return hasAccess(sessionUserType, accessModules, requiredPermission);
    });
  }

  return hasEveryModule(accessModules, requiredModule);
}

export const hasNeededAccountType = (
  sessionUserType: UserType | null,
  accountType: UserType[] | null
) => {
  return (
    accountType === null ||
    (sessionUserType && accountType.includes(sessionUserType))
  );
};

export const isBackOfficeAccount = (
  sessionUserType: UserType | null | undefined
) =>
  sessionUserType === UserType.SuperAdmin || sessionUserType === UserType.Admin;

export const isSuperAdminAccount = (
  sessionUserType: UserType | null | undefined
) => sessionUserType === UserType.SuperAdmin;

export const isAdminAccount = (sessionUserType: UserType | null | undefined) =>
  sessionUserType === UserType.Admin;

export const isCustomerAccount = (
  sessionUserType: UserType | null | undefined
) => !isBackOfficeAccount(sessionUserType);

export const isMainAccount = (sessionUserType: UserType | null | undefined) =>
  sessionUserType === UserType.MainAccount;
