import {request} from '../lib/request';
import {ENDPOINTS} from '../endpoints';

const methods = {
  getComments(customerId:string) {
    return request({
      endpoint: ENDPOINTS.ACCOUNT_MANAGEMENT_READ_COMMENTARIES,
      method: 'GET',
      params: {customerId},
    });
  },

  createComment(customerId: string, payload: {comment:string}) {
    return request({
      endpoint: ENDPOINTS.ACCOUNT_MANAGEMENT_CREATE_COMMENTARY,
      method: 'POST',
      payload,
      params: {customerId}
    });
  },

  deleteComment(customerId: string, commentId: string) {
    return request({
      endpoint: ENDPOINTS.ACCOUNT_MANAGEMENT_DELETE_COMMENTARY,
      method: 'DELETE',
      params: {customerId, commentId}
    });
  },
};

export default methods
