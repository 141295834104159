import axiosInstance, { request } from '../lib/request';

import { ENDPOINTS } from '../endpoints';
import { CartCheckout, checkoutInformationReq } from '../store/checkout/types';
import { B2Cupload } from '../store/cart2/types';
import { generatePath } from 'react-router-dom';


const methods = {
  getCart() {
    return request({
      endpoint: ENDPOINTS.E_SHOP_FRONT_CART_READ,
      method: 'GET'
    });
  },
  addToCart(internalArticleId: string, quantity: number) {
    return request({
      endpoint: ENDPOINTS.E_SHOP_FRONT_CART_ADD_PRODUCT,
      method: 'GET',
      params: { internalArticleId },
      query: {
        quantity: quantity,
      },
    });
  },
  addToCartB2C(b2cUpload: B2Cupload) {
    const formData = new FormData();
    formData.append('file', b2cUpload.file, "b2cfile");
    return axiosInstance.post(
      generatePath(ENDPOINTS.E_SHOP_FRONT_UPLOAD_B2C, {
      }),
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  },
  removeFromCart(productIdentifier: string, quantity: number) {
    return request({
      endpoint: ENDPOINTS.E_SHOP_FRONT_CART_DELETE_PRODUCT,
      method: 'GET',
      params: { productIdentifier },
      query: {
        quantity: quantity,
      },
    });
  },
  checkout(checkout: CartCheckout) {
    //Missing some stuff that needs to be mappend
    return request({
      endpoint: ENDPOINTS.E_SHOP_FRONT_CART_CHECKOUT,
      method: 'POST',
      payload: checkout
    });
  },

  destroyCart(){
    return request({
      endpoint: ENDPOINTS.E_SHOP_FRONT_CART_DESTROY,
      method: 'DELETE',
    });
  },

  getCheckoutInformation() {
    return request({
      endpoint: ENDPOINTS.E_SHOP_FRONT_CHECKOUT_INFORMATION,
      method: 'GET'
    });
  },
  
  updateCheckoutInformation(checkout: checkoutInformationReq) {
    return request({
      endpoint: ENDPOINTS.E_SHOP_FRONT_CHECKOUT_INFORMATION,
      method: 'PUT',
      payload: checkout
    });
  },

  getWayOfDelivery() {
    return request({
      endpoint: ENDPOINTS.CHECKOUT_WAY_OF_DELIVERY,
      method: 'GET'
    });
  },
}

export default methods
