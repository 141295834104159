import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {RouteProps, RouteComponentProps, useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import Icon from '@mdi/react';
import {mdiFace} from '@mdi/js';
import { mdiChevronUp } from '@mdi/js';
import classnames from 'classnames';
import {
  PATH_CHANGE_PASSWORD,
  PATH_USER_PROFILE,
  PATH_GDPR_REPORT,
  PATH_HOME,
} from '../../router/paths';

import './Layout.scss';

import {ReactComponent as Logo} from './svgs/Solar_Supply_logo_-_EON.svg';
import Footer from '../Footer/Footer';

import {NavigationList} from '../../router/paths';

import {AppState} from '../../store';

import DropdownButton from '../DropdownButton';
import Loader from '../Loader';

import Nav from './Nav';
import AcceptGDPRModal from "../Account/AcceptGDPRModal";
import GenericErrorToaster from '../Error/GenericErrorToaster';
import Totop from '../ScrollToTop/ScrollToTop';
import { MessageBanner } from "../MessageBanner/MessageBanner";
import useVersion from '../../hooks/useVersion';

type ReactComponent =
  | React.ComponentType<RouteComponentProps<any>>
  | React.ComponentType<any>;

export type WithLayoutProps = {
  navigationList: NavigationList;
  navigationFallback: string;
  logout: () => void;
};

type LayoutProps = WithLayoutProps & {
  props?: any;
  Component: ReactComponent;
};

export default function Layout({
  Component,
  props,
  navigationList,
  navigationFallback,
  logout,
}: LayoutProps) {
  const {t} = useTranslation();
  const history = useHistory();
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const [GDPRModalVisible, setGDPRModalVisible] = useState(false);
  const { getVersionNumber, versionNumber } = useVersion();

  const {sessionUser, showLoader} = useSelector(
    (state: AppState) => state.system
  );

  function diff_years(dt2:Date, dt1:Date) {
    let diff =(dt2.getTime() - dt1.getTime()) / 1000;
    diff /= (60 * 60 * 24);
    return Math.abs(Math.round(diff/365.25));
  }

  useEffect(() => {
    if (versionNumber !== '') return
    getVersionNumber()
  }, [versionNumber, getVersionNumber])

  useEffect(() => {
    if (sessionUser) {
      const {acceptedGDPR} = sessionUser;
      const currentDate = new Date();
      const acceptedGDPRDate = new Date(acceptedGDPR);
      const diff = diff_years(currentDate, acceptedGDPRDate);
      if (diff >= 1 || !acceptedGDPR) {
        setGDPRModalVisible(true);
      }
    }
  }, [sessionUser]);

  const changePassword = () => {
    history.push(PATH_CHANGE_PASSWORD);
  };


  const editUserProfile = () => {
    history.push(PATH_USER_PROFILE);
  };
  const GDPRReport = () => {
    history.push(PATH_GDPR_REPORT);
  };



  return (
    <div className="layout">
        <Totop/>
      <header className="layout__header">
        <div className='layout__solar-logo'>
        <Logo
          data-testid="layout__logo"
          onClick={() => history.push(PATH_HOME)}
        />
        </div>
        <div className="layout__header__user navbar-light">
          <DropdownButton
            btnClassName="d-none d-lg-block align-items-center user-menu"
            menuClassName="w-100 left-0"
            id="user-name"
            label={
              <>
                <Icon path={mdiFace} size="34" className="icon-34" />
                <span className="layout__header__user__name">
                  {sessionUser?.userInformation?.name}
                </span>
              </>
            }
            dropdownList={[
              {text: t('common.Change password'), onClick: changePassword},
              {text: t('common.Profile'), onClick: editUserProfile},
              {text: t('common.GDPR report'), onClick: GDPRReport},
              {text: t('common.Log out'), onClick: logout},
            ]}
          />
          <button
            className="navbar-toggler collapsed"
            type="button"
            data-toggle="collapse"
            data-target="#navbarMainMenu"
            aria-controls="navbarMainMenu"
            aria-expanded={!isNavCollapsed ? true : false}
            aria-label="Toggle navigation"
          >
            <div className="animated-icon">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </button>
        </div>
      </header>

      <MessageBanner />

      <div className="layout__container">
        <Nav
            navigationList={navigationList}
            navigationFallback={navigationFallback}
            changePassword={changePassword}
            editUserProfile={editUserProfile}
            GDPRReport={GDPRReport}
            logout={logout}
            isNavCollapsed={isNavCollapsed}
            setIsNavCollapsed={setIsNavCollapsed}
        />
        <div className="container-fluid">
        <GenericErrorToaster />
          <main
              className={classnames('layout__main', {
                'us-none': showLoader,
              })}
          >
            <Component {...props} />
            <Loader fixed show={showLoader}/>
          </main>
        </div>

        <AcceptGDPRModal
            modalVisible={GDPRModalVisible}
            setModalVisible={setGDPRModalVisible}
        />
          
      </div> 
      <footer className="layout__footer" data-testid="layout__footer">
        <Footer versionNumber={versionNumber}/>  
      </footer>
        
    </div>
  );
}

export function withLayout(component: ReactComponent) {
  return ({
    navigationList,
    navigationFallback,
    logout,
    ...props
  }: WithLayoutProps & RouteProps) => (
    <Layout
      Component={component}
      props={props}
      navigationList={navigationList}
      navigationFallback={navigationFallback}
      logout={logout}
    />
  );
}
