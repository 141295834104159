import React from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from '../../components/Modal/ConfirmationModal';

export default function CaseManagementDeleteCaseModal(props: {
  modalVisible: boolean;
  setModalVisible: (f: boolean) => void;
  onConfirmation: () => void;
}) {
  const {t} = useTranslation();

  return (
    <ConfirmationModal
      title="Ta bort serviceärende?"
      message="Är du säker på att du vill ta bort serviceärende?"
      buttonMessage={t('common.Delete')}
      {...{
        ...props,
      }}
    />
  );
}
