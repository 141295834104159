import {
  CustomerUserInput,
  AdminUserInput,
  SuperAdminUserInput, CustomerUserEditInput, AccountInformation,
} from '../store/users/types';

import {generateEndpoint, request} from '../lib/request';

import {ENDPOINTS} from '../endpoints';

import {ListMeta} from './types';
import {DeepPartial} from "@reduxjs/toolkit";
import { UserClause } from '../store/users/types';

const methods = {
  createUser(customerId:string,
    payload: CustomerUserInput | SuperAdminUserInput | AdminUserInput
  ) {
    let userObj;

    if ('accessModules' in payload) {
      userObj = {
        userCredential: {
          email: payload.email,
          password: "~"
        },
        userInformation: {
          phoneNumber: payload.phoneNumber,
          name: payload.name,
          lastName: "...",
          address: "...",
          position: payload.position
        },
        userPermission: {
          userType: payload.userType,
          belongsToCustomer: payload.belongsToCompany,
          accessModules: payload.accessModules,
        }
      };
    }else{
      userObj = {
        userCredential: {
          email: payload.email,
          password: "~"
        },
        userInformation: {
          phoneNumber: payload.phoneNumber,
          name: payload.name,
          lastName: "...",
          address: "...",
          position: payload.position
        },
        userPermission: {
          userType: payload.userType,
          belongsToCustomer: payload.belongsToCompany,
        }
      };
    }

    return request({
      endpoint: ENDPOINTS.ACCOUNT_MANAGEMENT_CREATE_USER,
      method: 'POST',
      payload: userObj,
      params:{customerId}
    });
  },

  getUsers({page, filter, textSearch, limit, onlyAdmins}: ListMeta) {
    return request({
      endpoint: ENDPOINTS.ACCOUNT_MANAGEMENT_READ_USERS,
      method: 'GET',
      query: {
        onlyAdmins: onlyAdmins,
        _page: page,
        _limit: limit,
        ...(filter ? {status: filter} : null),
        ...(textSearch ? {q: textSearch} : null),
      },
    });
  },

  getUser(userId: string) {
    return request({
      endpoint: ENDPOINTS.ACCOUNT_MANAGEMENT_READ_SPECIFIC_USER,
      method: 'GET',
      params: {userId},
    });
  },

  // TODO: fix this after the fields are updated on backend
  updateUser(
    userId: string,
    userClause: UserClause,
    payload:
      | {
          Customer: Partial<CustomerUserEditInput>;
        }
      | {
          admin: Partial<AdminUserInput>;
        }
      | {
          superAdmin: Partial<SuperAdminUserInput>;
        }
  ) {
    let userObj;

    if ('Customer' in payload) {
      const {Customer} = payload;
      userObj = {
        userInformation:{
          phoneNumber: Customer.phoneNumber,
          name: Customer.name,
          position: Customer.position,
        },
        userPermission:{
          userType:Customer.userType,
          belongsToCompany: Customer.belongsToCompany
        },
        userCredential:{
          email: Customer.email
        }
      };
    } else if ('superAdmin' in payload) {
      const {superAdmin} = payload;
      userObj = {
        userCredential: {
          email: superAdmin.email,
        },
        userAssociation:{
          organizationNumber: "somenumberAgain"
        },
        userPermission:{
          userType:superAdmin.userType,
          belongsToCompany: superAdmin.belongsToCompany
        },
        userInformation: {
          name: superAdmin.name,
          phoneNumber: superAdmin.phoneNumber,
          lastName: '..',
          address: '..',
          position: superAdmin.position
        },
        userClause:{
          id: userClause.id,
          offerLimitation: userClause.offerLimitation,
          offerMinCoverage: userClause.offerMinCoverage,
        }
      };
    } else {
      const {admin} = payload;
      userObj = {
        userCredential: {
          email: admin.email,
        },
        userInformation: {
          name: admin.name,
          cellPhoneNumber: '..',
          lastName: '..',
          address: '..',
          position: admin.position
        },
        userPermission: {
          userType:admin.userType,
          belongsToCompany: admin.belongsToCompany,
          accessModules: admin.accessModules,
        },
        userClause:{
          id: userClause.id,
          offerLimitation: userClause.offerLimitation,
          offerMinCoverage: userClause.offerMinCoverage,
        }
      };
    }
    return request({
      endpoint: ENDPOINTS.ACCOUNT_MANAGEMENT_UPDATE_USER,
      method: 'PUT',
      params: {userId},
      payload: userObj,
    });
  },

  updateUserInformation(
      userId: string,
      payload:DeepPartial<AccountInformation>
  ) {
    return request({
      endpoint: ENDPOINTS.ACCOUNT_MANAGEMENT_UPDATE_PROFILE,
      method: 'PUT',
      params: {userId},
      payload: payload,
    });
  },

  deleteUser(userId: string) {
    return request({
      endpoint: ENDPOINTS.ACCOUNT_MANAGEMENT_DELETE_USER,
      method: 'DELETE',
      params: {userId},
    });
  },

  changePassword(password: string) {
    return request({
      endpoint: ENDPOINTS.ACCOUNT_MANAGEMENT_USER_CHANGE_PASSWORD,
      method: 'PUT',
      payload:{
        password
      }
    });
  },

  inactivateUser(id: string) {
    return request({
      endpoint: '/users/:id',
      method: 'PATCH',
      params: {id},
    });
  },

  activationEmail(id: string) {
    return request({
      endpoint: ENDPOINTS.ACCOUNT_MANAGEMENT_RESEND_EMAIL,
      method: 'GET',
      params: {id},
    });
  },

  generateGDPRFileDownloadLink(userId: string) {
    return generateEndpoint(ENDPOINTS.GDPR_REPORT, {
      userId
    });
  },

  getCurrentUserExtended() {
    return request({
      endpoint: '/auth/info/extended',
      method: 'GET',
    });
  },
}

export default methods
