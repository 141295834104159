import React, { useEffect, useState } from 'react';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';
import useUsers from '../../hooks/useUsers';
import Modal from '../Modal';
import InputField from '../Form/InputField';
import { CircularProgress } from '@material-ui/core';

export type PickedUser = {name: string};

export default function OrderUserPickerModal({
  modalVisible,
  setModalVisible,
  onSave,
  itemId,
  loader,
}: {
  modalVisible: boolean;
  setModalVisible: (f: boolean) => void;
  onSave: (inputs: PickedUser) => void;
  itemId?: string;
  loader: boolean;
}) {
  const {t} = useTranslation();
  const {getUsers, users} = useUsers();
  const [selectedUser, setSelectedUser] = useState<string>("");
  const [searchUser, setSearchUser] = useState<string>("")
  
  useEffect(() => {
    if(modalVisible)
      resetStates()
  },[modalVisible, loader])

  const resetStates = () => {
    setSelectedUser("")
    setSearchUser("")
  }

  useEffect(() =>{
    if(users !== undefined) return;

    getUsers({page: 1,filter: "", textSearch: "", limit: 200, onlyAdmins: 1})
  },[getUsers, users])

  const onSearchChange = (e:any)=> {
    setSearchUser(e.target.value);
  }

  const filteredItems = users?.filter(user => (
		!searchUser 
		|| user.userCredential?.email?.toLowerCase().includes(searchUser.toLowerCase())
		|| user.userPermission?.userType?.toLowerCase().includes(searchUser.toLowerCase())
		|| user.userInformation?.position?.toLowerCase().includes(searchUser.toLocaleLowerCase())
		|| user.userInformation?.phoneNumber?.toLowerCase().includes(searchUser.toLocaleLowerCase())
		|| user.userPermission?.resolvedCustomerName?.toLocaleLowerCase().includes(searchUser.toLocaleLowerCase())
		|| user.userInformation?.name?.toLocaleLowerCase().includes(searchUser.toLocaleLowerCase()) 
    ))

  const handleInput = useForm<PickedUser>({mode: 'onChange'});
  return (
    <Modal
      name="crate-new-folder"
      modalVisible={modalVisible}
      setModalVisible={setModalVisible}
      title="Sök användare"
      dialogClassName="modal-mw-small modal-dialog-centered"
    >
      <InputField
        name="searchField"
        placeholder="Sök användare"
        onChange={onSearchChange}
        className="mb-4"
        value={searchUser}
      />
      <div className="d-flex justify-content-center">
        <CircularProgress hidden={!loader} style={{color: "#ef7b10"}} />
      </div>
      <form onSubmit={handleInput.handleSubmit(onSave)}>
        <div className="form-group w-100">
          <div className="card">
              <ul className="list-group list-group-flush">
                {searchUser.length > 2 && filteredItems?.map((user, index) => {
                  return(
                      <span className="list-group-item" 
                        key={index} 
                        onClick={() => setSelectedUser(JSON.stringify({id: user.id, name: user.userInformation?.name+ " " + user.userInformation?.lastName, itemId: itemId }))}
                        style={{cursor: "pointer", alignContent: "middle"}}
                      >
                        <span className="btn-link" >
                          <span className="font-weight-bold">
                            {user.userInformation?.name} ({user.userCredential?.email})
                          </span>
                        </span>
                        <button type="submit" className="btn btn-primary btn-small float-right">
                          {t('common.Add')}
                        </button>
                      </span>
                    )
                  })}

              </ul>
          </div>
        </div>

        <div hidden>
          <InputField
            name="selectedUser"
            handler={handleInput}
            rules={{required: true}}
            placeholder={t('common.Folder name')}
            value={selectedUser}
          />
        </div>

          <div className="float-right mt-7">
            <button
              type="button"
              className="btn btn-info mr-2"
              data-dismiss="modal"
            >
              {t('common.Cancel')}
            </button>
          </div>
        </form>
    </Modal>
  );
}
