import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    entityPending,
    entityFulfilled,
    entityRejected,
  } from '../utils';

  import {UIComponent, ComponentsState} from './types';

  const name = 'components';

  export const toggleToast = createAsyncThunk<UIComponent, {shouldToggle: boolean, message: string, status: number}>(
    `eon-ui/toggleToast`,
    async function ({shouldToggle, message, status}) {
      return {
          toggleToast: shouldToggle,
          message: message,
          status: status
      } as UIComponent;
    }
  );

  export const initialState: ComponentsState = {
    list: {
      data: [],
      loading: false,
      requestId: '',
      total: 0,
    },
    selected: {
      data: null,
      loading: false,
      requestId: '',
    }
  };

  const componentsSlice = createSlice({
    name,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder.addCase(toggleToast.pending, (state, action) => {
        entityPending(state.selected, action);
      });
      builder.addCase(toggleToast.fulfilled, (state, action) => {
        entityFulfilled(state.selected, action);
      });
      builder.addCase(toggleToast.rejected, (state, action) => {
        entityRejected(state.selected, action);
      });
    },
  });
  
  export default componentsSlice.reducer;
  
  