import React, {useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useHistory, useParams} from 'react-router-dom';

import auth from '../../services/auth';

import {PATH_AUTH_LOGIN} from '../../router/paths';

import {AppState} from '../../store';
import {setShowLoader} from '../../store/system';

import NewPasswordForm, {
  NewPasswordFormInput,
} from '../../components/Auth/NewPasswordForm';

const connector = connect((state: AppState) => ({}), {setShowLoader});

type NewPasswordProps = ConnectedProps<typeof connector> & {};

function NewPassword({setShowLoader}: NewPasswordProps) {
  const {t} = useTranslation();
  const history = useHistory();
  const {token} = useParams<{token:string}>();

  const handleInput = useForm<NewPasswordFormInput>({
    validateCriteriaMode: 'all',
    mode: 'onChange',
  });

  const {
    formState: {isSubmitting},
  } = handleInput;

  const onSubmit = async (inputs: NewPasswordFormInput) => {
    try {
      await auth.createNewPassword({token, password: inputs.password});

      history.push(PATH_AUTH_LOGIN);
    } catch (e) {
      /* istanbul ignore else */
      if (e.isAxiosError) {
        const {response} = e
        const {data: {message}} = response
        handleInput.setError('passwordAgain', message);
      } else {
        console.error(e);
      }
    }
  };

  useEffect(() => {
    setShowLoader(isSubmitting);
  }, [isSubmitting, setShowLoader]);

  return <NewPasswordForm handleInput={handleInput} onSubmit={onSubmit} />;
}

export default connector(NewPassword);
