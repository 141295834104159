import React, {useState, useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';

import auth from '../../services/auth';

import {PATH_AUTH_LOGIN} from '../../router/paths';

import {AppState} from '../../store';
import {setShowLoader} from '../../store/system';

import {DEFAULT_RULES} from '../../lib/form';

import InputField from '../../components/Form/InputField';

const connector = connect((state: AppState) => ({}), {setShowLoader});

type RequestPasswordProps = ConnectedProps<typeof connector> & {};
type RequestPasswordInput = {
  email: string;
};

function RequestPassword({setShowLoader}: RequestPasswordProps) {
  const {t} = useTranslation();
  const history = useHistory();
  const [emailSentTo, setEmailSentTo] = useState<null | string>(null);
  const handleInput = useForm<RequestPasswordInput>({mode: 'onChange'});
  const {handleSubmit} = handleInput;

  const {
    formState: {isSubmitting},
  } = handleInput;

  const onSubmit = async (inputs: RequestPasswordInput) => {
    try {
      await auth.requestNewPassword(inputs.email);

      setEmailSentTo(inputs.email);
    } catch (e) {
      /* istanbul ignore else */
      if (e.isAxiosError) {
        handleInput.setError('email', t('validation.E-mail address not found'));
      } else {
        console.error(e);
      }
    }
  };

  useEffect(() => {
    setShowLoader(isSubmitting);
  }, [isSubmitting, setShowLoader]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <legend className="primary">{t('common.Forgot password')}</legend>
      {!emailSentTo && (
        <InputField
          name="email"
          handler={handleInput}
          label={t('common.E-mail address')}
          rules={{
            required: true,
            minLength: DEFAULT_RULES.email.minLength,
            pattern: DEFAULT_RULES.email.pattern,
          }}
        />
      )}
      {emailSentTo && (
        <p data-testid="success_message">
          <strong className="headline">
            {t('common.Forgot password E-mail sent.Headline')}
          </strong>
          {t('common.Forgot password E-mail sent.Recipient', {
            email: emailSentTo,
          })}
          <br />
          {t('common.Forgot password E-mail sent.Instruction')}
        </p>
      )}
      <div className="clearfix">
        <div className="float-right mt-7">
          {emailSentTo ? (
            <button
              key="back_to_login"
              className="btn btn-primary "
              type="button"
              onClick={() => history.push(PATH_AUTH_LOGIN)}
              data-testid="back_to_login"
            >
              {t('common.Back to login')}
            </button>
          ) : (
            <button
              key="reset_password"
              type="submit"
              className="btn btn-primary"
              data-testid="reset_password"
            >
              {t('common.Reset password')}
            </button>
          )}
        </div>
      </div>
    </form>
  );
}

export default connector(RequestPassword);
