import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { User } from "../../store/users/types";
import InputField from "../Form/InputField";


export default function UserPicker({
    Users,
    setSelectedUserId
  }: {
    Users: User[]
    setSelectedUserId: (f: string) => void;
  }): JSX.Element {
    const {t} = useTranslation();
    const [searchUser, setSearchUser] = useState<string>("")
    const [User, setUser] = useState<User>();

    useEffect(() => {
        setSelectedUserId("")
        setSearchUser("")
        setUser(undefined)
      },[])

    const onSearchChange = (e:any)=> {
        setSearchUser(e.target.value);
      }

      const setValues =(user: User) => {
        setUser(user)
        setSelectedUserId(user.id)
      }

      const filteredItems = Users?.filter(user => (
		!searchUser 
		|| user.userCredential?.email?.toLowerCase().includes(searchUser.toLowerCase())
		|| user.userPermission?.userType?.toLowerCase().includes(searchUser.toLowerCase())
		|| user.userInformation?.position?.toLowerCase().includes(searchUser.toLocaleLowerCase())
		|| user.userInformation?.phoneNumber?.toLowerCase().includes(searchUser.toLocaleLowerCase())
		|| user.userPermission?.resolvedCustomerName?.toLocaleLowerCase().includes(searchUser.toLocaleLowerCase())
		|| user.userInformation?.name?.toLocaleLowerCase().includes(searchUser.toLocaleLowerCase()) 
    ))

    return (
        <>
        {!User?.id && (
            <>
                <InputField
                    name="searchField"
                    placeholder="Sök användare"
                    onChange={onSearchChange}
                    className="mb-4"
                    value={searchUser}
                />
            
                <div className="card">
                    <ul className="list-group list-group-flush">
                    {searchUser.length > 2 && filteredItems?.map((user, index) => {
                        return(
                            <span className="list-group-item" 
                                key={index} 
                                onClick={() => setValues(user)}
                                style={{cursor: "pointer", alignContent: "middle"}}
                                >
                                <p className="btn-link mb-0" >
                                    <span className="font-weight-bold">
                                        <p className="mb-0">{user.userInformation?.name} ({user.userCredential?.email})</p>
                                        <p className="mb-0">{user?.userPermission?.resolvedCustomerName}</p>
                                    </span>
                                </p>
                            </span>
                        )
                        })}

                    </ul>
                </div>
                </>
            )}
            <div>
                {User?.id &&
                <>
                    <p style={{fontWeight: 800}}>{t('offer.Selected user')}</p>
                    <span className="list-group-item" 
                        style={{alignContent: "middle"}}
                        >
                        <p className="btn-link mb-0">
                            <span className="font-weight-bold">
                                <p className="mb-0">{User?.userInformation?.name} ({User?.userCredential?.email})</p>
                                <p className="mb-0">{User?.userPermission?.resolvedCustomerName}</p>
                            </span>
                        </p>
                    </span>
                </>
                }
                
            </div>
        </>
    )
}