import { Badge, Box, makeStyles } from '@material-ui/core'
import { green } from '@material-ui/core/colors';
import React from 'react'
import { useTranslation } from 'react-i18next';
import formatTime from '../../../lib/timeHelper'

interface StockTextWithCircleProps {
	noneStockProduct?: boolean
	stockAmount?: number
	inDeliveryOfStock?: any
	inStock?: boolean
}

const useStyles = makeStyles(()=>({
	successBadge: {
		backgroundColor: green[500]
	},
	warningBadge: {
		backgroundColor: '#ffc107'
	}
}))

const StockTextWithCircle: React.FC<StockTextWithCircleProps> = ({ 
	noneStockProduct, 
	stockAmount = 0,
	inDeliveryOfStock,
	inStock
}) => {
	const {t} = useTranslation();
	const classes = useStyles()
	const max =  
		stockAmount >= 1000 ? 1000 
		: stockAmount >= 500 ? 500
		: stockAmount >= 200 ? 200
		: stockAmount >= 100 ? 100
		: stockAmount >= 50 ? 50
		: stockAmount >= 10 ? 10
		: stockAmount
		
	return noneStockProduct ?
		<>
			{t('Eshop.Order item')}
			<Badge classes={{ badge: classes.warningBadge }} variant="dot" overlap="circle">&nbsp;&nbsp;</Badge>
		</>
	: stockAmount > 0 ?
		<>
			{t('Eshop.In stock')}: {max >= 10 && '+'}
			<Badge classes={{ badge: classes.successBadge }} variant="dot" overlap="circle">{max}&nbsp;&nbsp;&nbsp;</Badge>
		</>
	:
		<>
			{t('Eshop.Temporary sold out')}
			<Badge color="error" variant="dot" overlap="circle">&nbsp;&nbsp;</Badge>
			{ inDeliveryOfStock &&
				<Box style={{ opacity: .9, transform: 'scale(.9)', transformOrigin: 'left' }}>{t('Eshop.Expected delivery')}: {formatTime(inDeliveryOfStock)}</Box>
			}
		</>
}

export default StockTextWithCircle
