import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {FormContextValues} from 'react-hook-form';

import InputField from '../../components/Form/InputField';
import {
  PriceGroupInputNew,
  Row,
  PriceGroup,
} from '../../store/priceGroups/types';
import {v4 as uuid} from 'uuid';

import PriceGroupFormRow from './PriceGroupFormRow';
import {useHistory} from 'react-router-dom';
import { MergedArticleProduct } from '../../store/products/types';

const formColumnClass = 'col-md-12 col-lg-6';

type PriceGroupFormProps = {
  handleInput: FormContextValues<PriceGroupInputNew>;
  articles: MergedArticleProduct[]
  priceGroup: PriceGroup | null
};

export type MultipleInputRow = {
  value: string;
  handleInput: FormContextValues<PriceGroupInputNew>;
};

export function PriceGroupForm({
  articles,
  priceGroup,
  handleInput,
}: PriceGroupFormProps) {
  const {t} = useTranslation();
  const history = useHistory();

  const [row, setRow] = useState<Row[]>([]);
  const [disableSave] = useState(false);
  const [refresh, setRefresh] = useState(0);


  useEffect(() => {
    if (
      priceGroup !== null &&
      priceGroup !== undefined &&
      history.location.pathname !== '/e-shop/manage/price-groups/create'
    ) {
      if (
        priceGroup.priceGroupArticles !== null &&
        priceGroup.priceGroupArticles !== undefined
      ) {
        const rows: Row[] = priceGroup.priceGroupArticles.map((priceGroupArticle) => {
          return {
            id: uuid().toString(),
            percentage: priceGroupArticle.percentageDiscount,
            internalArticleId: priceGroupArticle.internalArticleId,
            productName:  priceGroupArticle.productName,
            fortnoxProductId: priceGroupArticle.fortnoxProductId,
            rowSelect: priceGroupArticle.fortnoxProductId,
            internalProductName: priceGroupArticle.productName
          };
        });
        const data: Partial<PriceGroupInputNew> = {
          name: priceGroup.name,
          row: rows,
        };
        setRow(rows);
        setRefresh(refresh + 1)
        handleInput.reset({...data});  
      }
    }
  }, [articles, priceGroup]);

  useEffect(() => {
    if (history.location.pathname !== '/e-shop/manage/price-groups/create' && row.length > 0) {
      const rows: Row[] = row.map((rowArticle) => {
        return {
          id: uuid().toString(),
          percentage: rowArticle.percentage,
          internalArticleId: rowArticle.internalArticleId,
          productName:  rowArticle.productName,
          fortnoxProductId: rowArticle.fortnoxProductId,
          rowSelect: rowArticle.fortnoxProductId,
          internalProductName: rowArticle.productName
        };
      });
      const data: Partial<PriceGroupInputNew> = {
        name: priceGroup?.name,
        row: rows,
      };
      setRow(rows);
      handleInput.reset({...data});
    }
    
  }, [refresh]);

  const addNewRow = () => {
    let ob = {
      id: uuid().toString(),
      percentage: "",
      internalArticleId: "",
      productName:  "",
      fortnoxProductId: "",
      rowSelect: ""
    } as Row;
    row.push(ob);
    setRow(row);
    setRefresh(refresh + 1);
  };

  const removeIndex = (index: string) => {
    setRow(row.filter((r) => r.id !== index));
    setRefresh(refresh + 1);
  };


  return (
    <>
      <div className="form-row">
        <InputField
          name="name"
          handler={handleInput}
          label={t('common.Name')}
          className={formColumnClass}
        />
      </div>
      {row.map((mr, key) => {
        return (
          <PriceGroupFormRow
            key={Object.freeze(mr.id)}
            value={mr.percentage}
            index={Object.freeze(mr.id)}
            id={Object.freeze(key)}
            removeIndex={removeIndex}
            handleInput={handleInput}
            articlesData={articles}
          />
        );
      })}
      <a href="#" onClick={() => addNewRow()}>
        {t('common.Add')}
      </a>
      <hr className="form-delimiter" />
      <button
        disabled={disableSave}
        type="submit"
        className="btn btn-primary mr-2"
        data-testid="submit__price_group_form"
      >
        {t('common.Save data')}
      </button>
      <button
        type="reset"
        className="btn btn-info"
        onClick={() => history.goBack()}
      >
        {t('common.Cancel')}
      </button>
    </>
  );
}

export default PriceGroupForm;
