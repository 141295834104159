import React, {useEffect, useState} from 'react'
import {connect, ConnectedProps} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {generatePath, useHistory} from 'react-router-dom'
import {AppState} from '../../store'
import {setShowLoader} from '../../store/system'
import {getProductCategories, deleteProductCategory} from '../../store/productCategories'

import Table from '../../components/Table'
import Pager from '../../components/Pager'
import ManageEShopTabNav from '../../components/EShop/ManageEShopTabNav'
import DropdownButton from '../../components/DropdownButton'
import {
    PATH_E_SHOP_CREATE_PRODUCT_CATEGORY,
    PATH_E_SHOP_EDIT_PRODUCT_CATEGORY,
    PATH_E_SHOP_MANAGE_PRODUCT_CATEGORIES_PRIORITY
} from '../../router/paths'
import {SecondaryCategory} from "../../store/productCategories/types"
import ConfirmationModal from "../../components/Modal/ConfirmationModal"

const connector = connect(
    (state: AppState) => ({
        productCategories: state.productCategories.list,
    }),
    {setShowLoader, getProductCategories, deleteProductCategory}
)

type ManageProductCategoriesProps = ConnectedProps<typeof connector> & {}

export function ManageProductCategories({
	productCategories: categories,
	setShowLoader,
	getProductCategories,
	deleteProductCategory
}: ManageProductCategoriesProps) {
    const history = useHistory()
    const {t} = useTranslation()

    const [itemToDelete, setItemToDelete] = useState<string>('')
    const [searchStr, setSearchStr] = useState('')
    const [confirmModalVisible, setConfirmModalVisible] = useState(false)

    const onSearchChange = (e: any) => setSearchStr(e.target.value)

    useEffect(() => {
        getProductCategories()
    }, [getProductCategories])

    useEffect(() => {
        setShowLoader(categories.loading)
    }, [categories.loading, setShowLoader])

    const createNewProductCategory = () => history.push(PATH_E_SHOP_CREATE_PRODUCT_CATEGORY)

    const EditProductCategory = (categoryId: string) => () =>
        history.push(generatePath(PATH_E_SHOP_EDIT_PRODUCT_CATEGORY, {categoryId}))
    

    const DeleteProductCategory = (categoryId: string) => () => {
        setItemToDelete(categoryId)
        setConfirmModalVisible(true)
    }

    const onConfirmation = async () => {
        await deleteProductCategory(itemToDelete)
        setConfirmModalVisible(false)
        getProductCategories()
    }

    const filteredItems = searchStr 
		? categories.data.filter(data => data.categoryName.toLowerCase().includes(searchStr.toLowerCase())) 
		: categories.data

    const getCategoryThirdsCount = (subCategory: SecondaryCategory[]) => 
		subCategory.reduce((p,{categoryThirds}) => p+=categoryThirds.length, 0)

    return (
        <div>
            <h1 data-testid="e-shop_product_management_headline">
                {t('navigation.E-shop__nav.Manage e-shop')}
            </h1>
            <ManageEShopTabNav/>
            <button className="btn btn-primary" onClick={() => history.push(PATH_E_SHOP_MANAGE_PRODUCT_CATEGORIES_PRIORITY)}>Ändra prioritet</button>
            <Table
                loading={categories.loading}
                newButton={{
                    onClick: createNewProductCategory,
                    text: t('common.Create new category'),
                }}
                searchField={{
                    name: 'productSearch',
                    text: t('common.Search product'),
                    onChange: onSearchChange,
                    value: searchStr,
                }}
                header={[
                    t('common.Main category'),
                    t('common.Secondary category'),
                    t('common.Subcategory'),
                    "",
                ]}
                row={(row) => (
                    <>
                        <td>{row.categoryName}</td>
                        <td>{row.categorySeconds.length} st</td>
                        <td>{getCategoryThirdsCount(row.categorySeconds)} st</td>
                        <td className="text-center">
                            <DropdownButton
                                id={`user__option__dropdown_${row.id}`}
                                label={t('common.Options')}
                                dropdownList={[
                                    {text: t('common.Edit'), onClick: EditProductCategory(row.id)},
                                    {
                                        text: t('common.Delete'),
                                        onClick: DeleteProductCategory(row.id),
                                        className: 'text-danger'
                                    },
                                ]}
                            />
                        </td>
                    </>
                )}
                list={filteredItems}
                extractKey={(el) => el.id}
            />

            <ConfirmationModal
                title={t('common.Delete category')}
                message={t('common.Are you sure you want to delete the selected category?')}
                buttonMessage={t('common.Delete')}
                onConfirmation={onConfirmation}
                setModalVisible={setConfirmModalVisible}
                modalVisible={confirmModalVisible}
            />
        </div>
    )
}

export default connector(ManageProductCategories)
