import React, {useEffect, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {useForm} from 'react-hook-form';

import {AppState} from '../../store';
import {setShowLoader} from '../../store/system';

import ChangePasswordForm, {
  ChangePasswordFormInput,
} from '../../components/Account/ChangePasswordForm';
import UserService from "../../services/users";

const connector = connect((state: AppState) => ({}), {setShowLoader});

type NewPasswordProps = ConnectedProps<typeof connector> & {};

function ChangePassword({setShowLoader}: NewPasswordProps) {

  const handleInput = useForm<ChangePasswordFormInput>({
    validateCriteriaMode: 'all',
    mode: 'onChange',
  });

  const {
    formState: {isSubmitting},
  } = handleInput;

  const [ feedback, setFeedback ] = useState<'error' | 'success' | undefined>()

  const onSubmit = async (inputs: ChangePasswordFormInput) => {
	setFeedback(undefined)
    const {password} = inputs;
    try {
      await UserService.changePassword(password);
      handleInput.reset();
	  setFeedback('success')
    } catch (e) {
      console.error(e);
	  setFeedback('error')
    }
  };

  useEffect(() => {
    setShowLoader(isSubmitting);
  }, [isSubmitting, setShowLoader]);

  return <ChangePasswordForm handleInput={handleInput} onSubmit={onSubmit} feedback={feedback} />;
}

export default connector(ChangePassword);
