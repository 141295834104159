import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import InputField from "../../../../components/Form/InputField";
import priceFormatter from "../../../../lib/priceFormatter";
import { OfferProduct } from "../../../../store/offers/types";
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { useSelector } from "react-redux";
import { AppState } from "../../../../store";

interface RowProps {
	product: OfferProduct,
	updateProduct: (params: {
		id: string;
		articleId: string;
		name: keyof OfferProduct;
		value: number;
	}) => void
	index: number;
	key: number;
	isEdit?: boolean
	isCreate?: boolean
	remove: (id: string) => unknown
	onDragStart:(e: any, id?: number) => void
	onDragEnter:(e: any, id?: number) => void
	onDragEnd:(e: any) => void
	currency?: string
}

const roundToTwoDecimals = (number: number) => Math.round(number * 100)/100

function OfferToolFormRow({
	product,
	updateProduct,
	index,
	isEdit,
	isCreate,
	remove,
	onDragStart,
	onDragEnter,
	onDragEnd,
	currency
}: RowProps) {
	var customerProduct = useSelector((state: AppState) => state.products.customerproducts.data.find(p => p.product.fnArticleId === product.fnArticleId))
	var purchasePrice = customerProduct?.product.productInformation.articlePrice.purchasePrice;

	const readOnly = !isEdit && !isCreate
	const name = `offerProducts[${index}]`
	const format = priceFormatter(currency ?? "SEK")

	return (
		<tr onDragStart={(e) => onDragStart(e, index)}
			onDragEnter={(e) => onDragEnter(e, index)}
			onDragEnd={(e) => onDragEnd(e)}
			key={index}
			draggable>
			<td style={{ width: 65 }}>{product.fnArticleId}</td>
			<td style={{ minWidth: 200, width: "40%"}}>{product.name} {product.disposableQuantity ? ` - ${product.disposableQuantity}st` : ''}</td>
			<td style={{ width: 65 }}>
				<TextField 
					variant="standard" 
					value={product.quantity >= 0 ? product.quantity : 0}
					disabled={readOnly}
					name={name+'.quantity'}								
					onChange={(e: any) => updateProduct(
						{ 
							id: product.id, 
							articleId: product.fnArticleId, 
							name: 'quantity', 
							value: parseInt(e.target.value) >= 0 ? parseInt(e.target.value) : 0
						})}
					InputProps={{style: { fontSize: '0.75rem'}}}
				/>
			</td>
			<td style={{ width: 100 }}>
				<TextField 
					variant="standard" 
					value={purchasePrice && purchasePrice >= 0 ? purchasePrice : 0}
					disabled={true}
					name={name+'.purchasePrice'}								
					onChange={(e: any) => updateProduct(
						{ 
							id: product.id, 
							articleId: product.fnArticleId, 
							name: 'purchasePrice', 
							value: purchasePrice && purchasePrice >= 0 ? purchasePrice : 0
						})}
					InputProps={{style: { fontSize: '0.75rem'}}}
				/>
			</td>
			<td style={{ width: 100 }}>
				<TextField 
					variant="standard" 
					value={product.priceEach >= 0 ? product.priceEach : 0}
					disabled={readOnly}
					name={name+'.priceEach'}								
					onChange={(e: any) => updateProduct(
						{ 
							id: product.id, 
							articleId: product.fnArticleId, 
							name: 'priceEach', 
							value: parseInt(e.target.value) >= 0 ? parseInt(e.target.value) : 0
						})}
					InputProps={{style: { fontSize: '0.75rem', width: '100%'}}}
				/>
			</td>
			<td style={{ width: 85 }}>
				<TextField 
					variant="standard" 
					value={product.discount >= 0 ? product.discount : 0}
					disabled={readOnly}
					name={name+'.discount'}								
					onChange={(e: any) => {
						let value = parseInt(e.target.value); 
						if(value < 0)   value = 0;
						if(value > 100) value = 100;

						updateProduct(
						{ 
							id: product.id, 
							articleId: product.fnArticleId, 
							name: 'discount', 
							value: value
						})
					}}
					InputProps={{style: { fontSize: '0.75rem'}}}
				/>
			</td>
			<td style={{ whiteSpace: 'nowrap', width: 100 }}>
				{format(product.totalPrice)}
			</td>
			{isNaN(product.tg)}
			<td style={{ whiteSpace: 'nowrap', width: 100 }}>
				{isNaN(product.tg) || !isFinite(product.tg) ? 0 : roundToTwoDecimals(product.tg)}
			</td>
			<td style={{ whiteSpace: 'nowrap', width: 100 }}>
				{format(product.marginPrice)}
			</td>
			{!readOnly &&
				<td>
					<IconButton
						aria-label="remove"
						title="remove product"
						size="small"
						onClick={() => remove?.(product.fnArticleId)}
						disabled={!product}
					>
						<Close fontSize="small" />
					</IconButton>
				</td>
			}
		</tr>
	)
}

export default OfferToolFormRow