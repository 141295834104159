import React, {useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {AppState} from '../../store';
import {getNewsList} from '../../store/news';

import Carousel from '../../components/Carousel';

const connector = connect(
  (state: AppState) => ({
    newsList: state.news.list,
  }),
  {getNewsList}
);

type HomeProps = ConnectedProps<typeof connector> & {};

export function Home({newsList, getNewsList}: HomeProps) {
  const {t} = useTranslation();

  const {data, loading} = newsList;

  const homeNews = data.filter((item)=>{
        return item.visibleOnHomepage === true && item.isActive === true
    })

  useEffect(() => {
    getNewsList({});
  }, [getNewsList]);

  return (
    <div>
      <h1 data-testid="home_headline">{t('navigation.Home')}</h1>
      <Carousel name="news" images={homeNews} loading={loading} />
    </div>
  );
}

export default connector(Home);
