import React, {useEffect} from 'react';
import {connect, ConnectedProps, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useForm} from "react-hook-form";

import {AppState} from '../../store';
import {setShowLoader} from '../../store/system';
import {getCustomer, updateCustomer} from '../../store/companies';
import { toggleToast } from '../../store/components';
import MainAccountCompanyForm, {MainAccountCompanyFormInput} from '../../components/Company/MainAccountCompanyForm';
import MainAccountManageCompanyTabNav from '../../components/Company/MainAccountManageCompanyTabNav';
import { Customer } from '../../store/companies/types';

const connector = connect(
    (state: AppState) => ({
        company: state.companies.selected
    }),
    {
        setShowLoader,
        getCustomer,
        updateCustomer,
        toggleToast
    }
);

type EditCompanyProps = ConnectedProps<typeof connector> & {};

export function EditCompanyMainAccount({
	company,
	setShowLoader,
	getCustomer,
	updateCustomer,
    toggleToast
}: EditCompanyProps) {
    const {t} = useTranslation();

    const {sessionUser} = useSelector(
        (state: AppState) => state.system
    );
    
    const companyId = sessionUser?.userPermission.belongsToCustomer;

    const {
        data: companyData,
        loading: companyLoading,
    } = company;
    const handleInput = useForm<MainAccountCompanyFormInput>({
        mode: 'onChange',
    });

    const {
        formState: {isSubmitting},
    } = handleInput;

    const showLoader = companyLoading || isSubmitting;
    useEffect(() => {
        if(companyId){
            getCustomer(companyId).catch((err) =>
                console.error('getCompany error', err)
            );
        }
    }, [companyId, getCustomer]);

    useEffect(() => {},[toggleToast])

    useEffect(() => {
        setShowLoader(showLoader);
    }, [showLoader, setShowLoader]);

    const onSubmit = ({
		Customer,
		InternalCustomer,
	}: MainAccountCompanyFormInput) => {
        const CountryCode = Customer.Country?.value
        if(companyId){
            updateCustomer({
                customerId: companyId,
                payload: {
                    Customer:{
                        CustomerNumber: companyData?.Customer.CustomerNumber,
                        Name:Customer.Name,
                        Email: Customer.Email,
                        OrganisationNumber: companyData?.Customer.OrganisationNumber,
                        CountryCode: CountryCode,
                        Address1: Customer.Address1,
                        Address2: Customer.Address2,
                        Phone1: Customer.Phone1,
                        Phone2: Customer.Phone2,
                        WWW: Customer.WWW,
                        ZipCode: Customer.ZipCode,
                        City: Customer.City,
						SalesAccount: Customer.SalesAccount
                    },
                    InternalCustomer: {
                        ourReferenceEmail: InternalCustomer.ourReferenceEmail,
                        ourReferencePhoneNumber: InternalCustomer.ourReferencePhoneNumber
                    },
                },
                callback: m => {
                    toggleToast({
                        shouldToggle: true,
                        message: m.message,
                        status: m.status
                    })
                }
            }).then(response => {
                let cust = response.payload as Customer

                if (cust.Customer){
                    let msg = t('common.Saved')
                    toggleToast({
                        shouldToggle: true,
                        message: msg,
                        status: 200
                    })
                }
            });
        }
    };


    return (
        <div>
            <h1 data-testid="edit_company_main_account_headline">
                {t('navigation.Customers__nav.Manage companies')}
            </h1>
            {
                companyId && <MainAccountManageCompanyTabNav companyId={companyId}/>
            }
            <form onSubmit={handleInput.handleSubmit(onSubmit)}>
                <MainAccountCompanyForm
                    handleInput={handleInput}
                    companyData={companyData}
                />

                <hr className="form-delimiter"/>

                <button
                    type="submit"
                    className="btn btn-primary mr-2"
                    data-testid="submit__company_form"
                >
                    {t('common.Save data')}
                </button>
            </form>
        </div>
    );
}

export default connector(EditCompanyMainAccount);
