import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import SelectField, { Option } from "../../../components/Form/SelectField";
import { ErrorResponseResolver } from "../../../lib/responseHelper";
import { PATH_CASES_BACK_OFFICE_LIST } from "../../../router/paths";
import { AppState } from "../../../store";
import { assignCaseToUser, getSpecificServiceCase } from '../../../store/cases';
import { ServiceCaseAssignForm, ServiceCaseStatus } from "../../../store/cases/types";
import { toggleToast } from '../../../store/components';
import { getActiveUser } from '../../../store/system';
import { getUsers } from '../../../store/users';
import { JocularUser, Module } from "../../../store/users/types";
import CaseManagementTabNav from './../CaseManagementTabNav';

const connector = connect(
    (state: AppState) => ({
        activeUser: state.system.sessionUser,
        users: state.users.list,
        specificCase: state.cases.selected,
    }),
    {
        getActiveUser, getSpecificServiceCase, getUsers, assignCaseToUser, toggleToast
    }
);

type EditCaseManagementAssignCaseProps = ConnectedProps<typeof connector> & {};

export function EditCaseManagementAssignCase({
    activeUser,
    users,
    specificCase,
    getActiveUser,
    getSpecificServiceCase,
    getUsers,
    assignCaseToUser,
    toggleToast
}: EditCaseManagementAssignCaseProps) {

    const { t } = useTranslation();
    const history = useHistory()
    const { serviceCaseId } = useParams() as {
        serviceCaseId: string;

    }
    const formColumnClass = 'col-md-3 col-lg-3';

    const [jocularUser, setJocaularUser] = useState<JocularUser>();
    const [searchUser, setSearchUser] = useState<string>("")
    const [searchUserOptionList, setSearchUserOptionList] = useState<Option[]>([])
    // const [statusOptionsData, setStatusOptionsData] = useState<Option[] | []>([]);

    const { data: specificCaseData, loading: specificCaseLoading } = specificCase;
    const { data: usersData, loading: usersDataLoading } = users;

    const handler = useForm<ServiceCaseAssignForm>({ mode: 'onChange' });
    const { reset } = handler;

    useEffect(() => {
        getSpecificServiceCase({ serviceCaseId: serviceCaseId })
    }, [getSpecificServiceCase, serviceCaseId])

    useEffect(() => {
        getUsers({});
    }, [getUsers])


    useEffect(() => {
        getActiveUser()
            .then(u => setJocaularUser(u.payload as JocularUser))
    }, [serviceCaseId, getActiveUser])

    const canEdit = activeUser?.userPermission?.accessModules?.find(am => am.module === Module.CASE_MANAGEMENT)
    
    const serviceCaseOptions = useMemo(
        () => [
            {
                label: "Pågående",
                value: ServiceCaseStatus.ONCOING
            },
            {
                label: "Ny",
                value: ServiceCaseStatus.NEW
            },
            {
                label: "Avslutad",
                value: ServiceCaseStatus.COMPLETED
            },
            {
                label: "Återkoppling krävs",
                value: ServiceCaseStatus.FEEDBACK_REQUIRED
            },
        ],
        [reset]
    );


    useEffect(() => {
        if (specificCaseData !== undefined || specificCaseData !== null) {
            const caseOption = serviceCaseOptions.find
                (val => val.value === specificCaseData?.serviceCaseStatus);
            if (caseOption !== undefined) {
                const usersDataOptions = usersData.map(u => {
                    return { label: u.userInformation?.name.concat(" - " + u.userCredential.email), value: u.id } as Option
                })
                const assignedUser = usersData.find(u => u.id === specificCaseData?.caseOwner)
                const assignedToUser = assignedUser
                    ? { label: assignedUser.userInformation?.name.concat(" - " + assignedUser.userCredential.email), value: assignedUser.id } as Option
                    : undefined
                setSearchUserOptionList(usersDataOptions)
                reset({
                    ...caseOption,
                    status: caseOption,
                    assignedTo: assignedToUser
                })
            }
        }
    }, [specificCaseData, serviceCaseOptions, usersData])


    useEffect(() => {
        const caseOption = serviceCaseOptions.find
            (val => val.value === specificCaseData?.serviceCaseStatus);
        let option: Option = {
            label: specificCaseData?.caseOwnerResolved ? specificCaseData?.caseOwnerResolved : "",
            value: specificCaseData?.caseOwner !== undefined ? specificCaseData?.caseOwner : ""
        }
        if (searchUser !== undefined) {
            const userMatch =
                usersData.filter(u => u.userInformation?.name?.toLocaleLowerCase().includes(searchUser.toLocaleLowerCase()) ||
                    u.userInformation?.email?.toLocaleLowerCase().includes(searchUser))
            if (userMatch !== undefined) {
                const options = userMatch.map(u => {
                    return { label: u.userInformation?.name.concat(" - " + u.userCredential?.email), value: u.id } as Option
                })
                setSearchUserOptionList(options)
                reset({
                    ...caseOption,
                    status: caseOption
                })
                return
            }
        }
        setSearchUserOptionList(usersData.map(u => {
            return { label: u.userInformation?.name.concat(" - " + u.userCredential?.email), value: u.id } as Option
        }))
        reset({
            ...caseOption,
            status: caseOption
        })

    }, [searchUser])


    const submit = async (data: any) => {
        const {assignedTo} = data
        try {
            await assignCaseToUser({
                userId: assignedTo?.value,
                serviceCaseId: serviceCaseId,
                serviceCaseStatus: data.status.value,
                callback: (errorResponseResolver: ErrorResponseResolver) => {
                    toggleToast({
                        message: errorResponseResolver.message,
                        status: errorResponseResolver.status,
                        shouldToggle: true
                    })
                }
            })
            history.push(PATH_CASES_BACK_OFFICE_LIST)
        } catch (e) {

        }
        
    }

    return (
        <>
            <h1 data-testid="manage_companies_headline">Ärendehantering</h1>
                    <CaseManagementTabNav
                        isEdit={true}
                        sessionUser={jocularUser}
                        serviceCaseId={serviceCaseId} 
					/>
                    <h4>Tilldela</h4>
                    <hr />
                    <form onSubmit={handler.handleSubmit(submit)}>
                        <div className="row">

                            <SelectField
                                name="status"
                                handler={handler}
                                label={"Status"}
                                placeholder={"Status"}
                                options={serviceCaseOptions}
                                className={"col-md-6 col-lg-6"}
                                rules={{ required: true }}
                            />

                            <SelectField
                                name="assignedTo"
                                handler={handler}
                                label={"Är Tilldelad"}
                                placeholder={"Tilldelad"}
                                options={searchUserOptionList}
                                isClearable={true}
                                isSearchable={true}
                                className={"col-md-6 col-lg-6"}
                            />

                        </div>

                        <hr />

                        {canEdit !== undefined ? 
                        <button
                            type="submit"
                            className="btn btn-primary mr-2"
                            data-testid="submit__company_form"
                        >
                            {t('common.Save data')}
                        </button>
                        : ""}
                        <button type="button" className="btn btn-info" onClick={() => history.push(PATH_CASES_BACK_OFFICE_LIST)} >
                            {t('common.Cancel')}
                        </button>
                    </form>
                </>
    );
}

export default connector(EditCaseManagementAssignCase);