import React from 'react';
import {useTranslation} from 'react-i18next';

import {
  PATH_CUSTOMERS_EDIT_COMPANY,
  PATH_CUSTOMERS_VIEW_COMPANY_USERS,
  PATH_CUSTOMERS_EDIT_COMPANY_DOCUMENTS,
  PATH_CUSTOMERS_COMPANY_COMMENTS,
} from '../../router/paths';

import TabNav from '../TabNav';

export default function ManageCompanyTabNav({companyId}: {companyId: string}) {
  const {t} = useTranslation();

  return (
    <>
      <TabNav
        className="mb-5 mt-5"
        linkClassName="px-2"
        links={[
          {
            path: PATH_CUSTOMERS_EDIT_COMPANY,
            params: {companyId},
            component: <>{t('common.Information')}</>,
          },
          {
            path: PATH_CUSTOMERS_EDIT_COMPANY_DOCUMENTS,
            params: {companyId},
            component: <>{t('common.Document')}</>,
          },
          {
            path: PATH_CUSTOMERS_COMPANY_COMMENTS,
            params: {companyId},
            component: <>{t('common.Comments')}</>,
          },
          {
            path: PATH_CUSTOMERS_VIEW_COMPANY_USERS,
            params: {companyId},
            component: <>{t('common.Users')}</>,
          },
        ]}
      />
      <div className="mb-2" />
    </>
  );
}
