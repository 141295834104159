import {AxiosError} from "axios";
import {PATH_AUTH_LOGIN} from "../router/paths";

export const onResponseError = (error: AxiosError): Promise<AxiosError> => {
    // Handle response errors
    handleResponseResponseStatusCode(error.response?.status ?? 0)
    return Promise.reject(error);
}

const handleResponseResponseStatusCode = (statusCode: number) => {
    if(statusCode === 504) {
        window.location.href = PATH_AUTH_LOGIN;
    }
}