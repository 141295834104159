import { FieldValues, FormContextValues } from "react-hook-form";
import TextField from "@mui/material/TextField";
import ControlledAutocomplete from "../../../../components/ControlledAutocomplete/ControlledAutocomplete";
import { useTranslation } from "react-i18next";

export type Option<T extends string = string> = {
    label: string;
    value: T;
};

interface OfferToolAddProductsProps {
    handler: FormContextValues<FieldValues>
    isEdit?: boolean
    isCreate?: boolean
    handleAddProduct: (opt: Option) => unknown
    addProductsReadOnly: boolean
    availableProductOptions: {
        label: string;
        value: string;
    }[]
}

function OfferToolAddProducts({
    handler,
    isEdit,
    isCreate,
    handleAddProduct,
    addProductsReadOnly,
    availableProductOptions
}: OfferToolAddProductsProps) {
    const { t } = useTranslation()
    
    return(
        <>
            <tr style={{borderBottom: "1px solid black"}}>
                <td colSpan={10} style={{ width: 'clamp(200px,20vw,600px)'}}>
                    {(isEdit || isCreate) &&
                        <ControlledAutocomplete
                            control={handler.control}
                            name="product"
                            options={availableProductOptions}
                            renderInput={params =>
                                <TextField
                                    {...params}
                                    variant="standard"
                                    size="small"
                                    style={{ width: '100%'}}
                                    InputLabelProps={{shrink: true}}
                                    label={t('offer.Add product')}
                                />
                            }
                            onChange={handleAddProduct}
                            disableClearable
                            readOnly={addProductsReadOnly}
                        />
                    }
                </td>
            </tr>
        </>
    )
}

export default OfferToolAddProducts