import { useState } from 'react'

interface UseBoolean {
	(initial?: any): [
		boolean,
		(value: boolean) => void,
		{
			toggle: () => void
			setTrue: () => void
			setFalse: () => void
			setIfOpposing: (value: boolean) => void
		}
	]
}

const useBoolean: UseBoolean = (initial)  => {
	const [is, _setIs] = useState<boolean>(Boolean(initial))
	const setIs = (value: any) => _setIs( Boolean(value) )

	const methods: ReturnType<UseBoolean>[2] = {
		toggle: () => setIs(!is),
		setTrue: () => setIs(true),
		setFalse: () => setIs(false),
		setIfOpposing: (value) => is !== value && _setIs(value)
	}

	return [ is, setIs, methods ]
}

export default useBoolean