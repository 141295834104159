import {useCallback, useEffect, useMemo, useState} from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { AppState } from '../../store';
import { getSpecificOrder, getSpecificOrders, addArticleToOrder, changeStatus } from '../../store/orders';
import { useParams, useHistory, Link } from 'react-router-dom';
import { setShowLoader } from '../../store/system';
import {PATH_ORDERS_LIST, PATH_ORDERS_VIEW_ORDER} from '../../router/paths';
import formatTime from '../../lib/timeHelper'
import { useTranslation } from 'react-i18next';
import './styles/edit-order.scss';
import { useForm } from "react-hook-form";
import { EditOrderRequestCartItem, OrderInputValues } from '../../store/orders/types';
import { useGetCountries } from '../../components/Form/SelectCountriesInput';
import { Option } from '../../components/Form/SelectField';
import EditOrderArticleRow from './EditOrderArticleRow';
import priceFormatter from '../../lib/priceFormatter';
import { UserType } from '../../store/users/types';
import CustomerCard from '../../components/Order/CustomerCard';
import OrderDetailsCard from '../../components/Order/OrderDetailsCard';
import DeliveryDetailsCard from '../../components/Order/DeliveryDetailsCard';
import DeliveryCard from '../../components/Order/DeliveryCard';
import { getWayOfDeliveryOptions } from '../../store/checkout';
import DeliveryInformationCard from '../../components/Order/DeliveryInformationCard';
import OtherInformationCard from '../../components/Order/OtherInformationCard';
import InternInformationCard from '../../components/Order/InternInformationCard';
import { toggleToast } from '../../store/components';
import InvalidateOrderConfirmation from './InvalidateOrderConfirmation';
import OrderExtrasRow from './OrderViewExtrasRow';
import {ArrowBack, ArrowForward} from "@material-ui/icons";
import Divider from '@mui/material/Divider';

const connector = connect(
    (state: AppState) => ({
			order: state.orders.selected,
			orders: state.orders.selectedOrders,
			sessionUser: state.system.sessionUser,
			deliveryOptions: state.checkouts.deliveryOptions,
    }),
    { getSpecificOrder, getSpecificOrders, setShowLoader, changeStatus, getWayOfDeliveryOptions, toggleToast }
);

type ViewOrderProps = ConnectedProps<typeof connector> & {};

export function ViewOrder({
    order,
	orders,
    setShowLoader,
    getSpecificOrder,
	getSpecificOrders,
	toggleToast,
	sessionUser,
	changeStatus,
	getWayOfDeliveryOptions,
	deliveryOptions
}: ViewOrderProps) {
	const { t } = useTranslation()
	const { orderId } = useParams<{ [key: string]: string }>();
	const history = useHistory()
	const { data, loading } = order;
	const showLoader = loading;
	const countries = useGetCountries();
	const [currency, setCurrency] = useState("")
	const [invalidateModalVisible, setInvalidateModalVisible] = useState(false)
	const [navigatedOrdersCount, setNavigatedOrdersCount] = useState(-1);

	useEffect(() => { getWayOfDeliveryOptions() }, [getWayOfDeliveryOptions])

	const typeOfOrder = useMemo(() => 
		!data ? undefined : data.headOrderId === null && data.partlyDelivery ? 'main' : typeof data.headOrderId === 'string' && data.partlyDelivery ? 'child' : undefined
	, [data])
	
	useEffect(() => {
		if(typeOfOrder !== 'main') return
		getSpecificOrders({orderId})
	}, [getSpecificOrders, orderId, typeOfOrder])
	
	const childOrder = useMemo(() =>
		orders.data.find(item => typeof item.headOrderId === 'string')
	, [orders.data, order])

	const handleInput = useForm<OrderInputValues>({
        mode: 'onChange',
    });

    const { reset } = handleInput;

    useEffect(() => {
        getSpecificOrder({ orderId })
    }, [getSpecificOrder, orderId])


    const CountryOptions = useMemo(
        () => countries.countries,
        [countries.countries]
    );

    useEffect(() => {
        if (data?.shopOrderCartItems != undefined) {
            if (data?.shopOrderCartItems.length >= 0) {
                let ob = data?.shopOrderCartItems.find(i => i.id != undefined)?.shopOrderPrice.currency
                setCurrency(ob !== undefined ? ob : "")
            }
        }
    }, [data])

    useEffect(() => {
        setShowLoader(loading);
    }, [showLoader, setShowLoader, loading])

	useEffect(() => {
		if (!data) return

		const rows: EditOrderRequestCartItem[] = data.shopOrderCartItems?.map((article) => ({
			quantity: article.quantity,
			articleId: article.articleId,
			fortnoxProductId: article.fortnoxProductId
		}))
		reset({
			...data,
			deliveryDate: data.deliveryDate ? new Date(formatTime(data.deliveryDate)) : undefined,
			preliminaryDeliveryDate: data.preliminaryDeliveryDate ? new Date(formatTime(data.preliminaryDeliveryDate)) : undefined,
			proposedDeliveryDate: data.proposedDeliveryDate ? new Date(formatTime(data.proposedDeliveryDate)) : undefined,
			deliveryCountryCode: CountryOptions.find(({ value }) => value === data.deliveryCountryCode),
			shopOrderCartItems: rows,
			wayOfDeliveryCode: deliveryOptions?.data?.find(({ value }) => value === data.wayOfDeliveryCode),
		})
	}, [data, reset, CountryOptions, deliveryOptions]);

	const navigateToOrder = useCallback((orderId: string | null | undefined) => {
		if(!orderId)
			return
		setNavigatedOrdersCount(prev => --prev)
		history.push(PATH_ORDERS_VIEW_ORDER.replace(':orderId', orderId))
	}, [])

	const userAdminType = sessionUser?.userPermission.userType  === UserType.Admin || sessionUser?.userPermission.userType  === UserType.SuperAdmin
	const userSuperAdminType = sessionUser?.userPermission.userType  === UserType.SuperAdmin

	const doChangeStatus = (status: string, orderId: string) => async () => {
		if (status !== '') {
			setInvalidateModalVisible(false)
			setShowLoader(true)

			await changeStatus({
				orderId,
				status,
				callback: v => {
					toggleToast({
						shouldToggle: true,
						message: v.message,
						status: v.status,
					})
				}
			})
		}
		setShowLoader(false)
	}

	const displayExtraCost = order.data?.otherCostText || order.data?.otherCostText2 || order.data?.otherCostText3 
							|| order.data?.otherCost || order.data?.otherCost2 || order.data?.otherCost3
							|| order.data?.discountText || order.data?.discountText2 || order.data?.discountText3
							|| order.data?.discountCost || order.data?.discountCost2 || order.data?.discountCost3 
							|| (order.data?.transportCost !== undefined && order.data?.transportCost > 0)

	return (
		<div className="row order-container">
			{data?.status === 'NEW' && <div className="col-12">
				<button
					onClick={() => navigateToOrder(data?.previousId)}
					disabled={!data?.previousId}
					type="button"
					className="btn btn-primary float-left ml-1 mb-5"><ArrowBack style={{ fontSize:12, fontWeight: 'bolder' }} /> {t('order.Previous')}
				</button>
				<button
					onClick={() => navigateToOrder(data?.nextId)}
					disabled={!data?.nextId}
					type="button"
					className="btn btn-primary float-right ml-1 mb-5"> {t('order.Next')} <ArrowForward style={{ fontSize:12, fontWeight: 'bolder' }} />
				</button>
			</div>}
			<div className="col-6 col-sm-6 col-lg-8">
				<h3 data-testid="view_order_headline">
					{t('common.View order')} {data?.orderId} {(!!data?.status) ? <>({t('common.' + data?.status ?? '')})</> : ""}
				</h3>
			</div>

			<form className="col-md-12 col-sm-12">
				<InternInformationCard
					handleInput={handleInput}
					userAdminType={userAdminType}
					readOnly={true}
					sessionUser={sessionUser}
				/>
				<CustomerCard
					userAdminType={userAdminType}
					data={data}
					readOnly={true}
				/>
				<OrderDetailsCard
					handleInput={handleInput}
					userAdminType={userAdminType}
					data={data}
					order={order}
					readOnly={true}
				/>
				<DeliveryDetailsCard
					handleInput={handleInput}
					userAdminType={userAdminType}
					data={data}
					order={order}
					CountryOptions={CountryOptions}
					readOnly={true}
				/>
				<DeliveryCard
					handleInput={handleInput}
					userAdminType={userAdminType}
					deliveryOptions={deliveryOptions.data as Option[]}
					order={order}
					readOnly={true}
				/>
				{data?.status !== 'NEW' &&
					<DeliveryInformationCard
						handleInput={handleInput}
						userAdminType={userAdminType}
						data={data}
						readOnly={true}
					/>
				}
				<OtherInformationCard
					handleInput={handleInput}
					userAdminType={userAdminType}
					readOnly={true}
				/>
				<div className="col-md-12 col-sm-12 mt-4">
					<table className="table">
						<thead>
							<tr>
								<th scope="col">{t('order.Item number')}</th>
								<th scope="col">{t('order.Product name')}</th>
								<th scope="col">{t('order.Quantity')}</th>
								{sessionUser?.userPermission.userType  === UserType.SuperAdmin &&
									<th scope="col">{t('order.Fortnox disposable')}</th>}
								{userAdminType &&
									<th scope="col">{t('order.Disposable qty')}</th>}
								<th scope="col">{t('order.Price item')}</th>
								<th scope="col">{t('order.Discount')}</th>
								<th scope="col">{t('order.Total price')}</th>
								<th scope="col">{t('order.Partial delivery')}</th>
								<th scope="col">{t('order.Submission date')}</th>
							</tr>
						</thead>
						<tbody>
							{data?.shopOrderCartItems.map((item, index) =>
								<tr key={index}>
									<EditOrderArticleRow
										index={index}
										handleInput={handleInput}
										currency={currency}
										cartItem={item}
										userAdminType={userAdminType}
										userSuperAdminType={userSuperAdminType}
									/>
								</tr>
							)}
							{displayExtraCost &&
								<OrderExtrasRow
								order={order} 
								currency={currency}
								userSuperAdminType={userSuperAdminType}
								isDeleteColumnVisible={false}
								userAdminType={userAdminType}
								/>
							}
						</tbody>
					</table>

					{typeOfOrder === 'child' && order.data?.headOrderId &&
						<h5><Link to={PATH_ORDERS_VIEW_ORDER.replace(':orderId', order.data?.headOrderId)}>Gå till huvudorder</Link></h5>
					}

					{typeOfOrder === 'main' && childOrder &&
						<>
							<h4 className="ml-1 mt-4">Delleverans, id: {childOrder.orderId}</h4>
							<table className="table">
								<thead>
									<tr>
										<th scope="col">{t('order.Item number')}</th>
										<th scope="col">{t('order.Product name')}</th>
										<th scope="col">{t('order.Quantity')}</th>
										{sessionUser?.userPermission.userType  === UserType.SuperAdmin &&
											<th scope="col">{t('order.Fortnox disposable')}</th>
										}
										<th scope="col">{t('order.Disposable qty')}</th>
										<th scope="col">{t('order.Price item')}</th>
										<th scope="col">{t('order.Discount')}</th>
										<th scope="col">{t('order.Total price')}</th>
										<th scope="col">{t('order.Partial delivery')}</th>
										<th scope="col">{t('order.Submission date')}</th>
									</tr>
								</thead>
								<tbody>
									{childOrder?.shopOrderCartItems.map((item, index) =>
										<tr key={index}>
											<EditOrderArticleRow
												index={index}
												handleInput={handleInput}
												currency={currency}
												cartItem={item}
												userAdminType={userAdminType}
												userSuperAdminType={userSuperAdminType}
											/>
										</tr>
									)}
								</tbody>
							</table>
							<h5><Link to={PATH_ORDERS_VIEW_ORDER.replace(':orderId', childOrder.id)}>Gå till delleverans</Link></h5>
						</>
					}
				</div>
				<div className='row justify-content-end mt-2'>
					<div className='col-2'>
						<span className="float-left mt-2">
							<h5 style={{color:"#ef7b10", fontWeight: 500}}>
								{t('order.Totalsum ex VAT')}:&nbsp;
							</h5>
							<h4 style={{color:"#ef7b10", fontWeight: 700}}>
								{priceFormatter(currency || 'SEK')(order.data?.shopOrderViewResolver.sum ?? 0)}
							</h4>
						</span>
					</div>

					<div className='col-2'>
						<span className="float-right mt-2">
							<h5 style={{color:"#ef7b10", fontWeight: 500}}>
								{t('order.Totalsum inc VAT')}:&nbsp;
							</h5>
							<h4 style={{color:"#ef7b10", fontWeight: 700}}>
								{priceFormatter(currency || 'SEK')(order.data?.shopOrderViewResolver.sumIncVAT ?? 0)}
							</h4>
						</span>
					</div>
				</div>

				<div className='col-md-12 col-sm-12 mt-2'>
					{ sessionUser?.userPermission.userType  === UserType.SuperAdmin
						&& order.data?.orderStatusInternal === 'DFDS_ORDER_CANCELLED'
							&& order.data.status !== 'CANCELED' &&
						<button
							type='button'
							className="btn btn-outline-primary float-right ml-3"
							onClick={() => setInvalidateModalVisible(true)}
							style={{ minWidth: 150 }}>
							{t('order.Invalidate')}
						</button>
					}
					<button type='reset' className="btn btn-primary float-right" onClick={() => history.go(navigatedOrdersCount)}>Gå tillbaka</button>
				</div>
			</form>

			<InvalidateOrderConfirmation
				modalVisible={invalidateModalVisible}
				onConfirmation={doChangeStatus('CANCELED', orderId)}
				setModalVisible={setInvalidateModalVisible}
			/>
		</div>
	)
}

export default connector(ViewOrder);