import React from 'react';
import {useTranslation} from 'react-i18next';

import {
    PATH_E_SHOP_MANAGE_PRODUCTS,
    PATH_E_SHOP_MANAGE_PRODUCT_CATEGORIES
} from '../../router/paths';

import TabNav from '../TabNav';

export default function ManageEShopTabNav() {
    const {t} = useTranslation();
    return (
        <TabNav
            links={[
                {
                    path: PATH_E_SHOP_MANAGE_PRODUCTS,
                    component: (
                        <>
                            {t('common.Products')}
                        </>
                    ),
                },
                {
                    path: PATH_E_SHOP_MANAGE_PRODUCT_CATEGORIES,
                    component: (
                        <>
                            {t('common.Categories')}
                        </>
                    ),
                }
            ]}
        />
    );
}
