import React, {useCallback, useEffect, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useHistory, generatePath, useParams} from 'react-router-dom';

import {PATH_CUSTOMERS_EDIT_USER} from '../../router/paths';

import {AppState} from '../../store';
import {setShowLoader} from '../../store/system';
import {getCompanyUsers, deleteUser} from '../../store/users';

import ManageCompanyTabNav from '../../components/Company/ManageCompanyTabNav';
import Table from '../../components/Table';
import Pager from '../../components/Pager';
import DropdownButton from '../../components/DropdownButton';
import DeleteUserConfirmationModal from "../../components/User/DeleteUserConfirmationModal";

const connector = connect(
    (state: AppState) => ({
        users: state.users.list,
    }),
    {setShowLoader, getCompanyUsers, deleteUser}
);

type EditUserProps = ConnectedProps<typeof connector> & {};

export function CompanyUsers({
                                 users,
                                 setShowLoader,
                                 getCompanyUsers,
                                 deleteUser,
                             }: EditUserProps) {
    const {companyId} = useParams<{[key:string]:string}>();
    const {t} = useTranslation();
    const history = useHistory();
    const [
        deleteUserConfirmationModalVisible,
        setDeleteUserConfirmationModalVisible,
    ] = useState(false);

    const [itemToDelete, setItemToDelete] = useState<string>('');

    const [searchStr, setSearchStr] = useState('');

    const onSearchChange = (e:React.FormEvent<HTMLInputElement>)=> {
        setSearchStr(e.currentTarget.value);
    }

    const {data,error, loading: listLoading} = users;

    const showLoader = listLoading;

    useEffect(() => {
        setShowLoader(showLoader);
    }, [showLoader, setShowLoader]);


    const editUser = useCallback(
        (id: string) => () => {
            history.push(generatePath(PATH_CUSTOMERS_EDIT_USER, {userId: id}));
        },
        [history]
    );

    const deleteUserHandler =  (id: string) => async () => {
        setDeleteUserConfirmationModalVisible(true)
        setItemToDelete(id)
    };

    const deleteItem = useCallback(async () => {
        await deleteUser(itemToDelete)
        getCompanyUsers({
            companyId,
            meta: {},
        });
        setDeleteUserConfirmationModalVisible(false);
    }, [itemToDelete,deleteUser,getCompanyUsers, companyId]);

    useEffect(() => {
        getCompanyUsers({
            companyId,
            meta: {},
        });
    }, [companyId, getCompanyUsers]);

    let filteredList = data.filter((data)=>
        !searchStr || (
			data.userInformation?.name.toLowerCase().includes(searchStr.toLowerCase())
            || data.userInformation?.phoneNumber.includes(searchStr.toLowerCase())
            || data.userCredential?.email.toLowerCase().includes(searchStr.toLowerCase())
            || data.userInformation?.position.toLowerCase().includes(searchStr.toLowerCase())
            || data.userPermission?.userType.toLowerCase().includes(searchStr.toLowerCase())
		)
    )

    if(error){
        filteredList = []
    }

    return (
        <div>
            <h1 data-testid="company_users_headline">
                {t('navigation.Customers__nav.Manage companies')}
            </h1>
            <ManageCompanyTabNav companyId={companyId}/>
            <Table
                title={t('common.Select users')}
                searchField={{
                    name: 'userSearch',
                    text: t('common.Search user'),
                    onChange: onSearchChange,
                    value: searchStr,
                }}
                header={[
                    t('common.Name'),
                    t('common.Phone number'),
                    t('common.E-mail address'),
                    t('common.Position'),
                    t('common.User type'),
                    {
                        key: 'search',
                        component: (
                            <Pager
                                className="float-right"
                                page={1}
                                totalItems={filteredList.length}
                                onPagination={()=>{}}
                            />
                        ),
                    },
                ]}
                row={(row) => (
                    <>
                        <td>{row.userInformation?.name}</td>
                        <td>{row.userInformation?.phoneNumber}</td>
                        <td>{row.userCredential?.email}</td>
                        <td>{row.userInformation?.position}</td>
                        <td>{t(`common.${row.userPermission?.userType}`)}</td>
                        <td className="text-right">
                            <DropdownButton
                                id={`user__option__dropdown_${row.id}`}
                                label={t('common.Options')}
                                dropdownList={[
                                    {text: t('common.Edit'), onClick: editUser(row.id)},
                                    {text: t('common.Delete'), className: 'text-danger', onClick: deleteUserHandler(row.id)},
                                ]}
                            />
                        </td>
                    </>
                )}
                list={filteredList}
                extractKey={(el) => `${el.id}`}
            />
            <DeleteUserConfirmationModal
                modalVisible={deleteUserConfirmationModalVisible}
                setModalVisible={setDeleteUserConfirmationModalVisible}
                onConfirmation={deleteItem}
            />
        </div>
    );
}

export default connector(CompanyUsers);
