import React from 'react'
import Cart from '../../components/EShop/Cart'
import Grid from '@material-ui/core/Grid/Grid'
import EShopMain from '../../components/EShop/Checkout/Main/EShopMain'
import EShopProvider from '../../components/EShop/Providers/EShopProvider'

const EShop = () => 
	<EShopProvider>
		<>
			<Grid container justify="space-between">
				<h1 className="border-0">Produkter</h1>
				<Cart />
			</Grid>

			<hr />

			<EShopMain />
		</>
	</EShopProvider>

export default EShop
