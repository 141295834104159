import axios, {AxiosRequestConfig, AxiosResponse, Method} from 'axios';
import {generatePath} from 'react-router-dom';
import {setupInterceptors} from "../interceptors/setupInterceptors";

const BACKEND_ENDPOINT = process.env.REACT_APP_API_URL;

export type RequestParams = {
  endpoint: string;
  method: Method;
  payload?: any;
  headers?: {[k: string]: string};
  query?: {[k: string]: any};
  params?: {[k: string]: string};
  axiosOptions?: AxiosRequestConfig;
};

const axiosInstance = axios.create({
  baseURL: BACKEND_ENDPOINT,
  timeout: 20000,
  withCredentials: true,
});

export default setupInterceptors(axiosInstance);

export function request({
  endpoint,
  method,
  payload,
  headers,
  query,
  params,
  axiosOptions,
}: RequestParams) {
  return axiosInstance({
    method,
    params: query,
    url: generatePath(endpoint, params),
    data: payload,
    headers: {
      ...headers,
    },
    ...axiosOptions,
  });
}

export function generateEndpoint(path: string, params?: {[k: string]: any}) {
  return generatePath(`${BACKEND_ENDPOINT}${path}`, params);
}

// {
//   "validationException": [
//       "@field{username} @reason{already exists}",
//       "@field{email} @reason{already exists}"
//   ]
// }
// {
//   validationException: [
//     '@field{customerNumber} @message{customerNumber is mandatory}',
//     '@field{email} @message{email is mandatory}',
//   ],
// }
const SERVER_VALIDATION_EXCEPTION_REGEX = /^@field{([\w]+?)} @(?:reason|message){(.+?)}$/;

export function parseValidationException(data: any) {
  const validationErrors: {[k: string]: string} = {};
  if (typeof data !== 'object' || data === null) {
    return validationErrors;
  }

  if (
    !('validationException' in data) ||
    !Array.isArray(data.validationException)
  ) {
    return validationErrors;
  }

  data.validationException.forEach((serverError: string) => {
    const error = String(serverError).match(SERVER_VALIDATION_EXCEPTION_REGEX);
    if (error) {
      const [, field, message] = error;
      validationErrors[field] = message;
    }
  });

  return validationErrors;
}

export const download = (props: AxiosResponse) => {
	const { data, status, headers } = props
	let filename = headers['content-disposition'] as string
	filename = filename.slice(filename.indexOf('filename="'), filename.length).replace('filename="','').replaceAll('"','')
	if(!filename) throw Error('Cannot resolve extension')
	if(status !== 200) return
	const url = window.URL.createObjectURL(new Blob([data]))
	const link = document.createElement('a')
	link.href = url
	link.setAttribute('download', filename)
	link.click()
}
