import {useEffect, useState} from 'react';
import versionService from '../services/versionNumber';
import { AxiosResponse } from 'axios';


const useVersion = () => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<string>()
    const [response, setResponse] = useState<AxiosResponse>()
    const [versionNumber, setVersionNumber] = useState('')

    useEffect(() =>{

    }, [response, setResponse])

    const getVersionNumber = async (): Promise<void> => {
        if (!loading) {
            setLoading(true)
            setError(undefined)
            try{
                let response = await versionService.getVersionNumber();

                if(response?.data)
                    setVersionNumber(response?.data)
            }catch (e: any) {
                setError(e)
                setResponse({statusText: "Something whent wrong", status: 400} as AxiosResponse)
            } finally {
                setLoading(false)
            }
        }
    }

    return {
        loading,
        getVersionNumber,
        versionNumber,
        error,
        response,
      }

}

export default useVersion