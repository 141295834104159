import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { connect, ConnectedProps } from "react-redux";
import { useHistory } from "react-router";
import { PATH_OFFERS_OFFER_TOOL_EDIT } from "../../../router/paths";
import { AppState } from "../../../store"
import { createOffer } from "../../../store/offers";
import { getCustomerProducts, resetCustomerProducts } from "../../../store/products";
import OfferToolForm, { OfferToolFormProps } from "./OfferToolForm/OfferToolForm";
import offerService from '../../../services/offers';
import { download } from "../../../lib/request";
import { resolveOfferPayload } from "./common";
import { getUser } from '../../../store/users';
import { User, UserType } from '../../../store/users/types';
import { getCustomer, resetCustomer } from '../../../store/companies';
import { Offer } from "../../../store/offers/types";
import { toggleToast } from '../../../store/components'
import { useTranslation } from "react-i18next";
import {setShowLoader} from '../../../store/system';
import { useGetCountries } from "../../../components/Form/SelectCountriesInput";
import UserPickerModal from "../../../components/User/UserPickerModal";
import Icon from "@mdi/react";
import { mdiPencil } from "@mdi/js";
import companiesService from "../../../services/companies";
import { Option } from "../../../components/Form/SelectField"

const connector = connect(
	(state: AppState) => ({
		sessionUser: state.system.sessionUser,
		userType: state.system.sessionUserType,
		customerproducts: state.products.customerproducts,
		customer: state.companies.selected
	}),
	{ createOffer, getUser, resetCustomer, toggleToast, getCustomerProducts, resetCustomerProducts, setShowLoader, getCustomer}
);

interface CreateOfferProps extends ConnectedProps<typeof connector> {
	
}

const CreateOffer = ({
	createOffer,
	getUser,
	customer,
	getCustomer,
	sessionUser,
	userType,
	resetCustomer,
	toggleToast,
	getCustomerProducts,
	resetCustomerProducts,
	setShowLoader,
	customerproducts,
}: CreateOfferProps) => {
	const history = useHistory()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => { return () => {resetCustomer(); resetCustomerProducts()} }, [])

	const { t } = useTranslation()
	const [ user, setuser ] = useState<User>(sessionUser as User)
	const [ customerId, setCustomerId ] = useState<string>()
	const [userPickerModalVisible, setUserPickerModalVisible] = useState(false);
	const [pickedUser, setPickedUser] = useState<User>();

	useEffect(() => { 
		handler.setValue("informationText", t('offer.defaultInformationText'));
		setPickedUser(undefined)
		setCustomerId(undefined)
		let usr = sessionUser as User
		getUser(usr.id).then(result => {
			setuser(result.payload as User)
		})
		if(userType === UserType.MainAccount || userType === UserType.SubAccount) {
			if(user.userPermission.belongsToCustomer){
				setCustomerId(user?.userPermission?.belongsToCustomer)
				return
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	const handler = useForm()
	const countries = useGetCountries();
	const CountryOptions = useMemo(
        () => countries.countries,
        [countries.countries]
    );

	const [deliveryOptions, setDeliveryOptions] = useState<Option[]>([])

	useEffect(() => {
		companiesService.getWayOfDeliveries().then((wayOfDeliveries) => {
			const { data } = wayOfDeliveries;
			let wayOfDeliveriesOptionsList: Option[] = [];
			data.WayOfDeliveries.forEach((wayOfDeliveries: any) => {
				wayOfDeliveriesOptionsList.push({ label: wayOfDeliveries.Description, value: `${wayOfDeliveries.Code}` })
			})
			setDeliveryOptions(wayOfDeliveriesOptionsList)
		});
	}, [])
	
	const setPickedUserModal = (user: User) => {
		if(user?.userPermission?.belongsToCustomer === undefined) return
		
		setPickedUser(user)

		if(user?.userPermission?.belongsToCustomer)
			setCustomerId(user?.userPermission?.belongsToCustomer)
	}

	useEffect(() => {
		if(!customerId) return
		getCustomer(customerId)
		getCustomerProducts({customerId})
		handler.setValue("customer", customerId, false)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getCustomer, customerId])

	useEffect(() => {
		if(!customer.data || pickedUser === undefined) return

		handler.setValue('yourOrderNumber', customer?.data?.Customer.YourOrderNumber)
		handler.setValue('ourReference', customer?.data?.Customer.OurReference)
		handler.setValue('yourReference', customer?.data?.Customer.YourReference)
		handler.setValue('deliveryCustomerName', customer?.data?.Customer.Name)
		handler.setValue('deliveryAddress', customer?.data?.Customer.Address1)
		handler.setValue('deliveryPostalNo', customer?.data?.Customer.ZipCode)
		handler.setValue('deliveryCity', customer?.data?.Customer.City)
		handler.setValue('deliveryCountryCode', CountryOptions.find(({ value }) => value === customer.data?.Customer.CountryCode))
		handler.setValue('deliveryPhoneNumber', customer?.data?.Customer.Phone1)

		if(pickedUser?.userCredential.email !== undefined)
			handler.setValue('deliveryEmail', pickedUser?.userCredential.email)
		if(pickedUser?.id !== undefined)
			handler.setValue('deliveryCustomerId', pickedUser?.id)
	}, [customer, pickedUser])

	const handleSubmit: OfferToolFormProps['onSubmit'] = useCallback((fieldValues, type) => {
		if(!sessionUser || !customer.data) return
		const data: Parameters<typeof createOffer>[0] = {
			...resolveOfferPayload({
				sessionUser,
				customerId: pickedUser?.userPermission?.belongsToCustomer ?? customer.data.InternalCustomer.id,
				fieldValues,
				OfferId: NaN
			}),
			callback: v => {
				toggleToast({
					shouldToggle: true,
					message: v.message,
					status: v.status,
				})
			},
		}
		type === 'save' && createOffer(data).then(c=> {	
			let savedData = c.payload as Offer
			if(savedData?.id){
				localStorage.setItem("type", "")
				history.push(PATH_OFFERS_OFFER_TOOL_EDIT.replace(':offerId', savedData?.id + '')) //redirect you to edit mode
				let msg = t('common.Saved')
				toggleToast({
					shouldToggle: true,
					message: msg,
					status: 200,
				})
			}
		})

		type === 'preview' && offerService.generatePdf(data).then(download)
	}, [createOffer, history, pickedUser, sessionUser, t, toggleToast, customer.data])

	const showLoader = (customerproducts.loading);

	useEffect(() => {
        setShowLoader(showLoader);
    }, [showLoader, setShowLoader]);

	return (
		<OfferToolForm 
			isCreate 
			handler={handler} 
			onSubmit={handleSubmit} 
			actionDisabled={!sessionUser || !customer.data}
			userType={userType??undefined}
			user={user}
			countries={CountryOptions}
			onImageChange={() => {}}
			currency={customer.data?.Customer.Currency}
			deliveryOptions={deliveryOptions}
		>
			<div className="form-row mb-2" style={{ gap: '1rem' }}>
				<div style={{display: userType !== UserType.MainAccount && userType !== UserType.SubAccount ? "block" : "none" }}>
					<UserPickerModal 
						modalVisible={userPickerModalVisible}
						setModalVisible={setUserPickerModalVisible} 
						loader={false}		
						setUser={setPickedUserModal}
					/>
					<div>
						<label>
							{t('offer.Assigned')}:
							<span
								style={{cursor: "pointer", alignContent: "middle"}} onClick={() => setUserPickerModalVisible(true)}>
								<Icon
									path={mdiPencil}
									size="18"
									color="black"
									title="edit"
									className="icon-18 ml-4"
									style={{maxHeight: "18px"}}
								/>
							</span>
						</label>
						<div>
							<div className="card p-2" style={{minHeight: "44px", minWidth: "300px"}}>
								<p style={{color: "#ef7b10"}} className="m-0">
									{pickedUser?.userPermission?.resolvedCustomerName} {pickedUser?.userInformation?.name ? " - " + pickedUser?.userInformation?.name : ""}
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</OfferToolForm>
	)
}

export default connector(CreateOffer) 