import Divider from '@mui/material/Divider';
import {  useState } from "react";
import { FieldValues, FormContextValues } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { OfferProduct } from "../../../../store/offers/types";
import OfferToolAddProducts from './OfferToolAddProducts';
import OfferToolFormDiscount from "./OfferToolFormDiscount";
import OfferToolFormExtraCost from "./OfferToolFormExtraCost";
import OfferToolFormRow from "./OfferToolFormRow";

export type Option<T extends string = string> = {
    label: string;
    value: T;
  };

interface OfferToolTableProps {
    handleUpdateProduct: (params: {
        id: string;
        articleId: string;
        name: keyof OfferProduct;
        value: number;
    }) => void,
    handleRemoveProduct: (id: string) => unknown
    handleSetValues: (params: {
      otherCost1?: number,
      otherCost2?: number,
      otherCost3?: number,
      discountCost?: number,
		  discountCost2?: number,
		  discountCost3?: number,
	  }) => void
    HandleDragEnd: (params: {
        row: number,
		dropIndex: number,
	}) => void
    Products: OfferProduct[]
    readOnly: boolean
    handler: FormContextValues<FieldValues>
    values:{
		discountCost: number,
		discountCost2: number,
		discountCost3: number,
    otherCost1: number,
    otherCost2: number,
    otherCost3: number
	}
    isEdit?: boolean
    isCreate?: boolean
    currency?: string
    availableProductOptions: {
        label: string;
        value: string;
    }[]
    handleAddProduct: (opt: Option) => unknown
    addProductsReadOnly: boolean
}

function OfferToolTable({
    handleUpdateProduct,
    handleRemoveProduct,
    Products,
    readOnly,
    handler,
    values,
    isEdit,
    isCreate,
    handleSetValues,
    HandleDragEnd,
    currency,
    availableProductOptions,
    handleAddProduct,
    addProductsReadOnly
}: OfferToolTableProps) {
    const { t } = useTranslation()
    const DragState = {
		row: -1,
		dropIndex: -1,
	  };

	  const handleOnRowDragStart = (
		e: any,
		tableDataId?: number
	  ) => {
		if (tableDataId !== undefined) {
		  e.currentTarget.classList.add('draggingRow');
		  DragState.row = tableDataId;
		}
	  };

      const handleOnRowDragEnter = (
        e: any,
        tableDataId?: number
      ) => {
        if (tableDataId === undefined) {
          return;
        }
    
        e.preventDefault();
        
        const tableBodyElement = e.currentTarget.parentElement;
        
        if (
          tableBodyElement &&
          DragState.dropIndex !== tableDataId &&
          DragState.row !== tableDataId
        ) {
          DragState.dropIndex = tableDataId;

          const allRows =
            tableBodyElement.childNodes as NodeListOf<HTMLTableRowElement>;

            allRows.forEach((row, index) => {

                if (index === DragState.dropIndex) {
                        row.classList.add('dropArea');
                    
                }else{
                    row.classList.remove('dropArea');
                }
            });
        }
      };
    
      const handleOnRowDragEnd = async (e: any) => {
        e.currentTarget.classList.remove('draggingRow');
        
        const tableBodyElement = e.currentTarget.parentElement;

        if(tableBodyElement){
            const allRows =
            tableBodyElement.childNodes as NodeListOf<HTMLTableRowElement>;

            allRows.forEach((row, index)=> {
                row.classList.remove('dropArea');
            })
        }
       
        if (DragState.dropIndex !== -1 && DragState.dropIndex !== DragState.row) {
            HandleDragEnd(DragState)
        }
        DragState.row = -1;
        DragState.dropIndex = -1;
    };

  const [addDiscount1, setAddDiscount1] = useState<boolean>(false)
	const [addDiscount2, setAddDiscount2] = useState<boolean>(false)
	const [addDiscount3, setAddDiscount3] = useState<boolean>(false)
  const [otherCost1, setOtherCost1] = useState<boolean>(false)
  const [otherCost2, setOtherCost2] = useState<boolean>(false)
  const [otherCost3, setOtherCost3] = useState<boolean>(false)


    return (
        <div className="col-12 col-md-12 col-lg-12 offer-table">
            <div className="table-responsive-md">
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Artikelnr.</th>
                            <th scope="col">Produktnamn</th>
                            <th scope="col">Antal</th>
                            <th scope="col">Inköpspris</th>
                            <th scope="col">Pris</th>
                            <th scope="col">Rabatt (%)</th>
                            <th scope="col">Total pris</th>
                            <th scope="col">TG (%)</th>
                            <th scope="col">Marginal</th>
                            {!readOnly && <th scope="col" />}
                        </tr>
                    </thead>
                    <tbody>
                        {Products?.map((product, index) =>
                            <OfferToolFormRow
                                key={index}
                                index={index}
                                product={product}
                                updateProduct={handleUpdateProduct}
                                isEdit={isEdit}
                                isCreate={isCreate}
                                remove={handleRemoveProduct}
                                onDragStart={ handleOnRowDragStart}
                                onDragEnter={handleOnRowDragEnter}
                                onDragEnd={handleOnRowDragEnd}
                                currency={currency}
                            />
                        )}  

                        <OfferToolAddProducts 
                            handler={handler}
                            isEdit={isEdit}
                            isCreate={isCreate}
                            availableProductOptions={availableProductOptions}
                            handleAddProduct={handleAddProduct} 
                            addProductsReadOnly={addProductsReadOnly}                        
                        />

                        {/* Divider */}
                        <tr hidden={(!otherCost1 && !addDiscount1)}>
                            <td colSpan={10} style={{backgroundColor: '#f1f1f1', borderRadius: 0}}>
                                <Divider textAlign="left">{t('offer.Other')}</Divider>
                            </td>
                        </tr>
                        {/* Divider */}

                        <OfferToolFormExtraCost
                            handleSetOtherCost={handleSetValues}
                            handler={handler}
                            readOnly={readOnly}
                            values={values}
                            addOtherCost1={otherCost1}
                            setAddOtherCost1={setOtherCost1}
                            addOtherCost2={otherCost2}
                            setAddOtherCost2={setOtherCost2}
                            addOtherCost3={otherCost3}
                            setAddOtherCost3={setOtherCost3}
                        />

                        <OfferToolFormDiscount
                            handleSetDiscount={handleSetValues}
                            handler={handler}
                            readOnly={readOnly}
                            values={values}
                            addDiscount1={addDiscount1}
                            setAddDiscount1={setAddDiscount1}
                            addDiscount2={addDiscount2}
                            setAddDiscount2={setAddDiscount2}
                            addDiscount3={addDiscount3}
                            setAddDiscount3={setAddDiscount3}
                        />
                         <tr >
                            <td colSpan={2} style={{border: "none"}}>
                                <div style={{color: "#ef7b10", cursor: "pointer", fontWeight: 700}} 
                                        onClick={()=> setAddDiscount1(true)}
                                        hidden={addDiscount1}>
                                    <p className="align-bottom">+ Lägg till avdrag</p>
                                </div>
                                <div style={{color: "#ef7b10", cursor: "pointer", fontWeight: 700}} 
                                        onClick={()=> setAddDiscount2(true)}
                                        hidden={!addDiscount1 || addDiscount2}>
                                    <p className="align-bottom">+ Lägg till avdrag</p>
                                </div>
                                <div style={{color: "#ef7b10", cursor: "pointer", fontWeight: 700}} 
                                        onClick={()=> setAddDiscount3(true)}
                                        hidden={!addDiscount2 || addDiscount3}>
                                    <p className="align-bottom">+ Lägg till avdrag</p>
                                </div>
                                <div style={{color: "#ef7b10", cursor: "pointer", fontWeight: 700}} 
                                        onClick={() => setOtherCost1(true)}
                                        hidden={otherCost1}>
                                    <p className="align-bottom">+ Lägg till rad</p>
                                </div>
                                <div style={{color: "#ef7b10", cursor: "pointer", fontWeight: 700}}
                                     onClick={() => setOtherCost2(true)}
                                     hidden={!otherCost1 || otherCost2}>
                                    <p className="align-bottom">+ Lägg till rad</p>
                                </div>
                                <div style={{color: "#ef7b10", cursor: "pointer", fontWeight: 700}}
                                     onClick={() => setOtherCost3(true)}
                                     hidden={!otherCost2 || otherCost3}>
                                    <p className="align-bottom">+ Lägg till rad</p>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    ) 
}

export default OfferToolTable
