import {useCallback, useEffect, useMemo, useState} from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { AppState } from '../../store';
import { getSpecificOrder, getSpecificOrders, addArticleToOrder, removeArticleFromOrder, changeStatus } from '../../store/orders';
import { useParams, useHistory, Link } from 'react-router-dom';
import { setShowLoader } from '../../store/system';
import {PATH_ORDERS_EDIT_ORDER, PATH_ORDERS_LIST, PATH_ORDERS_VIEW_ORDER} from '../../router/paths';
import formatTime from '../../lib/timeHelper'
import { useTranslation } from 'react-i18next';
import orderService from '../../services/orders'
import { useForm } from "react-hook-form";
import { EditOrderRequest, EditOrderRequestCartItem, OrderInputValues } from '../../store/orders/types';
import { useGetCountries } from '../../components/Form/SelectCountriesInput';
import { Option } from '../../components/Form/SelectField';
import EditOrderArticleRow from './EditOrderArticleRow';
import { AddArticleToOrder } from '../../components/Order/AddArticleToOrder';
import { ErrorResponseResolver } from '../../lib/responseHelper';
import { toggleToast } from '../../store/components';
import priceFormatter from '../../lib/priceFormatter';
import useBoolean from '../../hooks/useBoolean';
import Box from '@material-ui/core/Box/Box';
import Collapse from '@material-ui/core/Collapse/Collapse';
import Alert from '@material-ui/lab/Alert/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle/AlertTitle';
import './styles/edit-order.scss';
import { UserType } from '../../store/users/types';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton } from '@material-ui/core';
import OrderManagementChangeStatus from './OrderManagementChangeStatus';
import { getWayOfDeliveryOptions } from '../../store/checkout';
import InternInformationCard from '../../components/Order/InternInformationCard';
import CustomerCard from '../../components/Order/CustomerCard';
import OrderDetailsCard from '../../components/Order/OrderDetailsCard';
import DeliveryDetailsCard from '../../components/Order/DeliveryDetailsCard';
import DeliveryCard from '../../components/Order/DeliveryCard';
import OtherInformationCard from '../../components/Order/OtherInformationCard';
import DeliveryInformationCard from '../../components/Order/DeliveryInformationCard';
import OrderExtrasRow from './OrderViewExtrasRow';
import Icon from '@mdi/react';
import { mdiDeleteForeverOutline } from '@mdi/js';
import {getProductsWithoutFilters} from "../../store/products";
import {ArrowBack, ArrowForward} from "@material-ui/icons";
import Divider from '@mui/material/Divider';

const connector = connect(
    (state: AppState) => ({
			products: state.products.list,
			order: state.orders.selected,
			orders: state.orders.selectedOrders,
			sessionUser: state.system.sessionUser,
			sessionUserType: state.system.sessionUserType,
			deliveryOptions: state.checkouts.deliveryOptions,
    }),
    { getSpecificOrder, getSpecificOrders, setShowLoader, addArticleToOrder, removeArticleFromOrder, toggleToast, changeStatus, getWayOfDeliveryOptions, getProductsWithoutFilters}
);

type EditOrderProps = ConnectedProps<typeof connector> & {};

export function EditOrder({
    order,
	orders,
    setShowLoader,
	sessionUserType,
    getSpecificOrder,
	getSpecificOrders,
    toggleToast,
    addArticleToOrder,
	removeArticleFromOrder,
	sessionUser,
	changeStatus,
	getWayOfDeliveryOptions,
	deliveryOptions,
	products,
	getProductsWithoutFilters
}: EditOrderProps) {
	const { t } = useTranslation()
	const { orderId } = useParams<{ [key: string]: string }>();
	const history = useHistory()
	const { data, loading } = order;
	const showLoader = loading;
	const countries = useGetCountries();
	const [currency, setCurrency] = useState("")
	const [ notAllowedToSave, setNotAllowedToSave ] = useBoolean()
	const [navigatedOrdersCount, setNavigatedOrdersCount] = useState(-1);
	
	useEffect(() => { getWayOfDeliveryOptions() }, [getWayOfDeliveryOptions])

	useEffect(() => {
		getProductsWithoutFilters()
	}, [])

	const typeOfOrder = useMemo(() => 
		!data ? undefined : data.headOrderId === null && data.partlyDelivery ? 'main' : typeof data.headOrderId === 'string' && data.partlyDelivery ? 'child' : undefined
	, [data])

	useEffect(() => {
		if(typeOfOrder !== 'main') return
		getSpecificOrders({orderId})
	}, [getSpecificOrders, orderId, typeOfOrder])

	const childOrder = useMemo(() =>
		orders.data.find(item => typeof item.headOrderId === 'string')
	, [orders.data, order])

    const handleInput = useForm<OrderInputValues>({
        mode: 'onChange',
    });

    const { reset, setValue } = handleInput;
	// const [userPickerModalVisible, setUserPickerModalVisible,] = useState(false);

    useEffect(() => {
        getSpecificOrder({ orderId })
    }, [getSpecificOrder, orderId])


    const CountryOptions = useMemo(
        () => countries.countries,
        [countries.countries]
    );


    useEffect(() => {
        if (data?.shopOrderCartItems != undefined) {
            if (data?.shopOrderCartItems.length >= 0) {
                let ob = data?.shopOrderCartItems.find(i => i.id != undefined)?.shopOrderPrice.currency
                setCurrency(ob !== undefined ? ob : "")
            }
        }
    }, [data])

    useEffect(() => {
        setShowLoader(loading);
    }, [showLoader, setShowLoader, loading])

	const navigateToOrder = useCallback((orderId: string | null | undefined) => {
		if(!orderId)
			return
		setNavigatedOrdersCount(prev => --prev)
		history.push(PATH_ORDERS_EDIT_ORDER.replace(':orderId', orderId))
	}, [])

    const onSubmit = async (form: any) => {
			const formData = form as any
			
			if(!order.data?.partlyDelivery && formData.partlyDelivery === "Ja" && formData.shopOrderCartItems.every((item: EditOrderRequestCartItem) => !item.partlyDeliveryArticle)) {
				setNotAllowedToSave(true)
				return
			} else notAllowedToSave && setNotAllowedToSave(false)

			try {
				if (formData != null ) {
					setShowLoader(true)

					const optionCountryCode = formData.deliveryCountryCode as Option<string>
					const isPartlyDelivery = formData.partlyDelivery === "Ja" ? true : false
					const wayOfDeliveryCode = formData.wayOfDeliveryCode as Option<string>;
					
					const order = {
						preliminaryDeliveryDate: formData.preliminaryDeliveryDate,
						proposedDeliveryDate: formData.proposedDeliveryDate,
						deliveryAddress: formData.deliveryAddress,
						deliveryAddress2: formData.deliveryAddress2,
						customerDeliveryInformation: formData.customerDeliveryInformation,
						customerReferenceEmail: formData.customerReferenceEmail,
						deliveryCity: formData.deliveryCity,
						deliveryCountry: formData.deliveryCountry,
						deliveryCountryCode: optionCountryCode?.value,
						deliveryCustomerName: formData.deliveryCustomerName,
						deliveryPhone: formData.deliveryPhone,
						deliveryPostalNo: formData.deliveryPostalNo,
						driverDeliveryInstuction: formData.driverDeliveryInstuction,
						ourReference: formData.ourReference,
						orderComment: formData.orderComment,
						yourReference: formData.yourReference,
						yourOrderNumber: formData.yourOrderNumber,
						partlyDelivery: isPartlyDelivery,
						shopOrderCartItems: formData.shopOrderCartItems.map((v: any) => v.partlyDeliveryArticle ? { ...v, partlyDeliveryArticle: true } : v),
						assignedUserId: formData.assignedUserId,
						assignedUserName: formData.assignedUserName,
						internalComment: formData.internalComment,
						wareHouseDeliveryInformation: formData.wareHouseDeliveryInformation,
						wayOfDeliveryCode: wayOfDeliveryCode !== undefined ? wayOfDeliveryCode.value : "",
					} as EditOrderRequest
					await orderService.updateOrder(orderId, order)
					await getSpecificOrder({ orderId: orderId })
				}
		} catch(e: any) {
			toggleToast({
				shouldToggle: true,
				message: e.message,
				status: e.status,
			})
		}
		setShowLoader(false)
	}	

    useEffect(() => {
			if (!data) return

			const rows: EditOrderRequestCartItem[] = data.shopOrderCartItems?.map((article) => ({
				quantity: article.quantity,
				articleId: article.articleId,
				fortnoxProductId: article.fortnoxProductId
			}))
			setValue("preliminaryDeliveryDate", data.preliminaryDeliveryDate ? new Date(formatTime(data.preliminaryDeliveryDate)) : undefined);
			setValue("proposedDeliveryDate", data.proposedDeliveryDate ? new Date(formatTime(data.proposedDeliveryDate)) : undefined);
			
			reset({
				...data,
				deliveryDate: data.deliveryDate ? new Date(formatTime(data.deliveryDate)) : undefined,
				preliminaryDeliveryDate: data.preliminaryDeliveryDate ? new Date(formatTime(data.preliminaryDeliveryDate)) : undefined,
				proposedDeliveryDate: data.proposedDeliveryDate ? new Date(formatTime(data.proposedDeliveryDate)) : undefined,
				deliveryCountryCode: CountryOptions.find(({ value }) => value === data.deliveryCountryCode),
				shopOrderCartItems: rows,
				wayOfDeliveryCode: deliveryOptions?.data?.find(({ value }) => value === data.wayOfDeliveryCode),
			})
    }, [data, reset, CountryOptions, deliveryOptions]);
	
    const addArticleCallback = async (fortnoxId: string | undefined, addedToOrder: Date) => {
		if(!fortnoxId)
			return
        setShowLoader(true)
        if (fortnoxId !== undefined &&
            data?.id !== undefined) {
            await addArticleToOrder({
                orderId: data?.id,
                articleId: fortnoxId,
				addedToOrder: addedToOrder,
                callbackOnSuccess: () => {
                    setShowLoader(false)
                },
                callbackOnFail: (errorResponseResolver: ErrorResponseResolver) => {
                    setShowLoader(false)
                    toggleToast({
                        shouldToggle: true,
                        message: errorResponseResolver.message,
                        status: errorResponseResolver.status,
                    });
                }
            })
        }
        setShowLoader(false)
    }

	const removeArticleCallback = async (fortnoxId: string) => {
        setShowLoader(true)
        if (fortnoxId !== undefined &&
            data?.id !== undefined) {
            await removeArticleFromOrder({
                orderId: data?.id,
                articleId: fortnoxId,
                callbackOnSuccess: () => {
                    setShowLoader(false)
                },
                callbackOnFail: (errorResponseResolver: ErrorResponseResolver) => {
                    setShowLoader(false)
                    toggleToast({
                        shouldToggle: true,
                        message: errorResponseResolver.message,
                        status: errorResponseResolver.status,
                    });
                }
            })
        }
        setShowLoader(false)
    }

	const userAdminType = sessionUser?.userPermission.userType  === UserType.Admin || sessionUser?.userPermission.userType  === UserType.SuperAdmin
	const userSuperAdminType = sessionUser?.userPermission.userType  === UserType.SuperAdmin
	
	const [statusModal, setStatusModal] = useState({showModal: false, isAllowed: false})

	const doChangeStatus = (status: string, orderId: string) => async () => {
			if (status !== '') {
				setShowLoader(true)
				
				await changeStatus({
					orderId, 
					status, 	
					callback: v => {
						toggleToast({
							shouldToggle: true,
							message: v.message,
							status: v.status,
						})
					}
				})
			}
			setStatusModal({showModal: false, isAllowed: false})
			setShowLoader(false)
	}

	const displayExtraCost = order.data?.otherCostText || order.data?.otherCostText2 || order.data?.otherCostText3 
							|| order.data?.otherCost || order.data?.otherCost2 || order.data?.otherCost3
							|| order.data?.discountText || order.data?.discountText2 || order.data?.discountText3
							|| order.data?.discountCost || order.data?.discountCost2 || order.data?.discountCost3 
							|| (order.data?.transportCost !== undefined && order.data?.transportCost > 0)

    return (
		<div className="row order-container">
			{data?.status === 'NEW' && <div className="col-12">
				<button
					onClick={() => navigateToOrder(data?.previousId)}
					disabled={!data?.previousId}
					type="button"
					className="btn btn-primary float-left ml-1 mb-5"><ArrowBack style={{ fontSize:12, fontWeight: 'bolder' }} /> {t('order.Previous')}
				</button>
				<button
					onClick={() => navigateToOrder(data?.nextId)}
					disabled={!data?.nextId}
					type="button"
					className="btn btn-primary float-right ml-1 mb-5"> {t('order.Next')} <ArrowForward style={{ fontSize:12, fontWeight: 'bolder' }} />
				</button>
			</div>}
			<div className="col-md-12 col-sm-12">
				<div className="row">
					<div className="col-6 col-sm-6 col-lg-8">
						<h3>
						{t('order.Edit')} {t('order.Order')} {data?.orderId} {(!!data?.status) ? <>({t('common.'+data?.status??'')})</> : "" }
						</h3>
					</div>
					
					<div className="col-6 col-sm-6 col-lg-4">
						<form onSubmit={handleInput.handleSubmit(onSubmit)}>	{/** TODO Change this or internInformation Submit */}
							<button type="submit" className="btn btn-primary float-right ml-1"> {t('common.Save')} </button>
						</form>
						<button type='reset' className="btn btn-primary ml-2 float-right" onClick={() => history.go(navigatedOrdersCount)}>Gå tillbaka</button>
					</div>
				</div>
			</div>
			<div className='col-md-12 col-sm-12'>
				<InternInformationCard
					handleInput={handleInput}
					userAdminType={userAdminType}
					readOnly={false}
					sessionUser={sessionUser}
				/>
				<CustomerCard
					userAdminType={userAdminType}
					data={data}
					readOnly={false}
				/>
			</div>
			<form className='col-md-12 col-sm-12' onSubmit={handleInput.handleSubmit(onSubmit)}>

				<OrderDetailsCard
					handleInput={handleInput}
					userAdminType={userAdminType}
					data={data}
					order={order}
					readOnly={false}
				/>
				<DeliveryDetailsCard
					handleInput={handleInput} 
					userAdminType={userAdminType}		
					data={data} 	
					order={order}
					CountryOptions={CountryOptions}
					readOnly={false}
				 />
				<DeliveryCard 
					handleInput={handleInput}
					userAdminType={userAdminType}
					deliveryOptions={deliveryOptions.data as Option[]}
					order={order}
					readOnly={false}
				/>
				{data?.status !== 'NEW' &&
					<DeliveryInformationCard
						handleInput={handleInput}
						userAdminType={userAdminType}
						data={data}
						readOnly={false}
					/>
				}
				<OtherInformationCard
					handleInput={handleInput}
					userAdminType={userAdminType}
					readOnly={false}
				/>
				<div className="col-md-12 col-sm-12 mt-4">
					<table className="table table-bordered">
						<thead>
							<tr>
								<th scope="col">{t('order.Item number')}</th>
								<th scope="col">{t('order.Product name')}</th>
								<th scope="col">{t('order.Quantity')}</th>
								{sessionUser?.userPermission.userType  === UserType.SuperAdmin &&
									<th scope="col" className={'text-truncate'}>{t('order.Fortnox disposable')}</th>}
								{userAdminType &&
								<th scope="col" className='text-truncate'>{t('order.Disposable qty')}</th>}
								<th scope="col">{t('order.Price item')}</th>
								<th scope="col">{t('order.Discount')}</th>
								<th scope="col">{t('order.Total price')}</th>
								<th scope="col">{t('order.Partial delivery')}</th>
								<th scope="col">{t('order.Submission date')}</th>
								{data?.shopOrderCartItems.length ? data.shopOrderCartItems.length > 1 &&
									<th scope="col"></th> : null
								}	
							</tr>
						</thead>
						<tbody>
							{data?.shopOrderCartItems.map((item, index) =>
								<tr key={index}>
									<EditOrderArticleRow
										index={index}
										handleInput={handleInput}
										currency={currency}
										cartItem={item}
										edit
										disablePartialDelivery={order.data?.partlyDelivery}
										userAdminType={userAdminType}
										userSuperAdminType={userSuperAdminType}
									/>
									{data?.shopOrderCartItems.length > 1 &&
										<td>
											<span
												style={{color:"#ef7b10", cursor:"pointer"}}
												onClick={() => removeArticleCallback(item.fortnoxProductId)}
											>
												<Icon path={mdiDeleteForeverOutline} size="24" className="icon-24 mr-2" />
											</span>
										</td>
									}
								</tr>
							)}
							{displayExtraCost &&
								<OrderExtrasRow
								order={order} 
								currency={currency}
								userSuperAdminType={userSuperAdminType}
								isDeleteColumnVisible={data?.shopOrderCartItems != undefined && data.shopOrderCartItems.length > 1}
								userAdminType={userAdminType}
								/>
							}
						</tbody>
					</table>

					{ typeOfOrder === 'child' && order.data?.headOrderId &&
							<h5><Link to={PATH_ORDERS_EDIT_ORDER.replace(':orderId',order.data?.headOrderId)}>Gå till huvudorder</Link></h5>
					}

					{ typeOfOrder === 'main' && childOrder &&
						<>
							<h4 className="ml-1 mt-4">Delleverans, id: {childOrder.orderId}</h4>
							<table className="table">
								<thead>
									<tr>
										<th scope="col">{t('order.Item number')}</th>
										<th scope="col">{t('order.Product name')}</th>	
										<th scope="col">{t('order.Quantity')}</th>
										{sessionUser?.userPermission.userType  === UserType.SuperAdmin &&
											<th scope="col">{t('order.Fortnox disposable')}</th>}
										<th scope="col">{t('order.Disposable qty')}</th>
										<th scope="col">{t('order.Price item')}</th>
										<th scope="col">{t('order.Discount')}</th>
										<th scope="col">{t('order.Total price')}</th>
										<th scope="col">{t('order.Partial delivery')}</th>
										<th scope="col">{t('order.Submission date')}</th>	
									</tr>
								</thead>
								<tbody>
									{childOrder?.shopOrderCartItems.map((item, index) =>
										<tr key={index}>
											<EditOrderArticleRow
												index={index}
												handleInput={handleInput}
												currency={currency}
												cartItem={item}
												userAdminType={userAdminType}
												userSuperAdminType={userSuperAdminType}
											/>
										</tr>
									)}
								</tbody>
							</table>
							<h5><Link to={PATH_ORDERS_EDIT_ORDER.replace(':orderId',childOrder.id)}>Gå till delleverans</Link></h5>
						</>
					}
					<AddArticleToOrder
						formContext={handleInput}
						products={products}
						addArticleCallback={addArticleCallback}
						isLoading={products.loading} />
				</div>
				<div className='row justify-content-end'>
					<div className='col-2'>
						<span className="float-left mt-2">
							<h5 style={{color:"#ef7b10", fontWeight: 500}}>
								{t('order.Totalsum ex VAT')}:&nbsp;
							</h5>
							<h4 style={{color:"#ef7b10", fontWeight: 700}}>
								{priceFormatter(currency || 'SEK')(order.data?.shopOrderViewResolver.sum ?? 0)}
							</h4>
						</span>
					</div>

					<div className='col-2'>
						<span className="float-right mt-2">
							<h5 style={{color:"#ef7b10", fontWeight: 500}}>
								{t('order.Totalsum inc VAT')}:&nbsp;
							</h5>
							<h4 style={{color:"#ef7b10", fontWeight: 700}}>
								{priceFormatter(currency || 'SEK')(order.data?.shopOrderViewResolver.sumIncVAT ?? 0)}
							</h4>
						</span>
					</div>
				</div>
				<div className='row'>
					<div className='col-md-12 col-sm-12'>
						<Box my={2}>
							<Collapse in={notAllowedToSave}>
									<Alert severity="error" variant="outlined">
										<AlertTitle>Delleverans</AlertTitle>
										För att skapa en delleverans behöver åtminstone en produkt väljas ur listan.
									</Alert>
							</Collapse>
						</Box>
						<button type="submit" className="btn btn-primary ml-3 float-right"> {t('common.Save')} </button>
						<button
							type='button'
							className="btn btn-outline-primary float-right"
							onClick={() => setStatusModal({showModal: true, isAllowed: !(data?.status === 'CONFIRMED' && data?.orderStatusInternal === 'FORTNOX')})}
							style={{ minWidth: 150 }}>
							{ t('common.Change status')}
						</button>
						<button type='reset' className="btn btn-primary  mr-3  float-right" onClick={() => history.go(navigatedOrdersCount)}>Gå tillbaka</button>
					</div>
				</div>
			</form>
			
			<Dialog
				open={statusModal.showModal}
				onClose={() => setStatusModal({showModal: false, isAllowed: false})}
			>
				<DialogTitle>{t('common.Change order status')}</DialogTitle>
				<DialogContent>
					<Grid container>
						<OrderManagementChangeStatus
							orderId={orderId}
							onClick={doChangeStatus}
							loading={showLoader}
							isAllowed={statusModal.isAllowed}
						/>
					</Grid>
				</DialogContent>
				<DialogActions>
					<button 
						className="btn btn-light"
						onClick={() => setStatusModal({showModal: false, isAllowed: false})}
					>
						{t('common.Close')}
					</button>
				</DialogActions>
			</Dialog>
		</div>
	)
}

export default connector(EditOrder);