import React, {useEffect, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useForm, FormContext} from 'react-hook-form';

import {AppState} from '../../store';
import {setShowLoader} from '../../store/system';
import {getProductCategory, updateProductCategory} from '../../store/productCategories';
import {ProductCategoryInput} from '../../store/productCategories/types';

import ProductCategoryForm from '../../components/EShop/ProductCategoryForm';
import {useParams, useHistory} from 'react-router-dom';
import {useUpdateEffect} from "../../hooks/useUpdateEffect";

const connector = connect(
    (state: AppState) => ({
        productCategory: state.productCategories.selected,
    }),
    {setShowLoader, getProductCategory, updateProductCategory}
);

type CreateProductCategoryProps = ConnectedProps<typeof connector> & {};

export function EditProductCategory({
	productCategory,
	setShowLoader,
	getProductCategory,
	updateProductCategory
}: CreateProductCategoryProps) {
    const {t} = useTranslation();
    const history = useHistory();
    const {categoryId} = useParams<{categoryId:string}>();
    const [hasErrors, setHasErrors] = useState<boolean>()

    const {data, loading: productCategoryLoading} = productCategory;

    const isLoading = productCategoryLoading;

    const handleInput = useForm<ProductCategoryInput>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: {
            categoryName: '',
            categorySeconds: []
        }
    });

    const {reset, getValues} = handleInput;
    const {categoryName} = getValues()

    useEffect(() => {
        setShowLoader(isLoading);
    }, [isLoading, setShowLoader]);

    useEffect(() => {
        getProductCategory(categoryId);
    }, [categoryId, getProductCategory]);

    useUpdateEffect(() => {
        if(!data || categoryName) return
		reset(data)
    }, [categoryId, categoryName, data, reset])

    const submit = async (input: ProductCategoryInput) => {
        setHasErrors(false)
        const d: any = await updateProductCategory({categoryId, payload: input})
        const {error} = d
        if(error) {
            setHasErrors(true)
        }
    };



    return (
        <div>
            <h1 data-testid="edit_product_categories_headline">
                {t('navigation.E-shop')}
            </h1>
            {hasErrors && <div className="alert alert-danger" role="alert">
                Något gick fel. Kontrollera fälten
            </div>}
            <FormContext {...handleInput}>
                <form onSubmit={handleInput.handleSubmit(submit)}>
                    <legend>{t('common.Edit category')}</legend>
                    {categoryName && <ProductCategoryForm isEdit={true} />}

                    <hr className="form-delimiter"/>

                    <button
                        type="submit"
                        className="btn btn-primary mr-2"
                        data-testid="submit__company_form"
                    >
                        {t('common.Save data')}
                    </button>
                    <button
                        type="reset"
                        className="btn btn-info"
                        onClick={() => history.goBack()}
                    >
                        {t('common.Cancel')}
                    </button>
                </form>
            </FormContext>
        </div>
    );
}

export default connector(EditProductCategory);
