import React, {useCallback, useEffect, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import Fade from '@material-ui/core/Fade/Fade'
import {CreditCardOutlined, Search} from '@material-ui/icons';
import {useQuery} from '../../lib/hooks'
import {AppState} from '../../store'
import { getOrderStats, getOrdersWithLimits } from '../../store/orders'
import Table from '../../components/Table'
import Pager from '../../components/Pager'
import TabNav from '../../components/TabNav'
import formatTime from '../../lib/timeHelper'
import { PATH_ORDERS_EDIT_ORDER, PATH_ORDERS_VIEW_ORDER } from '../../router/paths'
import {isBackOfficeAccount} from '../../lib/permissions';
import usePaging from '../../hooks/usePaging'
import priceFormatter from '../../lib/priceFormatter'
import { toggleToast } from '../../store/components'
import { Order, OrderWithLimits } from '../../store/orders/types'
import ProgressSpinnerBox from '../../components/ProgressSpinnerBox/ProgressSpinnerBox'
import NonIdealState from '../../components/NonIdealState/NonIdealState'
import { useMemo } from 'react'
import useFetchOrdersFilter from '../../hooks/useFetchOrdersFilter';
import {setShowLoader} from "../../store/system";
import Icon from '@mdi/react';
import { mdiMessageAlert } from '@mdi/js';
import { AccessModules, UserType } from '../../store/users/types';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';

enum OrdersFilter {
	All = '',
	New = 'NEW',
    MineAssigned ='MINE_ASSIGNED',
	Booked = 'BOOKED',
	Preorder = 'PREORDER',
	Confirmed = 'CONFIRMED',
	Canceled = 'CANCELED',
	Delivered = 'DELIVERED'
}

const connector = connect(
	(state: AppState) => ({
		orderInformation: state.orders.orderWithLimits,
		stats: state.orders.stats,
		sessionUserType: state.system.sessionUserType,
		accessModules: state.system.accessModules,
		sessionUser: state.system.sessionUser,
	}),
	{getOrdersWithLimits, getOrderStats, toggleToast, setShowLoader}
)

type OrderManagementProps = ConnectedProps<typeof connector> & {}

export function OrderManagement({
	orderInformation,
	stats,
	sessionUserType,
	getOrdersWithLimits,
	accessModules,
	setShowLoader,
	toggleToast,
	sessionUser
}: OrderManagementProps) {
	const [refreshOrdersFilter, setRefreshOrdersFilter] = useState<boolean>(true);
	const ordersFilter = useFetchOrdersFilter({
		refreshOrdersFilter: refreshOrdersFilter,
		setRefreshOrdersFilter: setRefreshOrdersFilter
	});
	const history = useHistory()
	const query = useQuery()
	const {t} = useTranslation()
	const filter = query.get('filter') as OrdersFilter | null ?? undefined
	const pageNumberParam = query.get('page') as string | null ?? undefined
	const columnName = query.get('columnName') as string | null ?? undefined
	const direction = query.get('direction') as string | null ?? undefined
	const shouldHighlightBoundary = isBackOfficeAccount(sessionUserType)
	const resetFilter = () => { 
		query.set('filter', '')
		history.push(`?${query}`)
		localStorage.setItem("filter", '')
		localStorage.setItem("page", '')
	}

	useEffect(() => {
		const setFilterInitialState = () => {
			const queryFilter = query.get('filter');

			if(queryFilter === null && isBackOfficeAccount(sessionUserType)) {
				query.set('filter', OrdersFilter.New)
				history.push(`?${query}`)
			}else if(queryFilter === null){
				query.set('filter', OrdersFilter.All)
				history.push(`?${query}`)
			}
		}
		setFilterInitialState()
	}, [])

	useEffect(() => {
		setStatusSearchTerm(filter ?? '');
		localStorage.setItem("filter", filter ?? '')
		localStorage.setItem("page", pageNumberParam ?? '')
		setRefreshOrdersFilter(true);
	}, [filter, pageNumberParam, columnName, direction])
	
	const handleGetOrders = useCallback(({ filters, textSearch, columnName, direction }) => {
		if(filter === undefined) return
		
		let statusSearch = localStorage.getItem("filter") !== '' ? localStorage.getItem("filter") : filter
		let pageParam = localStorage.getItem("page")
		columnName = query.get("columnName") as string | null ?? undefined
		direction = query.get("direction") as string | null ?? undefined

		filters as {limit: number, offset: number}
		let pageNumber = pageParam ? parseInt(pageParam)-1 : 0
		filters.offset = filters.limit*(pageNumber);

		getOrdersWithLimits({
			...filters,
			orderStatus: statusSearch ,
			search: textSearch,
			columnName: columnName,
			direction: direction,
		})

	}, [getOrdersWithLimits, filter, columnName, direction])

	const {
		limit, page, onPagination, searchTerm, setSearchTerm, setStatusSearchTerm, triggerRefresh, hasFetched
	} = usePaging({ 
		getItems: handleGetOrders, 
		limit: 10 
	})
	
	useEffect(() => { triggerRefresh() }, [triggerRefresh])

	const isLoading = orderInformation.loading || stats.loading || !hasFetched

	useEffect(() => {
		setShowLoader(isLoading);
	}, [isLoading, toggleToast])

	const filteredOrdersWithLimits = 
	filter && 
	filter !== OrdersFilter.MineAssigned  
	? orderInformation.data?.filter(i => i.order.status === filter) 
			: orderInformation.data ?? []
	
	const tableHeader = [
		'',
		t('common.Order date'),
		t('common.Order id'),
		t('common.Delivery city'),
		t('common.Order number'),
		t('common.Status'),
		t('common.InternalStatus'),
		t('common.Customer reference'),
		t('common.Personal reference'),
		t('common.Deliverynumber'),
		t('common.Shipment date'),
		t('common.Delivery date'),
		t('common.Total'),
		{
			key: 'search',
			component: (
				<Pager
					className="text-center"
					page={page}
					limit={limit}
					totalItems={orderInformation.total ?? 0}
					onPagination={page => {
						onPagination(page);
						setRefreshOrdersFilter(true);
					}}
				/>
			),
		},
	]

	const getOrdersFilterCount = (filter: OrdersFilter): number => {
		switch(filter) {
			case OrdersFilter.All: return ordersFilter.allOrders;
			case OrdersFilter.New: return ordersFilter.newOrders;
			case OrdersFilter.MineAssigned: return ordersFilter.mineAssigned;
			case OrdersFilter.Booked: return ordersFilter.bookedOrders;
			case OrdersFilter.Preorder: return ordersFilter.preOrder;
			case OrdersFilter.Confirmed: return ordersFilter.confirmedOrders;
			case OrdersFilter.Canceled: return ordersFilter.canceledOrders;
			case OrdersFilter.Delivered: return ordersFilter.deliveredOrders;
			default: return 0;
		}
	}
	const navLinks = useMemo(() => Object.values(OrdersFilter).map(s => ({
			isActive: filter === s,
			path: `?filter=${s}`,
			component: (
				<>
				{t(`common.${s === ''?'All':s}`)}
				<span className="badge badge-info">
				 {getOrdersFilterCount(s)} st
				</span>
		</>
		),
	})), [filter, orderInformation.data, t])

	const navLinksFiltered = navLinks.filter((navLink) => navLink.path !== `?filter=${OrdersFilter.MineAssigned}`)
	const isAdminOrSuperAdmin = UserType.SuperAdmin === sessionUserType || UserType.Admin === sessionUserType;

	let [clickedHeader, setClickedHeader] = useState(query.get('columnName') ?? '')

	const handleSort = (event: React.MouseEvent, h: string) => {
		let resolvedHeaders = h === 'Orderdatum' ? 'orderCreated' :
								h === 'Order' ? 'orderId' :
								  h === 'Leveransort' ? 'deliveryCity' :
								    h === 'Order id Fortnox' ? 'orderNr' :
								      h === 'Status' ? 'status' :
										h === 'Intern status' ? 'orderStatusInternal' :
										  h === 'Företag' ? 'deliveryCustomerName' :
											h === 'Kundreferens' ? 'yourReference' :
											  h === 'Beräkningsnamn' ? 'sunProjectName' :
												h === 'Utleveransdatum' ? 'proposedDeliveryDate' :
												  h === 'Leveransdatum' ? 'preliminaryDeliveryDate' :
													h === 'Summa' ? 'orderSum' :
													  h === '' ? 'assignedUserName' : ''
		if(localStorage.getItem('columnName')?.localeCompare(resolvedHeaders)) {
			localStorage.setItem('direction', '')
			localStorage.setItem('columnName', resolvedHeaders)
		} else {
			localStorage.setItem('columnName', resolvedHeaders)
		}
		let direction = localStorage.getItem('direction') === '' || null ? 'asc' : localStorage.getItem('direction') === 'asc' ? 'desc' : ''
		localStorage.setItem('direction', direction)

		setClickedHeader(h)
		query.set('columnName', resolvedHeaders)
		query.set('direction', direction)
		history.push(`?${query}`)

	}

	return (
		<div>
			<h1 data-testid="order_management_headline">
				{t('navigation.Order management__nav.Orders')}
			</h1>
			<h3>{t('common.Order status')}</h3>
			
		   <Fade in={!isLoading && !!filteredOrdersWithLimits.length}>
		
				<>
					<TabNav links={isAdminOrSuperAdmin ? navLinks : navLinksFiltered} />
					<Table
						list={filteredOrdersWithLimits}
						extractKey={(r) => r.order.id}
						loading={orderInformation.loading}
						searchField={{
							name: 'orderSearch',
							text: t('common.Search order'),
							onChange: (e: any) => setSearchTerm(e.target.value),
							value: searchTerm,
						}}
						sortable={{
							active: true,
							onClick: handleSort,
							direction: direction,
							header: clickedHeader
						}}
						blockClassNames={"customTable-ordermgm"}
						header={tableHeader}
						row={
							r => (
							<>
								<TableRow 
									row={r} 
									shouldHighlightBoundary={shouldHighlightBoundary} 
									isBackOfficeAccount={isBackOfficeAccount(sessionUserType)}
									accessModules={accessModules}
								/>
							</>
							)
						}
						style={{ border: '2p solid red' }}
					/>
				</>
			</Fade>
			<Fade in={!isLoading && !filteredOrdersWithLimits.length}>
				{(+(orderInformation.total ?? 0)) !== 0 ? 
					<NonIdealState
						icon={<Search style={{ fontSize: 60, opacity: .6 }} />}
						title="Inga resultat"
						action={
							<button
								className="btn btn-primary btn-md mt-2"
								onClick={() => {
									resetFilter()
									setSearchTerm('')
								}}
								disabled={isLoading}
							>
								Visa alla ordrar
							</button>
						}
					/>
					:
					<NonIdealState
						title="Inga ordrar"
						description="Det finns inga orderar att visa"
					/>
				}
			</Fade>
			<Fade in={isLoading}>
				{ProgressSpinnerBox()}
			</Fade>
		</div>
	)
}

const TableRow: React.FC<{ 
	row: OrderWithLimits, 
	shouldHighlightBoundary?: boolean,
	isBackOfficeAccount?: boolean,
	accessModules: AccessModules,
}> = ({ 
	row, 
	shouldHighlightBoundary,
	isBackOfficeAccount,
	accessModules
}) => {
	const {t} = useTranslation()
	const history = useHistory()
	const [currency, setCurrency] = useState("")
	const className = (
		shouldHighlightBoundary
		&& row.limitations?.customerOrderLimit !== null 
		&& row.limitations?.totalOrdered !== undefined 
		&& row.limitations.totalOrdered > row.limitations.customerOrderLimit
			? 'error'
			: row.order.status === 'NEW' && row.order.orderStatusInternal === 'FORTNOX'
				? 'warning'
				: ''
	)

	useEffect(() => {
        if (row.order?.shopOrderCartItems != undefined) {
            if (row.order?.shopOrderCartItems.length >= 0) {
                let ob = row.order?.shopOrderCartItems.find(i => i.id != undefined)?.shopOrderPrice.currency
                setCurrency(ob !== undefined ? ob : "")
            }
        }
    }, [TableRow])

	const getDeliveryDateString = (order: Order): string => {
		const {deliveryDate, preliminaryDeliveryDate} = order
		if(!deliveryDate)
			return `Prel: ${formatTime(preliminaryDeliveryDate)}`;
		return formatTime(deliveryDate);
	}
	
	const initialAssignedUser = () => {
		if(!isBackOfficeAccount) return

		let firstName = row?.order?.assignedUserName?.substring(0, 1)
		let lastName = row?.order?.assignedUserName?.split(" ")[1]?.substring(0, 1) 
		
		if(firstName === "" || firstName === undefined) return

		return (
			<>
				<div className="align-middle" 
					style={{
						borderRadius: "20px", 
						backgroundColor: "#ef7b10", 
						height:"27px", 
						width: "27px",
						textAlign: "center",
						color: "#fff"
					}}>
						{firstName}{lastName}
				</div>
			</>
		) 
	}

	const ClickableRowFunc = () => {
		isBackOfficeAccount && accessModules.ORDER_MANAGEMENT ? (
			(row.order.status === OrdersFilter.New || row.order.status === OrdersFilter.Booked) && row.order.orderStatusInternal === OrdersFilter.New ?
				history.push(PATH_ORDERS_EDIT_ORDER.replace(':orderId', row.order.id)) :
				history.push(PATH_ORDERS_VIEW_ORDER.replace(':orderId', row.order.id))
		):(history.push(PATH_ORDERS_VIEW_ORDER.replace(':orderId', row.order.id)))
	}

	return (
		<tr style={{display: 'contents', cursor: 'pointer'}} onClick={() => ClickableRowFunc()} >
			<td className={className}>
				{initialAssignedUser()}
				{row.order.paymentMethod === "F" &&
					<Tooltip title={t("order.Preorder") ?? ""}>
						<CreditCardOutlined/>
					</Tooltip>
				}
			</td>
			<td className={className}>
				{row.order.shopOrderViewResolver?.orderDate}
			</td>
			<td className={className}>
				{row.order.orderId}
			</td>
			<td className={className}>
				{row.order.deliveryCity}
			</td>
			<td className={className}>
				{row.order.shopOrderViewResolver?.orderNumber}
			</td>
			<td className={className}>
				{row.order.status !== "AWAITING" ? t('common.'+row.order.status) : "---"}
			</td>
			<td className={className}>
				{(shouldHighlightBoundary && row.order.orderComment) && 
					<Icon 
						path={mdiMessageAlert} 
						size="16"
						style={{color: '#ef7b10', maxHeight: "16px"}}
						title={t('common.Comment')}
						className="icon-14 mr-1"
					/>
				}
				{t('common.'+row.order.orderStatusInternal)}
			</td>
			<td className={className}>
				{row.order.shopOrderViewResolver?.customerReference}
			</td>
			<td className={className}>
				{row.order.yourReference}
			</td>
			<td className={className}>
				{row.order.shopOrderShipments.map(item => item.shipmentNumber).join(", ")}
			</td>
			<td className={className}>
				{formatTime(row.order.shipmentFromWMSDate)}
			</td>
			<td className={className}>
				{getDeliveryDateString(row.order)}
			</td>
			<td className={className} style={{whiteSpace: "nowrap"}}>
				{priceFormatter(currency || 'SEK')(row.order.orderSum)}
			</td>
			<td className={className}></td>
		</tr>
	)
}

export default connector(OrderManagement)