import React, { createContext, useEffect, useMemo } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { PATH_E_SHOP_CHECKOUT } from '../../../router/paths'
import { AppState } from '../../../store'
import { CartItem } from '../../../store/checkout/types'
import { addToCart, deleteFromCart } from '../../../store/cart2'
import { getCheckoutInformation } from '../../../store/checkout'

const connector = connect(
    (state: AppState) => ({
        productCategories: state.productCategories.list,
		checkoutInformation: state.checkouts.selected,
    }),
    { addToCart, deleteFromCart, getCheckoutInformation }
)

type EShopCartProviderProps = ConnectedProps<typeof connector> & {
	children: React.ReactNode
}

interface ContextEShopCartProvider {
	cart?: {
		productMethods: {
			increment: (articleId: string) => void
			decrement: (articleId: string) => void
			remove: (articleId: string, qantity: number) => void
			setQuantity: (articleId?: string, quantity?: number) => void
		}
		total: {
			price: number
			amount: number
		}
		items: CartItem[]
		b2COrder: boolean
	}
	goToCheckout?: () => void
	overCapacityProductList?: CartItem[]
}

export const contextEShopCart = createContext<ContextEShopCartProvider>({})

const EShopCartProvider = ({
    addToCart,
	deleteFromCart,
	children,
	getCheckoutInformation,
	checkoutInformation
}: EShopCartProviderProps) => {
	const history = useHistory()

	useEffect(() => {
        getCheckoutInformation()
    }, [])

	const handleCartProductAmount = useMemo(() => ({
		increment: async (articleId?: string) => {
			if(!articleId) return
			await addToCart({articleId,quantity:1})
			getCheckoutInformation()
		},
		decrement: async (articleId?: string) => {
			if(!articleId) return
			await deleteFromCart({articleId,quantity:1})
			getCheckoutInformation()
		},
		remove: async (articleId: string, quantity: number) => {
			if(!quantity || !articleId) return
			await deleteFromCart({articleId,quantity:quantity})
			getCheckoutInformation()
		}, 
		setQuantity: async (articleId?: string, quantity?: number) => {
			if(!quantity || !articleId) return
			await addToCart({articleId,quantity})
			getCheckoutInformation()
		}, 
	}), [addToCart, deleteFromCart, getCheckoutInformation])
	
	const total = useMemo(() => ({
		price:  checkoutInformation?.data?.cartItems.filter(item => item.articlePrice !== undefined).reduce((sum, current) => sum + current.totalPriceFromMultipleArticles, 0) ?? 0,
		amount:  checkoutInformation?.data?.cartItems.filter(item => item.articlePrice !== undefined).reduce((sum, current) => sum + current.amountOfInCart, 0) ?? 0
	}), [checkoutInformation?.data?.cartItems])

	const goToCheckout = () => history.push(PATH_E_SHOP_CHECKOUT)

	const overCapacityProductList = useMemo(() => 
		checkoutInformation?.data?.cartItems.reduce<CartItem[]>((p,c) => c.amountOfInCart <= c.disposableQuantity ? p : [...p,c], [])
	, [ checkoutInformation?.data?.cartItems])

	return (
		<contextEShopCart.Provider value={{
			cart: {
				productMethods: handleCartProductAmount,
				total,
				items: checkoutInformation?.data?.cartItems ?? [] as CartItem[],
				b2COrder: checkoutInformation?.data?.b2COrder ?? false as boolean,
			},
			goToCheckout,
			overCapacityProductList
		}}>
			{children}
		</contextEShopCart.Provider>
	)
}
export default connector(EShopCartProvider)
