import React from 'react';
import {useTranslation} from 'react-i18next';

import ConfirmationModal from '../Modal/ConfirmationModal';

export default function ChangeCustomerStatusModal(props: {
  modalVisible: boolean;
  setModalVisible: (f: boolean) => void;
  onConfirmation: () => void;
  onCancel: () => void;
}) {
  const {t} = useTranslation();

  return (
    <ConfirmationModal
      title={t('common.Change company status')}
      message={t('common.Do you want to deactivate the Company?')}
      buttonMessage={'OK'}
      {...{
        ...props,
      }}
    />
  );
}
