import React, { useContext } from 'react';
import Breadcrumbs from '@material-ui/core/Breadcrumbs/Breadcrumbs'
import Link from '@material-ui/core/Link/Link'
import { contextEShopCategory } from '../../../EShop/Providers/EShopCategoryProvider';
import { contextEShopProducts } from '../../../EShop/Providers/EShopProductsProvider';

const hasCategory = (category: { id: string, name: string } | undefined) => category?.id && category.id !== "0"

const EShopCategoryBreadcrumbs = () => {
	const { configuration, setConfiguration, resetConfiguration } = useContext(contextEShopCategory)
	const { category1, category2, category3 } = configuration ?? {}
	
	const { recommendedProducts } = useContext(contextEShopProducts)
	const { selectedManufacturer, resetManufacturer  } = useContext(contextEShopCategory)
	
    return (
		<Breadcrumbs aria-label="breadcrumb">
			<Link 
				color="inherit" 
				onClick={setConfiguration ? selectedManufacturer ? resetManufacturer : resetConfiguration : undefined}
			>
				{recommendedProducts ? 'Rekommenderat' : 'Alla'}
			</Link>
			{ selectedManufacturer &&
				<Link 
					color="inherit"
				>
					{selectedManufacturer}
				</Link>
			}
			{ hasCategory(category1) && 
				<Link 
					color="inherit" 
					onClick={() => setConfiguration?.({ id1: category1!.id })}
				>
					{category1!.name}
				</Link>
			}
			{ hasCategory(category2) && 
				<Link 
					color="inherit" 
					onClick={() => setConfiguration?.({ id1: category1!.id, id2: category2!.id })}>
					{category2!.name}
				</Link>
			}
			{ hasCategory(category3) && 
				<Link
					color="textPrimary"
					onClick={() => setConfiguration?.({ id1: category1!.id, id2: category2!.id, id3: category3!.id })}
				>
					{category3!.name}
				</Link>
			}
		</Breadcrumbs>
    )
}

export default EShopCategoryBreadcrumbs