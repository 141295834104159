import React, {useEffect, useMemo} from 'react';
import {useForm} from 'react-hook-form';
import axios, {Canceler, AxiosResponse} from 'axios';
import classnames from 'classnames';
import {useTranslation} from 'react-i18next';
import {FormContextValues} from 'react-hook-form';

import {UploadedFile, UploadedFilesDictionary} from '../../types';

import {extractErrorText, getErrorTestId, getErrorObj} from '../../lib/form';

import {InputDefaultProps} from './types';
import {formatDate} from '../../lib/format';

interface FileInputFieldProps<T> extends InputDefaultProps {
  metaInfo?: string | null;
  signed?: string | null;
  placeholder?: string;
  uploading: boolean;
  uploadedFile: UploadedFile | null;
  progress?: number;
  handler: FormContextValues<T>;
  onView: (f: UploadedFile) => void;
  onDelete: (f: UploadedFile | null) => void;
}

export function FileInputField<T>({
  name,
  handler,
  rules,
  className,
  label,
  placeholder,
  metaInfo,
  signed,
  uploading,
  uploadedFile,
  progress,
  onView,
  onDelete,
}: FileInputFieldProps<T>) {
  const {t} = useTranslation();
  const id = `file__eon-ui`;

  const elementError = getErrorObj(handler.errors, name);
  const errorText = extractErrorText(elementError, rules, t);

  const currentValue = handler.watch(name) as FileList | undefined;

  const filled =
    uploadedFile || // file existing in the db
    (currentValue &&
      currentValue instanceof FileList &&
      currentValue.length > 0);

  const display = filled
    ? uploadedFile
      ? uploadedFile.originalName
      : currentValue
      ? currentValue[0].name
      : '(Missing name)'
    : placeholder ?? label ?? '(Missing label/placeholder)';

  const fileCustomElem = (
    <span
      className={classnames('file-custom', {
        border: elementError,
        'border-danger': elementError,
      })}
    >
      {display}
      {progress ? <span className="file-meta">{progress}%</span> : null}
      {metaInfo ? <span className="file-meta">{t('common.Date')}:{metaInfo}</span> : null}
      {signed ? <span className="file-meta">{t('common.Signed')}:{formatDate(signed)}</span> : null}
    </span>
  );

  return (
    <div className={classnames('form-group', 'col', className)}>
      {label === undefined ? null : (
        <label htmlFor={uploadedFile ? `com.eon.ui:eon-ui:war:1.0-SNAPSHOT_noop` : id}>{label}</label>
      )}

      <div>
        <div className="input-group">
          {uploadedFile ? (
            <span className="file cursor-initial file__selected">
              {fileCustomElem}
            </span>
          ) : (
            <label
              className={classnames('file', {
                file__selected: filled,
                'cursor-initial': uploading,
              })}
              htmlFor={uploading ? `com.eon.ui:eon-ui:war:1.0-SNAPSHOT_noop` : id}
            >
              <input
                name={name}
                type="file"
                id={id}
                data-testid={id}
                aria-label="File browser"
                ref={rules ? handler.register(rules) : handler.register}
              />
              {fileCustomElem}
            </label>
          )}
          {filled && (
            <div className="input-group-append">
              <span className="input-group-text bg-white">
                <div className="dropdown d-inline-block">
                  {uploadedFile && (
                      <button
                          className="dropdown-text-button dropdown-toggle"
                          type="button"
                          id={`com.eon.ui:eon-ui:war:1.0-SNAPSHOT__dropdown`}
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          disabled={progress ? progress > 99 : undefined}
                      >
                        {t('common.Options')}
                      </button>
                  )}
                  <div
                    className="dropdown-menu"
                    aria-labelledby={`com.eon.ui:eon-ui:war:1.0-SNAPSHOT__dropdown`}
                  >
                    {uploadedFile && (
                      <span
                        className="dropdown-item"
                        onClick={() => uploadedFile && onView(uploadedFile)}
                      >
                        {t('common.Download')}
                      </span>
                    )}
                    <span
                      className="dropdown-item text-danger"
                      onClick={() => onDelete(uploadedFile)}
                    >
                      {t('common.Delete')}
                    </span>
                  </div>
                </div>
              </span>
            </div>
          )}
        </div>
      </div>

      {elementError ? (
        <span
          className="form-control-error text-danger"
          data-testid={getErrorTestId(elementError)}
        >
          {errorText}
        </span>
      ) : null}
    </div>
  );
}

type FileInput = FileList | undefined;

export function UploaderField({
  id,
  file,
  placeholder,
  uploadProgress,
  fieldClassNames,
  onView,
  onFileSelect,
  onDelete,
}: {
  id: string;
  file: UploadedFile | null;
  placeholder: string;
  uploadProgress?: number;
  fieldClassNames?: string;
  onView: (id: string) => string;
  onFileSelect: (id: string, fileList: undefined | FileList) => void;
  onDelete: (id: string, file: UploadedFile | null) => void;
}) {
  const fieldId = `file_com.eon.ui:eon-ui:war:1.0-SNAPSHOT`;

  const handleInput = useForm<{[k: string]: FileInput}>({
    mode: 'onChange',
  });

  const watchedFiles = handleInput.watch(fieldId);

  /**
   * hack for FF bug
   * useEffect can't detect the changes due to some weird FF optimizations
   */
  const numberOfFiles = watchedFiles?.length;
  const firstFile = watchedFiles?.[0];
  useEffect(() => {
    onFileSelect(id, watchedFiles);
  }, [watchedFiles, firstFile, numberOfFiles, id, onFileSelect]);

  return (
    <>
      <div className="form-row">
        <FileInputField
          name={fieldId}
          handler={handleInput}
          uploading={typeof uploadProgress === 'number'}
          uploadedFile={file}
          progress={uploadProgress}
          metaInfo={file ? formatDate(file.uploadedAt) : undefined}
          signed={file ? file.signedDate : undefined}
          placeholder={placeholder}
          className={fieldClassNames ?? 'col-6'}
          onView={(f) => {
            window.open(onView(f.id), '_blank')?.focus();
          }}
          onDelete={(file) => onDelete(id, file)}
        />
      </div>
    </>
  );
}

type InstantMultipleFileUploaderState = {
  files: UploadedFilesDictionary;
  progress: {[k: string]: number};
};

type InstantMultipleFileUploaderProps = {
  files: UploadedFilesDictionary;
  placeholder: string;
  fieldClassNames?: string;
  cleanAndCallback?: () => void
  onView: (id: string) => string;
  onDelete: (id: string) => any;
  noDetails?:boolean;
  uploadService: (
    file: File,
    onUploadProgress: (e: any) => void,
    cancel: (cancelFn: Canceler) => void
  ) => Promise<AxiosResponse<any>>;
};

let id = 0;
const nextId = () => {
  return `tmp-id-${id++}`;
};

export default class InstantMultipleFileUploader extends React.Component<
  InstantMultipleFileUploaderProps,
  InstantMultipleFileUploaderState
> {
  state: InstantMultipleFileUploaderState = {
    files: this.props.files,
    progress: {},
  };

  cancelTokens: {[k: string]: Canceler} = {};

  componentDidMount() {
    this.addEmptyField();
  }

  cleanAndCallback = () => {
    if(this.props.cleanAndCallback !== undefined){
      this.props.cleanAndCallback()
    }
  }

  setUploadedFile = (id: string, data: any) => {
    this.setState((state) => ({
		...state,
		files: {
		  ...state.files,
		  [id]: data as UploadedFile,
		},
	  }))
  };

  setProgress = (id: string, percent: number) => {
    this.setState((state) => ({
      ...state,
      progress: {
        ...state.progress,
        [id]: percent,
      },
    }));
  };

  clearProgress = (id: string) => {
    this.setState((state) => {
      const copy = {...state.progress};
      delete copy[id];

      return {
        ...state,
        progress: copy,
      };
    });
  };

  addEmptyField = () => {
    this.setState((state) => ({
      ...state,
      files: {
        ...state.files,
        [`${nextId()}`]: null,
      },
    }));
  };

  onFileSelect = (id: string, fileList: FileInput) => {
    if (!fileList || fileList.length === 0) {
      return;
    }

    const file = fileList[0];

    this.addEmptyField();

    this.setProgress(id, 0);

    this.props
      .uploadService(
        file,
        (e) => {
          this.setProgress(id, Math.round((100 * e.loaded) / e.total));
        },
        (cancel: any) => this.cancelTokens[id] = cancel
      )
      .then((res) => {
        if(!this.props.noDetails){
          this.setUploadedFile(id, res.data);
        }
        /* if(this.cleanAndCallback !== undefined){
          this.removeField(id)
          this.cleanAndCallback()
        } */
      })
      .catch((e) => {
        if (e instanceof axios.Cancel) {
          this.removeField(id);
        } else {
          console.error('error', {e});
        }
      })
      .finally(() => {
        delete this.cancelTokens[id];
        this.clearProgress(id);
      });
  };

  removeField = (id: string) => {
    this.setState((state) => {
      const copy = {...state.files};
      delete copy[id];

      return {
        ...state,
        files: copy,
      };
    });
  };

  onDelete = async (id: string, file: UploadedFile | null) => {
    if (file) {
      await this.props.onDelete(file.id);
    }

    this.cancelTokens[id] ? this.cancelTokens[id]() : this.removeField(id);
  };

  render() {
    return <Files that={this} />
  }
}

const Files = ({ that }: { that: any }) => {
	const {files, progress} = that.state;
    const {fieldClassNames, placeholder, onView, noDetails} = that.props;
    const {onFileSelect, onDelete} = that;

	const entries = Object.entries(files)
	
	const content = useMemo(() => 
		Object.entries(files).map(([fileId, file], index) => {
			return (
			<UploaderField
				key={fileId}
				id={fileId}
				file={file as UploadedFile}
				placeholder={placeholder}
				uploadProgress={progress[fileId]}
				onView={onView}
				onFileSelect={onFileSelect}
				onDelete={onDelete}
				fieldClassNames={classnames(fieldClassNames, {
				'mb-2': index < entries.length - 1,
				})}
			/>
			)
		})
	, [entries])
	return <>{content}</>
}