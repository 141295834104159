import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

import SE from './langs/se.json';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      se: {
        translation: SE,
      },
    },
    lng: 'se',
    debug: process.env.NODE_ENV === 'development',
    fallbackLng: 'se',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
