import { request } from '../lib/request';
import { ENDPOINTS } from "../endpoints";

const methods = {

  getUnmappedArticles() {
    return request({
      endpoint: ENDPOINTS.ESHOP_BACK_ARTICLES_UNMAPPED_READ,
      method: 'GET',
    });
  },
}

export default methods
