import React, {useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {useForm} from 'react-hook-form';
import {Trans, useTranslation} from 'react-i18next';
import {Link, useHistory} from 'react-router-dom';

import {PATH_AUTH_FORGOT_PASSWORD, PATH_HOME} from '../../router/paths';

import {AppState} from '../../store';
import {createSession, setShowLoader} from '../../store/system';

import {DEFAULT_RULES} from '../../lib/form';

import InputField from '../../components/Form/InputField';

import {ReactComponent as Logo} from './svgs/Solar_Supply-logo_-_EON.svg';
import { Grid } from '@material-ui/core';

const connector = connect((state: AppState) => ({}), {
  setShowLoader,
  createSession,
});

type LoginProps = ConnectedProps<typeof connector> & {};
type LoginFormInput = {
  email: string;
  password: string;
};

function Login({setShowLoader, createSession}: LoginProps) {
  const history = useHistory();
  const {t} = useTranslation();
  const handleInput = useForm<LoginFormInput>({mode: 'onChange'});

  const {
    formState: {isSubmitting},
  } = handleInput;

  const onSubmit = async (inputs: LoginFormInput) => {
    const resp = await createSession(inputs);

    if ('error' in resp) {
      /* istanbul ignore else */
      if (resp.payload?.isAxiosError) {
        handleInput.setValue('password', '');
        handleInput.setError(
          'password',
          t('validation.Invalid email or password')
        );
        handleInput.setError('email', '');
      } else {
        // TODO: handle network/request errors
        console.error('login error', resp.error, resp);
      }
    } else {
      history.push(PATH_HOME);
    }
  };

  useEffect(() => {
    setShowLoader(isSubmitting);
  }, [isSubmitting, setShowLoader]);

  return (
    <form
      onSubmit={handleInput.handleSubmit(onSubmit)}
      className="position-relative"
    >
      <Grid container direction="row-reverse" spacing={2}>
		<Grid item container xs={12} sm={3} justify="flex-end"><Logo /></Grid>
		<Grid item xs={12} sm={9}><legend className="primary">{t('common.Login')}</legend></Grid>
	  </Grid>
      <InputField
        name="email"
        handler={handleInput}
        label={t('common.E-mail address')}
        rules={{
          required: true,
          minLength: DEFAULT_RULES.email.minLength,
          pattern: DEFAULT_RULES.email.pattern,
        }}
      />
      <InputField
        name="password"
        type="password"
        label={t('common.Password')}
        handler={handleInput}
        showHidePassword={!!handleInput.watch('password')}
        rules={{
          required: true
        }}
      />
      <div className="clearfix">
        <div className="forgot-password float-right">
          <Link to={PATH_AUTH_FORGOT_PASSWORD}>
            {t('common.Forgot password')}
          </Link>
        </div>
      </div>
      <div className="clearfix">
        <button
          type="submit"
          className="btn btn-primary float-right"
          data-testid="log_in"
        >
          {t('common.Log in')}
        </button>
      </div>
      <div className="mt-6 text-center">
        <Trans i18nKey="common.New user?">New user? Contact <a href="mailto:order@solarsupply.se?subject=Ny användarförfrågan">order@solarsupply.se</a> and we will create an account for you.</Trans>
      </div>
    </form>
  );
}

export default connector(Login);
