import React, { useMemo } from 'react'
import {default as InfiniteScrollComponent, Props} from 'react-infinite-scroll-component'
import ProgressSpinnerBox from '../ProgressSpinnerBox/ProgressSpinnerBox'

interface InfiniteScrollProps<T> extends Pick<Props, 'scrollableTarget'> {
	src: T[], 
	next: () => any
	extractItem: (v: (T | undefined), i:number) => JSX.Element
	className?: string
	container?: (children: any) => JSX.Element
	hasMore: boolean
}

const InfiniteScroll = <T extends object>({ 
	src, 
	next, 
	extractItem, 
	className, 
	container = (v:any)=>v,
	hasMore,
	...props
}: InfiniteScrollProps<T>) => {
	const items = useMemo(() => src.map(extractItem), [src])
	
	return (
		<InfiniteScrollComponent
			{...props}
			dataLength={src.length}
			next={next}
			hasMore={hasMore}
			loader={ProgressSpinnerBox()}
			className={className}
			style={{ overflow: 'hidden' }}
		>
			{ container(items) }
		</InfiniteScrollComponent>
	)
}

export default InfiniteScroll
