import React, { useEffect, useState, useCallback } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Icon from '@mdi/react';
import {
  mdiMagnify,
  mdiPlus,
  mdiCloudUpload,
  mdiCloudDownload,
  mdiDelete,
  mdiPencil,
} from '@mdi/js';
import { useHistory } from 'react-router-dom';

import './Documents.scss';

import { useQuery } from '../../lib/hooks';
import { isBackOfficeAccount } from '../../lib/permissions';
import { iconClear } from '../../lib/svg';

import { AppState } from '../../store';
import { setShowLoader } from '../../store/system';
import { generateEndpoint } from '../../lib/request';
import {
  getFiles,
  getFolders,
  resolveFolderStructure,
  createNewFolder,
  deleteFolders,
  deleteFiles,
  renameFolder,
  renameFile,
} from '../../store/documents';
import {
  getMergedDocuments,
  isDocumentsLoading,
  getResolvedFolderStructure,
  MergedDocument,
} from '../../store/documents/selectors';

import InputField from '../../components/Form/InputField';
import DeleteFilesConfirmationModal from '../../components/Documents/DeleteFilesConfirmationModal';
import RenameModal from '../../components/Documents/RenameModal';
import CreateNewFolderModal, {
  CreateNewFolderModalInput,
} from '../../components/Documents/CreateNewFolderModal';
import DocumentRow from '../../components/Documents/DocumentRow';
import { ENDPOINTS } from "../../endpoints";
import Breadcrumbs from '@material-ui/core/Breadcrumbs/Breadcrumbs';
import Link from '@material-ui/core/Link/Link';
import Typography from '@material-ui/core/Typography/Typography';
import { FileUploaderModal } from '../../components/Modal/FileUploaderModal';
import { toggleToast } from '../../store/components';
import { UIComponent } from '../../store/components/types';

const connector = connect(
  (state: AppState) => ({
    sessionUserType: state.system.sessionUserType,
    mergedList: getMergedDocuments(state),
    documentsLoading: isDocumentsLoading(state),
    resolvedFolderStructure: getResolvedFolderStructure(state),
  }),
  {
    setShowLoader,
    getFolders,
    getFiles,
    resolveFolderStructure,
    createNewFolder,
    deleteFolders,
    deleteFiles,
    renameFolder,
    renameFile,
    toggleToast,
  }
);

type DocumentsProps = ConnectedProps<typeof connector> & {};

export function Documents({
  sessionUserType,
  mergedList,
  documentsLoading,
  resolvedFolderStructure,
  setShowLoader,
  getFiles,
  getFolders,
  resolveFolderStructure,
  createNewFolder,
  deleteFolders,
  deleteFiles,
  renameFolder,
  renameFile,
  toggleToast,
}: DocumentsProps) {
  const { t } = useTranslation();
  const history = useHistory()

  const query = useQuery();
  const currentFolderId = query.get('folderId') ? query.get('folderId') : '';

  const isLoading = documentsLoading;

  const [selectedItemIds, setSelectedItemIds] = useState<string[]>([]);
  const [searchStr, setSearchStr] = useState('');
  const onSearchChange = (e: any) => {
    setSearchStr(e.target.value);
  }

  const selectRow = useCallback(
    (id: string, isSelected: boolean, multi = false) => () => {
      if (!multi) {
        isSelected && selectedItemIds.length <= 1
          ? setSelectedItemIds([])
          : setSelectedItemIds([id]);
      } else {
        isSelected
          ? setSelectedItemIds(
            selectedItemIds.filter((itemId) => itemId !== id)
          )
          : setSelectedItemIds([...selectedItemIds, id]);
      }
    },
    [selectedItemIds]
  );

  const deselectAll = () => {
    setSelectedItemIds([]);
  };

  useEffect(() => {
    setShowLoader(isLoading);
  }, [isLoading, setShowLoader]);

  useEffect(() => {
    getFolders(currentFolderId);
    getFiles(currentFolderId);
    resolveFolderStructure(currentFolderId);
  }, [currentFolderId, getFolders, getFiles, resolveFolderStructure]);

  useEffect(() => {
    if (!documentsLoading) {
      setSelectedItemIds([]);
    }
  }, [documentsLoading, mergedList]);

  // modal visibility switches
  const [renameModalVisible, setRenameModalVisible] = useState(false);
  const [
    createNewFolderModalVisible,
    setCreateNewFolderModalVisible,
  ] = useState(false);
  const [
    createNewFileModalVisible,
    setCreateNewFileModalVisible,
  ] = useState(false);
  const [
    deleteFilesConfirmationModalVisible,
    setDeleteFilesConfirmationModalVisible,
  ] = useState(false);


  const [parentFolders, currentFolder] = resolvedFolderStructure;

  const createNewFolderCallback = useCallback(
    ({ name }) => {
      setCreateNewFolderModalVisible(false);
      createNewFolder({ name, parentId: currentFolderId });
    },
    [currentFolderId, createNewFolder]
  );

  const [itemsToDelete, setItemsToDelete] = useState<string[]>([]);

  const deleteItems = useCallback(() => {
    setDeleteFilesConfirmationModalVisible(false);

    if (!itemsToDelete.length) {
      return;
    }

    const fileIds: string[] = [];
    const folderIds: string[] = [];

    itemsToDelete.forEach((id) => {
      const exists = mergedList.find((item) => item.id === id);

      exists &&
        (exists.type === 'folder' ? folderIds.push(id) : fileIds.push(id));
    });

    fileIds.length && deleteFiles(fileIds);
    folderIds.length && deleteFolders(folderIds);

    setItemsToDelete([]);
  }, [itemsToDelete, mergedList, setItemsToDelete, deleteFiles, deleteFolders]);

  const activateDeleteOnSelected = () => {
    setItemsToDelete(selectedItemIds);
    setDeleteFilesConfirmationModalVisible(true);
  };

  const activateDeleteOnCurrent = useCallback(
    (current: MergedDocument) => () => {
      const id = current.id;

      setItemsToDelete([id]);
      setDeleteFilesConfirmationModalVisible(true);
    },
    [setItemsToDelete]
  );

  const [itemToRename, setItemToRename] = useState<MergedDocument | null>(null);

  const generateDownloadLink = (type: string, id: string) => {
    const prefix = type === "file" ? '?file=' : '?directory='
    return type === 'file' ? generateEndpoint(ENDPOINTS.DOCUMENTS_FILE_DOWNLOAD, {}) + prefix + id :
      generateEndpoint(ENDPOINTS.DOCUMENTS_DIRECTORY_DOWNLOAD, {}) + prefix + id

  }

  const onDownload = () => {
    const [itemId] = selectedItemIds;
    const exists = mergedList.find(({ id }) => id === itemId);
    if (exists) {
      const downloadLink = generateDownloadLink(exists.type, exists.data.id)
      window.open(downloadLink, '_blank')?.focus();
    }
  };

  const activateRenameOnSelected = () => {
    setRenameModalVisible(true);
    const [itemId] = selectedItemIds;
    const exists = mergedList.find(({ id }) => id === itemId);

    exists ? setItemToRename(exists) : setItemToRename(null);
  };

  const activateRenameOnCurrent = useCallback(
    (current: MergedDocument) => () => {
      setRenameModalVisible(true);
      setItemToRename(current);
    },
    []
  );

  const goTo = (path: string) => {
    history.push(path)
  }

  const renameItem = useCallback(
    ({ name }: CreateNewFolderModalInput) => {
      setRenameModalVisible(false);

      if (!itemToRename) {
        return;
      }

      const payload = { id: itemToRename.id, name };
      itemToRename.type === 'folder'
        ? renameFolder(payload)
        : renameFile(payload);

      setItemToRename(null);
      getFolders(currentFolderId);
      getFiles(currentFolderId);
      resolveFolderStructure(currentFolderId);
    },
    [itemToRename, renameFolder, renameFile]
  );

  const filteredMergedList = mergedList.filter(item => item.data.name.toLowerCase().includes(searchStr.toLowerCase()))

  const [uploadOk, setuploadOk] = useState<UIComponent>()
  
  useEffect(() => {
    if(uploadOk?.toggleToast){
       toggleToast({
        shouldToggle: true,
        message: uploadOk.message,
        status: uploadOk.status
      })
      
      setuploadOk({
        toggleToast: false,
        message: "",
        status: 0
      })
    }
	getFolders(currentFolderId);
	getFiles(currentFolderId);
	resolveFolderStructure(currentFolderId);
  },[uploadOk])

  return (
    <div>
      <h1 data-testid="documents_headline">{t('navigation.Documents')}</h1>
      <RenameModal
        modalVisible={renameModalVisible}
        setModalVisible={setRenameModalVisible}
        item={itemToRename}
        onSave={renameItem}
      />
      <CreateNewFolderModal
        modalVisible={createNewFolderModalVisible}
        setModalVisible={setCreateNewFolderModalVisible}
        onSave={createNewFolderCallback}
      />
      <FileUploaderModal 
        modalVisible={createNewFileModalVisible} 
        setModalVisible={setCreateNewFileModalVisible}
        folderPath={currentFolderId ? currentFolderId : '/'}
        setuploadOk={setuploadOk}
      />
      <DeleteFilesConfirmationModal
        modalVisible={deleteFilesConfirmationModalVisible}
        setModalVisible={setDeleteFilesConfirmationModalVisible}
        onConfirmation={deleteItems}
      />
      <h3>
	 	 <Breadcrumbs aria-label="breadcrumb">
			<Link color="inherit" onClick={() => history.push('?folderId=')} style={{cursor: "pointer"}}>
				{t('common.Catalog')}
			</Link>
			{parentFolders.map(({ name, id }) =>
                <Link color="inherit" onClick={() => history.push(`?folderId=com.eon.ui:eon-ui:war:1.0-SNAPSHOT`)} style={{cursor: "pointer"}}>
                  {name}
                </Link>
            )}
			{ currentFolder &&
				<Link>
					<Typography color="textPrimary">{currentFolder.name}</Typography>
				</Link>
			}
		</Breadcrumbs>
      </h3>
      <form>
        <div className="form-row">
          <InputField
            name={'test'}
            onChange={onSearchChange}
            placeholder={t('common.Search')}
            value={searchStr}
            className="col-md-2"
            feedback={
              <Icon
                path={mdiMagnify}
                size="24"
                className="form-control-feedback icon-24"
              />
            }
          />
          <div className="col-md-10">
            {isBackOfficeAccount(sessionUserType) &&
              selectedItemIds.length === 0 && (
                <>
                  <button
                    type="button"
                    className="documents__button"
                    onClick={() => setCreateNewFolderModalVisible(true)}
                  >
                    <Icon
                      path={mdiPlus}
                      size="24"
                      className="icon-24 text-primary mr-2"
                    />
                    <div>{t('common.Create new folder')}</div>
                  </button>
                  <button type="button"
                    className="documents__button"
                    onClick={() => setCreateNewFileModalVisible(true)}
                  >
                    <Icon
                      path={mdiCloudUpload}
                      size="24"
                      className="icon-24 text-primary mr-2"
                    />
                    <div>{t('common.Upload file')}</div>
                  </button>
                </>
              )}

            {selectedItemIds.length > 0 && (
              <>
                <button
                  type="button"
                  className="documents__button"
                  onClick={deselectAll}
                >
                  <Icon
                    path={iconClear}
                    size="24"
                    className="icon-24 text-primary mr-2"
                  />
                  <div>
                    {t('common.X highlighted', { total: selectedItemIds.length })}
                  </div>
                </button>
              </>
            )}

            {selectedItemIds.length === 1 && (
              <>
                {isBackOfficeAccount(sessionUserType) && (
                  <button
                    type="button"
                    className="documents__button"
                    onClick={activateRenameOnSelected}
                  >
                    <Icon
                      path={mdiPencil}
                      size="24"
                      className="icon-24 text-primary mr-2"
                    />
                    <div>{t('common.Rename')}</div>
                  </button>
                )}
                <button type="button"
                  className="documents__button"
                  onClick={onDownload}
                >
                  <Icon
                    path={mdiCloudDownload}
                    size="24"
                    className="icon-24 text-primary mr-2"
                  />
                  <div>{t('common.Download')}</div>
                </button>
              </>
            )}

            {selectedItemIds.length > 0 && isBackOfficeAccount(sessionUserType) &&
				<button
					type="button"
					className="documents__button text-danger"
					onClick={activateDeleteOnSelected}
				>
					<Icon path={mdiDelete} size="24" className="icon-24 mr-2" />
					<div>{t('common.Delete')}</div>
				</button>
            }
          </div>
        </div>
        {filteredMergedList.map((document) => 
            <DocumentRow
              key={document.id}
              enableAminOptions={isBackOfficeAccount(sessionUserType)}
              document={document}
              isSelected={selectedItemIds.some((id) => id === document.id)}
              selectRow={selectRow}
              activateRenameOnCurrent={activateRenameOnCurrent}
              activateDeleteOnCurrent={activateDeleteOnCurrent}
              generateDownloadLink={generateDownloadLink}
            />
        )}
      </form>
    </div>
  );
}

export default connector(Documents);
