import  {Option} from '../../components/Form/SelectField';
import { List, Entity } from "../types";


export enum ServiceCaseStatus {
    NEW = "NEW",
    ONCOING = "ONGOING",
    COMPLETED = "COMPLETED",
	FEEDBACK_REQUIRED = "FEEDBACK_REQUIRED"
}

export enum ServiceCaseCategory {
    SERVICE_AND_SUPPORT = "SERVICE_AND_SUPPORT",
    ORDER = "ORDER",
    INVOICE = "INVOICE",
    KUNDPLATTFORM = "KUNDPLATTFORM",
    OTHER = "OTHER"
}

export enum FileCategory {
    NOT_SPECIFIED,
    IMAGE,
    DOCUMENT,
    RESALE_TERMS,
    CASE_MANAGEMENT_ATTACHMENT
}

export type ServiceCaseAssignForm = {
    status: Option,
    assignedTo: Option | null | undefined
    users: Option[]
}

export type ServiceCaseCustomerComment = {
    id: string
    comment: string
    commentHeader: string
    commentedBy: string
    resolvedFirstName: string
    resolvedLastName: string
    createdAt: Date
    updateAt: Date
}

export type CreateCaseFormType = {
    serviceCaseStatus: ServiceCaseStatus
    serviceCaseCategory: {
        value: string,
        key: string
    }
    facility: string
    caseDescription: string
    caseSubject: string
    caseCreatorResolved: string
    customerIdResolved: string
    casePriority: Option;
}

export type CreateCaseRequest = {
    serviceCaseStatus: string
    serviceCaseCategory: string
    facility: string
    caseDescription: string
    caseSubject: string
}

export type ServiceCaseInternalComment = {
    id: string
    comment: string
    commentHeader: string
    commentedBy: string
    resolvedFirstName: string
    resolvedLastName: string
    createdAt: Date
    updateAt: Date
}

export type File = {
    id: string
    uploadedByUserId: string
    physicalUrl: string
    filePath: string
    mimeType: string
    uploadedAt: string
    fileCategory: FileCategory
    originalName: string
    fileExtension: string
}


export type Case = {
    id: string
    serviceCaseStatus: ServiceCaseStatus
    serviceCaseCategory: ServiceCaseCategory
    customerId: string
    customerIdResolved: string
    caseSubject: string
    caseDescription: string
    caseOwnerResolved: string
    caseCreator: string;
    caseCreatorResolved: string;
    facility: string
    caseOwner: string
    createdAt: Date
    updatedAt: Date
    serviceCaseCustomerComments: ServiceCaseCustomerComment[]
    serviceCaseInternalComments: ServiceCaseInternalComment[]
    serviceCaseCommentFiles: File[]
    resolvedCaseCommentFiles: string[]
    casePriority: number
}


export type CasesState = {
    list: List<Case>;
    selected: Entity<Case>;
  };
