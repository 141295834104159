import { FormContextValues } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Order, OrderInputValues } from "../../store/orders/types"
import { Entity, EntityData } from "../../store/types"
import InputField from "../Form/InputField"


export interface OrderDetailsCardProps {
    handleInput: FormContextValues<OrderInputValues>
    userAdminType: boolean
    data: EntityData<Order>
    order: Entity<Order>
    readOnly: boolean
} 

const OrderDetailsCard: React.FC<OrderDetailsCardProps> = ({
    handleInput,
    userAdminType,
    data,
    order,
    readOnly
}) => {
    const { t } = useTranslation()

    return (
        <>
            <div className="col-md-12 col-sm-12">
                <div className="row">
                    <div className="col-12 mb-2" hidden={!readOnly && !userAdminType}>
                        <div className="card">
                            <div className='card-header' data-toggle="collapse" data-target="#orderinfo">
                                {t('order.Orderdetails')}
                                <i className="float-right arrow down"></i>
                                <i className="float-right arrow up"></i>
                            </div>
                            <div className="collapse show" id="orderinfo">
                                <div className='card-body'>
                                    <div className="row">
                                        <div className="col-4">
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <label>
                                                        {t('order.Order id')}
                                                    </label>
                                                    <p>
                                                        {data?.orderId}
                                                    </p>
                                                </div>

                                                <div className='col-12 mt-2'>
                                                    <label>
                                                        {t('order.Status')}
                                                    </label>
                                                    <p>
                                                        {t('common.' + data?.status ?? '')}
                                                    </p>
                                                </div>

                                                <div className='col-12 mt-2'>
                                                    <InputField
                                                        name="yourReference"
                                                        handler={handleInput}
                                                        label={t('order.Your refrence')}
                                                        type="text"
                                                        readOnly={readOnly}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <label>
                                                        {t('order.Order id fortnox')}
                                                    </label>
                                                    <p>
                                                        {data?.shopOrderViewResolver.orderNumber !== "" ? data?.shopOrderViewResolver.orderNumber : "-"}
                                                    </p>
                                                </div>
                                                <div className='col-12 mt-2'>
                                                    <label>
                                                        {t('order.Internal status')}
                                                    </label>
                                                    <p>
                                                        {t('common.' + order.data?.orderStatusInternal ?? '')}
                                                    </p>
                                                </div>
                                                <div className='col-12 mt-2'>
                                                    <InputField
                                                        name="ourReference"
                                                        handler={handleInput}
                                                        label={t('order.Our refrence')}
                                                        type="text"
                                                        readOnly={readOnly}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <label>
                                                        {t('order.Createdby email')}
                                                    </label>
                                                    <p>
                                                        <a href={"mailto:" + order.data?.customerReferenceEmail}>
                                                            {order.data?.customerReferenceEmail}
                                                        </a>
                                                    </p>
                                                </div>
                                                <div className='col-12 mt-2'>
                                                    <label>
                                                        {t('order.Createdby')}
                                                    </label>
                                                    <p>
                                                        {order.data?.customerReference}
                                                    </p>
                                                </div>
                                                <div className='col-12 mt-2'>
                                                    <label>
                                                        {t('order.Your ordernumber')}
                                                    </label>
                                                    <InputField
                                                        name="yourOrderNumber"
                                                        handler={handleInput}
                                                        placeholder={t('order.Your ordernumber')}
                                                        readOnly={readOnly}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OrderDetailsCard