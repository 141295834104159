import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { AppState } from "../../store";
import { downloadPriceList, downloadPriceListCSV } from '../../store/products';
import { setShowLoader } from '../../store/system';
import { getActiveUser } from '../../store/system/index';

const connector = connect(
    (state: AppState) => ({
        system: state.system
    }),
    { setShowLoader, downloadPriceList, getActiveUser, downloadPriceListCSV }
);

type PriceListProps = ConnectedProps<typeof connector> & {};

export function PriceList({
    system,
    getActiveUser,
    downloadPriceList,
	downloadPriceListCSV,
    setShowLoader,
} : PriceListProps) {

    const {
        sessionUser,
        showLoader
      } = system;

    useEffect(() => {
        getActiveUser()
    }, [getActiveUser])


    const downloadFile = () => {
        let customerId = sessionUser?.userPermission.belongsToCustomer === null 
        || sessionUser?.userPermission.belongsToCustomer === undefined ? "" : 
        sessionUser?.userPermission.belongsToCustomer
        downloadPriceList({customerId: customerId})
    }

    const downloadCSV = () => {
        let customerId = sessionUser?.userPermission.belongsToCustomer === null 
        || sessionUser?.userPermission.belongsToCustomer === undefined ? "" : 
        sessionUser?.userPermission.belongsToCustomer
        downloadPriceListCSV({customerId: customerId})
    }

    useEffect(() => {
        setShowLoader(showLoader);
    }, [showLoader]);

    return (
        <div className="container-fluid" style={{ display: 'flex', gap: 10 }}> 
			<button className="btn btn-primary" onClick={downloadFile}>Ladda ner prislista</button>
			<button className="btn btn-primary" onClick={downloadCSV}>Ladda ner prislista som CSV</button>
        </div>
    );
}

export default connector(PriceList);