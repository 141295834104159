import { AxiosResponse } from "axios";

export const headerFilenameExtension = (r : AxiosResponse) =>{
    var filename = "";
    var disposition = r.headers['content-disposition']
    if (disposition && disposition.indexOf('attachment') !== -1) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) { 
          filename = matches[1].replace(/['"]/g, '');
        }
    }
  
    if(filename === ""){
      let extension: string = r.headers['file-extension'];
      filename =  'prislista'.concat('.').concat(extension.split('/')[1])
    }
    
    return filename
  }