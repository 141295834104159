import React from 'react';
import { EditOrderInput } from './EditOrderInput';
import { shopOrderShipments } from '../../store/orders/types';

interface ShipmentInformationProps {
    shopOrderShipments: shopOrderShipments[]
}

const ShipmentInformation = ({
    shopOrderShipments
}: ShipmentInformationProps) =>
	<>
		<hr />
		<label>Leveransinformation</label>
		{shopOrderShipments.map( sos =>

			<>
				<EditOrderInput
					name="Speditör"
					value={sos.shipmentMethod}
				/>
				
				<EditOrderInput
					name="Leveransnummer"
					value={sos.shipmentNumber}
				/>

				{sos.shipmentPackages.map(sp =>
					<div className="input-group mb-3">
						<div className="input-group-prepend">
							<span className="input-group-text">Kollinr</span>
						</div>
						<input
							type="text"
							className="form-control"
							placeholder="Kollinr"
							aria-label="Kollinr"
							value={sp}
							readOnly 
						/>
					</div>
				)}
				<hr />
			</>

		)}

		{shopOrderShipments.length === 0 &&
			<p className="ml-1">Ingen leveransinformation från speditör finns</p>
		}
		<hr />
	</>

export default ShipmentInformation