import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { AppState } from '../../../store'
import { connect, ConnectedProps } from 'react-redux'
import { checkout, getCheckoutInformation, getWayOfDeliveryOptions, destroyCart } from '../../../store/checkout'
import { useHistory } from 'react-router-dom'
import { CartCheckout } from '../../../store/checkout/types'
import CheckoutConfirmationCard from './CheckoutConfirmationCard'
import Box from '@material-ui/core/Box/Box'
import CheckoutForm, { CheckoutFields } from '../../EShop/Checkout/CheckoutForm/CheckoutFormContainer'
import ProductList from '../ProductCards/ProductList'
import useBoolean from '../../../hooks/useBoolean'
import DeliveryTerms from './DeliveryTerms'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { contextEShopCart } from '../../EShop/Providers/EShopCartProvider'
import Grid from '@material-ui/core/Grid/Grid'
import Typography from '@material-ui/core/Typography/Typography'
import Divider from '@material-ui/core/Divider/Divider'
import Collapse from '@material-ui/core/Collapse/Collapse'
import Paper from '@material-ui/core/Paper/Paper'
import LinearProgress from '@material-ui/core/LinearProgress/LinearProgress'
import { contextEShopCategory } from '../../EShop/Providers/EShopCategoryProvider'
import Alert from '@material-ui/lab/Alert/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle/AlertTitle'
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox/Checkbox'
import { useMemo } from 'react'
import {useTranslation} from 'react-i18next';
import DialogWarning from '../../DialogWarning/DialogWarning'
import Loader from '../../Loader'

const connector = connect(
    (state: AppState) => ({
		checkoutResponse: state.checkouts.response,
		checkoutInformation: state.checkouts.selected,
		deliveryOptions: state.checkouts.deliveryOptions,
		
    }),
    { checkout, getCheckoutInformation, getWayOfDeliveryOptions, destroyCart }
);

type CheckoutProps = ConnectedProps<typeof connector> & {};

export function Checkout({
	checkout,
	checkoutResponse,
	checkoutInformation,
	getCheckoutInformation,
	destroyCart,
	getWayOfDeliveryOptions,
	deliveryOptions,

}: CheckoutProps) {
	useEffect(() => { getWayOfDeliveryOptions() }, [getWayOfDeliveryOptions])
	
	const history = useHistory()
	const {t} = useTranslation();
	const [ initialLoading, , { setFalse: hasLoaded } ] = useBoolean(true)
	const loadingTimeout = useRef<NodeJS.Timeout | undefined>(undefined)
	initialLoading && (loadingTimeout.current || checkoutInformation.loading || setTimeout(hasLoaded, 5000))

	const { cart } = useContext(contextEShopCart)
	const { gotoProduct } = useContext(contextEShopCategory)
	const { total, productMethods, items: cartItems } = cart ?? {}

	useEffect(() => {
		if(initialLoading || checkoutInformation?.data) return
		getCheckoutInformation()
	}, [getCheckoutInformation, initialLoading])
	
	const [ input, setInput ] = useState<undefined | CheckoutFields & { isReadyForCheckout: boolean }>()
	const [ userConsent, , { toggle: toggleUserConsent } ] = useBoolean()
    const [ isOrderFinished, setIsOrderFinished, { setFalse: setOrderToUnfinished } ] = useBoolean()

	const information = useMemo(() => checkoutInformation.data ? {
		...checkoutInformation.data,
	} : undefined, [checkoutInformation])

	const [ inPartialDelivery, setInPartialDelivery ] = useState<{ fnArticleId: string, productId: string }[] | undefined>()
	const toggleInPartialDelivery = useCallback((productId: string, fnArticleId: string) => {
		if(inPartialDelivery?.some(item => item.productId === productId)) {
			const items = inPartialDelivery.filter(item => item.productId !== productId)
			setInPartialDelivery(items.length ? items : undefined)
		} else setInPartialDelivery(items => [...items??[], { productId, fnArticleId } ])
	}, [inPartialDelivery])
	useEffect(() => {
		if(input?.partlyDelivery || !inPartialDelivery) return
		setInPartialDelivery(undefined)
	}, [inPartialDelivery, input?.partlyDelivery])

	const handleCheckout = useCallback(() => {
		if(!input?.isReadyForCheckout) return
		const checkoutData: CartCheckout = {
			...input,
			preliminaryDeliveryDate: input.proposedDeliveryDate?.toISOString() || checkoutInformation.data?.preliminaryDeliveryDate || undefined,
			partlyDeliveryProposedDeliveryDate: input.partlyDelivery ? input.partlyDeliveryProposedDeliveryDate?.toISOString() || undefined : undefined,
			proposedDeliveryDate: input.proposedDeliveryDate?.toISOString() || undefined,
			partlyDeliveryProducts: inPartialDelivery,
		}
		checkout(checkoutData)
		setHandleCloseConfirm(true)
		setHandleCloseModal(false); 
		history.block(() => {});
		setIsOrderFinished(true)
	}, [input, inPartialDelivery, checkout, setIsOrderFinished])

	useEffect(() => {
		if(!checkoutInformation.data || !cartItems) return
		hasLoaded()

		if(loadingTimeout.current) {
			clearTimeout()
			loadingTimeout.current = undefined
		}
	}, [cartItems, checkoutInformation, hasLoaded])


	useEffect(() => {
		if(checkoutInformation?.data?.restrictedToOffer){
			return () => {
				destroyCart()
			}
		}
	},[])

	const [handleCloseModal, setHandleCloseModal] = useState(false)
    const [handleCloseConfirm, setHandleCloseConfirm] = useState(false)
	
	useEffect(() => {
		if(checkoutInformation?.data?.restrictedToOffer === undefined && 
			!checkoutInformation?.data?.restrictedToOffer) return

		if(checkoutInformation?.data?.restrictedToOffer && !handleCloseConfirm && !handleCloseModal){
			history.block(tl => {
				setHandleCloseModal(true) 
				
				return false;
		   })
		}
	},[handleCloseModal,checkoutInformation?.data?.restrictedToOffer])
	
	useEffect(()=>{
		if(handleCloseConfirm){
			setHandleCloseModal(false)
		}

	},[handleCloseConfirm])

	const handleConfirmAndNav = () => {
		setHandleCloseConfirm(true)
		setHandleCloseModal(false); 
		history.block(() => {});
		destroyCart();
		history.goBack()
	}

	const [price, setPrice] = useState<number>(0)

	useEffect(() => {
		if(checkoutInformation?.data?.otherCost === undefined && 
			checkoutInformation?.data?.otherCost === null && 
			checkoutInformation?.data?.transportCost === undefined &&
			checkoutInformation?.data?.transportCost === null) return

		let totPri = 0
		if(checkoutInformation?.data?.otherCost !== undefined)
			totPri+=checkoutInformation?.data?.otherCost

		if(checkoutInformation?.data?.transportCost !== undefined)
			totPri+=checkoutInformation?.data?.transportCost
		
		if(total?.price !== undefined)
			totPri += total?.price

		setPrice(totPri)
	},[checkoutInformation?.data?.transportCost, checkoutInformation?.data?.otherCost, total?.price, setPrice])

	const allowCheckout = userConsent && !!total?.amount && input?.isReadyForCheckout && !checkoutInformation?.data?.customerRestrictionsActive

	return !isOrderFinished ? 
		<> 
			<Loader fixed show={checkoutInformation.loading}/>
			<DialogWarning
				open={handleCloseModal}
				subject={t('modal.Are you sure?')}
				title={t('modal.Are you sure?')}
				content={t('modal.If you cancel your changed data will get lost.')}
				onConfirm={() => {handleConfirmAndNav()}}
				onClose={() => setHandleCloseModal(false)}
			/>
			<Box mb={2}>
				<h1>Översikt</h1>
			</Box>
			<Grid container direction="row-reverse" justify="flex-end" style={{ gridGap: '6em' }}>
			<Box>
				<ProductList 
					title="Order"
					products={cartItems?.length ? cartItems.map(item => ({
						id: item.articleId,
						name: item.articleName,
						quantity: item.amountOfInCart,
						image: item.publicImage,
						articlePrice: item.articlePrice,
						articleNumber: item.fnArticleId,
						disposableQuantity: item.disposableQuantity,
						amountOfInCart: item.amountOfInCart,
						maxOrderQuantity: item.maxOrderQuantity,
						b2COrder: information?.b2COrder !== undefined ? information?.b2COrder : false,
					})) : undefined}
					totalAmount={total?.amount}
					totalPrice={price} 
					increment={productMethods?.increment}
					decrement={productMethods?.decrement}
					remove={productMethods?.remove}
					userCanToggleShowImage={true}
					visit={gotoProduct}
					loading={initialLoading || checkoutInformation.loading}
					inPartialDelivery={inPartialDelivery}
					toggleInPartialDelivery={input?.partlyDelivery ? toggleInPartialDelivery : undefined}
					isLocked={checkoutInformation?.data?.restrictedToOffer}
					otherCost={checkoutInformation?.data?.otherCost}
					transportCost={checkoutInformation?.data?.transportCost}
						/>
					<Box mt={3}>
						{!!cartItems?.length && <span
							onClick={() => setHandleCloseModal(true)}
							className="btn btn-danger mr-2 float-right">
							{t('common.Empty cart')}
						</span>}
					</Box>
			</Box>
				
				<Box mt={3}>
					<Collapse in={initialLoading && !checkoutInformation.loading}>
						<Box mb={3}>
							<Alert severity="info" variant="outlined">
								<AlertTitle>Hämtar information</AlertTitle>
								<LinearProgress />
							</Alert>
						</Box>
					</Collapse>
					<Collapse in={!initialLoading && !!loadingTimeout.current && !checkoutInformation.loading}>
						<Box mb={3}>
							<Alert severity="warning" variant="outlined">
								Kunde inte hämta order information
							</Alert>
						</Box>
					</Collapse>
					<Collapse in={!initialLoading && !!total && !total.amount && !checkoutInformation.loading}>
						<Box mb={3}>
							<Alert severity="warning" variant="outlined">
								Din varukorg är tom
							</Alert>
						</Box>
					</Collapse>
					<Collapse in={checkoutInformation.data?.customerRestrictionsActive && !checkoutInformation.loading}>
						<Box mb={3} maxWidth="80ch">
						<Alert severity="error" variant="outlined">
							{checkoutInformation.data?.customerRestrictionComments}
						</Alert>
						</Box>
					</Collapse>
					<Typography variant="h6" color="textSecondary">Orderdetaljer</Typography>
					
					<CheckoutForm 
						onValuesChange={setInput}
						information={information}
						loading={checkoutInformation.loading}
						deliveryOptions={deliveryOptions.data ?? []}
						restrictedToOffer={checkoutInformation?.data?.restrictedToOffer}
						onDelete={() => setHandleCloseModal(true)}
					/>
				</Box>
			</Grid>

			<Box maxWidth="80ch">
				<Box py={2}>
					<Divider />
				</Box>
				<DeliveryTermsAccordion 
					userConsent={userConsent}
					toggleUserConsent={toggleUserConsent}
				/>
				<Box py={2}>
					<Divider />
				</Box>
				<Box display="flex">
					<button 
						type="button" 
						onClick={history.goBack} 
						className="btn btn-lg btn-block" 
						style={{ border: 'none', boxShadow: 'none' }}
						disabled={checkoutResponse.loading}
					>
						<Typography component="span" variant="body2">Gå tillbaka</Typography>
					</button>
					<button
						type="button" 
						onClick={handleCheckout} 
						className="btn btn-lg btn-primary btn-block"
						disabled={!allowCheckout}
					>
						{ checkoutResponse.loading && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
						<Typography component="span" variant="body1">{' '+'Lägg order'}</Typography>
					</button>
				</Box>
			</Box>
		</>
        : !cartItems || ( !cartItems.length) ?
		<>
			<div className="row">
				<div className="row">
					<div className="col">
						<h4 className="mb-5">Varukorgen är tom :(</h4>
					</div>
				</div>
			</div>
			<div className="row">
				<button type="button" className="btn btn-primary" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" onClick={history.goBack}>
					Gå tillbaka till E-shoppen
				</button>
			</div>
		</> 
		: 
		<CheckoutConfirmationCard
			checkoutResponse={checkoutResponse}
			setOrderToUnfinished={setOrderToUnfinished}
		/>
}


interface DeliveryTermsAccordionProps {
	userConsent: boolean
	toggleUserConsent: () => void
}

const DeliveryTermsAccordion: React.FC<DeliveryTermsAccordionProps> = ({
	userConsent,
	toggleUserConsent
}) => {
	const [ open, , { toggle, setFalse: close } ] = useBoolean()
	const transform = open ? 'rotate(180deg)' : 'rotate(0)'

	return (
		<Box py={1}>
			<FormControlLabel
				value="end"
				checked={userConsent}
				control={<Checkbox color="primary" disableRipple />}
				onChange={() => { close(); toggleUserConsent() }}
				label={
					<Box onClick={toggle} className="form-check-label cursor-pointer" display="flex" gridGap={5} component="label">
						<Typography variant="body1" noWrap>
							Jag godkänner köp och leveransvillkor  
						</Typography>
						<ExpandMore style={{ transition: 'transform .3s ease', transform }} />
					</Box>
				}
				labelPlacement="end"
			/>
			<Collapse in={open}>
				<Paper elevation={0} square>
					<Box maxHeight={600} style={{ overflowY: 'scroll' }} px={2}>
						<Typography component="div">
							{DeliveryTerms()}
						</Typography>
					</Box>
				</Paper>
			</Collapse>
		</Box>
	)
}


export default connector(Checkout);