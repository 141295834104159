import { Box } from '@material-ui/core'
import { Add, Remove } from '@material-ui/icons'
import React, { useState, useEffect, useCallback, useRef } from 'react'

interface NumberInputProps {
	value: number
	setValue?: (value: number) => void
	inputHeight?: any
	options?: {
		min?: number
		max?: number
		rate?: number
	}
	disabled?: boolean
}

const NumberInput: React.FC<NumberInputProps> = ({
	value, 
	setValue,
	inputHeight = 38,
	options: {
		min = 0,
		max,
		rate = 1
	} = {},
	disabled
}) => {
	const [ localValue, setLocalValue ] = useState(value)

	useEffect(() => setLocalValue(value), [value])

	const handleBlur = useCallback((e?: any) => {
		if(event.current !== undefined) return
		let input = +(e?.target.value ?? localValue)
		
		input = max && input > max ? max
		: input < min ? min
			: input
			
		input = isNaN(input) ? min : input
		
		event.current = undefined
		e && setLocalValue(input)
		if(value === input) return
		setValue?.(input)
	}, [localValue, max, min, setValue, value])

	const bind = {
		value: localValue,
		onChange: (e: any) => setLocalValue(+e.target.value),
		onKeyDown: (e: any) => e.key === 'Enter' && e.target.blur(),
		onBlur: handleBlur,
		disabled: !setValue || disabled,
		type: 'number'
	}

	const event = useRef<'inc' | 'dec' | 'blur' | undefined>(undefined)

	const increment = () => (!max || value < max) && setLocalValue(v => max ? (v + rate) > max ? max : (v += rate) : (v += rate) )

	const decrement = () => value > min && setLocalValue(v => (v - rate) > min ? (v -= rate) : min )

	const timeout = useRef<any>(), interval = useRef<any>()
	const getBtnProps = useCallback((method: () => void) => ({ 
		className: "btn btn-outline-default btn-sm", 
		style: { border: 'none', boxShadow: 'none' },
		onMouseDown: () => { method(); timeout.current = setTimeout(() => interval.current = setInterval(() => method(), 50), 200)},
		onMouseUp: () => { clearTimeout(timeout.current); clearInterval(interval.current); handleBlur() },
		onMouseLeave: () => { clearTimeout(timeout.current); clearInterval(interval.current); handleBlur() },
		disabled: disabled || !setValue
	}), [disabled, handleBlur, setValue])

	return (
		<Box display="flex" justifyContent="center">
			<button type="button" {...getBtnProps(decrement)}><Remove color="action" fontSize="small" /></button>
			<input style={{ height: inputHeight, maxWidth: 50, textAlign: 'center' }} {...bind} />
			<button type="button" {...getBtnProps(increment)}><Add color="action" fontSize="small" /></button>
		</Box>
	)
}

export default NumberInput
