import React from 'react';
import {useTranslation} from 'react-i18next';
import classnames from 'classnames';

import './Loader.scss';

export type LoaderProps = {
  absolute?: boolean;
  fixed?: boolean;
  show?: boolean;
  containerClassName?: string;
};

export default function Loader({
  fixed,
  absolute,
  show,
  containerClassName,
}: LoaderProps) {
  const {t} = useTranslation();

  return (
    <>
      <div
        data-testid="loader__container"
        className={classnames('loader__container', containerClassName, {
          'loader__container--absolute': absolute,
          'loader__container--fixed': fixed,
          'd-none': show === false,
        })}
      >
        <div className="loader__wrapper">
          <div className="loader__message">
            <span className="loader__text">{t('common.Loading')}</span>
            <span className="loader__dot--1">.</span>
            <span className="loader__dot--2">.</span>
            <span className="loader__dot--3">.</span>
          </div>
          <div className="loader__spinner"></div>
        </div>
      </div>
      {(absolute || fixed) && show && (
        <div
          data-testid="loader__blur"
          className={classnames('loader__blur')}
        />
      )}
    </>
  );
}
