import React, {CSSProperties} from 'react';
import ReactDatePicker, {registerLocale} from 'react-datepicker';
import {Controller, FormContextValues} from 'react-hook-form';
import classnames from 'classnames';
import {useTranslation} from 'react-i18next';
import sv from 'date-fns/locale/sv';

import 'react-datepicker/dist/react-datepicker.css';

import {extractErrorText, getErrorTestId, getErrorObj} from '../../lib/form';

registerLocale('sv', sv);

export interface DatePickerProps<T> {
    name: string;
    className?: string;
    rules?: any;
    label?: string | React.ReactElement;
    placeholder?: string;
    defaultValue?: any;
    errorText?: string;
    handler: FormContextValues<T>;
    dateFormat?: undefined | string
    style?: CSSProperties
    minDate?: Date
}

export default function DateTimePicker<T>({
                                          name,
                                          className,
                                          rules,
                                          label,
                                          placeholder,
                                          defaultValue,
                                          handler,
                                          dateFormat,
                                          style,
                                          minDate,
                                      }: DatePickerProps<T>) {
    const {t} = useTranslation();

    const id = `datepicker__eon-ui`;

    const elementError = getErrorObj(handler?.errors, name);
    const errorText = extractErrorText(elementError, rules, t);

    const filterPassedTime = (time: Date) => {
        const currentDate = new Date();
        currentDate.setHours(currentDate.getHours() - 1); /* Allow 1 hour before current time */
        const selectedDate = new Date(time);

        return currentDate.getTime() < selectedDate.getTime();
    };

    placeholder = placeholder === null
        ? undefined
            : placeholder === undefined && typeof label === 'string'
                ? label
                    : placeholder;

    return (
        <div className={classnames('form-group', className)} style={style}>
            {label === undefined ? null : <label htmlFor={id}>{label}</label>}
            <Controller
                as={(args: any) => (
                    <ReactDatePicker
                        {...args}
                        id={id}
                        locale="sv"
                        dateFormat={dateFormat !== undefined ? dateFormat : "P"}
                        wrapperClassName="datepicker__wrapper"
                        minDate={minDate ?? new Date()}
                        showTimeSelect={true}
                        timeFormat={"HH:mm"}
                        timeIntervals={15}
                        timeCaption={"Tid"}
                        filterTime={filterPassedTime}
                    />
                )}
                control={handler.control}
                rules={rules}
                valueName="selected" // DateSelect value's name is selected
                onChange={([selected]) => selected}
                name={name}
                className={classnames('form-control', {
                    border: elementError,
                    'border-danger': elementError,
                })}
                placeholderText={placeholder}
                defaultValue={defaultValue}
                register={{ required: true }}
            />
            {elementError ? (
                <span
                    className="form-control-error text-danger"
                    data-testid={getErrorTestId(elementError)}
                >
                    {errorText}
                </span>
            ) : null}
        </div>
    );
}
