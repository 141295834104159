import { AxiosError } from "axios"

export type ErrorResponseResolver = {
    message: string;
    status: number;
    errorInformation?: {
		errorToLog: string
		errorToClient: string
		error: boolean
		message: string
		code: number
	},
}

export default function errorResponse(axiosError: any): ErrorResponseResolver {
    const err = axiosError as AxiosError
    if (err.response) {
        if (err.response.data.message !== undefined) {
            return {
                message: err.response.data.message,
                status: err.response.status,
                errorInformation: err.response.data?.errorInformation
            }
        }
        else if (err.response.data.errorInformation !== undefined) {
            return {
                message: err.response.data.errorInformation.message,
                status: err.response.status
            }
        }
    }
    return {
        message: "Kunde inte genomföra operationen.",
        status: 0
    }
}
