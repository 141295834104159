import React from 'react';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';

import Modal from '../Modal';
import InputField from '../Form/InputField';

export type CreateNewFolderModalInput = {name: string};

export default function CreateNewFolderModal({
  modalVisible,
  setModalVisible,
  onSave,
}: {
  modalVisible: boolean;
  setModalVisible: (f: boolean) => void;
  onSave: (inputs: CreateNewFolderModalInput) => void;
}) {
  const {t} = useTranslation();
  const handleInput = useForm<CreateNewFolderModalInput>({mode: 'onChange'});

  return (
    <Modal
      name="crate-new-folder"
      modalVisible={modalVisible}
      setModalVisible={setModalVisible}
      title={t('common.Create new folder')}
      dialogClassName="modal-mw-small modal-dialog-centered"
    >
      <form onSubmit={handleInput.handleSubmit(onSave)}>
        <InputField
          name="name"
          handler={handleInput}
          rules={{required: true}}
          placeholder={t('common.Folder name')}
        />
        <div className="float-right mt-7">
          <button
            type="button"
            className="btn btn-info mr-2"
            data-dismiss="modal"
          >
            {t('common.Cancel')}
          </button>
          <button type="submit" className="btn btn-primary">
            {t('common.Create')}
          </button>
        </div>
      </form>
    </Modal>
  );
}
