import React, {useState, useEffect, useCallback} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {
  Route,
  Redirect,
  RouteProps,
  RouteComponentProps,
} from 'react-router-dom';

import {AppState} from '../store';
import {clearSession, destroySession, getActiveUser} from '../store/system';

import {hasAccess} from '../lib/permissions';

import {
  NAVIGATION_FALLBACK,
  NAV_LIST,
  ACCESS_MODULE_MAP,
  PATH_AUTH_LOGIN,
  PATH_ACCESS_DENIED,
  PATH_NOT_FOUND,
} from './paths';

import {withLayout} from '../components/Layout/Layout';
import Loader from '../components/Loader';

const connector = connect(
  (state: AppState) => ({
    system: state.system,
  }),
  {clearSession, destroySession, getActiveUser}
);

type PrivateRouterProps = ConnectedProps<typeof connector> &
  RouteProps & {
    component:
      | React.ComponentType<RouteComponentProps<any>>
      | React.ComponentType<any>;
  };

export function PrivateRoute({
  component,
  system,
  clearSession,
  destroySession,
  getActiveUser,
  ...props
}: PrivateRouterProps) {
  const {
    session,
    initialSessionChecked,
    sessionUserType,
    permissionAccessModule,
  } = system;
  const [authChecked, setAuthChecked] = useState(false);

  const requiredAccessModules =
    typeof props.path === 'string' ? ACCESS_MODULE_MAP[props.path] : undefined;

  useEffect(() => {
    if (session === null) {
      setAuthChecked(true);
    } else if (session.expiresAt <= Date.now()) {
      // the next useEffect block will clear the session
      return;
    } else {
      if (initialSessionChecked) {
        setAuthChecked(true);
      } else {
        getActiveUser();
      }
    }
  }, [session, initialSessionChecked, clearSession, getActiveUser]);
  
  const now = Date.now();

  // needs to be separated, so the initialization part runs only once
  // and when the user navigates / the PrivateRoute is rendered we check the session lifetime
  useEffect(() => {
    if (session && session.expiresAt <= now) {
      clearSession();
    }
  }, [now, session, clearSession]);

  const logout = useCallback(() => {
    destroySession();
  }, [destroySession]);

  // wait till we have every data we may need
  // otherwise me may redirect the user to other page
  if (!authChecked) {
    return <Loader />;
  }

  return (
    <Route
      {...props}
      render={(routeProps: RouteProps) => {
        // user isn't authenticated
        if (session === null) {
          return (
            <Redirect
              to={{
                pathname: PATH_AUTH_LOGIN,
                state: {from: routeProps.location},
              }}
            />
          );
        }

        // unknown private route? for security purpose redirect to 403
        // its easier to fix later than show something we shouldn't
        // by showing 403 it's easier to distinguish between not having rights and missing something
        if (requiredAccessModules === undefined) {
          return (
            <Redirect
              to={{
                pathname: PATH_ACCESS_DENIED,
                state: {from: routeProps.location},
              }}
            />
          );
        }

        const hasTheAccess = hasAccess(
          sessionUserType,
          permissionAccessModule,
          requiredAccessModules
        );
     
        /* TODO I WAS HERE. */

        return hasTheAccess ? (
          withLayout(component)({
            navigationFallback: NAVIGATION_FALLBACK,
            navigationList: NAV_LIST,
            logout,
            ...routeProps,
          })
        ) : (
          <Redirect
            to={{
              pathname: PATH_NOT_FOUND,
              state: {from: routeProps.location},
            }}
          />
        );
      }}
    />
  );
}

export default connector(PrivateRoute);
