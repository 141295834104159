import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import DropdownButton, { DropdownItem } from "../../components/DropdownButton";
import { isBackOfficeAccount } from '../../lib/permissions';
import { PATH_CASES_BACK_OFFICE_EDIT, PATH_CASES_BACK_OFFICE_VIEW, PATH_CASES_BACK_OFFICE_VIEW_COMMENTS } from '../../router/paths';
import { Case } from '../../store/cases/types';
import { JocularUser, Module } from '../../store/users/types';

  export type CaseManagementListRowDropDownProps = {
        row: Case,
        user: JocularUser | undefined
        setPickedServiceCaseToDelete: (value: React.SetStateAction<string>) => void
        setDeleteModalVisible:  (value: React.SetStateAction<boolean>) => void
  };
  
  export function CaseManagementListRowDropDown({
    row,
    setPickedServiceCaseToDelete,
    setDeleteModalVisible,
    user
  }: CaseManagementListRowDropDownProps) {
    const {t} = useTranslation()
    const history = useHistory()
    const [dropdown, setDropdown] = useState<DropdownItem[]>([])
	
    useEffect(() => {
		setDropdown([
			{ text: t('common.Comments'), onClick: () => history.push(PATH_CASES_BACK_OFFICE_VIEW_COMMENTS.replace(":serviceCaseId", row.id))},
			...user != undefined ? [
				{ text: t('common.View'), onClick: () => history.push(PATH_CASES_BACK_OFFICE_VIEW.replace(":serviceCaseId", row.id)) }
			] : [],
			...user?.sessionUser?.userPermission?.accessModules.some(a => a.module === Module.CASE_MANAGEMENT) && isBackOfficeAccount(user?.sessionUserType) ? [
				{ text: t('common.Edit'), onClick: () => history.push(PATH_CASES_BACK_OFFICE_EDIT.replace(":serviceCaseId", row.id)) },
        {
					text: t('common.Delete'), className: 'text-danger',
					onClick: () => {
						setPickedServiceCaseToDelete(row.id)
						setDeleteModalVisible(true)
					}
				}
			] : []
		])   
    }, [user, t, history, setPickedServiceCaseToDelete, setDeleteModalVisible, setDropdown, row.id])
    
    return (
           <DropdownButton
                id={`company__option__dropdown_${row.id}`}
                label={t('common.Options')}
                dropdownList={dropdown}
            />
    );
  }
  
  export default CaseManagementListRowDropDown;
  