import React, {useEffect, useState} from 'react';
import bannermessages from '../services/bannermessageService';
import {BannerMessage} from "../store/bannermessages/types";
import {AxiosResponse} from "axios";

const useMessageBanner = () => {
    const [messageBanner, setMessageBanner] = useState<BannerMessage[]>();
    const [response, setResponse] = useState<AxiosResponse>();
    const [messageId, setMessageToHide] = useState<string>("");
    const [message, setMessage] = useState<BannerMessage>();
    const [responseSpecific, setResponseSpecific] = useState<AxiosResponse>()

    useEffect(() => {

    }, [response, setResponse]);

    const getAllActiveMessages = async (): Promise<void> => {
        try {
            let response = await bannermessages.getAllActiveBannerMessages();
            if (response?.data)
                setMessageBanner(response?.data as BannerMessage[])
        } catch (e: any) {
            setResponse({statusText: "Something went wrong", status: 400} as AxiosResponse)
        }
    }

    const messageToHide = () => {
        try{
            if(messageId !== "")
                bannermessages.inactivateBannerMessage(messageId);
        } catch (e) {
            setResponse({statusText: "Something went wrong", status: 400} as AxiosResponse)
        }
    }

    const getSpecificBannerMessage = async (messageId: string): Promise<void> => {
        try {
            let responseSpecific = await bannermessages.getSpecificBannerMessage(messageId);
            if (responseSpecific?.data)
                setMessage(responseSpecific?.data as BannerMessage)
        } catch (e: any) {
            setResponseSpecific({statusText: "Something went wrong", status: 400} as AxiosResponse)
        }
    }

    return {
        getAllActiveMessages,
        messageBanner,
        response,
        messageToHide,
        setMessageToHide,
        messageId,
        getSpecificBannerMessage,
        message,
        responseSpecific
    }
}

export default useMessageBanner