import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { ENDPOINTS } from "../../endpoints";
import { request } from "../../lib/request";
import { Countries } from "./types";

export function useGetCountries(): {
    isLoading: boolean;
    countries: Countries[];
    setCountries: Dispatch<SetStateAction<Countries[] | null>>;
  } {
    const [countries, setCountries] = useState<any | null>([]);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
      try {
		request({
			endpoint: ENDPOINTS.E_SHOP_FRONT_COUNTRIES_READ,
			method: 'GET',
		  }).then(({data}) => {
			setCountries(data);
			setIsLoading(false);
		  });
	  } catch (error) {
		setCountries(null)
		setIsLoading(false)
	  }
    }, [request]);

    return {isLoading, countries, setCountries};
}
  