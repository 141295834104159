import axiosInstance, { request } from '../lib/request';

import { ENDPOINTS } from '../endpoints';
import { generatePath } from 'react-router-dom';
import { CreateCaseFormType, CreateCaseRequest, ServiceCaseCustomerComment, ServiceCaseStatus } from '../store/cases/types';




const methods = {
    getAllCases() {
    return request({
      endpoint: ENDPOINTS.CASE_MANAGEMENT_GET_CASES,
      method: 'GET'
    });
  },
  getAllCustomerRelatedCases() {
    return request({
      endpoint: ENDPOINTS.CASE_MANAGEMENT_GET_CUSTOMER_RELATED_CASES,
      method: 'GET'
    });
  },
  getAllUserRelatedCases() {
    return request({
      endpoint: ENDPOINTS.CASE_MANAGEMENT_GET_USER_RELATED_CASES,
      method: 'GET'
    });
  },
  getSpecificServiceCase(serviceCaseId: string) {
    return request({
      endpoint: ENDPOINTS.CASE_MANAGEMENT_GET_SPECIFIC_CASE
        .replace(":serviceCaseId", serviceCaseId),
      method: 'GET'
    });
  },
  getAllCaseCategories() {
    return request({
      endpoint: ENDPOINTS.CASE_MANAGEMENT_GET_CATEGORIES,
      method: 'GET'
    });
  },
  deleteServiceCase(caseId: string){
    return request({
      endpoint: ENDPOINTS.CASE_MANAGEMENT_DELETE_CASE
        .replace(":serviceCaseId", caseId),
      method: 'DELETE'
    });
  },
  deleteServiceCaseAttachment(caseId: string, attachmentId: string){
    return request({
      endpoint: ENDPOINTS.CASE_MANAGEMENT_DELETE_CASE_ATTACHMENT
        .replace(":serviceCaseId", caseId)
        .replace(":attachmentId", attachmentId),
      method: 'DELETE'
    });
  },
  createServiceCase(fileList: FileList[], 
    createCaseFormType: CreateCaseFormType){
		
    const formData = new FormData();
    /* const data = {...createCaseFormType}; */
    const request = {
        caseDescription: createCaseFormType.caseDescription,
        facility: createCaseFormType.facility,
        serviceCaseCategory: createCaseFormType.serviceCaseCategory?.value,
        serviceCaseStatus: ServiceCaseStatus.NEW.toString(),
        caseSubject: createCaseFormType.caseSubject,
        casePriority: createCaseFormType.casePriority?.value
    } as CreateCaseRequest

    const serviceCaseBlob = new Blob([JSON.stringify(request)], {
      type: 'application/json',
    });

    fileList.forEach((p) => {
      if (p[0] !== null && p[0] !== undefined) {
        const blob = p[0] as Blob;
        formData.append('file', blob, p[0].name);
      }
    });

    formData.append('data', serviceCaseBlob);

    return axiosInstance.post(
      generatePath(ENDPOINTS.CASE_MANAGEMENT_CREATE_CASE),
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  },
  editServiceCase(fileList: FileList[], 
    createCaseFormType: CreateCaseFormType,
    serviceCaseId: string){
		
	const formData = new FormData();
	/* const data = {...createCaseFormType}; */
	
	const request = {
		caseDescription: createCaseFormType.caseDescription,
		facility: createCaseFormType.facility,
		serviceCaseCategory: createCaseFormType.serviceCaseCategory.value,
		serviceCaseStatus: ServiceCaseStatus.NEW,
        caseSubject: createCaseFormType.caseSubject,
        casePriority: createCaseFormType.casePriority?.value
    } as CreateCaseRequest
	
    const serviceCaseBlob = new Blob([JSON.stringify(request)], {
		type: 'application/json',
    });

    fileList.forEach((p) => {
      if (p[0] !== null && p[0] !== undefined) {
        const blob = p[0] as Blob;
        formData.append('file', blob, p[0].name);
      }
    });

    formData.append('data', serviceCaseBlob);
	
    return axiosInstance.put(
      generatePath(ENDPOINTS.CASE_MANAGEMENT_EDIT_CASE
        .replace(":serviceCaseId", serviceCaseId)),
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  },

  createCaseComment(fileList: FileList[], 
    serviceCaseCustomerComment: ServiceCaseCustomerComment,
    serviceCaseId: string){
      
    const formData = new FormData();
    /* const data = {...serviceCaseCustomerComment}; */


    const request = {
        comment: serviceCaseCustomerComment.comment,
        commentHeader: "Some Header",
    } as ServiceCaseCustomerComment

    const serviceCaseBlob = new Blob([JSON.stringify(request)], {
      type: 'application/json',
    });

    fileList.forEach((p) => {
      if (p[0] !== null && p[0] !== undefined) {
        const blob = p[0] as Blob;
        formData.append('file', blob, p[0].name);
      }
    });

    formData.append('data', serviceCaseBlob);

    return axiosInstance.post(
      generatePath(ENDPOINTS.CASE_MANAGEMENT_ADD_CASE_COMMENT
        .replace(":serviceCaseId", serviceCaseId)),
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  },

  getCaseAttachmentFile(fileId: string, serviceCaseId: string) {
    return axiosInstance({
      url: ENDPOINTS.CASE_MANAGEMENT_DOWNLOAD_ATTACHMENT
      .replace(':fileId', fileId)
      .replace(':serviceCaseId', serviceCaseId),
      responseType: 'blob',
    });
  },
  assignCaseToUser(
    userId: string,
    serviceCaseId: string, 
    serviceCaseStatus:ServiceCaseStatus) {
    return request({
       endpoint: ENDPOINTS.CASE_MANAGEMENT_ASSIGN_CASE_TO_USER
        .replace(":serviceCaseId", serviceCaseId),
        method: 'PUT',
        payload: {
          userId: userId,
          serviceCaseId: serviceCaseId,
          serviceCaseStatus: serviceCaseStatus
        }
    })
  }
}

export default methods
