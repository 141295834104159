import {createSlice, createAsyncThunk, PayloadAction} from '@reduxjs/toolkit';
import {AxiosError} from 'axios';

import authService from '../../services/auth';

import {UserType, AccessModules, User, Session2, PermissionViewDescription} from '../users/types';

import {saveState, } from '../utils';

import {Session, SystemState} from './types';

const name = 'system';

export const getActiveUser = createAsyncThunk<{
  accessModules: AccessModules;
  sessionUser: User;
  sessionUserType: UserType;
  permissionViewDescription: PermissionViewDescription;
  permissionAccessModule: AccessModules;
}>(`eon-ui/getActiveUser`, async function () {
  const {data} = await authService.getCurrentUser();
  const accessModules: AccessModules = {};
  const permissionAccessModule: AccessModules = {};

  let session2user = (data as Session2);

  session2user.user.userPermission.accessModules.forEach(
    ({module}) => (accessModules[module] = true)
  );
  session2user.permissionViewAccessModules.forEach(
    ({module}) => (permissionAccessModule[module] = true)
  );

  const sessionUserType = session2user.user.userPermission.userType;
  const sessionUser = session2user.user;
  const permissionViewDescription = session2user.permissionViewDescription;

  if (!sessionUserType) {
    throw new Error('`userType` missing');
  }

  return {
    accessModules,
    sessionUserType,
    sessionUser,
    permissionViewDescription,
    permissionAccessModule
  };
});

export const createSession = createAsyncThunk<
  Session,
  {email: string; password: string},
  {rejectValue: AxiosError}
>(`eon-ui/createSession`, async function (credentials, {rejectWithValue}) {
  try {
    const {data} = await authService.createSession(credentials);

    const session: Session = {
      createdAt: +new Date(data.createdAt),
      expiresAt: +new Date(data.expiresAt),
    };

    return session;
  } catch (e) {
    return rejectWithValue(e);
  }
});

export const destroySession = createAsyncThunk(
  `eon-ui/destroySession`,
  async function (_, {dispatch}) {
    dispatch(clearSession());

    try {
      await authService.destroySession();
    } catch (e) {
      if ((e.isAxiosError && (e as AxiosError).response?.status) ?? 0 < 500) {
        return;
      } else {
        console.error('destroySession failed', e);
      }
    }
  }
);


export const initialState: SystemState = {
  showLoader: false,
  initialSessionChecked: false,
  accessModules: {},
  session: null,
  sessionUser: null,
  sessionUserType: null,
  permissionViewDescription: null,
  permissionAccessModule: {}
};

const clearSessionAction = (state: SystemState) => {
  saveState({
    system: {
      ...initialState,
    },
  });

  state.initialSessionChecked = false;
  state.accessModules = {};
  state.session = null;
  state.sessionUser = null;
  state.sessionUserType = null;
  state.permissionViewDescription = null;
  state.permissionAccessModule = {};
};

const systemSlice = createSlice({
  name,
  initialState,
  reducers: {
    setShowLoader: (state, action: PayloadAction<boolean>) => {
      state.showLoader = action.payload;
    },
    clearSession: (state) => {
      clearSessionAction(state);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getActiveUser.fulfilled, (state, action) => {
      const {accessModules, sessionUserType, sessionUser, permissionAccessModule, permissionViewDescription} = action.payload;

      state.initialSessionChecked = true;
      state.accessModules = accessModules;
      state.sessionUser = sessionUser;
      state.sessionUserType = sessionUserType;
      state.permissionAccessModule = permissionAccessModule;
      state.permissionViewDescription = permissionViewDescription;
    });
    builder.addCase(getActiveUser.rejected, (state) => {
      clearSessionAction(state);
    });

    
    builder.addCase(createSession.fulfilled, (state, action) => {
      // clear any possible previous state
      clearSessionAction(state);
      state.session = action.payload;

      // save the new session
      saveState({
        system: {
          ...initialState,
          session: action.payload,
        },
      });
    });
  },
});

export const {clearSession, setShowLoader} = systemSlice.actions;

export default systemSlice.reducer;
