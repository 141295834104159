import React from 'react'
import Box from '@material-ui/core/Box/Box'
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress'

const ProgressSpinnerBox = () => (
	<Box width="100%" height={100} display="grid" style={{ placeContent: 'center' }}>
		<CircularProgress color="primary" />
	</Box>
)

export default ProgressSpinnerBox
