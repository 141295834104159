import React, {useEffect, useState} from 'react';
import {OrdersFilter} from '../store/orders/types';
import orders from '../services/orders';

export interface useFetchOrdersFilter {
  refreshOrdersFilter: boolean;
	setRefreshOrdersFilter: React.Dispatch<React.SetStateAction<boolean>>;
}

const initialStateOrdersFilters: OrdersFilter = {
	allOrders: 0,
	newOrders: 0,
	confirmedOrders: 0,
	mineAssigned: 0,
	deliveredOrders: 0,
	canceledOrders: 0
}

export default function useFetchOrdersFilter({refreshOrdersFilter, setRefreshOrdersFilter}: useFetchOrdersFilter) {
  const [ordersFiler, setOrdersFilter] = useState<OrdersFilter>(initialStateOrdersFilters);

  useEffect(() => {
		if (refreshOrdersFilter) {
			const request = async () => {
				let ob
				try {
					ob = await orders.getOrdersFilter()
				} catch (error) {
				}
				setOrdersFilter(ob?.data ?? []);
			};
			request();
			setRefreshOrdersFilter(false)
		}
  }, [refreshOrdersFilter, setRefreshOrdersFilter]);

  return ordersFiler;
}
