import { useLayoutEffect, useRef } from 'react'
import useBoolean from './useBoolean'

type UseHoverReturn = [
	boolean,
	{
		onMouseEnter: () => void
		onMouseLeave: () => void
		onClick: () => void
		ref: React.MutableRefObject<any>
	}
]

/**
 * useHover hook - Returns an array of two elements: isHover, bind:
 * * _isHover_ {boolean}: Contains the state, just like the first element of useState.
 * * _bind_ {object}: Contains two methods for hover listeners. Can be manually applied or spread onto the target node
 */
const useHover = (mobileFriendly: boolean = true): UseHoverReturn => {
	const [isHover, , { setTrue, setFalse, toggle }] = useBoolean()
	const ref = useRef<undefined | HTMLElement>()

	const bind = {
		onMouseEnter: setTrue,
		onMouseLeave: setFalse,
		onClick: toggle,
		ref
	}

	useLayoutEffect(() => {
		if(mobileFriendly && ref.current != undefined) {
			const listener = (e: any) => !ref.current?.contains(e.target) && setFalse()

			document.addEventListener('mousedown', listener, eventListenerOptions)
			document.addEventListener('touchstart', listener, eventListenerOptions)

			return () => {
				document.removeEventListener('mousedown', listener, eventListenerOptions)
				document.removeEventListener('touchstart', listener, eventListenerOptions)
			}
		}
	}, [mobileFriendly, setFalse])

	return [ isHover, bind ]
}

const eventListenerOptions = {
	capture: false,
	passive: true,
}


export default useHover
