import Icon from '@mdi/react';
import {
  mdiChevronLeft,
  mdiChevronRight,
  mdiChevronDoubleLeft,
  mdiChevronDoubleRight,

} from '@mdi/js';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import './Pager.scss';
import { useState } from 'react';
import { BorderAll } from '@material-ui/icons';
import { borderRadius } from 'react-select/src/theme';


type PagerProps = {
  page: number | null;
  totalItems: number;
  className?: string;
  limit?: number;

  onPagination: (page: number) => any;
};
const lastPage = (totalItems: number, limit: number, page: number): number => {
  return Math.max(page, Math.ceil(totalItems / limit));
};
const next = (totalItems: number, limit: number, page: number): number => {
  return Math.min(page + 1, Math.ceil(totalItems / limit));
};

export default function Pager({
  page: _page,
  totalItems,
  limit,
  className,

  onPagination,
}: PagerProps) {
  const [pageNumber, setPageNumber] = useState(_page || '');

  let page = _page ? +_page : 1;
  limit = limit ?? 10;

  const { t } = useTranslation();

  const total = Math.ceil(totalItems / limit);

  if (total === 0) {
    page = 0;
  }

  const p = Math.max(1, page - 1);
  const n = next(totalItems, limit, page);
  const l = lastPage(totalItems, limit, page);
  const f = Math.min(1, page);
  return (
    <div className={classnames('pager', className)}>
      <div
        className="pager__arrow"
        onClick={() => {
          page > f && onPagination(f);
          setPageNumber(f);
        }}
        title={t('common.First page')}
      >
        <Icon path={mdiChevronDoubleLeft} size="24" className="icon-24" />
      </div>
      <div
        className="pager__arrow"
        onClick={() => {
          page > p && onPagination(p);
          setPageNumber(p);
        }}
        title={t('common.Previous')}
      >
        <Icon path={mdiChevronLeft} size="24" className="icon-24" />
      </div>
      <div className="pager__state">
        <input
          type="number"
          min={1}
          max={total}
          value={pageNumber}
          style={{
            width: '15x',
            height: '18px',
            textAlign: 'center',
            borderRight: '0',
            borderLeft: '0',
            borderTop: '0',
            borderBottomColor: 'lightgrey',
            paddingTop: '0.02rem'
          }}
          onChange={(event) => {
            if (event.target.value) {
              setPageNumber(+event.target.value);
              onPagination(+event.target.value);
            } else {
              setPageNumber('');
            }
          }}
        />
        {t('common.Of')}
        {total}
      </div>

      <div
        className="pager__arrow"
        onClick={() => {
          page < n && onPagination(n);
          setPageNumber(n);
        }}
        title={t('common.Next')}
      >
        <Icon path={mdiChevronRight} size="24" className="icon-24" />
      </div>
      <div
        className="pager__arrow"
        onClick={() => {
          page < l && onPagination(l);
          setPageNumber(l);
        }}
        title={t('common.Last page')}
      >
        <Icon path={mdiChevronDoubleRight} size="24" className="icon-24" />
      </div>
    </div>
  );
}

