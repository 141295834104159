import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './GenericErrorToaster.scss'
import {Toast} from 'react-bootstrap'
import { AppState } from '../../store';
import { connect, ConnectedProps } from 'react-redux';
import { toggleToast } from '../../store/components'
import { Alert } from '@material-ui/lab';
import { Snackbar } from '@material-ui/core';

///TODO Refactor - delete this in the future.. Goal is to not use redux

const connector = connect(
  (state: AppState) => ({
    toggle: state.components.selected
  }),
  { toggleToast }
);

type GenericErrorToasterProps = ConnectedProps<typeof connector> & {};

function GenericErrorToaster({
  toggle,
  toggleToast
}: GenericErrorToasterProps) {
  const { data: uiComponentToggleData, loading: uiComponentToggleLoading } = toggle;

  const severity = () => {
    if(!uiComponentToggleData?.status && uiComponentToggleLoading) return

    switch(uiComponentToggleData?.status) {
      case 200:
        return 'success';
      // case warning:
      //   return 'warning';
      case 400:
        return 'error';
      default:
        return 'info';
    }
  }

  return (
    <React.Fragment>
      {uiComponentToggleLoading ? <p></p> :
          <Snackbar
            open={uiComponentToggleData !== null ? uiComponentToggleData.toggleToast : false}
            autoHideDuration={5000}
            onClose={() => toggleToast({shouldToggle: false, message: "", status: 0})}
            >
            <Alert severity={severity()} onClose={() => toggleToast({shouldToggle: false, message: "", status: 0})}>
              {uiComponentToggleData?.message}
            </Alert>
          </Snackbar>

        }
    </React.Fragment>
  );
}

export default connector(GenericErrorToaster);
