import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';

import productCategoriesService from '../../services/productCategories';

import {ListData} from '../types';
import {
  listPending,
  listFulfilled,
  listRejected,
  entityPending,
  entityFulfilled,
  entityRejected,
} from '../utils';

import {ProductCategoriesState, ProductCategory, ProductCategoryCard, ProductCategoryInput} from './types';
import {DeepPartial} from "redux";

const name = 'productCategories';

export const getProductCategories = createAsyncThunk<ListData<ProductCategory>>(
  `eon-ui/getProductCategories`,
  async function () {
    const {
      data,
      headers,
    } = await productCategoriesService.getProductCategories();

    return {
      data: data as ProductCategory[],
      total: headers['x-total-count'],
    };
  }
);

export const updateProductCategoryOrder = createAsyncThunk<ListData<ProductCategoryCard>, ProductCategoryCard[]>(
  `eon-ui/updateProductCategoryOrder`,
  async function (productCategoryCards) {
    const {data, headers} = await productCategoriesService.updateProductCategoryOrder(productCategoryCards);
  
    return {
      data: data as ProductCategoryCard[],
      total: headers['x-total-count'],
    };
  }
);

export const getProductCategory = createAsyncThunk<ProductCategory, string>(
  `eon-ui/getProductCategory`,
  async function (id) {
    const {data} = await productCategoriesService.getProductCategory(id);

    return data as ProductCategory;
  }
);

export const updateProductCategory = createAsyncThunk<
    ProductCategory,
    {categoryId: string; payload: DeepPartial<ProductCategoryInput>}
    >(`eon-ui/updateProductCategory`, async function ({categoryId, payload}) {
  const {data} = await productCategoriesService.editProductCategory(categoryId, payload);
  return data as ProductCategory;
});

export const createProductCategory = createAsyncThunk<
    ProductCategory,
    {payload: DeepPartial<ProductCategoryInput>}
    >(`eon-ui/createProductCategory`, async function ({payload}) {
  const {data} = await productCategoriesService.createProductCategory(payload);
  return data as ProductCategory;
});

export const deleteProductCategory = createAsyncThunk<ProductCategory, string>(
    `eon-ui/deleteProductCategory`,
    async function (categoryId) {
      const {data} = await productCategoriesService.deleteProductCategory(categoryId);
      return data as ProductCategory;
    }
);

export const getProductCategoryResolved = createAsyncThunk<ListData<ProductCategory>>(
  `eon-ui/getProductCategoriesResolved`,
  async function () {
    const {
      data,
      headers,
    } = await productCategoriesService.getProductCategoryResolved();

    return {
      data: data as ProductCategory[],
      total: headers['x-total-count'],
    };
  }
);

const initialState: ProductCategoriesState = {
  list: {data: [], total: 0, loading: false, requestId: ''},
  selected: {data: null, loading: false, requestId: ''},
};

const productCategoriesSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProductCategories.pending, (store, action) => {
      listPending(store.list, action);
    });
    builder.addCase(getProductCategories.fulfilled, (store, action) => {
      listFulfilled(store.list, action);
    });
    builder.addCase(getProductCategories.rejected, (store, action) => {
      listRejected(store.list, action);
    });

    builder.addCase(getProductCategoryResolved.pending, (store, action) => {
      listPending(store.list, action);
    });
    builder.addCase(getProductCategoryResolved.fulfilled, (store, action) => {
      listFulfilled(store.list, action);
    });
    builder.addCase(getProductCategoryResolved.rejected, (store, action) => {
      listRejected(store.list, action);
    });

    builder.addCase(getProductCategory.pending, (store, action) => {
      entityPending(store.selected, action);
    });
    builder.addCase(getProductCategory.fulfilled, (store, action) => {
      entityFulfilled(store.selected, action);
    });
    builder.addCase(getProductCategory.rejected, (store, action) => {
      entityRejected(store.selected, action);
    });

    builder.addCase(updateProductCategory.pending, (store, action) => {
      entityPending(store.selected, action);
    });
    builder.addCase(updateProductCategory.fulfilled, (store, action) => {
      entityFulfilled(store.selected, action);
    });
    builder.addCase(updateProductCategory.rejected, (store, action) => {
      entityRejected(store.selected, action);
    });

    builder.addCase(createProductCategory.pending, (store, action) => {
      entityPending(store.selected, action);
    });
    builder.addCase(createProductCategory.fulfilled, (store, action) => {
      entityFulfilled(store.selected, action);
    });
    builder.addCase(createProductCategory.rejected, (store, action) => {
      entityRejected(store.selected, action);
    });
  },
});

export default productCategoriesSlice.reducer;
