import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { FormContextValues } from "react-hook-form"
import { useHistory } from 'react-router-dom'
import { AppState } from '../../../store'
import { PATH_BANNERMESSAGE_BACK_OFFICE_LIST } from '../../../router/paths'
import { getActiveUser } from '../../../store/system'
import InputField from '../../../components/Form/InputField'
import SelectField from '../../../components/Form/SelectField'
import {BannerMessageType, CreateBannerMessageFormType} from '../../../store/bannermessages/types';
import DateTimePicker from '../../../components/Form/DateTimePicker';
import {registerLocale} from "react-datepicker";
import sv from "date-fns/locale/sv"
registerLocale("sv", sv);

const connector = connect(
    (state: AppState) => ({
        activeUser: state.system.sessionUser,
    }),
    {getActiveUser}
);

type CreateBannerMessageManagementFormTypeProps = ConnectedProps<typeof connector> & {
    handler: FormContextValues<CreateBannerMessageFormType>;
    handleSubmit: (input: CreateBannerMessageFormType) => void;
    isEdit: boolean
};

export function CreateBannerMessageForm({
    handler,
    isEdit,
    handleSubmit,
}: CreateBannerMessageManagementFormTypeProps) {

    const history = useHistory()
    const { t } = useTranslation()

    const formColumnClass = 'col-md-12 col-lg-12';

    return (
        <>
            <form onSubmit={handler.handleSubmit(handleSubmit)}>
                <div className="row">
                    <div className="col-6">
                        <SelectField
                            name="messageType"
                            handler={handler}
                            label={"Meddelandefärg"}
                            placeholder={"Meddelandefärg"}
                            className={formColumnClass}
                            options={[
                                    {label: "1 - Röd", value: BannerMessageType.ERROR_MESSAGE},
                                    {label: "2 - Gul", value: BannerMessageType.WARNING_MESSAGE},
                                    {label: "3 - Blå", value: BannerMessageType.INFO_MESSAGE},
                                    {label: "4 - Grön", value: BannerMessageType.SUCCESS_MESSAGE},
                            ]}
                            rules={{required: true}}
							isClearable
                        />
                    </div>
                    <div className="col-6">
                        <InputField name="header"
                                    handler={handler}
                                    label={"Rubrik"}
                                    placeholder={"Rubrik"}
                                    className={formColumnClass}
                        />
                    </div>
                    <div className="col-12">
                        <InputField
                            name="message"
                            handler={handler}
                            label={"Meddelande"}
                            placeholder={"Meddelande"}
                            className={formColumnClass}
                            rules={{ required: true }}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <DateTimePicker
                            name="startDateTime"
                            handler={handler}
                            label={"Start Datum & Tid"}
                            placeholder={new Date().toLocaleString()}
                            className={formColumnClass}
                            dateFormat={"yyyy-MM-dd | HH:mm"}
                            rules={{required: true}}
                        ></DateTimePicker>
                    </div>
                    <div className="col-6">
                        <DateTimePicker
                            name="endDateTime"
                            handler={handler}
                            label={"Slut Datum & Tid"}
                            placeholder={new Date().toLocaleString()}
                            className={formColumnClass}
                            dateFormat={"yyyy-MM-dd | HH:mm"}
                            rules={{required: true}}
                        ></DateTimePicker>
                    </div>
                </div>

                {isEdit ? <button
                    type="submit"
                    className="btn btn-primary mr-2"
                    data-testid="submit__project_form"
                > {t('common.Edit')}
                </button> : ""}

                {!isEdit ? <button
                    type="submit"
                    className="btn btn-primary mr-2"
                    data-testid="submit__project_form"
                > {t('common.Create')}
                </button> : ""} 
             
                <button type="button" className="btn btn-info" onClick={() => history.push(PATH_BANNERMESSAGE_BACK_OFFICE_LIST)} >
                    {t('common.Cancel')}
                </button>
            </form>
        </>
    );
}

export default connector(CreateBannerMessageForm);