import React, {useCallback, useEffect, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useHistory, generatePath} from 'react-router-dom';

// import {useForm} from 'react-hook-form';
import {getProductCategories} from '../../store/productCategories';
import {getProductsWithoutFilters} from '../../store/products';
import {getUnmappedArticles} from '../../store/articles';

import {AppState} from '../../store';
import {Article} from '../../store/articles/types';
import {createAndMapProduct} from '../../store/products/';
import {toggleToast} from '../../store/components';
import {setShowLoader} from '../../store/system';

import {PATH_E_SHOP_EDIT_PRODUCT} from '../../router/paths';

import ProductForm, {
  ProductFormInput,
} from '../../components/EShop/ProductForm';
import ProductSelectModal from '../../components/Products/ProductSelectModal';
import {ProductCustomer, ProductInput, ProductReserved} from '../../store/products/types';
import {ErrorResponseResolver} from '../../lib/responseHelper';

const connector = connect(
  (state: AppState) => ({
    productCategories: state.productCategories.list,
    unMappedArticles: state.articles.list,
  }),
  {
    getProductCategories,
    getProductsWithoutFilters,
    getUnmappedArticles,
    toggleToast,
    createAndMapProduct,
    setShowLoader,
  }
);

type CreateProductProps = ConnectedProps<typeof connector> & {};

export function CreateProduct({
  getProductCategories,
  getProductsWithoutFilters,
  getUnmappedArticles,
  toggleToast,
  createAndMapProduct,
  productCategories,
  unMappedArticles,
  setShowLoader,
}: CreateProductProps) {
  const {t} = useTranslation();
  const {data: unMappedArticlesData} = unMappedArticles;
  const history = useHistory();

  const [productsModalVisible, setProductsModalVisible] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState<Article>();
  const onProductSelect = useCallback(
    (article: Article) => () => {
      setSelectedArticle(article);
      setProductsModalVisible(false);
    },
    []
  );

  type Attachment = {
    id: string;
    fileList: FileList;
  };

  const [attached, setAttached] = useState<Attachment[] | Attachment[]>([]);
  const {data: categories, loading: categoriesLoading} = productCategories;

  const isLoading = categoriesLoading;

  useEffect(() => {
    getProductCategories();
  }, [getProductCategories]);

  useEffect(() => {
    getProductsWithoutFilters();
  }, [getProductsWithoutFilters]);

  useEffect(() => {
    getUnmappedArticles();
  }, [getUnmappedArticles]);

  useEffect(() => {
    setShowLoader(isLoading);
  }, [isLoading, setShowLoader]);

  const handleSubmit = async (
    productFormInput: ProductFormInput,
    data?: Blob,
    productCustomers?: ProductCustomer[],
    productReserved?: ProductReserved[]
  ) => {
    if (selectedArticle) {
      const {ArticleNumber} = selectedArticle;
      let categoryId = '',
        categorySecondId = '',
        categoryThirdId = '';
      if (productFormInput.category) {
        categoryId = productFormInput.category.value;
      }
      if (productFormInput.categorySecond) {
        categorySecondId = productFormInput.categorySecond.value;
      }
      if (productFormInput.categoryThird) {
        categoryThirdId = productFormInput.categoryThird.value;
      }

      const request = {
        name: productFormInput.name,
        description: productFormInput.description,
        manufacturer: productFormInput.manufacturer,
        categoryId: categoryId,
        categorySecondId: categorySecondId,
        categoryThirdId: categoryThirdId,
        noneStockProduct: !!productFormInput.noneStockProduct,
        priceGroup: '',
        image: data,
        documents: attached.map((f) => f.fileList),
        maxOrderQuantity: productFormInput.maxOrderQuantity,
        productCustomers: productCustomers,
        productReserved: productReserved
      } as ProductInput;

      if (request.image === undefined) {
        delete request.image;
      }
	  
      await createAndMapProduct({
        fortNoxArticleId: ArticleNumber,
        payload: request,
        callbackOnSuccess: (_, {product}) => {
          setShowLoader(false);
          history.push(
            generatePath(PATH_E_SHOP_EDIT_PRODUCT, {productId: product.id})
          );
        },
        callbackOnFail: (errorResponseResolver: ErrorResponseResolver) => {
          toggleToast({
            shouldToggle: true,
            message: errorResponseResolver.message,
            status: errorResponseResolver.status,
          });
        },
      });
    }
  };

  const removeDocument = (fileId: string) => {
    setAttached(attached.filter(a => a.id !== fileId))
  };

  const setAttachments = function (fileList: FileList, id: string) {
    const ids = attached.map(a => a.id)
    if(ids.includes(id)) return
      let ob = {
        fileList: fileList,
        id: id,
      } as Attachment;
      setAttached(a => [...a, ob]);
    };
  
  const deleteAttachments = function (id: string) {
    setAttached(attached.filter((v) => v.id !== id));
  };

  return (
    <div>
      <h1 data-testid="create_product_headline">
        {t('common.Create new product')}
      </h1>

      <ProductForm
        fortNoxArticleId={selectedArticle?.ArticleNumber}
        setModalVisible={setProductsModalVisible}
        onSubmit={handleSubmit}
        setAttachments={setAttachments}
        deleteAttachments={deleteAttachments}
        categoriesData={categories}
        setShowLoader={setShowLoader}
        attached={attached}
        removeDocument={removeDocument}
      />

      {unMappedArticlesData !== null && (
        <ProductSelectModal
          modalVisible={productsModalVisible}
          onSelect={onProductSelect}
          setModalVisible={setProductsModalVisible}
        />
      )}
    </div>
  );
}

export default connector(CreateProduct);
