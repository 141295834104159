import React, { useContext, useEffect, useState } from 'react'
import { contextEShopProducts } from '../../../EShop/Providers/EShopProductsProvider'
import { useTranslation } from 'react-i18next'
import Box from '@material-ui/core/Box/Box'
import { Search } from '@material-ui/icons'
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress'
import { contextEShopCategory } from '../../../EShop/Providers/EShopCategoryProvider'
import { useQuery } from '../../../../lib/hooks'
import { useHistory } from 'react-router';

const EShopSearch = () => {
	const { searchTerm, setSearchTerm, manufacturers, isSearching } = useContext(contextEShopProducts)
	const { setManufacturer } = useContext(contextEShopCategory)
	
	const [ localSearchTerm, setLocalSearchTerm ] = useState(searchTerm ?? '')
	const {t} = useTranslation()
	useEffect(() => { searchTerm !== localSearchTerm && setLocalSearchTerm(searchTerm ?? '') }, [searchTerm])

	const query = useQuery()
	const history = useHistory()
	const triggerSearchReset = (inputFeildSearchTerm : string) =>{
		if(inputFeildSearchTerm === ""){
			query.set('search', "")
			history.push(`?${query}`)
		}
	}

	return (
		<>
			<Box mb={1} mt={2} className="dropdown">
				<button 
					className="btn btn-default dropdown-toggle" 
					type="button" 
					data-toggle="dropdown" 
					aria-haspopup="true" 
					aria-expanded="false"
					disabled={!manufacturers?.data?.length}
				>
					{t('common.Manufacturer')}
				</button>
				<div className="dropdown-menu" aria-labelledby="dropdownMenuButton" style={{ height: 400, overflowY: 'auto', overflowX: 'hidden' }}>
					{manufacturers?.data?.map(item => 
						<div 
							className="dropdown-item"
							key={item}
							onClick={() => setManufacturer?.(item)}
						>
							{item}
						</div>	
					)}
				</div>
			</Box>
			<Box display="flex" style={{ gap: 5 }}>
				<input 
					className="form-control" 
					value={localSearchTerm}
					placeholder="Frisökning på alla produkter"
					onChange={e => (triggerSearchReset(e.target.value), setLocalSearchTerm(e.target.value))}
					onKeyPress={e => e.key === 'Enter' && setSearchTerm?.(localSearchTerm)}
				/>
                <button 
					className="btn btn-primary cursor-pointer" 
					onClick={() => setSearchTerm?.(localSearchTerm)}
					disabled={isSearching}
				>
					{ isSearching ? <CircularProgress size={24} /> : <Search /> }
				</button>
            </Box>
		</>
	)
}

export default EShopSearch
