import {useEffect, useRef, useState, useCallback, RefObject} from 'react';
import {useLocation, useHistory} from 'react-router-dom';
import {FormContextValues} from 'react-hook-form';

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function useBouncer(toWatch: any, delay: number, cb: CallableFunction) {
  const timer = useRef<number | null>(null);

  useEffect(() => {
    timer.current = window.setTimeout(() => {
      timer.current = null;
      cb();
    }, delay);

    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
        timer.current = null;
      }
    };
    // eslint-disable-next-line
  }, [toWatch, delay]);
}

export type UseSearchHookReturn = [
  string,
  (e: React.FormEvent<HTMLInputElement>) => void
];

export function useSearchHook(
  search: string | null,
  action: (search: string) => void,
  delay = 500
): UseSearchHookReturn {
  const [searchStr, setSearch] = useState(search ?? '');

  const onSearchChange = useCallback(
    (e) => {
      setSearch(e.currentTarget.value);
    },
    [setSearch]
  );

  const setSearchQuery = () => {
    if (search === null && !searchStr) {
      return;
    }

    action(searchStr);
  };

  useBouncer(searchStr, delay, setSearchQuery);

  useEffect(() => {
    setSearch(search ?? '');
  }, [search, setSearch]);

  return [searchStr, onSearchChange];
}

export function useSearch(delay = 500) {
  const history = useHistory();
  const query = useQuery();

  const search = query.get('search');

  return useSearchHook(
    search,
    (searchStr) => {
      query.set('search', searchStr);
      history.push(`?${query}`);
    },
    delay
  );
}

export function usePagedSearch(delay = 500) {
  const history = useHistory();
  const query = useQuery();

  const search = query.get('search');

  return useSearchHook(
    search,
    (searchStr: string) => {
      query.set('search', searchStr);
      query.set('page', '1');
      history.push(`?${query}`);
    },
    delay
  );
}

export function useCroppie() {
  const sourcePictureField = useRef<HTMLInputElement | null>(null);
  const croppie = useRef<Croppie | null>(null);
  const [sourcePicture, setSourcePicture] = useState<File | string | null>(
    null
  );

  return {sourcePictureField, croppie, sourcePicture, setSourcePicture};
}

export function useToggleAllCheckboxes<
  T extends {
    value: string;
  }
>(
  switchName: string,
  checkboxesFieldName: string,
  handleInput: FormContextValues<any>,
  options: T[]
) {
  const [overwritten, setOverwritten] = useState(false);
  const {
    watch,
    setValue,
    formState: {dirty},
  } = handleInput;
  const allCheckboxes: boolean = watch(switchName);

  useEffect(() => {
    if (!dirty) {
      setOverwritten(false);
      return;
    }

    if (allCheckboxes) {
      setOverwritten(true);
      setValue(
        checkboxesFieldName,
        options.map(({value}) => value)
      );
    } else {
      if (!overwritten) {
        return;
      }

      setOverwritten(false);
      setValue(checkboxesFieldName, []);
    }
  }, [
    checkboxesFieldName,
    dirty,
    allCheckboxes,
    options,
    overwritten,
    setOverwritten,
    setValue,
  ]);

  return {allCheckboxes};
}

export const useOnClickOutside = (
    ref: RefObject<HTMLDivElement>,
    closeMenu: () => void
) => {
  useEffect(() => {
    const listener = (event: MouseEvent) => {
      if (
          ref.current &&
          event.target &&
          ref.current.contains(event.target as Node)
      ) {
        return;
      }
      closeMenu();
    };

    document.addEventListener("mousedown", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
    };
  }, [ref, closeMenu]);
};

