import React from 'react';
import {useTranslation} from 'react-i18next';
import {mdiImage} from '@mdi/js';
import Icon from '@mdi/react';
import classnames from 'classnames';

import './TextSampleBox.scss';

export default function TextSampleBox({
  textAlign,
}: {
  textAlign: 'left' | 'right' | 'center' | 'justify';
}) {
  const {t} = useTranslation();
  return (
    <div className="sample__box">
      <div className="sample__container">
        <div
          className={classnames(
            'sample__align_text',
            `sample__align_text--${textAlign}`
          )}
        >
          <div className="sample__title">{t('common.Headline')}</div>
          <div className="sample__message">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
            ornare ex at orci rutrum elementum ac id ante. Suspendisse non mi
            augue. Nulla quam lorem, vulputate id luctus ut, dignissim laoreet
            turpis. Maecenas ac sodales metus. Mauris sagittis nibh eget turpis
            congue maximus
          </div>
        </div>

        <Icon
          path={mdiImage}
          size="62"
          color="rgba(0, 0, 0, 0.02)"
          className="sample__image"
        />
      </div>
    </div>
  );
}
