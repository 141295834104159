import React from 'react'
import OfferListingList from './OfferListingList/OfferListingList'

const MyOffers = () => {
	
	return (
		<OfferListingList type="my" />
	)
}

export default MyOffers
