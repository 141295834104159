export enum FileCategory {
  NOT_SPECIFIED = 'NOT_SPECIFIED',
  IMAGE = 'IMAGE',
  DOCUMENT = 'DOCUMENT',
  RESALE_TERMS = 'RESALE_TERMS',
}

export type UploadedFile = {
  id: string;
  fileCategory?: FileCategory;
  filePath: string | null;
  uploadedAt: string;
  signedDate: string;
  originalName?: string;
  name: string
};

export type UploadedFilesDictionary<T extends UploadedFile = UploadedFile> = {
  [k: string]: null | T;
};

export type Folder = {
  id: string;
  parentId?: string;
  name: string;
  lastModified: string;
};

export type LinkedList<T> = {
  data: T;
  next: null | LinkedList<T>;
};
