import React from 'react';
import {useTranslation} from 'react-i18next';
import {GDPRText} from '../../pages/Auth/ConfirmUser';

import Modal from '../Modal';
import {connect, ConnectedProps} from "react-redux";
import {AppState} from "../../store";
import {updateUserInformation} from "../../store/users";
import {getActiveUser} from '../../store/system';
import {User} from "../../store/users/types";
const connector = connect((state: AppState) => ({
  sessionUser: state.system.sessionUser
}), {updateUserInformation, getActiveUser});

type AcceptGDPRModalProps = ConnectedProps<typeof connector> & {
  modalVisible: boolean;
  setModalVisible: (f: boolean) => void;
  sessionUser: User|null
};

function AcceptGDPRModal({
  modalVisible,
  setModalVisible,
  sessionUser,
  updateUserInformation,
  getActiveUser
}: AcceptGDPRModalProps) {
  const {t} = useTranslation();
  const onAcceptGdpr = async () => {
    if(sessionUser){
      const userId = sessionUser.id;
      await updateUserInformation({
        userId,
        payload: {
          acceptedGDPR: (new Date).toISOString(),
        },
      });
      await getActiveUser();
    }
  };

  return (
    <Modal
      name="accept-GDPR"
      modalVisible={modalVisible}
      setModalVisible={setModalVisible}
      title={t('common.Accept GDPR terms to continue')}
      dialogClassName="modal-dialog-centered"
    >
      <GDPRText/>
      <button
          type="button"
          className="btn btn-primary col-sm-3 col-lg-2"
          data-dismiss="modal"
          onClick={onAcceptGdpr}
      >
        {t('common.Accept')}
      </button>
    </Modal>
  );
}

export default connector(AcceptGDPRModal);
