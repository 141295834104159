import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import Table from '../../components/Table';
import { AppState } from '../../store';
import { getUsers } from '../../store/users';
import { User, UserType } from '../../store/users/types';
import authService from '../../services/auth';
import Link from '@material-ui/core/Link/Link';
import { useHistory } from 'react-router';
import { PATH_HOME } from '../../router/paths';
import { isAdminAccount, isCustomerAccount } from '../../lib/permissions';
import Fade from '@material-ui/core/Fade/Fade';
import NonIdealState from '../../components/NonIdealState/NonIdealState';
import { Search } from '@material-ui/icons';
import useBoolean from '../../hooks/useBoolean';
import Alert from '@material-ui/lab/Alert/Alert';
import Box from '@material-ui/core/Box/Box';
import {setShowLoader} from "../../store/system";

const connector = connect(
    (state: AppState) => ({
	 users: state.users.list,
    }),
    { getUsers, setShowLoader }
);


type SwitchUserProps = ConnectedProps<typeof connector> & {};

const SwitchUser: React.FC<SwitchUserProps> = ({
	users,
	getUsers,
	setShowLoader
}) => {
	const {t} = useTranslation()
	const history = useHistory()
	useEffect(() => { getUsers() }, [getUsers])


	const [ searchTerm, setSearchTerm ] = useState('')
	const [ isAlert, , { setFalse: hideAlert, setTrue: showAlert } ] = useBoolean()

	const filteredUsers = 
		users.data
		.filter(u => isCustomerAccount(u.userPermission.userType) || isAdminAccount(u.userPermission.userType))
		.filter(u => [
				u.userInformation?.name,
				u.userInformation?.phoneNumber,
				u.userCredential.email,
				u.userInformation?.position,
				u.userPermission?.resolvedCustomerName,
				u.userPermission?.userType,
			].some(i => (i+'').toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()))
		)
		
	const changeUser = (userId: string) => async () => {
		try {
			!isAlert && hideAlert()
			await authService.changeUser(userId)
			history.push(PATH_HOME)
			window.location.reload()
		} catch (error) {
			showAlert()
		}
	}

	const isLoading = users.loading;

	useEffect(() => {
		setShowLoader(isLoading);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading])

	const tableHeader = [
		t('common.Name'),
		t('common.Phone number'),
		t('common.E-mail address'),
		t('common.Position'),
		t('common.Company'),
		t('common.User type'),
		t('')
	];

	return (
		<>
			<h1>Byt användare</h1>
			{ isAlert &&  
				<Fade in={isAlert}>
					<Box my={1} maxWidth={500}>
						<Alert severity="error" onClose={hideAlert}>
							Byte till användare misslyckades.
						</Alert>
					</Box>
				</Fade>
			}
			<Table
				title={t('common.Select users')}
				searchField={{
					name: 'searchTerm',
					text: t('common.Search user'),
					onChange: (v) => setSearchTerm((v.target as any).value),
					value: searchTerm,
				}}
				header={[
					t('common.Name'),
					t('common.Phone number'),
					t('common.E-mail address'),
					t('common.Position'),
					t('common.Company'),
					t('common.User type'),
					' ',
				]}
				row={(row:User) => (
					<>
						<td>{row.userInformation?.name}</td>
						<td>{row.userInformation?.phoneNumber}</td>
						<td>{row.userCredential.email}</td>
						<td>{row.userInformation?.position}</td>
						<td>{row.userPermission?.resolvedCustomerName}</td>
						<td>{t(`common.${row.userPermission?.userType}`)}</td>
						<td><Link onClick={changeUser(row.id)} style={{cursor: "pointer"}}>Välj</Link></td>
					</>
				)}
				list={filteredUsers}
				extractKey={(el) => el.id}
			/>
			{ filteredUsers.length === 0 && !users.loading &&
				<Fade in={filteredUsers.length === 0 && !users.loading}>
					<NonIdealState
						icon={<Search style={{ fontSize: 60, opacity: .6 }} />}
						title="Inga resultat"
						action={
							<button
								className="btn btn-primary btn-md mt-2"
								onClick={() => setSearchTerm('') }
							>
								Visa alla ordrar
							</button>
						}
					/>
				</Fade>
			}
		</>
	)
}

export default connector(SwitchUser)
