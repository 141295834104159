import React from 'react';
import {FormContextValues} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

import InputField from '../Form/InputField';

type CommentsFormProps = {
  handleInput: FormContextValues<CommentsFormInput>;
  onSubmit: (input: CommentsFormInput) => void;
};

export type CommentsFormInput = {
  comment: string;
};

const formColumnClass = 'col-md-12 col-lg-6';

function CommentsForm({handleInput, onSubmit}: CommentsFormProps) {
    const {t} = useTranslation();
  const {handleSubmit} = handleInput;


  return (
      <form onSubmit={handleSubmit(onSubmit)}>
          <hr className="form-delimiter" />
          <legend>{t('common.Comments')}</legend>
          <div className="form-row">
              <InputField
                  name="comment"
                  handler={handleInput}
                  placeholder={t('common.New comment')}
                  className={formColumnClass}
                  rules={{required: true}}
              />
          </div>
          <button
              type="submit"
              className="btn btn-primary mb-4"
              data-testid="submit__Comments"
          >
              {t('common.Commentate')}
          </button>
      </form>
  );
}

export default CommentsForm;
