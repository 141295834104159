import {withStyles} from '@material-ui/core/styles';
import MuiTreeItem from '@material-ui/lab/TreeItem/TreeItem';

export const EShopTreeItem = withStyles({
  root: {
    "&.MuiTreeItem-root": {
      marginLeft: 0
    },
    "&.MuiTreeItem-root > .MuiTreeItem-content": {
      flexDirection: "row-reverse",
      minHeight: "2.0rem",
      lineHeight:"3.5rem",
      listStyletype: "none",
    },
    "&.MuiTreeItem-root > .MuiTreeItem-content ": {
      color: "#6f7175",
    },
    "&.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content ": {
      backgroundColor: "rgba(239, 124, 18, 1.08)",
      color: "#fff",

    },
    "&.MuiTreeItem-root > .MuiTreeItem-group":{
      backgroundColor: "#d1d1d1",
      marginLeft: 0
    },
    "&.MuiTreeItem-root > .MuiTreeItem-content > .MuiTypography-body1": {
      fontFamily: 'Work Sans', 
      fontSize: '0.75rem',
      fontWeight: '700',
    },
    "&.MuiTreeItem-root > .MuiTreeItem-content > .MuiTreeItem-label:hover": {
      backgroundColor: "rgba(0, 0, 0, 0)",
      textDecoration: "underline",
    },
  }
})(MuiTreeItem);