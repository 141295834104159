import React from 'react';
import {useTranslation} from 'react-i18next';

import {
  PATH_E_SHOP_CREATE_PRODUCT,
  PATH_E_SHOP_EDIT_PRODUCT,
} from '../../router/paths';
import TabNav from '../TabNav';

type ProductFormTabNavProps = {
  productId?: string;
  materialCalculatorEnabled?: boolean;
  haveValueRequired?: boolean;
};

export default function ProductFormTabNav({
  productId,
  materialCalculatorEnabled,
  haveValueRequired,
}: ProductFormTabNavProps) {
  const {t} = useTranslation();

  return (
    <TabNav
      links={[
        {
          path: productId
            ? PATH_E_SHOP_EDIT_PRODUCT
            : PATH_E_SHOP_CREATE_PRODUCT,
          params: {productId},
          component: <>{t('common.Information')}</>,
        },
      ]}
    />
  );
}
