import React, {useCallback, useEffect, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {generatePath, useHistory} from 'react-router-dom';
import {User} from '../../store/users/types';

import {
    PATH_GENERAL_EDIT_USER,
    PATH_CUSTOMERS_EDIT_USER,
    PATH_CUSTOMERS_VIEW_USER,
    PATH_GENERAL_VIEW_USER
} from '../../router/paths';

import {AppState} from '../../store';
import {setShowLoader} from '../../store/system';
import {getUsers, deleteUser, activationEmail} from '../../store/users';
import UserService from '../../services/users'
import {isBackOfficeAccount, isMainAccount} from '../../lib/permissions';

import Table from '../../components/Table';
import Pager from '../../components/Pager';
import DropdownButton from '../../components/DropdownButton';
import DeleteUserConfirmationModal from "../../components/User/DeleteUserConfirmationModal";

const connector = connect(
    (state: AppState) => ({
        users: state.users.list,
        sessionUserType: state.system.sessionUserType,
    }),
    {setShowLoader, getUsers, deleteUser, activationEmail}
);

type ManageUsersProps = ConnectedProps<typeof connector> & {};

export function ManageUsers({
	users,
	sessionUserType,
	setShowLoader,
	getUsers,
	deleteUser,
	activationEmail,
}: ManageUsersProps) {
    const {t} = useTranslation();
    const history = useHistory();
    const {data, loading: listLoading} = users;

    const showLoader = listLoading;
    const [itemToDelete, setItemToDelete] = useState<string>('');
    const [searchUser, setSearchUser] = useState("")

    const [
        deleteUserConfirmationModalVisible,
        setDeleteUserConfirmationModalVisible,
    ] = useState(false);

    const deleteItem = useCallback(async () => {
        await deleteUser(itemToDelete)
        getUsers({});
        setDeleteUserConfirmationModalVisible(false);
    }, [itemToDelete,deleteUser,getUsers]);


    useEffect(() => {
        setShowLoader(showLoader);
    }, [showLoader, setShowLoader]);

    useEffect(() => {
        getUsers({});
    }, [getUsers]);


    const editUser = (id: string) => () => {
        history.push(
            generatePath(
                isBackOfficeAccount(sessionUserType)
                    ? PATH_CUSTOMERS_EDIT_USER
                    : PATH_GENERAL_EDIT_USER,
                {userId: id}
            )
        );
    };

    const getGDPRReport = (userId: string) => () => {
        const link = UserService.generateGDPRFileDownloadLink(userId)
        window.open(link, '_blank')?.focus();
    };

    const sendActivationEmailHandler = (id: string) => async () => {
        activationEmail(id);
    };


    const viewUser = (id: string) => () => {
        history.push(
            generatePath(
                isBackOfficeAccount(sessionUserType)
                    ? PATH_CUSTOMERS_VIEW_USER
                    : PATH_GENERAL_VIEW_USER,
                {userId: id}
            )
        );
    };

    const deleteUserHandler =  (id: string) => async () => {
        setDeleteUserConfirmationModalVisible(true)
        setItemToDelete(id)
    };



    const onSearchChange = (e:any)=> {
        setSearchUser(e.target.value);
    }

    const filteredItems = data.filter(data => (
		!searchUser 
		|| data.userCredential?.email?.toLowerCase().includes(searchUser.toLowerCase())
		|| data.userPermission?.userType?.toLowerCase().includes(searchUser.toLowerCase())
		|| data.userInformation?.position?.toLowerCase().includes(searchUser.toLocaleLowerCase())
		|| data.userInformation?.phoneNumber?.toLowerCase().includes(searchUser.toLocaleLowerCase())
		|| data.userPermission?.resolvedCustomerName?.toLocaleLowerCase().includes(searchUser.toLocaleLowerCase())
		|| data.userInformation?.name?.toLocaleLowerCase().includes(searchUser.toLocaleLowerCase()) 
    ))
    return (
        <div>
            <h1 data-testid="manage_users_headline">
                {t('navigation.General__nav.Manage users')}
            </h1>
            <Table
                title={t('common.Select users')}
                searchField={{
                    name: 'userSearch',
                    text: t('common.Search user'),
                    onChange: onSearchChange,
                    value: searchUser,
                }}
                header={[
                    t('common.Name'),
                    t('common.Phone number'),
                    t('common.E-mail address'),
                    t('common.Position'),
                    t('common.Company'),
                    t('common.User type'),
                    {
                        key: 'search',
                        component: (
                            <Pager
                                page={1}
                                totalItems={users.data.length}
                                onPagination={()=>{}}
                                limit={users.data.length}
                            />
                        ),
                    },
                ]}
                row={(row:User) => (
                    <>
                        <td>
                            {row.userInformation?.name}
                        </td>
                        <td>{row.userInformation?.phoneNumber}</td>
                        <td>{row.userCredential.email}</td>
                        <td>{row.userInformation?.position}</td>
                        <td>{row.userPermission?.resolvedCustomerName}</td>
                        <td>{t(`common.${row.userPermission?.userType}`)}</td>
                        <td className="text-center">
                            {isMainAccount(sessionUserType) &&  <DropdownButton
                                id={`user__option__dropdown_${row.id}`}
                                label={t('common.Options')}
                                dropdownList={[
                                    {text: t('common.View'), onClick: viewUser(row.id)},
                                    {text: t('common.Edit'), onClick: editUser(row.id)},
                                    {text: t('common.Send activation email'), onClick:sendActivationEmailHandler(row.id)},
                                    {text: t('common.Delete'), className: 'text-danger', onClick: deleteUserHandler(row.id)},
                                ]}
                            />}
                            
                            {isBackOfficeAccount(sessionUserType) &&
                            <DropdownButton
                                id={`user__option__dropdown_${row.id}`}
                                label={t('common.Options')}
                                dropdownList={[
                                    {text: t('common.View'), onClick: viewUser(row.id)},
                                    {text: t('common.Edit'), onClick: editUser(row.id)},
                                    {text: t('common.GDPR report'), onClick: getGDPRReport(row.id)},
                                    {text: t('common.Send activation email'), onClick:sendActivationEmailHandler(row.id)},
                                    {text: t('common.Delete'), className: 'text-danger', onClick: deleteUserHandler(row.id)},
                                ]}
                            />}
                        </td>
                    </>
                )}
                list={filteredItems}
                extractKey={(el) => el.id}
            />
            <DeleteUserConfirmationModal
                modalVisible={deleteUserConfirmationModalVisible}
                setModalVisible={setDeleteUserConfirmationModalVisible}
                onConfirmation={deleteItem}
            />
        </div>
    );
}

export default connector(ManageUsers);
