import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import './PreviewModal.scss';

import {News} from '../../store/news/types';

import Modal from '../Modal';
import Carousel from '../Carousel';

type PreviewModalProps = {
  modalVisible: boolean;
  carouselItem?: News | null;
  setModalVisible: (v: boolean) => void;
  onPublish: () => void;
  isCustomer?: boolean
};

export default function PreviewModal({
  modalVisible,
  carouselItem,
  setModalVisible,
  onPublish,
  isCustomer
}: PreviewModalProps) {
  const {t} = useTranslation();
  const [images, setImages] = useState<News[]>([]);

  useEffect(() => {
    if (modalVisible && carouselItem) {
      setImages([carouselItem]);
    } else {
      // cleanup
      setImages([]);
    }
  }, [modalVisible, carouselItem]);

  return (
    <Modal
      name="news-preview"
      modalVisible={modalVisible}
      setModalVisible={setModalVisible}
      title={t('common.Preview news')}
      dialogClassName="preview__dialog"
    >
      <Carousel
        name="news-preview"
        hideControls
        hideIndicators
        images={images}
        loading={images.length === 0}
        wrapperClassName="preview__container align-self-center"
        slideClassName="mx-0 my-0 mb-4"
      />
      <form>
        <hr className="form-delimiter" />
        {isCustomer &&
        <div className="float-right">
          <button
              type="button"
              data-dismiss="modal"
              className="btn btn-info mr-2"
          >
            {t('common.Back')}
          </button>
        </div> }
        { !isCustomer &&
        <div className="float-right">
          <button
              type="button"
              data-dismiss="modal"
              className="btn btn-info mr-2"
          >
            {t('common.Back')}
          </button>
          <button
              type="button"
              className="btn btn-primary"
              data-testid="submit__company_form"
              onClick={onPublish}
          >
            {t('common.Publish')}
          </button>
        </div>
        }

      </form>
    </Modal>
  );
}
