import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useFieldArray, useFormContext} from 'react-hook-form';
import Icon from '@mdi/react';
import './ProductCategoryForm.scss';
import {iconClear} from '../../lib/svg';
import InputField from '../../components/Form/InputField';
import {ProductCategoryInput} from '../../store/productCategories/types';

const formColumnClass = 'col-md-12 col-lg-6';

const ClearIcon = () => <Icon path={iconClear} size="24" className="icon-24"/>;

function SubCategories<T extends ProductCategoryInput>({
	prefix,
	isEdit
}: {
    prefix: string;
    isEdit: boolean;
}) {
    const {t} = useTranslation();
    const formContext = useFormContext()
    const {control, register} = formContext

    const {fields, append, remove} = useFieldArray({
        control: control,
        name: prefix,
        keyName: 'id',
    });

    useEffect(() => {
        if (fields?.length === 0) {
            append({categoryName: ''});
        }
    }, [fields?.length]);

    return (
        <>
            {fields?.map(({id, categoryName}, index) =>
                    id && <React.Fragment key={id}>
                        <InputField
                            key={id}
                            name={`${prefix}[${index}].categoryName`}
                            defaultValue={categoryName}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const {setValue} = formContext
                                const {currentTarget: {value}} = e
                                setValue(`${prefix}[${index}].categoryName`, value)
                            }}
                            label={index === 0 ? t('common.Subcategory') : index === 1 ? ' ' : undefined}
                            placeholder={t('common.Level X', {level: 3})}
                            className="col-6 mb-1"
                            inputAction={{
                                content: <ClearIcon/>,
                                onClick: () => {
                                    remove(index)
                                }
                            }}
                            inputRef={register()}
                        />
                        {/* workaround to keep the ID */}
                        {isEdit &&
							<input
								type="hidden"
								data-testid={`hidden_input__${prefix}[${index}].id`}
								name={`${prefix}[${index}].id`}
								defaultValue={id}
								ref={register()}
							/>
                        }

                    </React.Fragment>
        	)}
            <div className="col-12">
                <button
                    type="button"
                    className="add-field-btn"
                    onClick={() => append({categoryName: ''})}
                >
                    + {t('common.Add subcategory')}
                </button>
            </div>
        </>
    );
}

type ProductCategoryFormProps<T extends ProductCategoryInput> = {
	isEdit: boolean;
};

export default function ProductCategoryForm<T extends ProductCategoryInput>({
	isEdit
}: ProductCategoryFormProps<T>) {
    const {t} = useTranslation();

    const formContext = useFormContext()
    const {control, register} = formContext

    const {fields, append, remove, move} = useFieldArray({
        control,
        name: 'categorySeconds',
        keyName: 'id'
    });

    useEffect(() => {
        if (fields.length === 0) {
            append({categoryName: '', categoryThirds: [{categoryName: ''}]});
        }
    }, [fields.length, append]);

    return (
        <>
            <div className="form-row">
                <InputField
                    name="categoryName"
                    handler={formContext}
                    label={t('common.Main category')}
                    placeholder={t('common.Level X', {level: 1})}
                    className={formColumnClass}
                    rules={{required: true}}
                />
              {isEdit &&
              <input
                  type="hidden"
                  data-testid={`hidden_input__category.id`}
                  name="id"
                  ref={register}
              />}
            </div>

            {fields.map(({id, categoryName, categoryThirds}, index) => {
                const subCategoriesPrefix = `categorySeconds[${index}].categoryThirds`;
                return (
                    id && <React.Fragment key={id}>
                        <hr className="form-delimiter"/>
                        <div className="row">
                            <div className={formColumnClass}>
                                <div className="form-row">
                                    <InputField
                                        key={id}
                                        name={`categorySeconds[${index}].categoryName`}
                                        defaultValue={categoryName}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            const {setValue} = formContext
                                            const {currentTarget: {value}} = e
                                            setValue(`categorySeconds[${index}].categoryName`, value, true)
                                        }}
                                        label={t('common.Secondary category')}
                                        placeholder={t('common.Level X', {level: 2})}
                                        className="col-12 mb-1"
                                        inputAction={{
                                            content: <ClearIcon/>,
                                            onClick: () => {
                                                // TODO: Remove this ugly code when react hook form has been upgraded/patched
                                                // Due to a bug in react hook forms causing the underlying array reference to
                                                // not clear correctly when removing the parent (second category).
                                                // This will cause the third category useFieldArray to register inside off
                                                // the next third category array in the next secondary category which causes it to be set to null.

                                                // Move the secondary category to the end of the array before deleting
                                                // In doing so the underlying third category useFieldArray cannot be registered
                                                // to the next

                                                // Very ugly hack.
                                                const last = fields.length -1
                                                move(index, last)
                                                const timeout = setTimeout(() => remove(last), 40)

                                                return () => {
                                                    clearTimeout(timeout)
                                                }
                                            }
                                        }}
                                        inputRef={register()}
                                    />
                                    {/* workaround to keep the ID */}
                                    {isEdit &&
                                    <input
                                        type="hidden"
                                        data-testid={`hidden_input__categorySeconds[${index}].id`}
                                        name={`categorySeconds[${index}].id`}
                                        defaultValue={id}
                                        ref={register()}
                                    />}

                                    {fields.length - 1 === index && (
                                        <div className="col-12">
                                            <button
                                                type="button"
                                                className="add-field-btn"
                                                onClick={() => append({categoryName: '', categoryThirds: [{categoryName: ''}]})}
                                            >
                                                + {t('common.Add secondary category')}
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className={formColumnClass}>
                                <div className="form-row">
                                    <SubCategories
                                        prefix={subCategoriesPrefix}
                                        isEdit={isEdit}
                                    />
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                );
            })}
        </>
    );
}
