import React, {useMemo} from 'react';
import {FormContextValues} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

import {Customer} from '../../store/companies/types';
import {Module} from '../../store/users/types';

import {DEFAULT_RULES} from '../../lib/form';
import {useToggleAllCheckboxes} from '../../lib/hooks';

import InputField from '../Form/InputField';
import {InlineCheckbox} from '../Form/InlineCheckInput';
import ToggleField from '../Form/ToggleField';
import {UserClause} from '../../store/users/types';

const formColumnClass = 'col-md-12 col-lg-6';

export type AdminAccountFieldsInput = {
    name: string;
    phoneNumber: string;
    email: string;
    position: string;
    userClause: UserClause;
};

export type AdminAccountFormProps = {
    handleInput: FormContextValues<AdminAccountFieldsInput>;
    readOnly?: boolean;
    isEdit?: boolean;
};

export function AdminAccountForm({
                                     handleInput,
                                     readOnly,
                                     isEdit
                                 }: AdminAccountFormProps) {
    const {t} = useTranslation();

    return (
        <>
            <legend data-testid="form__admin_form">
                {t('common.Contact details')}
            </legend>
            <div className="form-row">
                <InputField
                    name="name"
                    handler={handleInput}
                    label={t('common.Name')}
                    className={formColumnClass}
                    readOnly={readOnly}
                    rules={{
                        required: true,
                    }}
                />
                <InputField
                    name="phoneNumber"
                    handler={handleInput}
                    label={t('common.Phone number')}
                    className={formColumnClass}
                    readOnly={readOnly}
                    rules={{
                        required: true,
                    }}
                />
                <InputField
                    name="email"
                    handler={handleInput}
                    label={t('common.E-mail address')}
                    className={formColumnClass}
                    readOnly={isEdit}
                    rules={{
                        required: true,
                        minLength: DEFAULT_RULES.email.minLength,
                        pattern: {
                            value: DEFAULT_RULES.email.pattern,
                            message: 'invalid email',
                        },
                    }}
                />
                <InputField
                    name="position"
                    handler={handleInput}
                    label={t('common.Position')}
                    className={formColumnClass}
                    readOnly={readOnly}
                    rules={{
                        required: true,
                    }}
                />
            </div>

            <hr className="form-delimiter"/>
        </>
    );
}

export type AdminModulesFieldsInput = {
    accessModules: Module[];
    userClause: UserClause
};

export type AdminAccountPrivilegesProps = {
    handleInput: FormContextValues<AdminModulesFieldsInput>;
    readOnly?: boolean;
    toolTipText?: string;
    warning?: boolean;
};

export function AdminAccountPrivileges({
	handleInput,
	readOnly,
	toolTipText,
	warning
}: AdminAccountPrivilegesProps) {
    const {t} = useTranslation();

    const modulesOptions = useMemo(
        () => [
            {
                value: Module.ACCOUNT_MANAGEMENT,
                label: t('navigation.Account management'),
            },
            {
                value: Module.E_SHOP_BACK,
                label: t('navigation.E-shop'),
            },
            {
                value: Module.MATERIAL_CALCULATOR,
                label: t('navigation.Material calculator'),
            },
            {
                value: Module.OFFER_GENERATION,
                label: t('navigation.Offer generator'),
            },
            {
                value: Module.ORDER_MANAGEMENT,
                label: t('navigation.Order management'),
            },
            {
                value: Module.CASE_MANAGEMENT,
                label: t('navigation.Case management'),
            },
        ],
        [t]
    );

    const {allCheckboxes: allModules} = useToggleAllCheckboxes(
        'allModules',
        'accessModules',
        handleInput,
        modulesOptions
    );

    return (
        <>
            <legend data-testid="form__admin_privileges">
                {t('common.Privileges')}
            </legend>
            <div className={toolTipText !== undefined ? "form-row cursor-pointer" : "form-row"} data-toggle="tooltip" data-placement="top" title={toolTipText}>
                <ToggleField
                    switchText={[t('common.All rights'), '']}
                    name="allModules"
                    handler={handleInput}
                    label={t('common.Modules')}
                    readOnly={readOnly}
                    className="col-auto"
                    singleMode
                    switchClassName="p-0"
                />
                {/* sorry, had no better idea */}
                <div className="vertical-separator">
                    <div className="vertical-separator-line"></div>
                </div>
                <InlineCheckbox
                    handler={handleInput}
                    name="accessModules"
                    label=""
                    readOnly={readOnly || allModules}
                    options={modulesOptions}
                />
                {warning && <span className='text-danger'>
                        {t('validation.Select at least one right')}
                    </span>}
            </div>

            <hr className="form-delimiter"/>
        </>
    );
}

export type CustomerAccountFieldsInput = {
    name: string;
    phoneNumber: string;
    email: string;
    position: string;
    belongsToCompany:string;
    userClause: UserClause;
};

export type CustomerAccountFormProps = {
    handleInput: FormContextValues<CustomerAccountFieldsInput>;
    readOnly?: boolean;
    isEdit?: boolean;
};

export function CustomerAccountForm({handleInput,readOnly, isEdit}: CustomerAccountFormProps) {
    const {t} = useTranslation();

    return (
        <>
            <legend data-testid="form__customer_form">
                {t('common.Contact details')}
            </legend>
            <div className="form-row">
                <input
                    type="hidden"
                    name="belongsToCompany"
                    defaultValue={''}
                    ref={handleInput.register()}
                />
                <InputField
                    name="name"
                    handler={handleInput}
                    label={t('common.Name')}
                    className={formColumnClass}
                    readOnly={readOnly}
                    rules={{
                        required: true,
                    }}
                />
                <InputField
                    name="phoneNumber"
                    handler={handleInput}
                    label={t('common.Phone number')}
                    className={formColumnClass}
                    readOnly={readOnly}
                    rules={{
                        required: true,
                    }}
                />
                <InputField
                    name="email"
                    handler={handleInput}
                    label={t('common.E-mail address')}
                    className={formColumnClass}
                    readOnly={isEdit}
                    rules={{
                        required: true,
                        minLength: DEFAULT_RULES.email.minLength,
                        pattern: {
                            value: DEFAULT_RULES.email.pattern,
                            message: 'invalid email',
                        },
                    }}
                />
                <InputField
                    name="position"
                    handler={handleInput}
                    label={t('common.Position')}
                    className={formColumnClass}
                    readOnly={readOnly}
                    rules={{
                        required: true,
                    }}
                />
            </div>

            <hr className="form-delimiter"/>
        </>
    );
}

export type AssociatedCompanyFormProps = {
    handleInput: FormContextValues<CustomerAccountFieldsInput>;
    openCompaniesModal?: () => void;
    company: Customer | null;
};

export function AssociatedCompanyForm({
                                          handleInput,
                                          openCompaniesModal,
                                          company,
                                      }: AssociatedCompanyFormProps) {
    const {t} = useTranslation();

    return (
        <>
            <legend data-testid="form__customer_company_form">
                {t('common.Company details')}
            </legend>
            {typeof openCompaniesModal === 'function' && (
                <div className="form-row">
                    <div className="form-group col-md-12 ">
                        <button
                            type="button"
                            className="btn btn-info"
                            onClick={() => openCompaniesModal()}
                        >
                            {t('common.Associate user with company')}
                        </button>
                        {handleInput.formState.submitCount > 0 && company === null && (
                            <span className="form-control-error text-danger mt-2">
                {t('validation.required')}
              </span>
                        )}
                    </div>
                </div>
            )}
            <div className="form-row">
                <InputField
                    disabled={true}
                    name="company.name"
                    label={t('common.Name')}
                    className={formColumnClass}
                    value={company?.Customer.Name ?? ''}
                />
                <InputField
                    disabled={true}
                    name="company.organizationNumber"
                    label={t('common.Organization number')}
                    className={formColumnClass}
                    value={company?.Customer.OrganisationNumber ?? ''}
                />
                <InputField
                    disabled={true}
                    name="company.invoiceAddress"
                    label={t('common.Invoice address')}
                    className={formColumnClass}
                    value={company?.Customer.Address1 ?? ''}
                />
                <InputField
                    disabled={true}
                    name="company.companyPhoneNumber"
                    label={t('common.Phone number')}
                    className={formColumnClass}
                    value={company?.Customer.Phone1?? company?.Customer.Phone}
                />
                <InputField
                    disabled={true}
                    name="company.companyEmail"
                    label={t('common.E-mail address')}
                    className={formColumnClass}
                    value={company?.Customer.Email ?? ''}
                />
                <InputField
                    disabled={true}
                    name="company.webAddress"
                    label={t('common.Web address')}
                    className={formColumnClass}
                    value={company?.Customer.Url ?? ''}
                />
                <InputField
                    disabled={true}
                    name="company.companyEmailInvoice"
                    label={t('common.E-mail address invoice')}
                    className={formColumnClass}
                    value={company?.Customer.EmailInvoice ?? ''}
                />
            </div>

            <hr className="form-delimiter"/>
        </>
    );
}

export type UserClauseFieldsInput = {
    id: string | null,
    offerLimitation: number| null,
    offerMinCoverage: number| null,
    orderLimitation: string| null,
    paymentCondition: string| null,
    priceGroup: string| null,
    responsibleSeller: string| null
};

export type UserClauseFormProps = {
    handleInput: FormContextValues<UserClauseFieldsInput>;
};

export function UserClauseForm({
    handleInput,
}: UserClauseFormProps) {
    const {t} = useTranslation();
    return(
        <>
        <legend data-testid="form__customer_company_form">
                {t('common.Clause')}
            </legend>
        <div className="form-row">
            <InputField
                    name="userClause.offerLimitation"
                    handler={handleInput}
                    label={t('common.offerLimitation')}
                    className={formColumnClass}
                    type="number"
                    rules={{max: {value: 2147483647, message: t('Enter a smaller number')} }}
                />
                <InputField
                    name="userClause.offerMinCoverage"
                    handler={handleInput}
                    label={t('common.offerMinCoverage')}
                    className={formColumnClass}
                    type="number"
                    rules={{max: {value: 100, message: t('Enter a smaller number')}}}
                />
        </div>
        </>
    );
}