import React from 'react'
import Autocomplete, { AutocompleteRenderInputParams } from "@material-ui/lab/Autocomplete/Autocomplete";
import { Option } from '../Form/SelectField';
import { Control, Controller } from 'react-hook-form';
import Box from '@material-ui/core/Box/Box';

interface ControlledAutocompleteProps { 
	options?: Option[],
	renderInput: (params: AutocompleteRenderInputParams) => React.ReactNode
	control: Control<Record<string, any>>
	defaultValue?: Option
	name: string
	readOnly?: boolean
	className?: string
	label?: string
	onChange?: (opt: Option) => unknown
	disableClearable?: boolean
}

const ControlledAutocomplete: React.FC<ControlledAutocompleteProps> = ({ 
	options = [], 
	renderInput, 
	control,
	defaultValue = { label: '', value: '' }, 
	name,
	readOnly,
	className,
	label,
	onChange: handleChange,
	disableClearable,
}) => (
	<Box display="grid" className={className}>
		{!label ?? <label>{label}</label>}
		<Controller
			as={({ onChange, ...props }) => 
				<Autocomplete
					options={options}
					getOptionLabel={(opt: Option)=> opt.label}
					renderInput={renderInput}
					onChange={(_, data: Option) => {
						onChange(data)
						handleChange?.(data)
					}}
					disabled={readOnly}
					style={{ opacity: .75 }}
					disableClearable={disableClearable}
					{...props}
				/>
			}
			defaultValue={defaultValue}
			name={name}
			control={control}
			readOnly={readOnly}
			style={{ opacity: .75 }}
		/>
	</Box>
)

export default ControlledAutocomplete
