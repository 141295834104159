import React, {useEffect, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';

import {AppState} from '../../store';
import {setShowLoader} from '../../store/system';
import {getComments, resetComments} from '../../store/comments';

import ManageCompanyTabNav from '../../components/Company/ManageCompanyTabNav';
import {useForm} from "react-hook-form";
import CommentsForm, {CommentsFormInput} from "../../components/Company/CommentsForm";

import CommentsService from '../../services/comments'
import {Comment} from '../../store/comments/types';
import {formatDate} from '../../lib/format';
import {iconClear} from "../../lib/svg";
import Icon from "@mdi/react";

const connector = connect(
  (state: AppState) => ({
    customerComments: state.comments.list,
  }),
  {setShowLoader, getComments, resetComments}
);

interface EditCommentsProps extends ConnectedProps<typeof connector>{};

const CompanyUsers = ({
  customerComments, 
  getComments,
  resetComments,
  setShowLoader,
}: EditCommentsProps) => {
  const {companyId} = useParams<{companyId:string}>();
  const {t} = useTranslation();

  const {
    data: data,
    loading: loading,
} = customerComments;

  const showLoader = loading;

  useEffect(() => {
    setShowLoader(showLoader);
}, [showLoader, setShowLoader]);

  useEffect(() => {
        getComments(companyId);
    }, [companyId, getComments]);

  useEffect(() => { return () => { resetComments() } }, [])

  const handleInput = useForm<CommentsFormInput>({mode: 'onChange'});

  const onSubmit = async (inputs: CommentsFormInput) => {
    const {comment} = inputs;
    try {
      await CommentsService.createComment(companyId, {comment});
      getComments(companyId);
      handleInput.reset()
    } catch (e) {
    }
  };

  const onDelete = async (commentId: string) => {
      try {
          await CommentsService.deleteComment(companyId,commentId)
          getComments(companyId);
      } catch (e) {
      }
  }

    return (
    <div>
      <h1 data-testid="company_comments_headline">
        {t('navigation.Customers__nav.Manage companies')}
      </h1>
      <ManageCompanyTabNav companyId={companyId} />
        {
            !showLoader && data !== null && data.length > 0 && data?.map((comment:Comment)=>{
              return(
                  <div className="alert alert-secondary bg-white p-3 row col-12" role="alert" key={comment.id}>
                    <span className="font-weight-bold col-8">{comment.comment}</span>
                    <div className="col-4 text-right">
                        <span>{`${comment.firstName}`}</span>
                        <span className="mx-3">{t('common.Date')}: {formatDate(comment.updatedAt)}</span>
                        <span className="cursor-pointer" onClick={()=>onDelete(comment.id)}> <Icon path={iconClear} size="24"  className="icon-44"/> </span>
                    </div>
                  </div>
              )
            })
        }

      <CommentsForm handleInput={handleInput} onSubmit={onSubmit}/>
    </div>
  );
}

export default connector(CompanyUsers);
