import React, { useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory, generatePath } from 'react-router-dom';

import {
  PATH_CUSTOMERS_EDIT_COMPANY,
  PATH_CUSTOMERS_VIEW_COMPANY,
} from '../../router/paths';

import { AppState } from '../../store';
import { setShowLoader } from '../../store/system';
import { getCustomers } from '../../store/companies';

import { useQuery } from '../../lib/hooks';

import Table from '../../components/Table';
import Pager from '../../components/Pager';
import DropdownButton from '../../components/DropdownButton';
import TabNav from '../../components/TabNav';

enum CompanyStatus {
  All = '',
  Active = 'Active',
  Inactive ='Inactive'
}

const connector = connect(
  (state: AppState) => ({
    companies: state.companies.list,
  }),
  { setShowLoader, getCustomers }
);

type ManageCompaniesProps = ConnectedProps<typeof connector> & {};

export function ManageCustomers({
  companies,
  setShowLoader,
  getCustomers
}: ManageCompaniesProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const query = useQuery();
  const filter = query.get('filter') as CompanyStatus ?? ""
  const page = query.get('page');
  const search = query.get('search');
  const { data, loading: listLoading } = companies;

  const showLoader = listLoading;

  useEffect(() => {
    setShowLoader(showLoader);
  }, [showLoader, setShowLoader]);

  const editCompany = (id: string) => () => {
    history.push(generatePath(PATH_CUSTOMERS_EDIT_COMPANY, { companyId: id }));
  };

  const viewCompany = (id: string) => () => {
    history.push(generatePath(PATH_CUSTOMERS_VIEW_COMPANY, { companyId: id }));
  };
  const [searchStr, setSearchStr] = useState('');

  const onSearchChange = (e: any) => {
    setSearchStr(e.target.value);
  }

  useEffect(() => {
    localStorage.setItem("filter", filter ?? '')
    getCustomers(localStorage.getItem('filter'));
  }, [getCustomers, filter]);

  const filteredItems = companies.data.filter((data) => {
    if (!searchStr)
      return data
    else if (data.Customer.Name.toLowerCase().includes(searchStr.toLowerCase()) ||
      data.Customer.Email.toLowerCase().includes(searchStr.toLowerCase()) ||
      data.Customer.CustomerNumber.toLowerCase().includes(searchStr.toLowerCase()) ||
      data.Customer.OrganisationNumber.toLowerCase().includes(searchStr.toLowerCase())
    ) {
      return data
    }
  })

	const navLinks = useMemo(() => Object.values(CompanyStatus).map(s => ({
			isActive: filter === s,
			path: `?filter=${s}`,
			component: (
				<>
				{t(`common.${s === ''?'All':s}`)}
        {filter === s && 
				<span className="badge badge-info">
				 {companies.data.length} st
				</span>}
		</>
		),
	})), [filter, companies.data, t])

  return (
    <div>
      <h1 data-testid="manage_companies_headline">
        {t('navigation.Customers__nav.Manage companies')}
      </h1>
      <TabNav links={navLinks}></TabNav>
      <Table
        loading={listLoading}
        title={t('common.Select company')}
        searchField={{
          name: 'companySearch',
          text: t('common.Search company'),
          onChange: onSearchChange,
          value: searchStr,
        }}
        header={[
          t('common.Customer numb'),
          t('common.Name'),
          t('common.Organization number'),
          t('common.Phone number'),
          t('common.E-mail address'),
          {
            key: 'pager',
            component: (
              <Pager
                page={page ? +page : 1}
                totalItems={data.length}
                onPagination={() => { }}
                limit={data.length}
              />
            ),
          },
        ]}
        row={(row) => (
          <>
            <td>{row.Customer.CustomerNumber}</td>
            <td>{row.Customer.Name}</td>
            <td>{row.Customer.OrganisationNumber}</td>
            <td>{row.Customer.Phone}</td>
            <td>{row.Customer.Email}</td>
            <td className="text-center">
              <DropdownButton
                id={`company__option__dropdown_${row.id}`}
                label={t('common.Options')}
                dropdownList={[
                  { text: t('common.View'), onClick: viewCompany(row.InternalCustomer.id) },
                  { text: t('common.Edit'), onClick: editCompany(row.InternalCustomer.id) }
                ]}
              />
            </td>
          </>
        )}
        list={filteredItems}
        extractKey={(el) => `${el.InternalCustomer.id}`}
      />
    </div>
  );
}

export default connector(ManageCustomers);
