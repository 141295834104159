import React from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {GDPRText} from '../Auth/ConfirmUser';
import {AppState} from '../../store';
import {useTranslation} from "react-i18next";

const connector = connect((state: AppState) => ({}), {});

type GDPRPageProps = ConnectedProps<typeof connector> & {};

function GDPRPage({}: GDPRPageProps) {
    const {t} = useTranslation();
    return <>
        <h1 className="primary" data-testid="GDPR_page">{t('common.GDPR report')}</h1>
        <GDPRText/>
    </>

}

export default connector(GDPRPage);
