import Box from '@material-ui/core/Box/Box'
import Fade from '@material-ui/core/Fade/Fade'
import Grid, { GridSize } from '@material-ui/core/Grid/Grid'
import Typography from '@material-ui/core/Typography/Typography'
import { ErrorOutline } from '@material-ui/icons'
import React, { useRef } from 'react'

const FieldGroup: React.FC<{ 
	label?: string
	hint?: string
	value?: any, 
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void 
	required?: boolean
	style?: object
	inputStyle?: object
	className?: string
	extraInformation?: JSX.Element
	size?: { xs?: GridSize, sm?: GridSize, md?: GridSize, lg?: GridSize, xl?: GridSize }
	loading?: boolean 
}> = ({ 
	label, 
	hint, 
	value, 
	onChange, 
	required, 
	children, 
	style = {}, 
	className, 
	inputStyle, 
	extraInformation, 
	size,
	loading
}) => {
	const touched = useRef(!!children)

	const showError = touched.current && required && !loading

	return (
		<Grid item {...size??{xs:12,sm:6}} style={{...style}} className={className}>
			{ !!label && 
				<Typography variant="caption" component="label">
					{ required && <span className="text-danger">* </span> }
					{label}
					{ hint && <Typography variant="caption" color="textSecondary" component="span" className="pl-1">{hint}</Typography> }
				</Typography>
			}
			<Box className="mb-1" style={{ position: 'relative', width: '100%' }}>
				{ !children ?
					<input 
						type="text" 
						placeholder={label} 
						value={value} 
						onChange={e => {
							touched.current ||= true
							onChange?.(e)
						}} 
						className="form-control"
						readOnly={!onChange}
						required={required}
						style={inputStyle}
					/> 
					: children
				}
				{ showError &&
					<Fade in={required && value === ""}>
						<ErrorOutline color="error" style={{ position: 'absolute', fontSize: 16, top: 'calc(50% - (16px / 2))', right: 16/2 }} />
					</Fade>
				}
			</Box>
			{ extraInformation ?? '' }
			<Fade in={showError && value === ""}>
				<Typography variant="caption" className="text-danger ml-2">Obligatorisk</Typography>
			</Fade>
		</Grid>
	)
}

export default FieldGroup
