import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import messageService from '../../services/bannermessageService';
import { ListData } from '../types';
import { entityFulfilled, entityPending, entityRejected, listFulfilled, listPending, listRejected } from '../utils';
import { BannerMessage, CreateBannerMessageFormType, BannerMessagesState } from './types';
import errorResponse, { ErrorResponseResolver } from '../../lib/responseHelper';

const name = 'bannerMessages';

export const getAllBannerMessages = createAsyncThunk<ListData<BannerMessage>>(
    `eon-ui/getAllBannerMessages`,
    async function () {
        const { data, headers } = await messageService.getAllBannerMessages();
        return {
            data: data as BannerMessage[],
            total: +headers['x-total-count'],
        };
    }
);

export const createBannerMessage = createAsyncThunk<BannerMessage, {
    createBannerMessageFormType: CreateBannerMessageFormType,
    callback: (errorResponseResolver: ErrorResponseResolver) => void;
}>(
    `eon-ui/create`,
    async function ({createBannerMessageFormType, callback}) {
        try{
            const { data } = await messageService.createBannerMessage(createBannerMessageFormType);
            return data as BannerMessage;
        }
        catch(error){
            callback(errorResponse(error))
            return {} as BannerMessage;
        }
    }
);

export const deleteBannerMessage = createAsyncThunk<BannerMessage, {
    bannerMessageId: string,
    callback: (errorResponseResolver: ErrorResponseResolver) => void;
}>(
    `eon-ui/delete`,
    async function ({bannerMessageId, callback}) {
        try{
            await messageService.deleteBannerMessage(bannerMessageId);
            return {} as BannerMessage;
        }
        catch(error){
            callback(errorResponse(error))
            return {} as BannerMessage;
        }
    }
);

export const editBannerMessage = createAsyncThunk<BannerMessage, {
    createBannerMessageFormType: CreateBannerMessageFormType,
    bannerMessageId: string,
    callback: (errorResponseResolver: ErrorResponseResolver) => void;
}>(
    `eon-ui/editBannerMessage`,
    async function ({createBannerMessageFormType, bannerMessageId, callback}) {
        try{
            const { data } = await messageService.editBannerMessage(createBannerMessageFormType, bannerMessageId);
            return data as BannerMessage;
        }
        catch(error){
            callback(errorResponse(error))
            return {} as BannerMessage;
        }
    }
);

const initialState: BannerMessagesState = {
    list: {
        data: [],
        loading: false,
        requestId: '',
        total: 0,
    },
    selected: {
        data: null,
        loading: false,
        requestId: '',
    },
};


const bannerMessagesSlice = createSlice({
    name,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        //delete from cart
        builder.addCase(getAllBannerMessages.fulfilled, (state, action) => {
            listFulfilled(state.list, action);
        });
        builder.addCase(getAllBannerMessages.pending, (state, action) => {
            listPending(state.list, action);
        });
        builder.addCase(getAllBannerMessages.rejected, (state, action) => {
            listRejected(state.list, action);
        });
        /*builder.addCase(getAllActiveHeaderMessages.fulfilled, (state, action) => {
            listFulfilled(state.list, action);
        });
        builder.addCase(getAllActiveHeaderMessages.pending, (state, action) => {
            listPending(state.list, action);
        });
        builder.addCase(getAllActiveHeaderMessages.rejected, (state, action) => {
            listRejected(state.list, action);
        });*/
        /*builder.addCase(createServiceCase.fulfilled, (state, action) => {
            entityFulfilled(state.selected, action);
        });
        builder.addCase(createServiceCase.pending, (state, action) => {
            entityPending(state.selected, action);
        });
        builder.addCase(createServiceCase.rejected, (state, action) => {
            entityRejected(state.selected, action);
        });*/
        /*builder.addCase(getSpecificBannerMessage.fulfilled, (state, action) => {
            entityFulfilled(state.selected, action);
        });
        builder.addCase(getSpecificBannerMessage.pending, (state, action) => {
            entityPending(state.selected, action);
        });
        builder.addCase(getSpecificBannerMessage.rejected, (state, action) => {
            entityRejected(state.selected, action);
        });*/
        builder.addCase(editBannerMessage.fulfilled, (state, action) => {
            entityFulfilled(state.selected, action);
        });
        builder.addCase(editBannerMessage.pending, (state, action) => {
            entityPending(state.selected, action);
        });
        builder.addCase(editBannerMessage.rejected, (state, action) => {
            entityRejected(state.selected, action);
        });
        builder.addCase(deleteBannerMessage.fulfilled, (state, action) => {
            entityFulfilled(state.selected, action);
        });
        builder.addCase(deleteBannerMessage.pending, (state, action) => {
            entityPending(state.selected, action);
        });
        builder.addCase(deleteBannerMessage.rejected, (state, action) => {
            entityRejected(state.selected, action);
        });
    }
});

export default bannerMessagesSlice.reducer;