import React, { useEffect, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { useForm } from "react-hook-form"
import { useHistory, useParams } from 'react-router-dom'
import { AppState } from '../../../store'
import { PATH_CASES_BACK_OFFICE_LIST } from '../../../router/paths'
import { updateServiceCase, getSpecificServiceCase, deleteServiceCaseAttachment, downloadServiceCaseAttachment } from '../../../store/cases'
import { CreateCaseFormType, ServiceCaseStatus } from '../../../store/cases/types'
import CaseManagementTabNav from './../CaseManagementTabNav'
import EditCaseManagementCaseForm from './EditCaseManagementCaseForm'
import { getCaseManamagementCategoriesOptions } from '../../../store/genericListOption'
import { Option } from '../../../components/Form/SelectField'
import { ErrorResponseResolver } from '../../../lib/responseHelper'
import { toggleToast } from '../../../store/components';
import { getActiveUser } from '../../../store/system';
import { JocularUser, Module } from "../../../store/users/types";
import InputField from '../../../components/Form/InputField'

const connector 
= connect(
    (state: AppState) => ({
        genericListOption: state.genericListOption.selected,
        selectedCase: state.cases.selected,
        activeUser: state.system.sessionUser,
    }),
    { 
        getCaseManamagementCategoriesOptions,
        updateServiceCase, 
        toggleToast, 
        getSpecificServiceCase, 
        deleteServiceCaseAttachment, 
        downloadServiceCaseAttachment,
        getActiveUser
    }
);

type EditCaseProps = ConnectedProps<typeof connector> & {};

export function EditCase({
    genericListOption,
    selectedCase,
    activeUser,
    getCaseManamagementCategoriesOptions,
    updateServiceCase,
    toggleToast,
    getSpecificServiceCase,
    deleteServiceCaseAttachment,
    downloadServiceCaseAttachment,
    getActiveUser
}: EditCaseProps) {
    const history = useHistory();
    const { serviceCaseId } = useParams() as {
        serviceCaseId: string;
    
    }
    const [categoriesOptionsData, setCategoriesOptionsData] = useState<Option[] | []>([]);
    const [priorityOptionsData, setPriorityOptionsData] = useState<Option[] | []>([
		{label: "1 - Akut", value: "1"},
		{label: "2 - Hög", value: "2"},
		{label: "3 - Medel", value: "3"},
		{label: "4 - Låg", value: "4"}
	]);
    const [user, setUser] = useState<JocularUser>();
    
    useEffect(() => {
        getActiveUser()
            .then(u => setUser(u.payload as JocularUser))
    },[serviceCaseId, getActiveUser])

    type Attachment = {
        id: string;
        fileList: FileList;
    };
    const [attached, setAttached] = useState<Attachment[] | Attachment[]>([]);

    const setAttachments = function (fileList: FileList, id: string) {
        let ob = {
            fileList: fileList,
            id: id,
        } as Attachment;
        attached.push(ob);
        setAttached(attached);
    };

    const deleteAttachments = (async (id: string) => {
        await deleteServiceCaseAttachment({
            attachmentId: id,
            serviceCaseId: serviceCaseId,
            callback: (errorResponseResolver: ErrorResponseResolver) => {
                toggleToast({
                    shouldToggle: true,
                    message: errorResponseResolver.message,
                    status: errorResponseResolver.status
                })
            }
        })
        getSpecificServiceCase({serviceCaseId: serviceCaseId })
    })

    const { data: getCaseManamagementCategoriesOptionsData, loading: getCaseManamagementCategoriesOptionsDataLoading } = genericListOption;
    const { data: selectedCaseData, loading: selectedCaseLoading } = selectedCase;
    
    useEffect(() => {
        getCaseManamagementCategoriesOptions()
    }, [getCaseManamagementCategoriesOptions])

    useEffect(() => {
        getSpecificServiceCase({serviceCaseId: serviceCaseId })
    }, [getSpecificServiceCase])

    useEffect(() => {
        let categoryOptions: Option[] = [];
        getCaseManamagementCategoriesOptionsData?.serviceCaseCategoriesUiList.forEach(val => categoryOptions.push({
            label: val.key,
            value: val.value
        }))
        setCategoriesOptionsData(categoryOptions)
    }, [getCaseManamagementCategoriesOptionsData])

    const handler = useForm<CreateCaseFormType>({ mode: 'onChange' });
    const { reset, watch } = handler;

    useEffect(() => {
        if (selectedCaseData !== undefined && selectedCaseData !== null) {
            let cate = selectedCaseData?.serviceCaseCategory === undefined ? "" : selectedCaseData?.serviceCaseCategory
            const pickedCategory = categoriesOptionsData?.find(opt => opt.value
                === cate);
            
            const prio = selectedCaseData?.casePriority as any as string;
            const pickedPrio = priorityOptionsData?.find(opt => opt.value 
                === prio);

            reset({
                ...pickedCategory,
                caseDescription: selectedCaseData?.caseDescription,
                serviceCaseCategory: pickedCategory,
                caseSubject: selectedCaseData?.caseSubject,
                facility: selectedCaseData?.facility,
                serviceCaseStatus: selectedCaseData?.serviceCaseStatus,
                caseCreatorResolved: selectedCaseData?.caseCreatorResolved,
                customerIdResolved: selectedCaseData?.customerIdResolved,
                casePriority: pickedPrio
            })
            
        }
    }, [selectedCaseData, getCaseManamagementCategoriesOptionsData])




    const handleSubmit = (async (createCaseFormType: CreateCaseFormType) => {
        let fileList =
            attached.map((f) => f.fileList);
			
        await updateServiceCase({
            fileList,
            createCaseFormType,
            serviceCaseId: serviceCaseId,
            callback: (errorResponseResolver: ErrorResponseResolver) => {
                toggleToast({
                    shouldToggle: true,
                    message: errorResponseResolver.message,
                    status: errorResponseResolver.status
                })
            }
        })
        history.push(PATH_CASES_BACK_OFFICE_LIST)
    })

    const requestFile = (async (fileId: string) => {
        await downloadServiceCaseAttachment({
            fileId: fileId,
            serviceCaseId: serviceCaseId,
            callback: (errorResponseResolver: ErrorResponseResolver) => {
                toggleToast({
                    shouldToggle: true,
                    message: errorResponseResolver.message,
                    status: errorResponseResolver.status
                })
            }})
    })

	const getStatus = (status?: ServiceCaseStatus) =>
		status === ServiceCaseStatus.ONCOING ? "Pågående"
		: status === ServiceCaseStatus.NEW ? "Ny"
		: status === ServiceCaseStatus.COMPLETED ? "Avslutad"
		: "Okänd"
		
    return (
        <div>
            <h1 data-testid="manage_companies_headline">Ärendehantering</h1>
            <CaseManagementTabNav 
                isEdit={true}
                sessionUser={user}
                serviceCaseId={serviceCaseId} />
            <h4>Editera ärende</h4>
            <hr/>
            <div className="row">
				<InputField
					handler={handler}
					name="caseCreatorResolved"
					label="Skapad av"
					className="col-md-6 col-lg-6"
					placeholder="Skapad av"
					disabled={true}
					readOnly={true}
				/>
				<InputField
					name="_status_"
					label="Status"
					className="col-md-6 col-lg-6"
					disabled={true}
					readOnly={true}
					value={getStatus(selectedCase.data?.serviceCaseStatus)}
				/>
			</div>
             <InputField
                handler={handler}
                name="customerIdResolved"
                label="Företag"
                className="col-md-6 col-lg-6"
                placeholder="Företag"
                disabled={true}
                readOnly={true}
            />
            <p></p>
            <EditCaseManagementCaseForm
                priorityOptionsData={priorityOptionsData}
                handler={handler}
                handleSubmit={handleSubmit}
                categorys={categoriesOptionsData}
                setAttachments={setAttachments}
                deleteAttachments={deleteAttachments}
                addedFiles={selectedCaseData?.serviceCaseCommentFiles}
                isEdit={true}
                requestFile={requestFile}
            /> 
        </div>
    );
}

export default connector(EditCase);