import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';


export type OrderManagementChangeStatusProps = {
  onClick: any;
  orderId: string;
  loading?: boolean
  isAllowed?: boolean
}


export default function OrderManagementChangeStatus({ onClick, orderId, loading, isAllowed }: OrderManagementChangeStatusProps) {
	const { t } = useTranslation();
	const [listValue, setListValue] = useState("NEW");
	
	let statusList = [
		{
			label: t("common.NEW"),
			value: "NEW"
		},
		{
			label: t("common.BOOKED"),
			value: "BOOKED"
		},
		{
			label: t("common.CONFIRMED"),
			value: "CONFIRMED"
		},
			{
			label: t("common.CANCELED"),
			value: "CANCELED"
		}
	]

	return (
		<>
			<select 
				className="form-control form-control-lg" 
				onChange={event => setListValue(event.target.value)} 
				value={listValue} 
				style={{ marginBottom: '.5rem' }}
				disabled={!isAllowed}
			>
				{statusList.map(opt => (
					<option key={opt.value} value={opt.value}>{opt.label}</option>
				))}
			</select>
			<button 
				className="btn btn-primary btn-block" 
				onClick={onClick(listValue, orderId)} 
				style={{ minWidth: 150 }}
				disabled={!isAllowed || loading}
			>
				{loading && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ marginRight: '.5em' }}></span>}
				{t('common.Change order status')}
			</button>
		</>
	)
}
