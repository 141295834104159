import React, {useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';

import {AppState} from '../../store';

import PriceGroupForm from '../../components/EShop/PriceGroupForm';
import PriceGroupService, {
  PriceGroupRequest,
  PriceGroupRequestArticles,
} from '../../services/priceGroup';
import {
  PriceGroupInput,
  PriceGroupInputNew,
} from '../../store/priceGroups/types';
import {useParams, useHistory} from 'react-router-dom';
import {getPriceGroup, updatePriceGroup} from '../../store/priceGroups';
import {getProductsWithoutFilters} from '../../store/products/';
import { setShowLoader } from '../../store/system';
import { toggleToast } from '../../store/components'
import errorResponse, { ErrorResponseResolver }  from '../../lib/responseHelper';

const connector = connect(
  (state: AppState) => ({
    articles: state.products.list,
    priceGroup: state.priceGroups.selected,
  }),
  {getProductsWithoutFilters, getPriceGroup, updatePriceGroup, setShowLoader, toggleToast }
);

type EditPriceGroupProps = ConnectedProps<typeof connector> & {};

export function EditPriceGroup({
  articles,
  priceGroup,
  updatePriceGroup,
  getProductsWithoutFilters,
  getPriceGroup,
  setShowLoader,
  toggleToast
}: EditPriceGroupProps) {
  const {t} = useTranslation();
  const history = useHistory();

  const {priceGroupId} = useParams<{priceGroupId: string}>();

  const handleInput = useForm<PriceGroupInputNew>({
    mode: 'onChange',
  });

  const submit = async(input: PriceGroupInputNew) => {
    const pga: PriceGroupRequestArticles[] =
      input.row !== undefined && input.row !== null
        ? input.row.map((article) => {
            return {
              internalArticleId: article.internalArticleId,
              percentageDiscount: article.percentage,
              rowSelect: article.rowSelect,
              fortnoxProductId: article.fortnoxProductId,
              productName: article.productName
            };
          })
        : [];

    const ob = {
      name: input.name,
      priceGroupArticles: pga,
    } as PriceGroupRequest;

    await PriceGroupService.updatePriceGroup(
      priceGroupId,
      ob
    )
    .then(e => history.push('/e-shop/manage/price-groups/'))
    .catch(e => {
      let er = errorResponse(e)
      toggleToast({
        message: er.message,
        shouldToggle: true,
        status: er.status
      })
    })
  };

  
  const {data: articlesData, loading: articlesDataLoader} = articles;
  const {data: priceGroupData, loading: priceGroupLoader} = priceGroup;

  const isNotLoading = !articlesDataLoader && !priceGroupLoader

  useEffect(() => {
    async function fetch() {
      await getPriceGroup(priceGroupId);
    }
    fetch();
  }, [getPriceGroup]);

  useEffect(() => {
    async function fetch() {
      await getProductsWithoutFilters();
    }
    fetch()
  }, [getProductsWithoutFilters]);

  useEffect(() => {
    setShowLoader(!isNotLoading);
  }, [isNotLoading, setShowLoader]);

  return (
    <div>
      <h1 data-testid="edit_group_headline">{t('common.Edit price group')}</h1>
       {!articlesDataLoader && !priceGroupLoader ? 
        <>
      <form onSubmit={handleInput.handleSubmit(submit)}>
        <PriceGroupForm 
          handleInput={handleInput}
          articles={articlesData}
          priceGroup={priceGroupData}  
        />
      </form>
      </>
      : <></>}
    </div>
  );
}

export default connector(EditPriceGroup);
