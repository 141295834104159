import { FormContextValues } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Order, OrderInputValues } from "../../store/orders/types"
import { Entity, EntityData } from "../../store/types"
import InputField from "../Form/InputField"
import SelectField from "../Form/SelectField"
import { Countries } from "../Form/types"


export interface DeliveryDetailsCardProps {
    handleInput: FormContextValues<OrderInputValues>
    userAdminType: boolean
    data: EntityData<Order>
    order: Entity<Order>
    CountryOptions: Countries[]
    readOnly: boolean
}

const DeliveryDetailsCard: React.FC<DeliveryDetailsCardProps> = ({
    handleInput,
    userAdminType,
    CountryOptions,
    readOnly
}) => {
    const { t } = useTranslation()

    return (
        <>
            <div className="col-md-12 col-sm-12">
                <div className="row">
                    <div className="col-12 mb-2" hidden={!readOnly && !userAdminType}>
                        <div className="card">
                            <div className='card-header' data-toggle="collapse" data-target="#deliverydetails">
                                {t('order.Delivery details')}
                                <i className="float-right arrow down"></i>
                                <i className="float-right arrow up"></i>
                            </div>
                            <div className="collapse show" id="deliverydetails">
                                <div className='card-body'>
                                    <div className='row'>
                                        <div className="col-md-6 col-lg-6">
                                            <InputField
                                                name="deliveryCustomerName"
                                                handler={handleInput}
                                                label={t('order.Company')}
                                                type="text"
                                                readOnly={readOnly}
                                            />
                                        </div>
                                        <div className="col-md-6 col-lg-6">
                                            <InputField
                                                name="deliveryPhone"
                                                handler={handleInput}
                                                label={t('order.Phonenumber')}
                                                type="text"
                                                readOnly={readOnly}
                                            />
                                        </div>
                                        <div className="col-lg-12">
                                            <div className='row'>
                                                <div className="col-md-6 col-lg-6">
                                                    <InputField
                                                        name="deliveryAddress"
                                                        handler={handleInput}
                                                        label={t('common.Address')}
                                                        type="text"
                                                        readOnly={readOnly}
                                                    />
                                                </div>
                                                <div className="col-md-6 col-lg-6">
                                                    <InputField
                                                        name="deliveryAddress2"
                                                        handler={handleInput}
                                                        label={t('common.Address') + " 2"}
                                                        type="text"
                                                        readOnly={readOnly}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-lg-6">
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <InputField
                                                        name="deliveryPostalNo"
                                                        handler={handleInput}
                                                        label={t('common.Zip')}
                                                        type="text"
                                                        readOnly={readOnly}
                                                    />
                                                </div>
                                                <div className='col-6'>
                                                    <InputField
                                                        name="deliveryCity"
                                                        handler={handleInput}
                                                        label={t("common.City")}
                                                        type="text"
                                                        readOnly={readOnly}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-lg-6">
                                            <SelectField
                                                name={"deliveryCountryCode"}
                                                handler={handleInput}
                                                placeholder={t('common.Select')}
                                                label={"Land"}
                                                options={CountryOptions}
                                                style={{ minWidth: 150 }}
                                                readOnly={readOnly}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DeliveryDetailsCard