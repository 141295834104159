import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from 'react';
import { Controller, FieldValues, FormContextValues } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface OfferToolFormExtraCostProps{
  handleSetOtherCost: (params: {
    otherCost1?: number,
    otherCost2?: number,
    otherCost3?: number,
  }) => void
  handler: FormContextValues<FieldValues>
  readOnly: boolean
  values: {
    otherCost1: number,
    otherCost2: number,
    otherCost3: number,
  }
  addOtherCost1: boolean,
  setAddOtherCost1: (f: boolean) => void,
  addOtherCost2: boolean,
  setAddOtherCost2: (f: boolean) => void,
  addOtherCost3: boolean,
  setAddOtherCost3: (f: boolean) => void,
}

function OfferToolFormExtraCost({
    handler,
    readOnly,
    values,
    handleSetOtherCost,
    addOtherCost1,
    setAddOtherCost1,
    addOtherCost2,
    setAddOtherCost2,
    addOtherCost3,
    setAddOtherCost3,
}: OfferToolFormExtraCostProps){

  const { t } = useTranslation()
  const [addOtherCost1Valid, setAddOtherCost1Valid] = useState<boolean>(false)
  const [addOtherCost2Valid, setAddOtherCost2Valid] = useState<boolean>(false)
  const [addOtherCost3Valid, setAddOtherCost3Valid] = useState<boolean>(false)

  const changeOtherCostValue = (otherCostToChange: string) => {
    if(otherCostToChange === "OtherCost1"){
      setAddOtherCost1(false)
      values.otherCost1 = 0
      handler.setValue("otherCostText1", "")
      handleSetOtherCost({...values, otherCost1: 0})
      values.otherCost2 = 0
      handler.setValue("otherCostText2", "")
    }

    if(otherCostToChange === "OtherCost2"){
      setAddOtherCost2(false)
      values.otherCost2 = 0
      handler.setValue("otherCostText2", "")
      handleSetOtherCost({...values, otherCost2: 0})
      values.otherCost3 = 0
      handler.setValue("otherCostText3", "")
    }

    if(otherCostToChange === "OtherCost3"){
      setAddOtherCost3(false)
      values.otherCost3 = 0
      handler.setValue("otherCostText3", "")
      handleSetOtherCost({...values, otherCost3: 0})
    }
  }

  useEffect(() => {

    let text1 = handler.getValues("otherText");
    let text2 = handler.getValues("otherText2");
    let text3 = handler.getValues("otherText3");

    if(values.otherCost1 > 0 || (text1 != null && text1.length > 0)){
      setAddOtherCost1(true)
    }

    if(values.otherCost2 > 0 || (text2 != null && text2.length > 0)){
      setAddOtherCost2(true)
    }

    if(values.otherCost3 > 0 || (text3 != null && text3.length > 0)){
      setAddOtherCost3(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[values])

  const watchOtherCostText1 = handler.watch("otherCostText1")
  const watchOtherCostText2 = handler.watch("otherCostText2")
  const watchOtherCostText3 = handler.watch("otherCostText3")

  useEffect(() => {
    if( addOtherCost1 && watchOtherCostText1 !== "")
      setAddOtherCost1Valid(true)

    if(addOtherCost1 && (watchOtherCostText1 === null || watchOtherCostText1 === ""))
      setAddOtherCost1Valid(false)

    if( addOtherCost2 && watchOtherCostText2 !== "")
      setAddOtherCost2Valid(true)

    if(addOtherCost2 && (watchOtherCostText2 === null || watchOtherCostText2 === ""))
      setAddOtherCost2Valid(false)

    if( addOtherCost3 && watchOtherCostText3 !== "")
      setAddOtherCost3Valid(true)

    if(addOtherCost3 && (watchOtherCostText3 === null || watchOtherCostText3 === ""))
      setAddOtherCost3Valid(false)

  },[addOtherCost1, addOtherCost2, addOtherCost3, watchOtherCostText1, watchOtherCostText2, watchOtherCostText3])

  useEffect(()=>{
    if(!addOtherCost1){
      let text = handler.getValues("otherText");
      setAddOtherCost1(values.otherCost1 > 0 || (text != null && text.length > 0))
    }

    if(!addOtherCost2){
      let text = handler.getValues("otherText2");
      setAddOtherCost2(values.otherCost2 > 0 || (text != null && text.length > 0))
    }

    if(!addOtherCost3) {
      let text = handler.getValues("otherText3");
      setAddOtherCost3(values.otherCost3 > 0 || (text != null && text.length > 0));
    }
      
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[addOtherCost1, addOtherCost2, addOtherCost3, values.otherCost1, values.otherCost2, values.otherCost3])

  return (
    <>
      <tr hidden={!addOtherCost1}>
        <td>
          Övrigt
        </td>
        <td>
          <Controller
            name={'otherText'}
            control={handler.control}
            as={
              <TextField
                fullWidth
                InputLabelProps={{shrink: true}}
                InputProps={{style: {fontSize: '0.75rem'}, readOnly: readOnly}}
                variant='standard'
                placeholder={t('offer.labelText.Other text')}
                id='standard-basic'
                helperText={!addOtherCost1Valid ? '*' + t('validation.required') : ''}
                error={!addOtherCost1Valid}
              />
            }
          />
        </td>
        <td></td>
        <td></td>
        <td>
          <TextField
            name='otherCost1'
            InputProps={{style: {fontSize: '0.75rem'}, readOnly: readOnly}}
            variant='standard'
            value={values.otherCost1 !== undefined && values.otherCost1 >= 0 ? values.otherCost1 : 0}
            onChange={(e) => handleSetOtherCost({
              ...values,
              otherCost1: parseInt(e.target.value) >= 0 ? parseInt(e.target.value) : 0,
            })}
          />
        </td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        {!readOnly &&
          <td>
            <IconButton
              aria-label='remove'
              title='remove product'
              size='small'
              onClick={() => changeOtherCostValue('OtherCost1')}
              hidden={addOtherCost2}
            >
              <Close fontSize='small' />
            </IconButton>
          </td>
        }
      </tr>

      <tr hidden={!addOtherCost2}>
        <td>
          Övrigt
        </td>
        <td>
          <Controller
            name={'otherText2'}
            control={handler.control}
            as={
              <TextField
                fullWidth
                InputLabelProps={{shrink: true}}
                InputProps={{style: {fontSize: '0.75rem'}, readOnly: readOnly}}
                variant='standard'
                placeholder={t('offer.labelText.Other text')}
                id='standard-basic'
                helperText={!addOtherCost2Valid ? '*' + t('validation.required') : ''}
                error={!addOtherCost2Valid}
              />
            }
          />
        </td>
        <td></td>
        <td></td>
        <td>
          <TextField
            name='otherCost2'
            InputProps={{style: {fontSize: '0.75rem'}, readOnly: readOnly}}
            variant='standard'
            value={values.otherCost2 !== undefined && values.otherCost2 >= 0 ? values.otherCost2 : 0}
            onChange={(e) => handleSetOtherCost({
              ...values,
              otherCost2: parseInt(e.target.value) >= 0 ? parseInt(e.target.value) : 0,
            })}
          />
        </td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        {!readOnly &&
          <td>
            <IconButton
              aria-label='remove'
              title='remove product'
              size='small'
              onClick={() => changeOtherCostValue('OtherCost2')}
              hidden={addOtherCost3}
            >
              <Close fontSize='small' />
            </IconButton>
          </td>
        }
      </tr>

      <tr hidden={!addOtherCost3}>
        <td>
          Övrigt
        </td>
        <td>
          <Controller
            name={'otherText3'}
            control={handler.control}
            as={
              <TextField
                fullWidth
                InputLabelProps={{shrink: true}}
                InputProps={{style: {fontSize: '0.75rem'}, readOnly: readOnly}}
                variant='standard'
                placeholder={t('offer.labelText.Other text')}
                id='standard-basic'
                helperText={!addOtherCost3Valid ? '*' + t('validation.required') : ''}
                error={!addOtherCost3Valid}
              />
            }
          />
        </td>
        <td></td>
        <td></td>
        <td>
          <TextField
            name='otherCost3'
            InputProps={{style: {fontSize: '0.75rem'}, readOnly: readOnly}}
            variant='standard'
            value={values.otherCost3 !== undefined && values.otherCost3 >= 0 ? values.otherCost3 : 0}
            onChange={(e) => handleSetOtherCost({
              ...values,
              otherCost3: parseInt(e.target.value) >= 0 ? parseInt(e.target.value) : 0,
            })}
          />
        </td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        {!readOnly &&
          <td>
            <IconButton
              aria-label='remove'
              title='remove product'
              size='small'
              onClick={() => changeOtherCostValue('OtherCost3')}
            >
              <Close fontSize='small' />
            </IconButton>
          </td>
        }
      </tr>
    </>
  );
}


export default OfferToolFormExtraCost