import React from 'react'
import EShopCheckout from '../../components/EShop/Checkout/EShopCheckout'
import EShopCartProvider from '../../components/EShop/Providers/EShopCartProvider'
import EShopCategoryProvider from '../../components/EShop/Providers/EShopCategoryProvider'

const Checkout = () => 
	<EShopCategoryProvider>
		<EShopCartProvider>
			<EShopCheckout />
		</EShopCartProvider>
	</EShopCategoryProvider>

export default Checkout
