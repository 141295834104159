import React, { createContext, useCallback, useEffect, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AppState } from '../../../store';
import { getCustomer } from '../../../store/companies';
import { Customer } from '../../../store/companies/types';
import { toggleToast } from '../../../store/components';
import { getProduct, getProductFile, createMonitorProduct } from '../../../store/products'
import { MonitorProduct, ProductInformationDocument } from '../../../store/products/types';
import { User } from '../../../store/users/types';

const connector = connect(
	(state: AppState) => ({
	  product: state.products.selected,
	  activeUser: state.system.sessionUser,
	  company: state.companies.selected,
	}),
	{ getProductFile, getProduct, createMonitorProduct, toggleToast, getCustomer}
)
  
type EShopProductProviderProps = ConnectedProps<typeof connector> & {
	children: React.ReactNode
}

interface ContextEShopProductProvider {
	productInformation?: Partial<FullScreenProductInformation>
	activeUser?: User
	company?: Customer
	getProductFile?: ({ fileId, customerId }: { fileId: string, customerId: string }) => void
	setMonitorProduct?: (monitorProduct: MonitorProduct) => void
}

export const contextEShopProduct = createContext<ContextEShopProductProvider>({})

const EShopProductProvider = ({
	product,
	activeUser,
	getProduct,
	getProductFile,
	children,
	createMonitorProduct,
	toggleToast,
	getCustomer,
	company
}: EShopProductProviderProps) => {
	const modal = !window.location.href.includes('e-shop')
	const params = useParams<{
        categoryFirstId: string,
        categorySecondId: string,
        categoryThirdId: string,
        articleId: string
    }>()

	useEffect(() => {
		if(!!activeUser?.userPermission?.belongsToCustomer){
			getCustomer(activeUser?.userPermission?.belongsToCustomer).catch((err) =>
				console.error('getCompany error', err)
			);
		}
    }, [activeUser?.userPermission?.belongsToCustomer]);

	useEffect(() => {
		if(!(params.articleId !== "0" && !!params.articleId) || product.loading) return
		getProduct(params.articleId)
	}, [params.articleId])

	const [ information, setInformation ] = useState<FullScreenProductInformation | {} | undefined>(undefined)

	useEffect(() => {
		if((information && Object.keys(information).length) || !params.articleId) {
			setInformation(undefined)
		} else if(information && !Object.keys(information).length && product.data) {
			const { product: { id, productInformation, fnArticleId, soldOut, maxOrderQuantity, disposableQuantity } } = product.data
			setInformation({
				id,
				image: productInformation.images?.length > 0 ? productInformation.images[0].publicLink : "",
				title: productInformation.name,
				manufacturer: productInformation.manufacturer,
				description: productInformation.description,
				price: productInformation.articlePrice.displayPrice,
				priceWithoutDiscount: productInformation.articlePrice.priceWithoutDiscount,
				currency: productInformation.articlePrice.currency,
				priceGroupDiscount: productInformation.articlePrice.priceGroupDiscountPercentage,
				noneStockProduct: productInformation.noneStockProduct,
				stockAmount: disposableQuantity,
				inDeliveryOfStock: productInformation.inDeliveryOfMoreQuantity,
				documents: productInformation.documents,
				articleNumber: fnArticleId,
				soldOut: soldOut,
				maxOrderQuantity: maxOrderQuantity
			})
		} else if(product.loading && !information) {
			setInformation({})
		}
	}, [product, params.articleId])

	const setMonitorProduct = (monitorProduct : MonitorProduct) => {
		createMonitorProduct({
			monitorProduct: monitorProduct, 
			callback: ((m) => {
				toggleToast({
					shouldToggle: true,
					message: m.message,
					status: m.status,
				})
		})}).then((m) => {
			let res = m?.payload as MonitorProduct
			if(res.created){
				let msg = res.created === res.updated ? "Bevakning skapad" : "Bevakning uppdaterad"
				toggleToast({
					shouldToggle: true,
					message: msg,
					status: 200,
				})
			}
		})
	}

	return (
		<contextEShopProduct.Provider value={{
			productInformation: information,
			getProductFile,
			activeUser: activeUser ?? undefined,
			setMonitorProduct,
			company: company?.data ?? undefined
		}}>
			{children}
		</contextEShopProduct.Provider>
	)
}

interface FullScreenProductInformation {
	id: string
	image: string
	title: string
	manufacturer: string
	description: string
	price: number
	priceWithoutDiscount: number
	currency: string
	priceGroupDiscount: number
	noneStockProduct: boolean
	stockAmount: number
	inDeliveryOfStock: any
	documents: ProductInformationDocument[]
	articleNumber: string
	soldOut: boolean
	maxOrderQuantity?: number
}

export default connector(EShopProductProvider)
