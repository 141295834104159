import React, {useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {Helmet} from 'react-helmet';

import './Auth.scss';

import {AppState} from '../../store';
import {setShowLoader} from '../../store/system';

import Loader from '../../components/Loader';

const connector = connect(
  (state: AppState) => ({
    showLoader: state.system.showLoader,
  }),
  {
    setShowLoader,
  }
);

type LoginProps = ConnectedProps<typeof connector> & {
  children?: React.ReactNode;
};

function AuthWrapper({children, showLoader, setShowLoader}: LoginProps) {
  useEffect(() => {
    return () => {
      setShowLoader(false);
    };
  }, [setShowLoader]);

  return (
    <>
      <Helmet>
        <body id="auth-body" />
      </Helmet>
      <div className="auth-block">{children}</div>
      {showLoader && <Loader absolute />}
    </>
  );
}

export default connector(AuthWrapper);
