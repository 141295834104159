import React, {useEffect, useState, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';
import {MergedArticleProduct, Product, ProductCustomer, ProductReserved} from '../../store/products/types';
import {useCroppie} from '../../lib/hooks';
import MultipleFileInput from '../../components/Form/MultipleFileInput';
import InputField from '../../components/Form/InputField';
import SelectField, {Option} from '../../components/Form/SelectField';
import TextAreaField from '../../components/Form/TextAreaField';
import ImageCrop from '../../components/ImageCrop';
import {ProductCategory} from '../../store/productCategories/types';
import DatePicker from '../Form/DatePicker';
import DropdownButton from '../DropdownButton';
import ConfirmationModal from '../Modal/ConfirmationModal';
import {useHistory} from 'react-router-dom';
import {PATH_E_SHOP_MANAGE_PRODUCTS} from '../../router/paths';
import {InlineCheckbox} from '../Form/InlineCheckInput';
import getImageUrlBase64AsFile from '../../lib/ImageHelper';
import {mdiPlus} from '@mdi/js';
import {Close} from '@material-ui/icons';
import {Chip} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton/IconButton';
import {Icon} from '@mdi/react';
import classnames from 'classnames';
import {useUpdateEffect} from "../../hooks/useUpdateEffect";

export type ProductFormInput = {
  name: string;
  description: string;
  manufacturer: string;
  category: Option;
  categorySecond: Option;
  categoryThird: Option;
  priceGroup: string;
  image: string;
  fnArticleId: string;
  inDeliveryOfMoreQuantity: string;
  noneStockProduct: boolean;
  numberInEachPackage: number;
  customerNumbers?: string[];
  soldOut: boolean;
  maxOrderQuantity?: number;
  associatedWithEmobilityPartner: string;
};
type Attachment = {
  id: string;
  fileList: FileList;
};
type ProductFormProps = {
  data?: MergedArticleProduct | null;
  isEdit?: boolean;
  categoriesData?: ProductCategory[] | null;
  setModalVisible: (f: boolean) => void;
  onSubmit: (input: ProductFormInput, data?: Blob, productCustomers?: ProductCustomer[], productReserved?: ProductReserved[]) => Promise<any>;
  setShowLoader: (set: boolean) => void;
  fortNoxArticleId?: string;
  setAttachments: (fileList: FileList, id: string) => void;
  deleteAttachments: (id: string) => void;
  removeDocument?: (fileId: string) => any;
  attached?: Attachment[];
};

const extraMargin: React.CSSProperties = {marginTop: '30px'};

const formColumnClass = 'col-md-12 col-lg-6';

const cropAreaSize = {height: 300, width: 300};

export default function ProductForm({
  data,
  isEdit,
  categoriesData,
  onSubmit,
  setModalVisible,
  setShowLoader,
  setAttachments,
  deleteAttachments,
  removeDocument,
  fortNoxArticleId,
  attached,
}: ProductFormProps) {
  const {t} = useTranslation();
  const history = useHistory();
  // const imageToBase64 = require('image-to-base64');

  const [imageSelected, setImageSelected] = useState<boolean>(false);
  const [itemToDelete, setItemToDelete] = useState<{id: string; name: string}>({
    id: '',
    name: '',
  });
  const [orderItem, setOrderItem] = useState('');

  const onCheckboxChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      orderItem === 'order' ? setOrderItem('') : setOrderItem('order');
    },
    [orderItem]
  );
  const [confirmationModalVisible, setConfirmationModalVisible] =
    useState(false);

  const {croppie, sourcePicture, sourcePictureField, setSourcePicture} =
    useCroppie();

  const [categoryOptions, setCategoryOptions] = useState<Option[]>([]);
  const [secondCategoryOptions, setSecondaryCategoryOptions] = useState<
    Option[]
  >([]);
  const [thirdCategoryOptions, setThirdCategoryOptions] = useState<Option[]>(
    []
  );

  const handleInput = useForm<any>({
    mode: 'onChange',
  });

  const category: Option | undefined = handleInput.watch('category');
  const categorySecond: Option | undefined =
    handleInput.watch('categorySecond');
  const categoryThird: Option | undefined = handleInput.watch('categoryThird');

  const showConfirmModal = (item: {id: string; name: string}) => {
    setConfirmationModalVisible(true);
    setItemToDelete(item);
  };

  const onConfirmation = () => {
    if (itemToDelete && removeDocument) {
      removeDocument(itemToDelete.id);
      setConfirmationModalVisible(false);
    }
  };

  const {
    formState: {isSubmitting, isSubmitted},
    reset,
    setValue,
  } = handleInput;

  useEffect(() => {
    setShowLoader(isSubmitting);
  }, [isSubmitting, setShowLoader]);

  useUpdateEffect(() => {
    if (!data) {
      return;
    }

    const {
      name,
      categoryId,
      categorySecondId,
      categoryThirdId,
      manufacturer,
      description,
      inDeliveryOfMoreQuantity,
      noneStockProduct,
      numberInEachPackage,
    } = data.product.productInformation;

    const category = categoriesData?.find((c) => c.id === categoryId);
    const categorySecond = category?.categorySeconds.find(
      (c) => c.id === categorySecondId
    );
    const categoryThird = categorySecond?.categoryThirds.find(
      (c) => c.id === categoryThirdId
    );

    reset({
      fnArticleId: data.product.fnArticleId,
      name: name,
      manufacturer: manufacturer,
      inDeliveryOfMoreQuantity: inDeliveryOfMoreQuantity
        ? new Date(inDeliveryOfMoreQuantity)
        : undefined,
      category: category
        ? {value: category.id, label: category.categoryName}
        : null,
      categorySecond: categorySecond
        ? {value: categorySecond.id, label: categorySecond.categoryName}
        : null,
      categoryThird: categoryThird
        ? {value: categoryThird.id, label: categoryThird.categoryName}
        : null,
      noneStockProduct: noneStockProduct,
      description: description,
      numberInEachPackage,
      soldOut: data.product.soldOut,
	    maxOrderQuantity: data.product.maxOrderQuantity,
      associatedWithEmobilityPartner: data.associatedWithEmobilityPartner
    });

    if (data.product.productInformation.images.length > 0) {
      getImageUrlBase64AsFile(
        data.product.productInformation.images[0].publicLink,
        data.product.productInformation.images[0].originalName
      ).then((e) => setSourcePicture(e));
    }

    let productReservedArray = [] as ProductReserved[];

    if(data?.product.productReserved !== undefined){
      data?.product.productReserved?.forEach((item) => {
        productReservedArray.push({productId: data?.product.id, fnCustomerId: item.customer?.fortnoxCustomerId, quantity: item.quantity})
      })
    }
    
    let productCustomersArray = [] as ProductCustomer[];
    
    if(data?.product.productCustomers !== undefined){
      data?.product.productCustomers?.forEach((item) => {
        productCustomersArray.push({productId: data?.product.id, fnCustomerId: item?.fortnoxCustomerId})
      })
    }
    setProductReservedList(productReservedArray)
    setProductCustomers(productCustomersArray);
  }, [data, categoriesData, reset, setSourcePicture]);

  const categoryValue = category?.value.trim();
  const categorySecondValue = categorySecond?.value.trim();
  const categoryThirdValue = categoryThird?.value.trim();

  useEffect(() => {
    const categoryOptions = categoriesData?.map((c) => ({
      label: c.categoryName,
      value: c.id,
    }));

    categoryOptions && setCategoryOptions(categoryOptions);

    if (categoryValue) {
      const categorySecondOptions = categoriesData
        ?.find((c) => c.id === categoryValue)
        ?.categorySeconds.map((c) => ({
          label: c.categoryName,
          value: c.id,
        }));

      setSecondaryCategoryOptions(categorySecondOptions ?? []);
      if (
        !categorySecondOptions?.some((c) => c.value === categorySecondValue)
      ) {
        setValue('categorySecond', null);
        setValue('categoryThird', null);
        setThirdCategoryOptions([]);

        return;
      }
    }

    if (categorySecondValue) {
      const categoryThirdOptions = categoriesData
        ?.find((c) => c.id === categoryValue)
        ?.categorySeconds?.find((c) => c.id === categorySecondValue)
        ?.categoryThirds.map((c) => ({
          label: c.categoryName,
          value: c.id,
        }));

      setThirdCategoryOptions(categoryThirdOptions ?? []);
      if (!categoryThirdOptions?.some((c) => c.value === categoryThirdValue)) {
        setValue('categoryThird', null);
      }
    }
  }, [
    categoriesData,
    categoryValue,
    categorySecondValue,
    categoryThirdValue,
    setValue,
    setCategoryOptions,
    setSecondaryCategoryOptions,
    setThirdCategoryOptions,
  ]);

  const setCroppieInstance = (inst: Croppie) => {
    croppie.current = inst;
  };

  const openFilePicker = () => {
    sourcePictureField.current?.click();
  };

  const onCancel = () => {
    history.push(PATH_E_SHOP_MANAGE_PRODUCTS);
  };

  const prepareSubmit = async (input: any) => {
    if (!croppie.current || !sourcePicture) {
      setImageSelected(true);
      try {
        await onSubmit(input, undefined, productCustomers, productReservedList);
        reset();
        setSourcePicture(null);
      } catch (e) {
        console.error(e);
      }
    } else {
      setImageSelected(true);
      try {
        const data = await croppie.current.result({
          size: 'original',
          type: 'blob',
          format: 'jpeg',
          quality: 0.9,
        });
        await onSubmit(input, data, productCustomers, productReservedList);
        reset();
        setSourcePicture(null);
      } catch (e) {
        console.error(e);
      }
    }
  };

  const resetImage = () => {
    setSourcePicture(null);
    reset();
  };

  const [productCustomers, setProductCustomers] = useState<ProductCustomer[]>([]);

  const [productCustomer, setProductCustomer] = useState<ProductCustomer>({});

  const addCustomerList = () => {
    if (productCustomer === undefined) return;

    if (productCustomers === null || productCustomers.length === 0) {    
      setProductCustomers([productCustomer]);
    } else if (productCustomers.length > 0) {
      let tempCustomerNumbers = productCustomers;
      tempCustomerNumbers.push(productCustomer);
      setProductCustomers(tempCustomerNumbers);
    }
  
    setProductCustomer({productId: "", fnCustomerId: ""});
  };

  const deleteCustomer = (value: ProductCustomer) => {
    let oldCustomerNumbers = productCustomers.slice();
    var index = oldCustomerNumbers.indexOf(value);
    if (index !== -1) {
      oldCustomerNumbers.splice(index, 1);
    }
    setProductCustomers(oldCustomerNumbers);
  };

  const [productReservedList, setProductReservedList] = useState<ProductReserved[]>([]);
  const [productReserved, setProductReserved] = useState<ProductReserved>({});

  const addReservedToCustomerList = () => {
    if (productReserved === undefined) return;

    if (productReservedList === null || productReservedList.length === 0) {
      setProductReservedList([productReserved]);
    } else if (productReservedList.length > 0) {
      let tempReservedToCustomers = productReservedList;
      tempReservedToCustomers.push(productReserved);
      setProductReservedList(tempReservedToCustomers);
    }
    
    setProductReserved({productId: "", fnCustomerId: "", quantity: ""});
  };

  const deleteReservedToCustomer = (value: ProductReserved) => {
    let oldArray = productReservedList.slice();
    var index = oldArray.indexOf(value);
    if (index !== -1) {
      oldArray.splice(index, 1);
    }
    setProductReservedList(oldArray);
  };

  return (
    <>
      <form onSubmit={handleInput.handleSubmit(prepareSubmit)}>
        <div className="form-row">
          {isEdit ? (
            <>
              <InputField
                name="fnArticleId"
                label={t('common.Article number')}
                className={formColumnClass}
                associated={true}
                associateValue={fortNoxArticleId}
                readOnly={true}
              />
            </>
          ) : (
            <>
              <InputField
                name="fnArticleId"
                handler={handleInput}
                label={t('common.Article number')}
                className={formColumnClass}
                associated={true}
                associateHandler={() => {
                  setModalVisible(true);
                }}
                associateText={t('common.Change article number')}
                associateValue={fortNoxArticleId}
                rules={{required: !fortNoxArticleId}}
              />
            </>
          )}

          <InputField
            name="name"
            handler={handleInput}
            label={t('common.Product name')}
            className={formColumnClass}
            rules={{required: true}}
          />

          <InputField
            name="manufacturer"
            handler={handleInput}
            label={t('common.Manufacturer')}
            className={formColumnClass}
          />

          <SelectField
            name="category"
            handler={handleInput}
            label={t('common.Category')}
            placeholder={t('common.Category')}
            className="col-md-4 col-lg-2"
            options={categoryOptions}
            rules={{required: true}}
          />
          <SelectField
            name="categorySecond"
            handler={handleInput}
            label=" "
            placeholder={t('common.Secondary category')}
            className="col-md-4 col-lg-2"
            disabled={!secondCategoryOptions.length && !category}
            options={secondCategoryOptions}
          />
          <SelectField
            name="categoryThird"
            handler={handleInput}
            label=" "
            placeholder={t('common.Product')}
            className="col-md-4 col-lg-2"
            disabled={!thirdCategoryOptions.length || !categorySecond}
            options={thirdCategoryOptions}
          />

            <div className="col-md-12 col-lg-6">
              <div className="col-12">
                <label>{t('common.Customer number')}</label>
              </div>
              <div className="col-md-6 col-lg-6">
                <div className="input-group mb-3">
                  <input
                    className="form-control"
                    name="customerNumbers"
                    value={productCustomer?.fnCustomerId}
                    placeholder={t('common.Customer numb')}
                    onChange={(c) => setProductCustomer({productId: data?.product.id, fnCustomerId: c.target.value})}
                  />
                  <button
                    className="btn btn-primary btn-sm"
                    type="button"
                    id="button-addon2"
                    style={{
                      borderRadius: '0rem 0.625rem 0.625rem 0rem',
                      width: '3rem',
                    }}
                    onClick={() => addCustomerList()}
                  >
                    <Icon path={mdiPlus} size="18" className="icon-12" />
                  </button>
                </div>
              </div>
              <div className="col-md-6 col-lg-6">
                {!!productCustomers &&
                  productCustomers.map((value, index) => (
                    <Chip
                      className="m-1 pr-3 selected-inverters-chip"
                      label={value.fnCustomerId}
                      key={index}
                      variant="outlined"
                      onDelete={() => deleteCustomer(value)}
                      deleteIcon={
                        <IconButton
                          className="p-3"
                          edge="end"
                          aria-label="delete"
                          size="small"
                        >
                          <Close fontSize="small" />
                        </IconButton>
                      }
                    />
                  ))}
              </div>
            </div>


            <div className="col-md-12 col-lg-6">
              <div className="col-12">
                <label>{t('Eshop.Reserv product to customer number')}</label>
              </div>
              <div className="col-md-12 col-lg-12">
                <div className="input-group mb-3">
                  <input
                    className="form-control"
                    name="reservedToCustomers"
                    value={productReserved?.fnCustomerId}
                    placeholder={t('common.Customer numb')}
                    onChange={(c) => setProductReserved( {productId: data?.product.id, fnCustomerId: c.target.value, quantity: productReserved?.quantity})}
                  />
                   <input
                    className="form-control"
                    name="reservedToCustomers"
                    value={productReserved?.quantity}
                    placeholder={t('Eshop.Reserved quantity')}
                    onChange={(c) => setProductReserved( {productId: data?.product.id, fnCustomerId: productReserved?.fnCustomerId, quantity: c.target.value})}
                  />
                  <button
                    className="btn btn-primary btn-sm"
                    type="button"
                    id="button-addon2"
                    style={{
                      borderRadius: '0rem 0.625rem 0.625rem 0rem',
                      width: '3rem',
                    }}
                    onClick={() => addReservedToCustomerList()}
                  >
                    <Icon path={mdiPlus} size="18" className="icon-12" />
                  </button>
                </div>
             
                <div className="col-md-12 col-lg-12">
                  {!!productReservedList &&
                    productReservedList.map((value, index) => (
                      <Chip
                        className="m-1 pr-3 selected-inverters-chip"
                        label={t('common.Customer numb') + ": " + value.fnCustomerId + " (" + value.quantity + "st)"}
                        key={index}
                        variant="outlined"
                        onDelete={() => deleteReservedToCustomer(value)}
                        deleteIcon={
                          <IconButton
                            className="p-3"
                            edge="end"
                            aria-label="delete"
                            size="small"
                          >
                            <Close fontSize="small" />
                          </IconButton>
                        }
                      />
                    ))}
                </div>
              </div>
            </div>

          <div className="col-md-12 col-lg-6">
            <label htmlFor="">{t('common.Documents and files')}</label>
            {data &&
              isEdit &&
              [
                ...data.product.productInformation.documents,
                ...(attached?.reduce<{originalName: string; id: string}[]>(
                  (p, c) => [
                    ...p,
                    {originalName: c.fileList[0].name, id: c.id},
                  ],
                  []
                ) ?? []),
              ].map((value) => {
                return (
                  <div key={value.id} className="input-group">
                    <label className="file border px-2 py-3 d-flex col-12 justify-content-between">
                      <span className="font-weight-bold">
                        {value.originalName}
                      </span>
                      <DropdownButton
                        id={`file__option__dropdown_${value.id}`}
                        label={t('common.Options')}
                        dropdownList={[
                          {
                            text: t('common.Delete'),
                            className: 'text-danger',
                            onClick: () => {
                              showConfirmModal({
                                id: value.id,
                                name: value.originalName,
                              });
                            },
                          },
                        ]}
                      />
                    </label>
                  </div>
                );
              })}

            {attached &&
              !isEdit &&
              [
                ...(attached?.reduce<{originalName: string; id: string}[]>(
                  (p, c) => [
                    ...p,
                    {originalName: c.fileList[0].name, id: c.id},
                  ],
                  []
                ) ?? []),
              ].map((value) => {
                return (
                  <div key={value.id} className="input-group">
                    <label className="file border px-2 py-3 d-flex col-12 justify-content-between">
                      <span className="font-weight-bold">
                        {value.originalName}
                      </span>
                      <DropdownButton
                        id={`file__option__dropdown_${value.id}`}
                        label={t('common.Options')}
                        dropdownList={[
                          {
                            text: t('common.Delete'),
                            className: 'text-danger',
                            onClick: () => {
                              showConfirmModal({
                                id: value.id,
                                name: value.originalName,
                              });
                            },
                          },
                        ]}
                      />
                    </label>
                  </div>
                );
              })}

            <MultipleFileInput
              label={''}
              files={{}}
              setAttachments={setAttachments}
              deleteAttachments={deleteAttachments}
              placeholder={t('common.Upload new document')}
            />
          </div>

          {isEdit ? (
            <div className="col-md-12 col-lg-6">
              <DatePicker
                name="inDeliveryOfMoreQuantity"
                label={t('common.Indelivery date')}
                placeholder={t('common.Delivery date')}
                handler={handleInput}
              />
            </div>
          ) : (
            <div className="d-md-none d-lg-block col-lg-6"></div>
          )}
          <div className="col-sm-12 col-lg-6">
            <div className="row">
              {/* REMOVED: due to task 2799, keep if it needs to be reinserted. */}
              {/*<div className="col-md-6 col-lg-6">
                <InlineCheckbox
                  name="soldOut"
                  options={[
                    {
                      value: '1',
                      label: t('Eshop.Sold out'),
                    },
                  ]}
                  handler={handleInput}
                />
              </div>*/}
              <div className="col-md-6 col-lg-6">
                <InlineCheckbox
                  name="noneStockProduct"
                  options={[{value: 'order', label: t('common.Order item')}]}
                  handler={handleInput}
                />
              </div>
            </div>
          </div>
              <div className="col-md-6 col-lg-6">
                <InlineCheckbox
                  name="associatedWithEmobilityPartner"
                  options={[
                    {
                      value: '1',
                      label: t('common.Included in E-Mobility\'s assortment'),
                    },
                  ]}
                  handler={handleInput}
                  value={orderItem}
                />
              </div>
          <div className="col-sm-12 col-lg-6">
            <div className="row">
              <div className="col-md-6 col-lg-6">
                <InputField
                  name="maxOrderQuantity"
				  placeholder={`${t('common.placeholder max order quantity')}`}
                  handler={handleInput}
                  label={`${t('common.Max order quantity')}`}
                />
              </div>
              <div className="col-md-6 col-lg-6">
                {'numberInEachPackage' in (data?.product.productInformation ?? {}) && (
                  <InputField
                    name="numberInEachPackage"
                    handler={handleInput}
                    label={`${t('common.Box size')} (st)`}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="form-row">
          <TextAreaField
            name="description"
            handler={handleInput}
            label={t('common.Description')}
            className="col-lg-6 col-sm-12"
            rows={22}
          />
          <div className="form-group col-lg-6 col-sm-12 d-flex flex-column">
            <label>{t('common.Picture')}</label>
				<ImageCrop
				sourcePicture={sourcePicture}
				setCroppieInstance={setCroppieInstance}
				cropAreaSize={cropAreaSize}
				/>
          </div>
        </div>

        <div className="row" style={sourcePicture ? extraMargin : undefined}>
          <div className="col d-flex justify-content-end align-items-end">
            <input
              type="file"
              name="sourcePicture"
              accept=".jpg,.jpeg,.png"
              ref={(ref) => (sourcePictureField.current = ref)}
              onChange={(e) => {
                if (e.currentTarget.files?.length) {
                  setSourcePicture(e.currentTarget.files[0]);
                } else {
                  setSourcePicture(null);
                }
              }}
              className="invisible"
            />
            <button
              type="button"
              className="btn mb-4 btn-warning mr-1"
              onClick={resetImage}
              disabled={sourcePicture === null ? true : false}
            >
              {t('common.Reset')}
            </button>
            <button
              type="button"
              className="btn btn-info mb-4"
              onClick={openFilePicker}
            >
              {t('common.Upload picture')}
            </button>
          </div>
        </div>

        <hr className="form-delimiter" />

        <button
          type="submit"
          className="btn btn-primary mr-2"
          data-testid="submit__company_form"
        >
          {t('common.Save')}
        </button>
        <button
          type="button"
          onClick={() => onCancel()}
          className="btn btn-info"
        >
          {t('common.Cancel')}
        </button>
      </form>
      <ConfirmationModal
        title={t('common.Delete document')}
        buttonMessage={t('common.Delete')}
        message={t('common.Are you sure you want to delete the document?', {
          document: itemToDelete.name,
        })}
        modalVisible={confirmationModalVisible}
        setModalVisible={setConfirmationModalVisible}
        onConfirmation={onConfirmation}
      />
    </>
  );
}
