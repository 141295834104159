import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';

import Modal from '../Modal';
import InputField from '../Form/InputField';
import {MergedDocument} from '../../store/documents/selectors';
import {UploadedFile, Folder} from '../../types';

export type RenameModalInputs = {name: string};

export default function RenameModal({
  item,
  modalVisible,
  setModalVisible,
  onSave,
}: {
  item: MergedDocument<UploadedFile | Folder> | null;
  modalVisible: boolean;
  setModalVisible: (f: boolean) => void;
  onSave: (inputs: RenameModalInputs) => void;
}) {
  const {t} = useTranslation();
  const handleInput = useForm<RenameModalInputs>({mode: 'onChange'});
  const {reset} = handleInput;

  useEffect(() => {
    if (modalVisible && item) {
      reset({
        name:
          'originalName' in item.data ? item.data.originalName : item.data.name,
      });
    }
  }, [modalVisible, item, reset]);

  return (
    <Modal
      name="rename"
      modalVisible={modalVisible}
      setModalVisible={setModalVisible}
      title={t('common.Rename')}
      dialogClassName="modal-mw-small modal-dialog-centered"
    >
      <form onSubmit={handleInput.handleSubmit(onSave)}>
        <InputField
          name="name"
          handler={handleInput}
          rules={{required: true}}
          placeholder={t('common.New name')}
        />
        <div className="float-right mt-7">
          <button
            type="button"
            className="btn btn-info mr-2"
            data-dismiss="modal"
          >
            {t('common.Cancel')}
          </button>
          <button type="submit" className="btn btn-primary">
            {t('common.Rename')}
          </button>
        </div>
      </form>
    </Modal>
  );
}
