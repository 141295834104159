import React from 'react'
import EShopProductsProvider from './EShopProductsProvider'
import EShopCategoryProvider from './EShopCategoryProvider'
import EShopProductProvider from './EShopProductProvider'
import EShopCartProvider from './EShopCartProvider'

const EShopProvider: React.FC = ({ children }) => (
	<EShopProductsProvider>
		<EShopCategoryProvider>
			<EShopProductProvider>
				<EShopCartProvider>
					{children}
				</EShopCartProvider>
			</EShopProductProvider>
		</EShopCategoryProvider>
	</EShopProductsProvider>
)

export default EShopProvider
