import { request } from "../lib/request";
import { CopyOffer, CreateOfferPayload, OfferStatus, OfferStatusValues } from "../store/offers/types";
import { uploadImg } from "./upload";

const methods = {
	getOffers(query?: { limit?: number, offset?: number, all?: boolean, received?: boolean, offerStatus?: OfferStatusValues, search?: string }) {
		return request({
			endpoint: '/customer/offer/read',
			method: 'GET',
			query,
		});
	},

	getQuantitiesOfOfferStatuses(query?: { all?: boolean, received?: boolean }) {
		return request({
			endpoint: '/customer/offer/filter/read',
			method: 'GET',
			query,
		});
	},
		
	getOffer(offerId: string) {
		return request({
			endpoint: '/customer/offer/:offerId/read',
			method: 'GET',
			params: { offerId },
		})
	},
		
	createOffer({ companyLogo, ...data }: CreateOfferPayload) {
		return uploadImg(
			{
				endpoint: '/customer/offer/create',
				data,
			},
			companyLogo
		)
	},
		
	// Just like create or update, only generate a temporary pdf for preview purposes
	// And doesn't have a relation to certain a certain id
	//
	// !Therefore shouldn't be hooked to cache
	generatePdf({ companyLogo, ...data }: CreateOfferPayload) {
		return uploadImg(
			{
				endpoint: '/customer/offer/generatepdf',
				data,
				responseType: 'blob'
			},
			companyLogo
		)
	},
	sendOffer(offerId: string) {
		return request({
			method: 'GET',
			endpoint: '/customer/offer/mail/:offerId',
			params: { offerId }
		})
	},
	addToCart(offerId: string) {
		return request({
			method: 'GET',
			endpoint: '/customer/offer/cart/:offerId',
			params: { offerId }
		})
	},

	updateOffer({ offerId, payload: { companyLogo, ...data } }: { offerId: string, payload: Partial<CreateOfferPayload> }) {
		return uploadImg(
			{
				endpoint: '/customer/offer/:offerId/update',
				data,
				params: { offerId },
			}, 
			companyLogo
		)
	},

	deleteOffer(offerId: string) {
		return request({
			endpoint: '/customer/offer/:offerId/delete',
			method: 'DELETE',
			params: { offerId },
		})
	},

	copyOffer(copyOffer: CopyOffer) {
		let offerId = copyOffer.OfferId.toString();
		let userId = copyOffer.userId ?? ''	
		
		return request({
			endpoint: userId === '' ? '/customer/offer/:offerId/copy' : '/customer/offer/:offerId/copy?userId=:userId',
			method: 'GET',
			params: { offerId, userId },
		})
	},

	updateOfferStatus(params: { offerId: string, status: OfferStatus }) {
		return request({
			endpoint: '/customer/offer/status/:offerId/:status',
			method: 'POST',
			params
		})
	},

	getRelatedCustomers() {
    return request({
      endpoint: '/customer/offer/customer/related',
      method: 'GET'
    });
  },

	getCustomerRelatedProject(projectId?: number) {
    return request({
      endpoint: '/customer/offer/products/related/:projectId/read',
      method: 'GET',
			params: { projectId: projectId+''  }
    });
  },

  createOrderFromOffer(offerId: string) {
	  return request({
		  endpoint: '/customer/offer/order/:offerId',
		  method: 'POST',
		  params: { offerId }
	  })
  },
};

export default methods
