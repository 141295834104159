import { useEffect, useState } from 'react'
import { useForm } from "react-hook-form"
import { connect, ConnectedProps } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Option } from '../../../components/Form/SelectField'
import { ErrorResponseResolver } from '../../../lib/responseHelper'
import { PATH_CASES_BACK_OFFICE_LIST } from '../../../router/paths'
import { AppState } from '../../../store'
import { createServiceCase } from '../../../store/cases'
import { CreateCaseFormType } from '../../../store/cases/types'
import { toggleToast } from '../../../store/components'
import { getCaseManamagementCategoriesOptions } from '../../../store/genericListOption'
import CreateManagementCaseForm from './CreateCaseManagementCaseForm'

const connector = connect(
    (state: AppState) => ({
        genericListOption: state.genericListOption.selected
    }),
    { getCaseManamagementCategoriesOptions, createServiceCase, toggleToast }
);

type CreateCaseProps = ConnectedProps<typeof connector> & {};

export function CreateCase({
    genericListOption,
    getCaseManamagementCategoriesOptions,
    createServiceCase,
    toggleToast
}: CreateCaseProps) {
    const history = useHistory();
    const [categoriesOptionsData, setCategoriesOptionsData] = useState<Option[] | []>([]);

    const { data: getCaseManamagementCategoriesOptionsData, loading: getCaseManamagementCategoriesOptionsDataLoading } = genericListOption;
    useEffect(() => {
        getCaseManamagementCategoriesOptions()
    }, [getCaseManamagementCategoriesOptions])

    useEffect(() => {
        let categoryOptions: Option[] = [];
        getCaseManamagementCategoriesOptionsData?.serviceCaseCategoriesUiList.forEach(val => categoryOptions.push({
            label: val.key,
            value: val.value
        }))
        setCategoriesOptionsData(categoryOptions)
    }, [getCaseManamagementCategoriesOptionsData])

    const handler = useForm<CreateCaseFormType>({ mode: 'onChange' });
    // const { reset, watch } = handler;


    type Attachment = {
        id: string;
        fileList: FileList;
    };

    const [attached, setAttached] = useState<Attachment[] | Attachment[]>([]);

    const setAttachments = function (fileList: FileList, id: string) {
        let ob = {
            fileList: fileList,
            id: id,
        } as Attachment;
        attached.push(ob);
        setAttached(attached);
    };

    const deleteAttachments = function (id: string) {
        setAttached(attached.filter((v) => v.id !== id));
    };


    const handleSubmit = (async (createCaseFormType: CreateCaseFormType) => {
        let fileList = 
            attached.map((f) => f.fileList);
        await createServiceCase({
            fileList,
            createCaseFormType,
            callback: (errorResponseResolver: ErrorResponseResolver) => {
                toggleToast({
                    shouldToggle: true,
                    message: errorResponseResolver.message,
                    status: errorResponseResolver.status
                })
            }
        })
        history.push(PATH_CASES_BACK_OFFICE_LIST)
    })

    const requestFile = (fileId: string) => {
       requestFile(fileId)
    }

    return (
        <div>
            <h1 data-testid="manage_companies_headline">Ärendehantering</h1>
            <h4>Skapa nytt ärende</h4>
            <p></p>
            <CreateManagementCaseForm
                handler={handler}
                handleSubmit={handleSubmit}
                categorys={categoriesOptionsData}
                setAttachments={setAttachments}
                deleteAttachments={deleteAttachments}
                addedFiles={undefined}
                isEdit={false}
                requestFile={requestFile}
            />
        </div>
    );
}

export default connector(CreateCase);
