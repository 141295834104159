import React, {useEffect, useState} from 'react';
import actionService from '../services/actionService';
import {Action} from '../types/actions/action';

export interface useFetchActionsNeededProps {
  refreshActionsNeeded: boolean;
  setRefreshActionsNeeded: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function useFetchActionsNeeded({refreshActionsNeeded, setRefreshActionsNeeded}: useFetchActionsNeededProps) {
  const [actions, setActions] = useState<Action[]>([]);

  useEffect(() => {
		if (refreshActionsNeeded) {
			const request = async () => {
				let response;
				try {
					response = await actionService.getActionsNeeded();
				} catch (error) {

				}
				setActions(response?.data ?? []);
			};
			request();
			setRefreshActionsNeeded(false)
		}
  }, [refreshActionsNeeded, setRefreshActionsNeeded]);

  return actions;
}
