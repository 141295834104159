import React from 'react';
import {Switch, Route, Redirect, useRouteMatch} from 'react-router-dom';

import ConfirmUser from '../pages/Auth/ConfirmUser';
import AuthWrapper from '../pages/Auth/AuthWrapper';
import Login from '../pages/Auth/Login';
import RequestPassword from '../pages/Auth/RequestPassword';
import NewPassword from '../pages/Auth/NewPassword';

import {
  PATH_AUTH_CONFIRM_USER,
  PATH_AUTH_LOGIN,
  PATH_AUTH_FORGOT_PASSWORD,
  PATH_AUTH_NEW_PASSWORD,
  PATH_HOME,
  PATH_ANY,
} from './paths';

export default function AuthRouter() {
  const {path} = useRouteMatch();

  return (
    <AuthWrapper>
      <Switch>
        <Route exact path={path}>
          <Redirect from={path} to={PATH_AUTH_LOGIN} />
        </Route>
        <Route path={PATH_AUTH_CONFIRM_USER} component={ConfirmUser} />
        <Route path={PATH_AUTH_LOGIN} component={Login} />
        <Route path={PATH_AUTH_FORGOT_PASSWORD} component={RequestPassword} />
        <Route path={PATH_AUTH_NEW_PASSWORD} component={NewPassword} />
        <Route path={PATH_ANY}>
          <Redirect to={PATH_HOME} />
        </Route>
      </Switch>
    </AuthWrapper>
  );
}
