import React from 'react'
import OfferListingList from './OfferListingList/OfferListingList'

const CustomerOffers = () => {
	
	return (
		<OfferListingList type="customer" />
	)
}

export default CustomerOffers
