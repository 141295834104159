
/**
 * Delay execution of function until _idle_ for duration of set delay
 * @function Debounce
 * @method process - Main process or logic
 */
function Debounce() {
	
	/** Tracks timeout */
	let timeout: NodeJS.Timeout

	/**
	 * Main process or logic
	 * @param func - callback function
	 * @param delay - ms
	 * @param cancel - cancels execution the delayed execution
	 * @returns {Promise} - resolve on execution and cancellation (with a message)
	 */
	return (func: Function, delay: number, cancel?: boolean) => {
		return new Promise((resolve, reject) => {

			// clears queued execution
			clearTimeout(timeout)

			// results in no execution
			if(cancel) resolve('function canceled')
			// queues execution of func until after delay
			timeout = setTimeout(() => {
				func()
				resolve('function executed')
			}, delay )
		})
	}
}

export default Debounce