import React, {useEffect, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';
import {AppState} from '../../store';
import {setShowLoader} from '../../store/system';
import {parseValidationException} from '../../lib/request';
import CompanyForm, {CompanyFormInput} from '../../components/Company/CompanyForm';
import { createCustomer, resetCustomer } from '../../store/companies';
import { toggleToast } from '../../store/components';
import { Customer } from '../../store/companies/types';
import { PATH_CUSTOMERS_MANAGE_COMPANIES } from '../../router/paths';
import { useHistory } from "react-router";
import DialogWarning from '../../components/DialogWarning/DialogWarning'
import { AxiosResponse } from 'axios';

const connector = connect((state: AppState) => ({

}), 
{setShowLoader, createCustomer, toggleToast, resetCustomer});

interface CreateCompanyProps extends ConnectedProps<typeof connector> {}

const CreateCompany =({
  setShowLoader,
  createCustomer,
  toggleToast,
  resetCustomer,
}: CreateCompanyProps) => {
  const {t} = useTranslation();
  const handleInput = useForm<CompanyFormInput>({
    mode: 'onChange',
  });
  const history = useHistory()
  const {
    formState: {isSubmitting},
  } = handleInput;
  const [handleCloseModal, setHandleCloseModal] = useState(false)
  const [handleCloseConfirm, setHandleCloseConfirm] = useState(false)

  const onSubmit = async ({
      Customer,
      InternalCustomer
  }: CompanyFormInput) => {
    const accessModules = InternalCustomer.customerPermission.accessModules;
    const TermsOfPayment = Customer.TermsOfPayment?.value
    const TermsOfDelivery = Customer.TermsOfDelivery?.value
    const WayOfDelivery = Customer.WayOfDelivery?.value
    const CountryCode = Customer.Country?.value
    const VATType = Customer.VATType?.value
    const PriceList = Customer.PriceList?.value
    const CostCenter = Customer.CostCenter?.value
    const SalesAccount = Customer.SalesAccount?.value
    const Currency = Customer.Currency?.value

    createCustomer({
      payload: {
              Customer:{
                Name: Customer.Name,
                Address1: Customer.Address1,
                Address2: Customer.Address2,
                Phone1: Customer.Phone1,
                Phone2: Customer.Phone2,
                Email: Customer.Email,
                OrganisationNumber: Customer.OrganisationNumber,
                CountryCode: CountryCode,
                WWW: Customer.WWW,
                ZipCode: Customer.ZipCode,
                City: Customer.City,
                YourReference: Customer.YourReference,
                OurReference: Customer.OurReference,
                PriceList: PriceList,
                Currency: Currency,
                CostCenter: CostCenter,
                SalesAccount: SalesAccount,
                ShowPriceVATIncluded: Customer.ShowPriceVATIncluded,
                InvoiceFreight: Customer.InvoiceFreight,
                InvoiceDiscount: Customer.InvoiceDiscount,
                InvoiceAdministrationFee: Customer.InvoiceAdministrationFee,
                InvoiceRemark: "Something here",
                TermsOfPayment: TermsOfPayment,
                TermsOfDelivery: TermsOfDelivery,
                WayOfDelivery: WayOfDelivery,
                VATType: VATType,
                VATNumber: Customer.VATNumber,
              },
              InternalCustomer: {
                customerPermission: {
                  accessModules:accessModules.map((module:string) => ({module}))
                },
            }
          },
          callback: m => {
            toggleToast({
                    shouldToggle: true,
                    message: m.message,
                    status: m.status
                })
        }
      }
    ).then(response => {
      let cust = response.payload as Customer

      if (cust.Customer){
          let msg = t('common.Saved')
          toggleToast({
              shouldToggle: true,
              message: msg,
              status: 200
          })
          handleInput.reset();
          history.push(PATH_CUSTOMERS_MANAGE_COMPANIES) //redirect you to edit mode
      }
    });
  }

  useEffect(() => { 
    return () => {resetCustomer()} }, [])

    useEffect(() => {
      if(handleCloseConfirm){
        setHandleCloseConfirm(false)
        history.push(PATH_CUSTOMERS_MANAGE_COMPANIES)
      }
    }, [handleCloseConfirm])
  
  useEffect(() => {
    setShowLoader(isSubmitting);
  }, [isSubmitting, setShowLoader]);

  return (
    <div>
      <h1 data-testid="create_company_headline">
        {t('navigation.Customers__nav.Create new company')}
      </h1>
      <form onSubmit={handleInput.handleSubmit(onSubmit)}>
        <CompanyForm handleInput={handleInput}/>

        <hr className="form-delimiter" />

        <button
          type="submit"
          className="btn btn-primary mr-2"
          data-testid="submit__company_form"
        >
          {t('common.Save data')}
        </button>
        <button type="button" className="btn btn-info" onClick={ () => setHandleCloseModal(true)}>
          {t('common.Cancel')}
        </button>
      </form>
      <DialogWarning
				open={handleCloseModal}
				subject={t('modal.Are you sure?')}
				title={t('modal.Are you sure?')}
				content={t('modal.If you cancel your changed data will get lost.')}
				onConfirm={() => setHandleCloseConfirm(true)}
				onClose={() => setHandleCloseModal(false)}
			/>
    </div>
  );
}

export default connector(CreateCompany);
