import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Modal from '../Modal';
import { useForm } from 'react-hook-form';
import MultipleFileInput from '../Form/MultipleFileInput';
import uploadService from '../../services/upload';
import { toggleToast } from '../../store/components';
import { UIComponent } from '../../store/components/types';
import DropdownButton from '../DropdownButton';
import { Alert } from '@material-ui/lab';
import { BytesToMB, BytesToSizeWithPrefix } from '../../extensions/BytesConverterExtension';

export function FileUploaderModal({
    modalVisible,
    setuploadOk,
    setModalVisible,
    folderPath
    }: {
    modalVisible: boolean;
    setModalVisible: (f: boolean) => void;
    folderPath: string;
    setuploadOk : (f: UIComponent) => void;
    }): JSX.Element{
        const {t} = useTranslation();
        const handleInput = useForm<any>({
            mode: 'onChange',
        });
		const [attachedSize, setattachedSize] = useState<number>(0);

        const [attached, setAttached] = useState<Attachment[]>([]);

        useEffect(()=> {},[toggleToast])

		useEffect(() => {
			let size = 0
			attached.forEach(f => 
			  size += f.fileList[0].size
			)
			setattachedSize(size)
		  },[attached])

        const prepareSubmit = () => {
            const uploadDocumentService = uploadService.uploadFiles(folderPath);
			let files = attached.map(i => i.fileList[0])
			uploadDocumentService(files)
				.then((msg) => {
					setuploadOk({
						toggleToast: true,
						message: msg.statusText === "OK" ? t("common.UploadComplete") : msg.statusText,
						status:  msg.status
						});
				}).catch((e)=> {
					setuploadOk({
						toggleToast: true,
						message: e.statusText ? e.statusText : t("common.UploadInComplete") ,
						status:  e.status ? e.status : "400"
					});
				})
          setAttached([])
          handleInput.reset()
          setModalVisible(false)
        };
        type Attachment = {
            id: string;
            fileList: FileList;
        };

        const setAttachments = function (fileList: FileList, id: string) {
            const ids = attached.map(a => a.id)
            if(ids.includes(id)) return

              let ob = {
                fileList: fileList,
                id: id,
              } as Attachment;
              setAttached(a => [...a, ob]);
        };

        const deleteAttachments = function (id: string) {
            setAttached(attached.filter((v) => v.id !== id));
        };

        return (
            <Modal
            name="crate-new-ile"
            modalVisible={modalVisible}
            setModalVisible={setModalVisible}
            title={t('common.Upload file')}
            dialogClassName="modal-mw-small modal-dialog-centered"
            >
            <form onSubmit={handleInput.handleSubmit(prepareSubmit)}>
            {attached &&
                [...attached?.reduce<{ originalName: string, id: string }[]>((p,c) => [ ...p, { originalName: c.fileList[0].name, id: c.id } ], []) ?? []].map((value) => {

                    return (
                            <div key={value.id} className="input-group">
                            <label className="file border px-2 py-3 d-flex col-12 justify-content-between">
                              <span className="font-weight-bold">
                                {value.originalName}
                              </span>
                              <DropdownButton
                                id={`file__option__dropdown_${value.id}`}
                                label={t('common.Options')}
                                dropdownList={[
                                  {
                                    text: t('common.Delete'),
                                    className: 'text-danger',
                                    onClick: () => {
                                        deleteAttachments(value.id);
                                    },
                                  },
                                ]}
                              />
                            </label>
                          </div>
                        );
                    })}

                <MultipleFileInput
                label={''}
                files={{}}
                setAttachments={setAttachments}
                deleteAttachments={deleteAttachments}
                placeholder={t('common.Upload new document')}
                />
                <div className="form-row float-right">
                     {attached.length > 0 && 
                        <>
                          {BytesToSizeWithPrefix(attachedSize)} <span>/ 30MB</span>
                        </>
                     }
                </div>
                <br />
                {BytesToMB(attachedSize) > 30 &&
                  <>
					<br />
					<Alert severity="error">Max tillåtna filstorlek är 30MB</Alert>
					<br />
                  </>
                }
                
                <div className="form-row float-right mt-2">
                  <button
                      type="button"
                      className="btn btn-info mr-2"
                      data-dismiss="modal"
                      onClick={() => setAttached([])}
                  >
                      {t('common.Cancel')}
                  </button>
                  <button type="submit" className="btn btn-primary" disabled={BytesToMB(attachedSize) > 30}>
                      {t('common.Upload')}
                  </button>
                </div>
            </form>
          </Modal>
        )
}