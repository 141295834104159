import React from 'react';
import classnames from 'classnames';

import './Dropdown.scss';

export type DropdownItem = {
  text: string;
  onClick?: React.MouseEventHandler;
  className?: string;
};

export type DropdownButtonProps = {
  id: string;
  label: string | JSX.Element;
  dropdownAs?: JSX.Element;
  dropdownList?: DropdownItem[];
  btnClassName?: string;
  wrapperClassName?: string;
  menuClassName?: string;
};

export default function DropdownButton({
  label,
  id,
  dropdownAs,
  dropdownList,
  btnClassName,
  wrapperClassName,
  menuClassName,
}: DropdownButtonProps) {
  return (
    <div className={classnames('dropdown', 'd-inline-block', wrapperClassName)}>
      <button
        className={classnames(
          'dropdown-text-button dropdown-toggle',
          btnClassName
        )}
        id={id}
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {label}
      </button>
      <div
        className={classnames('dropdown-menu', menuClassName)}
        aria-labelledby={id}
      >
        {dropdownList &&
          dropdownList.map(({text, className, onClick}) => (
            /* TODO: span to button? */
            <span
              key={text}
              className={classnames('dropdown-item', className)}
              onClick={onClick}
            >
              {text}
            </span>
          ))}
        {dropdownAs}
      </div>
    </div>
  );
}
