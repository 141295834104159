import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import cartService from '../../services/cart';
import { ListData } from '../types';
import { listFulfilled, listPending, listRejected} from '../utils';
import { B2Cupload, CartItem, CartState } from '../cart2/types';
import errorResponse, { ErrorResponseResolver } from '../../lib/responseHelper';

const name = 'cart';

export const addToCart = createAsyncThunk<ListData<CartItem>, { articleId: string, quantity: number }>(
  `eon-ui/addToCart`,
  async function ({ articleId, quantity }) {
    const { data, headers } = await cartService.addToCart(articleId, quantity);
    return {
      data: data as CartItem[],
      total: +headers['x-total-count']
    }
  }
);

export const addToCartB2CFile = createAsyncThunk<
    ListData<CartItem>, {
    b2cupload: B2Cupload,
    callback: (errorResponseResolver: ErrorResponseResolver) => void;
    callbackOnSuccess: () => void;
   }>(
  `eon-ui/addToCartB2Cfile`, async function ({b2cupload, callback, callbackOnSuccess}) {
    try{
        const { data, headers } = await cartService.addToCartB2C(b2cupload);
        callbackOnSuccess();
        return {
          data: data.cartItems as CartItem[],
          total: +headers['x-total-count'],
        };
    }
    catch(error){
      callback(errorResponse(error))
      return {
        data: [] as CartItem[],
        total: 0,
      };
    }
});

export const deleteFromCart = createAsyncThunk<ListData<CartItem>,  { articleId: string, quantity: number }>(
  `eon-ui/deleteFromCart`,
  async function ({ articleId, quantity }) {
    const { data, headers } = await cartService.removeFromCart(articleId, quantity);
    return {
      data: data as CartItem[],
      total: +headers['x-total-count']
    };
  }
);


const initialState: CartState = {
  list: {
    data: [],
    loading: false,
    requestId: '',
    total: 0,
  },
  selected: {
    data: null,
    loading: false,
    requestId: '',
  },
};


const cartListSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addToCart.pending, (state, action) => {
      listPending(state.list, action);
    });
    builder.addCase(addToCart.fulfilled, (state, action) => {
      listFulfilled(state.list, action);
    });
    builder.addCase(addToCart.rejected, (state, action) => {
      listRejected(state.list, action);
    });  
    builder.addCase(addToCartB2CFile.pending, (state, action) => {
      listPending(state.list, action);
    });
    builder.addCase(addToCartB2CFile.fulfilled, (state, action) => {
      listFulfilled(state.list, action);
    });
    builder.addCase(addToCartB2CFile.rejected, (state, action) => {
      listRejected(state.list, action);
    });
    builder.addCase(deleteFromCart.fulfilled, (state, action) => {
      listFulfilled(state.list, action);
    });
    builder.addCase(deleteFromCart.pending, (state, action) => {
      listPending(state.list, action);
    });
    builder.addCase(deleteFromCart.rejected, (state, action) => {
      listRejected(state.list, action);
  });
  },
});

export default cartListSlice.reducer;