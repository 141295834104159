import { createSlice, createAsyncThunk, DeepPartial } from '@reduxjs/toolkit';
import usersService from '../../services/users';
import companiesService from '../../services/companies';
import { ListMeta } from '../../services/types';

import {
  listFulfilled,
  listRejected,
  entityFulfilled,
  entityPending,
  entityRejected,
  listPending,
} from '../utils';
import { ListData } from '../types';

import {
  User,
  UsersState,
  CustomerUserInput,
  AdminUserInput,
  SuperAdminUserInput,
  AccountInformation
} from './types';
import errorResponse, { ErrorResponseResolver } from '../../lib/responseHelper';
import { UserClause } from './types';

const name = 'users';

export const getUsers = createAsyncThunk<ListData<User>, ListMeta | undefined>(
  `eon-ui/getUsers`,
  async function (meta = {}) {
    const { data, headers } = await usersService.getUsers(meta);
    return {
      data: data as User[],
      total: +headers['x-total-count'],
    };
  }
);

export const getCompanyUsers = createAsyncThunk<
  ListData<User>,
  { companyId: string; meta: ListMeta }
>(`eon-ui/getCompanyUsers`, async function ({ companyId, meta }) {
  const { data, headers } = await companiesService.getCompanyUsers(
    companyId,
    meta
  );
  return {
    data: data as User[],
    total: +headers['x-total-count'],
  };
});

export const getUser = createAsyncThunk<User, string>(
  `eon-ui/getUser`, async function (userId) {
    const { data } = await usersService.getUser(userId);
    return data as User;
  }
);

export const updateUser = createAsyncThunk<
  User,
  {
    userId: string;
    userClause: UserClause,
    payload:
    | {
      Customer: Partial<CustomerUserInput>;
    }
    | {
      admin: Partial<AdminUserInput>;
    }
    | {
      superAdmin: Partial<SuperAdminUserInput>;
    };
    callback: (errorResponseResolver: ErrorResponseResolver) => void;
  }
>(`eon-ui/updateUser`, async function ({ userId, userClause, payload, callback}) {
    try{
    const { data } = await usersService.updateUser(userId, userClause, payload)
    return data as User;
    }
    catch(error){
      callback(errorResponse(error))
      return {} as User;
    }
});


export const updateUserInformation = createAsyncThunk<
  User,
  {
    userId: string;
    payload: DeepPartial<AccountInformation>
  }
>(`eon-ui/updateUser`, async function ({ userId, payload }) {
  const { data } = await usersService.updateUserInformation(userId, payload);
  return data as User;
});


export const deleteUser = createAsyncThunk<User, string>(
  `eon-ui/deleteUser`,
  async function (userId) {
    const { data } = await usersService.deleteUser(userId);
    return data as User;
  }
);

export const activationEmail = createAsyncThunk<User, string>(
  `eon-ui/activationEmail`,
  async function (userId) {
    const { data } = await usersService.activationEmail(userId);
    return data as User;
  }
);


export const initialState: UsersState = {
  list: {
    data: [],
    loading: false,
    requestId: '',
    total: 0,
  },
  selected: {
    data: null,
    loading: false,
    requestId: '',
  }
};

const usersSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUsers.pending, (state, action) => {
      listPending(state.list, action);
    });
    builder.addCase(getUsers.fulfilled, (state, action) => {
      listFulfilled(state.list, action);
    });
    builder.addCase(getUsers.rejected, (state, action) => {
      listRejected(state.list, action);
    });

    builder.addCase(getCompanyUsers.pending, (state, action) => {
      listPending(state.list, action);
    });
    builder.addCase(getCompanyUsers.fulfilled, (state, action) => {
      listFulfilled(state.list, action);
    });
    builder.addCase(getCompanyUsers.rejected, (state, action) => {
      listRejected(state.list, action);
    });

    builder.addCase(getUser.pending, (state, action) => {
      entityPending(state.selected, action);
    });
    builder.addCase(getUser.fulfilled, (state, action) => {
      entityFulfilled(state.selected, action);
    });
    builder.addCase(getUser.rejected, (state, action) => {
      entityRejected(state.selected, action);
    });

    builder.addCase(updateUser.pending, (state, action) => {
      entityPending(state.selected, action);
    });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      entityFulfilled(state.selected, action);
    });
    builder.addCase(updateUser.rejected, (state, action) => {
      entityRejected(state.selected, action);
    });

    builder.addCase(deleteUser.pending, (state, action) => {
      entityPending(state.selected, action);
    });
    builder.addCase(deleteUser.fulfilled, (state, action) => {
      entityFulfilled(state.selected, action);
    });
    builder.addCase(deleteUser.rejected, (state, action) => {
      entityRejected(state.selected, action);
    });

    builder.addCase(activationEmail.pending, (state, action) => {
      entityPending(state.selected, action);
    });
    builder.addCase(activationEmail.fulfilled, (state, action) => {
      entityFulfilled(state.selected, action);
    });
    builder.addCase(activationEmail.rejected, (state, action) => {
      entityRejected(state.selected, action);
    });
  },
});

export default usersSlice.reducer;
