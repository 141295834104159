/* eslint-disable import/no-anonymous-default-export */
/**
 * Takes a currency and returns a function that takes a number and returns it formatted
 * @example
 * const format = formatter('SEK')
 * 
 * format(1010) //-> "1 010,00 SEK"
 * /---- or -----/
 * format(11256.1) //-> "1 256,10 SEK"
 */
export default 
	(
		currency: string, 
		{
			removeSEK
		}: {
			removeSEK?: boolean 
		} = {}
	) => 
		(price: number) => {
			const raw = 
				new Intl.NumberFormat(
					'de-DE', 
					{ style: 'currency', currency }
				).format(price).replace(/\./g, ' ')

			return removeSEK ? raw.replace(/SEK/, '') : raw
		}