import React from 'react';
import {FormContextValues} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import classnames from 'classnames';

import {hasErrorOf, DEFAULT_RULES} from '../../lib/form';

import InputField from '../Form/InputField';
import { Alert, AlertTitle } from '@material-ui/lab';
import Fade from '@material-ui/core/Fade/Fade';

type ChangePasswordFormProps = {
  handleInput: FormContextValues<ChangePasswordFormInput>;
  onSubmit: (input: ChangePasswordFormInput) => void;
  feedback: 'error' | 'success' | undefined
};

export type ChangePasswordFormInput = {
  old_password: string;
  password: string;
  passwordAgain: string;
};

function ChangePasswordForm({handleInput, onSubmit, feedback}: ChangePasswordFormProps) {
  const {t} = useTranslation();

  const {errors, handleSubmit, watch} = handleInput

  const minLengthError = classnames({ 'text-danger': hasErrorOf(errors, 'minLength') || hasErrorOf(errors, 'required') })

  const patternError = classnames({ 'text-danger': hasErrorOf(errors, 'pattern') || hasErrorOf(errors, 'required') })

  const genericErrorMsg = t('validation.Password does not meet the requirements');
  
  const formRules = {
    required: {value: true, message: genericErrorMsg},
    minLength: {
      value: DEFAULT_RULES.password.minLength,
      message: genericErrorMsg,
    },
    pattern: {
      value: DEFAULT_RULES.password.pattern,
      message: genericErrorMsg,
    },
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={'col-10 offset-1'}>
      <legend className="primary" data-testid="form__change_password_form">{t('common.Change password')}</legend>
      <ul>
        <li className={minLengthError}>
          {t('common.New password rules.At least eight characters')}
        </li>
        <li className={patternError}>
          {t('common.New password rules.Must contain numbers, special characters lowercase and uppercase characters')}
        </li>
      </ul>
      <InputField
        name="password"
        type="password"
        label={t('common.New password')}
        handler={handleInput}
        showHidePassword={!!handleInput.watch('password')}
        rules={formRules}
      />
      <InputField
        name="passwordAgain"
        type="password"
        label={t('common.Repeat password')}
        handler={handleInput}
        rules={{
          ...formRules,
          validate: {
            passwordMatch: (value: string) => value === watch('password'),
          },
        }}
      />
	  {feedback && 
		<Fade in={!!feedback}>
			<Alert severity={feedback}>
				{ feedback === 'success' ? 
					<>
						Bytet av lösenord lyckades!
					</>
					: feedback === 'error' ? 
					<>
						Kunde inte uppdatera. Testa en annan kombination eller försök igen senare
					</>
					: 
					<></>
				}
			</Alert>
		</Fade>
	  }
      <div className="clearfix">
        <div className="float-right mt-7">
          <button
            type="submit"
            className="btn-submit btn btn-primary"
            data-testid="change_password"
          >
            {t('common.Change password')}
          </button>
        </div>
      </div>
    </form>
  );
}

export default ChangePasswordForm;
