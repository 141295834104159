import { useState } from 'react'

interface FilterParam<T extends {[key:string]:any}> {
	obj: T,
	key?: keyof T,
	value?: any
}

const filter = <T extends {[key:string]:any}>({ obj, key, value}: FilterParam<T>) =>
	(Object.keys(obj) as (keyof T)[])
		.filter(k => typeof value === 'undefined' ? k !== key : obj[k] !== value)
		.reduce((prev, key) => ({ ...prev, [key]: obj[key] }), {}) as T

type useObjectReturn<T extends object> = [
	T,
	React.Dispatch<React.SetStateAction<T>>,
	{
		add: (val: Partial<T>) => void
		clear: () => void
		removeByKey: (key: keyof T) => void
		removeByValue: (value: any) => void
	}
]


const useObject = <T extends object>(initial: T): useObjectReturn<T> => {
	const [ object, setObject ] = useState<T>(initial)

	const methods = {
		add: (val: Partial<T>) => setObject( obj => ({ ...obj, ...val }) ),
		clear: () => setObject({} as T),
		removeByKey: (key: keyof T) => setObject( obj => filter({obj, key})),
		removeByValue: (value: any) => setObject( obj => filter({obj, value})),
	}

	return [ object, setObject, methods ]
}

export default useObject