import React, {useEffect, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useForm} from "react-hook-form";
import {useHistory, useParams} from 'react-router-dom';

import {AppState} from '../../store';
import {setShowLoader} from '../../store/system';
import {getCustomer, updateCustomer, resetCustomer} from '../../store/companies';
import {getPriceGroups} from '../../store/priceGroups';

import CompanyForm, {CompanyFormInput,} from '../../components/Company/CompanyForm';
import ManageCompanyTabNav from '../../components/Company/ManageCompanyTabNav';
import ChangeCustomerStatusModal from "../../components/Company/ChangeCustomerStatusModal";
import { CustomerPriceGroup } from '../../store/companies/types';
import {parseValidationException} from '../../lib/request';
import {PATH_CUSTOMERS_MANAGE_COMPANIES} from '../../router/paths';
import { toggleToast } from '../../store/components';
import { Customer } from '../../store/companies/types';
import DialogWarning from '../../components/DialogWarning/DialogWarning'
import {getProductCategories} from '../../store/productCategories';

const connector = connect(
    (state: AppState) => ({
        company: state.companies.selected,
        priceGroupList: state.priceGroups.list,
        productCategories: state.productCategories.list,
    }),
    {
        setShowLoader,
        getCustomer,
        updateCustomer,
        getPriceGroups,
        toggleToast,
        resetCustomer,
        getProductCategories
    }
);

interface EditCompanyProps extends ConnectedProps<typeof connector> {}

const EditCompany = ({
    getProductCategories,
    productCategories,
	company,
	setShowLoader,
	getCustomer,
	updateCustomer,
	getPriceGroups,
	priceGroupList,
    resetCustomer,
    toggleToast,
}: EditCompanyProps) => {
    const {t} = useTranslation();
    const {companyId} = useParams<{companyId:string}>();
    const history = useHistory();

    const {data: categories, loading: categoriesLoading} = productCategories;

    useEffect(() => {
        getProductCategories();
      }, [getProductCategories]);
	
    useEffect(() => { return () => {
        resetCustomer()
    } }, [])

    useEffect(() => {},[toggleToast])

    const {
        data: companyData,
        loading: companyLoading,
    } = company;

    const handleInput = useForm<CompanyFormInput>({
        mode: 'onChange',
        defaultValues:{
            Customer:{
                Active:true
            }
        }
    });

    const {
        setValue,
        formState: {isSubmitting},
    } = handleInput;

    const showLoader = companyLoading || isSubmitting || categoriesLoading;
    const [handleCloseModal, setHandleCloseModal] = useState(false)
    const [handleCloseConfirm, setHandleCloseConfirm] = useState(false)

    useEffect(() => {
        getCustomer(companyId).catch((err) =>
            console.error('getCompany error', err)
        );
        getPriceGroups();
    }, [companyId, getPriceGroups]);

    useEffect(() => {
        setShowLoader(showLoader);
    }, [showLoader, setShowLoader]);

    const [
        changeCustomerStatusModalVisible,
        setChangeCustomerStatusModalVisible,
    ] = useState(false);

    const onConfirm = ()=>{
        setChangeCustomerStatusModalVisible(false)
    }

    const onCancel = ()=>{
        setValue('Customer.Active',true)
    }

    const onSubmit = ({
		Customer,
		InternalCustomer,
        CustomerRestriction,
		...rest
	}: CompanyFormInput) => {
			const accessModules = InternalCustomer.customerPermission.accessModules;
			const priceGroup = InternalCustomer.customerPriceGroup?.priceGroupId?.value ?? null;
			const CountryCode = Customer.Country?.value
			const TermsOfPayment = Customer.TermsOfPayment?.value
			const TermsOfDelivery = Customer.TermsOfDelivery?.value
			const WayOfDelivery = Customer.WayOfDelivery?.value
			const VATType = Customer.VATType?.value
			const Project = Customer.Project?.value
			const CostCenter = Customer.CostCenter?.value
			const PriceList = Customer.PriceList?.value
			const SalesAccount = Customer.SalesAccount?.value
			const Currency = Customer.Currency?.value
            const ReferenceCategory = CustomerRestriction?.referenceCategory?.value
            const Categories = CustomerRestriction?.categories?.map(item => item.value).join(",");
			
			const priceGroupResolved = priceGroup === null ? null : {
				priceGroupId: priceGroup
			} as CustomerPriceGroup;
            updateCustomer({
                customerId: companyId,
                payload: {
                    Customer:{
                        Name: Customer.Name,
                        Active: Customer.Active,
                        CustomerNumber: companyData?.Customer.CustomerNumber,
                        CountryCode: CountryCode,
                        Address1: Customer.Address1,
                        Address2: Customer.Address2,
                        Phone1: Customer.Phone1,
                        Phone2: Customer.Phone2,
                        Email: Customer.Email,
                        EmailInvoice: Customer.EmailInvoice,
                        OrganisationNumber: Customer.OrganisationNumber,
                        WWW: Customer.WWW,
                        ZipCode: Customer.ZipCode,
                        City: Customer.City,
                        YourReference: Customer.YourReference,
                        OurReference: Customer.OurReference,
                        PriceList: PriceList,
                        Currency: Currency,
                        CostCenter: CostCenter,
                        SalesAccount: SalesAccount,
                        ShowPriceVATIncluded: Customer.ShowPriceVATIncluded,
                        InvoiceFreight: Customer.InvoiceFreight,
                        InvoiceAdministrationFee: Customer.InvoiceAdministrationFee,
                        InvoiceDiscount: Customer.InvoiceDiscount,
                        Project: Project,
                        TermsOfPayment: TermsOfPayment,
                        TermsOfDelivery: TermsOfDelivery,
                        WayOfDelivery: WayOfDelivery,
                        VATType: VATType,
                        VATNumber: Customer.VATNumber,
                        InvoiceRemark: "Something here",
                    },
                    InternalCustomer: {
						ourReferenceEmail: rest.ourReferenceEmail ?? '',
						ourReferencePhoneNumber: rest.ourReferencePhoneNumber ?? '',
						yourReferenceEmail: rest.yourReferenceEmail ?? '',
						yourReferencePhoneNumber: rest.yourReferencePhoneNumber ?? '',
                        customerPayment: {
                            orderLimit: InternalCustomer.customerPayment?.orderLimit?.value
                        },
                        customerPermission: {
                            accessModules: accessModules.map((module:string) => ({module}))
                        },
                        customerPriceGroup: priceGroupResolved,
                        customerRestriction: {
                            isActive: InternalCustomer?.customerRestriction?.active,
                            minAmountPanels: InternalCustomer?.customerRestriction?.minAmountPanels,
                            preliminaryDeliveryDate: typeof InternalCustomer?.customerRestriction?.preliminaryDeliveryDate === "object" 
                                                        ? InternalCustomer.customerRestriction.preliminaryDeliveryDate
                                                            : undefined,
                            categories: Categories,
                            referenceCategory: ReferenceCategory,
                            restrictionComment: InternalCustomer?.customerRestriction?.restrictionComment,
                        },
                    },
                },
                callback: m => {
                    toggleToast({
                            shouldToggle: true,
                            message: m.message,
                            status: m.status
                        })
                    getCustomer(companyId).catch((err) =>
                        console.error('getCompany error', err)
                    );

                    if(m.errorInformation){
                        let errorInformation = m.errorInformation.message ? m.errorInformation.message.replace('[','').replace(']','').split(', ') : []
                        if(errorInformation.length > 0){
                            errorInformation.forEach( c => {
                                handleInput.setError("Customer." + c, "");
                            })
                        }
                    }
                }
            }).then(response => {
                let cust = response.payload as Customer

                if (cust.Customer){
                    let msg = t('common.Saved')
                    toggleToast({
                        shouldToggle: true,
                        message: msg,
                        status: 200
                    })
                }
            });
        };

    useEffect(() => {
        if(handleCloseConfirm){
          setHandleCloseConfirm(false)
          history.push(PATH_CUSTOMERS_MANAGE_COMPANIES)
        }
      }, [handleCloseConfirm])

    return (
        <div>
            <h1 data-testid="edit_company_headline">
                {t('navigation.Customers__nav.Manage companies')}
            </h1>
            <ManageCompanyTabNav companyId={companyId}/>
            <form onSubmit={handleInput.handleSubmit(onSubmit)}>
                <CompanyForm
                    showWarningModal={setChangeCustomerStatusModalVisible}
                    handleInput={handleInput}
                    priceGroupsList={priceGroupList}
                    companyData={companyData} 
					isEdit
                    categoriesData={categories}
                />

                <hr className="form-delimiter"/>

                <button
                    type="submit"
                    className="btn btn-primary mr-2"
                    data-testid="submit__company_form"
                >
                    {t('common.Save data')}
                </button>
                <button type="button" className="btn btn-info" onClick={ () => setHandleCloseModal(true)}>
                    {t('common.Cancel')}
                </button>
            </form>
            <ChangeCustomerStatusModal
                    modalVisible={changeCustomerStatusModalVisible}
                    onConfirmation={onConfirm}
                    onCancel={onCancel}
                    setModalVisible={setChangeCustomerStatusModalVisible}
            />
            <DialogWarning
				open={handleCloseModal}
				subject={t('modal.Are you sure?')}
				title={t('modal.Are you sure?')}
				content={t('modal.If you cancel your changed data will get lost.')}
				onConfirm={() => setHandleCloseConfirm(true)}
				onClose={() => setHandleCloseModal(false)}
			/>
        </div>
    );
}

export default connector(EditCompany);
