import { List, Entity } from "../types";

export enum BannerMessageStatus {
    VISIBLE = "VISIBLE",
    HIDDEN = "HIDDEN"
}

export enum BannerMessageType {
    ERROR_MESSAGE = "ERROR_MESSAGE",
    WARNING_MESSAGE = "WARNING_MESSAGE",
    INFO_MESSAGE = "INFO_MESSAGE",
    SUCCESS_MESSAGE = "SUCCESS_MESSAGE"
}

export type CreateBannerMessageFormType = {
    messageStatus: {
        value: string,
        label: string
    }
    messageType: {
        value: string,
        label: string
    }
    message: string
    startDateTime: Date
    endDateTime: Date
    header: string
}

export type BannerMessage = {
    id: string
    messageStatus: BannerMessageStatus
    messageType: BannerMessageType
    message: string
    startDateTime: Date
    endDateTime: Date
    header: string
}

export type BannerMessagesState = {
    list: List<BannerMessage>;
    selected: Entity<BannerMessage>;
};
