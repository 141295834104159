import { useEffect, useState } from "react";
import "./ScrollToTop.scss";
import Icon from '@mdi/react'
import { mdiChevronUp } from '@mdi/js';


const Totop = () => {
 
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);

  
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' 
    });
  };

  return (
    <>


      {showButton && (
        <button onClick={() => scrollToTop()}>
      
     <Icon path={mdiChevronUp} size="44"className="back-to-top" />
     
         </button>
        )
        }
     
    </>
  );
};

export default Totop;