import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

import { AppState } from '../../store';

import PriceGroupForm from '../../components/EShop/PriceGroupForm';
import PriceGroupService from '../../services/priceGroup'
import { PriceGroupInput, PriceGroupInputNew } from "../../store/priceGroups/types";
import { useHistory, useParams } from 'react-router-dom';
import priceGroup from '../../services/priceGroup';

import {getPriceGroup} from '../../store/priceGroups';
import products, {getProductsWithoutFilters} from '../../store/products/';
import errorResponse from '../../lib/responseHelper';
import { toggleToast } from '../../store/components'
import { setShowLoader } from '../../store/system';

const connector = connect(
  (state: AppState) => ({
    articles: state.products.list,
    priceGroup: state.priceGroups.selected,
  }),
  {getProductsWithoutFilters, getPriceGroup, toggleToast, setShowLoader}
);

type CreatePriceGroupProps = ConnectedProps<typeof connector> & {};

export function CreatePriceGroup({
  articles,
  priceGroup,
  getProductsWithoutFilters,
  getPriceGroup,
  toggleToast,
  setShowLoader
} : CreatePriceGroupProps) {
  const { t } = useTranslation();
  const history = useHistory()
  const {priceGroupId} = useParams<{priceGroupId: string}>();

  const handleInput = useForm<PriceGroupInputNew>({
    mode: 'onChange',
  });

  const submit = async(input: PriceGroupInputNew) => {
    await PriceGroupService.createPriceGroups(input)
    ?.then(e => history.push('/e-shop/manage/price-groups/'))
    ?.catch(e => {
      let er = errorResponse(e)
      toggleToast({
        message: er.message,
        shouldToggle: true,
        status: er.status
      })
    })
  };

  const {data: articlesData, loading: articlesDataLoader} = articles;
  const {data: priceGroupData, loading: priceGroupLoader} = priceGroup;

  useEffect(() => { getPriceGroup(priceGroupId) }, [getPriceGroup, priceGroupId])

  useEffect(() => { getProductsWithoutFilters() }, [getProductsWithoutFilters]);

  useEffect(() => { setShowLoader(articles.loading) }, [articles.loading])

  

  return (
    <div>
       <h1 data-testid="create_price_group_headline">
        {t('common.Create new price group')}
      </h1>
      {!articlesDataLoader && !priceGroupLoader ? 
        <>
      <form onSubmit={handleInput.handleSubmit(submit)}>
        <PriceGroupForm 
         	handleInput={handleInput}
            articles={articlesData}
            priceGroup={priceGroupData}  
        />
        <hr className="form-delimiter" />
      </form>
      </>
      : <></>}
    </div>
  );
}

export default connector(CreatePriceGroup);
