import {List, ListData, Entity, EntityData} from './types';
import {SerializedError} from '@reduxjs/toolkit';

export function loadState() {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
}

export function saveState<T>(state: T) {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch {
    // ignore write errors
  }
}

type PendingAction = {
  type: string;
  payload: undefined;
  meta: {
    arg: unknown;
    requestId: string;
  };
};

type FulfilledAction<P = any> = {
  type: string;
  payload: P;
  meta: {
    arg: unknown;
    requestId: string;
  };
};

type RejectedAction<
  E extends SerializedError = SerializedError,
  A = unknown
> = {
  type: string;
  payload: unknown;
  error: E;
  meta: {
    arg: A;
    requestId: string;
    aborted: boolean;
    condition: boolean;
  };
};

export function listPending<T>(list: List<T>, action: PendingAction) {
  list.loading = true;
  list.error = null;
  list.requestId = action.meta.requestId;
}

export function listFulfilled<T>(
  list: List<T>,
  action: FulfilledAction<ListData<T>>
) {
  if (list.requestId !== action.meta.requestId) {
    return;
  }

  list.loading = false;
  list.data = action.payload.data;
  list.total = action.payload.total;
}

export function listFulfilledMeta<T>(list: List<T>, action: FulfilledAction) {
  if (list.requestId !== action.meta.requestId) {
    return false;
  }

  list.loading = false;

  return true;
}

export function listRejected<E, A, T>(
  list: List<T>,
  action: RejectedAction<E, A>
) {
  if (list.requestId !== action.meta.requestId) {
    return;
  }

  list.loading = false;
  list.error = action.error;

  // TODO: handle errors properly
  process.env.NODE_ENV !== 'production' &&
    console.error('listRejected error', {
      requestId: action.meta.requestId,
      error: action.error,
    });

  if(list.error.message.includes('401')){
    window.location.href= '/auth/login'
  }
}

export function entityPending<T>(
  entity: Entity<T>,
  action: PendingAction,
  resetData = true
) {
  entity.loading = true;
  entity.error = null;
  if (resetData) {
    entity.data = null;
  }
  entity.requestId = action.meta.requestId;
}

export function entityFulfilled<T>(
  entity: Entity<T>,
  action: FulfilledAction<EntityData<T>>
) {
  if (entity.requestId !== action.meta.requestId) {
    return;
  }

  entity.loading = false;
  entity.data = action.payload;
}

export function entityRejected<T, E>(
  entity: Entity<T>,
  action: RejectedAction<E>
) {
  if (entity.requestId !== action.meta.requestId) {
    return;
  }

  entity.loading = false;
  entity.error = action.error;



  // TODO: handle errors properly
  process.env.NODE_ENV !== 'production' &&
    console.error('entityRejected error', {
      requestId: action.meta.requestId,
      error: action.error,
    });
  if(entity.error.message.includes('401')){
    window.location.href= '/auth/login'
  }

}
