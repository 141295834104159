import React, {useMemo, useState} from 'react'
import ControlledAutocomplete from "../ControlledAutocomplete/ControlledAutocomplete";
import TextField from "@material-ui/core/TextField/TextField";
import {Option} from "../Form/SelectField";
import {ListData} from "../../store/types";
import {MergedArticleProduct} from "../../store/products/types";
import {FormContextValues} from "react-hook-form";
import {OrderInputValues} from "../../store/orders/types";
import {useTranslation} from "react-i18next";
import ProgressSpinnerBox from "../ProgressSpinnerBox/ProgressSpinnerBox";

export type AddArticleToOrderProperties<T> = {
    formContext: FormContextValues<T>
    addArticleCallback: (fortnoxId: string | undefined, addedToOrder: Date) => void,
    products: ListData<MergedArticleProduct>,
    isLoading: boolean
};

export function AddArticleToOrder({
    formContext,
    addArticleCallback,
    products,
    isLoading
}: AddArticleToOrderProperties<OrderInputValues>) {
    const {t} = useTranslation()
    const [fortnoxId, setFortnoxId] = useState<undefined | string>()
    const {setValue} = formContext

    const submit = () => {
        addArticleCallback(fortnoxId, new Date())
        setFortnoxId(undefined)
        setValue('fortnoxId', undefined)
    }

    const availableProductOptions = useMemo(() =>
            products.data?.map(p => ({
            label: p.product.productInformation?.name ? `${p.product.fnArticleId} - ${p.product.productInformation.name}` : p.product.fnArticleId,
            value: p.product.fnArticleId
        }))
    , [products])

    return (
        <>
            {isLoading && <ProgressSpinnerBox />}
            {!isLoading && <>
                <hr/>
                <div className="form-group col-4">
                    <label>{t('order.Search articles')}</label>
                    <ControlledAutocomplete
                        control={formContext.control}
                        name="fortnoxId"
                        options={availableProductOptions}
                        className="form-group"
                        renderInput={params =>
                            <TextField
                                {...params}
                                variant="outlined"
                                size="small"
                                style={{ width: 'clamp(300px,100vw,600px)', marginTop: -20 }}
                            />
                        }
                        onChange={(e: Option) => setFortnoxId(e.value)}
                        disableClearable
                        readOnly={products?.total <= 0}
                    />
                </div>
                <button onClick={() => submit()} disabled={!fortnoxId} className="btn btn-primary">{t('order.Add article')}</button>
                <hr/>
            </>}
        </>
    )
}