import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from '@material-ui/lab';
import { Snackbar } from '@material-ui/core';
import { useBouncer } from '../../lib/hooks';
import { mdiTooltipImage } from '@mdi/js';

export const Toaster = ({ 
  toggle, 
  toggleToast, 
  loading,
} : { 
  toggle: {shouldToggle: boolean, message: string, status: number}; 
  toggleToast?: () => void, 
  loading: boolean,
}) => {
  const severity = () => {
    switch(toggle?.status) {
      case 200:
        return 'success';
      // case warning:
      //   return 'warning';
      case 400:
        return 'error';
      default:
        return 'info';
    }
  }
  const [toogling, setToogling] = useState<boolean>(false);

  useEffect(() =>{
    if(toggle.shouldToggle && toggle.message !== "")
      setToogling(toggle.shouldToggle)
  },[toggle]) 

  const setTooglingfunc = () => {
    setToogling(false)
  };

  useBouncer(toogling, 2200, setTooglingfunc);

  const backgroundColor = () =>{
        switch(toggle?.status) {
      case 200:
        return 'rgb(237, 247, 237)';
      // case warning:
      //   return 'rgb(255, 244, 229)';
      case 400:
        return 'rgb(253, 236, 234)';
      default:
        return 'rgb(232, 244, 253)';
    }
  }

  return (
    <React.Fragment>
      {loading ? <p></p> :
          <Snackbar
            open={toogling !== null ? toogling : false}
            autoHideDuration={3000}
            >
              <Alert severity={severity() } style={{backgroundColor: backgroundColor()}}>
                {toggle?.message}
            </Alert>
          </Snackbar>
        }
    </React.Fragment>
  );
}
export default Toaster;
