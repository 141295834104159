import { FieldValues } from "react-hook-form";
import { createOffer } from "../../../store/offers";
import { OfferStatus } from "../../../store/offers/types";
import { User } from "../../../store/users/types";

export const resolveOfferPayload = ({
	sessionUser,
	customerId,
	fieldValues,
	status,
	imagechanged,
	OfferId
}: {
	sessionUser: User,
	customerId: string,
	fieldValues: FieldValues,
	status?: OfferStatus
	imagechanged?: boolean
	OfferId: number
}): Parameters<typeof createOffer>[0] => ({
	userId: sessionUser.id,
	customerId: customerId,
	expirationDate: fieldValues.expirationDate,
	offerCurrency: 'SEK',
	totalExTaxText: fieldValues.totalExTaxText,
	totalExTaxCost: fieldValues.totalExTaxCost,
	totalExTaxTG: fieldValues.totalExTaxTG,
	totalExTaxMargin: fieldValues.totalExTaxMargin,
	transportText: fieldValues.transportText,
	transportCost: fieldValues.transportCost,
	otherText: fieldValues.otherText,
	otherCost: fieldValues.otherCost1,
	otherText2: fieldValues.otherText2,
	otherCost2: fieldValues.otherCost2,
	otherText3: fieldValues.otherText3,
	otherCost3: fieldValues.otherCost3,
	totalCostExTaxOnOffer: fieldValues.totalCostExTaxOnOffer,
	taxValue: fieldValues.taxValue,
	totalCostInkTaxOnOffer: fieldValues.totalCostInkTaxOnOffer,
	totalExTaxCostAdjusted: fieldValues.totalExTaxCostAdjusted,
	informationText: fieldValues.informationText,
	companyLogo: fieldValues.companyLogo instanceof FileList ? fieldValues.companyLogo[0] : fieldValues.companyLogo,
	offerProducts: fieldValues.products.map((v: any) => { delete v.id; return v }),
	status: status ?? 'NEW',
	deliveryCustomerName: fieldValues.deliveryCustomerName, 
	deliveryAddress: fieldValues.deliveryAddress, 
	deliveryPostalNo: fieldValues.deliveryPostalNo, 
	deliveryCity: fieldValues.deliveryCity, 
	deliveryCountryCode: fieldValues.deliveryCountryCode?.value,
	deliveryEmail: fieldValues.deliveryEmail,
	deliveryPhoneNumber: fieldValues.deliveryPhoneNumber,
	deliveryCustomerId: fieldValues.deliveryCustomerId,
	imagechanged: imagechanged,
	id: OfferId,
	discountCost: fieldValues.discountCost,
	discountCost2: fieldValues.discountCost2,
	discountCost3: fieldValues.discountCost3,
   	discountText: fieldValues.discountText,
	discountText2: fieldValues.discountText2,
	discountText3: fieldValues.discountText3,
	surcharge: fieldValues.surcharge,
	yourReference: fieldValues.yourReference,
	ourReference: fieldValues.ourReference,
	yourOrderNumber: fieldValues.yourOrderNumber,
	proposedDeliveryDate: fieldValues.proposedDeliveryDate,
	driverDeliveryInstruction: fieldValues.driverDeliveryInstruction,
	customerDeliveryInstruction: fieldValues.customerDeliveryInstruction,
	wayOfDeliveryCode: fieldValues.wayOfDeliveryCode?.value,
	sendEmail: fieldValues.sendEmail,
	comment: fieldValues.comment,
})

export const downloadPdfFromUrl = (url: string, filename: string) => {
	const link = document.createElement('a')
	link.href = url
	link.target = '_blank'
	link.download = filename
	link.click()
}

export const validateOfferStatusEditable = (offerStatus: OfferStatus | undefined) => offerStatus !== undefined && ![
	OfferStatus.LOCKED, 
	OfferStatus.SENT, 
	OfferStatus.CANCELED, 
	OfferStatus.ORDERED,
	OfferStatus.APPROVED,
	OfferStatus.REJECTED,
].includes(offerStatus)
