import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import {Icon} from '@mdi/react';
import {mdiMagnify, mdiPlus} from '@mdi/js';

import './Table.scss';
import InputField from '../Form/InputField';
import Loader from '../Loader';
import Debounce from '../../lib/Debounce';
import { useHistory } from 'react-router-dom';
import { ArrowDropUp, ArrowDropDown, KeyboardArrowDown, KeyboardArrowUp, UnfoldMore, AssignmentInd } from '@material-ui/icons';

type TableProps<T> = {
  title?: string;
  blockClassNames?: string;
  searchFieldClassNames?: string;
  header?: Array<{key: string; component: JSX.Element} | string>;
  list: T[];
  loading?: boolean;
  useLoader?: boolean;
  searchRowInformationText?: JSX.Element; 
  searchField?: {
    name: string;
    text: string;
    onChange: (e: React.FormEvent<HTMLInputElement>) => void;
    value: any;
	withInternalState?: boolean
  };
  newButton?: {
    text: string;
    onClick: (e: React.MouseEvent) => void;
  };
  row: (obj: T) => JSX.Element;
  extractKey: (obj: T) => string;
  style?: React.CSSProperties
  withInternalState?: boolean
	tableStyle?: React.CSSProperties
  sortable?: {
    active: boolean;
    onClick: (e: React.MouseEvent, h: string) => void;
    direction?: string | null | undefined
    header?: string | null | undefined
  }
};

export default function Table<T>({
  title,
  newButton,
  header,
  list,
  searchRowInformationText,
  searchField,
  blockClassNames,
  searchFieldClassNames,
  loading,
  useLoader,
  row,
  extractKey,
  style,
	tableStyle,
  sortable
}: TableProps<T>) {
const [ localSearchTerm, setLocalSearchTerm ] = useState(searchField?.value ?? '')
const history = useHistory()

useEffect(() => {
	if(!searchField?.withInternalState) return
	debounce(() => {
		searchField.onChange({ target: { value: localSearchTerm}} as any as React.FormEvent<HTMLInputElement>)
	}, 300)
}, [localSearchTerm])

  return (
    <div className={classnames('table__block', blockClassNames)}>
      <div className="table__title">
        <div className="row">
          {(title || newButton) && (
            <div
              className={classnames({
                'col-md-8 col-lg-10': searchField,
                'col-12': searchField,
              })}
            >
              {title}
              {newButton && (
                <button
                  type="button"
                  onClick={newButton.onClick}
                  className={classnames(
                    'btn',
                    'btn-primary',
                    'h-100',
                    'd-inline-flex',
                    'align-items-center',
                    'pt-0',
                    'pb-0',
                    {
                      'ml-3': title,
                    }
                  )}
                >
                  <div>{newButton.text}</div>
                  <Icon path={mdiPlus} size="24" className="icon-24 ml-2" />
                </button>
              )}
            </div>
          )}
          {searchField && (
            <>
            {!newButton && (
              <div className='col-md-8 col-lg-10'>
                 {searchRowInformationText}
              </div>
            )}
            
            <div
              className={classnames('col-md-4 col-lg-2 float-right', searchFieldClassNames)}
            >
              <form onSubmit={e=>e.preventDefault()}>
                <InputField
                  name={searchField.name}
                  placeholder={searchField.text}
                  onChange={searchField.withInternalState ? (v: any) => setLocalSearchTerm(v.target.value) : searchField.onChange}
                  value={searchField.withInternalState ? localSearchTerm : searchField.value}
                  className="mb-0"
                  feedback={
                    <Icon
                      path={mdiMagnify}
                      size="24"
                      className="form-control-feedback icon-24"
                    />
                  }
                />
              </form>
            </div>
            </>
          )}
        </div>
      </div>
      <div className="table__wrapper table-responsive">
        <table className="table table__content" style={tableStyle as React.CSSProperties | undefined}>
          {header && (
            <thead className="table__head">
              <tr className="table__row">
                {header.map((h) => (
                  sortable?.active && typeof h === 'string' && h !== 'Summa' ? (
                    <td onClick={(event) => {sortable.onClick(event, h)}} style={{cursor: 'pointer', whiteSpace: 'nowrap'}} key={h}>
                      {sortable.header === h && sortable.direction === 'asc' ? <span style={{color: "#555"}}>{h} <KeyboardArrowDown style={{fontSize: "1rem"}}/></span> :
                      sortable.header === h && sortable.direction === 'desc' ? <span style={{color: "#555"}}>{h} <KeyboardArrowUp style={{fontSize: "1rem"}}/></span> :
                      <>{h} <UnfoldMore  style={{fontSize: "1rem"}}/></>}
                  </td>
                  ) : (
                    <td key={typeof h === 'string' ? h : h.key}>
                    {typeof h === 'string' ? h : h.component}
                  </td>
                  )

                ))}
              </tr>
            </thead>
          )}
          <tbody>
            {list.map((el) => (
                <tr key={extractKey(el)} className="table__row">
                  {row(el)}
                </tr>
            ))}
          </tbody>
        </table>
        {useLoader && <Loader absolute show={loading} />}
        {!useLoader && (
          <div className={classnames('table__loader', {'d-none': !loading})} />
        )}
      </div>
    </div>
  );
}

const debounce = Debounce()
