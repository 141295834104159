
import { Box } from '@material-ui/core';
import React, { useMemo } from 'react';
import TabNav from '../../components/TabNav';
import { isBackOfficeAccount } from '../../lib/permissions';
import {
    PATH_CASES_BACK_OFFICE_ASSIGN_COMMENTS, PATH_CASES_BACK_OFFICE_ASSIGN_COMMENTS_VIEW, PATH_CASES_BACK_OFFICE_CREATE_COMMENTS,

    PATH_CASES_BACK_OFFICE_EDIT,
    PATH_CASES_BACK_OFFICE_VIEW,
	PATH_CASES_BACK_OFFICE_VIEW_COMMENTS
} from '../../router/paths';
import { JocularUser, Module } from "../../store/users/types";



export default function CaseManagementTabNav({
    serviceCaseId,
    sessionUser,
    isEdit 
}: {
    serviceCaseId: string,
    sessionUser: JocularUser | undefined,
    isEdit: boolean
}) {
    
	const hasScope = sessionUser?.sessionUser?.userPermission?.accessModules.filter(am => am.module === Module.CASE_MANAGEMENT)
	const isType = sessionUser?.sessionUserType
    const links = useMemo(() => [
		...isBackOfficeAccount(isType) ? 
			isEdit && hasScope !== undefined ?
			[
				{
					path: PATH_CASES_BACK_OFFICE_EDIT,
					params: { serviceCaseId },
					component: <>Ärende</>,
				},
				{
					path: PATH_CASES_BACK_OFFICE_ASSIGN_COMMENTS,
					params: { serviceCaseId },
					component: <>Tilldelning</>,
				},
				{
					path: PATH_CASES_BACK_OFFICE_CREATE_COMMENTS,
					params: { serviceCaseId },
					component: <>Kommentarer</>,
				}
			] : 
			[
				{
					path: PATH_CASES_BACK_OFFICE_VIEW,
					params: { serviceCaseId },
					component: <>Ärende</>,
				},
				{
					path: PATH_CASES_BACK_OFFICE_ASSIGN_COMMENTS_VIEW,
					params: { serviceCaseId },
					component: <>Tilldelning</>,
				},
				{
					path: PATH_CASES_BACK_OFFICE_VIEW_COMMENTS,
					params: { serviceCaseId },
					component: <>Kommentarer</>,
				}
			]
		: []
	], [isEdit, sessionUser, serviceCaseId])

    return (
        <Box my={4}>
            {sessionUser !== undefined &&
				<Box display="flex" style={{ gap: 10 }}>
               	 	{sessionUser !== undefined ?
                <><TabNav
                    className="mb-5 mt-5"
                    linkClassName="px-2"
                    links={links}
                />
                    <div className="mb-2" />
                </>
                : <>
                    <TabNav
                    className="mb-5 mt-5"
                    linkClassName="px-2"
                    links={[]}
                />
                    <div className="mb-2" />
                </>
            }
				</Box>
            }
        </Box>
    );
}
