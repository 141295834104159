import React, { useEffect, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { FormContextValues, useForm } from "react-hook-form"
import { useHistory, useParams } from 'react-router-dom'
import { AppState } from '../../../store'
import { PATH_CASES_BACK_OFFICE_LIST } from '../../../router/paths'
import { CreateCaseFormType, File } from '../../../store/cases/types'
import { getActiveUser } from '../../../store/system'
import InputField from '../../../components/Form/InputField'
import SelectField, { Option } from '../../../components/Form/SelectField'
import TextAreaField from '../../../components/Form/TextAreaField'
import MultipleFileInput from '../../../components/Form/MultipleFileInput'
import DropdownButton from '../../../components/DropdownButton'
import ConfirmationModal from '../../../components/Modal/ConfirmationModal'
import { Module } from '../../../store/users/types'

const connector = connect(
    (state: AppState) => ({
        activeUser: state.system.sessionUser,
    }),
    {getActiveUser}
);

type CreateCaseManagementCaseFormProps = ConnectedProps<typeof connector> & {
    handler: FormContextValues<CreateCaseFormType>;
    handleSubmit: (input: CreateCaseFormType) => void;
    categorys: Option[]
    setAttachments: (fileList: FileList, id: string) => void;
    deleteAttachments: (id: string) => void;
    isEdit: boolean
    addedFiles?: File[]
    requestFile: (fileId: string) => void;
};

export function CreateCaseManagementCaseForm({
    handler,
    addedFiles,
    isEdit,
    activeUser,
    handleSubmit,
    categorys,
    setAttachments,
    deleteAttachments,
    requestFile,
    getActiveUser
}: CreateCaseManagementCaseFormProps) {

    const history = useHistory()
    const { t } = useTranslation()
    const [confirmationModalVisible, setConfirmationModalVisible] = useState(false);
    const [pickedAttachmendId, setPickedattachmentId] = useState("");

    const formColumnClass = 'col-md-12 col-lg-12';

    const canEdit = activeUser?.userPermission?.accessModules?.find(am => am.module === Module.CASE_MANAGEMENT)

    const onConfirmation = () => {
        deleteAttachments(pickedAttachmendId)
        setConfirmationModalVisible(false)
    }

    const prepareSubmit = async (input: any) => {
        try {
            handleSubmit(input)
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <>
            <form onSubmit={handler.handleSubmit(prepareSubmit)}>
                <div className="row">
                    <div className="col-6">
                        <SelectField
                            name="casePriority"
                            handler={handler}
                            label={"Prioritet"}
                            placeholder={"Prioritet"}
                            className={formColumnClass}
                            options={
                                [
                                    {label: "1 - Akut", value: "1"},
                                    {label: "2 - Hög", value: "2"},
                                    {label: "3 - Medel", value: "3"},
                                    {label: "4 - Låg", value: "4"}
                                ]
                            }
							isClearable
                        />
                    </div>
                    <div className="col-6">
                        <InputField
                            name="caseSubject"
                            handler={handler}
                            label={"Ärende"}
                            placeholder={"Ärendenamn"}
                            className={formColumnClass}
                            rules={{ required: true }}
                        />
                    </div>
                    <div className="col-6">
                        <SelectField
                            name="serviceCaseCategory"
                            handler={handler}
                            label={"Kategori"}
                            placeholder={"Kategori"}
                            options={categorys}
                            className={formColumnClass}
                            rules={{ required: true }}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <InputField
                            name="facility"
                            handler={handler}
                            label="Anläggning/order nr/faktura nr"
                            className={formColumnClass}
                            placeholder="Anläggning"
                        />
                    </div>
                    <div className="col-6">
                        {addedFiles !== undefined && addedFiles !== null ?
                            <>
                                {addedFiles.map(f => {
                                    return (
                                        <div key={f.id} className="input-group">
                                            <label className="file border px-2 py-3 d-flex col-12 justify-content-between">
                                                <span className="font-weight-bold">
                                                    {f.originalName}
                                                </span>
                                                <DropdownButton
                                                    id={`file__option__dropdown_${f.id}`}
                                                    label={t('common.Options')}
                                                dropdownList={[
                                                {
                                                    text: "Ladda ner",
                                                    className: 'text-danger',
                                                    onClick: () => {
                                                      requestFile(f.id) 
                                                    }
                                                },
                                                {
                                                    text: t('common.Delete'),
                                                    className: 'text-danger',
                                                    onClick: () => {
                                                     setPickedattachmentId(f.id)
                                                     setConfirmationModalVisible(true)
                                                    }
                                                },
                                                ]}
                                                />
                                            </label>
                                        </div>
                                    );
                                })}
                            </> :
                            <></>}
                        <label htmlFor="">{t('common.Documents and files')}</label>
                        <MultipleFileInput
                            label={''}
                            files={{}}
                            setAttachments={setAttachments}
                            deleteAttachments={deleteAttachments}
                            placeholder={t('common.Upload new document')}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <TextAreaField
                            name="caseDescription"
                            handler={handler}
                            label={"Beskrivning"}
                            className="col-12"
                            rows={12}
                        />
                    </div>
                </div>
                {isEdit && canEdit ? <button
                    type="submit"
                    className="btn btn-primary mr-2"
                    data-testid="submit__project_form"
                > {t('common.Edit')}
                </button> : ""} 

                {!isEdit && canEdit ? <button
                    type="submit"
                    className="btn btn-primary mr-2"
                    data-testid="submit__project_form"
                > {t('common.Create')}
                </button> : ""} 
             
                <button type="button" className="btn btn-info" onClick={() => history.push(PATH_CASES_BACK_OFFICE_LIST)} >
                    {t('common.Cancel')}
                </button>
            </form>
            <ConfirmationModal
                title={"Ta bort fil"}
                buttonMessage={t('common.Delete')}
                message={"Är du säker på att du vill ta bort filen?"}
                modalVisible={confirmationModalVisible}
                setModalVisible={setConfirmationModalVisible}
                onConfirmation={onConfirmation}
            />
        </>
    );
}

export default connector(CreateCaseManagementCaseForm);