import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {I18nextProvider} from 'react-i18next';

import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'simplebar/dist/simplebar.min.css';

import * as serviceWorker from './serviceWorker';

import './scss/index.scss';
import i18n from './i18n';

import AppRouter from './router';
import store from './store';
import theme from './theme/theme';
import { ThemeProvider } from '@material-ui/core/styles';

ReactDOM.render(
  // FIXME: enable after react-select was updated
  // <React.StrictMode>
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
		<ThemeProvider theme={theme}>
			<AppRouter />
		</ThemeProvider>
    </Provider>
  </I18nextProvider>,
  // </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
