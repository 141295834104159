import axiosInstance, {request} from '../lib/request';
import {ListMeta} from './types';
import {ENDPOINTS} from '../endpoints';
import {
  ProductInput,
  InternalProduct,
  MonitorProduct,
} from '../store/products/types';
import {generatePath} from 'react-router-dom';

export type ProductsFilter = {
  priceLowest?: number | null;
  priceHighest?: number | null;
  limit: number;
  offset: number;
  textSearch?: string | null
  searchCategoryId?: string | null
};

const methods = {
  createProduct(fortNoxArticleId: string, payload: ProductInput) {
    const formData = new FormData();
    const {...data} = {...payload};
    // @ts-ignore
    delete data.image;
    const prep = {
      productInformation: {
        ...data,
      },
      productCustomers: data.productCustomers,
      productReserved: data.productReserved
    };

    const productBlob = new Blob([JSON.stringify(prep)], {
      type: 'application/json',
    });

    if (payload.image !== undefined) {
      formData.append('image', payload.image, 'product-image');
    } else {
      formData.append('image', new Blob(), 'product-image');
    }
    formData.append('data', productBlob);

    payload.documents.forEach((p) => {
      if (p[0] !== null && p[0] !== undefined) {
        const blob = p[0] as Blob;
        formData.append('document', blob, p[0].name);
      }
    });

    return axiosInstance.post(
      generatePath(ENDPOINTS.ESHOP_BACK_ARTICLES_CREATE, {
        fortNoxArticleId,
      }),
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  },

  deleteInternalArticleImage(internalArticleId: string) {
    return request({
      endpoint:
        '/eshopback/article/:internalArticleId/removeimage/delete',
      method: 'DELETE',
      params: {internalArticleId},
    });
  },

  getProducts({filter, filters, textSearch, manufacturer, page, searchCategoryId}: ListMeta<ProductsFilter>) {
    const {limit, offset} = filters ?? {};
    return request({
      endpoint: '/eshopfront/articles/read',
      method: 'GET',
      query: {
        ...(filter ? {type: filter} : null),
        ...(page
          ? {
              limit: 10,
              page,
            }
          : null),
        limit: limit,
        offset: offset,
		...textSearch ? {search: textSearch}: null,
		...manufacturer ? {manufacturer}: null,
		...searchCategoryId ? {searchCategoryId}: null
      },
    });
  },

  getRecommendedProducts() {
    return request({
      endpoint: ENDPOINTS.E_SHOP_FRONT_ARTICLES_RECOMMENDED_READ,
      method: 'GET'
    });
  },

  getProduct(internalArticleId: string) {
    return request({
      endpoint: ENDPOINTS.ESHOP_BACK_READ_SPECIFIC_ARTICLE,
      method: 'GET',
      params: {internalArticleId},
    });
  },

  createMonitorProduct(payload : MonitorProduct){
    return request({
      endpoint: ENDPOINTS.MONITOR_CREATE,
      method: 'POST',
      payload
    }) 
  },

  deleteProduct(internalArticleId: string) {
    return request({
      endpoint: ENDPOINTS.ESHOP_BACK_ARTICLES_UNMAPPED_DELETE,
      method: 'DELETE',
      params: {internalArticleId},
    });
  },

  deleteProductImage(articleId: string, fileId: string) {
    return request({
      endpoint: ENDPOINTS.ESHOP_BACK_PRODUCT_DELETE_PRODUCT_IMAGE,
      method: 'DELETE',
      params: {articleId, fileId},
    });
  },

  getProductFile(fileId: string, customerId: string) {
    return request({
      endpoint: ENDPOINTS.E_SHOP_FRONT_FILE,
      params: {customerId, fileId},
      method: 'GET',
      axiosOptions: {responseType: 'blob'},
    });
  },

  downloadPriceList(customerId: string) {
    return request({
      endpoint: ENDPOINTS.E_SHOP_FRONT_DOWNLOAD_PRICE_LIST,
      params: {customerId},
      method: 'GET',
      axiosOptions: {responseType: 'blob'},
    });
  },

  downloadPriceListCSV(customerId: string) {
    return request({
      endpoint: ENDPOINTS.E_SHOP_FRONT_DOWNLOAD_PRICE_LIST_CSV,
      params: {customerId},
      method: 'GET',
      axiosOptions: {responseType: 'blob'},
    });
  },

  getProductsWithoutFilter() {
    return request({
      endpoint: '/eshopfront/articles/read?sorted=true',
      method: 'GET',
    });
  },

  getCustomerProducts(customerId: string) {
    return request({
      endpoint: '/eshopfront/articles/read',
      method: 'GET',
      query: {
				customerId: customerId,
        smallAmountOfData: true
			},
    });
  },

  getProductsManufacturers() {
    return request({
      endpoint: '/eshopfront/manufacture/read',
      method: 'GET',
    });
  },

  editProduct(internalArticleId: string, internalProduct: InternalProduct) {
    const formData = new FormData();
	
    let internalProductUpdate = {
      articleName: internalProduct.articleName,
      categoryId: internalProduct.categoryId,
      categorySecondId: internalProduct.categorySecondId,
      categoryThirdId: internalProduct.categoryThirdId,
      description: internalProduct.description,
      manufacturer: internalProduct.manufacturer,
      inDeliveryOfMoreQuantity: internalProduct.inDeliveryOfMoreQuantity,
      noneStockProduct: !!internalProduct.noneStockProduct,
      customerNumbers: internalProduct.customerNumbers,
      soldOut: !!internalProduct.soldOut,
      maxOrderQuantity: internalProduct.maxOrderQuantity,
      productCustomers: internalProduct.productCustomers,
      productReserved:internalProduct.productReserved,
      associatedWithEmobilityPartner: internalProduct.associatedWithEmobilityPartner
    } as InternalProduct;
	
    const internalProductUpdateBlob = new Blob(
      [JSON.stringify(internalProductUpdate)],
      {
        type: 'application/json',
      }
    );

    formData.append('data', internalProductUpdateBlob);

    internalProduct.documents.forEach((p) => {
      if (p[0] !== null && p[0] !== undefined) {
        const blob = p[0] as Blob;
        formData.append('document', blob, p[0].name);
      }
    });

    if (internalProduct.image !== undefined) {
      const blob = internalProduct.image as Blob;
      formData.append('image', blob, 'profile');
    }

    return axiosInstance.put(
      generatePath(ENDPOINTS.ESHOP_BACK_EDIT_PRODUCT, {
        internalArticleId: internalArticleId,
      }),
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  },
};

export default methods
