import { useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { connect, ConnectedProps } from "react-redux";
import { AppState } from "../../../store"
import { getOffer, resetOffer, updateOffer, copyOffer } from "../../../store/offers";
import { getCustomerProducts, resetCustomerProducts } from "../../../store/products";
import OfferToolForm, { OfferToolFormProps } from "./OfferToolForm/OfferToolForm";
import offerService from '../../../services/offers';
import { download } from "../../../lib/request";
import { downloadPdfFromUrl, resolveOfferPayload, validateOfferStatusEditable } from "./common";
import { useHistory, useParams } from "react-router-dom";
import { getCustomer } from "../../../store/companies";
// import SelectProject from "./OfferToolForm/SelectProject";
import { getUser } from '../../../store/users';
import { User, UserType } from '../../../store/users/types';
import { toggleToast } from '../../../store/components'
import { useTranslation } from "react-i18next";
import { setShowLoader } from "../../../store/system";
import { useGetCountries } from "../../../components/Form/SelectCountriesInput";
import { CopyOffer, Offer, OfferStatus } from "../../../store/offers/types";
import { PATH_OFFERS_OFFER_TOOL_EDIT } from "../../../router/paths";
import companiesService from "../../../services/companies";
import { Option } from "../../../components/Form/SelectField"

const connector = connect(
	(state: AppState) => ({
		customer: state.companies.selected,
		offer: state.offers.offer,
		sessionUser: state.system.sessionUser,
		userType: state.system.sessionUserType,
		customerproducts: state.products.customerproducts,
	}),
	{ updateOffer, getOffer, getCustomer, getUser, toggleToast, resetCustomerProducts, getCustomerProducts, setShowLoader, resetOffer, copyOffer }
);

type EditOfferProps = ConnectedProps<typeof connector> & {};

const EditOffer = ({
	offer,
	getOffer,
	updateOffer,
	getUser,
	customer,
	sessionUser,
	getCustomer,
	getCustomerProducts,
	userType,
	toggleToast,
	resetCustomerProducts,
	customerproducts,
	setShowLoader,
	resetOffer,
	copyOffer,
}: EditOfferProps) => {
	const { offerId } = useParams<{ offerId: string }>();
	const { t } = useTranslation()
	const history = useHistory();

	useEffect(() => { getOffer(offerId)}, [getOffer, offerId])
	
	useEffect(() => {
		if(offer.data?.customerId === undefined) return
		getCustomer(offer.data.customerId) 
	}, [getCustomer, offer])
	
	useEffect(() => {
		if(customer.data?.InternalCustomer.id === undefined) return
		
		if(customer.data?.InternalCustomer.id && !customerproducts.loading){
			let customerId = customer.data?.InternalCustomer.id
			getCustomerProducts({customerId})
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[customer])

	useEffect(() => { return () => {
		resetCustomerProducts()
		resetOffer()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	} }, [])

	const [deliveryOptions, setDeliveryOptions] = useState<Option[]>([])

	useEffect(() => {
		companiesService.getWayOfDeliveries().then((wayOfDeliveries) => {
			const { data } = wayOfDeliveries;
			let wayOfDeliveriesOptionsList: Option[] = [];
			data.WayOfDeliveries.forEach((wayOfDeliveries: any) => {
				wayOfDeliveriesOptionsList.push({ label: wayOfDeliveries.Description, value: `${wayOfDeliveries.Code}` })
			})
			setDeliveryOptions(wayOfDeliveriesOptionsList)
		});
	}, [])

	const countries = useGetCountries();

	const [imageChange, setimageChange] = useState<boolean>(false)


	const CountryOptions = useMemo(
        () => countries.countries,
        [countries.countries]
    );

	const handler = useForm()
	const initial = useRef(true)
	useEffect(() => {
		if(offer.loading || countries.isLoading || !offer.data || !initial.current || !customer.data) return

		handler.reset({
			...offer.data,
			companyLogo: null,
			expirationDate: new Date(offer.data.expirationDate),
			ourReference: offer.data.ourReference,
			yourReference: offer.data.yourReference,
			offerId: offer.data.id,
			customer: { value: customer.data.InternalCustomer, label: customer.data.Customer.Name },
			deliveryCountryCode: CountryOptions.find(({ value }) => value === offer.data?.deliveryCountryCode),
			proposedDeliveryDate: new Date(offer.data.proposedDeliveryDate),
			wayOfDeliveryCode: deliveryOptions?.find(({ value }) => value === offer.data?.wayOfDeliveryCode),
			sendEmail: offer.data.sendEmail,
			comment: offer.data.comment
		})
		initial.current = false
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [customer.data, handler, offer, CountryOptions, deliveryOptions])

	const [ user, setuser ] = useState<User>(sessionUser as User)

	useEffect(() => { 
		if(sessionUser === null) return
		
		let usr = sessionUser as User
		getUser(usr.id).then(result => {
			setuser(result.payload as User)
		})
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getUser, user.id, toggleToast])

	const showLoader = (customerproducts.loading || offer.loading || countries.isLoading);

	useEffect(() => {
        setShowLoader(showLoader);
    }, [showLoader, setShowLoader]);

	const handleSubmit: OfferToolFormProps['onSubmit'] = (fieldValues, type, userId) => {
		if(!offer.data || !sessionUser) return
		let feedback = true;
		const data = resolveOfferPayload({
			sessionUser,
			customerId: offer.data.customerId,
			fieldValues,
			imagechanged: imageChange,
			OfferId: offer.data?.id,
		})
		
		type === 'copy' && userId !== undefined  && copyOffer({OfferId: data.id, userId: userId} as CopyOffer).then((e) => {
			let newOffer = e.payload as Offer;
			history.push(PATH_OFFERS_OFFER_TOOL_EDIT.replace(':offerId',newOffer.id+''))
		})

		type === 'accept' && updateOffer({ 
			offerId,
			payload: { ...data, status: 'LOCKED' },
			callback: v => {
				toggleToast({
					shouldToggle: true,
					message: v.message,
					status: v.status,
				})
				feedback = false
			}
		}).then(() => {
			if(feedback){
				let msg = t('common.Saved')
				toggleToast({
					shouldToggle: true,
					message: msg,
					status: 200,
				})
			}
			feedback = false;
			getOffer(offerId)
		})

		type === 'save' && updateOffer({ 
			offerId,
			payload: data,
			callback: v => {
				toggleToast({
					shouldToggle: true,
					message: v.message,
					status: v.status,
				})
				feedback = false
			}
		}).then(() => {
			if(feedback){
				let msg = t('common.Saved')
				toggleToast({
					shouldToggle: true,
					message: msg,
					status: 200,
				})
			}
			feedback = false;
			getOffer(offerId)
		})
		
		type === 'preview' && offerService.generatePdf({...data, status: offer.data.status }).then(download)
		type === 'download' && downloadPdfFromUrl(offer.data.offerPdfFile.publicLink, offer.data.offerPdfFile.originalName)

		type === 'convertToOrder' && offerService.createOrderFromOffer(offerId).then(data => {
			toggleToast({
				shouldToggle: true,
				message: `Order har skapats från offert. Ny order har id: ${data.data}`,
				status: data.status
			});

			getOffer(offerId);
		});
	}

	return (
			<OfferToolForm 
				isEdit={validateOfferStatusEditable(offer.data?.status)}
				handler={handler} 
				onSubmit={handleSubmit} 
				defaultOfferProducts={offer.data?.offerProducts}
				actionDisabled={!offer.data || !sessionUser}
				userType={userType??undefined}
				otherCost1={offer.data?.otherCost}
				otherCost2={offer.data?.otherCost2}
				otherCost3={offer.data?.otherCost3}
				discountCost={offer.data?.discountCost}
				discountCost2={offer.data?.discountCost2}
				discountCost3={offer.data?.discountCost3}
				transportCost={offer.data?.transportCost}
				surcharge={offer.data?.surcharge}
				totalExTaxCostAdjusted={offer.data?.totalExTaxCostAdjusted}
				projectId={offer.data?.id}
				user={user}
				countries={CountryOptions}
				companyLogoInformation={offer.data?.companyLogo}
				onImageChange={(e) => setimageChange(e)}
				currency={offer.data?.offerCurrency}
				deliveryOptions={deliveryOptions}
			>
				<div className="form-row mb-2" style={{ gap: '1rem' }}>
					<div>
						{sessionUser?.userPermission.userType !== UserType.MainAccount && sessionUser?.userPermission.userType !== UserType.SubAccount &&
							(
								<>
								<div>
									<label>
										{t('offer.Customer')}:
									</label>
									<div className="card p-2" style={{minHeight: "44px", minWidth: "300px"}}>
										<p style={{color: "#ef7b10"}} className="m-0">
											{offer?.data?.deliveryCustomerName} {offer?.data?.deliveryEmail ? " - " + offer?.data?.deliveryEmail: ""}
										</p>
									</div>
								</div>
							</>
							)
						}
					</div>
					{/* <SelectProject handler={handler} customerId={customer.data?.id} /> NOTE!! Customer has desided to inactivate solconfiguratorn */}
				</div>
			</OfferToolForm>
	)
}

export default connector(EditOffer) 