import React, {useEffect, useState} from 'react';
import { connect, ConnectedProps } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory, generatePath } from 'react-router-dom'
import './ManageProducts.scss'
import { AppState } from '../../store'
import { deleteProduct, getProductsIncremental } from '../../store/products'
import { PATH_E_SHOP_CREATE_PRODUCT, PATH_E_SHOP_EDIT_PRODUCT } from '../../router/paths'
import Table from '../../components/Table'
import Pager from '../../components/Pager'
import ManageEShopTabNav from '../../components/EShop/ManageEShopTabNav'
import DropdownButton from '../../components/DropdownButton'
import usePaging from '../../hooks/usePaging'
import Fade from '@material-ui/core/Fade/Fade'
import ProgressSpinnerBox from '../../components/ProgressSpinnerBox/ProgressSpinnerBox'
import NonIdealState from '../../components/NonIdealState/NonIdealState'
import { Search } from '@material-ui/icons'
import DialogWarning from '../../components/DialogWarning/DialogWarning'
import priceFormatter from '../../lib/priceFormatter'
import {setShowLoader} from '../../store/system';

const connector = connect(
	(state: AppState) => ({
		products: state.products.list,
	}),
	{ getProductsIncremental, deleteProduct, setShowLoader }
)

type ManageProductsProps = ConnectedProps<typeof connector> & {}

export function ManageProducts({
	products,
	getProductsIncremental,
	deleteProduct,
	setShowLoader
}: ManageProductsProps) {
	const history = useHistory()
	const { t } = useTranslation()
	const {
		page, onPagination, searchTerm, setSearchTerm, triggerRefresh, hasFetched
	} = usePaging({ 
		getItems: props => getProductsIncremental({ listMeta: props, previousProducts: [] }), 
		limit: 10
	})

	const [ productIdToDelete, setProductIdToDelete ] = useState<string | undefined>()

	const handleDeleteProduct = async () => { 
		productIdToDelete && await deleteProduct(productIdToDelete)
		triggerRefresh()
	}

	const editProduct = (productId: string) => () => history.push(generatePath(PATH_E_SHOP_EDIT_PRODUCT, { productId: productId }))
	
	const createNewProduct = () => history.push(PATH_E_SHOP_CREATE_PRODUCT)


	const isLoading = products.loading || !hasFetched;

	useEffect(() => {
		setShowLoader(isLoading);
	}, [isLoading, setShowLoader]);
	
	return (
		<div>
			<h1 data-testid="e-shop_product_management_headline">
				{t('navigation.E-shop__nav.Manage e-shop')}
			</h1>
			<Fade in={!isLoading && !!products.data.length}>
				<>
					<ManageEShopTabNav />
					<Table
						loading={products.loading}
						newButton={{
							onClick: createNewProduct,
							text: t('common.Create new product'),
						}}
						searchField={{
							name: 'productSearch',
							text: t('common.Search product'),
							onChange: (e: any) => setSearchTerm(e.target.value),
							value: searchTerm,
						}}
						header={[
							t('common.Picture'),
							t('common.Article number'),
							t('common.Product name'),
							t('common.Manufacturer'),
							t('common.Category'),
							t('common.Price'),
							{
								key: 'search',
								component: (      
									<Pager
										page={page}
										className="text-center"
										totalItems={products.total}
										onPagination={onPagination}
									/>
								),
							},
						]}
						row={(row) => (
							<>
								<td><img className="img manageProducts__imgSmall" src={row.product.productInformation.images?.length > 0 ? 
									row.product.productInformation.images[0].publicThumbnailLink : ""} alt="" /></td>
								<td>{row.product.fnArticleId}</td>
								<td>{row.product.productInformation.name}</td>
								<td>{row.product.productInformation.manufacturer}</td>
								<td>{row.product.productInformation.categoryNames.categoryName}</td>
								<td>{priceFormatter(row.product.productInformation.articlePrice.currency ?? 'SEK')(row.product.productInformation.articlePrice.displayPrice)}</td>
								<td className="text-center">
								<DropdownButton
									id={`user__option__dropdown_${row.product.id}`}
									label={t('common.Options')}
									dropdownList={[
									{ text: t('common.Edit'), onClick: editProduct(row.product.id) },
									{ text: t('common.Delete'), className: 'text-danger', onClick: () => setProductIdToDelete(row.product.id)},
									]}
								/>
								</td>
							</>
						)}
						list={products.data}
						extractKey={(el) => el.product.id}
					/>
				</>
			</Fade>
			<Fade in={(!isLoading && !products.data.length) || isLoading}>
				{	isLoading ? ProgressSpinnerBox()
					: 
						<NonIdealState
							icon={<Search style={{ fontSize: 60, opacity: .6 }} />}
							title="Inga sökresultat"
							description={
								<>
									<span>Dina sökning matchade inte något order.</span>
									<span>Försök att söka efter något annat.</span>
								</>
							}
							action={
								<button
									className="btn btn-primary btn-md mt-2"
									onClick={() => setSearchTerm('')}
									disabled={isLoading}
								>
									Rensa sökning
								</button>
							}
						/>
				}
			</Fade>
			<DialogWarning
				open={!!productIdToDelete}
				subject="Radera"
				title={t('common.Delete product')}
				content={t('common.Are you sure you want to delete the selected product?')}
				onConfirm={handleDeleteProduct}
				onClose={() => setProductIdToDelete(undefined)}
			/>
		</div>
	)
}

export default connector(ManageProducts)
