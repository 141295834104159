import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';


import { Module } from '../../store/users/types';

import { DEFAULT_RULES } from '../../lib/form';
import { useToggleAllCheckboxes } from '../../lib/hooks';

import InputField from '../Form/InputField';
import SelectField, { Option } from '../Form/SelectField';
import ToggleField from '../Form/ToggleField';
import { InlineCheckbox } from '../Form/InlineCheckInput';
import { Customer, CustomerDetails, customerRestriction, InternalCustomerInformation } from "../../store/companies/types";
import { EntityData, List } from "../../store/types";
import companiesService from "../../services/companies";
import { PriceGroup } from "../../store/priceGroups/types";
import { useGetCountries } from '../Form/SelectCountriesInput';
import TextField from '@material-ui/core/TextField/TextField';
import { FormContextValues } from 'react-hook-form/dist/contextTypes';
import ControlledAutocomplete from '../ControlledAutocomplete/ControlledAutocomplete';
import DatePicker from '../Form/DatePicker';
import { ProductCategory } from '../../store/productCategories/types';
import Icon from '@mdi/react';
import { mdiPlus } from '@mdi/js';
import { Chip, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';


export interface  CompanyFormInput  {
    Customer: CustomerDetails
    InternalCustomer: InternalCustomerInformation
	ourReferenceEmail: string
	ourReferencePhoneNumber: string
	yourReferenceEmail: string
	yourReferencePhoneNumber: string
    CustomerRestriction: customerRestriction
};
export interface CompanyFormProps {
    companyData?: EntityData<Customer>
    priceGroupsList?: List<PriceGroup>
    handleInput: FormContextValues<CompanyFormInput>
    readOnly?: boolean
    isEdit?: boolean
    showWarningModal?: (a: boolean) => void;
    categoriesData?: ProductCategory[] | null;
};

const formColumnClass = 'col-md-12 col-lg-6';


const CompanyForm: React.FC<CompanyFormProps> = ({ 
    handleInput,
    readOnly, 
    companyData, 
    isEdit, 
    showWarningModal, 
    priceGroupsList,
    categoriesData

}) => {
    const { t } = useTranslation();
	const  {countries: countryOptions} = useGetCountries()

    const { reset, watch } = handleInput;
    const [projectsList, setProjectsList] = useState<Option[]>([]);
    const [costCentersList, setCostCentersList] = useState<Option[]>([]);
    const [priceList, setPriceList] = useState<Option[]>([]);
    const [priceGroups, setPriceGroups] = useState<Option[]>([]);
    const [salesAccounts, setSalesAccounts] = useState<Option[] | undefined>(undefined);
    const [currencies, setCurrencies] = useState<Option[]>([]);
    // const [orderLimits, setOrderLimits] = useState<Option[]>([]);
    const [wayOfDeliveries, setWayOfDeliveries] = useState<Option[]>([]);
    const [termsOfDeliveries, setTermsOfDeliveries] = useState<Option[]>([]);
    const [termsOfPayments, setTermsOfPayments] = useState<Option[]>([]);
    const [categoryOptions, setCategoryOptions] = useState<Option[]>([]);
    const [referenceCategoryOptions, setReferenceCategoryOptions] = useState<Option[]>([]);
    const [chosenCategories, setChosenCategories] = useState<Option[]>([]);
    const [category, setCategory] = useState<Option>();

    const modulesOptions = useMemo(
        () => [
            {
                value: Module.MATERIAL_CALCULATOR,
                label: t('navigation.Material calculator'),
            },
            {
                value: Module.OFFER_GENERATION,
                label: t('navigation.Offer management__nav.Create offer'),
            },
        ],
        [t]
    );
    const orderLimitOptions = useMemo(
        () => [
            { label: '10000', value: '10000' },
            { label: '50000', value: '50000' },
            { label: '100000', value: '100000' },
            { label: '500000', value: '500000' },
            { label: '1000000', value: '1000000' },
        ],
        []
    );

    const taxTypesOptions = useMemo(
        () => [
            { label: t('common.SE'), value: 'SEVAT' },
            { label: t('common.SEE reverse tax liability'), value: 'SEREVERSEDVAT' },
            { label: t('common.EU reverse tax liability'), value: 'EUREVERSEDVAT' },
            { label: t('common.EU subject to VAT'), value: 'EUVAT' },
            { label: t('common.Export'), value: 'EXPORT' },
        ],
        [t]
    );

    const projectsOptions = useMemo(
        () => projectsList,
        [projectsList]
    );

    const costCentersOptions = useMemo(
        () => costCentersList,
        [costCentersList]
    );

    const priceListOptions = useMemo(
        () => priceList,
        [priceList]
    );

    const priceGroupOptions = useMemo(
        () => priceGroups,
        [priceGroups]
    );
    const salesAccountsOptions = useMemo(
        () => salesAccounts,
        [salesAccounts]
    );
    const currenciesOptions = useMemo(
        () => currencies,
        [currencies]
    );

    const termsOfDeliveryOptions = useMemo(
        () => termsOfDeliveries,
        [termsOfDeliveries]
    );

    const termsOfPaymentOptions = useMemo(
        () => termsOfPayments,
        [termsOfPayments]
    );

    const wayOfDeliveryOptions = useMemo(
        () => wayOfDeliveries,
        [wayOfDeliveries]
    );


    useEffect(() => {
        try {
            companiesService.getCompanyProjects().then((companyProjects) => {
                const { data } = companyProjects;
                let ProjectsOptionsList: Option[] = [];
                data.Projects.forEach((project: any) => {
                    ProjectsOptionsList.push({ label: project.Description, value: `${project.ProjectNumber}` })
                })
                setProjectsList(ProjectsOptionsList)
            })
            companiesService.getCompanySalesAccounts().then((salesAccounts) => {
                const { data } = salesAccounts;
                let salesAccountsOptionsList: Option[] = [];
                data.Accounts.forEach((account: any) => {
                    salesAccountsOptionsList.push({ label: `${account.Number} - ${account.Description}`, value: `${account.Number}` })
                })
                setSalesAccounts(salesAccountsOptionsList);
            })
            companiesService.getCompanyCostCenters().then((costCenters) => {
                const { data } = costCenters;
                let CostCentersOptionsList: Option[] = [];
                data.CostCenters.forEach((CostCenter: any) => {
                    CostCentersOptionsList.push({ label: CostCenter.Description, value: `${CostCenter.Code}` })
                })
                setCostCentersList(CostCentersOptionsList)
            })

            companiesService.getCompanyPriceList().then((priceList) => {
                const { data } = priceList;
                let PriceListOptionsList: Option[] = [];
                data.PriceLists.forEach((price: any) => {
                    PriceListOptionsList.push({ label: price.Description, value: `${price.Code}` })
                })
                setPriceList(PriceListOptionsList)
            })

            companiesService.getCompanyCurrencies().then((currencies) => {
                const { data } = currencies;
                let CurrenciesOptionsList: Option[] = [];
                data.Currencies.forEach((currency: any) => {
                    CurrenciesOptionsList.push({ label: currency.Description, value: `${currency.Code}` })
                })
                setCurrencies(CurrenciesOptionsList)
            })

            companiesService.getTermsOfDeliveries().then((termsOfDeliveries) => {
                const { data } = termsOfDeliveries;
                let termsOfDeliveryOptionsList: Option[] = [];
                data.TermsOfDeliveries.forEach((termsOfDelivery: any) => {
                    termsOfDeliveryOptionsList.push({ label: termsOfDelivery.Description, value: `${termsOfDelivery.Code}` })
                })
                setTermsOfDeliveries(termsOfDeliveryOptionsList)
            })

            companiesService.getTermsOfPayment().then((termsOfPayments) => {
                const { data } = termsOfPayments;
                let termsOfPaymentsOptionsList: Option[] = [];
                data.TermsOfPayments.forEach((termsOfPayment: any) => {
                    termsOfPaymentsOptionsList.push({ label: termsOfPayment.Description, value: `${termsOfPayment.Code}` })
                })
                setTermsOfPayments(termsOfPaymentsOptionsList)
            })

            companiesService.getWayOfDeliveries().then((wayOfDeliveries) => {
                const { data } = wayOfDeliveries;
                let wayOfDeliveriesOptionsList: Option[] = [];
                data.WayOfDeliveries.forEach((wayOfDeliveries: any) => {
                    wayOfDeliveriesOptionsList.push({ label: wayOfDeliveries.Description, value: `${wayOfDeliveries.Code}` })
                })
                setWayOfDeliveries(wayOfDeliveriesOptionsList)
            })

        } catch (error) {
            console.error(error);
        }
    }, []);

    useEffect(() => {
        const categoryOptions = categoriesData?.map((c) => ({
          label: c.categoryName,
          value: c.id,
        }));
    
        categoryOptions && setCategoryOptions(categoryOptions);
        categoryOptions && setReferenceCategoryOptions(categoryOptions)
      }, [
        categoriesData,
        setCategoryOptions,
        setReferenceCategoryOptions
      ]);

    useEffect(() => {
		if (!priceGroupsList?.data.length) return
		const PriceGroupOptionsList = priceGroupsList.data.map(priceGroup => ({ label: priceGroup.name, value: priceGroup.id }))
		PriceGroupOptionsList.unshift({ label: t('common.None selected'), value: '' })
		setPriceGroups(PriceGroupOptionsList)
    }, [priceGroupsList]);

	const initial = useRef(true)
    useEffect(() => {
		if (companyData && companyData.InternalCustomer && companyData.Customer && initial.current) {
            const priceGroupId = companyData.InternalCustomer.customerPriceGroup?.priceGroupId ? companyData.InternalCustomer.customerPriceGroup.priceGroupId : '';
            const selectedCountry = countryOptions.find(({ value }) => value === companyData.Customer.CountryCode);
            const TermsOfPayment = termsOfPaymentOptions.find(({ value }) => value === companyData.Customer.TermsOfPayment);
            const TermsOfDelivery = termsOfDeliveryOptions.find(({ value }) => value === companyData.Customer.TermsOfDelivery);
            const WayOfDelivery = wayOfDeliveryOptions.find(({ value }) => value === companyData.Customer.WayOfDelivery);
            const TaxTypes = taxTypesOptions.find(({ value }) => value === companyData.Customer.VATType);
            const Project = projectsOptions.find(({ value }) => value === companyData.Customer.Project);
            const SalesAccount = salesAccounts?.find(({ value }) => value === companyData.Customer.SalesAccount)
            const CostCenter = costCentersOptions.find(({ value }) => value === companyData.Customer.CostCenter);
            const PriceList = priceListOptions.find(({ value }) => value === companyData.Customer.PriceList);
            const orderLimit = orderLimitOptions.find(({ value }) => parseInt(value) === companyData.InternalCustomer.customerPayment?.orderLimit);
            const priceGroup = priceGroupOptions.find(({ value }) => value === priceGroupId);
            const Currency = currenciesOptions.find(({ value }) => value === companyData.Customer.Currency);
            const ReferenceCategory = categoryOptions.find(({ value }) => value === companyData?.InternalCustomer?.customerRestriction?.referenceCategory);
            const { accessModules } = companyData.InternalCustomer.customerPermission;
            let accessModulesList = []
            if (accessModules) {
                accessModulesList = accessModules.map((module: { id: string, module: string }) => module.module)
            }
            handleInput.setValue("CustomerRestriction.categories", chosenCategories)
            reset({
                Customer: {
                    ...companyData?.Customer,
                    Country: selectedCountry,
                    TermsOfPayment: TermsOfPayment,
                    TermsOfDelivery: TermsOfDelivery,
                    WayOfDelivery: WayOfDelivery,
                    VATType: TaxTypes,
                    Project: Project,
                    CostCenter: CostCenter,
                    SalesAccount: SalesAccount,
                    PriceList: PriceList,
                    Currency: Currency
                },
                InternalCustomer: {
                    customerPayment: {
                        orderLimit: orderLimit ?? ''
                    },
                    customerPermission: {
                        accessModules: accessModulesList ?? ''
                    },
                    customerPriceGroup: {
                        priceGroupId: priceGroup ?? ''
                    },
                    customerRestriction: {
                        active: companyData?.InternalCustomer?.customerRestriction?.active,
                        minAmountPanels: companyData?.InternalCustomer?.customerRestriction?.minAmountPanels,
                        preliminaryDeliveryDate: typeof companyData?.InternalCustomer?.customerRestriction?.preliminaryDeliveryDate === "string" ?
                                                    new Date(companyData.InternalCustomer.customerRestriction.preliminaryDeliveryDate)
                                                        : undefined,
                        categories: companyData?.InternalCustomer?.customerRestriction?.categories,
                        referenceCategory: companyData?.InternalCustomer?.customerRestriction?.referenceCategory,
                        restrictionComment: companyData?.InternalCustomer?.customerRestriction?.restrictionComment,
                    },
                },
				ourReferenceEmail: companyData?.InternalCustomer.ourReferenceEmail ?? '',
				ourReferencePhoneNumber: companyData?.InternalCustomer.ourReferencePhoneNumber ?? '',
				yourReferenceEmail: companyData?.InternalCustomer.yourReferenceEmail ?? '',
				yourReferencePhoneNumber: companyData?.InternalCustomer.yourReferencePhoneNumber ?? '',
                CustomerRestriction: {
                    referenceCategory: ReferenceCategory,
                    categories: chosenCategories,
                },
            })
        }
    }, [
		costCentersOptions, priceListOptions, taxTypesOptions, termsOfDeliveryOptions, termsOfPaymentOptions, wayOfDeliveryOptions, 
		companyData, countryOptions, orderLimitOptions, reset, projectsOptions, priceGroupsList, salesAccounts, priceGroupOptions, currenciesOptions
	]);

	useEffect(() => {
        const categories = companyData?.InternalCustomer?.customerRestriction?.categories;

        if (!categories) return;
        if (!categoryOptions || categoryOptions.length === 0) return;

        setChosenCategories(categories.map((value) => {
            const category = categoryOptions.find(c => c.value === value) as Option ?? {label: '', value: ''}
            return {label: category.label, value: category.value} as Option
        }))
    }, [companyData?.InternalCustomer.customerRestriction, categoryOptions])

    const { allCheckboxes: allModules } = useToggleAllCheckboxes(
        'allModules',
        'InternalCustomer.customerPermission.accessModules',
        handleInput,
        modulesOptions
    );

    const watchStatus = watch("Customer.Active");

    const handleStatusChange = () => {
        if (watchStatus && showWarningModal) {
            showWarningModal(true);
        }
    }

    useEffect(() => {
        handleInput.setValue("CustomerRestriction.categories", chosenCategories)
      }, [chosenCategories.length, chosenCategories])

    const restrictionStatus = watch("InternalCustomer.customerRestriction.active");

    const handleRestricitonChange = () => {
        if (restrictionStatus) {
            setChosenCategories([]);
        }
    }
	
    const addCategoriesList = () => {
        if (category === undefined || category === null) return;
    
        if (chosenCategories === null || chosenCategories.length === 0) {
          let tempArray = [];
          tempArray.push(category);
          setChosenCategories(tempArray);
        } else if (chosenCategories.length > 0 && !chosenCategories.find(({value}) => value === category.value)) {
          let tempArray = chosenCategories;
          tempArray.push(category);
          setChosenCategories(tempArray);
        }
        setCategory(undefined);
      };
    
      const deleteCategoriesList = (value: Option) => {
        let oldArray = chosenCategories.slice();
        var index = oldArray.indexOf(value);
        if (index !== -1) {
          oldArray.splice(index, 1);
        }
        setChosenCategories(oldArray);
      };

    return (
        <>
            <legend className="mb-1" data-testid="form__company_form">
                {t('common.Company information')}
            </legend>
            {
                companyData?.Customer?.CustomerNumber &&
                <p className="mb-5">
                    {t('common.Customer number')}: {companyData?.Customer?.CustomerNumber}
                </p>
            }

            <div className="form-row">
                <InputField
                    name="Customer.Name"
                    handler={handleInput}
                    label={t('common.Name')}
                    className={formColumnClass}
                    rules={{ required: true }}
                    readOnly={readOnly}
                />
                <InputField
                    name="Customer.OrganisationNumber"
                    handler={handleInput}
                    label={t('common.Organization number')}
                    className={formColumnClass}
                    rules={{ required: true }}
                    readOnly={readOnly}
                />
                <InputField
                    name="Customer.Address1"
                    handler={handleInput}
                    label={t('common.Address')}
                    placeholder={t('common.Address line 1')}
                    className={formColumnClass}
                    rules={{ required: true }}
                    readOnly={readOnly}
                />
                <InputField
                    name="Customer.Address2"
                    handler={handleInput}
                    label={t('common.Address') + ' 2'}
                    placeholder={t('common.Address line 2')}
                    className={formColumnClass}
                    readOnly={readOnly}
                    rules={{ required: false }}
                />
                <InputField
                    name="Customer.ZipCode"
                    handler={handleInput}
                    label={t('common.Zip code / city')}
                    placeholder={t('common.Zip c')}
                    className={'col-md-2 col-lg-2'}
                    rules={{ required: true }}
                    readOnly={readOnly}
                />
                <InputField
                    name="Customer.City"
                    handler={handleInput}
                    label={''}
                    placeholder={t('common.City')}
                    className={'col-md-10 col-lg-4'}
                    rules={{ required: true }}
                    readOnly={readOnly}
                />

                <SelectField
                    name="Customer.Country"
                    handler={handleInput}
                    readOnly={readOnly}
                    isSearchable={true}
                    rules={{ required: false }}
                    label={t('common.Country')}
                    className={formColumnClass}
                    options={countryOptions}
                />
                <InputField
                    name="Customer.Phone1"
                    handler={handleInput}
                    label={t('common.Phone number 1')}
                    placeholder={t('common.Phone number 1')}
                    className={formColumnClass}
                    rules={{ required: true }}
                    readOnly={readOnly}
                />
                <InputField
                    name="Customer.Phone2"
                    handler={handleInput}
                    label={t('common.Phone number 2')}
                    placeholder={t('common.Phone number 2')}
                    className={formColumnClass}
                    readOnly={readOnly}
                    rules={{ required: false }}
                />
                <InputField
                    name="Customer.Email"
                    handler={handleInput}
                    label={t('common.E-mail address')}
                    className={formColumnClass}
                    readOnly={readOnly}
                    rules={{
                        required: false,
                        minLength: DEFAULT_RULES.email.minLength,
                        pattern: DEFAULT_RULES.email.pattern,
                    }}
                />
                <InputField
                    name="Customer.EmailInvoice"
                    handler={handleInput}
                    label={t('common.E-mail address invoice')}
                    className={formColumnClass}
                    readOnly={readOnly}
                    rules={{
                        required: false,
                        minLength: DEFAULT_RULES.email.minLength,
                        pattern: DEFAULT_RULES.email.pattern,
                    }}
                />
                <InputField
                    name="Customer.WWW"
                    handler={handleInput}
                    label={t('common.Web address')}
                    className={formColumnClass}
                    readOnly={readOnly}
                    rules={{ required: false }}
                />

                {isEdit && <ToggleField
                    switchText={[t('common.Inactive'), t('common.Active')]}
                    name="Customer.Active"
                    handler={handleInput}
                    label={'Status'}
                    className={formColumnClass}
                    readOnly={readOnly}
                    onChange={handleStatusChange}
                />
                }
            </div>

            <hr className="form-delimiter" />
            <legend>{t('common.Payment and delivery terms')}</legend>
            <div className="form-row">
                
                <SelectField
                    name="Customer.TermsOfPayment"
                    handler={handleInput}
                    readOnly={readOnly}
                    rules={{ required: false }}
                    label={t('common.Terms of payment')}
                    className={formColumnClass}
                    options={termsOfPaymentOptions}
                />

                {isEdit && <SelectField
                    name="InternalCustomer.customerPayment.orderLimit"
                    handler={handleInput}
                    readOnly={readOnly}
                    rules={{ required: false }}
                    label={t('common.Order value limit')}
                    className={formColumnClass}
                    options={orderLimitOptions}
                />}

                <SelectField
                    name="Customer.TermsOfDelivery"
                    handler={handleInput}
                    readOnly={readOnly}
                    rules={{ required: false }}
                    label={t('common.Terms delivery')}
                    className={formColumnClass}
                    options={termsOfDeliveryOptions}
                />
                <SelectField
                    name="Customer.WayOfDelivery"
                    handler={handleInput}
                    readOnly={readOnly}
                    rules={{ required: false }}
                    label={t('common.Delivery method')}
                    className={formColumnClass}
                    options={wayOfDeliveryOptions}
                />
            </div>

            <hr className="form-delimiter" />
            <legend>{t('common.Billing')}</legend>
            <div className="form-row">
                <SelectField
                    name="Customer.PriceList"
                    handler={handleInput}
                    readOnly={readOnly}
                    rules={{ required: false }}
                    label={t('common.Price list')}
                    className={formColumnClass}
                    options={priceListOptions}
                />
                <SelectField
                    name="Customer.Currency"
                    handler={handleInput}
                    readOnly={readOnly}
                    rules={{ required: false }}
                    label={t('common.Currency')}
                    className={formColumnClass}
                    options={currenciesOptions}
                />
                <InputField
                    name="Customer.InvoiceDiscount"
                    handler={handleInput}
                    label={t('common.Invoice discount (%)')}
                    className={formColumnClass}
                    readOnly={readOnly}
                    rules={{ required: false }}
                />
                <InputField
                    name="Customer.InvoiceAdministrationFee"
                    handler={handleInput}
                    label={t('common.Invoice fee')}
                    className={formColumnClass}
                    readOnly={readOnly}
                    rules={{ required: false }}
                />
                <InputField
                    name="Customer.InvoiceFreight"
                    handler={handleInput}
                    label={t('common.Freight charge')}
                    className={formColumnClass}
                    readOnly={readOnly}
                    rules={{ required: false }}
                />
                <ToggleField
                    switchText={[t('common.No'), t('common.Yes')]}
                    name="Customer.ShowPriceVATIncluded"
                    handler={handleInput}
                    label={t('common.Prices incl VAT')}
                    className={formColumnClass}
                    readOnly={readOnly}
                />
            </div>

            <hr className="form-delimiter" />
            <legend>{t('common.References')}</legend>
            <div className="form-row">
                <div className={formColumnClass}>
					<InputField
						name="Customer.OurReference"
						handler={handleInput}
						label={t('common.Our reference')}
						placeholder={t('common.First name, Last name')}
						className="col-md-12"
						readOnly={readOnly}
					/>
					<InputField
						name="ourReferenceEmail"
						handler={handleInput}
						label="E-postadress"
						className="col-md-12"
						readOnly={readOnly}
					/>
					<InputField
						name="ourReferencePhoneNumber"
						handler={handleInput}
						label="Telefonnummer"
						className="col-md-12"
						readOnly={readOnly}
					/>
				</div>
                <div className={formColumnClass}>
					<InputField
						name="Customer.YourReference"
						handler={handleInput}
						label={t('common.Your reference')}
						placeholder={t('common.First name, Last name')}
						className="col-md-12"
						readOnly={readOnly}
					/>
					<InputField
						name="yourReferenceEmail"
						handler={handleInput}
						label="E-postadress"
						placeholder="E-postadress kontaktperson"
						className="col-md-12"
						readOnly={readOnly}
					/>
					<InputField
						name="yourReferencePhoneNumber"
						handler={handleInput}
						label="Telefonnummer"
						placeholder="Telefonnummer kontaktperson"
						className="col-md-12"
						readOnly={readOnly}
					/>
				</div>
            </div>

            <hr className="form-delimiter" />
            <legend>{t('common.Accounting')}</legend>
            <div className="form-row">
                <InputField
                    name="Customer.VATNumber"
                    handler={handleInput}
                    label={t('common.VAT number')}
                    className={formColumnClass}
                    readOnly={readOnly}
                    rules={{ required: false }}
                />
                <SelectField
                    name="Customer.VATType"
                    handler={handleInput}
                    readOnly={readOnly}
                    rules={{ required: false }}
                    label={t('common.TAX types')}
                    className={formColumnClass}
                    options={taxTypesOptions}
                />
				<ControlledAutocomplete
					control={handleInput.control}
					name="Customer.SalesAccount"
					options={salesAccountsOptions}
					renderInput={(params) => 
						<TextField 
							{...params} 
							variant="outlined"
							size="small"
							disabled={readOnly}
							className={(params as any).className + ' form-control'}
						/>
					}
					readOnly={readOnly}
					className={formColumnClass}
					label={t('common.Sales account')}
				/>
                <SelectField
                    name="Customer.CostCenter"
                    handler={handleInput}
                    readOnly={readOnly}
                    rules={{ required: false }}
                    label={t('common.Cost center')}
                    className={formColumnClass}
                    options={costCentersOptions}
                />

                <SelectField
                    name="Customer.Project"
                    handler={handleInput}
                    readOnly={readOnly}
                    rules={{ required: false }}
                    label={t('common.Project (pr)')}
                    className={formColumnClass}
                    options={projectsOptions}
                />
            </div>

            {isEdit &&
                <>
                    <hr className="form-delimiter" />

                    <legend>{t('common.CustomerRestriction')}</legend>

                    <div className="form-row">
                        <ToggleField
                            switchText={[t('common.Active'), '']}
                            name="InternalCustomer.customerRestriction.active"
                            handler={handleInput}
                            label={t('common.Limitations')}
                            readOnly={readOnly}
                            className="col-auto"
                            singleMode
                            switchClassName="p-0"
                            onChange={handleRestricitonChange}
                        />
                    </div>
                    {handleInput.getValues("InternalCustomer.customerRestriction.active") &&
                        <div className="form-row">
                            <DatePicker
                                name="InternalCustomer.customerRestriction.preliminaryDeliveryDate"
                                label={t('common.PreliminaryDeliveryDate')}
                                placeholder={t('common.Delivery date')}
                                dateFormat="yyyy-MM-dd"
                                minDate={new Date()}
                                handler={handleInput}
                                className={formColumnClass}
                            />
                            <InputField
                                name="InternalCustomer.customerRestriction.minAmountPanels"
                                handler={handleInput}
                                label={t('common.MinAmountPanels')}
                                className={formColumnClass}
                                readOnly={readOnly}
                                rules={{ required: false }}
                                type="number"
                            />
                            <InputField
                                name="InternalCustomer.customerRestriction.restrictionComment"
                                handler={handleInput}
                                label={t('common.RestrictionComment')}
                                className={formColumnClass}
                                readOnly={readOnly}
                                rules={{ required: false }}
                            />
                            <SelectField
                                name="CustomerRestriction.referenceCategory"
                                handler={handleInput}
                                readOnly={readOnly}
                                rules={{ required: false }}
                                label={t('common.ReferenceCategory')}
                                className={formColumnClass}
                                options={referenceCategoryOptions}
                                isClearable={true}
                            />
                            {/* Hidden field for save value. SetValue dosen't work */}
                            <InputField
                                name="CustomerRestriction.categories"
                                handler={handleInput}
                                value={chosenCategories}
                                className="d-none"
                                readOnly={true}
                            />
                            <div className="col-12">
                                <label>{t('common.Categories')}</label>
                            </div>
                            <div className="col-md-6 col-lg-6">
                                <div className="input-group mb-3">
                                    <SelectField
                                        name="CustomerRestriction.categories"
                                        handler={handleInput}
                                        rules={{ required: false }}
                                        className={"form-control"}
                                        style={{ padding: '0', border: "none" }}
                                        options={categoryOptions}
                                        handleChange={([opt]: [Option, unknown]) => setCategory(opt)}
                                        placeholder={t('common.Categories')}
                                    />
                                    <button
                                        className="btn btn-primary btn-sm"
                                        type="button"
                                        id="button-addon2"
                                        style={{
                                            borderRadius: "0.625rem",
                                            marginLeft: "0.625rem",
                                            width: '3rem',
                                        }}
                                        onClick={() => addCategoriesList()}
                                    >
                                        <Icon path={mdiPlus} size="18" className="icon-12" />
                                    </button>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6">
                                {!!chosenCategories && chosenCategories.map((value, index) => (
                                    <Chip
                                        className="m-1 pr-3 selected-inverters-chip"
                                        label={value.label}
                                        key={index}
                                        variant="outlined"
                                        onDelete={() => deleteCategoriesList(value)}
                                        deleteIcon={
                                            <IconButton
                                                className="p-3"
                                                edge="end"
                                                aria-label="delete"
                                                size="small"
                                            >
                                                <Close fontSize="small" />
                                            </IconButton>
                                        }
                                    />
                                ))}
                            </div>
                        </div>
                    }
                </>
            }

            <hr className="form-delimiter" />

            <legend>{t('common.Customer permissions')}</legend>
            <div className="form-row">
                {/* toggling this with DevTools open causes memory leak, but
                    if it's closed everything is fine
                    Maybe it has something to do with React dev tools, dunno
                    but it's not real issue, if DevTools is closed it doesn't cause leak
                */}
                <ToggleField
                    switchText={[t('common.All rights'), '']}
                    name="allModules"
                    handler={handleInput}
                    label={t('common.Modules')}
                    readOnly={readOnly}
                    className="col-auto"
                    singleMode
                    switchClassName="p-0"
                />
                {/* sorry, had no better idea */}
                <div className="vertical-separator">
                    <div className="vertical-separator-line"></div>
                </div>
                <InlineCheckbox
                    handler={handleInput}
                    name="InternalCustomer.customerPermission.accessModules"
                    label=""
                    readOnly={readOnly || allModules}
                    className="col-auto"
                    options={modulesOptions}
                />
            </div>
        </>
    );
}
export default CompanyForm