import React from 'react';
import {useTranslation} from 'react-i18next';

import Modal from '.';

export default function ConfirmationModal({
  title,
  message,
  buttonMessage,
  modalVisible,
  setModalVisible,
  onConfirmation,
  onCancel
}: {
  title: string;
  message: string;
  buttonMessage: string;
  modalVisible: boolean;
  setModalVisible: (f: boolean) => void;
  onConfirmation: () => void;
  onCancel?: () => void;
}) {
  const {t} = useTranslation();

  return (
    <Modal
      name="confirmation"
      modalVisible={modalVisible}
      setModalVisible={setModalVisible}
      title={title}
      dialogClassName="modal-mw-small modal-dialog-centered"
    >
      <form>
        <legend>{message}</legend>
        <div className="float-right mt-7">
          <button
            type="button"
            className="btn btn-info mr-2"
            data-dismiss="modal"
            onClick={onCancel}
          >
            {t('common.Cancel')}
          </button>
          <button
            type="button"
            className="btn btn-danger"
            onClick={onConfirmation}
          >
            {buttonMessage}
          </button>
        </div>
      </form>
    </Modal>
  );
}
