import React, {useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {useForm} from 'react-hook-form';
import {updateUserInformation} from '../../store/users';
import {getActiveUser} from '../../store/system';

import {AppState} from '../../store';
import {setShowLoader} from '../../store/system';

import UserProfileForm, {UserProfileFormInput} from "../../components/Account/UserProfileForm";

const connector = connect((state: AppState) => ({
  sessionUser: state.system.sessionUser
}), {setShowLoader, updateUserInformation, getActiveUser});

type UserProfileProps = ConnectedProps<typeof connector> & {};

function UserProfile({setShowLoader, sessionUser, updateUserInformation, getActiveUser}: UserProfileProps) {

  const handleInput = useForm<UserProfileFormInput>({
    validateCriteriaMode: 'all',
    mode: 'onChange',
  });

  const {
    formState: {isSubmitting},
  } = handleInput;

  const onSubmit = async (inputs: UserProfileFormInput) => {
    if(sessionUser){
      const userId = sessionUser.id;
      await updateUserInformation({
        userId,
        payload: {
          notification:inputs.receiveNotification,
          phoneNumber: inputs.phoneNumber,
          firstName: inputs.name,
          position: inputs.position,
        },
      });
      getActiveUser()
    }
  };

  useEffect(() => {
    setShowLoader(isSubmitting);
  }, [isSubmitting, setShowLoader]);

  return <UserProfileForm sessionUser={sessionUser} handleInput={handleInput} onSubmit={onSubmit} />;
}

export default connector(UserProfile);
