import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import articlesService from '../../services/article';
import {listFulfilled, listPending, listRejected} from '../utils';
import {ListData} from '../types';
import {ArticleState} from './types';
import { Article } from './types';

const name = 'articles';

export const initialState: ArticleState = {
    list: {
      data: [],
      loading: false,
      requestId: '',
      total: 0,
    },
    articles:{
      data: [],
      loading: false,
      requestId: '',
      total: 0,
    },
    selected: {
      data: null,
      loading: false,
      requestId: '',
    }
  };
  

export const getUnmappedArticles = createAsyncThunk<
ListData<Article>
>(`eon-ui/getUnmappedArticles`, async function (meta) {
const {data, headers} = await articlesService.getUnmappedArticles();
  if(data.Articles !== null && data.Articles !== undefined){
    return {
      data: data.Articles as Article[],
      total: headers['x-total-count'],
    };
  }
  else{
    return {
      data: [],
      total: headers['x-total-count']
    }
  }
});

const articlesSlice = createSlice({
    name,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
    builder.addCase(getUnmappedArticles.pending, (state, action) => {
        listPending(state.list, action);
      });
      
      builder.addCase(getUnmappedArticles.fulfilled, (state, action) => {
        listFulfilled(state.list, action);
      });
      builder.addCase(getUnmappedArticles.rejected, (state, action) => {
        listRejected(state.list, action);
      });  
    },
  });
  
  export default articlesSlice.reducer;
  