import { useTranslation } from "react-i18next"
import priceFormatter from '../../lib/priceFormatter';
import { Order } from '../../store/orders/types';
import { Entity } from "../../store/types"
import Divider from '@mui/material/Divider';

type OrderExtrasRowProps = {
    order: Entity<Order>
    currency: string
    userSuperAdminType: boolean
    userAdminType: boolean
    isDeleteColumnVisible: boolean
}

export function OrderExtrasRow ({
    order,
    currency,
    userSuperAdminType,
    userAdminType,
    isDeleteColumnVisible
}: OrderExtrasRowProps) {
    const { t } = useTranslation()

    let columns = 8
    if (userSuperAdminType)
        columns++
    if (isDeleteColumnVisible)
        columns++
    if (userAdminType)
        columns++


    return (
	<>
        <tr>
            <td colSpan={columns} style={{backgroundColor: '#f1f1f1', borderRadius: 0}}>
                <Divider textAlign="left">{t('offer.Other')}</Divider>
            </td>
        </tr>
        {(order.data?.otherCostText || (order.data?.otherCost !== undefined && order.data?.otherCost > 0)) &&
                <tr>{/* OtherField 1*/}
                    <td className='text-center' style={{ width: 100}}> Övrigt </td>
                    <td className='text-truncate'>{order.data?.otherCostText}</td>
                    <td style={{ width: 100}}></td>
                    {userSuperAdminType && <td className='pl-2' style={{ width: 80}}></td>}
                    {userAdminType && <td className='pl-2' style={{ width: 80}}></td>}
                    <td className='pl-2 pr-2 text-truncate'>{priceFormatter(currency || 'SEK')(order.data?.otherCost ?? 0)}</td> {/* PriceField */}
                    <td className='pl-2 pr-2 text-truncate'></td><td className='pl-2 pr-2 text-truncate'></td><td className='p-0' style={{ width: 80}}></td>
                    <td style={{ width: 100}}></td>
                    {isDeleteColumnVisible && <td></td>}
                </tr>
            }
            {(order.data?.otherCostText2 || (order.data?.otherCost2 !== undefined && order.data?.otherCost2 > 0)) &&
                <tr>{/* OtherField 2*/}
                    <td className='text-center' style={{ width: 100}}> Övrigt </td>
                    <td className='text-truncate'>{order.data?.otherCostText2}</td>
                    <td style={{ width: 100}}></td>
                    {userSuperAdminType && <td className='pl-2' style={{ width: 80}}></td>}
                    {userAdminType && <td className='pl-2' style={{ width: 80}}></td>}
                    <td className='pl-2 pr-2 text-truncate'>{priceFormatter(currency || 'SEK')(order.data?.otherCost2 ?? 0)}</td> {/* PriceField */}
                    <td className='pl-2 pr-2 text-truncate'></td><td className='pl-2 pr-2 text-truncate'></td><td className='p-0' style={{ width: 80}}></td>
                    <td style={{ width: 100}}></td>
                    {isDeleteColumnVisible && <td></td>}
                </tr>
            }
            {(order.data?.otherCostText3 || (order.data?.otherCost3 !== undefined && order.data?.otherCost3 > 0)) &&
                <tr>{/* OtherField 3*/}
                    <td className='text-center' style={{ width: 100}}> Övrigt </td>
                    <td className='text-truncate'>{order.data?.otherCostText3}</td>
                    <td style={{ width: 100}}></td>
                    {userSuperAdminType && <td className='pl-2' style={{ width: 80}}></td>}
                    {userAdminType && <td className='pl-2' style={{ width: 80}}></td>}
                    <td className='pl-2 pr-2 text-truncate'>{priceFormatter(currency || 'SEK')(order.data?.otherCost3 ?? 0)}</td> {/* PriceField */}
                    <td className='pl-2 pr-2 text-truncate'></td><td className='pl-2 pr-2 text-truncate'></td><td className='p-0' style={{ width: 80}}></td>
                    <td style={{ width: 100}}></td>
                    {isDeleteColumnVisible && <td></td>}
                </tr>
            }
            {(order.data?.discountText || (order.data?.discountCost !== undefined && order.data?.discountCost > 0)) &&
                <tr>{/* OtherField 4*/}
                    <td className='text-center' style={{ width: 100}}>{t('offer.table-Discount')}</td>
                    <td className='text-truncate'>{order.data?.discountText}</td>
                    <td style={{ width: 100}}></td>
                    {userSuperAdminType && <td className='pl-2' style={{ width: 80}}></td>}
                    {userAdminType && <td className='pl-2' style={{ width: 80}}></td>}
                    <td className='pl-2 pr-2 text-truncate'>-{priceFormatter(currency || 'SEK')(order.data?.discountCost ?? 0)}</td> {/* PriceField */}
                    <td className='pl-2 pr-2 text-truncate'></td><td className='pl-2 pr-2 text-truncate'></td><td className='p-0' style={{ width: 80}}></td>
                    <td style={{ width: 100}}></td>
                    {isDeleteColumnVisible && <td></td>}
                </tr>
            }
            {(order.data?.discountText2 || (order.data?.discountCost2 !== undefined && order.data?.discountCost2 > 0)) &&
                <tr>{/* OtherField 5*/}
                    <td className='text-center' style={{ width: 100}}>{t('offer.table-Discount')}</td>
                    <td className='text-truncate'>{order.data?.discountText2}</td>
                    <td style={{ width: 100}}></td>
                    {userSuperAdminType && <td className='pl-2' style={{ width: 80}}></td>}
                    {userAdminType && <td className='pl-2' style={{ width: 80}}></td>}
                    <td className='pl-2 pr-2 text-truncate'>-{priceFormatter(currency || 'SEK')(order.data?.discountCost2 ?? 0)}</td> {/* PriceField */}
                    <td className='pl-2 pr-2 text-truncate'></td><td className='pl-2 pr-2 text-truncate'></td><td className='p-0' style={{ width: 80}}></td>
                    <td style={{ width: 100}}></td>
                    {isDeleteColumnVisible && <td></td>}
                </tr>
            }
            {(order.data?.discountText3 || (order.data?.discountCost3 !== undefined && order.data?.discountCost3 > 0)) &&
                <tr>{/* OtherField 6*/}
                    <td className='text-center' style={{ width: 100}}>{t('offer.table-Discount')}</td>
                    <td className='text-truncate'>{order.data?.discountText3}</td>
                    <td style={{ width: 100}}></td>
                    {userSuperAdminType && <td className='pl-2' style={{ width: 80}}></td>}
                    {userAdminType && <td className='pl-2' style={{ width: 80}}></td>}
                    <td className='pl-2 pr-2 text-truncate'>-{priceFormatter(currency || 'SEK')(order.data?.discountCost3 ?? 0)}</td> {/* PriceField */}
                    <td className='pl-2 pr-2 text-truncate'></td><td className='pl-2 pr-2 text-truncate'></td><td className='p-0' style={{ width: 80}}></td>
                    <td style={{ width: 100}}></td>
                    {isDeleteColumnVisible && <td></td>}
                </tr>
            }
            {order.data?.transportCost !== undefined && order.data?.transportCost > 0 &&
                <tr>{/* TransportCost 3*/}
                    <td className='text-center' style={{ width: 100}}> Fraktavgift </td>
                    <td className='text-truncate'>{t('common.Freight charge')}</td>
                    <td style={{ width: 100}}></td>
                    {userSuperAdminType && <td className='pl-2' style={{ width: 80}}></td>}
                    {userAdminType && <td className='pl-2' style={{ width: 80}}></td>}
                    <td className='pl-2 pr-2 text-truncate'>{priceFormatter(currency || 'SEK')(order.data?.transportCost ?? 0)}</td> {/* PriceField */}
                    <td className='pl-2 pr-2 text-truncate'></td><td className='pl-2 pr-2 text-truncate'></td><td className='p-0' style={{ width: 80}}></td>
                    <td style={{ width: 100}}></td>
                    {isDeleteColumnVisible && <td></td>}
                </tr>
            }
	</>
    )
}
export default OrderExtrasRow