import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import Table from '../Table';
import Pager from '../Pager';
import Modal from '../Modal';
import {Article} from '../../store/articles/types';
import {AppState} from '../../store';
import {connect, ConnectedProps} from 'react-redux';
import {getUnmappedArticles} from '../../store/articles';

const connector = connect(
  (state: AppState) => ({
    unMappedArticles: state.articles.list,
  }),
  {getUnmappedArticles}
);

type ProductSelectModal = ConnectedProps<typeof connector> & {
  modalVisible: boolean;
  onSelect: (article: Article) => React.MouseEventHandler;
  setModalVisible: (f: boolean) => void;
};

export function ProductSelectModal({
  unMappedArticles,
  modalVisible,
  onSelect,
  setModalVisible,
  getUnmappedArticles,
}: ProductSelectModal) {
  const {t} = useTranslation();
  const [searchStr, setSearchStr] = useState('');
  const onSearchChange = (e: any) => {
    setSearchStr(e.target.value);
  }

  useEffect(() => {
    getUnmappedArticles();
  }, [getUnmappedArticles]);

  const filteredItems = unMappedArticles.data.filter(article => 
	(article.ArticleNumber+'').toLowerCase().includes(searchStr) 
	|| (article.Description+'').toLowerCase().includes(searchStr)
  )

  return (
    <Modal
      name="product-list"
      modalVisible={modalVisible}
      setModalVisible={setModalVisible}
      title={t('common.Associate product to article number')}
    >
      {unMappedArticles.data && (
        <Table
          loading={false}
          useLoader={true}
          title={t('common.Select article')}
          searchField={{
            name: 'userSearch',
            text: t('common.Search article'),
            onChange: onSearchChange,
            value: searchStr,
          }}
          header={[
            t('common.Article number'),
            t('common.Product name'),
            {
              key: 'pager',
              component: (
                <Pager
                  className="float-right"
                  page={1}
                  totalItems={1}
                  onPagination={() => {}}
                />
              ),
            },
          ]}
          row={row => (
            <>
              <td>{row.ArticleNumber}</td>
              <td>{row.Description}</td>
              <td className="text-right">
                <span
                  className="text-primary cursor-pointer"
                  onClick={onSelect(row)}
                >
                  {t('common.Associate')}
                </span>
              </td>
            </>
          )}
          list={filteredItems}
          extractKey={(el) => `${el.ArticleNumber}`}
        />
      )}
    </Modal>
  );
}

export default connector(ProductSelectModal);
