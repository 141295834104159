import axiosInstance, { request } from '../lib/request';

import { ENDPOINTS } from '../endpoints';
import { generatePath } from 'react-router-dom';
import { BannerMessage, CreateBannerMessageFormType } from '../store/bannermessages/types';




const methods = {
    getAllBannerMessages() {
        return request({
            endpoint: ENDPOINTS.BANNER_MESSAGES_GET_ALL_MESSAGES,
            method: 'GET'
        });
    },

    getAllActiveBannerMessages() {
        return request({
            endpoint: ENDPOINTS.BANNER_MESSAGES_GET_ALL_ACTIVE_MESSAGES,
            method: 'GET'
        });
    },

    getSpecificBannerMessage(bannerMessageId: string) {
        return request({
            endpoint: ENDPOINTS.BANNER_MESSAGES_GET_SPECIFIC_MESSAGES
                .replace(":messageId", bannerMessageId),
            method: 'GET'
        });
    },

    inactivateBannerMessage(bannerMessageId: string) {
        return request({
            endpoint: ENDPOINTS.BANNER_MESSAGES_INACTIVATE_MESSAGE
            .replace(":messageId", bannerMessageId),
            method: 'GET'
        });
    },

    deleteBannerMessage(bannerMessageId: string){
        return request({
            endpoint: ENDPOINTS.BANNER_MESSAGES_DELETE_MESSAGE
                .replace(":messageId", bannerMessageId),
            method: 'DELETE'
        });
    },

    createBannerMessage(createBannerMessageFormType: CreateBannerMessageFormType){
        const request = {
            messageStatus: createBannerMessageFormType.messageStatus?.value,
            messageType: createBannerMessageFormType.messageType?.value,
            message: createBannerMessageFormType.message,
            startDateTime: createBannerMessageFormType.startDateTime,
            endDateTime: createBannerMessageFormType.endDateTime,
            header: createBannerMessageFormType.header,
        } as BannerMessage

        return axiosInstance.post(
            generatePath(ENDPOINTS.BANNER_MESSAGES_CREATE_MESSAGE),
            request,
        );
    },

    editBannerMessage(
                    createBannerMessageFormType: CreateBannerMessageFormType,
                    bannerMessageId: string){

        const request = {
            messageStatus: createBannerMessageFormType.messageStatus?.value,
            messageType: createBannerMessageFormType.messageType?.value,
            message: createBannerMessageFormType.message,
            startDateTime: createBannerMessageFormType.startDateTime,
            endDateTime: createBannerMessageFormType.endDateTime,
            header: createBannerMessageFormType.header,
        } as BannerMessage

        return axiosInstance.put(
            generatePath(ENDPOINTS.BANNER_MESSAGES_EDIT_MESSAGE
                .replace(":messageId", bannerMessageId)),
            request,
        );
    },
}

export default methods
