import React, {useCallback, useContext, useEffect, useState} from 'react'
import { useTranslation } from 'react-i18next'
import NumberInput from '../../NumberInput/NumberInput'
import StockTextWithCircle from './StockTextWithCircle'
import PriceWithDiscount from './PriceWithDiscount'
import Grid from '@material-ui/core/Grid/Grid'
import Card from '@material-ui/core/Card/Card'
import CardContent from '@material-ui/core/CardContent/CardContent'
import Fade from '@material-ui/core/Fade/Fade'
import Tooltip from '@material-ui/core/Tooltip/Tooltip'
import CardMedia from '@material-ui/core/CardMedia/CardMedia'
import Skeleton from '@material-ui/lab/Skeleton/Skeleton'
import Box from '@material-ui/core/Box/Box'
import Typography from '@material-ui/core/Typography/Typography'
import Link from '@material-ui/core/Link/Link'
import CardActions from '@material-ui/core/CardActions/CardActions'
import { useMediaQuery } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

interface ProductCardProps {
  	product?: {
		id: string
		image: string
		title: string
		manufacturer: string
		description: string
		price: {
			price: number
			priceWithoutDiscount: number
			currency: string
			discount: number
		}
		noneStockProduct: boolean
		stockAmount: number
		articleNumber: string
		inStock?: boolean
		maxOrderQuantity?: number
	}
	setProductInCart?: (id: string, quantity: number) => void
	gotoProduct?: (id: string) => void
	b2COrder: boolean
}

const ProductCard: React.FC<ProductCardProps> = ({
	product,
	setProductInCart,
	gotoProduct,
	b2COrder,
  }) => {
	const { t } = useTranslation()
	const [numberToBuy, setNumberToBuy] = useState(1);
	
	useEffect(() => { setNumberToBuy(1) }, [setProductInCart])

	const media = useMediaQuery('(max-width:600px)')

	const addToCart = useCallback(() => {
		if(!product)
			return

		if(setProductInCart) {
			setProductInCart(product.id, numberToBuy)
			setNumberToBuy(1)
		}

		product.stockAmount -= numberToBuy;

		if(product.stockAmount <= 0) {
			product.stockAmount = 0;
			product.inStock = false;
		}

	}, [numberToBuy, product, setProductInCart])

	const showFullPage = product?.id && gotoProduct ? () => gotoProduct(product.id) : undefined

	return !media ? (
		<Grid item xs={12} sm={6} lg={4} xl={3} style={{ minWidth: 250, maxWidth: 320 }}>
			<Card>
				<CardMedia 
					style={{ height: 200, display: 'grid', placeContent: 'center' }} 
					onClick={showFullPage}
					className="cursor-pointer"
				>
					{product?.image ? 
						<img src={product.image} style={{ maxWidth: 200, maxHeight: 200 }} alt={"Product: "+product.title} />
						: <Skeleton variant="rect" width="100%" height="100%" /> 
					}
				</CardMedia>

				<CardContent>
					<Box style={{ height: 50 }}>
						<Tooltip title={product?.title ?? false} TransitionComponent={Fade} enterDelay={1000}>
							{ product?.title ?
								showFullPage 
									? 
										<Link 
											type="button" 
											onClick={showFullPage}
											style={{ width: '100%' }}
										>
											<Typography
												style={{ width: '100%' }} 
												variant="body1" 
												color="textPrimary" 
												noWrap
											>
												{product?.title}
											</Typography>
										</Link>
									:
										<Typography
											style={{ width: '100%' }} 
											variant="body1" 
											color="textPrimary" 
											noWrap
										>
											{product?.title}
										</Typography>
								: <Skeleton width="100%" />
							}
						</Tooltip>
						{ product !== undefined && product.manufacturer !== null &&
							<Typography variant="caption" color="textSecondary">
								{product.manufacturer ?? <Skeleton width={'45%'} />}
							</Typography>
						}
					</Box>
					
					<Grid container justify="space-between" alignItems="flex-end" direction="row" style={{ height: 36 }}>
						<Box mr={2}>
							<Typography variant="caption" style={{ color: 'rgba(0,0,0, 0.75)' }}>
								{ product?.stockAmount !== undefined ?
									<StockTextWithCircle 
										noneStockProduct={product.noneStockProduct}
										stockAmount={product.stockAmount}
									/>
									: <Skeleton width={80} />
								}
							</Typography>
						</Box>
						<Typography variant="body1" color="textPrimary" component="div" style={{ textAlign: 'right' }}>
							<Grid container direction="column-reverse" spacing={0}>
								{ product?.price !== undefined ?
									<PriceWithDiscount 
										{...product.price} 
										discountProps={{ variant: "caption", color: "textSecondary", style: { height: 12 } }}
										priceProps={{ variant: "subtitle2", color: "textPrimary" }}
									/>
									: <Skeleton width={80} />
								}
							</Grid>
						</Typography>
					</Grid>
				</CardContent>
				
				<CardActions>
					<Grid style={{ width: '100%' }}>
						
						<Box width="60%" minWidth={150} mx="auto">
							<NumberInput 
								disabled={product?.noneStockProduct || !product?.inStock}
								value={numberToBuy} 
								setValue={setNumberToBuy} 
								inputHeight={25.75} 
							/>
						</Box>
						
						{(!b2COrder && !!product?.maxOrderQuantity && numberToBuy > product?.maxOrderQuantity) &&
							<Alert className='mt-2 mb-2' severity="info" style={{fontSize: "0.75rem"}}>
								{t('Eshop.You cant order more. Please contact your seller after you put your order', {maxOrderQuantity: product?.maxOrderQuantity})}
							</Alert>
						}

						{(!!product?.stockAmount && product?.stockAmount < numberToBuy) && 
							<Alert className='mt-2 mb-2' severity="info" style={{fontSize: "0.75rem"}}>
								{t('Eshop.You cant order more than.', {maxOrderQuantity: product?.stockAmount})}
							</Alert>
						}

						<button
							className="btn btn-primary btn-sm mt-1 btn-block"
							style={{ textAlign: 'center' }}
							onClick={addToCart}
							disabled={product?.noneStockProduct || !product?.inStock || (!!product?.stockAmount && product?.stockAmount < numberToBuy)}
						>
							<Typography component="span" variant="caption">
								{(t('common.Add'))}
							</Typography>
						</button>
						<Link 
							type="button" 
							className="btn btn-sm btn-block" 
							onClick={showFullPage} 
							style={{ boxShadow: 'none' }}
						>
							<Typography component="span" variant="caption">Läs mer</Typography>
						</Link>
					</Grid>
				</CardActions>
			</Card>
		</Grid>
	) : (
		<Grid item xs={6} sm={6} lg={4} xl={3} style={{ minWidth: 150, maxWidth: 320 }}>
			<Card>
				<CardMedia 
					style={{ height: 100, display: 'grid', placeContent: 'center' }} 
					onClick={showFullPage}
					className="cursor-pointer"
				>
					{product?.image ? 
						<img src={product.image} style={{ maxWidth: 150, maxHeight: 100 }} alt={"Product: "+product.title} />
						: <Skeleton variant="rect" width="100%" height="100%" /> 
					}
				</CardMedia>

				<CardContent>
					<Box style={{ height: 50 }}>
						<Tooltip title={product?.title ?? false} TransitionComponent={Fade} enterDelay={1000}>
							{ product?.title ?
								showFullPage 
									? 
										<Link 
											type="button" 
											onClick={showFullPage}
											style={{ width: '100%' }}
										>
											<Typography
												style={{ width: '100%' }} 
												variant="body1" 
												color="textPrimary" 
												noWrap
											>
												{product?.title}
											</Typography>
										</Link>
									:
										<Typography
											style={{ width: '100%' }} 
											variant="body1" 
											color="textPrimary" 
											noWrap
										>
											{product?.title}
										</Typography>
								: <Skeleton width="100%" />
							}
						</Tooltip>
						{ product !== undefined && product.manufacturer !== null &&
							<Typography variant="caption" color="textSecondary" noWrap>
								{product.manufacturer ?? <Skeleton width={'45%'} />}
							</Typography>
						}
					</Box>
					
					<Box display="grid" alignItems="start" style={{ gap: 5 }}>
						<Box mr={2}>
							<Typography variant="caption" style={{ color: 'rgba(0,0,0, 0.75)', fontSize: 11 }} noWrap>
								{ product?.stockAmount !== undefined ?
									<StockTextWithCircle 
										noneStockProduct={product.noneStockProduct}
										stockAmount={product.stockAmount}
									/>
									: <Skeleton width={80} />
								}
							</Typography>
						</Box>
						<Typography variant="body2" color="textPrimary" component="div" style={{ textAlign: 'right', fontSize: 11 }}>
							<Grid container direction="column-reverse" spacing={0}>
								{ product?.price !== undefined ?
									<PriceWithDiscount 
										{...product.price} 
										discountProps={{ variant: "caption", color: "textSecondary", style: { height: 12 } }}
										priceProps={{ variant: "subtitle2", color: "textPrimary" }}
									/>
									: <Skeleton width={80} />
								}
							</Grid>
						</Typography>
					</Box>
				</CardContent>
				<CardActions>
					<Grid>
						<Box mx="auto">
							<NumberInput 
								disabled={product?.noneStockProduct || !product?.inStock}
								value={numberToBuy} 
								setValue={setNumberToBuy} 
								inputHeight={25.75} 
							/>
						</Box>
						
						{(!!product?.maxOrderQuantity && numberToBuy > product?.maxOrderQuantity) &&
							<Alert className='mt-2 mb-2' severity="info" style={{fontSize: "0.75rem"}}>
								{t('Eshop.You cant order more. Please contact your seller after you put your order', {maxOrderQuantity: product?.maxOrderQuantity})}
							</Alert>
						}
						<button
							className="btn btn-primary btn-sm mt-1 btn-block"
							style={{ textAlign: 'center' }}
							onClick={addToCart}
							disabled={product?.noneStockProduct || !product?.inStock}
						>
							<Typography component="span" variant="caption">
								{t('common.Add')}
							</Typography>
						</button>
						<Link 
							type="button" 
							className="btn btn-sm btn-block" 
							onClick={showFullPage}
							style={{ boxShadow: 'none' }}
						>
							<Typography component="span" variant="caption">Läs mer</Typography>
						</Link>
					</Grid>
				</CardActions>
			</Card>
		</Grid>
	)
}

export default ProductCard 