import React from 'react';
import {connect, ConnectedProps} from 'react-redux';

import {AppState} from '../../store';

import {isBackOfficeAccount} from '../../lib/permissions';

import CustomerHome from './CustomerHome';
import BackOfficeHome from './BackOfficeHome'; // In future backoffice may have other startpage

const connector = connect(
  (state: AppState) => ({
    sessionUserType: state.system.sessionUserType,
  }),
  {}
);

type HomeProps = ConnectedProps<typeof connector> & {};

export function Home({sessionUserType}: HomeProps) {
  if (isBackOfficeAccount(sessionUserType)) {
    return <CustomerHome />;
  }

  return <CustomerHome />;
}

export default connector(Home);
