import React from 'react'
import Box from '@material-ui/core/Box/Box'
import Card from '@material-ui/core/Card/Card'
import CardContent from '@material-ui/core/CardContent/CardContent'
import CardHeader from '@material-ui/core/CardHeader/CardHeader'
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel'
import Grid from '@material-ui/core/Grid/Grid'
import IconButton from '@material-ui/core/IconButton/IconButton'
import Typography from '@material-ui/core/Typography/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery'
import { Add, Close, Remove, RemoveShoppingCart } from '@material-ui/icons'
import useBoolean from '../../../hooks/useBoolean'
import Skeleton from '@material-ui/lab/Skeleton/Skeleton'
import priceFormatter from '../../../lib/priceFormatter'
import Link from '@material-ui/core/Link/Link'
import NonIdealState from '../../NonIdealState/NonIdealState'
import Checkbox from '@material-ui/core/Checkbox/Checkbox'
import { Alert } from '@material-ui/lab'
import { useTranslation } from 'react-i18next'


export interface ProductListProduct {
	id: string
	name: string
	image?: string
	articlePrice?: {
		displayPrice: number
		currency: string
	},
	quantity: number
	articleNumber?: string
	disposableQuantity: number
	amountOfInCart: number
	maxOrderQuantity?: number
}

interface ProductListProps {
	products?: ProductListProduct[]
	totalPrice?: number
	increment?: (articleId: string) => void
	decrement?: (articleId: string) => void
	remove?: (articleId: string, qantity: number) => void
	showImage?: boolean
	userCanToggleShowImage?: boolean
	visit?: (articleId: string) => void
	emphasize?: string[]
	title?: string
	totalAmount?: number
	transparent?: boolean
	limitItems?: number
	loading?: boolean
	limitWidth?: boolean
	showContainer?: boolean
	inPartialDelivery?: {productId: string}[]
	toggleInPartialDelivery?: (articleId: string, articleNumber: string) => unknown
	isLocked?: boolean
	otherCost?: number
	transportCost?: number,
	b2COrder?: boolean,
}



const ProductList: React.FC<ProductListProps> = ({ 
	products, 
	totalPrice, 
	increment, 
	decrement, 
	remove, 
	showImage, 
	userCanToggleShowImage, 
	visit,
	title,
	transparent,
	limitItems = 4,
	loading = true,
	limitWidth = true,
	showContainer = true,
	inPartialDelivery,
	toggleInPartialDelivery,
	isLocked,
	otherCost,
	transportCost,
	b2COrder,
}) => {
	const { t } = useTranslation()
	const matches = useMediaQuery('(max-width:600px)')
	const [ withImage, , { toggle: toggleWithImage } ] = useBoolean(showImage)
	const cardMaxWidth = matches || !limitWidth ? 'inherit' : '55ch'
	const render = products ?? [undefined, undefined]

	!showContainer && (transparent = true) 
	return (
		<Box
			p={showContainer?4:0}
			mt={matches?0:3}
			style={{
				backgroundColor: transparent ? 'transparent' : '#f1f1f1', 
				width: '100%', maxWidth: cardMaxWidth, height: 'min-content' 
			}}
		>
			{ loading || !!products?.length ? 
				<>
					{ (title || userCanToggleShowImage) &&
						<Grid container justify="space-between">
							{ title && 
								<Typography variant="h6" color="textSecondary">
									{title}
								</Typography>
							}
							{ userCanToggleShowImage && 
								<FormControlLabel
									control={<Checkbox color="primary" size="small" disableRipple />}
									onChange={toggleWithImage}
									label={
										<Box display="grid" alignContent="center">
											<Typography variant="caption" component="label" noWrap>Visa bilder</Typography>
										</Box>
									}
									labelPlacement="end"
								/>
							}
						</Grid>
					}

					<Box 
						style={{ 
							maxHeight: 2<render.length?(100+(6*2))*limitItems:'auto',
							overflowY: 'auto', scrollbarWidth: 'thin' 
						}}
					>
						{ render.map((product: ProductListProduct | undefined, i: number) => 
						
							<Box mt={i?2:0} key={product?.id ?? i} style={{borderBottom: "1px solid #ccc"}}>
								<Card elevation={0} style={{ position: 'relative', display: 'flex', height: '100%' }} className="mb-2">
									{withImage && 
										<Box 
											width={100} 
											height={100} 
											display="grid" 
											style={{ placeContent: 'center' }} 
											onClick={product?.id && visit ? () => visit(product.id) : undefined}
										>
											{ product ?
												<img src={product?.image} style={{ width: 75, height: 75 }} alt={product?.name} /> 
												: <Skeleton variant="rect" style={{ width: 75, height: 75 }} /> 
											}
										</Box>
									}
									<CardContent style={{ width: withImage ? 'calc(100% - 100px - 20px)' : 'calc(100% - 20px)' }}>
										<Box>
											<Box mb={1}>
												{ visit ? 
													<Link 
														type="button" 
														className="btn btn-sm btn-block" 
														onClick={product?.id ? () => visit(product.id) : undefined} 
														style={{ boxShadow: 'none', padding: 0, textAlign: 'left' }}
													>
														<Typography color="textPrimary" variant="body2" noWrap>
														<svg height="15" width="15">
																<circle cx="5" cy="5" r="4" stroke="black" strokeWidth="0" 
																	fill={product?.disposableQuantity !== undefined && 
																		product?.disposableQuantity > 0 &&
																		product?.amountOfInCart <= product?.disposableQuantity   ? 
																	"green" : "orange"
																} />
															</svg> 
															{product?.name ?? <Skeleton width="100%" />}
														</Typography>
													</Link>
												:
													<Typography color="textPrimary" variant="body2" noWrap>
														{product?.name ?? <Skeleton width="100%" />}
													</Typography>
												}
												<Typography color="textSecondary" variant="caption">
													{ product?
														<>
															{ product.articlePrice?.currency && product.articlePrice?.displayPrice && 
																priceFormatter(product.articlePrice.currency)(product.articlePrice.displayPrice)
															}
														</>
														: <Skeleton width={`${35*(15/3)*(3%i)}%`} />
													}
												</Typography>
											</Box>

											<Box display="flex">
												{  !isLocked && decrement && 
													<IconButton 
														aria-label="decrement" 
														size="small" 
														onClick={product ? () => decrement(product.id) : undefined} 
														disabled={!product}
													>
														<Remove fontSize="small" />
													</IconButton>
												}
												<Box display="grid" alignContent="center" whiteSpace="nowrap" px={1}>
													<Typography variant="caption" color="textPrimary">
														{ isLocked ? "Antal: " + product?.quantity ?? false : product?.quantity ?? false}
													</Typography>
												</Box>
												{  !isLocked && increment && 
													<IconButton 
														aria-label="add" 
														size="small" 
														onClick={product ? () => increment(product.id) : undefined} 
														disabled={!product}
													>
														<Add fontSize="small" />
													</IconButton>
												}
												{toggleInPartialDelivery && 
													<FormControlLabel
														style={{ margin: 0, height: 25 }}
														control={<Checkbox color="primary" size="small" disableRipple />}
														checked={inPartialDelivery?.some(item => item.productId === product?.id)??false}
														onChange={product ? () => toggleInPartialDelivery(product.id, product.articleNumber!) : undefined}
														label={
															<Box display="grid" alignContent="center">
																<Typography variant="caption" noWrap>Delleverans</Typography>
															</Box>
														}
														labelPlacement="end"
													/>
												}
											</Box>
										</Box>	
									</CardContent>

									{ !isLocked && remove && 
										<CardHeader
											style={{position: 'absolute', right: 0, top: 'calc(50% - 24px)' }}
											action={
												<IconButton 
													aria-label="remove" 
													size="small" 
													onClick={product ? () => remove(product.id, product.quantity) : undefined} 
													disabled={!product}
												>
													<Close fontSize="small" />
												</IconButton>
											}
										/>
									}
								</Card>
								{(!!b2COrder && !!product?.maxOrderQuantity && product?.amountOfInCart === product?.maxOrderQuantity) &&
									<Alert className='mt-2 mb-2' severity="info" style={{fontSize: "0.75rem", backgroundColor: "rgb(232, 244, 253)"}}>												
										{t('Eshop.You cant order more. Please contact your seller after you put your order', {maxOrderQuantity: product?.maxOrderQuantity})}
									</Alert>
								}
							</Box>

						)}
					</Box>

					{ totalPrice !== undefined &&
						<Box pb={1} pt={2}>
							{isLocked ? (
								<Typography variant="body1" color="textPrimary">
									Andra kostnader: {!!render[0]?.articlePrice?.currency && priceFormatter(render[0].articlePrice.currency)(otherCost || 0)}
									<br />
									Frakt: {!!render[0]?.articlePrice?.currency && priceFormatter(render[0].articlePrice.currency)(transportCost || 0)}
									<br />
									<b>Totalt:</b> {!!render[0]?.articlePrice?.currency && priceFormatter(render[0].articlePrice.currency)(totalPrice)}
								</Typography>
								):(
									<Typography variant="body1" color="textPrimary">
										Totalt: {!!render[0]?.articlePrice?.currency && priceFormatter(render[0].articlePrice.currency)(totalPrice)}
									</Typography>
								)
							}
							

						</Box>
					}
				</>
			:
				<NonIdealState style={{display: loading ? "none" : "block"}}
					icon={<RemoveShoppingCart fontSize="large" />}
					title="Din varukorg är tom"
					description={
						<>
							<span>Dina varukorg innehåller inga produkter.</span>
							<span>Försök ladda om hemsidan eller gå tillbaka.</span>
						</>
					}
				/>
			}
		</Box>
	)
}

export default ProductList
