import React from 'react'
import OfferListingList from './OfferListingList/OfferListingList'

const OurOffers = () => {
	
	return (
		<OfferListingList type="our" />
	)
}

export default OurOffers
