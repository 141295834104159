import {
  mdiHome,
  mdiWhiteBalanceSunny,
  mdiCart,
  mdiPencilBox,
  mdiFileDocumentEdit,
  mdiTag,
  mdiCube,
} from '@mdi/js';

import {
  AccessModuleMap,
  RequiredPermission,
  INHERIT_MODULES,
} from '../lib/permissions';
import { UserType, Module } from '../store/users/types';

type DefaultNavItem = {
  key: string;
  path: RegExp;
  icon: string;
  text: string;
  hiddenOnMobile?: boolean;
} & RequiredPermission;

type NavDropdownItem = {
  text: string;
  path: string;
  routeMatch?: string | string[];
  exact?: boolean;
} & RequiredPermission;

type NavItemWithDropdown = DefaultNavItem & {
  dropdown: Array<NavDropdownItem>;
  to?: never;
};

type NavItemWithDestination = DefaultNavItem & {
  dropdown?: never;
  to: string;
};

export type NavigationList = Array<
  NavItemWithDropdown | NavItemWithDestination
>;

export const PATH_ANY = '*';
export const PATH_NOT_FOUND = '/404';
export const PATH_ACCESS_DENIED = '/403';

export const PATH_AUTH = '/auth';
export const PATH_AUTH_LOGIN = `${PATH_AUTH}/login`;
export const PATH_AUTH_FORGOT_PASSWORD = `${PATH_AUTH}/forgot-password`;
export const PATH_AUTH_NEW_PASSWORD = `${PATH_AUTH}/new-password/:token`;
export const PATH_AUTH_CONFIRM_USER = `${PATH_AUTH}/confirm-user/:token`;

export const PATH_HOME = '/';

export const PATH_NEWS_ARCHIVE = '/news-archive';
export const PATH_CHANGE_PASSWORD = '/change-password';
export const PATH_USER_PROFILE = '/user-profile';
export const PATH_GDPR_REPORT = '/gdpr-report';

export const PATH_NEWS = '/back-office';
export const PATH_NEWS_CREATE_NEWS = `${PATH_NEWS}/create-news`;
export const PATH_NEWS_EDIT_NEWS = `${PATH_NEWS}/news/:newsId/edit`;
export const PATH_NEWS_MANAGE_NEWS = `${PATH_NEWS}/news`;

export const PATH_GENERAL = '/general';
export const PATH_GENERAL_CREATE_USER = `${PATH_GENERAL}/create-user`;
export const PATH_GENERAL_MANAGE_USERS = `${PATH_GENERAL}/users`;
export const PATH_GENERAL_EDIT_USER = `${PATH_GENERAL_MANAGE_USERS}/:userId/edit`;
export const PATH_GENERAL_VIEW_USER = `${PATH_GENERAL_MANAGE_USERS}/:userId/view`;
export const PATH_GENERAL_MANAGE_COMPANY = `${PATH_GENERAL}/company/edit`;
export const PATH_GENERAL_MANAGE_COMPANY_DOCUMENTS = `${PATH_GENERAL}/:companyId/edit/documents`;

export const PATH_CUSTOMERS = '/customers';
export const PATH_CUSTOMERS_SWITCH_USER = `${PATH_CUSTOMERS}/switch-user`;
export const PATH_CUSTOMERS_CREATE_USER = `${PATH_CUSTOMERS}/create-user`;
export const PATH_CUSTOMERS_MANAGE_USERS = `${PATH_CUSTOMERS}/users`;
export const PATH_CUSTOMERS_EDIT_USER = `${PATH_CUSTOMERS_MANAGE_USERS}/:userId/edit`;
export const PATH_CUSTOMERS_VIEW_USER = `${PATH_CUSTOMERS_MANAGE_USERS}/:userId/view`;
export const PATH_CUSTOMERS_CREATE_COMPANY = `${PATH_CUSTOMERS}/create-company`;
export const PATH_CUSTOMERS_MANAGE_COMPANIES = `${PATH_CUSTOMERS}/companies`;
export const PATH_CUSTOMERS_MANAGE_CUSTOMERS = `${PATH_CUSTOMERS}/companies`;
export const PATH_CUSTOMERS_EDIT_COMPANY = `${PATH_CUSTOMERS_MANAGE_COMPANIES}/:companyId/edit`;
export const PATH_CUSTOMERS_EDIT_COMPANY_DOCUMENTS = `${PATH_CUSTOMERS_MANAGE_COMPANIES}/:companyId/edit/documents`;
export const PATH_CUSTOMERS_COMPANY_COMMENTS = `${PATH_CUSTOMERS_MANAGE_COMPANIES}/:companyId/edit/comments`;
export const PATH_CUSTOMERS_VIEW_COMPANY_USERS = `${PATH_CUSTOMERS_MANAGE_CUSTOMERS}/:companyId/users`;
export const PATH_CUSTOMERS_VIEW_COMPANY = `${PATH_CUSTOMERS_MANAGE_COMPANIES}/:companyId/view`;

export const PATH_E_SHOP = '/e-shop';
export const PATH_E_SHOP_PRICELIST = '/e-shop/download-price-list';
export const PATH_E_SHOP_B2C_UPLOAD = '/e-shop/upload-b2c';
export const PATH_E_SHOP_PRODUCT = '/e-shop/products/:productId';
export const PATH_E_SHOP_EDIT_PRODUCT = '/e-shop/products/:productId/edit';
export const PATH_E_SHOP_MANAGE = `${PATH_E_SHOP}/manage`;
export const PATH_E_SHOP_MANAGE_PRODUCTS = `${PATH_E_SHOP_MANAGE}/products`;
export const PATH_E_SHOP_CREATE_PRODUCT = `${PATH_E_SHOP_MANAGE}/products/create`;
export const PATH_E_SHOP_MANAGE_PRODUCT_CATEGORIES = `${PATH_E_SHOP_MANAGE}/product-categories`;
export const PATH_E_SHOP_MANAGE_PRODUCT_CATEGORIES_PRIORITY = `${PATH_E_SHOP_MANAGE}/product-categories-priority`;
export const PATH_E_SHOP_CREATE_PRODUCT_CATEGORY = `${PATH_E_SHOP_MANAGE}/product-categories/create`;
export const PATH_E_SHOP_CREATE_PRICE_GROUP = `${PATH_E_SHOP_MANAGE}/price-groups/create`;
export const PATH_E_SHOP_EDIT_PRICE_GROUP = `${PATH_E_SHOP_MANAGE}/price-groups/:priceGroupId/edit`;
export const PATH_E_SHOP_EDIT_PRODUCT_CATEGORY = `${PATH_E_SHOP}/product-categories/:categoryId/edit`;

export const PATH_E_SHOP2 = '/e-shop';
export const PATH_E_SHOP2_PRODUCT = '/products';
export const PATH_E_SHOP2_PRODUCT_FROM_CATEGORY = `${PATH_E_SHOP2}${PATH_E_SHOP2_PRODUCT}/:categoryFirstId/:categorySecondId/:categoryThirdId/:articleId`;

export const PATH_CASES = '/case-management';
export const PATH_CASES_BACK_OFFICE_LIST = `${PATH_CASES}/back-office/cases`;
export const PATH_CASES_BACK_OFFICE_CREATE = `${PATH_CASES}/back-office/create-case`;
export const PATH_CASES_BACK_OFFICE_EDIT = `${PATH_CASES}/back-office/edit-case/:serviceCaseId`;

export const PATH_CASES_BACK_OFFICE_CREATE_COMMENTS = `${PATH_CASES}/back-office/edit-case/:serviceCaseId/comments`;
export const PATH_CASES_BACK_OFFICE_ASSIGN_COMMENTS = `${PATH_CASES}/back-office/edit-case/:serviceCaseId/assign`;

export const PATH_CASES_BACK_OFFICE_VIEW_COMMENTS = `${PATH_CASES}/back-office/view-case/:serviceCaseId/comments`;
export const PATH_CASES_BACK_OFFICE_VIEW = `${PATH_CASES}/back-office/view-case/:serviceCaseId`;
export const PATH_CASES_BACK_OFFICE_ASSIGN_COMMENTS_VIEW = `${PATH_CASES}/back-office/view-case/:serviceCaseId/assign`;

export const PATH_CASES_LIST = `${PATH_CASES}/cases`;
export const PATH_CASES_CREATE = `${PATH_CASES}/create-case`;

export const PATH_BANNERMESSAGE = '/message-management';
export const PATH_BANNERMESSAGE_BACK_OFFICE_LIST = `${PATH_BANNERMESSAGE}/back-office/bannermessages`;
export const PATH_BANNERMESSAGE_BACK_OFFICE_CREATE = `${PATH_BANNERMESSAGE}/back-office/create-message`;
export const PATH_BANNERMESSAGE_BACK_OFFICE_EDIT = `${PATH_BANNERMESSAGE}/back-office/edit-message/:messageId`;

export const PATH_OFFERS = '/offer-management';
export const PATH_OFFERS_OFFER_TOOL = `${PATH_OFFERS}/offers/create`;
export const PATH_OFFERS_OFFER_TOOL_EDIT = `${PATH_OFFERS}/offers/:offerId/edit`;
export const PATH_OFFERS_BACK_OFFICE_LIST = `${PATH_OFFERS}/back-office/offers`;
export const PATH_OFFERS_MY_OFFERS_LIST = `${PATH_OFFERS}/main/my-offers`
export const PATH_OFFERS_CUSTOMER_OFFERS_LIST = `${PATH_OFFERS}/main/customer-offers`

export const PATH_ORDERS = '/order-management';
export const PATH_ORDERS_BACK_OFFICE_LIST = `${PATH_ORDERS}/back-office/orders`;
export const PATH_ORDERS_BACK_OFFICE_CREATE = `${PATH_ORDERS}/back-office/create-order`;
export const PATH_ORDERS_LIST = `${PATH_ORDERS}/orders`;
export const PATH_ORDERS_EDIT_ORDER = `${PATH_ORDERS}/:orderId/edit-order`;
export const PATH_ORDERS_VIEW_ORDER = `${PATH_ORDERS}/:orderId/view-order`;
// export const PATH_ORDERS_CREATE = `${PATH_ORDERS}/create-order`;

export const PATH_E_SHOP_CHECKOUT = '/e-shop/checkout';

export const PATH_DOCUMENTS = '/documents';

export const PATH_ARCHIVES = '/archives';

export const PATH_HELP = '/help';

export const ACCESS_MODULE_MAP: AccessModuleMap = {
  [PATH_HOME]: { accountType: null, module: null },
  [PATH_DOCUMENTS]: { accountType: null, module: null },

  '/download': { accountType: null, module: null },

  // back-office
  [PATH_NEWS_CREATE_NEWS]: {
    accountType: null,
    module: Module.ACCOUNT_MANAGEMENT,
  },
  [PATH_NEWS_MANAGE_NEWS]: {
    accountType: null,
    module: Module.ACCOUNT_MANAGEMENT,
  },
  [PATH_NEWS_EDIT_NEWS]: { accountType: null, module: Module.ACCOUNT_MANAGEMENT },

  [PATH_CUSTOMERS_SWITCH_USER]: {
    accountType: null,
    module: Module.ACCOUNT_MANAGEMENT,
  },
  [PATH_CUSTOMERS_CREATE_USER]: {
    accountType: null,
    module: Module.ACCOUNT_MANAGEMENT,
  },
  [PATH_CUSTOMERS_EDIT_USER]: {
    accountType: null,
    module: Module.ACCOUNT_MANAGEMENT,
  },
  [PATH_CUSTOMERS_VIEW_USER]: {
    accountType: null,
    module: Module.ACCOUNT_MANAGEMENT,
  },
  [PATH_CUSTOMERS_MANAGE_USERS]: {
    accountType: null,
    module: Module.ACCOUNT_MANAGEMENT,
  },
  [PATH_CUSTOMERS_CREATE_COMPANY]: {
    accountType: null,
    module: Module.ACCOUNT_MANAGEMENT,
  },
  [PATH_CUSTOMERS_EDIT_COMPANY]: {
    accountType: null,
    module: Module.ACCOUNT_MANAGEMENT,
  },
  [PATH_CUSTOMERS_EDIT_COMPANY_DOCUMENTS]: {
    accountType: null,
    module: Module.ACCOUNT_MANAGEMENT,
  },
  [PATH_CUSTOMERS_COMPANY_COMMENTS]: {
    accountType: null,
    module: Module.ACCOUNT_MANAGEMENT,
  },
  [PATH_CUSTOMERS_VIEW_COMPANY_USERS]: {
    accountType: null,
    module: Module.ACCOUNT_MANAGEMENT,
  },
  [PATH_CUSTOMERS_MANAGE_COMPANIES]: {
    accountType: null,
    module: Module.ACCOUNT_MANAGEMENT,
  },
  [PATH_CUSTOMERS_VIEW_COMPANY]: {
    accountType: null,
    module: Module.ACCOUNT_MANAGEMENT,
  },

  [PATH_E_SHOP_PRODUCT]: { accountType: null, module: null },
  [PATH_E_SHOP_MANAGE_PRODUCTS]: {
    accountType: null,
    module: Module.E_SHOP_BACK,
  },
  [PATH_E_SHOP_CREATE_PRODUCT]: { accountType: null, module: Module.E_SHOP_BACK },
  [PATH_E_SHOP_EDIT_PRODUCT]: { accountType: null, module: Module.E_SHOP_BACK },
  [PATH_E_SHOP_MANAGE_PRODUCT_CATEGORIES]: {
    accountType: null,
    module: Module.E_SHOP_BACK,
  },
  [PATH_E_SHOP_MANAGE_PRODUCT_CATEGORIES_PRIORITY]: {
    accountType: null,
    module: Module.E_SHOP_BACK,
  },

  [PATH_E_SHOP_PRICELIST]: {
    accountType: null,
    module: null,
  },

  [PATH_E_SHOP_B2C_UPLOAD]: {
    accountType: null,
    module: null,
  },

  [PATH_E_SHOP_CREATE_PRODUCT_CATEGORY]: {
    accountType: null,
    module: Module.E_SHOP_BACK,
  },

  [PATH_E_SHOP_CREATE_PRICE_GROUP]: {
    accountType: null,
    module: Module.E_SHOP_BACK,
  },

  [PATH_E_SHOP_EDIT_PRICE_GROUP]: {
    accountType: null,
    module: Module.E_SHOP_BACK,
  },
  [PATH_E_SHOP_EDIT_PRODUCT_CATEGORY]: {
    accountType: null,
    module: Module.E_SHOP_BACK,
  },

  [PATH_CASES_BACK_OFFICE_LIST]: {
    accountType: null,
    module: null,
  },
  [PATH_CASES_BACK_OFFICE_CREATE]: {
    accountType: null,
    module: null,
  },
  [PATH_CASES_BACK_OFFICE_EDIT]: {
    accountType: null,
    module:null,
  },

  [PATH_CASES_BACK_OFFICE_VIEW]: {
    accountType: null,
    module: null
  },

  [PATH_ORDERS_BACK_OFFICE_LIST]: {
    accountType: null,
    module: null,
  },
  [PATH_ORDERS_BACK_OFFICE_CREATE]: {
    accountType: null,
    module: Module.ORDER_MANAGEMENT,
  },

  [PATH_ORDERS_VIEW_ORDER]: {
    accountType: null,
    module: null,
  },
  [PATH_ORDERS_EDIT_ORDER]: {
    accountType: null,
    module: Module.ORDER_MANAGEMENT,
  },
  [PATH_BANNERMESSAGE_BACK_OFFICE_LIST]: {
    accountType: null,
    module: null,
  },
  [PATH_BANNERMESSAGE_BACK_OFFICE_CREATE]: {
    accountType: null,
    module: null,
  },
  [PATH_BANNERMESSAGE_BACK_OFFICE_EDIT]: {
    accountType: null,
    module: null,
  },

  // customers
  [PATH_NEWS_ARCHIVE]: { accountType: null, module: null },
  [PATH_CHANGE_PASSWORD]: { accountType: null, module: null },
  [PATH_USER_PROFILE]: { accountType: null, module: null },
  [PATH_GDPR_REPORT]: { accountType: null, module: null },

  [PATH_GENERAL_CREATE_USER]: { accountType: null, module: null },
  [PATH_GENERAL_EDIT_USER]: { accountType: null, module: null },
  [PATH_GENERAL_VIEW_USER]: { accountType: null, module: null },
  [PATH_GENERAL_MANAGE_USERS]: { accountType: null, module: null },
  [PATH_GENERAL_MANAGE_COMPANY]: { accountType: null, module: null },
  [PATH_GENERAL_MANAGE_COMPANY_DOCUMENTS]: { accountType: null, module: null },

  [PATH_E_SHOP]: { accountType: null, module: null },
  [PATH_E_SHOP2]: { accountType: null, module: null },
  [PATH_E_SHOP2_PRODUCT_FROM_CATEGORY]: {
    accountType: null,
    module: null,
  },


  [PATH_OFFERS_OFFER_TOOL]: { accountType: null, module:  Module.OFFER_GENERATION  },
  [PATH_OFFERS_OFFER_TOOL_EDIT]: { accountType: null, module:  Module.OFFER_GENERATION  },
  [PATH_OFFERS_BACK_OFFICE_LIST]: { accountType: null, module: null },
  [PATH_OFFERS_MY_OFFERS_LIST]: { accountType: null, module: null },
  [PATH_OFFERS_CUSTOMER_OFFERS_LIST]: { accountType: null, module: null },


  [PATH_CASES_CREATE]: { accountType: null, module: null },
  [PATH_CASES_LIST]: { accountType: null, module: null },

  // [PATH_ORDERS_CREATE]: {accountType:null, module:null},
  [PATH_ORDERS_LIST]: { accountType: null, module: null },

  [PATH_E_SHOP_CHECKOUT]: {
    accountType: null,
    module: null,
  },

  [PATH_CASES_BACK_OFFICE_CREATE_COMMENTS]: {
    accountType: null,
    module: null
  },

  [PATH_CASES_BACK_OFFICE_VIEW_COMMENTS]: {
    accountType: null,
    module: null
  },

  [PATH_CASES_BACK_OFFICE_ASSIGN_COMMENTS]: {
    accountType: null,
    module: null
  },

  [PATH_CASES_BACK_OFFICE_ASSIGN_COMMENTS_VIEW]: {
    accountType: null,
    module: null
  }
};

const adminAccounts = [UserType.SuperAdmin, UserType.Admin];
const customerAccounts = [UserType.MainAccount, UserType.SubAccount];
const mainAccount = [UserType.MainAccount];

export const NAVIGATION_FALLBACK = 'home';


const backOffice: NavigationList = [
  {
    key: NAVIGATION_FALLBACK,
    path: new RegExp(`(?:^${PATH_HOME}$|^${PATH_NEWS})`),
    icon: mdiHome,
    text: 'Home',
    dropdown: [
      {
        text: 'Home',
        path: PATH_HOME,
        exact: true,
        ...ACCESS_MODULE_MAP[PATH_HOME],
      },
      {
        text: 'Create new news',
        path: PATH_NEWS_CREATE_NEWS,
        ...ACCESS_MODULE_MAP[PATH_NEWS_CREATE_NEWS],
      },
      {
        text: 'Manage news',
        path: PATH_NEWS_MANAGE_NEWS,
        ...ACCESS_MODULE_MAP[PATH_NEWS_MANAGE_NEWS],
      },
      {
        text: 'Manage messages',
        path: PATH_BANNERMESSAGE_BACK_OFFICE_LIST,
        ...ACCESS_MODULE_MAP[PATH_BANNERMESSAGE_BACK_OFFICE_LIST],
      },
    ],
    module: INHERIT_MODULES,
    accountType: adminAccounts,
  },
  {
    key: 'customers',
    path: new RegExp(`^${PATH_CUSTOMERS}`),
    icon: mdiWhiteBalanceSunny,
    text: 'Customers',
    hiddenOnMobile: true,
    dropdown: [
      {
        text: 'Switch user',
        path: PATH_CUSTOMERS_SWITCH_USER,
        ...ACCESS_MODULE_MAP[PATH_CUSTOMERS_SWITCH_USER],
      },
      {
        text: 'Create new user',
        path: PATH_CUSTOMERS_CREATE_USER,
        ...ACCESS_MODULE_MAP[PATH_CUSTOMERS_CREATE_USER],
      },
      {
        text: 'Manage users',
        path: PATH_CUSTOMERS_MANAGE_USERS,
        ...ACCESS_MODULE_MAP[PATH_CUSTOMERS_MANAGE_USERS],
      },
      {
        text: 'Create new company',
        path: PATH_CUSTOMERS_CREATE_COMPANY,
        ...ACCESS_MODULE_MAP[PATH_CUSTOMERS_CREATE_COMPANY],
      },
      {
        text: 'Manage companies',
        path: PATH_CUSTOMERS_MANAGE_COMPANIES,
        ...ACCESS_MODULE_MAP[PATH_CUSTOMERS_MANAGE_COMPANIES],
      },
    ],
    module: INHERIT_MODULES,
    accountType: adminAccounts,
  },
  {
    key: 'e-shop',
    path: new RegExp(`^${PATH_E_SHOP}`),
    icon: mdiCart,
    text: 'E-shop',
    dropdown: [
      {
        routeMatch: PATH_E_SHOP_PRODUCT, //  it will match with PATH_E_SHOP and PATH_E_SHOP_PRODUCT
        path: PATH_E_SHOP,
        text: 'E-shop',
        ...ACCESS_MODULE_MAP[PATH_E_SHOP],
        exact: true,
      },
      {
        routeMatch: PATH_E_SHOP_PRICELIST, // it will match with PATH_E_SHOP_MANAGE_PRODUCTS and PATH_E_SHOP_MANAGE
        path: PATH_E_SHOP_PRICELIST,
        text: 'Pricelist e-shop',
        ...ACCESS_MODULE_MAP[PATH_E_SHOP],
      },
      {
        routeMatch: PATH_E_SHOP_B2C_UPLOAD, // it will match with PATH_E_SHOP_MANAGE_PRODUCTS and PATH_E_SHOP_MANAGE
        path: PATH_E_SHOP_B2C_UPLOAD,
        text: 'B2c e-shop',
        ...ACCESS_MODULE_MAP[PATH_E_SHOP],
      },
      {
        routeMatch: PATH_E_SHOP_MANAGE, // it will match with PATH_E_SHOP_MANAGE_PRODUCTS and PATH_E_SHOP_MANAGE
        path: PATH_E_SHOP_MANAGE_PRODUCTS,
        text: 'Manage e-shop',
        ...ACCESS_MODULE_MAP[PATH_E_SHOP_MANAGE_PRODUCTS],
      },
    ],
    module: INHERIT_MODULES,
    accountType: adminAccounts,
  },
  {
    key: 'case-management',
    path: new RegExp(`^${PATH_CASES}`),
    icon: mdiPencilBox,
    text: 'Case management',
    hiddenOnMobile: true,
    dropdown: [
      {
        path: PATH_CASES_BACK_OFFICE_LIST,
        text: 'Manage cases',
        ...ACCESS_MODULE_MAP[PATH_CASES_BACK_OFFICE_LIST],
      },
    ],
    module: INHERIT_MODULES,
    accountType: adminAccounts,
  },
  {
    key: 'offer-management',
    path: new RegExp(`^${PATH_OFFERS}`),
    icon: mdiTag,
    text: 'Offer management',
    hiddenOnMobile: true,
    dropdown: [
      {
        text: 'All offers' ,
        path: PATH_OFFERS_BACK_OFFICE_LIST,
        ...ACCESS_MODULE_MAP[PATH_OFFERS_BACK_OFFICE_LIST],
      },
      {
        text: 'My offers',
        path: PATH_OFFERS_MY_OFFERS_LIST,
        ...ACCESS_MODULE_MAP[PATH_OFFERS_MY_OFFERS_LIST],
      },
      {
        text: 'Create offer',
        path: PATH_OFFERS_OFFER_TOOL,
        ...ACCESS_MODULE_MAP[PATH_OFFERS_OFFER_TOOL],
      },
    ],
    module: INHERIT_MODULES,
    accountType: adminAccounts,
  },
  {
    key: 'order-management',
    path: new RegExp(`^${PATH_ORDERS}`),
    icon: mdiCube,
    text: 'Order management',
    dropdown: [
      // {
      //   path: PATH_ORDERS_BACK_OFFICE_CREATE,
      //   text: 'Create order',
      //   ...ACCESS_MODULE_MAP[PATH_ORDERS_BACK_OFFICE_CREATE],
      // },
      {
        path: PATH_ORDERS_BACK_OFFICE_LIST,
        text: 'Orders',
        ...ACCESS_MODULE_MAP[PATH_ORDERS_BACK_OFFICE_LIST],
      },
    ],
    module: INHERIT_MODULES,
    accountType: adminAccounts,
  },
  {
    key: 'documents',
    path: new RegExp(`^${PATH_DOCUMENTS}$`),
    text: 'Documents',
    icon: mdiFileDocumentEdit,
    dropdown: [
      {
        path: PATH_DOCUMENTS,
        text: 'Documentation',
        ...ACCESS_MODULE_MAP[PATH_DOCUMENTS],
      },
    ],
    module: INHERIT_MODULES,
    accountType: adminAccounts,
  },

];

const customer: NavigationList = [
  {
    key: NAVIGATION_FALLBACK,
    path: new RegExp(`(?:^${PATH_HOME}$|^${PATH_NEWS_ARCHIVE})`),
    icon: mdiHome,
    text: 'Home',
    dropdown: [
      {
        text: 'Home',
        path: PATH_HOME,
        exact: true,
        ...ACCESS_MODULE_MAP[PATH_HOME],
      },
      {
        text: 'News archive',
        path: PATH_NEWS_ARCHIVE,
        ...ACCESS_MODULE_MAP[PATH_NEWS_ARCHIVE],
      },
    ],
    module: null,
    accountType: customerAccounts,
  },
  {
    key: 'general',
    path: new RegExp(`^${PATH_GENERAL}`),
    icon: mdiWhiteBalanceSunny,
    text: 'General',
    hiddenOnMobile: true,
    dropdown: [
      {
        text: 'Create new user',
        path: PATH_GENERAL_CREATE_USER,
        ...ACCESS_MODULE_MAP[PATH_GENERAL_CREATE_USER],
      },
      {
        text: 'Manage users',
        path: PATH_GENERAL_MANAGE_USERS,
        ...ACCESS_MODULE_MAP[PATH_GENERAL_MANAGE_USERS],
      },
      {
        text: 'Manage company',
        path: PATH_GENERAL_MANAGE_COMPANY,
        ...ACCESS_MODULE_MAP[PATH_GENERAL_MANAGE_COMPANY],
      },
    ],
    module: INHERIT_MODULES,
    accountType: mainAccount,
  },

  {
    key: 'e-shop',
    path: new RegExp(`^${PATH_E_SHOP}`),
    icon: mdiCart,
    text: 'E-shop',
    dropdown: [
      {
        routeMatch: PATH_E_SHOP_PRODUCT, //  it will match with PATH_E_SHOP and PATH_E_SHOP_PRODUCT
        path: PATH_E_SHOP,
        text: 'E-shop',
        ...ACCESS_MODULE_MAP[PATH_E_SHOP],
        exact: true,
      },
	  {
        routeMatch: PATH_E_SHOP_PRICELIST, // it will match with PATH_E_SHOP_MANAGE_PRODUCTS and PATH_E_SHOP_MANAGE
        path: PATH_E_SHOP_PRICELIST,
        text: 'Pricelist e-shop',
        ...ACCESS_MODULE_MAP[PATH_E_SHOP],
      },
      {
        routeMatch: PATH_E_SHOP_B2C_UPLOAD, // it will match with PATH_E_SHOP_MANAGE_PRODUCTS and PATH_E_SHOP_MANAGE
        path: PATH_E_SHOP_B2C_UPLOAD,
        text: 'B2c e-shop',
        ...ACCESS_MODULE_MAP[PATH_E_SHOP],
      }
    ],
    module: INHERIT_MODULES,
    accountType: customerAccounts
  },
  {
    key: 'case-management',
    path: new RegExp(`^${PATH_CASES}`),
    icon: mdiPencilBox,
    text: 'Case management',
    hiddenOnMobile: true,
    dropdown: [
      {
        path: PATH_CASES_BACK_OFFICE_LIST,
        text: 'Manage cases',
        ...ACCESS_MODULE_MAP[PATH_CASES_BACK_OFFICE_LIST],
      },
    ],
    module: INHERIT_MODULES,
    accountType: customerAccounts,
  },
  {
    key: 'offer-management',
    path: new RegExp(`^${PATH_OFFERS}`),
    icon: mdiTag,
    text: 'Offer management',
    hiddenOnMobile: true,
    dropdown: [
      {
        text: 'Incoming offers',
        path: PATH_OFFERS_MY_OFFERS_LIST,
        ...ACCESS_MODULE_MAP[PATH_OFFERS_MY_OFFERS_LIST],
      },
      {
        text: 'Customer offers',
        path: PATH_OFFERS_CUSTOMER_OFFERS_LIST,
        ...ACCESS_MODULE_MAP[PATH_OFFERS_CUSTOMER_OFFERS_LIST],
      },
      {
        text: 'Create offer',
        path: PATH_OFFERS_OFFER_TOOL,
        ...ACCESS_MODULE_MAP[PATH_OFFERS_OFFER_TOOL],
      },
    ],
    module: INHERIT_MODULES,
    accountType: customerAccounts,
  },
  {
    key: 'order-management',
    path: new RegExp(`^${PATH_ORDERS}`),
    icon: mdiCube,
    text: 'Order management',
    dropdown: [
      {
        path: PATH_ORDERS_LIST,
        text: 'Orders',
        ...ACCESS_MODULE_MAP[PATH_ORDERS_LIST],
      },
    ],
    module: INHERIT_MODULES,
    accountType: customerAccounts,
  },
  {
    key: 'documents',
    path: new RegExp(`^${PATH_DOCUMENTS}$`),
    text: 'Documents',
    icon: mdiFileDocumentEdit,
    dropdown: [
      {
        path: PATH_DOCUMENTS,
        text: 'Documentation',
        ...ACCESS_MODULE_MAP[PATH_DOCUMENTS],
      },
    ],
    module: INHERIT_MODULES,
    accountType: customerAccounts,
  },
];


export const NAV_LIST: NavigationList = [
  ...backOffice,
  ...customer,
  {
    key: 'documents',
    to: PATH_DOCUMENTS,
    path: new RegExp(`^${PATH_DOCUMENTS}$`),
    icon: mdiFileDocumentEdit,
    text: 'Documents',
    hiddenOnMobile: true,
    ...ACCESS_MODULE_MAP[PATH_DOCUMENTS],
  },
];


