import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import React from "react";
import {Box, Grid} from "@material-ui/core";

export type UserCompanyReferencesProps = {
    ourReferenceEmail?: string;
    ourReferencePhoneNumber?: string;
    yourReferenceEmail?: string;
    yourReferencePhoneNumber?: string;
}

export const UserCompanyReferencesForm = (
    {
        ourReferenceEmail,
        ourReferencePhoneNumber,
        yourReferenceEmail,
        yourReferencePhoneNumber
    }: UserCompanyReferencesProps) => {

    const {t} = useTranslation();
    const {register} = useForm<UserCompanyReferencesProps>();

    return (
        <Grid container direction="row" spacing={1}>
            <legend>
                {t('common.Company references')}
            </legend>
            <Grid item xs={12} lg={6}>
                <label htmlFor='ourReferenceEmail'>{t('common.Company our reference email')}</label>
                <input
                    disabled={true}
                    id='ourReferenceEmail'
                    className='form-control'
                    defaultValue={ourReferenceEmail}
                    {...register('ourReferenceEmail')} />
            </Grid>
            <Grid item xs={12} lg={6}>
                <label htmlFor='yourReferenceEmail'>{t('common.Company your reference email')}</label>
                <input
                    disabled={true}
                    id='yourReferenceEmail'
                    className='form-control'
                    defaultValue={yourReferenceEmail}
                    {...register('yourReferenceEmail')} />
            </Grid>
            <Grid item xs={12} lg={6}>
                <label htmlFor='ourReferencePhoneNumber'>{t('common.Company our reference phone number')}</label>
                <input
                    disabled={true}
                    id='ourReferencePhoneNumber'
                    className='form-control'
                    defaultValue={ourReferencePhoneNumber}
                    {...register('ourReferencePhoneNumber')} />
            </Grid>
            <Grid item xs={12} lg={6}>
                <label htmlFor='yourReferencePhoneNumber'>{t('common.Company your reference phone number')}</label>
                <input
                    disabled={true}
                    id='yourReferencePhoneNumber'
                    className='form-control'
                    defaultValue={yourReferencePhoneNumber}
                    {...register('yourReferencePhoneNumber')} />
            </Grid>
            <Grid item xs={12}>
                <Box pt={2}>
                    <hr className="form-delimiter"/>
                </Box>
            </Grid>
        </Grid>
    );
}