import React, {useState, useEffect, useMemo, useCallback} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';
import {useParams} from 'react-router-dom';


import {AppState} from '../../store';
import {setShowLoader} from '../../store/system';
import {getCustomer} from '../../store/companies';
import {updateUser, getUsers} from '../../store/users';
import {Customer} from '../../store/companies/types';
import { UserType } from '../../store/users/types';

import {
    isBackOfficeAccount,
    isCustomerAccount,
    isAdminAccount,
    isSuperAdminAccount,
} from '../../lib/permissions';

import {
    AdminAccountForm,
    AdminAccountPrivileges,
    CustomerAccountForm,
    AssociatedCompanyForm,
    UserClauseForm,
    AdminAccountFieldsInput,
    AdminModulesFieldsInput,
    CustomerAccountFieldsInput,
} from '../../components/User/UserForm';
import { ErrorResponseResolver } from '../../lib/responseHelper';
import { toggleToast } from '../../store/components';
import {UserCompanyReferencesForm} from "../../components/User/UserCompanyReferencesForm";
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

const connector = connect(
    (state: AppState) => ({
        sessionUser: state.system.sessionUser,
        sessionUserType: state.system.sessionUserType,
        users: state.users.list,
        company: state.companies.selected,
        companies: state.companies.list,
    }),
    {setShowLoader, getCustomer, getUsers, updateUser, toggleToast}
);

type EditUserProps = ConnectedProps<typeof connector> & {};

export function EditUser({
    sessionUser,
	sessionUserType,
	users,
	company,
	setShowLoader,
	getCustomer,
	getUsers,
	updateUser,
	toggleToast
}: EditUserProps) {
    const {t} = useTranslation();
    const {userId} = useParams() as { 
        userId: string;
      }

    const {data: usersData, loading: userLoading, error: userError} = users;

    useEffect(() => {
        getUsers({})
    }, [getUsers]);
    
    
    const [selectedCompany, setSelectedCompany] = useState<Customer | null>(null);
   

    const handleInput = useForm<any>({mode: 'onChange'});

    const {
        reset,
        formState: {isSubmitting},
    } = handleInput;
    
    
    const user = usersData?.find(u => u.id === userId)
    const editedUserUserType = user?.userPermission.userType
    const [selectedUserType, setSelectedUserType] = useState<UserType>(
        editedUserUserType!
      );
     
      const showLoader =
        user === null ||
        editedUserUserType === null ||
        userLoading ||
        (editedUserUserType !== undefined &&
            isCustomerAccount(editedUserUserType) &&
            !!user?.userPermission.belongsToCustomer &&
            (company.data === null && !company.error)) ||
        isSubmitting;
        
        
        const availableUserTypes = useMemo(() => {
            const availableUserTypes = [
              {
                value: `${UserType.MainAccount}`,
                label: t('common.Main account'),
              },
              {
                value: `${UserType.SubAccount}`,
                label: t('common.Sub account'),
              },
            ];
        
            if (isSuperAdminAccount(sessionUserType)) {
              availableUserTypes.unshift(
                {
                  value: `${UserType.SuperAdmin}`,
                  label: t('common.Super admin'),
                },
                {
                  value: `${UserType.Admin}`,
                  label: t('common.Admin'),
                }
              );  
            }
            return availableUserTypes;
          }, [sessionUserType, t]);
       
    
        

    useEffect(() => {
        if (!userLoading && !userError && user) {
            if (
                selectedUserType &&
                user?.userPermission.belongsToCustomer
            ) {
                getCustomer(user.userPermission.belongsToCustomer);
            }

            if (selectedUserType === UserType.SuperAdmin) {
                const data: Partial<AdminAccountFieldsInput & AdminModulesFieldsInput> = {
                    email: user.userCredential.email,
                    name: user.userInformation?.name,
                    phoneNumber: user.userInformation?.phoneNumber,
                    position: user.userInformation?.position,
                    accessModules: user.userPermission.accessModules.map(
                        ({module}) => module
                    ),
                    userClause: user.userClause,
                };
                

                reset({...data});
            }

            if (selectedUserType === UserType.Admin) {
                const data: Partial<AdminAccountFieldsInput & AdminModulesFieldsInput> = {
                    email: user.userCredential.email,
                    name: user.userInformation?.name,
                    phoneNumber: user.userInformation?.phoneNumber,
                    position: user.userInformation?.position,
                    accessModules: user.userPermission.accessModules.map(
                        ({module}) => module
                    ),
                    userClause: user.userClause,
                };

                reset({...data});
            }

            if (isCustomerAccount(selectedUserType)) {
                const data: Partial<CustomerAccountFieldsInput> = {
                    email: user.userCredential.email,
                    name: user.userInformation?.name,
                    phoneNumber: user.userInformation?.phoneNumber,
                    position: user.userInformation?.position,
                };

                reset({...data});
            }

        }
    }, [user, userLoading, userError, editedUserUserType, getCustomer, reset]);
    
    useEffect(() => {
    	if (company.loading) return
		setSelectedCompany(company.data)
    }, [setSelectedCompany, company]);

    useEffect(() => {
        setShowLoader(showLoader);
    }, [showLoader, setShowLoader]);
    const onSelectAccountType = useCallback(
        (e: React.FormEvent<HTMLInputElement>) =>
          setSelectedUserType((e.currentTarget.value as any) as UserType),
        []
      );

    const toggleToastMessage = useCallback((statusCode: number, message: string) => {
        toggleToast({
            shouldToggle: true,
            message: t(message),
            status: statusCode
        })
    }, [toggleToast])

    const onSubmit = async (input: any) => {
        if (!user) {
            // todo: probably impossible
            return;
        }
        const belongsToCompany = user.userPermission.belongsToCustomer;
       
        if (isCustomerAccount(selectedUserType)) {
            const formInput = input as CustomerAccountFieldsInput;

            if(belongsToCompany){
                const response = await updateUser({
                    userId,
                    userClause: user.userClause,
                    payload: {
                        Customer: {
                            name: formInput.name,
                            email: formInput.email,
                            phoneNumber: formInput.phoneNumber,
                            position: formInput.position,
                            belongsToCompany: belongsToCompany,
                            userType:selectedUserType
                        },
                    },
                    callback: (errorResponseResolver: ErrorResponseResolver) => toggleToastMessage(errorResponseResolver.status, errorResponseResolver.message)
                })
                if(response?.payload)
                    toggleToastMessage(200, 'common.Saved')
            }
        } else if (isBackOfficeAccount(selectedUserType)) {
            const formInput = input as AdminAccountFieldsInput;
            const {accessModules} = input as AdminModulesFieldsInput;

            if(belongsToCompany){
                const userObj = {
                    name: formInput.name,
                    email: formInput.email,
                    phoneNumber: formInput.phoneNumber,
                    position: formInput.position,
                    belongsToCompany: belongsToCompany,
                    userType:selectedUserType
                };

                const payload = isAdminAccount(selectedUserType)
                    ? {
                        admin: {
                            ...userObj,
                            accessModules: accessModules.map((module) => ({module})),
                        },
                    }
                    : {
                        superAdmin: userObj,
                    };
                const response = await updateUser({
                    userId,
                    userClause: {
                        id: user.userClause.id,
                        offerLimitation: sessionUser?.userPermission.userType === 'SUPER_ADMIN' ? formInput.userClause.offerLimitation : user.userClause.offerLimitation,
                        offerMinCoverage:
                            sessionUser?.userPermission.userType === 'SUPER_ADMIN' ? formInput.userClause.offerMinCoverage : user.userClause.offerMinCoverage
                    },
                    payload,
                    callback: (errorResponseResolver: ErrorResponseResolver) => toggleToastMessage(errorResponseResolver.status, errorResponseResolver.message)
                })
                if(response?.payload)
                    toggleToastMessage(200, 'common.Saved')
            }
        }
    };

    if (!user) {
        return (
            <div>
                <h1 data-testid="edit_user_headline">
                    {t('navigation.General__nav.Manage users')}
                </h1>
            </div>
        );
    }

    const showUserCompanyReferencesForm = (): boolean => {
        return isBackOfficeAccount(sessionUserType)
            || sessionUser?.userPermission?.belongsToCustomer === selectedCompany?.id;
    }

    const shouldEditAdmin = sessionUserType === UserType.SuperAdmin || (sessionUserType === UserType.Admin && user?.userPermission.userType !== UserType.SuperAdmin);

    return (
        <div>
            <h1 data-testid="edit_user_headline">
                {t('navigation.General__nav.Manage users')}
            </h1>
            <form onSubmit={handleInput.handleSubmit(onSubmit)} > 
            {isBackOfficeAccount(sessionUserType) && shouldEditAdmin && (
              <>
             <FormControl component="fieldset">
                <RadioGroup value={selectedUserType} onChange={onSelectAccountType}>
                            <Box display="flex" style={{ gap: 20 }}>
                                {availableUserTypes.map((opt, index) => <FormControlLabel
                                    key={index}
                                    value={opt.value}
                                    control={<Radio size="small" color="primary" disableRipple />}
                                    label={<Typography className="form-check-label" component="label" variant="subtitle2" style={{ marginTop: 1 }} noWrap>
                                        {opt.label}
                                    </Typography>}
                                    labelPlacement="end" />
                                )}
                            </Box>
                        </RadioGroup>
                    </FormControl>
                    <hr className="form-delimiter" />
                    </> 
            )}
                <AssociatedCompanyForm
                    handleInput={handleInput}
                    company={selectedCompany}
                />
                {showUserCompanyReferencesForm() &&
                    <UserCompanyReferencesForm {...selectedCompany?.InternalCustomer}/>
                } {isCustomerAccount(selectedUserType) && (
                    <CustomerAccountForm handleInput={handleInput} isEdit={!(sessionUserType === UserType.SuperAdmin)} />
                )}
                {isBackOfficeAccount(selectedUserType) && (
                    <AdminAccountForm handleInput={handleInput} isEdit={!(sessionUserType === UserType.SuperAdmin)} />
                    
                )}
                
                {isAdminAccount(selectedUserType) && (
                    <>
                        { sessionUser?.userPermission.userType === 'SUPER_ADMIN' &&
                            <UserClauseForm handleInput={handleInput} />
                        }
                       <AdminAccountPrivileges handleInput={handleInput}/>
                    </>
                )}
                 
                 {isSuperAdminAccount(selectedUserType) && (
                    <> 
                        { sessionUser?.userPermission.userType === 'SUPER_ADMIN' &&
                            <UserClauseForm handleInput={handleInput} />
                        }
                        <AdminAccountPrivileges handleInput={handleInput} readOnly={true} toolTipText={"En superadmins rättigheter kan inte editeras."} />
                    </>
                )}
            
                <button
                    type="submit"
                    className="btn btn-primary mr-2"
                    data-testid="submit__user_form">
                    {t('common.Save data')}
                </button>
            </form>
        </div>
    );
}

export default connector(EditUser);



