import React, { useEffect } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { useForm } from "react-hook-form"
import { useHistory, useParams } from 'react-router-dom'
import { AppState } from '../../../store'
import { PATH_BANNERMESSAGE_BACK_OFFICE_LIST } from '../../../router/paths'
import { editBannerMessage } from '../../../store/bannermessages'
import { EditMessageForm } from './EditMessageForm'
import { ErrorResponseResolver } from '../../../lib/responseHelper'
import { toggleToast } from '../../../store/components';
import {CreateBannerMessageFormType} from "../../../store/bannermessages/types";
import useGetSpecificMessage from "../../../hooks/useMessageBanner";
import {useTranslation} from "react-i18next";

const connector = connect(
    (state: AppState) => ({
        selectedMessage: state.bannerMessages.selected,
        activeUser: state.system.sessionUser,
    }),
    { toggleToast, editBannerMessage }

);

type EditBannerMessageProps = ConnectedProps<typeof connector> & {};

export function EditMessage({
                             editBannerMessage,
                             toggleToast,
                         }: EditBannerMessageProps) {

    const { t } = useTranslation();
    const history = useHistory();
    const { messageId } = useParams() as { messageId: string }

    const {getSpecificBannerMessage, message} = useGetSpecificMessage()

    useEffect(() => {
        getSpecificBannerMessage(messageId)
    }, [])

    const handler = useForm<CreateBannerMessageFormType>({ mode: 'onSubmit' });

    const handleSubmit = (async (createBannerMessageFormType: CreateBannerMessageFormType) => {
        let feedback = true;
        await editBannerMessage({
            createBannerMessageFormType,
            bannerMessageId: messageId,
            callback: (errorResponseResolver: ErrorResponseResolver) => {
                toggleToast({
                    shouldToggle: true,
                    message: errorResponseResolver.message,
                    status: errorResponseResolver.status
                })
                feedback = false;
            }
        }).then(() => {
            if(feedback) {
                let msg = t('common.Saved');
                toggleToast({
                    shouldToggle: true,
                    message: msg,
                    status: 200
                })
            }
            feedback = false;
        })
        history.push(PATH_BANNERMESSAGE_BACK_OFFICE_LIST)
    })

    return (
        <div>
            <h1 data-testid="manage_companies_headline">Meddelandehantering</h1>
            <h4>Editera meddelande</h4>
            <EditMessageForm
                handler={handler}
                handleSubmit={handleSubmit}
                isEdit={true}
                selectedMessage={message}
            />
        </div>
    );
}

export default connector(EditMessage);