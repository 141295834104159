export const ENDPOINTS = {
  ANONYMOUS_SCOPES: '/anonymous/scopes',
  REQUEST_RESET_PASSWORD: '/anonymous/resetpassword',
  UPDATE_PASSWORD: '/anonymous/updatepassword/:token',
  ACTION_NEEDED: '/actionneeded/',
  ACTIVATE_ACCOUNT: '/anonymous/activation/:token/activate',
  GET_VERSION: '/anonymous/version',

  AUTH_READ: '/auth/read',
  AUTH_CREATE: '/auth/create',
  AUTH_DESTROY: '/auth/destroy',
  AUTH_INFO: '/auth/info',
  AUTH_CHANGE_USER: '/auth/change/:userId',

  ACCOUNT_MANAGEMENT_USER_CHANGE_PASSWORD:
    '/accountmanagement/user/changepassword',
  GDPR_REPORT: '/logs/:userId/file/read',
  ACCOUNT_MANAGEMENT_READ_USERS: '/accountmanagement/users/read',
  ACCOUNT_MANAGEMENT_CREATE_USER:
    '/accountmanagement/user/activation/:customerId/create',
  ACCOUNT_MANAGEMENT_READ_SPECIFIC_USER: '/accountmanagement/user/:userId/read',
  ACCOUNT_MANAGEMENT_UPDATE_USER: '/accountmanagement/user/:userId/update',
  ACCOUNT_MANAGEMENT_UPDATE_PROFILE:
    '/accountmanagement/user/:userId/own/update',
  ACCOUNT_MANAGEMENT_DELETE_USER: '/accountmanagement/user/:userId/destroy',
  ACCOUNT_MANAGEMENT_RESEND_EMAIL: '/anonymous/activation/resend/:id',

  ACCOUNT_MANAGEMENT_READ_CUSTOMERS: '/accountmanagement/customers/read',
  ACCOUNT_MANAGEMENT_CREATE_CUSTOMER: '/accountmanagement/customer/create',
  ACCOUNT_MANAGEMENT_READ_CUSTOMER: '/accountmanagement/customer/read',
  ACCOUNT_MANAGEMENT_READ_CUSTOMER_USERS:
    '/accountmanagement/customer/:companyId/users',
  ACCOUNT_MANAGEMENT_READ_SPECIFIC_CUSTOMER:
    '/accountmanagement/customer/:customerId/read',
  ACCOUNT_MANAGEMENT_UPDATE_CUSTOMER:
    '/accountmanagement/customer/:customerId/update',
  ACCOUNT_MANAGEMENT_CUSTOMER_UPLOAD_FILE:
    '/accountmanagement/customer/:companyId/uploadfile',
  ACCOUNT_MANAGEMENT_CUSTOMER_UPLOAD_AGREEMENT_FILE:
    '/accountmanagement/customer/:companyId/agreement/uploadfile',
  ACCOUNT_MANAGEMENT_CUSTOMER_DELETE_FILE:
    '/accountmanagement/customer/:companyId/:fileId/deletefile',
  ACCOUNT_MANAGEMENT_CUSTOMER_REQUEST_FILE:
    '/accountmanagement/customer/:companyId/:fileId/requestfile',
  ACCOUNT_MANAGEMENT_READ_PREDEFINED_ACCOUNTS:
    '/accountmanagement/accounts/read',
  ACCOUNT_MANAGEMENT_READ_PROJECTS: '/accountmanagement/projects/read',
  ACCOUNT_MANAGEMENT_READ_COST_CENTERS: '/accountmanagement/costcenters/read',
  ACCOUNT_MANAGEMENT_READ_PRICE_LIST: '/accountmanagement/pricelists/read',
  ACCOUNT_MANAGEMENT_READ_CURRENCY: '/accountmanagement/currencies/read',
  ACCOUNT_MANAGEMENT_READ_ORDER_LIMITS: '/ordermanagement/orders/payment/read',
  ACCOUNT_MANAGEMENT_READ_ADD_ARTICLE_TO_ORDER:
    '/ordermanagement/order/:orderId/addArticle/:fortnoxArticleId',
  ORDER_MANAGEMENT_REMOVE_ARTICLE_FROM_ORDER:
    '/ordermanagement/order/:orderId/removeArticle/:fortnoxArticleId',
  ACCOUNT_MANAGEMENT_SIGN_AGREEMENT:
    '/accountmanagement/customer/:userId/:fileId/agreement/sign',
  ACCOUNT_MANAGEMENT_TERMS_OF_DELIVERIES:
    '/accountmanagement/termsofdeliveries/read',
  ACCOUNT_MANAGEMENT_TERMS_OF_PAYMENTS:
    '/accountmanagement/termsofpayments/read',
  ACCOUNT_MANAGEMENT_WAY_OF_DELIVERIES:
    '/accountmanagement/wayofdeliveries/read',

  // TODO delete all old Company endpoints use Customer's
  ACCOUNT_MANAGEMENT_READ_COMPANIES: '/accountmanagement/companies/read',
  ACCOUNT_MANAGEMENT_CREATE_COMPANY: '/accountmanagement/company/create',
  ACCOUNT_MANAGEMENT_READ_SPECIFIC_COMPANY:
    '/accountmanagement/company/:companyId/read',
  ACCOUNT_MANAGEMENT_UPDATE_COMPANY:
    '/accountmanagement/company/:companyId/update',
  ACCOUNT_MANAGEMENT_DELETE_COMPANY:
    '/accountmanagement/company/:companyId/destroy',
  ACCOUNT_MANAGEMENT_COMPANY_UPLOAD_FILE:
    '/accountmanagement/company/:companyId/uploadfile',
  ACCOUNT_MANAGEMENT_COMPANY_DELETE_FILE:
    '/accountmanagement/company/:companyId/:fileId/deletefile',
  ACCOUNT_MANAGEMENT_COMPANY_REQUEST_FILE:
    '/accountmanagement/company/:companyId/:fileId/requestfile',

  ACCOUNT_MANAGEMENT_CREATE_COMMENTARY:
    '/accountmanagement/customer/:customerId/commentary/create',
  ACCOUNT_MANAGEMENT_UPDATE_COMMENTARY:
    '/accountmanagement/customer/:customerId/commentary/update',
  ACCOUNT_MANAGEMENT_READ_COMMENTARIES:
    '/accountmanagement/customer/:customerId/commentaries/read',
  ACCOUNT_MANAGEMENT_DELETE_COMMENTARY:
    '/accountmanagement/customer/:customerId/:commentId/commentary/destroy',

  ACCOUNT_MANAGEMENT_READ_NEWS: '/accountmanagement/news/read',
  ACCOUNT_MANAGEMENT_READ_SPECIFIC_NEWS: '/accountmanagement/news/:newsId/read',
  ACCOUNT_MANAGEMENT_CREATE_NEWS: '/accountmanagement/news/create',
  ACCOUNT_MANAGEMENT_EDIT_NEWS: '/accountmanagement/news/:newsId/update',
  ACCOUNT_MANAGEMENT_DELETE_NEWS: '/accountmanagement/news/:newsId/destroy',

  ESHOP_BACK_PRODUCT_CATEGORY_CREATE: '/eshopback/category/create',
  ESHOP_BACK_PRODUCT_CATEGORY_EDIT: '/eshopback/category/:categoryId/edit',
  ESHOP_BACK_PRODUCT_CATEGORY_DELETE: '/eshopback/category/:categoryId/destroy',
  ESHOP_BACK_PRODUCT_CATEGORIES_READ: '/eshopback/categories/read',
  ESHOP_BACK_PRODUCT_CATEGORY_READ: '/eshopback/category/:categoryId/read',

  ESHOP_BACK_ARTICLES_UNMAPPED_READ: '/eshopback/articles/unmapped/read',
  ESHOP_BACK_ARTICLES_UNMAPPED_DELETE:
    '/eshopback/articles/deleteandunmap/:internalArticleId',
  ESHOP_BACK_ARTICLES_CREATE: '/eshopback/article/:fortNoxArticleId/create',
  ESHOP_BACK_READ_SPECIFIC_ARTICLE:
    '/eshopback/article/:internalArticleId/read',
  ESHOP_BACK_PRODUCT_DELETE_PRODUCT_IMAGE:
    '/eshopback/article/document/:articleId/:fileId/delete',

  E_SHOP_BACK_UPDATE_CATEGORY_ORDER: '/eshopback/product/updatecategory',
  ESHOP_BACK_PRICE_GROUPS_READ: '/eshopback/pricegroups/read',
  ESHOP_BACK_PRICE_GROUPS_CREATE: '/eshopback/pricegroup/create',
  ESHOP_BACK_PRICE_GROUPS_UPDATE:
    '/eshopback/pricegroup/:internalArticleId/update',
  ESHOP_BACK_PRICE_GROUP_READ: '/eshopback/pricegroup/:priceGroupId/read',
  ESHOP_BACK_PRICE_GROUP_DELETE: '/eshopback/pricegroup/:priceGroupId/destroy',
  ESHOP_BACK_EDIT_PRODUCT: '/eshopback/article/:internalArticleId/edit',

  E_SHOP_FRONT_RESOLVE_CATEGORY_NAMES: '/eshopfront/categories/resolve/read',
  E_SHOP_FRONT_CART_READ: '/eshopfront/cart/read',
  E_SHOP_FRONT_CART_ADD_PRODUCT: '/eshopfront/cart/:internalArticleId/create',
  E_SHOP_FRONT_CART_DELETE_PRODUCT:
    '/eshopfront/cart/:productIdentifier/destroy',
  E_SHOP_FRONT_CART_CHECKOUT: '/eshopfront/cart/checkout',
  E_SHOP_FRONT_CART_DESTROY: '/eshopfront/cart/destroy',
  E_SHOP_FRONT_PRODUCT_CATEGORIES_READ: '/eshopfront/categories/read',
  E_SHOP_FRONT_COUNTRIES_READ: '/eshopfront/countries/read',
  E_SHOP_FRONT_ARTICLES_RECOMMENDED_READ: '/eshopfront/articles/recommended/read',
  E_SHOP_FRONT_FILE:
    '/accountmanagement/customer/:customerId/:fileId/requestfile',
  E_SHOP_FRONT_DOWNLOAD_PRICE_LIST: '/eshopfront/prices/file/:customerId/read',
  E_SHOP_FRONT_DOWNLOAD_PRICE_LIST_CSV: '/eshopfront/prices/file/csv/:customerId/read',
  E_SHOP_FRONT_UPLOAD_B2C: '/eshopfront/cart/uploadCVS/create',
  E_SHOP_FRONT_CHECKOUT_INFORMATION: '/eshopfront/checkout/information',
  CHECKOUT_WAY_OF_DELIVERY: '/accountmanagement/wayofdeliveries/read',

  DOCUMENTS_FILES_READ: '/documents/files/read',
  DOCUMENTS_FILES_CREATE: '/documents/files/create',
  DOCUMENTS_FILES_DELETE: '/documents/files/delete',
  DOCUMENTS_FILES_RENAME: '/documents/file/rename',
  DOCUMENTS_FILES_DOWNLOAD: '/documents/files/download',
  DOCUMENTS_FILE_DOWNLOAD: '/documents/file/download',
  DOCUMENTS_DIRECTORY_DOWNLOAD: '/documents/directory/download',
  DOCUMENTS_DIRECTORIES_READ: '/documents/directory/read',
  DOCUMENTS_DIRECTORIES_CREATE: '/documents/directory/create',
  DOCUMENTS_DIRECTORIES_DELETE: '/documents/directory/delete',
  DOCUMENTS_DIRECTORIES_RENAME: '/documents/directory/rename',

  CASE_MANAGEMENT_GET_CASES: '/case/cases/all/read',
  CASE_MANAGEMENT_GET_CUSTOMER_RELATED_CASES: '/case/cases/related/read',
  CASE_MANAGEMENT_GET_USER_RELATED_CASES: '/case/cases/user/related/read',
  CASE_MANAGEMENT_GET_SPECIFIC_CASE: '/case/:serviceCaseId/read',
  CASE_MANAGEMENT_GET_CATEGORIES: '/case/cases/categories/all/read',
  CASE_MANAGEMENT_DELETE_CASE: '/case/delete/:serviceCaseId',
  CASE_MANAGEMENT_DELETE_CASE_ATTACHMENT:
    '/case/:serviceCaseId/attachment/:attachmentId/delete',
  CASE_MANAGEMENT_CREATE_CASE: '/case/create',
  CASE_MANAGEMENT_EDIT_CASE: '/case/update/entity/:serviceCaseId',
  CASE_MANAGEMENT_DOWNLOAD_ATTACHMENT:
    '/case/:serviceCaseId/attachment/:fileId/read',
  CASE_MANAGEMENT_ADD_CASE_COMMENT: '/case/comment/:serviceCaseId/create',
  CASE_MANAGEMENT_ASSIGN_CASE_TO_USER: '/case/:serviceCaseId/assign',

  BANNER_MESSAGES_GET_ALL_MESSAGES: '/bannermessage/all/read',
  BANNER_MESSAGES_CREATE_MESSAGE: '/bannermessage/create',
  BANNER_MESSAGES_DELETE_MESSAGE: '/bannermessage/delete/:messageId',
  BANNER_MESSAGES_GET_ALL_ACTIVE_MESSAGES: '/bannermessage/all/active/read',
  BANNER_MESSAGES_INACTIVATE_MESSAGE: '/bannermessage/:messageId/inactivate',
  BANNER_MESSAGES_EDIT_MESSAGE: '/bannermessage/edit/:messageId',
  BANNER_MESSAGES_GET_SPECIFIC_MESSAGES: '/bannermessage/read/:messageId',
	
  ORDER_MANAGEMENT_READ_ORDERS_FILTER: '/ordermanagement/orders/filter/read',
  MONITOR_CREATE: '/monitor/create',
  MONITOR_GET_ALL: '/monitor/read',
  MONITOR_DELETE: '/monitor/:id/destroy',
};
