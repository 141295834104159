import React, { useState, MouseEventHandler, useMemo, useEffect } from 'react';
import { useRouteMatch, Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import Icon from '@mdi/react';
import { mdiChevronRight, mdiChevronDown, mdiFace } from '@mdi/js';

import './Nav.scss';

import { NavigationList } from '../../router/paths';

import { hasAccess } from '../../lib/permissions';

import { AppState } from '../../store';
import DropdownButton from '../DropdownButton';
import useFetchActionsNeeded from '../../hooks/useFetchActionsNeeded';
import {Action} from '../../types/actions/action';
import {AccessModuleName} from '../../types/permission/accessModuleName';

type NavDropdownItem = {
  text: string;
  path: string;
  exact?: boolean;
  routeMatch?: string | string[];
};

type NavItemProps = {
  id: string;
  icon: string;
  text: string;
  actionsNeeded: Action[];
  to?: string;
  isActive?: boolean;
  dropdown?: NavDropdownItem[];
  hiddenOnMobile?: boolean;
  onClick: MouseEventHandler;
  onRouteChange: () => void;
};

type NavDropdownProps = {
  dropdown: NavDropdownItem[];
  parent: string;
};

type NavDropdownItemProps = NavDropdownItem;

export function NavDropdownItem({
  text,
  path,
  exact,
  routeMatch,
}: NavDropdownItemProps) {
  const isActive = useRouteMatch({
    path: routeMatch
      ? typeof routeMatch === 'string'
        ? [path, routeMatch]
        : [path, ...routeMatch]
      : path,
    exact,
  });

  return (
    <li className={classnames('nav__dropdown__item', { active: isActive })}>
      <Link to={path}>{text}</Link>
    </li>
  );
}

export function NavDropdown({ dropdown, parent }: NavDropdownProps) {
  const { t } = useTranslation();
  return (
    <ul className="nav__dropdown__list">
      {dropdown.map(({ text, ...rest }) => {
        const translated = t(`navigation.MavenProject: com.eon:eon-master:0.0.1-SNAPSHOT @ /home/vsts/work/1/s/pom.xml__nav.${text}`);
        return <NavDropdownItem key={text} text={translated} {...rest} />;
      })}
    </ul>
  );
}

export function NavItem({
  icon,
  text,
  actionsNeeded,
  isActive,
  dropdown,
  to,
  id,
  onClick,
  onRouteChange,
  hiddenOnMobile,
}: NavItemProps) {
  const { t } = useTranslation();

  const translated = t(`navigation.${text}`);
  const hasDropdown = Array.isArray(dropdown);

  const mapNavItemActionNeededCount = (navItemText: string): number | string => {
    if(navItemText === 'Offer management') {
      const offersActionsNeeded = actionsNeeded.find(
        f => f.accessModuleName === AccessModuleName.OfferGeneration)?.numbers ?? 0;
      
      return offersActionsNeeded > 0 ? offersActionsNeeded : '';
    }
    if(navItemText === 'Case management') {
      const casesActionsNeeded = actionsNeeded.find(
        f => f.accessModuleName === AccessModuleName.CaseManagement)?.numbers ?? 0;
      
      return casesActionsNeeded > 0 ? casesActionsNeeded : '';
    }
    if(navItemText === 'Order management') {
      const casesActionsNeeded = actionsNeeded.find(
        f => f.accessModuleName === AccessModuleName.OrderManagement)?.numbers ?? 0;
      
      return casesActionsNeeded > 0 ? casesActionsNeeded : '';
    }
    return '';
  }

  const item = (
    <>
      {dropdown != undefined && dropdown[0] !== undefined ?
        <>
          <Link to={dropdown[0].path} className="nav__item__link" style={{ paddingLeft: 0}}>
          <Icon path={icon} size="24" className="nav__icon icon-24" />
          {translated}
          </Link>
          {hasDropdown && (
            <>
              <span className="badge badge-primary">{mapNavItemActionNeededCount(text)}</span>
              <div className="nav__dropdown_icon">
                <Icon
                  path={isActive ? mdiChevronDown : mdiChevronRight}
                  size="24"
                  className="icon-24"
                />
              </div>
            </>
          )}
        </>
        : <></>}
    </>
  );

  return (
    <li className={hiddenOnMobile ? 'd-none d-lg-block' : ''}>
      <div
        className={classnames('nav__item', { active: isActive })}
        data-testid={`nav__item__com.eon.ui:eon-ui:war:1.0-SNAPSHOT`}
        onClick={onClick}
      >
        {hasDropdown ? (
          <span className="nav__item__link">{item}</span>
        ) : (
            <Link to={to ?? ''} className="nav__item__link">
              {item}
            </Link>
          )}
      </div>
      {hasDropdown && isActive && (
        <div onClick={onRouteChange}>
          <NavDropdown parent={text} dropdown={dropdown as NavDropdownItem[]} />
        </div>
      )}
    </li>
  );
}

export type NavProps = {
  navigationList: NavigationList;
  navigationFallback: string;
  changePassword: () => void;
  editUserProfile: () => void;
  GDPRReport: () => void;
  logout: () => void;
  isNavCollapsed: boolean;
  setIsNavCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function Nav({
  navigationList,
  navigationFallback,
  changePassword,
  editUserProfile,
  GDPRReport,
  logout,
  isNavCollapsed,
  setIsNavCollapsed,
}: NavProps) {
  const { path } = useRouteMatch();
  const history = useHistory();
  const [refreshActionsNeeded, setRefreshActionsNeeded] = useState<boolean>(true);
  const actionsNeeded = useFetchActionsNeeded({
    refreshActionsNeeded: refreshActionsNeeded,
    setRefreshActionsNeeded: setRefreshActionsNeeded
  });

  const {permissionAccessModule, sessionUserType } = useSelector(
    (state: AppState) => state.system
  );

  const accessibleNav = useMemo(
    () =>
      navigationList
        .filter(({ dropdown, ...rest }) => {
          return hasAccess(
            sessionUserType,
            permissionAccessModule,
            rest,
            dropdown?.map(({ path }) => path)
          );
        })
        .map((nav) => {
          // filter the dropdowns according to access and account type
          if (nav.dropdown) {
            nav.dropdown = nav.dropdown.filter((permissionRequired) => {
              // if accountType is specified then hide if we don't have the right account type or even when we don't have any
              return hasAccess(
                sessionUserType,
                permissionAccessModule,
                permissionRequired
              );
            });
          }

          return nav;
        })
		.filter(nav => nav.dropdown?.length),
    [navigationList, sessionUserType, permissionAccessModule]
  );
  
  const activeNav = useMemo(
    () => accessibleNav.find((accessible) => accessible.path.test(path)),
    [path, accessibleNav]
  );

  const [manualActiveNav, setManualActiveNav] = useState(
    activeNav?.key ?? navigationFallback
  );

  const { sessionUser } = useSelector((state: AppState) => state.system);

  const { t } = useTranslation();

  useEffect(() => {
    setManualActiveNav(activeNav?.key ?? navigationFallback);
  }, [activeNav, navigationFallback, setManualActiveNav]);

  return (
    <nav className="navbar-expand-lg navbar-light">
      <div
        className={`collapse navbar-collapse layout__nav align-items-start ${isNavCollapsed ? 'hidden' : ''
          }`}
        id="navbarMainMenu"
      >
        <div className="navbar-top-actions">
          <DropdownButton
            btnClassName="d-flex align-items-center text-body"
            menuClassName="w-100 left-0"
            id="user-name"
            label={
              <>
                <Icon path={mdiFace} size="34" className="icon-34" />
                <span className="layout__header__user__name">
                  {sessionUser?.userInformation?.name}
                </span>
              </>
            }
            dropdownList={[
              {
                text: t('common.Change password'),
                onClick: () => {
                  setIsNavCollapsed(!isNavCollapsed);
                  changePassword();
                },
              },
              {
                text: t('common.Profile'),
                onClick: () => {
                  setIsNavCollapsed(!isNavCollapsed);
                  editUserProfile();
                },
              },
              {
                text: t('common.GDPR report'),
                onClick: () => {
                  setIsNavCollapsed(!isNavCollapsed);
                  GDPRReport();
                },
              },
              {
                text: t('common.Log out'),
                onClick: logout,
              },
            ]}
          />
          <button
            className="navbar-toggler collapsed"
            type="button"
            data-toggle="collapse"
            data-target="#navbarMainMenu"
            aria-controls="navbarMainMenu"
            aria-expanded={!isNavCollapsed ? true : false}
            aria-label="Toggle navigation"
          >
            <div className="animated-icon">
              <span>x</span>
            </div>
          </button>
        </div>
        <ul className="nav__list">
          {accessibleNav.map(
            ({ key, icon, text, to, dropdown, hiddenOnMobile }) => {
              if (dropdown !== undefined
                && dropdown !== null) {
                return (
                  <NavItem
                    key={key}
                    id={key}
                    to={to}
                    icon={icon}
                    text={text}
                    actionsNeeded={actionsNeeded}
                    isActive={manualActiveNav === key}
                    dropdown={dropdown}
                    onClick={() => setManualActiveNav(key)}
                    onRouteChange={() => setIsNavCollapsed(!isNavCollapsed)}
                    hiddenOnMobile={hiddenOnMobile}
                  />
                );
              }
            }
          )}
        </ul>
      </div>
    </nav>
  );
}
