import { FormContextValues } from "react-hook-form"
import { useTranslation } from "react-i18next"
import formatTime from "../../lib/timeHelper"
import EditOrderInput from "../../pages/OrderManagement/EditOrderInput"
import ShipmentInformation from "../../pages/OrderManagement/ShipmentInformation"
import { Order, OrderInputValues } from "../../store/orders/types"
import { EntityData } from "../../store/types"
import DatePicker from "../Form/DatePicker"


export interface DeliveryDetailsCardProps {
	handleInput: FormContextValues<OrderInputValues>
	userAdminType: boolean
	data: EntityData<Order>
	readOnly: boolean
}

const DeliveryDetailsCard: React.FC<DeliveryDetailsCardProps> = ({
	handleInput,
	userAdminType,
	data,
	readOnly
}) => {
	const { t } = useTranslation()

	return (
		<>
			<div className="col-md-12 col-sm-12">
				<div className="row">
					<div className="col-12 mb-2" hidden={!readOnly && !userAdminType}>
						<div className="card">
							<div className='card-header' data-toggle="collapse" data-target="#deliveryinfo">
								{t('order.Delivery information')}
								<i className="float-right arrow down"></i>
								<i className="float-right arrow up"></i>
							</div>
							<div className="collapse show" id="deliveryinfo">
								<div className='card-body'>
									<EditOrderInput
										name={t('common.Shipment date')}
										value={
											data?.shipmentFromWMSDate != null
												? formatTime(data?.shipmentFromWMSDate)
												: ''
										}
									/>
									{ShipmentInformation({ shopOrderShipments: data != null ? data.shopOrderShipments : [] })}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default DeliveryDetailsCard