import { FormContextValues } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { OrderInputValues } from "../../store/orders/types"
import InputField from "../Form/InputField"
import TextAreaField from "../Form/TextAreaField"


export interface OtherInformationCardProps {
    handleInput: FormContextValues<OrderInputValues>
    userAdminType: boolean
    readOnly: boolean
}

const OtherInformationCard: React.FC<OtherInformationCardProps> = ({
    handleInput,
    userAdminType,
    readOnly
}) => {
    const { t } = useTranslation()

    return (
        <>
            <div className="col-md-12 col-sm-12">
                <div className="row">
                    <div className="col-12 mb-2" hidden={!readOnly && !userAdminType}>
                        <div className="card">
                            <div className='card-header' data-toggle="collapse" data-target="#otherinfo">
                                {t('order.Other')}
                                <i className="float-right arrow down"></i>
                                <i className="float-right arrow up"></i>
                            </div>
                            <div className="collapse show" id="otherinfo">
                                <div className='card-body'>
                                    <label>
                                        {t('order.Information to customer')}
                                    </label>
                                    <InputField
                                        name="customerDeliveryInformation"
                                        handler={handleInput}
                                        placeholder={t('order.Information to customer')}
                                        readOnly={readOnly}
                                    />
                                    <label>
                                        {t('common.Comment')}
                                    </label>
                                    <TextAreaField
                                        className="m-0 mb-4"
                                        name="orderComment"
                                        handler={handleInput}
                                        placeholder={t('common.Comment')}
                                        rows={3}
                                        readOnly={readOnly}
                                    />
                                    <label>
                                        {t('order.Information to warehouse')}
                                    </label>
                                    <TextAreaField
                                        className="m-0"
                                        name="wareHouseDeliveryInformation"
                                        handler={handleInput}
                                        placeholder={t('order.Information to warehouse')}
                                        rows={3}
                                        readOnly={readOnly}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OtherInformationCard