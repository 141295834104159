import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {DEFAULT_RULES} from '../../lib/form';

import InputField from '../Form/InputField';
import SelectField, {Option} from '../Form/SelectField';
import {Customer, InternalCustomerInformation} from "../../store/companies/types";
import {EntityData} from "../../store/types";
import { useGetCountries } from '../Form/SelectCountriesInput';
import { FormContextValues } from 'react-hook-form/dist/contextTypes';


export type MainAccountCompanyFormInput = {
    Customer: {
        Name: string
        Address1: string
        Address2: string
        ZipCode: string
        City: string
        Country: Option
        Phone1: string
        Phone2: string
        Email: string
        SalesAccount: string
        WWW: string
    };
    InternalCustomer: InternalCustomerInformation
};
export type MainAccountCompanyFormProps = {
    companyData?:EntityData<Customer>;
    handleInput: FormContextValues<MainAccountCompanyFormInput>;
    readOnly?: boolean;
};

const formColumnClass = 'col-md-12 col-lg-6';


export default function MainAccountCompanyForm({handleInput, readOnly,companyData}: MainAccountCompanyFormProps) {
    const {t} = useTranslation();
	const {countries: countryOptions} = useGetCountries();
    const {reset} = handleInput;

    useEffect(() => {
        if(!companyData) return
		reset({
			Customer: {
				...companyData.Customer,
				Country: countryOptions.find(({value}) => value === companyData.Customer.CountryCode)
			},
			InternalCustomer: {
				...companyData.InternalCustomer
			}
		})
    }, [companyData, countryOptions, reset ]);
	
    return (
        <>
            <legend className="mb-1" data-testid="form__main_account_company_form">
                {t('common.Company information')}
            </legend>
            <div className="form-row">
                <InputField
                    name="Customer.Name"
                    handler={handleInput}
                    label={t('common.Name')}
                    className={formColumnClass}
                    rules={{required: true}}
                    readOnly={readOnly}
                />
                <InputField
                    name="Customer.Email"
                    handler={handleInput}
                    label={t('common.E-mail address')}
                    className={formColumnClass}
                    readOnly={readOnly}
                    rules={{
                        required: false,
                        minLength: DEFAULT_RULES.email.minLength,
                        pattern: DEFAULT_RULES.email.pattern,
                    }}
                />
                <InputField
                    name="Customer.Address1"
                    handler={handleInput}
                    label={t('common.Address') + ' 1'}
                    placeholder={t('common.Address line 1')}
                    className={formColumnClass}
                    rules={{required: true}}
                    readOnly={readOnly}
                />
                <InputField
                    name="Customer.Address2"
                    handler={handleInput}
                    label={t('common.Address') + ' 2'}
                    placeholder={t('common.Address line 2')}
                    className={formColumnClass}
                    readOnly={readOnly}
                    rules={{required: false}}
                />
                <InputField
                    name="Customer.ZipCode"
                    handler={handleInput}
                    label={t('common.Zip code / city')}
                    placeholder={t('common.Zip c')}
                    className={'col-md-2 col-lg-2'}
                    rules={{required: true}}
                    readOnly={readOnly}
                />
                <InputField
                    name="Customer.City"
                    handler={handleInput}
                    label={''}
                    placeholder={t('common.City')}
                    className={'col-md-10 col-lg-4'}
                    rules={{required: true}}
                    readOnly={readOnly}
                />

                <SelectField
                    name="Customer.Country"
                    handler={handleInput}
                    readOnly={readOnly}
                    isSearchable={true}
                    rules={{required: false}}
                    label={t('common.Country')}
                    className={formColumnClass}
                    options={countryOptions}
                />
                <InputField
                    name="Customer.Phone1"
                    handler={handleInput}
                    label={t('common.Phone number 1')}
                    placeholder={t('common.Phone number 1')}
                    className={formColumnClass}
                    rules={{required: true}}
                    readOnly={readOnly}
                />
                <InputField
                    name="Customer.Phone2"
                    handler={handleInput}
                    label={t('common.Phone number 2')}
                    placeholder={t('common.Phone number 2')}
                    className={formColumnClass}
                    readOnly={readOnly}
                    rules={{required: false}}
                />
                <InputField
                    name="Customer.WWW"
                    handler={handleInput}
                    label={t('common.Web address')}
                    className={formColumnClass}
                    readOnly={readOnly}
                    rules={{required: false}}
                />
                <InputField
                    name="Customer.EmailInvoice"
                    handler={handleInput}
                    label="Fakturamail"
                    className={formColumnClass}
                    readOnly={readOnly}
                    rules={{required: false}}
                />
				<InputField
					name="Customer.YourReference"
					handler={handleInput}
					label={t('common.Your reference')}
					placeholder={t('common.First name, Last name')}
					className={formColumnClass}
					readOnly={readOnly}
					rules={{ required: true }}
				/>
				<InputField
					name="InternalCustomer.ourReferenceEmail"
					handler={handleInput}
					label="E-postadress"
					placeholder="E-postadress kontaktperson"
					className={formColumnClass}
					readOnly={readOnly}
					rules={{ required: true }}
				/>
				<InputField
					name="InternalCustomer.ourReferencePhoneNumber"
					handler={handleInput}
					label="Telefonnummer"
					placeholder="Telefonnummer kontaktperson"
					className={formColumnClass}
					readOnly={readOnly}
					rules={{ required: true }}
				/>
            </div>
        </>
    );
}
