import {createStore, combineReducers, applyMiddleware, Action} from 'redux';
import thunkMiddleware, {ThunkAction} from 'redux-thunk';
import {composeWithDevTools} from 'redux-devtools-extension';

import {loadState} from './utils';

import systemReducer from './system';
import ordersReducer from './orders';
import offersReducer from './offers';
import productsReducer from './products';
import articleReducer from './articles';
import cartReducer from './cart2';
import companiesReducer from './companies';
import usersReducer from './users';
import newsListReducer from './news';
import documentsReducer from './documents';
import productCategoriesReducer from './productCategories';
import priceGroupsReducer from './priceGroups';
import commentsReducer from './comments';
import checkoutReducer from './checkout';
import componentsReduced from './components';
import casesReducer from './cases';
import genericListOptionReducer from './genericListOption';
import bannerMessagesReducer from './bannermessages'

const rootReducer = combineReducers({
  system: systemReducer,
  orders: ordersReducer,
  offers: offersReducer,
  products: productsReducer,
  cart2: cartReducer,
  companies: companiesReducer,
  users: usersReducer,
  news: newsListReducer,
  documents: documentsReducer,
  productCategories: productCategoriesReducer,
  priceGroups: priceGroupsReducer,
  comments: commentsReducer,
  checkouts: checkoutReducer,
  articles: articleReducer,
  components: componentsReduced,
  cases: casesReducer,
  genericListOption: genericListOptionReducer,
  bannerMessages: bannerMessagesReducer
});

export type AppState = ReturnType<typeof rootReducer>;

export type AppThunk<ReturnType = Promise<any>, S = AppState> = ThunkAction<
  ReturnType,
  S,
  unknown,
  Action<String>
>;

export function initStore(initState?: Partial<AppState>) {
  const middlewares = [thunkMiddleware];
  const middleWareEnhancer = applyMiddleware(...middlewares);

  const store = createStore(
    rootReducer,
    {
      ...loadState(),
      ...initState,
    },
    composeWithDevTools(middleWareEnhancer)
  );

  return store;
}

export default initStore();
