import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';

import documentsService from '../../services/documents';
import {DocumentsState} from './types';
import {Folder, UploadedFile, LinkedList} from '../../types';
import {ListData} from '../types';
import {
  listPending,
  listFulfilled,
  listRejected,
  entityPending,
  entityFulfilled,
  entityRejected,
  listFulfilledMeta,
} from '../utils';

const name = 'documents';

export const getFolders = createAsyncThunk<ListData<Folder>, string | null>(
  `eon-ui/getFolders`,
  async function (parentId) {
    const {data, headers} = await documentsService.getFolders(parentId);
    const {directories, folderPath} = data;
    const folders = directories.map((dir:string) => ({
      "id": dir,
      "parentId": folderPath,
      "name": dir.slice(dir.lastIndexOf('/')+1,dir.length)
    }))
    return {
      data: folders as Folder[],
      total: +headers['x-total-count'],
    };
  }
);

export const getFiles = createAsyncThunk<ListData<UploadedFile>, string | null>(
  `eon-ui/getFiles`,
  async function (parentId) {
    const {data, headers} = await documentsService.getFiles(parentId);
    const {files, folderPath} = data;
    const fileList = files.map((file:string) => ({
      "id": file,
      "filePath": file,
      "parentId": folderPath,
      "uploadedAt": '',
      "name": file.slice(file.lastIndexOf('/')+1,file.length)
    }))
    return {
      data: fileList as UploadedFile[],
      total: +headers['x-total-count'],
    };
  }
);

export const resolveFolderStructure = createAsyncThunk<
  LinkedList<Folder>,
  string | null
>(`eon-ui/resolveFolderStructure`, async function (parentId) {
  const {data} = await documentsService.resolveFolder(parentId);
  const {folderPath} = data;
  const folders = folderPath.slice(1).split('/');
  const foldersArray:{
    id:string,
    parentId:string,
    name:string
  }[]=[];

  for (let i = 0; i < folders.length; i++) {
    if(i===0){
      foldersArray.push({
        id: '/'+folders[i],
        parentId: '',
        name: folders[i],
      });
    }else{
      foldersArray.push({
        id: foldersArray[i-1].id + '/'+folders[i],
        parentId: foldersArray[i-1].id,
        name: folders[i],
      });
    }
  }
  const resolver = (folderId:string|null) => {
    if (folderId === null) {
      return null;
    }
    const found = foldersArray.find(({id}) => id === folderId);
    if (!found) {
      return null;
    }
    const next:any = resolver(found.parentId);
    return {data: found, next} as any;
  };

  const  res = resolver(parentId);
  return res as LinkedList<Folder>;
});

export const createNewFolder = createAsyncThunk<
  Folder,
  {parentId: string | null; name: string}
>(`eon-ui/createNewFolder`, async function ({parentId, name}) {
  const {data} = await documentsService.createFolder(parentId, name);
  const folder = {
    id:data[0],
    parentId:parentId,
    name:name
  }
  return folder as Folder;
});

export const deleteFolders = createAsyncThunk<void, string[]>(
  `eon-ui/deleteFolders`,
  async function (folderIds) {
    await documentsService.deleteFolders(folderIds);
  }
);

export const deleteFiles = createAsyncThunk<void, string[]>(
  `eon-ui/deleteFiles`,
  async function (fileIds) {
    await documentsService.deleteFiles(fileIds);
  }
);

export const renameFolder = createAsyncThunk<void, {id: string; name: string}>(
  `eon-ui/renameFolder`,
  async function ({id, name}) {
    await documentsService.renameFolder(id, name);
  }
);
export const renameFile = createAsyncThunk<void, {id: string; name: string}>(
  `eon-ui/renameFile`,
  async function ({id, name}) {
    await documentsService.renameFile(id, name);
  }
);

export const initialState: DocumentsState = {
  folders: {data: [], loading: false, requestId: '', total: 0},
  files: {data: [], loading: false, requestId: '', total: 0},
  folderStructure: {data: null, loading: false, requestId: ''},
};

const documentsSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getFolders.pending, (store, action) => {
      listPending(store.folders, action);
    });
    builder.addCase(getFolders.fulfilled, (store, action) => {
      listFulfilled(store.folders, action);
    });
    builder.addCase(getFolders.rejected, (store, action) => {
      listRejected(store.folders, action);
    });

    builder.addCase(getFiles.pending, (store, action) => {
      listPending(store.files, action);
    });
    builder.addCase(getFiles.fulfilled, (store, action) => {
      listFulfilled(store.files, action);
    });
    builder.addCase(getFiles.rejected, (store, action) => {
      listRejected(store.files, action);
    });

    builder.addCase(resolveFolderStructure.pending, (store, action) => {
      entityPending(store.folderStructure, action, false);
    });
    builder.addCase(resolveFolderStructure.fulfilled, (store, action) => {
      entityFulfilled(store.folderStructure, action);
    });
    builder.addCase(resolveFolderStructure.rejected, (store, action) => {
      entityRejected(store.folderStructure, action);
    });

    builder.addCase(createNewFolder.pending, (store, action) => {
      listPending(store.folders, action);
    });
    builder.addCase(createNewFolder.fulfilled, (store, action) => {
      if (!listFulfilledMeta(store.folders, action)) {
        return;
      }

      store.folders.data.push(action.payload);
      store.folders.total += 1;
    });
    builder.addCase(createNewFolder.rejected, (store, action) => {
      listRejected(store.folders, action);
    });

    builder.addCase(deleteFolders.pending, (store, action) => {
      listPending(store.folders, action);
    });
    builder.addCase(deleteFolders.fulfilled, (store, action) => {
      if (!listFulfilledMeta(store.folders, action)) {
        return;
      }

      store.folders.data = store.folders.data.filter(
        ({id}) => !action.meta.arg.includes(id)
      );
      store.folders.total -= action.meta.arg.length;
    });
    builder.addCase(deleteFolders.rejected, (store, action) => {
      listRejected(store.folders, action);
    });

    builder.addCase(deleteFiles.pending, (store, action) => {
      listPending(store.files, action);
    });
    builder.addCase(deleteFiles.fulfilled, (store, action) => {
      if (!listFulfilledMeta(store.files, action)) {
        return;
      }

      store.files.data = store.files.data.filter(
        ({id}) => !action.meta.arg.includes(id)
      );
      store.files.total -= action.meta.arg.length;
    });
    builder.addCase(deleteFiles.rejected, (store, action) => {
      listRejected(store.files, action);
    });

    builder.addCase(renameFolder.pending, (store, action) => {
      listPending(store.folders, action);
    });
    builder.addCase(renameFolder.fulfilled, (store, action) => {
      if (!listFulfilledMeta(store.folders, action)) {
        return;
      }

      const {id, name} = action.meta.arg;
      const folder = store.folders.data.find((folder) => folder.id === id);
      if (folder) {
        folder.name = name;
      }
    });
    builder.addCase(renameFolder.rejected, (store, action) => {
      listRejected(store.folders, action);
    });

    builder.addCase(renameFile.pending, (store, action) => {
      listPending(store.files, action);
    });
    builder.addCase(renameFile.fulfilled, (store, action) => {
      if (!listFulfilledMeta(store.files, action)) {
        return;
      }

      const {id, name} = action.meta.arg;
      const file = store.files.data.find((file) => file.id === id);
      if (file) {
        file.originalName = name;
      }
    });
    builder.addCase(renameFile.rejected, (store, action) => {
      listRejected(store.files, action);
    });
  },
});

export default documentsSlice.reducer;
