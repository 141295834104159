import { OfferProduct } from "../../../../store/offers/types"

export const roundToTwoDecimals = (number: number) => Math.round(number * 100)/100

const getTG = (purchase?: number, sell?: number) =>
	typeof sell === 'number' && typeof purchase === 'number' && sell > 0 && purchase > 0 ? ((sell - purchase) / sell) * 100 : 0

const totalPriceProduct = (quantity: number, priceEach: number, discount?: number) => {
	return (quantity * priceEach) - discountCost((quantity * priceEach), discount)
}

const marginPrice = (purchasePrice: number, priceEach: number, quantity: number, discount?: number) => {
	let price = (quantity * priceEach)
	let discountNumber = discountCost(price, discount)
	return (price -  discountNumber) - (purchasePrice*quantity)
}

const discountCost = (price: number, discount?: number) => {
	let discountNumber = discount ?? 1
	let discountMath = discountNumber/100
	let discountSum = discount === null ? 0 : (price * discountMath)

	return discountSum;
}

export const resolveOfferProductValues = (product: Omit<OfferProduct, 'totalPrice' | 'tg' | 'marginPrice'>) => {
	let tgDiscount = discountCost(product.priceEach, product.discount)

	return({
		...product,
		totalPrice: totalPriceProduct(product.quantity, product.priceEach, product.discount), 
		tg: getTG(product.purchasePrice,(product.priceEach - tgDiscount)),
		marginPrice: marginPrice(product.purchasePrice, product.priceEach, product.quantity, product.discount),
	})
}

export const resolveOfferValues = (obj: {
	products: OfferProduct[],
	taxValue: number,
	transportCost: number,
	otherCost1: number,
	otherCost2: number,
	otherCost3: number,
	proposedDeliveryDate?: Date,
	companyLogo?: File,
	totalExTaxCostAdjusted?: number,
	imagechanged?:boolean,
	discountCost: number,
	discountCost2: number,
	discountCost3: number,
	surcharge: number,
}) => {
	let costAdjusted = 0;
	if(obj.totalExTaxCostAdjusted !== undefined)
		costAdjusted = obj.totalExTaxCostAdjusted

	const discounts = obj.discountCost + obj.discountCost2 + obj.discountCost3;
	const otherCosts = obj.otherCost1 + obj.otherCost2  + obj.otherCost3
	const totalExTaxCost = obj.products.reduce((p,c) => p + totalPriceProduct( c.quantity, c.priceEach, c.discount), 0) + otherCosts;
	const totalInPrice = obj.products.reduce((p,c) => p + (c.purchasePrice * c.quantity), 0);
	const totalExTaxTG = roundToTwoDecimals(getTG(totalInPrice, totalExTaxCost));
	const surCharge = (obj.surcharge/100)+1;

	return  {
		...obj,
		totalExTaxCost: (totalExTaxCost -discounts),
		totalExTaxTG: isNaN(totalExTaxTG) || !isFinite(totalExTaxTG) ? 0: totalExTaxTG,
		totalExTaxMargin: roundToTwoDecimals((totalExTaxCost||0) - (totalInPrice||0)-discounts),
		// add discounts total
		totalCostExTaxOnOffer: costAdjusted > 0 ?
			roundToTwoDecimals((costAdjusted)+obj.transportCost)*(surCharge) :
			roundToTwoDecimals((totalExTaxCost||0)+obj.transportCost)*(surCharge)-discounts,

		totalCostInkTaxOnOffer: costAdjusted > 0 ?
			roundToTwoDecimals(((costAdjusted)+obj.transportCost)*((obj.taxValue/100)+1)*(surCharge)) :
			roundToTwoDecimals(((totalExTaxCost||0)+obj.transportCost - discounts)*((obj.taxValue/100)+1)*(surCharge)),
	}
}