import { request } from '../lib/request';
import { ENDPOINTS } from '../endpoints';

const methods = {
    getVersionNumber() {
        return request({
            endpoint: ENDPOINTS.GET_VERSION,
            method: 'GET'
        });
    },
}

export default methods