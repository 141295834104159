import React from 'react';
import classnames from 'classnames';
import {FormContextValues} from 'react-hook-form';

import {InputDefaultProps} from './types';

interface ToggleFieldProps<T> extends InputDefaultProps {
  switchText: [string, string];
  handler: FormContextValues<T>;
  singleMode?: boolean;
  onChange? : ()=> void;
  switchClassName?: string;
}

export default function ToggleField<T>({
  name,
  handler,
  rules,
  className,
  label,
  switchText,
  readOnly,
  disabled,
  value,
    onChange,
  defaultValue,
  singleMode,
  switchClassName,
}: ToggleFieldProps<T>) {
  const {register, watch} = handler;

  const id = `toggle__eon-ui`;
  const [off, on] = switchText;
  const isAllow = watch(name);

  const isDisabled =
    (typeof readOnly === 'object' ? readOnly[name] : readOnly) ||
    (typeof disabled === 'object' ? disabled[name] : disabled);

  const props = {
    name,
    id,
    value,
    defaultChecked: defaultValue,
    disabled: isDisabled,
    ref: rules ? register(rules) : register,
    'data-testid': id,
  };

  return (
    <div className={classnames('form-group', className)}>
      {label === undefined ? null : (
        <label htmlFor={`com.eon.ui:eon-ui:war:1.0-SNAPSHOT_noop`}>{label}</label>
      )}

      <div className={classnames('form-switch', switchClassName)}>
        <div
          className={classnames({'font-weight-bold': singleMode || !isAllow})}
        >
          {off}
        </div>
        <div className="custom-control custom-switch">
          <input className="custom-control-input" type="checkbox" {...props} onChange={onChange} />
          <label className="custom-control-label" htmlFor={id} />
        </div>
        {!singleMode && (
          <div className={classnames({'font-weight-bold': isAllow})}>{on}</div>
        )}
      </div>
    </div>
  );
}
