import React, { useState, useCallback } from 'react'
import {Product, MergedArticleProduct} from '../../store/products/types'
import { PriceGroupInputNew } from '../../store/priceGroups/types'
import {FormContextValues} from 'react-hook-form'
import InputField from '../Form/InputField'
import useObject from '../../hooks/useObject'
import ProductSelectModal from './ProductSelectModal'

export type PriceGroupFormRowProps = {
  value: string;
  index: string;
  id: number;
  removeIndex: (index: string) => void;
  articlesData: MergedArticleProduct[];
  handleInput: FormContextValues<PriceGroupInputNew>;
};

export type Option<T extends string = string> = {
  label: string;
  value: string;
};

export function PriceGroupFormRow({
  value,
  index,
  articlesData,
  handleInput,
  id,
  removeIndex,
}: PriceGroupFormRowProps) {
  const [modalVisible, setModalVisible] = useState(false);
  const [data, setData] = useObject({
	  id: '',
	  name: '',
	  fortnoxId: '' 
  });

  const onProductSelect = useCallback((product?: Product | null) => () => {
		setData(product ? {
			id: product.id,
			name: product.productInformation.name,
			fortnoxId: product.fnArticleId 
		} : {
			id: '',
			name: '',
			fortnoxId: '' 
		})
		setModalVisible(false)
	}, [setModalVisible, setData])

  const name = 'row[id]'.replace('id', id.toString());

  return (
    <>
      <div className="row">
        <div className="d-none">
          <InputField
            name={name.concat('.internalArticleId')}
            handler={handleInput}
            label="Internt artikelid"
            value={data.id}
            readOnly={true}
          />
          <InputField
            name={name.concat('.fortnoxProductId')}
            handler={handleInput}
            label="ArtikelId"
            value={data.fortnoxId}
            readOnly={true}
          />
           <InputField
            name={name.concat('.productName')}
            handler={handleInput}
            label="ProduktNamn"
            value={data.name}
            readOnly={true}
          />
        </div>
        <div className="col-2">
          <InputField
            name={name.concat('.fnArticleId')}
            handler={handleInput}
            label=""
            className=""
            associated={true}
            associateHandler={() => setModalVisible(true) }
            associateText={'Lägg till artikel'}
            associateValue={''}
            rules={{required: false}}
          />
        </div>
        <div className="col-2">
          <InputField
              name={name.concat('.internalProductName')}
              label="Artikelnamn"
              handler={handleInput}
              value={data.name}
              readOnly={true}
            />
        </div>
        <div className="col-2">
          <InputField
            name={name.concat('.rowSelect')}
            handler={handleInput}
            label="Artikelnummer"
            value={articlesData
              .filter((f) => f.product.id === data.id)
              .map((f) => f.product.fnArticleId)}
            readOnly={true}
          />
        </div>
        <div className="col-2">
          <InputField
            name={name.concat('.percentage')}
            handler={handleInput}
            label="Rabatt (%)"
            defaultValue={value}
          />
        </div>
        <div className="col-3 cursor-pointer">
          <p className="label">Ta bort</p>
          <button
            className="btn btn-danger"
            onClick={() => removeIndex(index.toString())}
          >
            Ta bort
          </button>
        </div>
      </div>
      { modalVisible && 
		<ProductSelectModal
			id={id}
			products={articlesData.map((d) => d.product)}
			modalVisible={modalVisible}
			onSelect={onProductSelect}
			setModalVisible={setModalVisible}
		/>
	  }
    </>
  );
}

export default PriceGroupFormRow;
