import { FormContextValues } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Order, OrderInputValues } from "../../store/orders/types"
import { Entity } from "../../store/types"
import DatePicker from "../Form/DatePicker"
import { InlineCheckbox } from "../Form/InlineCheckInput"
import InputField from "../Form/InputField"
import SelectField, { Option } from "../Form/SelectField"


export interface DeliveryCardCardProps {
    handleInput: FormContextValues<OrderInputValues>
    userAdminType: boolean
    deliveryOptions: Option[]
    order: Entity<Order>
    readOnly: boolean
}

const DeliveryCard: React.FC<DeliveryCardCardProps> = ({
    handleInput,
    userAdminType,
    deliveryOptions,
    order,
    readOnly,
}) => {
    const { t } = useTranslation()
    const {getValues} = handleInput
    const {deliveryDate} = getValues()

    return (
        <>
            <div className="col-md-12 col-sm-12">
                <div className="row">
                    <div className="col-12 mb-2" hidden={!readOnly && !userAdminType}>
                        <div className="card">
                            <div className='card-header' data-toggle="collapse" data-target="#delivery">
                                {t('order.Delivery')}
                                <i className="float-right arrow down"></i>
                                <i className="float-right arrow up"></i>
                            </div>
                            <div className="collapse show" id="delivery">
                                <div className='card-body'>
                                    <div className='row'>
                                        <div className="col-sm-6 col-lg-6">
                                            <div className='col-12'>
                                                <SelectField
                                                    handler={handleInput}
                                                    name="wayOfDeliveryCode"
                                                    placeholder={t('common.Select')}
                                                    label={"Leveranssätt"}
                                                    options={deliveryOptions}
                                                    readOnly={readOnly}
                                                />
                                            </div>
                                            <div className='col-12'>
                                                <label>
                                                    {t('common.PreliminaryDeliveryDate')}
                                                </label>
                                                <DatePicker
                                                    name="preliminaryDeliveryDate"
                                                    handler={handleInput}
                                                    dateFormat={"yyyy-MM-dd"}
                                                    isWeekdayInActive
                                                    readOnly={readOnly}
                                                />
                                            </div>
                                            {deliveryDate && <div className='col-12'>
                                                <label>
                                                    {t('common.Confirmed delivery date')}
                                                </label>
                                                <DatePicker
                                                    name="deliveryDate"
                                                    handler={handleInput}
                                                    dateFormat={"yyyy-MM-dd"}
                                                    isWeekdayInActive
                                                    readOnly={true}
                                                />
                                            </div>}
                                            <div className='col-12'>
                                                <InlineCheckbox
                                                    className="m-0"
                                                    name="partlyDelivery"
                                                    handler={handleInput}
                                                    options={[
                                                        { value: 'Ja', label: 'Delleverans' },
                                                    ]}
                                                    disabled={order.data?.partlyDelivery}
                                                    readOnly={readOnly}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-lg-6">
                                            <div className='col-12'>
                                                <label>
                                                    {t('order.Information to driver')}
                                                </label>
                                                <InputField
                                                    name="driverDeliveryInstuction"
                                                    handler={handleInput}
                                                    placeholder={t('order.Information to driver')}
                                                    readOnly={readOnly}
                                                />
                                            </div>
                                            <div className='col-12'>
                                                <label>
                                                    {t('order.Proposed delivery date')}
                                                </label>
                                                <DatePicker
                                                    name="proposedDeliveryDate"
                                                    handler={handleInput}
                                                    dateFormat={"yyyy-MM-dd"}
                                                    isWeekdayInActive
                                                    readOnly={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DeliveryCard