import React from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';

import AuthRouter from './AuthRouter';
import PrivateRoute from './PrivateRoute';

import Home from '../pages/Home/Home';
import ChangePassword from '../pages/Account/ChangePassword';
import UserProfile from '../pages/Account/UserProfile';
import GDPRPage from '../pages/Account/GDPRPage';
import CreateNews from '../pages/News/CreateNews';
import EditNews from '../pages/News/EditNews';
import NewsArchive from '../pages/News/NewsArchive';
import ManageNews from '../pages/News/ManageNews';
import CreateUser from '../pages/Users/CreateUser';
import ManageUsers from '../pages/Users/ManageUsers';
import EditUser from '../pages/Users/EditUser';
import ViewUser from '../pages/Users/ViewUser';
import CreateCompany from '../pages/Companies/CreateCompany';
import ManageCompanies from '../pages/Companies/ManageCustomers';
import EditCompany from '../pages/Companies/EditCompany';
import EditCompanyMainAccount from '../pages/Companies/EditCompanyMainAccount';
import ViewCompany from '../pages/Companies/ViewCompany';
import EditCompanyDocuments from '../pages/Companies/EditCompanyDocuments';
import EditCompanyDocumentsMainAccount from '../pages/Companies/EditCompanyDocumentsMainAccount';
import EditCompanyComments from '../pages/Companies/EditCompanyComments';
import CompanyUsers from '../pages/Users/CompanyUsers';
import OrderManagement from '../pages/OrderManagement/OrderManagement';
import ManageEShop from '../pages/EShop/ManageProducts';
import ManageProductCategories from '../pages/EShop/ManageProductCategories';
import ProductCategoryPriority from '../components/EShop/ProductCategoryPriority';
import CreateProductCategory from '../pages/EShop/CreateProductCategory';
import CreatePriceGroup from '../pages/EShop/CreatePriceGroup';
import EditPriceGroup from '../pages/EShop/EditPriceGroup';
import EditProductCategory from '../pages/EShop/EditProductCategory';
import EditProduct from '../pages/EShop/EditProduct';
import CreateProduct from '../pages/EShop/CreateProduct';
import Documents from '../pages/Documents/Documents';
import Checkout from '../pages/EShop/Checkout';
import PriceList from '../pages/EShop/PriceList';
import EShop2 from '../pages/EShop/EShop';
import Download from '../pages/Download';
import EditOrder from '../pages/OrderManagement/EditOrder';
import ViewOrder from '../pages/OrderManagement/ViewOrder';
import B2C from '../pages/EShop/B2C';
import CaseManagement from '../pages/CaseManagement/CaseManagement';
import Bannermessages from '../pages/BannerMessage/BannerMessages';

import CreateCase from '../pages/CaseManagement/create/CreateCase';
import CreateCaseComment from '../pages/CaseManagement/create/CreateCaseComment';

import EditCaseManagementAssignCase from '../pages/CaseManagement/edit/EditCaseManagementAssignCase'

import {
  PATH_HOME,
  PATH_CHANGE_PASSWORD,
  PATH_USER_PROFILE,
  PATH_GDPR_REPORT,
  PATH_GENERAL_CREATE_USER,
  PATH_GENERAL_MANAGE_USERS,
  PATH_GENERAL_MANAGE_COMPANY,
  PATH_GENERAL_MANAGE_COMPANY_DOCUMENTS,
  PATH_GENERAL_EDIT_USER,
  PATH_OFFERS_OFFER_TOOL,
  PATH_OFFERS_OFFER_TOOL_EDIT,
  PATH_AUTH,
  PATH_ANY,
  PATH_ACCESS_DENIED,
  PATH_E_SHOP,
  PATH_E_SHOP_MANAGE_PRODUCTS,
  PATH_CUSTOMERS_CREATE_USER,
  PATH_CUSTOMERS_MANAGE_USERS,
  PATH_CUSTOMERS_EDIT_USER,
  PATH_CUSTOMERS_CREATE_COMPANY,
  PATH_CUSTOMERS_MANAGE_COMPANIES,
  PATH_CUSTOMERS_EDIT_COMPANY,
  PATH_CUSTOMERS_EDIT_COMPANY_DOCUMENTS,
  PATH_CUSTOMERS_COMPANY_COMMENTS,
  PATH_CUSTOMERS_VIEW_COMPANY_USERS,
  PATH_NEWS_CREATE_NEWS,
  PATH_NEWS_EDIT_NEWS,
  PATH_NEWS_MANAGE_NEWS,
  PATH_DOCUMENTS,
  PATH_NEWS_ARCHIVE,
  PATH_E_SHOP_CREATE_PRODUCT_CATEGORY,
  PATH_E_SHOP_EDIT_PRODUCT_CATEGORY,
  PATH_E_SHOP_EDIT_PRODUCT,
  PATH_OFFERS_BACK_OFFICE_LIST,
  PATH_ORDERS_BACK_OFFICE_LIST,
  PATH_ORDERS_LIST,
  PATH_GENERAL_VIEW_USER,
  PATH_CUSTOMERS_VIEW_USER,
  PATH_CUSTOMERS_VIEW_COMPANY,
  PATH_E_SHOP_MANAGE_PRODUCT_CATEGORIES,
  PATH_E_SHOP_MANAGE_PRODUCT_CATEGORIES_PRIORITY,
  PATH_E_SHOP_CREATE_PRODUCT,
  PATH_E_SHOP_CREATE_PRICE_GROUP,
  PATH_E_SHOP_EDIT_PRICE_GROUP,
  PATH_E_SHOP2,
  PATH_E_SHOP2_PRODUCT_FROM_CATEGORY,
  PATH_E_SHOP_CHECKOUT,
  PATH_ORDERS_EDIT_ORDER,
  PATH_ORDERS_VIEW_ORDER,
  PATH_E_SHOP_PRICELIST,
  PATH_E_SHOP_B2C_UPLOAD,
  PATH_CASES_BACK_OFFICE_LIST,
  PATH_CASES_BACK_OFFICE_CREATE,
  PATH_CASES_BACK_OFFICE_CREATE_COMMENTS,
  PATH_CASES_BACK_OFFICE_EDIT,
  PATH_CASES_BACK_OFFICE_ASSIGN_COMMENTS,
  PATH_CASES_BACK_OFFICE_VIEW_COMMENTS,
  PATH_CASES_BACK_OFFICE_ASSIGN_COMMENTS_VIEW,
  PATH_CASES_BACK_OFFICE_VIEW,
  PATH_CUSTOMERS_SWITCH_USER,
  PATH_OFFERS_MY_OFFERS_LIST,
  PATH_OFFERS_CUSTOMER_OFFERS_LIST,
  PATH_BANNERMESSAGE_BACK_OFFICE_LIST,
  PATH_BANNERMESSAGE_BACK_OFFICE_CREATE,
  PATH_BANNERMESSAGE_BACK_OFFICE_EDIT
} from './paths';

import EditCase from '../pages/CaseManagement/edit/EditCase';
import ViewCase from '../pages/CaseManagement/view/ViewCase';
import ViewCaseManagementAssignCase from '../pages/CaseManagement/view/ViewCaseManagementAssignCase';
import SwitchUser from '../pages/Users/SwitchUser';
import CreateOffer from '../pages/OfferManagement/OfferTool/CreateOffer';
import EditOffer from '../pages/OfferManagement/OfferTool/EditOffer';
import OurOffers from '../pages/OfferManagement/OfferListing/OurOffers';
import MyOffers from '../pages/OfferManagement/OfferListing/MyOffers';
import CustomerOffers from '../pages/OfferManagement/OfferListing/CustomerOffers';
import CreateBannerMessage from "../pages/BannerMessage/create/CreateBannerMessage";
import EditMessage from "../pages/BannerMessage/edit/EditMessage";

export default function AppRouter() {
  return (
    <Router
      basename={
        process.env.PUBLIC_URL && process.env.PUBLIC_URL.startsWith('/')
          ? process.env.PUBLIC_URL
          : undefined
      }
    >
      <Switch>
        <PrivateRoute exact path={PATH_HOME} component={Home} />
        <PrivateRoute
          exact
          path={PATH_CHANGE_PASSWORD}
          component={ChangePassword}
        />
        <PrivateRoute exact path={PATH_USER_PROFILE} component={UserProfile} />
        <PrivateRoute exact path={PATH_GDPR_REPORT} component={GDPRPage} />
        <PrivateRoute exact path={PATH_NEWS_ARCHIVE} component={NewsArchive} />

        <PrivateRoute path={PATH_NEWS_CREATE_NEWS} component={CreateNews} />
        <PrivateRoute path={PATH_NEWS_EDIT_NEWS} component={EditNews} />
        <PrivateRoute path={PATH_NEWS_MANAGE_NEWS} component={ManageNews} />

        <PrivateRoute path={PATH_GENERAL_CREATE_USER} component={CreateUser} />
        <PrivateRoute path={PATH_GENERAL_EDIT_USER} component={EditUser} />
        <PrivateRoute path={PATH_GENERAL_VIEW_USER} component={ViewUser} />
        <PrivateRoute
          exact
          path={PATH_GENERAL_MANAGE_USERS}
          component={ManageUsers}
        />
        <PrivateRoute
          exact
          path={PATH_GENERAL_MANAGE_COMPANY}
          component={EditCompanyMainAccount}
        />

        <PrivateRoute
          exact
          path={PATH_GENERAL_MANAGE_COMPANY_DOCUMENTS}
          component={EditCompanyDocumentsMainAccount}
        />

        <PrivateRoute exact path="/download" component={Download} />

        <PrivateRoute
          path={PATH_CUSTOMERS_SWITCH_USER}
          component={SwitchUser}
        />

        <PrivateRoute
          path={PATH_CUSTOMERS_CREATE_USER}
          component={CreateUser}
        />
        <PrivateRoute path={PATH_CUSTOMERS_EDIT_USER} component={EditUser} />
        <PrivateRoute path={PATH_CUSTOMERS_VIEW_USER} component={ViewUser} />
        <PrivateRoute
          exact
          path={PATH_CUSTOMERS_MANAGE_USERS}
          component={ManageUsers}
        />
        <PrivateRoute
          exact
          path={PATH_CUSTOMERS_CREATE_COMPANY}
          component={CreateCompany}
        />
        <PrivateRoute
          path={PATH_CUSTOMERS_EDIT_COMPANY_DOCUMENTS}
          component={EditCompanyDocuments}
        />
        <PrivateRoute
          path={PATH_CUSTOMERS_VIEW_COMPANY_USERS}
          component={CompanyUsers}
        />
        <PrivateRoute
          path={PATH_CUSTOMERS_COMPANY_COMMENTS}
          component={EditCompanyComments}
        />
        <PrivateRoute
          exact
          path={PATH_CUSTOMERS_EDIT_COMPANY}
          component={EditCompany}
        />
        <PrivateRoute
          exact
          path={PATH_CUSTOMERS_VIEW_COMPANY}
          component={ViewCompany}
        />

        <PrivateRoute
          path={PATH_CUSTOMERS_MANAGE_COMPANIES}
          component={ManageCompanies}
        />

        <PrivateRoute exact path={PATH_E_SHOP_CHECKOUT} component={Checkout} />

        <PrivateRoute exact path={PATH_E_SHOP} component={EShop2} />
        <PrivateRoute
          exact
          path={PATH_E_SHOP_MANAGE_PRODUCTS}
          component={ManageEShop}
        />
        <PrivateRoute
          path={PATH_E_SHOP_EDIT_PRODUCT}
          component={EditProduct}
          exact
        />
        <PrivateRoute
          exact
          path={PATH_E_SHOP_MANAGE_PRODUCT_CATEGORIES}
          component={ManageProductCategories}
        />
        <PrivateRoute
          exact
          path={PATH_E_SHOP_MANAGE_PRODUCT_CATEGORIES_PRIORITY}
          component={ProductCategoryPriority}
        />
        <PrivateRoute
          exact
          path={PATH_E_SHOP_CREATE_PRODUCT_CATEGORY}
          component={CreateProductCategory}
        />

        <PrivateRoute
          exact
          path={PATH_E_SHOP_CREATE_PRICE_GROUP}
          component={CreatePriceGroup}
        />

        <PrivateRoute exact path={PATH_E_SHOP2} component={EShop2} />
        <PrivateRoute
          exact
          path={PATH_E_SHOP_PRICELIST}
          component={PriceList}
        />

        <PrivateRoute exact path={PATH_E_SHOP_B2C_UPLOAD} component={B2C} />

        <PrivateRoute
          exact
          path={PATH_E_SHOP_EDIT_PRICE_GROUP}
          component={EditPriceGroup}
        />

        <PrivateRoute
          exact
          path={PATH_E_SHOP2_PRODUCT_FROM_CATEGORY}
          component={EShop2}
        />

        <PrivateRoute
          exact
          path={PATH_E_SHOP_EDIT_PRODUCT_CATEGORY}
          component={EditProductCategory}
        />
        <PrivateRoute
          exact
          path={PATH_E_SHOP_CREATE_PRODUCT}
          component={CreateProduct}
        />

        <PrivateRoute
          exact
          path={PATH_CASES_BACK_OFFICE_CREATE}
          component={CreateCase}
        />

        <PrivateRoute
          exact
          path={PATH_CASES_BACK_OFFICE_CREATE_COMMENTS}
          component={CreateCaseComment}
        />
        
        <PrivateRoute
          exact
          path={PATH_CASES_BACK_OFFICE_ASSIGN_COMMENTS}
          component={EditCaseManagementAssignCase}
        />

      <PrivateRoute
          exact
          path={PATH_CASES_BACK_OFFICE_ASSIGN_COMMENTS_VIEW}
          component={ViewCaseManagementAssignCase}
        />

        <PrivateRoute
          path={PATH_ORDERS_BACK_OFFICE_LIST}
          component={OrderManagement}
        />
        <PrivateRoute path={PATH_ORDERS_LIST} component={OrderManagement} />

        <PrivateRoute
          exact
          path={PATH_ORDERS_VIEW_ORDER}
          component={ViewOrder}
        />

        <PrivateRoute
          exact
          path={PATH_ORDERS_EDIT_ORDER}
          component={EditOrder}
        />

        <PrivateRoute
          exact
          path={PATH_CASES_BACK_OFFICE_LIST}
          component={CaseManagement}
        />

        
        <PrivateRoute
          exact
          path={PATH_CASES_BACK_OFFICE_EDIT}
          component={EditCase}
        />

      <PrivateRoute
          exact
          path={PATH_CASES_BACK_OFFICE_VIEW}
          component={ViewCase}
        />

        <PrivateRoute
          exact
          path={PATH_CASES_BACK_OFFICE_VIEW_COMMENTS}
          component={CreateCaseComment}
        />

        <PrivateRoute
            exact
            path={PATH_BANNERMESSAGE_BACK_OFFICE_LIST}
            component={Bannermessages}
        />

        <PrivateRoute
            exact
            path={PATH_BANNERMESSAGE_BACK_OFFICE_CREATE}
            component={CreateBannerMessage}
        />

        <PrivateRoute
            exact
            path={PATH_BANNERMESSAGE_BACK_OFFICE_EDIT}
            component={EditMessage}
        />

        <PrivateRoute exact path={PATH_OFFERS_OFFER_TOOL_EDIT} component={EditOffer}  />
        <PrivateRoute exact path={PATH_OFFERS_OFFER_TOOL} component={CreateOffer} />
        <PrivateRoute path={PATH_OFFERS_BACK_OFFICE_LIST} component={OurOffers} />
        <PrivateRoute path={PATH_OFFERS_MY_OFFERS_LIST} component={MyOffers} />
        <PrivateRoute path={PATH_OFFERS_CUSTOMER_OFFERS_LIST} component={CustomerOffers} />

        <PrivateRoute path={PATH_DOCUMENTS} component={Documents} />

        <Route path={PATH_AUTH} component={AuthRouter} />

        <Route path={PATH_ACCESS_DENIED}>
          <div className="d-flex flex-grow-1 justify-content-center align-items-center">
            <h1 className="m-0 p-0 pl-3 pr-3">403</h1>
          </div>
        </Route>
        <Route path={PATH_ANY}>
          <div className="d-flex flex-grow-1 justify-content-center align-items-center">
            <h1 className="m-0 p-0 pl-3 pr-3">404</h1>
          </div>
        </Route>
      </Switch>
    </Router>
  );
}
