import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Debounce from '../lib/Debounce'
import { useQuery } from '../lib/hooks'
import {usePrevious} from './usePrevious';

interface UsePaginationProps {
	getItems: (obj: {
		filters: {
			limit: number;
			offset: number;
		};
		statusSearch: string;
		textSearch: string;
	}) => any
	statusSearch?: string;
	textSearch?: string;
	limit?: number
}

const usePaging = ({ statusSearch, textSearch, limit = 50, getItems }: UsePaginationProps) => {
	const query = useQuery()
	const history = useHistory()
	const page = +(query.get('page') ?? 1)
	const hasFetched = useRef(false)
	const [ refreshSearch, setRefreshSearch ] = useState(false)
	const previousPage = usePrevious(page);
	const [ searchTerm, setSearchTerm ] = useState(textSearch ?? '')
	const [ statusSearchTerm, setStatusSearchTerm ] = useState(statusSearch ?? '')

	const setCurrentPage = (pageNumber: number | string) => {
		query.set('page', pageNumber.toString())
		history.push(`?${query}`)
	}

	const onPagination = (p: number | string) => { setCurrentPage(p) }

	const performSearch = () => {
		hasFetched.current ||= true
		fetchItems()
	}

	const fetchItems = useCallback(() => getItems({
		filters: {
			limit: limit,
			offset: limit*(page-1)
		},
		statusSearch: statusSearchTerm,
		textSearch: searchTerm
	}), [getItems, page, searchTerm, statusSearchTerm])

	useEffect(() => {
		debounce(() => {
			if(previousPage === 1) {
				setRefreshSearch(true)
				return
			}
			setCurrentPage(1)
		}, 400)
	}, [searchTerm])

	useEffect(() => {
		debounce(() => {
			setRefreshSearch(true)
		}, 50)
	}, [statusSearchTerm, page])

	useEffect(() => {
		if(refreshSearch === false)
			return
		performSearch()
		setRefreshSearch(false)
	}, [refreshSearch])

	return { 
		page, 
		limit,
		searchTerm,
		statusSearchTerm,
		setStatusSearchTerm,
		setSearchTerm, 
		onPagination, 
		triggerRefresh: fetchItems, 
		hasFetched: hasFetched.current 
	}
}

export default usePaging

const debounce = Debounce()
