import React from 'react';
import Icon from '@mdi/react';
import {mdiArrowLeft, mdiArrowRight} from '@mdi/js';
import classnames from 'classnames';
import {useTranslation} from 'react-i18next';

import './Carousel.scss';

import {News} from '../../store/news/types';

import Loader from '../Loader';

export type CarouselPosition = 'left' | 'right' | 'center' | 'justify';

type CarouselProps = {
  images: News[];
  name: string;
  loading: boolean;
  hideControls?: boolean;
  hideIndicators?: boolean;
  wrapperClassName?: string;
  slideClassName?: string;
};

export default function Carousel({
  images,
  name,
  hideControls,
  hideIndicators,
  wrapperClassName,
  slideClassName,
  loading,
}: CarouselProps) {
  const elementId = `carousel__eon-ui`;

  const {t} = useTranslation();

    const controls = (
    <>
      <a
        className="carousel-control-prev"
        href={`#${elementId}`}
        role="button"
        data-slide="prev"
        data-testid="slider__prev"
      >
        <Icon
          path={mdiArrowLeft}
          size="24"
          color="#ef7b10"
          title="Previous"
          className="icon-24"
          style={{backgroundColor: "#FFF", borderRadius: "30px", marginLeft: "-50px"}}
        />
      </a>
      <a
        className="carousel-control-next"
        href={`#${elementId}`}
        role="button"
        data-slide="next"
        data-testid="slider__next"
      >
        <Icon
          path={mdiArrowRight}
          size="24"
          color="#ef7b10"
          title="Next"
          className="icon-24"
          style={{backgroundColor: "#FFF", borderRadius: "30px", marginRight: "-50px"}}
        />
      </a>
    </>
  );

  const indicators = (
    <ol className="carousel-indicators">
      {images.map(({id}, idx) => (
        <li
          key={id}
          data-target={`#${elementId}`}
          data-slide-to={idx}
          data-testid={`slider__slide-to_${idx}`}
          className={classnames({active: idx === 0})}
        ></li>
      ))}
    </ol>
  );

  return (
    <div className={classnames('carousel-wrapper', wrapperClassName)}>
      <div
        id={elementId}
        data-testid={elementId}
        className={classnames('carousel', 'slide', slideClassName)}
        data-ride="carousel"
      >
        <div className="carousel-inner">
          {images.map(
            (
              {id, url, headline, headlineSize, position, text, linkTo},
              idx
            ) => {
                const showNewsOverlay = headline || text || linkTo;
                const newsOverlay = (
                <div
                  data-testid="carousel__news__container"
                  className={classnames(
                    'carousel__news__container',
                    `carousel__news__container--${position ?? 'left'}`
                  )}
                >
                  {headline && (
                    <h2
                      style={{
                        ...(headlineSize
                          ? {
                              fontSize: `${+headlineSize}px`,
                              lineHeight: `${+headlineSize + 6}px`,
                            }
                          : undefined),
                      }}
                    >
                      {headline}
                    </h2>
                  )}
                  {text && (
                    <div className="carousel__news__message">
                      {text.split('\n').map((t, i) => (
                        <React.Fragment key={`${i}-${t.substr(10)}`}>
                          {t}
                          <br />
                        </React.Fragment>
                      ))}
                    </div>
                  )}
                  {
                    linkTo && (
                      <div className="carousel__news__link">
                        <a href={`/e-shop/products/${linkTo}/0/0/0`}>{t('common.Go to e-shop')}</a>
                      </div>
                    )
                  }
                </div>
              );

              return (
                <div
                  key={id}
                  className={classnames('carousel-item', {
                    active: idx === 0,
                  })}
                >
                  {showNewsOverlay && newsOverlay}
                  <img
                    src={url}
                    className="d-block w-100"
                    alt={headline || ''}
                  />
                </div>
              );
            }
          )}
          {loading && <Loader containerClassName="mt-4 mb-4" />}
        </div>
        {!hideControls && controls}
        {!hideIndicators && indicators}
      </div>
    </div>
  );
}
