import { useEffect, useState } from "react";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { Controller, FieldValues, FormContextValues } from "react-hook-form";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";

interface OfferToolFormDiscountrops {
    handleSetDiscount: (params: {
      discountCost?: number,
      discountCost2?: number,
		  discountCost3?: number,
	}) => void
    handler: FormContextValues<FieldValues>
    readOnly: boolean
    values: {
      discountCost: number,
		  discountCost2: number,
		  discountCost3: number,
    }
    addDiscount1: boolean,
    setAddDiscount1: (f: boolean) => void,
    addDiscount2: boolean,
    setAddDiscount2: (f: boolean) => void,
    addDiscount3: boolean,
    setAddDiscount3: (f: boolean) => void,
}

function OfferToolFormDiscount({
    handleSetDiscount,
    handler,
    readOnly,
    values,
    addDiscount1,
    setAddDiscount1,
    addDiscount2,
    setAddDiscount2,
    addDiscount3,
    setAddDiscount3,
}: OfferToolFormDiscountrops) {
  const { t } = useTranslation()
  const [addDiscount1Valid, setAddDiscount1Valid] = useState<boolean>(false)
	const [addDiscount2Valid, setAddDiscount2Valid] = useState<boolean>(false)
	const [addDiscount3Valid, setAddDiscount3Valid] = useState<boolean>(false)

	const changeDiscountValue = (discountToChange: string) => {
    if(discountToChange === "Discount1"){
			setAddDiscount1(false)
			values.discountCost = 0
			handler.setValue("discountText", "")
      handleSetDiscount({...values, discountCost: 0})
      values.discountCost2 = 0
			handler.setValue("discountText2", "")
		}

		if(discountToChange === "Discount2"){
			setAddDiscount2(false)
			values.discountCost2 = 0
			handler.setValue("discountText2", "")
      handleSetDiscount({...values, discountCost2: 0})
			values.discountCost3 = 0
			handler.setValue("discountText3", "")
		}

		if(discountToChange === "Discount3"){
			setAddDiscount3(false)
			values.discountCost3 = 0
			handler.setValue("discountText3", "")
      handleSetDiscount({...values, discountCost3: 0})
		}
	}

	useEffect(() => {

        let text1 = handler.getValues("discountText")
        let text2 = handler.getValues("discountText2")
        let text3 = handler.getValues("discountText3")

        if(values.discountCost > 0 || (text1 != null && text1.length > 0)){
			setAddDiscount1(true)
		}

		if(values.discountCost2 > 0 || (text2 != null && text2.length > 0)){
			setAddDiscount2(true)
		}

		if(values.discountCost3 > 0 || (text3 != null && text3.length > 0)){
			setAddDiscount3(true)
		}
        // eslint-disable-next-line react-hooks/exhaustive-deps
	},[values])

	const watchDiscountText = handler.watch("discountText")
	const watchDiscountText2 = handler.watch("discountText2")
	const watchDiscountText3 = handler.watch("discountText3")

	useEffect(() => {
    if( addDiscount1 && watchDiscountText !== "")
			setAddDiscount1Valid(true)

    if(addDiscount1 && (watchDiscountText === null || watchDiscountText === ""))
			setAddDiscount1Valid(false)

		if( addDiscount2 && watchDiscountText2 !== "")
			setAddDiscount2Valid(true)

		if(addDiscount2 && (watchDiscountText2 === null || watchDiscountText2 === ""))
			setAddDiscount2Valid(false)

		if( addDiscount3 && watchDiscountText3 !== "")
			setAddDiscount3Valid(true)

		if(addDiscount3 && (watchDiscountText3 === null || watchDiscountText3 === ""))
			setAddDiscount3Valid(false)

	},[watchDiscountText2, watchDiscountText3, addDiscount2, addDiscount3, addDiscount1, watchDiscountText])

	useEffect(()=>{
    if(!addDiscount1)
			setAddDiscount1(values.discountCost > 0)

		if(!addDiscount2)
			setAddDiscount2(values.discountCost2 > 0)

		if(!addDiscount3)
			setAddDiscount3(values.discountCost3 > 0)
            // eslint-disable-next-line react-hooks/exhaustive-deps
	},[addDiscount1, addDiscount2, addDiscount3, values.discountCost, values.discountCost2, values.discountCost3])

    return(
        <>
            {/* addDiscount1 */}
            <tr hidden={!addDiscount1}>
                <td>{t('offer.table-Discount')}</td>
                <td>
                    <Controller
                        name={'discountText'}
                        control={handler.control}
                        as={
                            <TextField
                                fullWidth
                                InputLabelProps={{shrink: true}}
                                InputProps={{style: { fontSize: '0.75rem'}, readOnly: readOnly}}
                                variant='standard'
                                placeholder={t('offer.labelText.Discount text')}
                                id="standard-basic"
                                helperText={!addDiscount1Valid ? '*' + t('validation.required') : ""}
                                error={!addDiscount1Valid}
                            />
                        }
                    />
                </td>
                <td></td>
                <td></td>
                <td>
                    <TextField
                        id="standard-adornment-amount"
                        name="discountCost"
                        InputProps={{
                            startAdornment: '-',
                            style: { fontSize: '0.75rem'},
                            readOnly: readOnly
                        }}
                        variant='standard'
                        value={values.discountCost !== undefined && values.discountCost >= 0 ? values.discountCost : 0}
                        onChange={(e: any) => handleSetDiscount({ ...values, discountCost: parseInt(e.target.value) >= 0 ? parseInt(e.target.value) : 0 })}
                    />
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                {!readOnly &&
                    <td>
                        <IconButton
                            aria-label="remove"
                            title="remove product"
                            size="small"
                            onClick={() => changeDiscountValue("Discount1")}
                            hidden={addDiscount2}
                        >
                            <Close fontSize="small" />
                        </IconButton>
                    </td>
                }
            </tr>
            {/* addDiscount1 */}

            {/* addDiscount2 */}
            <tr hidden={!addDiscount2}>
                <td>{t('offer.table-Discount')}</td>
                <td>
                    <Controller
                        name={'discountText2'}
                        control={handler.control}
                        as={
                            <TextField
                                fullWidth
                                InputLabelProps={{shrink: true}}
                                InputProps={{style: { fontSize: '0.75rem'}, readOnly: readOnly}}
                                variant='standard'
                                placeholder={t('offer.labelText.Discount text')}
                                id="standard-basic"
                                helperText={!addDiscount2Valid ? '*' + t('validation.required') : ""}
                                error={!addDiscount2Valid}
                            />
                        }
                    />
                </td>
                <td></td>
                <td></td>
                <td>
                    <TextField
                        id="standard-adornment-amount"
                        name="discountCost2"
                        InputProps={{
                            startAdornment: '-',
                            style: { fontSize: '0.75rem'},
                            readOnly: readOnly
                        }}
                        variant='standard'
                        value={values.discountCost2 !== undefined && values.discountCost2 >= 0 ? values.discountCost2 : 0}
                        onChange={(e: any) => handleSetDiscount({ ...values, discountCost2: parseInt(e.target.value) >= 0 ? parseInt(e.target.value) : 0 })}
                    />
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                {!readOnly &&
                    <td>
                        <IconButton
                            aria-label="remove"
                            title="remove product"
                            size="small"
                            onClick={() => changeDiscountValue("Discount2")}
                            hidden={addDiscount3}
                        >
                            <Close fontSize="small" />
                        </IconButton>
                    </td>
                }
            </tr>
            {/* addDiscount2 */}

             {/* addDiscount3 */}
             <tr hidden={!addDiscount3}>
                <td>{t('offer.table-Discount')}</td>
                <td>
                    <Controller
                        name={'discountText3'}
                        control={handler.control}
                        as={
                            <TextField
                                fullWidth
                                InputLabelProps={{shrink: true}}
                                InputProps={{style: { fontSize: '0.75rem'}, readOnly: readOnly}}
                                variant='standard'
                                placeholder={t('offer.labelText.Discount text')}
                                id="standard-basic"
                                helperText={!addDiscount3Valid ? '*' + t('validation.required') : ""}
                                error={!addDiscount3Valid}
                            />
                        }
                    />
                </td>
                <td></td>
                <td></td>
                <td>
                    <TextField
                        id="standard-adornment-amount"
                        name="discountCost3"
                        InputProps={{
                            startAdornment: '-',
                            style: { fontSize: '0.75rem'},
                            readOnly: readOnly
                        }}
                        variant='standard'
                        value={values.discountCost3 !== undefined && values.discountCost3 >= 0 ? values.discountCost3 : 0}
                        onChange={(e: any) => handleSetDiscount({ ...values, discountCost3: parseInt(e.target.value) >= 0 ? parseInt(e.target.value) : 0 })}
                    />
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                {!readOnly &&
                    <td>
                        <IconButton
                            aria-label="remove"
                            title="remove product"
                            size="small"
                            onClick={() => changeDiscountValue("Discount3")}
                        >
                            <Close fontSize="small" />
                        </IconButton>
                    </td>
                }
            </tr>
            {/* addDiscount3 */}
        </>
    )

}

export default OfferToolFormDiscount