import { Box, Typography } from '@material-ui/core'
import React from 'react'

interface NonIdealStateProps {
	icon?: JSX.Element
	title?: string
	description?: string | JSX.Element
	action?: JSX.Element
	withoutContainer?: boolean
	className?: string
	style?: React.CSSProperties
	gap?: number
}

const NonIdealState: React.FC<NonIdealStateProps> = ({
	style,
	className,
	withoutContainer,
	gap = 10,
	...props
}) => withoutContainer ?
	<Content {...props} />
	:
	<Box className={className} display="grid" style={{ textAlign: 'center', justifyContent: 'center', ...style??{}, gap }}>
		<Content {...props} />
	</Box>
	


const Content: React.FC<NonIdealStateProps> = ({
	icon,
	title, 
	description,
	action,
	children
}) =>
	<>
		{ icon ? <Box>{icon}</Box> : <></> }
		{ title ? <Typography variant="h6" component="h6" color="textPrimary">{title}</Typography> : <></> }
		{ description ? <Typography variant="body1" component="div" style={{ whiteSpace: 'pre-line', display: 'grid' }}>{description}</Typography> : <></> }
		{ action ? <Box>{action}</Box> : <></> }
		{ children ?? <></> }
	</>


export default NonIdealState
