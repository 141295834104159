import './MessageBanner.scss';
import { Alert } from "@material-ui/lab";
import { BannerMessageType } from "../../store/bannermessages/types";
import {useEffect} from "react";
import useMessageBanner from "../../hooks/useMessageBanner";
import useInactivateMessage from "../../hooks/useMessageBanner";

export function MessageBanner() {

    const { getAllActiveMessages, messageBanner } = useMessageBanner();
    const { messageToHide, setMessageToHide, messageId } = useInactivateMessage();

    useEffect(() => {
        messageToHide();
    }, [messageId]);

    useEffect(() => {
        getAllActiveMessages();
        const interval = setInterval(() => {
            getAllActiveMessages();
        }, 60000);
        return () => clearInterval(interval);
    }, [messageId]);

    const getType = (type: BannerMessageType) =>
        type === BannerMessageType.ERROR_MESSAGE ? "error"
            : type === BannerMessageType.WARNING_MESSAGE ? "warning"
                : type === BannerMessageType.INFO_MESSAGE ? "info"
                    : type === BannerMessageType.SUCCESS_MESSAGE ? "success"
                        : "info";

    return (
        <div className="messagebanner">
            {messageBanner?.map((c) => {
                if(c.messageType.valueOf().toLowerCase().includes("warning") || c.messageType.valueOf().toLowerCase().includes("error")){
                    return (
                        <Alert
                            key={c.id}
                            severity={getType(c.messageType)}
                        >
                            <strong>{c.header ? c.header + ': ' : ''}</strong>{c.message}
                        </Alert>
                    )
                } else {
                    return (
                        <Alert
                            key={c.id}
                            onClose={() => {setMessageToHide(c.id)}}
                            severity={getType(c.messageType)}
                        >
                            <strong>{c.header ? c.header + ': ' : ''}</strong>{c.message}
                        </Alert>
                    )
                }}
            )}
        </div>
    )
}