import React, {useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';
import {useParams, useHistory} from 'react-router-dom';

import {AppState} from '../../store';
import {setShowLoader} from '../../store/system';
import {getCustomer,resetCustomer} from '../../store/companies';
import {getPriceGroups} from '../../store/priceGroups';
import {PATH_CUSTOMERS_MANAGE_COMPANIES} from '../../router/paths';
import CompanyForm, {
  CompanyFormInput,
} from '../../components/Company/CompanyForm';

const connector = connect(
  (state: AppState) => ({
    company: state.companies.selected,
    priceGroupList: state.priceGroups.list,
  }),
  {
    setShowLoader,
    getCustomer,
    getPriceGroups,
    resetCustomer,
  }
);

interface ViewCompanyProps extends ConnectedProps<typeof connector> {};

const ViewCompany = ({
  company,
  setShowLoader,
  getCustomer,
  getPriceGroups,
  priceGroupList
}: ViewCompanyProps) => {
  const {t} = useTranslation();
  const {companyId} = useParams<{companyId:string}>();
  const history = useHistory();
  const {
    data: companyData,
    loading: companyLoading,
  } = company;

  useEffect(() => { return () => {
    resetCustomer()
  } }, [])
  
  const handleInput = useForm<CompanyFormInput>({
    mode: 'onChange',
  });

  useEffect(() => {
    getPriceGroups();
  }, [getPriceGroups]);

  const showLoader = companyLoading;

  useEffect(() => {
    setShowLoader(showLoader);
  }, [showLoader, setShowLoader]);

  useEffect(() => {
    getCustomer(companyId);
  }, [companyId, getCustomer]);

  return (
    <div>
      <h1 data-testid="edit_company_headline">
        {t('navigation.Customers__nav.Manage companies')}
      </h1>
      <form>
        <CompanyForm handleInput={handleInput} priceGroupsList={priceGroupList} companyData={companyData} readOnly  isEdit/>

        <hr className="form-delimiter" />
        <button type="reset" className="btn btn-info" onClick={ () => history.push(PATH_CUSTOMERS_MANAGE_COMPANIES)}>
            {t('common.Cancel')}
        </button>
      </form>
    </div>
  );
}

export default connector(ViewCompany);
