import {createSelector} from 'reselect';

import {Folder, UploadedFile, LinkedList} from '../../types';

import {EntityData} from '../types';
import {AppState} from '..';

const getFolders = (state: AppState) => state.documents.folders;
const getFiles = (state: AppState) => state.documents.files;
const getFolderStructure = (state: AppState) => state.documents.folderStructure;

export type MergedDocument<
  T extends UploadedFile | Folder = UploadedFile | Folder
> = (T extends UploadedFile
  ? {type: 'file'; data: UploadedFile}
  : {type: 'folder'; data: Folder}) & {id: string};

export const getMergedDocuments = createSelector(
  [getFolders, getFiles],
  (folders, files): Array<MergedDocument<UploadedFile | Folder>> => {
    return [
      ...folders.data.map((data) => ({
        type: 'folder' as 'folder',
        data,
        id: data.id,
      })),
      ...files.data.map((data) => ({
        type: 'file' as 'file',
        data,
        id: data.id,
      })),
    ];
  }
);

export const isDocumentsLoading = createSelector(
  [getFolders, getFiles, getFolderStructure],
  (folders, files, folderStructure) => {
    return folders.loading || files.loading || folderStructure.loading;
  }
);

export const getResolvedFolderStructure = createSelector(
  [getFolderStructure],
  ({
    data: structure,
  }): [
    Array<{name: string; id: string}>,
    {name: string; id: string} | undefined
  ] => {
    if (!structure) {
      return [[], undefined];
    }

    const list = [];

    for (
      let f: EntityData<LinkedList<Folder>> = structure;
      f !== null;
      f = f.next
    ) {
      list.unshift({name: f.data.name, id: f.data.id});
    }

    const current = list.pop();

    return [list, current];
  }
);
