import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useEshop from '../../../../hooks/useEShop';
import { CheckoutInformation, checkoutInformationReq } from '../../../../store/checkout/types';
import DialogWarning from '../../../DialogWarning/DialogWarning';
import { useGetCountries } from '../../../Form/SelectCountriesInput';
import { Option } from '../../../Form/SelectField';
import Toaster from '../../../Toaster/Toaster';
import CheckoutForm, { CheckoutFields } from './CheckoutForm'


export type { CheckoutFields }

const defaultInputChecked: CheckoutFields = {
	customerReference: "",
	customerReferenceEmail: "",

	deliveryName: "",
	deliveryAddress: "",
	deliveryPostalNr: "",
	deliveryCity: "",
	deliveryCountryCode: "",
	deliveryPhone: "",

	ourReference: "",
	yourReference: "",
	yourOrderNumber: "",
	driverDeliveryInstruction: "",
	orderComment: "",
	customerDeliveryInformation: "",
	partlyDelivery: false,
	preliminaryDeliveryDate: new Date(),
	proposedDeliveryDate: new Date(),

	userConsent: false,
	wayOfDeliveryCode: null,
	sendEmail: false,
}

export const requiredCheckoutFormFields: (keyof CheckoutFields)[] = [
	'deliveryName', 
	'deliveryAddress', 
	'deliveryPostalNr', 
	'deliveryCity', 
	'deliveryCountryCode', 
	'deliveryPhone'
]

interface CheckoutFormContainerProps {
	information?: CheckoutInformation
	onValuesChange: (value?: CheckoutFields & { isReadyForCheckout: boolean }) => void
	deliveryOptions: Option[]
	loading: boolean
	restrictedToOffer?: boolean
	onDelete?: () => void
}

const CheckoutFormContainer: React.FC<CheckoutFormContainerProps> = ({
	information,
	onValuesChange,
	deliveryOptions,
	loading,
	restrictedToOffer,
	onDelete
}) => {
	const [fields, setFields] = useState(defaultInputChecked)
	const parentInput = useRef<undefined | CheckoutFields>(defaultInputChecked)
	const { countries } = useGetCountries()
	const [ showWarning, setShowWarning ] = useState(false)
	const useEshopHook = useEshop()
	
	const handleChange = (obj: Partial<CheckoutFields>) => setFields({ ...fields, ...obj})
	
	useEffect(() => {
		if(!information || countries?.length < 1) return
		setFields(f=>({
			...f,
			customerDeliveryInformation: information.customerDeliveryInformation ?? f.customerDeliveryInformation,
			customerReferenceEmail: information.customerReferenceEmail ?? f.customerReferenceEmail,
			customerReference: information.customerReference ?? f.customerReference,
			deliveryName: information.deliveryName ?? f.deliveryName,
			deliveryAddress: information.deliveryAddress ?? f.deliveryAddress,
			deliveryPostalNr: information.deliveryZipCode ?? f.deliveryPostalNr,
			deliveryCity: information.deliveryCity ?? f.deliveryCity,
			deliveryCountryCode: (information.deliveryCountryCode !== null ? countries.find(opt => opt.value === information.deliveryCountryCode)?.value :
																				countries.find(opt => opt.label === information.deliveryCountry)?.value) ?? 
																				f.deliveryCountryCode,
			deliveryPhone: information.deliveryPhone ?? f.deliveryPhone,
			driverDeliveryInstruction: information.driverDeliveryInstruction ?? f.driverDeliveryInstruction,
			ourReference: information.ourReference ?? f.ourReference,
			yourReference: information.yourReference ?? f.yourReference,
			yourOrderNumber: information.yourOrderNumber ?? f.yourOrderNumber,
			preliminaryDeliveryDate: information.preliminaryDeliveryDate ? new Date(information.preliminaryDeliveryDate) : undefined,
			proposedDeliveryDate: information.proposedDeliveryDate ? new Date(information.proposedDeliveryDate) : undefined,
			wayOfDeliveryCode: f.wayOfDeliveryCode,
			sendEmail: information.receiveNotification
		}))
	}, [information, setFields, countries])
	
	useEffect(() => {
		const isValid = requiredCheckoutFormFields.every(key => fields[key] !== undefined && fields[key] !== "")

		setShowWarning(!isValid)

		/* don't update parent if the form state is unchanged */
		if(fields === parentInput.current) return

		onValuesChange({...fields, isReadyForCheckout: isValid})
		parentInput.current = fields
	}, [fields, onValuesChange])

	const saveCheckoutinformation = () => {
		useEshopHook.updateCheckoutInformation({
			customerDeliveryInformation: fields.customerDeliveryInformation,
			customerReferenceEmail: fields.customerReferenceEmail,
			customerReference: fields.customerReference,
			deliveryAddress: fields.deliveryAddress,
			deliveryCity: fields.deliveryCity,
			deliveryCountry: countries.find(c=> c.value === fields.deliveryCountryCode)?.label,
			deliveryCountryCode: countries.find(c=> c.value === fields.deliveryCountryCode)?.value,
			deliveryName: fields.deliveryName,
			deliveryPhone: fields.deliveryPhone,
			deliveryZipCode: fields.deliveryPostalNr,
			driverDeliveryInstruction: fields.driverDeliveryInstruction,
			ourReference: fields.ourReference,
			preliminaryDeliveryDate: fields.preliminaryDeliveryDate,
			yourOrderNumber: fields.yourOrderNumber,
			yourReference: fields.yourReference
		} as checkoutInformationReq)
	}

	return (
		<>
			<CheckoutForm
				fields={fields}
				loading={loading}
				onFieldChange={handleChange} 
				showWarning={showWarning}
				deliveryOptions={deliveryOptions}
				countries={countries}
				onSave={saveCheckoutinformation}
				restrictedToOffer={restrictedToOffer}
				
			/>
			<Toaster toggle={{ 
				shouldToggle: useEshopHook.loading,
				message: useEshopHook?.response?.statusText ?? "", 
				status:  useEshopHook?.response?.status ?? 0}} 
				loading={false} />
		</>
	
	)
}

export default CheckoutFormContainer
