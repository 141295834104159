export const BytesToSizeWithPrefix = (bytes: number) => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
    if (bytes === 0) return 'n/a'

    const i = Math.floor(Math.log(bytes) / Math.log(1024))
    
    if (i === 0) return `${bytes} ${sizes[i]})`
        
    return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`
}

export const BytesToMB = (bytes: number) => {
    if (bytes === 0) return 0
    return (bytes / (1024*1024));
}