import React, { useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Table from '../../components/Table'
import TabNav from '../../components/TabNav'
import Debounce from '../../lib/Debounce'
import { useQuery } from '../../lib/hooks'
import { ErrorResponseResolver } from '../../lib/responseHelper'
import { formatTimeMinuteHourFromDate } from '../../lib/timeHelper'
import {PATH_BANNERMESSAGE_BACK_OFFICE_CREATE, PATH_BANNERMESSAGE_BACK_OFFICE_EDIT} from '../../router/paths'
import { AppState } from '../../store'
import { getAllBannerMessages, deleteBannerMessage } from '../../store/bannermessages'
import { toggleToast } from '../../store/components'
import { UserType } from '../../store/users/types'
import { BannerMessage, BannerMessageStatus, BannerMessageType } from "../../store/bannermessages/types";
import { DeleteOutlined, Edit } from "@material-ui/icons";

const connector = connect(
    (state: AppState) => ({
        bannerMessage: state.bannerMessages.list,
        activeUser: state.system.sessionUser,
        userType: state.system.sessionUserType
    }),
    { getAllBannerMessages, deleteBannerMessage, toggleToast }
);

type BannerMessageManagementProps = ConnectedProps<typeof connector> & {};

export function BannerMessageManagement({
                                   bannerMessage,
                                   getAllBannerMessages,
                                   deleteBannerMessage,
                                   toggleToast,
                                   activeUser,
                               }: BannerMessageManagementProps) {
    const { t } = useTranslation();
    const history = useHistory();
    const query = useQuery()
    const paramFilter = query.get('filter') ?? undefined
    const paramSearch = query.get('search') ?? undefined

    const handleSearch = (v: string) => {
        query.set('search', v)
        history.push(`?${query}`)
    }

    useEffect(() => {
        if(activeUser == undefined) return
        if(activeUser.userPermission.userType === UserType.SuperAdmin || activeUser.userPermission.userType === UserType.Admin) {
            getAllBannerMessages()
        }
    }, [activeUser, getAllBannerMessages])

    const createNewMessage = () => history.push(PATH_BANNERMESSAGE_BACK_OFFICE_CREATE)

    const deleteMessage = (async (messageId: string) => {
        let feedback = true;
        await deleteBannerMessage({
            bannerMessageId: messageId,
            callback: (errorResponseResolver: ErrorResponseResolver) => {
                toggleToast({
                    shouldToggle: true,
                    message: errorResponseResolver.message,
                    status: errorResponseResolver.status
                })
                feedback = false;
            }
        }).then(() => {
            if(feedback) {
                let msg = t('common.Message deleted');
                toggleToast({
                    shouldToggle: true,
                    message: msg,
                    status: 0
                })
            }
            feedback = false;
        })
        getAllBannerMessages()
    });

    const editMessage = (message :BannerMessage) => history.push(PATH_BANNERMESSAGE_BACK_OFFICE_EDIT.replace(':messageId', message.id))

    const getStatus = (status: BannerMessageStatus) =>
        status === BannerMessageStatus.VISIBLE ? "Synlig"
            : status === BannerMessageStatus.HIDDEN ? "Dold"
                : "Okänd"


    const getType = (category: BannerMessageType) =>
        category === BannerMessageType.ERROR_MESSAGE ? "Röd"
            : category === BannerMessageType.WARNING_MESSAGE ? "Gul"
                : category === BannerMessageType.INFO_MESSAGE ? "Blå"
                    : category === BannerMessageType.SUCCESS_MESSAGE ? "Grön"
                        : "Övrigt"

    const navLinks: {
        isActive: boolean
        path: string
        component: JSX.Element
    }[] = [
        BannerMessageStatus.VISIBLE,
        BannerMessageStatus.HIDDEN
    ].map(s => ({
        isActive: paramFilter === s,
        path: `?filter=${s}&search=${paramSearch?.replace(':','')}`,
        component: (
            <>
                {getStatus(s)}
                <span className="badge badge-info">
					{bannerMessage.data.map(c => c.messageStatus).filter(status => status.includes(s)).length ?? 0} st
				</span>
            </>
        ),
    }))
    navLinks.unshift({
        isActive: !paramFilter,
        path: `?filter=&search=${paramSearch?.replace(':','')}`,
        component: (
            <>
                {t('common.All')}
                <span className="badge badge-info">
					{bannerMessage.data.length} st
				</span>
            </>
        ),
    })

    const filteredMessages = (paramSearch ? bannerMessage.data.filter(c =>
        [
            c.id, c.message, getType(c.messageType), formatTimeMinuteHourFromDate(c.startDateTime), formatTimeMinuteHourFromDate(c.endDateTime)
        ].some(v => (v+'').toLowerCase().includes(paramSearch.toLowerCase()))
    ) : bannerMessage.data).filter(c => paramFilter ? c.messageStatus === paramFilter : true)

    return (
        <>
            <h1 data-testid="manage_companies_headline">
                {t('navigation.Message management')}
            </h1>
            {<TabNav links={navLinks} />}
            <Table
                list={filteredMessages ?? []}
                extractKey={el => el?.id}
                newButton={{
                    onClick: createNewMessage,
                    text: 'Skapa nytt meddelande',
                }}
                loading={bannerMessage.loading}
                title=""
                searchField={{
                    name: 'search',
                    text: 'Sök meddelande',
                    onChange: (e: any) => handleSearch(e.target.value),
                    value: paramSearch,
                    withInternalState: true
                }}
                header={[
                    'Rubrik',
                    'Meddelande',
                    'Meddelande Färg',
                    'Synligt Från',
                    'Dold Från',
                    'Status',
                    'Editera',
                    'Ta Bort',
                ]}
                row={row =>
                    <>
                        <td>{row.header}</td>
                        <td>{row.message}</td>
                        <td>{getType(row.messageType)}</td>
                        <td>{formatTimeMinuteHourFromDate(row.startDateTime)}</td>
                        <td>{formatTimeMinuteHourFromDate(row.endDateTime)}</td>
                        <td>{getStatus(row.messageStatus)}</td>
                        <td>
                            <button className="btn btn-link" style={{ boxShadow: 'none', padding: '0' }} onClick={() => editMessage(row)}>
                                <Edit fontSize={"small"}></Edit>
                            </button>
                        </td>
                        <td>
                            <button className="btn text-danger" style={{ boxShadow: 'none', padding: '0' }} onClick={() => deleteMessage(row.id)}>
                                <DeleteOutlined fontSize={"small"}></DeleteOutlined>
                            </button>
                        </td>
                    </>
                }
            />
        </>
    );
}

export default connector(BannerMessageManagement)

const debounce = Debounce()
