import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { mdiAccountPlus, mdiMagnify } from "@mdi/js";
import Icon from "@mdi/react";
import React, { useCallback, useEffect, useState } from "react";
import { FormContextValues } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { OrderInputValues } from "../../store/orders/types";
import InputField from "../Form/InputField";
import TextAreaField from "../Form/TextAreaField";
import OrderUserPickerModal from "../User/OrderUserPickerModal";
export interface InternInformationProps {
    handleInput: FormContextValues<OrderInputValues>
    userAdminType: boolean
    readOnly: boolean
    sessionUser: any 
}
const InternInformationCard: React.FC<InternInformationProps> = ({
    handleInput,
    userAdminType,
    readOnly,
    sessionUser
}) => {
    const { t } = useTranslation()
    const [userPickerModalLoader, setUserPickerModalLoader] = useState<boolean>(false);
    const [userPickerModalVisible, setUserPickerModalVisible,] = useState(false);
    const [assignedUser, setAssignedUser] = useState(handleInput.getValues("assignedUserName"))
    
    useEffect(() => {
        setAssignedUser(handleInput.getValues("assignedUserName"))
    },[assignedUser])
   
    const updateAssignedUser = useCallback(
        (user) => {
            setUserPickerModalLoader(true)
            let selectedUser = JSON.parse(user['selectedUser'])
            handleInput.setValue('assignedUserId', selectedUser.id, false)
            handleInput.setValue('assignedUserName', selectedUser.name, false)
            setUserPickerModalVisible(false)
            setAssignedUser(selectedUser)
        }, [handleInput]
    );

    const addAssignedUser = () => {
        setUserPickerModalLoader(true);
        handleInput.setValue('assignedUserId', sessionUser?.id, false);
        handleInput.setValue('assignedUserName', `${sessionUser?.userInformation.name} ${sessionUser?.userInformation.lastName}`, false);
        setAssignedUser(sessionUser.userInformation)
    };
    function removeAssignedUser() {
        setUserPickerModalLoader(false); 
        handleInput.setValue('assignedUserId', "", false);
        handleInput.setValue('assignedUserName', "", false);
        setAssignedUser("")
    }

    return (
        <>
            <div className="col-md-12 col-sm-12">
                <div className="row">
                    <div className="col-12 mb-2" hidden={!userAdminType}>
                        <div className="card">
                            <div className='card-header' data-toggle="collapse" data-target="#interninfo">
                                {t('common.Internal information')}
                                <i className="float-right arrow down"></i>
                                <i className="float-right arrow up"></i>
                            </div>
                            <div className="collapse show" id="interninfo">
                                {userAdminType && !readOnly &&
                                    <OrderUserPickerModal
                                        modalVisible={userPickerModalVisible}
                                        setModalVisible={setUserPickerModalVisible}
                                        onSave={updateAssignedUser}
                                        loader={userPickerModalLoader}
                                    />
                                }
                                <div className='card-body'>
                                    <label>
                                        {t('common.Assign user')}:
                                        {userAdminType && !readOnly &&
                                            <span
                                                style={{ cursor: "pointer", alignContent: "middle" }} onClick={() => setUserPickerModalVisible(true)}>
                                                <Icon
                                                    path={mdiMagnify}
                                                    size="18"
                                                    color="black"
                                                    title={t('common.Search user')}
                                                    className="icon-18 ml-4"
                                                    style={{ maxHeight: "18px" }}
                                                />
                                            </span>
                                        }
                                        {userAdminType && !readOnly &&
                                            <span
                                                style={{ cursor: "pointer", alignContent: "middle" }} onClick={() => addAssignedUser()}>
                                                <Icon
                                                    path={mdiAccountPlus}
                                                    size="18"
                                                    color="black"
                                                    title={t('common.Assign me')}
                                                    className="icon-18 ml-4"
                                                    style={{ maxHeight: "18px" }}
                                                />
                                            </span>
                                        }
                                        </label>
                                    <div className='row'>
                                        <div className="col-md-auto">
                                            <InputField
                                                name="assignedUserName"
                                                handler={handleInput}
                                                className='assignedUserNameField'
                                                readOnly={true}
                                            />
                                        </div>
                                        <div className="col">
                                            {
                                                assignedUser !=="" && assignedUser != undefined  && userAdminType && !readOnly &&
                                                <IconButton edge="end" aria-label="delete" size="small" style={{ color: "red" }} onClick={() => removeAssignedUser()}>
                                                    <Close fontSize="small" />
                                                </IconButton>
                                            }
                                        </div>
                                    </div>

                                    <div hidden>
                                        <InputField
                                            name="assignedUserId"
                                            handler={handleInput}
                                            readOnly={readOnly}
                                        />
                                    </div>
                                    {userAdminType &&
                                        <TextAreaField
                                            className="m-0 mt-0"
                                            name="internalComment"
                                            handler={handleInput}
                                            placeholder={t('common.Internal information')}
                                            rows={3}
                                            readOnly={readOnly}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default InternInformationCard