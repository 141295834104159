import React from 'react';
import {Link, generatePath, useRouteMatch} from 'react-router-dom';
import classnames from 'classnames';

import './TabNav.scss';

export type TabNavItemProps = {
  path: string;
  component: JSX.Element;
  isActive?: boolean;
  params?: any;
  linkClassName?: string;
  disabled?: boolean;
};

export type TabNavProps = {
  links: Array<TabNavItemProps>;
  className?: string;
  linkClassName?: string;
};

function TabNavItem({
  path,
  params,
  isActive,
  component,
  linkClassName,
  disabled,
}: TabNavItemProps) {
  const match = useRouteMatch({path, exact: true});

  isActive = isActive === undefined ? !!match : isActive;

  if (disabled) {
    return (
      <li>
        <div className="disabled">{component}</div>
      </li>
    );
  }

  return (
    <li>
      <Link
        to={generatePath(path, params)}
        className={classnames(linkClassName, {
          active: isActive,
          disabled,
        })}
      >
        {component}
      </Link>
    </li>
  );
}

export default function TabNav({links, className, linkClassName}: TabNavProps) {
  return (
    <ul className={classnames('tab__nav', className)}>
      {links.map((args) => {
        return (
          <TabNavItem key={args.path} linkClassName={linkClassName} {...args} />
        );
      })}
    </ul>
  );
}
