import React from 'react';
import {useTranslation} from 'react-i18next';
import Modal from '../Modal';
import {InlineCheckbox} from "../Form/InlineCheckInput";
import {useForm} from "react-hook-form";

export default function SignAgreementsModal({
	modalVisible,
	setModalVisible,
	onConfirm
}: {
  modalVisible: boolean;
  setModalVisible: (f: boolean) => void;
  onConfirm: ()=>void
}) {
  const {t} = useTranslation();
  const handler = useForm();

    const submit = () => {
        onConfirm()
    };

  return (
      <Modal
          name="sign-agreements"
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
          title={t('common.Dealer agreement')}
          dialogClassName={'modal-mw-small modal-dialog-centered'}
      >
          <form onSubmit={handler.handleSubmit(submit)} data-testid="sign-agreements-modal-headline">
              <h3>Vill du teckna Återförsäljaravtal?</h3>
              <InlineCheckbox
                  name="agreement"
                  handler={handler}
                  options={[
                      {value: 'accept', label: t('common.I accept the agreement and the terms above')},
                  ]}
                  rules={{required: true}}
              />
              <div className='mt-3 float-right'>
                  <button type='submit' className="btn btn-primary mr-6" data-testid="sign-agreements">
                      {t('common.Sign agreements')}
                  </button>
                  <button type="reset" className="btn btn-info" data-testid="cancel" onClick={()=>setModalVisible(false)}>
                      {t('common.Cancel')}
                  </button>
              </div>
          </form>


      </Modal>
  );
}
