import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormContextValues, useForm } from 'react-hook-form';
import './ProductCategoryForm.scss';
import { connect, ConnectedProps } from 'react-redux';
import { getProductCategories, updateProductCategoryOrder } from '../../store/productCategories'
import { AppState } from '../../store';
import update from 'immutability-helper'
import { ProductCategoryRowDragAndDrop } from './ProductCategoryRowDragAndDrop';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ProductCategoryCard } from '../../store/productCategories/types';
import { useHistory } from 'react-router-dom';
import { PATH_E_SHOP_MANAGE_PRODUCT_CATEGORIES } from '../../router/paths';


const connector = connect(
    (state: AppState) => ({
        productCategories: state.productCategories.list,
    }),
    { getProductCategories, updateProductCategoryOrder }
);

type ProductCategoryPriorityProps = ConnectedProps<typeof connector> & {
    handleInput: FormContextValues;
    isEdit: boolean;
};

function ProductCategoryPriority({
    productCategories,
    getProductCategories,
    updateProductCategoryOrder
}: ProductCategoryPriorityProps) {
    const { t } = useTranslation();
    const history = useHistory();



    const handleInput = useForm<any>({
        mode: 'onChange',
    });

    useEffect(() => {
        getProductCategories()
    }, [getProductCategories]);


    const { data: productCategoriesData, loading: productCategoriesDataLoading } = productCategories

    const [cards, setCards] = useState<ProductCategoryCard[]>([])

    useEffect(() => {
        let arr: ProductCategoryCard[] = []
        productCategoriesData.forEach(data => arr.push({
            id: data.orderList,
            productId: data.id,
            text: data.categoryName,
            orderList: data.orderList
        }))
        setCards(arr)
    }, [productCategoriesData])

    const prepareSubmit = () => {
        let arr: ProductCategoryCard[] = []
        cards.forEach((c, index) => {
            arr.push({
                productId: c.productId,
                id: index,
                orderList: index,
                text: c.text
            })
        })
        updateProductCategoryOrder(arr)
        history.push(PATH_E_SHOP_MANAGE_PRODUCT_CATEGORIES)
    }
    const moveCard = useCallback(
        (dragIndex: number, hoverIndex: number) => {
            const dragCard = cards[dragIndex]
            setCards(
                update(cards, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragCard],
                    ],
                }),
            )
        },
        [cards],
    )


    const renderCard = (card: { id: number; text: string }, index: number) => {
        return (
            <ProductCategoryRowDragAndDrop
                key={card.id}
                index={index}
                id={card.id}
                text={card.text}
                moveCard={moveCard}
            />
        )
    }

    return (
        <>
            {productCategoriesDataLoading ? "" :
                <>
                    <h1>Ändra prioritet på kategorier</h1>
                    <h4>*0 = Högsta prioritet.</h4>
                    <hr />
                    <DndProvider backend={HTML5Backend}>
                        <div>{cards.map((card, i) => renderCard({
                            text: i.toString().concat(" - ").concat(card.text),
                            id: card.id,
                        }, i))}</div>
                    </DndProvider>
                    <button className="btn btn-primary" onClick={prepareSubmit}>Uppdatera prioritet</button>
                </>
            }
        </>
    );
}

export default connector(ProductCategoryPriority)
