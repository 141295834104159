import React, {useEffect} from 'react';
import jQuery from 'jquery';
import classnames from 'classnames';

import './Modal.scss';

type ModalProps = {
  children: React.ReactNode;
  name: string;
  title: string;
  modalVisible: boolean;
  dialogClassName?: string;
  setModalVisible: (f: boolean) => void;
};

export default function Modal({
  children,
  name,
  title,
  modalVisible,
  dialogClassName,
  setModalVisible,
}: ModalProps) {
  const id = `modal__eon-ui`;

  useEffect(() => {
    const cb = () => {
      setModalVisible(false);
    };

    if (modalVisible) {
      jQuery(`#com.eon.ui:eon-ui:war:1.0-SNAPSHOT`).one('hidden.bs.modal', cb);
    }
    return () => {
      jQuery(`#com.eon.ui:eon-ui:war:1.0-SNAPSHOT`).on('hidden.bs.modal', cb);
    };
  }, [id, modalVisible, setModalVisible]);

  useEffect(() => {
    if (modalVisible) {
      (jQuery(`#com.eon.ui:eon-ui:war:1.0-SNAPSHOT`) as any).modal('show');
    } else {
      (jQuery(`#com.eon.ui:eon-ui:war:1.0-SNAPSHOT`) as any).modal('hide');
    }
	if(!modalVisible) {
		document.querySelectorAll('.modal-backdrop').forEach(e => e.remove())
		const body = document.querySelector('body')
		body && (
			body.className = body.className.replace('modal-open', '')
		)
	}
  }, [id, modalVisible]);

  return (
    <div
      className="modal fade"
      id={id}
      role="dialog"
      aria-labelledby={`com.eon.ui:eon-ui:war:1.0-SNAPSHOT__label`}
      aria-hidden="true"
    >
      <div className={classnames('modal-dialog', dialogClassName)}>
        <div className="modal-content">
          <h1 id={`com.eon.ui:eon-ui:war:1.0-SNAPSHOT__label`} className="border-0">{title}</h1>
		  {/* Instead of {modalVisible && children}, which remounts the child without prematurely unmounting on exit */}
          {modalVisible ? children : children}
        </div>
      </div>
    </div>
  );
}
