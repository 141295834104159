import React from 'react'
import Typography from '@material-ui/core/Typography/Typography'
import priceFormatter from '../../../lib/priceFormatter'
import Box from '@material-ui/core/Box/Box'

interface PriceWithDiscountProps {
	price?: number
	currency?: string
	priceWithoutDiscount?: number
	discount?: number
	priceProps?: any
	discountProps?: any
}

const PriceWithDiscount: React.FC<PriceWithDiscountProps> = ({
	price,
	priceWithoutDiscount,
	currency,
	discount,
	priceProps = {},
	discountProps = {}
}) => {
	const format =  currency ? priceFormatter(currency) : undefined
	discountProps.style = { ...discountProps.style ?? {}, textDecoration: 'line-through' }
	
	return (
		<>
			
			<Typography 
				{...priceProps}
			>
				<Box component="span" fontWeight={700}>{format?.(price ?? 0)}</Box>
			</Typography>
			{ !!discount && !!priceWithoutDiscount && 
				<Typography 
					{...discountProps}
					color="error"
				>
					{format?.(priceWithoutDiscount)}
				</Typography>
			}
		</>
	)
}
export default PriceWithDiscount
