import React, {Fragment, useContext, useMemo} from 'react'
import InfiniteScroll from '../../../InfiniteScroll/InfiniteScroll'
import ProductCard from '../../ProductCards/ProductCard'
import { Search } from '@material-ui/icons'
import { contextEShopProducts } from '../../../EShop/Providers/EShopProductsProvider'
import { contextEShopCart } from '../../../EShop/Providers/EShopCartProvider'
import { contextEShopCategory } from '../../../EShop/Providers/EShopCategoryProvider'
import NonIdealState from '../../../NonIdealState/NonIdealState'
import Grid from '@material-ui/core/Grid/Grid'
import { Box, Fade, Typography } from '@material-ui/core'

const EShopCatalog = () => {
	const { products, nextProducts, hasMore, recommendedProducts, isSearching, isLoading } = useContext(contextEShopProducts)
	const { cart } = useContext(contextEShopCart)
	const { gotoProduct, resetConfiguration } = useContext(contextEShopCategory)

	const noResults = !products?.data.length && !isLoading
	const hasRecommendedProducts = useMemo(() =>
		recommendedProducts && recommendedProducts.data?.length > 1 && !isLoading, [recommendedProducts, isLoading])

	return (
		<>
			{ !!products?.data.length && !products?.loading && !recommendedProducts &&
				<Box width="100%" height={30} mb={2} display="grid" style={{ placeContent: 'center' }}>
					<Typography variant="subtitle2" color="textSecondary">
						{`${products?.data.length ?? 0} / ${products?.total ?? 0}`}
					</Typography>
				</Box>
			}
			<InfiniteScroll
				src={!isSearching ? recommendedProducts ? recommendedProducts.data : (products?.data ?? []) : []} 
				next={recommendedProducts ? (()=>{}) : (()=>nextProducts?.({ reset: false }))}
				hasMore={recommendedProducts ? false : !!hasMore}
				container={(children: any) => 
					<Grid alignItems="stretch" container spacing={2}>{children}</Grid>
				}
				extractItem={(item, i) =>
					<Fragment key={i}>
						{cart?.productMethods?.setQuantity && <ProductCard
							product={item && {
								id: item.product.id,
								image: item.product.productInformation.images?.length > 0 ? item.product.productInformation.images[0].publicLink : "",
								title: item.product.productInformation.name,
								manufacturer: item.product.productInformation.manufacturer,
								description: item.product.productInformation.description,
								price: {
									price: item.product.productInformation.articlePrice.displayPrice,
									priceWithoutDiscount: item.product.productInformation.articlePrice.priceWithoutDiscount,
									currency: item.product.productInformation.articlePrice.currency,
									discount: item.product.productInformation.articlePrice.priceGroupDiscount
								},
								noneStockProduct: item.product.productInformation.noneStockProduct,
								stockAmount: item.product.disposableQuantity,
								articleNumber: item.product.fnArticleId,
								inStock: item.product.disposableQuantity > 0,
								maxOrderQuantity: item.product.maxOrderQuantity
							}}
							setProductInCart={cart?.productMethods?.setQuantity}
							gotoProduct={gotoProduct}
							b2COrder={cart.b2COrder}
						/>}
					</Fragment>
				}
				scrollableTarget={!window.location.href.includes('e-shop') ? 'scrollableTarget' : undefined}
			/>
			
			{ noResults &&
				<Fade in>
					<NonIdealState
						icon={<Search style={{ fontSize: 60, opacity: .6 }} />}
						title="Inga resultat"
						description={
							<>
								<span>Dina sökning matchade inte något produkt.</span>
								<span>Försök att söka efter något annat.</span>
							</>
						}
						action={
							<button
								className="btn btn-primary btn-md mt-2"
								onClick={resetConfiguration}
							>
								Välj alla produkter
							</button>
						}
					/>
				</Fade>
			}

			{hasRecommendedProducts &&
				<Fade in>
					<Box mt={2}>
						<NonIdealState
							title="Inga rekommenderade produkter"
							action={
								<button
									className="btn btn-primary btn-md mt-2"
									onClick={resetConfiguration}
								>
									Visa alla produkter
								</button>
							}
						/>
					</Box>
				</Fade>
			}
		</>
	)
}

export default EShopCatalog
