import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';
import {useHistory} from "react-router-dom";

import usersService from '../../services/users';

import {AppState} from '../../store';
import {setShowLoader} from '../../store/system';
import {getCustomers} from '../../store/companies';
import {Customer} from '../../store/companies/types';
import {UserType} from '../../store/users/types';

import {
  isAdminAccount,
  isBackOfficeAccount,
  isCustomerAccount,
  isMainAccount,
  isSuperAdminAccount,
} from '../../lib/permissions';

import {toggleToast} from '../../store/components'

import {
  AdminAccountFieldsInput,
  AdminAccountForm,
  AdminAccountPrivileges,
  AdminModulesFieldsInput,
  AssociatedCompanyForm,
  CustomerAccountFieldsInput,
  CustomerAccountForm,
} from '../../components/User/UserForm';
import CompanySelectModal from '../../components/Company/CompanySelectModal';

import {Module} from '../../store/users/types';
import {PATH_CUSTOMERS_MANAGE_USERS} from '../../router/paths';
import Radio from '@material-ui/core/Radio/Radio';
import Typography from '@material-ui/core/Typography/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup/RadioGroup';
import FormControl from '@material-ui/core/FormControl/FormControl';
import { Box } from '@material-ui/core';

const connector = connect(
  (state: AppState) => ({
    companies: state.companies.list,
    sessionUserType: state.system.sessionUserType,
    belongsToCustomer: state.system.sessionUser?.userPermission.belongsToCustomer,
  }),
  {setShowLoader, getCustomers, toggleToast}
);

type CreateUserProps = ConnectedProps<typeof connector> & {};

export function CreateUser({
  companies,
  sessionUserType,
  belongsToCustomer,
  setShowLoader,
  getCustomers,
  toggleToast
}: CreateUserProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const [selectedUserType, setSelectedUserType] = useState<UserType>(
    isBackOfficeAccount(sessionUserType)
      ? UserType.MainAccount
      : UserType.SubAccount
  );
  const [resetForm, setResetForm] = useState(false);
  const [modulesWarning, setModulesWarning] = useState(false);
  const [companiesModalVisible, setCompaniesModalVisible] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState<Customer | null>(null);

  const handleInput = useForm<any>({ mode: 'onChange'});

  const [page, setPage] = useState(1);
  const [searchCompany, setSearchCompany] = useState<null | string>(null);

  const {
    reset,
    formState: {isSubmitting},
  } = handleInput;
  const availableUserTypes = useMemo(() => {
    const availableUserTypes = [
      {
        value: `${UserType.MainAccount}`,
        label: t('common.Main account'),
      },
      {
        value: `${UserType.SubAccount}`,
        label: t('common.Sub account'),
      },
    ];

    if (isSuperAdminAccount(sessionUserType)) {
      availableUserTypes.unshift(
        {
          value: `${UserType.SuperAdmin}`,
          label: t('common.Super admin'),
        },
        {
          value: `${UserType.Admin}`,
          label: t('common.Admin'),
        }
      );
    }

    return availableUserTypes;
  }, [sessionUserType, t]);

  useEffect(() => {
    setShowLoader(isSubmitting);
  }, [isSubmitting, setShowLoader]);

  useEffect(() => {
    if (!resetForm) {
      return;
    }

    setSelectedCompany(null);
    reset();
    setResetForm(false);
  }, [resetForm, setSelectedCompany, reset, setResetForm]);

  useEffect(() => {
    reset();
    setPage(1);
    setSelectedCompany(null);
  }, [selectedUserType, reset, setPage, setSelectedCompany]);

  useEffect(() => {
    if (companiesModalVisible) {
      setPage(1);
      setSearchCompany(null);
    }
  }, [companiesModalVisible, setCompaniesModalVisible, setPage]);

  const onPagination = useCallback(
    (p) => {
      setPage(p);
    },
    [setPage]
  );

  useEffect(() => {
    if (!companiesModalVisible) {
      return;
    }

    getCustomers();
  }, [page, searchCompany, companiesModalVisible, getCustomers]);


  const openCompaniesModal = useCallback(
    () => setCompaniesModalVisible(true),
    []
  );

  const onCompanySelect = useCallback(
    (company: Customer) => () => {
      setSelectedCompany(company);
      setCompaniesModalVisible(false);
    },
    []
  );

  const onSelectAccountType = useCallback(
    (e: React.FormEvent<HTMLInputElement>) =>
      setSelectedUserType((e.currentTarget.value as any) as UserType),
    []
  );

  const onSubmit = async (input: any) => {
    if (isCustomerAccount(selectedUserType)) {
      if (selectedCompany === null) {
        if(isMainAccount(sessionUserType) && belongsToCustomer){
          const formInput = input as CustomerAccountFieldsInput;
          try {
            await usersService.createUser(belongsToCustomer,{
              name: formInput.name,
              email: formInput.email,
              phoneNumber: formInput.phoneNumber,
              position: formInput.position,
              userType: UserType.SubAccount,
              belongsToCompany: belongsToCustomer,
            });
            history.push(PATH_CUSTOMERS_MANAGE_USERS)
          } catch (e) {
            if (e.isAxiosError) {
              if (e?.response?.data?.message.includes(formInput.email)) {
                handleInput.setError('email', e?.response?.data?.message);
              }
              toggleToast({shouldToggle: true, message: e?.response?.data?.message, status: e?.response?.status});
            }
          }
        }
        return;
    }
    const formInput = input as CustomerAccountFieldsInput;

    try {
      await usersService.createUser(selectedCompany?.InternalCustomer.id,{
        name: formInput.name,
        email: formInput.email,
        phoneNumber: formInput.phoneNumber,
        position: formInput.position,
        userType: selectedUserType,
        belongsToCompany: selectedCompany.InternalCustomer.id,
      });
      history.push(PATH_CUSTOMERS_MANAGE_USERS)
    } catch (e) {
        if (e.isAxiosError) {
          if (e?.response?.data?.message.includes(formInput.email)) {
            handleInput.setError('email', e?.response?.data?.message);
          }
          toggleToast({shouldToggle: true, message: e?.response?.data?.message, status: e?.response?.status});
        }
      }
    }
    else if (isBackOfficeAccount(selectedUserType)) {
      if (selectedCompany === null) {
        return;
      }
      const formInput = input as AdminAccountFieldsInput;
      let {accessModules} = input as Partial<AdminModulesFieldsInput>;

      let finalAccessModules = [
        {module: Module.ACCOUNT_MANAGEMENT},
        {module: Module.E_SHOP_BACK},
        {module: Module.E_SHOP_FRONT},
        {module: Module.MATERIAL_CALCULATOR},
        {module: Module.OFFER_GENERATION},
        {module: Module.ORDER_MANAGEMENT},
        {module: Module.CASE_MANAGEMENT},
        {module: Module.ARCHIEVE},
        {module: Module.DOCUMENTATION}
      ];

      if(isAdminAccount(selectedUserType)) {
        //Admins should not get all the scopes.
        accessModules?.map((module) => ({module}))
      }
      if(isAdminAccount(selectedUserType)){
        if(accessModules?.length){
          finalAccessModules = accessModules.map((module) => ({module}))
          setModulesWarning(false)
        }else{
          setModulesWarning(true)
          return
        }
      }

      try {
        await usersService.createUser(selectedCompany?.InternalCustomer.id, {
          name: formInput.name,
          email: formInput.email,
          phoneNumber: formInput.phoneNumber,
          userType: selectedUserType,
          position: formInput.position,
          belongsToCompany: selectedCompany.InternalCustomer.id,
          accessModules: finalAccessModules,
        });
        history.push(PATH_CUSTOMERS_MANAGE_USERS)
      } catch (e) {
        if (e.isAxiosError) {
          if (e?.response?.status === 400) {
            if (e?.response?.data?.message.includes(formInput.email)) {
              handleInput.setError('email', `${t('validation.Used email')}: ${formInput.email}`);
            }
          } else if (e?.response?.status === 401) {
            toggleToast({ shouldToggle: true, message: e?.response?.data?.message, status: e?.response?.status });
          } else if (e?.response?.status === 403) {
            toggleToast({ shouldToggle: true, message: e?.response?.data?.message, status: e?.response?.status });
          }
        }
      }
    } else {
      console.warn('Unknown user account type', selectedUserType);
    }
  };

  return (
    <div>
      <h1 data-testid="create_user_headline">
        {t('navigation.General__nav.Create new user')}
      </h1>
      {isBackOfficeAccount(sessionUserType) && (
        <CompanySelectModal
          companies={companies}
          page={page}
          modalVisible={companiesModalVisible}
          onPagination={onPagination}
          onSelect={onCompanySelect}
          setModalVisible={setCompaniesModalVisible}
        />
      )}
      <form onSubmit={handleInput.handleSubmit(onSubmit)}>
        {isBackOfficeAccount(sessionUserType) && (
          <>
			<FormControl component="fieldset">
				<RadioGroup value={selectedUserType} onChange={onSelectAccountType}>
					<Box display="flex" style={{ gap: 20 }}>
						{availableUserTypes.map(opt => 
							<FormControlLabel
								value={opt.value}
								control={<Radio size="small" color="primary" disableRipple />}
								label={
									<Typography className="form-check-label" component="label" variant="subtitle2" style={{ marginTop: 1 }} noWrap>
										{opt.label}
									</Typography>
								}
								labelPlacement="end"
							/>
						)}
					</Box>
				</RadioGroup>
			</FormControl>
            <hr className="form-delimiter" />
          </>
        )}

        {isCustomerAccount(selectedUserType) && (
          <CustomerAccountForm handleInput={handleInput} />
        )}

        {isBackOfficeAccount(selectedUserType) && (
            <AdminAccountForm handleInput={handleInput} isEdit={false}/>
        )}

        {isBackOfficeAccount(sessionUserType) &&
            <AssociatedCompanyForm
              handleInput={handleInput}
              company={selectedCompany}
              openCompaniesModal={openCompaniesModal}
            />
          }

        {selectedUserType === UserType.Admin && (
          <AdminAccountPrivileges handleInput={handleInput} warning={modulesWarning}/>
        )}

        <button
          type="submit"
          className="btn btn-primary mr-2"
          data-testid="submit__user_form"
        >
          {t('common.Save data')}
        </button>
      </form>
    </div>
  );
}

export default connector(CreateUser);
