import {NestDataObject, FieldError, ValidationOptions} from 'react-hook-form';
import {TFunction} from 'i18next';

export const PASSWORD_RULE = /^(?=.*[a-zåäö])(?=.*[A-ZÅÄÖ])(?=.*\d)(?=.*[\-_!~@#$%^&*()+=,.?´`'":{}|\[\]<>\\\/])[A-ZÅÄÖa-zåäö\d\-_!~@#$%^&*()+=,.?´`'":{}|\[\]<>\\\/]{6,}$/;

// Source: https://www.w3.org/TR/2012/WD-html-markup-20120329/input.email.html#input.email.attrs.value.single
export const EMAIL_PATTERN = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export const DEFAULT_RULES = {
  password: {
    minLength: 6,
    pattern: PASSWORD_RULE,
  },
  email: {
    minLength: 6,
    pattern: EMAIL_PATTERN,
  },
};

export function hasErrorOf<T>(
  errors: NestDataObject<T, FieldError>,
  errorType: string
) {
  return Object.keys(errors).some((field) => {
    const e: FieldError = (errors as any)[field as any];

    return e.type === errorType || e.types?.[errorType];
  });
}

export function getErrorObj<T>(
  errors: NestDataObject<T, FieldError> | undefined,
  name: string | undefined
) {
  if (!errors || !name) {
    return;
  }

  const [first, ...rest] = name.split('.');

  let finalErrObj = errors[first as keyof T];

  if (!finalErrObj) {
    return;
  }

  for (const key of rest) {
    finalErrObj = (finalErrObj as any)[key as keyof T];
  }

  return finalErrObj;
}

// TODO: handle the types properly
export function extractErrorText<T>(
  elementError: NestDataObject<T, FieldError>[keyof T] | undefined,
  rules: ValidationOptions | undefined,
  t: TFunction
) {
  // if we have an error
  // and it's not set manually
  if (
    elementError &&
    (('isManual' in elementError && !elementError.isManual) ||
      !('isManual' in elementError))
  ) {
    // if it has a message set explicitly
    if ('message' in elementError && elementError.message) {
      return elementError.message;
    } else {
      if (Array.isArray(elementError)) {
        console.error(elementError);
        throw new Error('not supported');
      } else {
        // if it's a regular form validation error
        // use the type to get a default error message
        if ('type' in elementError) {
          return t(`validation.${elementError.type}`, {
            value: rules && rules[elementError.type as keyof ValidationOptions],
          });
        } else {
          console.error(elementError);
          throw new Error('not supported');
        }
      }
    }
    // if we have an error
    // and it's set manually
    // and has a type, use it
    // like on request password form:
    // handleInput.setError('email', t('validation.E-mail address not found'));
  } else if (
    elementError &&
    'isManual' in elementError &&
    elementError.isManual &&
    elementError.type
  ) {
    return elementError.type;
  }
}

// TODO: don't use custom error message as a type?
export function getErrorTestId(elementError: any) {
  return `error__${elementError.ref.name}__${elementError?.type}`;
}
