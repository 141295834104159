import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';

import offerService from '../../services/offers';

import {
  listPending,
  listFulfilled,
  listRejected,
	entityPending,
	entityFulfilled,
	entityRejected,
} from '../utils';
import {EntityData, ListData} from '../types';
import { CopyOffer, Offer, OffersState, OfferStatus } from './types';
import errorResponse, { ErrorResponseResolver } from '../../lib/responseHelper';
import { Customer } from '../companies/types';

const name = 'offers';

export const getOffers = createAsyncThunk<ListData<Offer>, Parameters<typeof offerService.getOffers>[0]>(
  `eon-ui/getOffers`,
  async function (query) {
    const {data, headers} = await offerService.getOffers(query)
    return {
      data: data as Offer[],
      total: headers['x-total-count'],
    };
  }
);

export const getQuantitiesOfOfferStatuses = createAsyncThunk<Record<OfferStatus, number | null> & { ALL: number | null }, Parameters<typeof offerService.getOffers>[0]>(
  `eon-ui/getQuantitiesOfOfferStatuses`,
  async function (query) {
    const { data } = await offerService.getQuantitiesOfOfferStatuses(query)
    return {
			[OfferStatus.NEW]: data[OfferStatus.NEW.toLowerCase()+'Offers'],
			[OfferStatus.APPROVED]: data[OfferStatus.APPROVED.toLowerCase()+'Offers'],
			[OfferStatus.CANCELED]: data[OfferStatus.CANCELED.toLowerCase()+'Offers'],
			[OfferStatus.LOCKED]: data[OfferStatus.LOCKED.toLowerCase()+'Offers'],
			[OfferStatus.ORDERED]: data[OfferStatus.ORDERED.toLowerCase()+'Offers'],
			[OfferStatus.REJECTED]: data[OfferStatus.REJECTED.toLowerCase()+'Offers'],
			[OfferStatus.SENT]: data[OfferStatus.SENT.toLowerCase()+'Offers'],
			ALL: data.allOffers
		}
  }
);

export const getOffer = createAsyncThunk<Offer, string>(
  `eon-ui/getOffer`,
  async function (offerId) {
    const { data } = await offerService.getOffer(offerId)
    return data as Offer
  }
);

export const resetOffer = createAsyncThunk<Offer | null>(
  `eon-ui/resetOffer`,
  async function () {
    return null
  }
);

export const createOffer = createAsyncThunk<Offer, Parameters<typeof offerService.createOffer>[0] & {
	callback?: (errorResponseResolver: ErrorResponseResolver) => void;
}>(
	`eon-ui/createOffer`,
  async function ({ callback, ...params }) {
    try {
			const { data } = await offerService.createOffer(params);
      return data as Offer;
		} catch(error) {
			callback?.(errorResponse(error))
			return {} as Offer;
		}
  }
);

export const updateOffer = createAsyncThunk<Offer, Parameters<typeof offerService.updateOffer>[0] & {
	callback: (errorResponseResolver: ErrorResponseResolver) => void;
}>(
  `eon-ui/updateOffer`,
  async function ({ callback, ...params }) {
		try {
			const { data } = await offerService.updateOffer(params);
      return data as Offer;
		} catch(error) {
			callback(errorResponse(error))
			return {} as Offer;
		}
  }
);

export const deleteOffer = createAsyncThunk<void, string>(
	`eon-ui/deleteOffer`,
	async function (offerId) {
		await offerService.deleteOffer(offerId)
	}
);

export const updateOfferStatus = createAsyncThunk<Offer, Parameters<typeof offerService.updateOfferStatus>[0] & {
	callback: (errorResponseResolver: ErrorResponseResolver) => void;
}>(
  `eon-ui/updateOfferStatus`,
  async function ({ callback, ...params }) {
		try {
			const { data } = await offerService.updateOfferStatus(params);
      return data as Offer;
		} catch(error) {
			callback(errorResponse(error))
			return {} as Offer;
		}
  }
);

export const copyOffer = createAsyncThunk<void, CopyOffer>(
	`eon-ui/copyOffer`,
	async function (copyOffer) {
    const {data} = await offerService.copyOffer(copyOffer)
    return data;
	}
);

export const getRelatedCustomers = createAsyncThunk<ListData<Customer>>(
  `eon-ui/getRelatedCustomers`,
  async function () {
    const {data, headers} = await offerService.getRelatedCustomers()
    return {
      data: data as Customer[],
      total: headers['x-total-count'],
    };
  }
);

export const initialState: OffersState = {
  offers: {
    data: [],
    loading: false,
    total: 0,
		requestId: '',
  },
	offerStatuses: {
		data: null,
		loading: false,
		requestId: '',
	},
	offer: {
		data: null,
		loading: false,
		requestId: '',
	},
	customers: {
		data: [],
		loading: false,
		total: 0,
		requestId: '',
	}
};

const ordersSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOffers.pending, (state, action) => {
      listPending(state.offers, action);
    });
    builder.addCase(getOffers.fulfilled, (state, action) => {
      listFulfilled(state.offers, action);
    });
    builder.addCase(getOffers.rejected, (state, action) => {
      listRejected(state.offers, action);
    });

		builder.addCase(getQuantitiesOfOfferStatuses.pending, (state, action) => {
      entityPending(state.offerStatuses, action);
    });
    builder.addCase(getQuantitiesOfOfferStatuses.fulfilled, (state, action) => {
      entityFulfilled(state.offerStatuses, action);
    });
    builder.addCase(getQuantitiesOfOfferStatuses.rejected, (state, action) => {
      entityRejected(state.offerStatuses, action);
    });

		builder.addCase(getOffer.pending, (state, action) => {
      entityPending(state.offer, action);
    });
    builder.addCase(getOffer.fulfilled, (state, action) => {
      entityFulfilled(state.offer, action);
    });
    builder.addCase(getOffer.rejected, (state, action) => {
      entityRejected(state.offer, action);
    });

    
		builder.addCase(resetOffer.pending, (state, action) => {
      entityPending(state.offer, action);
    });
    builder.addCase(resetOffer.fulfilled, (state, action) => {
      entityFulfilled(state.offer, action);
    });
    builder.addCase(resetOffer.rejected, (state, action) => {
      entityRejected(state.offer, action);
    });

		builder.addCase(createOffer.fulfilled, (state, action) => {
      entityFulfilled(state.offer, action);
    });
    builder.addCase(createOffer.pending, (state, action) => {
      entityPending(state.offer, action);
    });
    builder.addCase(createOffer.rejected, (state, action) => {
      entityRejected(state.offer, action);
    });

		builder.addCase(updateOffer.fulfilled, (state, action) => {
      entityFulfilled(state.offer, action);
    });
    builder.addCase(updateOffer.pending, (state, action) => {
      entityPending(state.offer, action);
    });
    builder.addCase(updateOffer.rejected, (state, action) => {
      entityRejected(state.offer, action);
    });
		
		builder.addCase(getRelatedCustomers.pending, (state, action) => {
      listPending(state.customers, action);
    });
    builder.addCase(getRelatedCustomers.fulfilled, (state, action) => {
      listFulfilled(state.customers, action);
    });
    builder.addCase(getRelatedCustomers.rejected, (state, action) => {
      listRejected(state.customers, action);
    });
  },
});

export default ordersSlice.reducer;
