import React, {useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {FormContext, useForm} from 'react-hook-form';
import {setShowLoader} from '../../store/system';

import {AppState} from '../../store';
import {ProductCategoryInput} from '../../store/productCategories/types';

import ProductCategoryForm from '../../components/EShop/ProductCategoryForm';
import { useHistory } from 'react-router-dom';
import {createProductCategory} from '../../store/productCategories';

const connector = connect(
  (state: AppState) => ({
      productCategory: state.productCategories.selected,
  }),
  {setShowLoader, createProductCategory}
);

type CreateProductCategoryProps = ConnectedProps<typeof connector> & {};

const defaultValues = {
  categorySeconds: [{categoryName: '', categoryThirds: [{categoryName: ''}]}],
};

export function CreateProductCategory({setShowLoader, createProductCategory}: CreateProductCategoryProps) {
  const {t} = useTranslation();
  const history = useHistory();

  const handleInput = useForm<ProductCategoryInput>({
    mode: 'onChange',
    defaultValues: defaultValues,
  });
  const {formState: {isSubmitting},reset}= handleInput;

  useEffect(() => {
    setShowLoader(isSubmitting);
  }, [isSubmitting, setShowLoader]);

  const submit = async (input: ProductCategoryInput) => {
    input.categorySeconds = input.categorySeconds.filter(category => {
      if(category.categoryName.trim()!==''){
        if(category.categoryThirds){
          category.categoryThirds = category.categoryThirds.filter(s => s.categoryName?.trim() !== '');
        }
        return category
      }
	  return false
    })

    await createProductCategory({payload: input})
    reset();
    history.goBack()
  };

  return (
    <div>
      <h1 data-testid="create_product_categories_headline">
        {t('common.Create new category')}
      </h1>
      <FormContext {...handleInput}>
        <form onSubmit={handleInput.handleSubmit(submit)}>
          <ProductCategoryForm isEdit={false}/>

          <hr className="form-delimiter" />

          <button
              type="submit"
              className="btn btn-primary mr-2"
              data-testid="submit__company_form"
          >
            {t('common.Save data')}
          </button>
          <button
              type="reset"
              className="btn btn-info"
              onClick={() => {
                handleInput.reset(defaultValues)
                history.goBack()
              }}
          >
            {t('common.Cancel')}
          </button>
        </form>
      </FormContext>
    </div>
  );
}

export default connector(CreateProductCategory);
