export enum AccessModuleName {
  AccountManagement = 'ACCOUNT_MANAGEMENT',
  EShopBack = 'E_SHOP_BACK',
  EShopFront = 'E_SHOP_FRONT',
  MaterialCalculator = 'MATERIAL_CALCULATOR',
  OfferGeneration = 'OFFER_GENERATION',
  OrderManagement = 'ORDER_MANAGEMENT',
  CaseManagement = 'CASE_MANAGEMENT',
  Archieve = 'ARCHIEVE',
  Documentation = 'DOCUMENTATION',
}