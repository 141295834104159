import {request} from '../lib/request';

import {ENDPOINTS} from '../endpoints';

const methods = {
  createSession(payload: {email: string; password: string}) {
    return request({
      endpoint: ENDPOINTS.AUTH_CREATE,
      method: 'POST',
      payload,
    });
  },

  destroySession() {
    return request({
      endpoint: ENDPOINTS.AUTH_DESTROY,
      method: 'DELETE',
    });
  },

  getCurrentUser() {
    return request({
      endpoint: '/auth/info',
      method: 'GET',
    });
  },

  changeUser(userId: string) {
    return request({
      endpoint: ENDPOINTS.AUTH_CHANGE_USER,
      method: 'GET',
	  params: {userId},
    });
  },

  requestNewPassword(email: string) {
    return request({
      endpoint: ENDPOINTS.REQUEST_RESET_PASSWORD,
      method: 'POST',
      payload: {userCredential: {email}},
    });
  },

  createNewPassword({token, password}: {token: string; password: string}) {
    return request({
      endpoint: ENDPOINTS.UPDATE_PASSWORD,
      method: 'PUT',
      params: {token},
      payload: {userCredential: {password}},
    });
  },

  confirmUser({token, password}: {token: string; password: string}) {
    return request({
      endpoint: ENDPOINTS.ACTIVATE_ACCOUNT,
      method: 'POST',
      params: {token},
      payload: {userCredential: {password}},
    });
  },
};

export default methods
