import React, { useEffect, useCallback, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DateTime, LocaleOptions } from 'luxon';
import jQuery from 'jquery';
import { useHistory, generatePath } from 'react-router-dom';

import './NewsList.scss';

import { PATH_NEWS_EDIT_NEWS } from '../../router/paths';

import { AppState } from '../../store';
import { getNewsList, deleteNews } from '../../store/news';
import { setShowLoader } from '../../store/system';

import { useQuery } from '../../lib/hooks';

import Table from '../../components/Table';
import Pager from '../../components/Pager';
import TabNav from '../../components/TabNav';
import DropdownButton from '../../components/DropdownButton';
import DeleteNewsConfirmationModal from "../../components/News/DeleteNewsConfirmationModal";

const connector = connect(
    (state: AppState) => ({
        newsList: state.news.list,
    }),
    { getNewsList, deleteNews, setShowLoader }
);

type ManageNewsProps = ConnectedProps<typeof connector> & {};

export function ManageNews({
    newsList,
    getNewsList,
    deleteNews,
    setShowLoader,
}: ManageNewsProps) {
    const { t } = useTranslation();
    const history = useHistory();
    const query = useQuery();

    const filter = query.get('filter');
    const page = query.get('page');

    const { data: list, loading: newsLoading } = newsList;

    const showLoader = newsLoading;
    const [itemToDelete, setItemToDelete] = useState<string>('');
    const [searchStr, setSearchStr] = useState('');
    const onSearchChange = (e: any) => {
        setSearchStr(e.target.value);
    }

    const editNews = (id: string) => () => {
        history.push(generatePath(PATH_NEWS_EDIT_NEWS, { newsId: id }));
    };
    const deleteNewsHandler = (id: string) => async () => {
        setDeleteNewsConfirmationModalVisible(true)
        setItemToDelete(id)
    };

    const [
        deleteNewsConfirmationModalVisible,
        setDeleteNewsConfirmationModalVisible,
    ] = useState(false);

    const deleteItem = useCallback(async () => {
        await deleteNews(itemToDelete)
        getNewsList({});
        setDeleteNewsConfirmationModalVisible(false)
    }, [deleteNews, getNewsList, itemToDelete]);

    const onPagination = useCallback(
        (p) => {
            query.set('page', `${p}`);
            history.push(`?${query}`);
        },
        [query, history]
    );

    useEffect(() => {
        setShowLoader(showLoader);
        (jQuery('[data-toggle="tooltip"]') as any).tooltip();
    }, [showLoader, setShowLoader]);

    useEffect(() => {
        getNewsList({});
    }, [getNewsList]);

    function applyFilters(filter: string | null, searchStr: string) {
        let filteredItems = list.filter(data =>
            !searchStr || (
                data.name.toLowerCase().includes(searchStr.toLowerCase()) ||
                data.text.toLowerCase().includes(searchStr.toLowerCase()) ||
                data.headline.toLowerCase().includes(searchStr.toLowerCase())
            )
        )
        switch (filter) {
            case 'home':
                filteredItems = filteredItems.filter((data) => {
                    return data.visibleOnHomepage === true && data.isActive === true
                })
                break;
            case 'active':
                filteredItems = filteredItems.filter((data) => {
                    return data.isActive === true
                })
                break;
            case 'inactive':
                filteredItems = filteredItems.filter((data) => {
                    return data.isActive === false
                })
                break;
        }
        return filteredItems
    }
    const filteredItems = applyFilters(filter, searchStr)

    return (
        <div>
            <h1 data-testid="manage_news_headline">
                {t('navigation.Home__nav.Manage news')}
            </h1>
            <TabNav
                className="mb-5 mt-5"
                linkClassName="px-2"
                links={[
                    {
                        isActive: !filter,
                        path: '?filter=',
                        component: <>{t('common.All')}</>,
                    },
                    {
                        isActive: filter === 'home',
                        path: '?filter=home',
                        component: <>{t('common.Home')}</>,
                    },
                    {
                        isActive: filter === 'active',
                        path: '?filter=active',
                        component: <>{t('common.Active')}</>,
                    },
                    {
                        isActive: filter === 'inactive',
                        path: '?filter=inactive',
                        component: <>{t('common.Inactive')}</>,
                    },
                ]}
            />
            <Table
                loading={newsLoading}
                title={t('common.News')}
                searchField={{
                    name: 'newsSearch',
                    text: t('common.Search news'),
                    onChange: onSearchChange,
                    value: searchStr,
                }}
                header={[
                    t('common.Picture'),
                    t('common.Name'),
                    t('common.Headline'),
                    t('common.Description'),
                    t('common.Publication date'),
                    t('common.Validity'),
                    t('common.Status'),
                    {
                        key: 'search',
                        component: (
                            <Pager
                                page={page ? +page : 1}
                                totalItems={list.length}
                                onPagination={onPagination}
                            />
                        ),
                    },
                ]}
                row={(row) => {
                    const text = row.text?.split('\n').map((t, i) => (
                        <React.Fragment key={`${i}-${t.substr(10)}`}>
                            {t}
                            <br />
                        </React.Fragment>
                    ));

                    return (
                        <>
                            <td className="table__fit-content">
                                <img src={row.url} style={{ width: '132px' }} alt={row.name} />
                            </td>
                            <td className="table__fit-content">{row.name}</td>
                            <td className="table__fit-content">{row.headline}</td>
                            <td>
                                <div
                                    className="news-text"
                                    data-toggle="tooltip"
                                    data-trigger="hover"
                                    data-placement="auto"
                                    data-delay={`{"show": 500, "hide": 100}`}
                                    title={row.text || ''}
                                >
                                    {text}
                                </div>
                            </td>
                            <td>
                                {row.publicationDate
                                    ? DateTime.fromISO(row.publicationDate)
                                        .setLocale('sv')
                                        .toLocaleString(DateTime.DATE_SHORT as LocaleOptions)
                                    : '-'}
                            </td>
                            <td>
                                {row.numberOf} {row.numberOf > 1 ? t(`common.${row.unit}`) : t(`common.${row.unit.slice(0, -1)}`)}
                            </td>
                            <td>
                                {row.isActive ? 'Aktiv' : 'Inaktiv'}
                            </td>

                            <td className="text-center">
                                <DropdownButton
                                    id={`news__option__dropdown_${row.id}`}
                                    label={t('common.Options')}
                                    dropdownList={[
                                        { text: t('common.Edit'), onClick: editNews(row.id) },
                                        { text: t('common.Delete'), className: 'text-danger', onClick: deleteNewsHandler(row.id) },
                                    ]}
                                />
                            </td>
                        </>
                    );
                }}
                list={filteredItems}
                extractKey={(el) => el.id}
            />
            <DeleteNewsConfirmationModal
                modalVisible={deleteNewsConfirmationModalVisible}
                setModalVisible={setDeleteNewsConfirmationModalVisible}
                onConfirmation={deleteItem}
            />
        </div>
    );
}

export default connector(ManageNews);
