import moment from 'moment'

export default function formatTime(dateString: string | undefined): string {
    if(dateString !== undefined){
        const date = moment(dateString)
        if(date.isValid()){
            return moment(date).format('YYYY-MM-DD'); 
        }
    }
    return ""
}

export function formatTimeFromDate(dateString: Date | undefined | null): string {
    if(dateString !== undefined && dateString !== null){
        const date = moment(dateString)
        if(date.isValid()){
            return moment(date).format('YYYY-MM-DD'); 
        }
    }
    return ""
}

export function formatTimeMinuteHourFromDate(dateString: Date | undefined | null): string {
    if(dateString !== undefined && dateString !== null){
        const date = moment(dateString)
        if(date.isValid()){
            return moment(date).format('YYYY-MM-DD HH:mm'); 
        }
    }
    return ""
}

export function addDaysToDate(date: Date, days: number): Date{
    return moment(date).add(days, "days").toDate()
}


export function getYesterday(): Date {
    return moment().subtract(1, 'days').toDate()
}

  