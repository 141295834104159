import React from 'react';
import {useTranslation} from 'react-i18next';
import {FormContextValues} from 'react-hook-form';
import classnames from 'classnames';

import {extractErrorText, getErrorTestId, getErrorObj} from '../../lib/form';

import {InputDefaultProps} from './types';
import {AccessModuleName} from '../../types/permission/accessModuleName';

export type Option = {
  label: string;
  value: string;
  extraComponent?: JSX.Element;
};

type InlineTypes = {
  type: 'checkbox' | 'radio';
};

interface InlineCheckProps<T> extends InputDefaultProps {
  options: Option[];
  defaultChecked?: boolean;
  bigLabel?: boolean;
  handler?: FormContextValues<T>;
  onChange?: React.FormEventHandler;
}

function InlineCheckInput<T>({
  name,
  options,
  handler,
  rules,
  label,
  type,
  bigLabel,
  value,
  className,
  defaultChecked,
  readOnly,
  disabled,
  onChange,
}: InlineCheckProps<T> & InlineTypes) {
  const {t} = useTranslation();

  const elementError = getErrorObj(handler?.errors, name);
  const errorText = extractErrorText(elementError, rules, t);
  const outerId = `check__eon-ui`;

  if (handler !== undefined && onChange !== undefined) {
    throw new Error('two controller provided');
  }

  // if checkbox has readOnly property it can be still changed by label
  // so as a workaround we don't distinguish readOnly and disabled, if any
  // of it is enabled we disable it
  const isDisabled =
    (typeof readOnly === 'object' ? readOnly[name] : readOnly) ||
    (typeof disabled === 'object' ? disabled[name] : disabled);

  const props = {
    disabled: isDisabled,
    ref: handler
      ? rules
        ? handler.register(rules)
        : handler.register
      : undefined,
    onChange,
  };

  const opts = options.map((option) => {
    const innerId = `${outerId}__${option.value}`;

    return (
      // TODO: Refactor in the future (hidden property), Since removing material calculator this also needs to be removed. 2023-05-22
      <div className={classnames('form__check', className)} key={innerId} hidden={option.value === AccessModuleName.MaterialCalculator}>
        <div
          className={classnames('form__check__column', {
            'h-auto': option.extraComponent,
          })}
        >
          <input
            checked={
              onChange !== undefined ? option.value === value : undefined
            }
            value={option.value}
            defaultChecked={defaultChecked}
            className={classnames('form__check__input', {
              'form__check__input--radio': type === 'radio',
              'form__check__input--checkbox': type !== 'radio',
            })}
            type={type}
            name={name}
            id={innerId}
            data-testid={innerId}
            {...props}
          />
          <label
            className={classnames('form__check__label', {
              empty: !option.label,
            })}
            htmlFor={innerId}
          >
            {option.label}
          </label>
          <div
            className={classnames('form__check__checkbox', {
              'form__check__checkbox--radio': type === 'radio',
              'ie-fix': !option.extraComponent,
            })}
          ></div>
        </div>
        {option.extraComponent && (
          <div className="w-100 mt-2 flex-grow-1 mb-4">
            {option.extraComponent}
          </div>
        )}
      </div>
    );
  });

  return (
    <div className="form__check__group" data-testid={outerId}>
      {label === undefined ? null : (
        <div className={classnames('label', {big: bigLabel})}>{label}</div>
      )}
      <div className="form__check__container">{opts}</div>
      {elementError ? (
        <span
          className="form-control-error text-danger my-0"
          data-testid={getErrorTestId(elementError)}
        >
          {errorText}
        </span>
      ) : null}
    </div>
  );
}

export function InlineRadio<T>(props: InlineCheckProps<T>) {
  return <InlineCheckInput type="radio" {...props} />;
}

export function InlineCheckbox<T>(props: InlineCheckProps<T>) {
  return <InlineCheckInput type="checkbox" {...props} />;
}
