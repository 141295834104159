import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import casesService from '../../services/cases';
import { ListData } from '../types';
import { entityFulfilled, entityPending, entityRejected, listFulfilled, listPending, listRejected } from '../utils';
import { Case, CasesState, CreateCaseFormType, ServiceCaseCustomerComment, ServiceCaseStatus } from './types';
import errorResponse, { ErrorResponseResolver } from '../../lib/responseHelper';

const name = 'cases';



export const getAllCases = createAsyncThunk<ListData<Case>>(
  `eon-ui/getAllCases`,
  async function () {
    const { data, headers } = await casesService.getAllCases();
    return {
      data: data as Case[],
      total: +headers['x-total-count'],
    };
  }
);

export const getAllCustomerRelatedCases = createAsyncThunk<ListData<Case>>(
  `eon-ui/getAllCustomerRelatedCases`,
  async function () {
    const { data, headers } = await casesService.getAllCustomerRelatedCases();
    return {
      data: data as Case[],
      total: +headers['x-total-count'],
    };
  }
);

export const getAllUserRelatedCases  = createAsyncThunk<ListData<Case>>(
  `eon-ui/getAllUserRelatedCases`,
  async function () {
    const { data, headers } = await casesService.getAllUserRelatedCases();
    return {
      data: data as Case[],
      total: +headers['x-total-count'],
    };
  }
);

export const getAllCaseCategories = createAsyncThunk<ListData<Case>>(
  `eon-ui/getAllCaseCategories`,
  async function () {
    const { data, headers } = await casesService.getAllCases();
    return {
      data: data as Case[],
      total: +headers['x-total-count'],
    };
  }
);

export const getSpecificServiceCase = createAsyncThunk<Case, {
  serviceCaseId: string
}>(
  `eon-ui/getSpecificServiceCase`,
  async function ({serviceCaseId}) {
    const { data } = await casesService.getSpecificServiceCase(serviceCaseId);
    return data as Case;
  }
);

export const createServiceCase = createAsyncThunk<Case, {
  fileList: FileList[],
  createCaseFormType: CreateCaseFormType,
  callback: (errorResponseResolver: ErrorResponseResolver) => void;
  }>(
  `eon-ui/createServiceCase`,
  async function ({fileList, createCaseFormType, callback}) {
    try{
      const { data } = await casesService.createServiceCase(fileList, createCaseFormType);
      return data as Case;
      }
      catch(error){
        callback(errorResponse(error))
        return {} as Case;
      }
  }
);

export const updateServiceCase = createAsyncThunk<Case, {
  fileList: FileList[],
  createCaseFormType: CreateCaseFormType,
  serviceCaseId: string,
  callback: (errorResponseResolver: ErrorResponseResolver) => void;
  }>(
  `eon-ui/editServiceCase`,
  async function ({fileList, createCaseFormType, serviceCaseId, callback}) {
    try{
      const { data } = await casesService.editServiceCase(fileList, createCaseFormType, serviceCaseId);
      return data as Case;
      }
      catch(error){
        callback(errorResponse(error))
        return {} as Case;
      }
  }
);

export const deleteServiceCase = createAsyncThunk<Case, {
  serviceCaseId: string,
  callback: (errorResponseResolver: ErrorResponseResolver) => void;
  }>(
  `eon-ui/deleteServiceCase`,
  async function ({serviceCaseId, callback}) {
    try{
      await casesService.deleteServiceCase(serviceCaseId);
      return {} as Case;
      }
      catch(error){
        callback(errorResponse(error))
        return {} as Case;
      }
  }
);


export const downloadServiceCaseAttachment = createAsyncThunk<void, {
  fileId: string,
  serviceCaseId: string
  callback: (errorResponseResolver: ErrorResponseResolver) => void;
  }>(
  `eon-ui/downloadServiceCaseAttachment`,
  async function ({fileId, serviceCaseId, callback}) {
    await casesService.getCaseAttachmentFile(fileId, serviceCaseId)
    .then(r => {
      let extension:string = r.headers["file-extension"]
      if(r.status === 200){
      const url = window.URL.createObjectURL(new Blob([r.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'file'.concat(".").concat(extension.split("/")[1])); //or any other extension
      document.body.appendChild(link);
      link.click();
      }
    })
    .catch(e => {
      callback(errorResponse(e))
    })
  }
);


export const deleteServiceCaseAttachment = createAsyncThunk<Case, {
  serviceCaseId: string,
  attachmentId: string,
  callback: (errorResponseResolver: ErrorResponseResolver) => void;
  }>(
  `eon-ui/deleteServiceCaseAttachment`,
  async function ({serviceCaseId, attachmentId, callback}) {
    try{
      await casesService.deleteServiceCaseAttachment(serviceCaseId, attachmentId);
      return {} as Case;
      }
      catch(error){
        callback(errorResponse(error))
        return {} as Case;
      }
  }
);


export const createCaseComment = createAsyncThunk<Case, {
  fileList: FileList[],
  serviceCaseCustomerComment: ServiceCaseCustomerComment,
  serviceCaseId: string
  callback: (errorResponseResolver: ErrorResponseResolver) => void;
  }>(
  `eon-ui/createCaseComment`,
  async function ({fileList, serviceCaseCustomerComment, serviceCaseId, callback}) {
    try{
      await casesService.createCaseComment(
        fileList,
        serviceCaseCustomerComment,
        serviceCaseId);
        return {} as Case;
      }
      catch(error){
        callback(errorResponse(error))
        return {} as Case;
      }
  }
);

export const assignCaseToUser = createAsyncThunk<Case, {
  userId: string,
  serviceCaseId: string,
  serviceCaseStatus: ServiceCaseStatus
  callback: (errorResponseResolver: ErrorResponseResolver) => void;
  }>(
  `eon-ui/assignCaseToUser`,
  async function ({userId, serviceCaseId, serviceCaseStatus, callback}) {
    try{
      const { data } = await casesService.assignCaseToUser(
        userId,
        serviceCaseId,
        serviceCaseStatus);
        return data as Case;
      }
      catch(error){
        callback(errorResponse(error))
        return {} as Case;
      }
  }
);




const initialState: CasesState = {
  list: {
    data: [],
    loading: false,
    requestId: '',
    total: 0,
  },
  selected: {
    data: null,
    loading: false,
    requestId: '',
  },
};


const casesSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //delete from cart 
    builder.addCase(getAllCases.fulfilled, (state, action) => {
      listFulfilled(state.list, action);
    });
    builder.addCase(getAllCases.pending, (state, action) => {
      listPending(state.list, action);
    });
    builder.addCase(getAllCases.rejected, (state, action) => {
      listRejected(state.list, action);
    });
    builder.addCase(getAllCustomerRelatedCases.fulfilled, (state, action) => {
      listFulfilled(state.list, action);
    });
    builder.addCase(getAllCustomerRelatedCases.pending, (state, action) => {
      listPending(state.list, action);
    });
    builder.addCase(getAllCustomerRelatedCases.rejected, (state, action) => {
      listRejected(state.list, action);
    });
    builder.addCase(getAllUserRelatedCases.fulfilled, (state, action) => {
      listFulfilled(state.list, action);
    });
    builder.addCase(getAllUserRelatedCases.pending, (state, action) => {
      listPending(state.list, action);
    });
    builder.addCase(getAllUserRelatedCases.rejected, (state, action) => {
      listRejected(state.list, action);
    });
    builder.addCase(getAllCaseCategories.fulfilled, (state, action) => {
      listFulfilled(state.list, action);
    });
    builder.addCase(getAllCaseCategories.pending, (state, action) => {
      listPending(state.list, action);
    });
    builder.addCase(getAllCaseCategories.rejected, (state, action) => {
      listRejected(state.list, action);
    });
    builder.addCase(createServiceCase.fulfilled, (state, action) => {
      entityFulfilled(state.selected, action);
    });
    builder.addCase(createServiceCase.pending, (state, action) => {
      entityPending(state.selected, action);
    });
    builder.addCase(createServiceCase.rejected, (state, action) => {
      entityRejected(state.selected, action);
    });
    builder.addCase(deleteServiceCase.fulfilled, (state, action) => {
      entityFulfilled(state.selected, action);
    });
    builder.addCase(deleteServiceCase.pending, (state, action) => {
      entityPending(state.selected, action);
    });
    builder.addCase(deleteServiceCase.rejected, (state, action) => {
      entityRejected(state.selected, action);
    });
    builder.addCase(getSpecificServiceCase.fulfilled, (state, action) => {
      entityFulfilled(state.selected, action);
    });
    builder.addCase(getSpecificServiceCase.pending, (state, action) => {
      entityPending(state.selected, action);
    });
    builder.addCase(getSpecificServiceCase.rejected, (state, action) => {
      entityRejected(state.selected, action);
    });
    builder.addCase(updateServiceCase.fulfilled, (state, action) => {
      entityFulfilled(state.selected, action);
    });
    builder.addCase(updateServiceCase.pending, (state, action) => {
      entityPending(state.selected, action);
    });
    builder.addCase(updateServiceCase.rejected, (state, action) => {
      entityRejected(state.selected, action);
    });
    builder.addCase(assignCaseToUser.fulfilled, (state, action) => {
      entityFulfilled(state.selected, action);
    });
    builder.addCase(assignCaseToUser.pending, (state, action) => {
      entityPending(state.selected, action);
    });
    builder.addCase(assignCaseToUser.rejected, (state, action) => {
      entityRejected(state.selected, action);
    });
    builder.addCase(createCaseComment.fulfilled, (state, action) => {
      entityFulfilled(state.selected, action);
    });
    builder.addCase(createCaseComment.pending, (state, action) => {
      entityPending(state.selected, action);
    });
    builder.addCase(createCaseComment.rejected, (state, action) => {
      entityRejected(state.selected, action);
    });
    
  }
});

export default casesSlice.reducer;