import React, {ChangeEvent, useContext, useState} from 'react';
import { contextEShopCategory } from '../../../EShop/Providers/EShopCategoryProvider'
import TreeView from '@material-ui/lab/TreeView/TreeView'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { ChevronRight } from '@material-ui/icons'
import { contextEShopProducts } from '../../../EShop/Providers/EShopProductsProvider'
import {EShopTreeItem} from './EShopTreeviewItem';
import { makeStyles } from '@material-ui/core';

interface ProductCategoryProps {
	disabled?: boolean
}

const EShopCategoryTree = ({
	disabled
}: ProductCategoryProps) => {
	const { configuration, setConfiguration, productCategories, showRecommendedProducts, resetConfiguration } = useContext(contextEShopCategory)
	const { resetProductsFilters } = useContext(contextEShopProducts)
	const [selectedCategoryId, setSelectedCategoryId] = useState<string>("1");
	disabled =  disabled || setConfiguration == undefined
	
    return (
		<TreeView
			defaultCollapseIcon={<ExpandMore />}
			defaultExpandIcon={<ChevronRight />}
			style={{ ...disabled ? { opacity: .4 } : {} }}
			onNodeSelect={(node: ChangeEvent<{}>, nodeId: string) => setSelectedCategoryId(nodeId ?? "1")}
			expanded={configuration ? [configuration.category1.id,configuration.category2.id,configuration.category3.id] : []}
			selected={selectedCategoryId}
		>
			<EShopTreeItem nodeId="1" label="Alla produkter" onClick={() => {
				resetProductsFilters?.()
				resetConfiguration?.()
			}} />
			<EShopTreeItem nodeId="2" label="Rekommenderat" onClick={() => {
				resetProductsFilters?.()
				showRecommendedProducts?.()
			}} />


			{productCategories?.data.map(pcd => 
				pcd.amountProducts > 0 && (<EShopTreeItem
					key={pcd.id}
					nodeId={pcd.id} 
					label={pcd.categoryName}
					onClick={!disabled ? () => setConfiguration?.({ id1: pcd.id }) : undefined}
				>

					{pcd.categorySeconds.map(pcd2 =>
						pcd2.amountProducts > 0 && (<EShopTreeItem
							key={pcd2.id}
							nodeId={pcd2.id}
							label={pcd2.categoryName}
							onClick={!disabled ? () => setConfiguration!({ id1: pcd.id, id2: pcd2.id }) : undefined}
						>
						
							{pcd2.categoryThirds.map(pcd3 =>
								pcd3.amountProducts > 0 && (<EShopTreeItem
									key={pcd3.id}
									nodeId={pcd3.id}
									label={pcd3.categoryName}
									onClick={!disabled ? () => setConfiguration!({ id1: pcd.id, id2: pcd2.id, id3: pcd3.id}) : undefined}
								/>
								
							))}
						</EShopTreeItem>
						
					))}
				</EShopTreeItem>
			))}
		</TreeView>
    )
}

export default EShopCategoryTree
