import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { AxiosResponse } from 'axios';
import usersService from '../services/users'
import { ListMeta } from '../services/types';
import { User } from '../store/users/types';

const useUsers = () => {
    const [loading, setLoading] = useState(false)
    const [users, setUsers] = useState<User[]>()
    const [error, setError] = useState<string>()
    const [response, setResponse] = useState<AxiosResponse>()
    const {t} = useTranslation()

    useEffect(() =>{

    }, [response, setResponse])

    const getUsers = async ({page, filter, textSearch, limit, onlyAdmins}: ListMeta): Promise<void> => {
        
        if (!loading) {
            setLoading(true)
            setError(undefined)
            try{
                let response =  await usersService.getUsers({page, filter, textSearch, limit, onlyAdmins});

                if(response?.data)
                    setUsers(response?.data as User[])
            }catch (e: any) {
                setError(e)
                setResponse({statusText: "Something whent wrong", status: 400} as AxiosResponse)
            } finally {
                setLoading(false)
            }
        }
    }

    return {
        loading,
        error,
        response,
        getUsers,
        users
      }
}
export default useUsers