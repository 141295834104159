import React from 'react'
import { green } from '@material-ui/core/colors'
import { CheckCircleOutline, ErrorOutline } from '@material-ui/icons'
import { formatTimeFromDate } from '../../../lib/timeHelper'
import { CheckoutResponse } from '../../../store/checkout/types'
import { Entity } from '../../../store/types'
import Card from '@material-ui/core/Card/Card'
import CardContent from '@material-ui/core/CardContent/CardContent'
import Box from '@material-ui/core/Box/Box'
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress'
import Typography from '@material-ui/core/Typography/Typography'
import Divider from '@material-ui/core/Divider/Divider'
import Grid from '@material-ui/core/Grid/Grid'

interface CheckoutConfirmationCardProps {
	checkoutResponse: Entity<CheckoutResponse> 
	setOrderToUnfinished: () => void
}

const CheckoutConfirmationCard: React.FC<CheckoutConfirmationCardProps> = ({ 
	checkoutResponse,
	setOrderToUnfinished
}) =>  {
	const { error, loading, data } = checkoutResponse
	
	return (
		<Card style={{ maxWidth: '80ch', width: '100%' }}>
			<CardContent>

				{ data == undefined || loading ?
					
					<Box justifyItems="center" display="grid" pt={2} pb={4} gridGap={20}>
						<CircularProgress color="primary" style={{ fontSize: 80 }}/>
						<Typography variant="h4" color="textPrimary" component="h4" style={{ fontSize: '1.7rem' }}>
							Tack för din beställning!
						</Typography>
					</Box>

				: error != undefined ?
					<Box justifyItems="center" display="grid" pt={2} pb={4} gridGap={20}>
						<ErrorOutline color="error" style={{ fontSize: 80 }}/>
						<Typography variant="h4" color="textPrimary" component="h4" style={{ fontSize: '1.7rem' }}>
							Något har gått fel
						</Typography>
						<Divider />
						<button type="button" className="btn btn-primary btn-lg" onClick={setOrderToUnfinished}>
							Gå tillbaka
						</button>
					</Box>

				:
					<>
						<Box justifyItems="center" display="grid" pt={2} pb={4} gridGap={20}>
							<CheckCircleOutline style={{ color: green[500], fontSize: 80 }}/>
							<Typography variant="h4" color="textPrimary" component="h4" style={{ fontSize: '1.7rem' }}>
								Tack för din beställning!
							</Typography>
						</Box>

						<Divider />

						{data.map(order => 
							<Grid container justify="center">
								<Box py={5} px={1} style={{ maxWidth: '60ch', width: '100%' }}>
									<Typography variant="h6" component="h5">
										Beställningsdetaljer, order id: {order.orderId}
									</Typography>
									<Grid container spacing={2}>

										<Grid item xs={6}>
											<Box mt={3}>
												<Box mb={1}>
													<Typography variant="body1" component="h6">
														<b>Skickas till</b>
													</Typography>
												</Box>
												<Typography variant="body2" component="div">
													<Grid container direction="column">
														{item('Namn', order.deliveryCustomerName)}
														{item('Adress', order.deliveryAddress)}
														{item('Postnummer', order.deliveryPostalNo)}
														{item('Stad', order.deliveryCity)}
														{item('Land', order.deliveryCountry)}
														{item('Telefon', order.deliveryPhone)}
														{item('Delleverans', order.partlyDelivery ? 'Ja' : 'Nej')}
													</Grid>
												</Typography>
											</Box>
										</Grid>

										<Grid item xs={6}>
											<Box mt={3}>
												<Box mb={1}>
													<Typography variant="body1" component="h6">
														<b>Leveransdatum</b>
													</Typography>
												</Box>
												<Typography variant="body2" component="div">
													<Grid container direction="column">
														{item('Önskat', order.proposedDeliveryDate ? formatTimeFromDate(new Date(order.proposedDeliveryDate)) : 'Okänd')}
														{item('Tidigaste', order.preliminaryDeliveryDate ? formatTimeFromDate(new Date(order.preliminaryDeliveryDate)) : 'Okänd')}
													</Grid>
												</Typography>
											</Box>
										</Grid>
										
									</Grid>
								</Box>
							</Grid>	
						)}

						<Divider />

						<Box justifyItems="center" display="grid" pt={3}>
							<Box mb={1}>
								<Typography variant="body1">
									En bekräftelse kommer levereras till:
								</Typography>
								<Typography variant="body1">
									{data[0].customerReferenceEmail}
								</Typography>
							</Box>
							<Typography variant="body2">
								Mvh, Solar Supply
							</Typography>
						</Box>						
					</>

				}

			</CardContent>
		</Card>
	)
}

const item = (label: string, value: string) => <Grid item><Typography variant="caption" component="span">{label}: </Typography><Typography variant="body2" component="span">{value}</Typography></Grid>

export default CheckoutConfirmationCard
