import React from 'react';
import {useTranslation} from 'react-i18next';
import {FormContextValues} from 'react-hook-form';
import classnames from 'classnames';

import {InputDefaultProps} from './types';
import {extractErrorText, getErrorTestId, getErrorObj} from '../../lib/form';

interface TextAreaFieldProps<T> extends InputDefaultProps {
  placeholder?: string;
  rows?: number;
  handler: FormContextValues<T>;
}

export default function TextAreaField<T>({
  name,
  handler,
  label,
  className,
  readOnly,
  disabled,
  defaultValue,
  placeholder,
  rules,
  rows,
  value,
}: TextAreaFieldProps<T>) {
  const {t} = useTranslation();

  const id = `text_area__eon-ui`;

  const elementError = getErrorObj(handler.errors, name);
  const errorText = extractErrorText(elementError, rules, t);

  placeholder =
    placeholder === null
      ? undefined
      : placeholder === undefined && typeof label === 'string'
      ? label
      : placeholder;

  const props = {
    name,
    id,
    value,
    placeholder,
    defaultValue,
    readOnly: typeof readOnly === 'object' ? readOnly[name] : readOnly,
    disabled: typeof disabled === 'object' ? disabled[name] : disabled,
    ref: rules ? handler.register(rules) : handler.register,
    'data-testid': id,
  };

  return (
    <div className={classnames('form-group', className)}>
      {label === undefined ? null : <label htmlFor={id}>{label}</label>}
      <textarea
        className={classnames('form-control', {
          border: elementError,
          'border-danger': elementError,
        })}
        rows={rows ?? 10}
        {...props}
      />
      {elementError ? (
        <span
          className="form-control-error text-danger"
          data-testid={getErrorTestId(elementError)}
        >
          {errorText}
        </span>
      ) : null}
    </div>
  );
}
