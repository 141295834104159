import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';

import ordersService from '../../services/orders';

import {
  listPending,
  listFulfilled,
  listRejected,
  entityFulfilled,
  entityPending,
  entityRejected,
} from '../utils';
import {ListData} from '../types';

import {CustomerOrderLimitations, Order, OrdersState, OrderStats, OrderWithLimits, OrderWithLimitsResponse} from './types';
import { CallbackOnFail, CallbackOnSuccess } from '../components/types';
import errorResponse, { ErrorResponseResolver } from '../../lib/responseHelper';

const name = 'orders';

export const getOrderStats = createAsyncThunk<OrderStats>(
  `eon-ui/getOrderStats`,
  async function () {
    const [
      {headers: allHeaders},
      {headers: activeHeaders},
      {headers: completedHeaders},
    ] = await ordersService.getOrderStats();

    return {
      all: +allHeaders['x-total-count'],
      active: +activeHeaders['x-total-count'],
      completed: +completedHeaders['x-total-count'],
    };
  }
);

export const getOrdersWithLimits = createAsyncThunk<ListData<OrderWithLimits>, Parameters<typeof ordersService.getOrdersWithLimits>[0]>(
  `eon-ui/getOffers`,
  async function (query) {
    const {data, headers} = await ordersService.getOrdersWithLimits(query)
    
    const orders = data as OrderWithLimitsResponse

    return {
      // eslint-disable-next-line no-sequences
      data: orders.ShopOrders.reduce<OrderWithLimits[]>((p,c) => (p.push({ order: c, limitations: orders.CustomerOrderLimitations.find(l => l.customerId === c.customerId) as CustomerOrderLimitations }), p), []),
      total: headers['x-total-count'],
    };
  }
);


export const getSpecificOrder = createAsyncThunk<Order, {orderId: string}>(
  `eon-ui/getSpecificOrder`,
  async function ({orderId}) {
    const {data} = await ordersService.getSpecificOrder(orderId);
    return data as Order;
  }
)

export const getSpecificOrders = createAsyncThunk<ListData<Order>, {orderId: string}>(
  `eon-ui/getSpecificOrders`,
  async function ({orderId}) {
    const {data, headers} = await ordersService.getSpecificOrders(orderId);
    return {
			data: data,
			total: +headers['x-total-count']
		};
  }
)

export const changeStatus = createAsyncThunk<Order, {orderId: string; status: string;} & {
	callback: (errorResponseResolver: ErrorResponseResolver) => void;
}>(
  `eon-ui/updateOrderStatus`,
  async function ({ callback, orderId, status }) {
		try {
			const {data} = await ordersService.changeStatus(orderId, status);
      return data as Order;
		} catch(error) {
      const {data} = await ordersService.getSpecificOrder(orderId)
			callback(errorResponse(error))
			return data as Order;
		}
  }
);

export const addArticleToOrder = createAsyncThunk<Order, 
  {
    orderId: string; 
    articleId: string;
    addedToOrder: Date;
    callbackOnSuccess: CallbackOnSuccess;
    callbackOnFail: CallbackOnFail;
  }>(
  `eon-ui/addArticleToOrder`,
  async function ({orderId, articleId, addedToOrder, callbackOnSuccess, callbackOnFail}) {
    try {
      const {data, status} = await ordersService.addArticleToOrder(orderId, articleId, addedToOrder);
      callbackOnSuccess(status, data);
      const {data:data3 } = await ordersService.getSpecificOrder(orderId)
      return data3 as Order;
    }
    catch(e){
      const {data:data2 } = await ordersService.getSpecificOrder(orderId)
      callbackOnFail(errorResponse(e));
      return data2 as Order;
    }
  }
)


export const removeArticleFromOrder = createAsyncThunk<Order, 
  {
    orderId: string; 
    articleId: string;
    callbackOnSuccess: CallbackOnSuccess;
    callbackOnFail: CallbackOnFail;
  }>(
  `eon-ui/removeArticleFromOrder`,
  async function ({orderId, articleId, callbackOnSuccess, callbackOnFail}) {
    try {
      const {data, status} = await ordersService.removeArticleFromOrder(orderId, articleId);
      callbackOnSuccess(status, data);
      const {data:data3 } = await ordersService.getSpecificOrder(orderId)
      return data3 as Order;
    }
    catch(e){
      const {data:data2 } = await ordersService.getSpecificOrder(orderId)
      callbackOnFail(errorResponse(e));
      return data2 as Order;
    }
  }
)


export const initialState: OrdersState = {
  list: {
    data: [],
    loading: false,
    requestId: '',
    total: 0,
  },
  selected: {
    data: null,
    loading: false,
    requestId: '',
  },
  selectedOrders: {
    data: [],
    loading: false,
    requestId: '',
    total: 0,
  },
  orderWithLimits: {
    data: [],
    loading: false,
    requestId: '',
    total: 0,
  },
  stats: {
    data: null,
    loading: false,
    requestId: '',
  },
};

const ordersSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {

    builder.addCase(getOrdersWithLimits.pending, (state, action) => {
		listPending(state.orderWithLimits, action);
    });
    builder.addCase(getOrdersWithLimits.fulfilled, (state, action) => {
		listFulfilled(state.orderWithLimits, action);
    });
    builder.addCase(getOrdersWithLimits.rejected, (state, action) => {
		listRejected(state.orderWithLimits, action);
    });



    builder.addCase(getOrderStats.pending, (state, action) => {
      entityPending(state.stats, action);
    });
    builder.addCase(getOrderStats.fulfilled, (state, action) => {
      entityFulfilled(state.stats, action);
    });
    builder.addCase(getOrderStats.rejected, (state, action) => {
      entityRejected(state.stats, action);
    });

    builder.addCase(getSpecificOrder.pending, (state, action) => {
      entityPending(state.selected, action);
    });
    builder.addCase(getSpecificOrder.fulfilled, (state, action) => {
      entityFulfilled(state.selected, action);
    });
    builder.addCase(getSpecificOrder.rejected, (state, action) => {
      entityRejected(state.selected, action);
    });

    builder.addCase(getSpecificOrders.pending, (state, action) => {
      listPending(state.selectedOrders, action);
    });
    builder.addCase(getSpecificOrders.fulfilled, (state, action) => {
      listFulfilled(state.selectedOrders, action);
    });
    builder.addCase(getSpecificOrders.rejected, (state, action) => {
      listRejected(state.selectedOrders, action);
    });

    builder.addCase(changeStatus.pending, (state, action) => {
      entityPending(state.selected, action);
    });
    builder.addCase(changeStatus.fulfilled, (state, action) => {
      entityFulfilled(state.selected, action);
    });
    builder.addCase(changeStatus.rejected, (state, action) => {
      entityRejected(state.selected, action);
    });
    builder.addCase(addArticleToOrder.pending, (state, action) => {
      entityPending(state.selected, action);
    });
    builder.addCase(addArticleToOrder.fulfilled, (state, action) => {
      entityFulfilled(state.selected, action);
    });
    builder.addCase(addArticleToOrder.rejected, (state, action) => {
      entityRejected(state.selected, action);
    });
    builder.addCase(removeArticleFromOrder.pending, (state, action) => {
      entityPending(state.selected, action);
    });
    builder.addCase(removeArticleFromOrder.fulfilled, (state, action) => {
      entityFulfilled(state.selected, action);
    });
    builder.addCase(removeArticleFromOrder.rejected, (state, action) => {
      entityRejected(state.selected, action);
    });
    
  },
});

export default ordersSlice.reducer;