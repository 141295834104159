import { useForm } from "react-hook-form"
import { connect, ConnectedProps } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { ErrorResponseResolver } from '../../../lib/responseHelper'
import { PATH_BANNERMESSAGE_BACK_OFFICE_LIST } from '../../../router/paths'
import { AppState } from '../../../store'
import { createBannerMessage } from '../../../store/bannermessages'
import { CreateBannerMessageFormType } from '../../../store/bannermessages/types'
import { toggleToast } from '../../../store/components'
import CreateMessageForm from "./CreateMessageForm";
import {useTranslation} from "react-i18next";

const connector = connect(
    (state: AppState) => ({
        genericListOption: state.genericListOption.selected
    }),
    { createBannerMessage, toggleToast }
);

type CreateMessageProps = ConnectedProps<typeof connector> & {};

export function CreateMessage({
                               createBannerMessage,
                               toggleToast
                           }: CreateMessageProps) {

    const { t } = useTranslation();
    const history = useHistory();

    const handler = useForm<CreateBannerMessageFormType>({ mode: 'onSubmit' });

    const handleSubmit = (async (createBannerMessageFormType: CreateBannerMessageFormType) => {
        let feedback = true;
        await createBannerMessage({
            createBannerMessageFormType,
            callback: (errorResponseResolver: ErrorResponseResolver) => {
                toggleToast({
                    shouldToggle: true,
                    message: errorResponseResolver.message,
                    status: errorResponseResolver.status
                })
                feedback = false;
            }
        }).then(() => {
            if(feedback) {
                let msg = t('common.Message created');
                toggleToast({
                    shouldToggle: true,
                    message: msg,
                    status: 200
                })
            }
            feedback = false;
        })
        history.push(PATH_BANNERMESSAGE_BACK_OFFICE_LIST)
    })

    return (
        <div>
            <h1 data-testid="manage_companies_headline">Meddelandehantering</h1>
            <h4>Skapa nytt meddelande</h4>
            <p></p>
            <CreateMessageForm
                handler={handler}
                handleSubmit={handleSubmit}
                isEdit={false}
            />
        </div>
    );
}

export default connector(CreateMessage);
