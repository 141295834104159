import React, {useCallback, useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {getProductCategories} from '../../store/productCategories';

import newsService from '../../services/news';

import {AppState} from '../../store';
import {setShowLoader} from '../../store/system';
import {TimeUnit} from '../../store/news/types';

import {NewsFrom, NewsFormInput} from '../../components/News/NewsForm';

const connector = connect((state: AppState) => ({
  productCategories: state.productCategories.list,
}), {
  setShowLoader,
  getProductCategories
});

type CreateNewsProps = ConnectedProps<typeof connector> & {};

export function CreateNews({setShowLoader: _setShowLoader, productCategories, getProductCategories}: CreateNewsProps) {
  const {t} = useTranslation();
  const setShowLoader = useCallback(
    (set: boolean) => {
      _setShowLoader(set);
    },
    [_setShowLoader]
  );

  useEffect(() => {
    getProductCategories();
  }, [getProductCategories]);

  const onSubmit = useCallback((input: NewsFormInput, data: Blob) => {
    return newsService.createNews({
      name: input.name,
      numberOf: +input.numberOf,
      unit: input.unit.value as TimeUnit,
      publicationDate: input.publicationDate.toISOString(),
      position: input.position,
      headline: input.headline,
      headlineSize: +input.headlineSize.value,
      text: input.text,
      visibleOnHomepage: input.visibleOnHomepage,
      linkTo:input.category.value,
      data,
    });
  }, []);

  return (
    <div>
      <h1 data-testid="create_news_headline">
        {t('navigation.Home__nav.Create new news')}
      </h1>
      <NewsFrom onSubmit={onSubmit} setShowLoader={setShowLoader} categories={productCategories}/>
    </div>
  );
}

export default connector(CreateNews);
