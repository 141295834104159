import React, { useState } from 'react';
import {useTranslation} from 'react-i18next';
import Table from '../Table';
import Modal from '../Modal';
import {Product} from '../../store/products/types';
import { Box } from '@material-ui/core';
import NumberInput from '../NumberInput/NumberInput';

export default function ProductSelectModal({
  products,
  modalVisible,
  id,
  onSelect,
  setModalVisible,
  isClearable
}: {
  id: number;
  products: Product[];
  modalVisible: boolean;
  onSelect: (product?: Product | null, quantity?: number) => React.MouseEventHandler;
  setModalVisible: (f: boolean) => void;
  isClearable?: boolean
}) {
  const {t} = useTranslation();

  const [ searchTerm, setSearchTerm ] = useState('')
  
  const filteredProducts = products.filter(p=>[p.fnArticleId, p.productInformation.name].some(s => (s+'').toLocaleLowerCase().includes(searchTerm.toLowerCase()))) 

  return (
    <Modal
      name={`product-list-com.eon.ui:eon-ui:war:1.0-SNAPSHOT`}
      modalVisible={modalVisible}
      setModalVisible={setModalVisible}
      title="Lägg till produkt"
    >
	  {isClearable && <Box mb={2}><button className="btn btn-danger btn-lg" onClick={onSelect(null)}>Ta bort</button></Box>}
      <Table
        header={[
          t('common.Article id'),
          t('common.Article name'),
          {
            key: 'options',
            component: <></>,
          },
        ]}
		searchField={{
			name: 'orderSearch',
			text: t('common.Search'),
			onChange: (e: any) => setSearchTerm(e.target.value),
			value: searchTerm,
		}}
        row={(product) => 
			<Row 
				product={product}
				onSelect={onSelect}
			/>
		}
        list={filteredProducts}
        extractKey={(product) => product.id}
      />
    </Modal>
  );
}

const Row: React.FC<{ 
	product: Product
	onSelect: (product?: Product | null, quantity?: number) => React.MouseEventHandler
}> = ({
	product,
	onSelect
}) => {
	const [ quantity, setQuantity ] = useState(1)
	const {t} = useTranslation()

	return (
		<>
		  <td>{product.fnArticleId}</td>
		  <td>{product.productInformation.name}</td>
		  <td>
			<NumberInput  
				value={quantity}
				setValue={setQuantity}
				options={{
					min: 1
				}}
			/>
		  </td>
		  <td className="text-center">
			<span
			  className="text-primary cursor-pointer"
			  onClick={onSelect(product, quantity)}
			>
			  {t('common.Associate')}
			</span>
		  </td>
		</>
	  )
}

