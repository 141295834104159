import {DateTime, LocaleOptions} from 'luxon';

type formatCurrencyOptions = {
  decimals?: number;
  display?: string;
};

const formatCurrencyDefault: formatCurrencyOptions = {decimals: 2};

export function formatCurrency(
  value: number,
  options: formatCurrencyOptions = formatCurrencyDefault
) {
  const {decimals, display} = {...formatCurrencyDefault, ...options};

  return new Intl.NumberFormat('sv-SE', {
    style: 'currency',
    currency: 'SEK',
    minimumFractionDigits: decimals,
    currencyDisplay: display,
  }).format(value);
}

export function formatDate(date: string) {
  return DateTime.fromISO(date)
    .setLocale('sv')
    .toLocaleString(DateTime.DATE_SHORT as LocaleOptions);
}
