import React from 'react'
import { useTranslation } from 'react-i18next'

export type SerialNumbersAddProperties = {
    serialNumbers: string[]
}


export function SerialNumbersAdd({
    serialNumbers
}: SerialNumbersAddProperties) {
    const { t } = useTranslation()

    return (
        <>
            {serialNumbers.length > 0 ? 
                <>
                <br />
                {t('common.Serialnumbers')}:
                <br />
                {serialNumbers.map((i, index) => {
                    return <span key={index}>{i}<br /></span>
                })}
                </>
            : ""}
        </>
    )

}