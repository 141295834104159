import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import Icon from '@mdi/react';
import {mdiEye, mdiEyeOff} from '@mdi/js';
import {FormContextValues} from 'react-hook-form';
import classnames from 'classnames';

import {extractErrorText, getErrorTestId, getErrorObj} from '../../lib/form';

import {InputDefaultProps} from './types';
import {Grid} from "@material-ui/core";

type InputFieldTypes = 'email' | 'password' | 'text' | 'number';

interface InputFieldProps<T> extends InputDefaultProps {
  type?: InputFieldTypes;
  placeholder?: string;
  showHidePassword?: boolean;
  inputAction?: {
    content: JSX.Element | string;
    onClick: React.MouseEventHandler;
  };
  inputRef?: React.RefCallback<HTMLInputElement>;
  handler?: FormContextValues<T>;
  onChange?: React.FormEventHandler;
  associated?: boolean;
  associateHandler?: React.MouseEventHandler;
  associateText?: string;
  associateValue?: string;
}

export default function InputField<T>({
  type,
  name,
  handler,
  onChange,
  rules,
  defaultValue,
  label,
  placeholder,
  showHidePassword,
  className,
  value,
  feedback,
  readOnly,
  disabled,
  inputAction,
  inputRef,
  associated,
  associateHandler,
  associateText,
  associateValue,
  gridSizeXs,
  gridSizeSm,
  gridSizeMd,
  gridSizeLg,
  gridSizeXl,
}: InputFieldProps<T>) {
  const {t} = useTranslation();

  const [currentType, setCurrentType] = useState(
    type === undefined ? 'text' : type
  );

  const id = `input__eon-ui`;

  const elementError = getErrorObj(handler?.errors, name);
  const errorText = extractErrorText(elementError, rules, t);

  placeholder =
    placeholder === null
      ? undefined
      : placeholder === undefined && typeof label === 'string'
      ? label
      : placeholder;

  if (handler !== undefined && onChange !== undefined) {
    throw new Error('two controller provided');
  }

  const props = {
    type: currentType,
    name,
    id,
    value,
    placeholder,
    defaultValue,
    readOnly: typeof readOnly === 'object' ? readOnly[name] : readOnly,
    disabled: typeof disabled === 'object' ? disabled[name] : disabled,
    autoComplete: showHidePassword ? 'off' : undefined,

    ref: inputRef
      ? inputRef
      : handler
      ? rules
        ? handler.register(rules)
        : handler.register
      : undefined,
    onChange,
    'data-testid': id,
  };
  
  return (
    <div className={classnames('form-group', className)}>
      {label === undefined ? null : <label htmlFor={id}>{label}</label>}
      <div
        className={classnames(!associated ? 'position-relative' : 'file', {
          'inner-addon': feedback,
          'right-addon': feedback,
        })}
      >
        {feedback && <span className="feedback">{feedback}</span>}

        {showHidePassword && (
          <div className="field-icon">
            <div
              data-testid={`password_toggle__com.eon.ui:eon-ui:war:1.0-SNAPSHOT`}
              className="cursor-pointer"
              onClick={() => {
                setCurrentType(
                  currentType === 'password' ? 'text' : 'password'
                )
              }}
            >
              <Icon
                path={currentType === 'password' ? mdiEye : mdiEyeOff}
                size="24"
                className="icon-24"
              />
            </div>
          </div>
        )}
        {inputAction &&
          <div className="field-icon" data-testid={`input_action__com.eon.ui:eon-ui:war:1.0-SNAPSHOT`}>
            <div className="cursor-pointer" onClick={inputAction.onClick}>
              {inputAction.content}
            </div>
          </div>
        }
        <Grid item xs={gridSizeXs} sm={gridSizeSm} md={gridSizeMd} lg={gridSizeLg} xl={gridSizeXl}>
          <input
            className={classnames('form-control', {
              border: elementError,
              'border-danger': elementError,
            })}
            {...props}
          />
        </Grid>
        {associated && (
          <>
            <span
              className={`file-custom text-right pr-3 ${
                disabled ? 'disabled' : ''
              }`}
              onClick={!disabled ? associateHandler : () => null}
            >
              {associateText}
            </span>
            <span className="associatedValue text-left pl-3">
              {associateValue}
            </span>
          </>
        )}
      </div>
      {elementError ? (
        <span
          className="form-control-error text-danger"
          data-testid={getErrorTestId(elementError)}
        >
          {errorText}
        </span>
      ) : null}
    </div>
  );
}
