import Dialog from '@material-ui/core/Dialog/Dialog'
import DialogActions from '@material-ui/core/DialogActions/DialogActions'
import DialogContent from '@material-ui/core/DialogContent/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle'
import Grid from '@material-ui/core/Grid/Grid'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { useHistory } from 'react-router-dom'
import DropdownButton from '../../../../components/DropdownButton'
import Pager from '../../../../components/Pager'
import Table from '../../../../components/Table'
import TabNav from '../../../../components/TabNav'
import usePaging from '../../../../hooks/usePaging'
import { useQuery } from '../../../../lib/hooks'
import priceFormatter from '../../../../lib/priceFormatter'
import { formatTimeFromDate } from '../../../../lib/timeHelper'
import { PATH_E_SHOP_CHECKOUT, PATH_OFFERS_OFFER_TOOL_EDIT } from '../../../../router/paths'
import { AppState } from '../../../../store'
import { deleteOffer, getOffers, getQuantitiesOfOfferStatuses, updateOfferStatus, copyOffer } from '../../../../store/offers'
import { CopyOffer, Offer, OfferStatus } from '../../../../store/offers/types'
import offerServices from '../../../../services/offers'
import { downloadPdfFromUrl } from '../../OfferTool/common'
import { UserType } from '../../../../store/users/types'
import { Alert } from '@material-ui/lab'
import { toggleToast } from '../../../../store/components'
import useUsers from '../../../../hooks/useUsers'
import UserPicker from '../../../../components/User/UserPicker'

const connector = connect(
	(state: AppState) => ({
		offers: state.offers.offers,
		offerStatuses: state.offers.offerStatuses,
		sessionUser: state.system.sessionUser,
		accessModules: state.system.accessModules,
	}),
	{ getOffers, getQuantitiesOfOfferStatuses, deleteOffer, updateOfferStatus, toggleToast, copyOffer }
)

interface OfferListingListProps extends ConnectedProps<typeof connector> {
	type: 'customer' | 'my' | 'our'
}

const getStatus = (status: OfferStatus) => 
	status === OfferStatus.NEW ? 'Ny'
	: status === OfferStatus.LOCKED ? 'Låst'
	: status === OfferStatus.APPROVED ? 'Godkänd'
	: status === OfferStatus.REJECTED ? 'Avvisad'
	: status === OfferStatus.CANCELED ? 'Annullerad'
	: status === OfferStatus.ORDERED ? 'Beställd'
	: status === OfferStatus.SENT ? 'Skickad'
	: undefined

const OfferListingList = ({
	sessionUser,
	accessModules,
	offers,
	getOffers,
	offerStatuses,
	getQuantitiesOfOfferStatuses,
	deleteOffer,
	updateOfferStatus,
	copyOffer,
	type,
	toggleToast,
}: OfferListingListProps) => {
	const { t } = useTranslation();
	const history = useHistory();
	const query = useQuery()
	const paramFilter = query.get('filter') as OfferStatus | null ?? undefined
	const {getUsers, users} = useUsers();

	useEffect(() =>{
		if(users !== undefined) return;
		getUsers({page: 1,filter: "", textSearch: "", onlyAdmins: 0})
	},[getUsers, users])

	const [ userId, setUserId ] = useState<string>()

	let isAdmin = sessionUser?.userPermission?.userType === UserType.SuperAdmin || sessionUser?.userPermission?.userType === UserType.Admin
	
	useEffect(()=> {
		if(paramFilter === undefined){
			query.set('filter', "NEW")
			history.push(`?${query}`)
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[])

	const handleGetOffers = useCallback(({ filters, textSearch }) => {
			if(offers.loading && !sessionUser?.userPermission?.userType && paramFilter === undefined) return

			if((sessionUser?.userPermission?.userType === UserType.MainAccount || sessionUser?.userPermission?.userType === UserType.SubAccount) 
				&& paramFilter === OfferStatus.NEW && type !== "customer")
			{
				getOffers({ ...filters, offerStatus: OfferStatus.SENT, search: textSearch, received: type === 'my', all: type === 'our' })
			}else{
				getOffers({ ...filters, offerStatus: paramFilter, search: textSearch, received: isAdmin ? false : type === 'my', all: type === 'our' })
			}
			localStorage.setItem("type", type)
	}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	, [getOffers, paramFilter, type])

	const {
		page, onPagination, searchTerm, setSearchTerm, triggerRefresh, hasFetched
	} = usePaging({ 
		getItems: handleGetOffers, 
		limit: 10
	})

	useEffect(() => { 
		getQuantitiesOfOfferStatuses({received: isAdmin ? false :type === 'my', all: type === 'our' }) 
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getQuantitiesOfOfferStatuses, type])

	useEffect(() => { triggerRefresh() }, [triggerRefresh])

	const navLinks: {
		isActive: boolean
		path: string
		component: JSX.Element
	}[] = Object.values(OfferStatus).filter(v => {
		// Needed to handle Enum conversation
		if(typeof v !== "string") return false
		if(!isAdmin && type ===  'my' && [OfferStatus.LOCKED,OfferStatus.SENT,OfferStatus.ORDERED].includes(v)) return false

		return true
	}).map(s => ({
		isActive: paramFilter === s,
		path: `?filter=${s}&search=${searchTerm.replace(":",'')}`,
		component: (
			<>
				{getStatus(s)}
				<span className="badge badge-info">{offerStatuses.data?.[s] ?? 0} st</span>
			</>
		),
	}))

	navLinks.unshift({
		isActive: !paramFilter,
		path: `?filter=&search=${searchTerm.replace(":",'')}`,
		component: (
			<>
				{t('common.All')}
				<span className="badge badge-info">
					{offerStatuses.data?.ALL ?? 0} st
				</span>
			</>
		),
	})

	const [ modal, setModal ] = useState<{
		id: number,
		type: 'change',
		value: OfferStatus,
		confirm?: boolean,
		error?: boolean,
	} | {
		id: number,
		type: 'delete',
		confirm?: boolean,
		error?: boolean,
	}| {
		id: number,
		type: 'copy',
		confirm?: boolean,
		error?: boolean,
	}>()

	const [ feedback, setFeedback ] = useState<{errorMsg: string, error: boolean}>()

	useEffect(() => {
		if(!modal?.confirm) return

		modal.type === 'copy' && copyOffer({OfferId: modal.id, userId: userId} as CopyOffer).then((e) => {
			if(modal.error) return
			let newOffer = e.payload as Offer;

			history.push(PATH_OFFERS_OFFER_TOOL_EDIT.replace(':offerId',newOffer.id+''))
			setModal(undefined)
		})

		modal.type === 'delete' && deleteOffer(modal.id+'').then(() => {
			triggerRefresh()
			setModal(undefined)
		})

		modal.type === 'change' && updateOfferStatus({ 
				offerId: modal.id+'', status: modal.value,
				callback: v => {
					setFeedback({errorMsg: v.message, error: v.status === 400 ? true : false})
					modal.error =  v.status === 400 ? true : false;
				}
		}).then(() => {
			if(modal.error) return

			triggerRefresh()
			getQuantitiesOfOfferStatuses({ received: isAdmin ? false : type === 'my', all: type === 'our' }) 
			setModal(undefined)
		})
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [deleteOffer, getQuantitiesOfOfferStatuses, modal, triggerRefresh, updateOfferStatus, copyOffer])

	const changeStatusOptions = useMemo(() => (
		type === 'my' && sessionUser?.userPermission.userType === UserType.MainAccount ? [OfferStatus.APPROVED,OfferStatus.REJECTED]
		: type === 'customer' && sessionUser?.userPermission.userType === UserType.MainAccount ? Object.values(OfferStatus)
			: Object.values(OfferStatus)
	).map(v => ({ label: getStatus(v), value: v })), [sessionUser?.userPermission.userType, type])

	const allowedActions = useMemo(() => 
	resolveAllowedActions(type, (!isAdmin)), 
	// eslint-disable-next-line react-hooks/exhaustive-deps
	[type])

	const [sendMail, setSendMail] = useState<{send: boolean, rowId: number}>()

	useEffect(()=> {
		if(!sendMail?.send && !sendMail?.rowId) return

		if(sessionUser?.userPermission.userType === UserType.MainAccount || sessionUser?.userPermission.userType === UserType.SubAccount){
			offerServices.updateOfferStatus({
				offerId: sendMail.rowId.toString(),
				status: OfferStatus.SENT
			}).then((c) => {
				triggerRefresh();
				getQuantitiesOfOfferStatuses({ received: isAdmin ? false : type === 'my', all: type === 'our' });
						toggleToast({
							shouldToggle: true,
							message: t('common.Status change to sent'),
							status: c.status,
						})
			})
		}else{
			offerServices.sendOffer(sendMail.rowId+'').then(c => {
				toggleToast({
					shouldToggle: true,
					message: t('common.Mail sent to:') + " " + c.data?.email,
					status: c.status,
				})
				triggerRefresh()
				getQuantitiesOfOfferStatuses({ received: isAdmin ? false : type === 'my', all: type === 'our' }) 
				setSendMail({send: false, rowId: 0})
			})
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[sendMail])

	return (
		<>
			<h1 data-testid="manage_companies_headline">
				{t('common.Offers')}
			</h1>
			<TabNav links={navLinks} />
			<Table
				tableStyle={{ paddingBottom: 200 }}
				loading={offers.loading || !hasFetched}
				searchRowInformationText={
					<>
						{type === "my" && !isAdmin ? (
							<p style={{fontStyle: "italic", fontSize: "1rem"}} className="align-middle">
								{t('offer.Collection of all offers that you got from Solar Supply')}
							</p>
						) : type === "customer" && !isAdmin ? (
							<p style={{fontStyle: "italic", fontSize: "1rem"}} className="align-middle">
								{t('offer.Collection of all offers that you created to end customer')}
							</p>
						) : (
							<></>
						) }
					</>
				}
				searchField={{
				name: 'orderSearch',
				text: t('common.Search'),
				onChange: (e: any) => setSearchTerm(e.target.value),
				value: searchTerm,
				}}
				header={[
				t('common.Date'),
				t('common.Offer number'),
				t('common.Customer numb'),
				t('common.Customer name'),
				t('common.Created by'),
				t('common.Total'),
				t('common.Current status'),
				{
					key: 'pager',
					component: (
						<Pager
							page={page}
							className="text-center"
							totalItems={offers.total}
							onPagination={onPagination}
						/>
					),
				},
				]}
					row={row =>
							<>
								<td style={ row.needSupervision ? {backgroundColor: 'rgb(255, 244, 229)' }: {} }>{formatTimeFromDate(new Date(row.offerCreated))}</td>
								<td style={ row.needSupervision ? {backgroundColor: 'rgb(255, 244, 229)' }: {} }>{row.id}</td>
								<td style={ row.needSupervision ? {backgroundColor: 'rgb(255, 244, 229)' }: {} }>{row.customer.CustomerNumber}</td>
								<td style={ row.needSupervision ? {backgroundColor: 'rgb(255, 244, 229)' }: {} }>{row.deliveryCustomerName}</td>
								<td style={ row.needSupervision ? {backgroundColor: 'rgb(255, 244, 229)' }: {} }>{row.resolvedUser}</td>
								<td style={ row.needSupervision ? {backgroundColor: 'rgb(255, 244, 229)' }: {} }>{priceFormatter( row.offerCurrency ?? 'SEK')(row.totalCostInkTaxOnOffer)}</td>
								<td style={ row.needSupervision ? {backgroundColor: 'rgb(255, 244, 229)' }: {} }>{getStatus(row.status)}</td>
								<td className="text-center"  style={ row.needSupervision ? {backgroundColor: 'rgb(255, 244, 229)' }: {} }>
									<DropdownButton
										id={`offer__option__dropdown_${row.id}`}
										label={t('common.Options')}
										dropdownList={accessModules.OFFER_GENERATION ? [
												{
													text: t('common.Edit'),
													onClick: () => history.push(PATH_OFFERS_OFFER_TOOL_EDIT.replace(':offerId',row.id+'')),
													key: 'edit',
												},
												...row.deliveryEmail?[{
													text: t('common.Send'),
													onClick: () => (row.status !== OfferStatus.NEW && setSendMail({send: true, rowId: row.id})),
													key: 'send',
													className: row.status === OfferStatus.NEW ? "disabled" : "",
												}]:[],
												{
													text: t('common.Download'),
													onClick: () => downloadPdfFromUrl(row.offerPdfFile.publicLink, row.offerPdfFile.originalName),
													key: 'download',
												},
												{
													text: t('common.Change status'),
													onClick: () => setModal({ id: row.id, type: 'change', value:
														changeStatusOptions.find(c=> c.value === row.status) ?
														row.status:  changeStatusOptions.find(c=> c.value)?.value as OfferStatus}),
													key: 'status',
												},
												{
													text: t('common.Add to cart'),
													onClick: () => offerServices.addToCart(row.id+'').then(() => history.push(PATH_E_SHOP_CHECKOUT)),
													key: 'cart',
													className: (sessionUser?.userPermission?.userType === UserType.MainAccount && type === 'my') ? "disabled" : "",
												},
												{
													text: t('common.Copy'),
													onClick: () => setModal({ id: row.id, type: 'copy' }),
													key: 'copy',
												},
												{
													text: t('common.Delete'), className: 'text-danger',
													onClick: () => setModal({ id: row.id, type: 'delete' }),
													key: 'delete',
												},
											].filter(v => allowedActions.includes(v.key))
											: []
										}
									/>
								</td>
							</>
						}
				list={offers.data}
				extractKey={(el) => el.id+''}
			/>
			<Dialog
				scroll={'body'}
				open={!!modal}
				onClose={() => { setFeedback(undefined); setModal(undefined)}}
			>
				<DialogTitle>
					{	modal?.type === 'change' ? (
						t('common.Change offer status')
					) : modal?.type === 'delete' ? (
						t('common.Delete offer')
					) : modal?.type === 'copy' ? (
						t('common.Copy')
					) : (<></>)
					}
				</DialogTitle>
				<DialogContent style={{overflow: "hidden"}}>
					{ modal?.type === 'change' ? (
						<Grid container>
							<select
								className="form-control form-control-lg"
								onChange={e => setModal(state => state && { ...state, value: e.target.value as OfferStatus})}
								value={modal.value}
								style={{ marginBottom: '.5rem' }}
								disabled={modal.confirm}
							>
								{changeStatusOptions.map(opt => (
									<option key={opt.value} value={opt.value}>{opt.label}</option>
								))}
							</select>
						</Grid>
						) : modal?.type === 'delete' ? (
							(t('offer.Are you sure you want to delete the selected offer?'))
						) : modal?.type === 'copy' ? (
							isAdmin ? (
								<div style={{minWidth: "330px", minHeight:"150px", overflow: "hidden", padding: "10px"}}>
									<UserPicker Users={users?? []} setSelectedUserId={setUserId}></UserPicker>
								</div>
							): (
								(t('offer.Do you whant to copy this offer?'))
							)
						) : (<></>)
					}
				</DialogContent>

				{(modal?.error || feedback?.error) &&
					<Alert severity="error" variant="outlined" style={{marginLeft: "10px", marginRight: "10px"}}>
							<span>
							{feedback?.errorMsg}
							</span>
					</Alert>
				}

				<DialogActions>
					<button
						className="btn btn-light"
						onClick={() => {setModal(undefined); setFeedback(undefined)}}
					>
						{t('common.Close')}
					</button>
					{ modal &&
						<button
							className="btn btn-primary btn-block"
							onClick={() => setModal(state => state && { ...state, confirm: true })}
							style={{ minWidth: 150 }}
							disabled={(modal.confirm && feedback?.error) || (modal.type === 'copy' && isAdmin && !userId) }
						>
							{modal.confirm && !feedback?.error && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ marginRight: '.5em' }}></span>}
							{modal.type === 'change' ? (t('common.Change order status')) :
							 modal.type === 'delete' ? (t('common.Delete')) :
							 modal.type === 'copy' ? (t('common.Copy')) :
							 (<></>)}
						</button>
					}
				</DialogActions>
			</Dialog>
		</>
	);
}

export default connector(OfferListingList)

// "backoffice-ish"
const actionsComplete = ['edit', 'send', 'delete', 'download', 'status', 'cart', 'copy']
// "HK-ish"
const actionsRestricted = ['download', 'status']
const resolveAllowedActions = (type: OfferListingListProps['type'], isNotAdmin: boolean ) => {
	if(type === 'my' && isNotAdmin) return actionsRestricted
	else return actionsComplete
}
