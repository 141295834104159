import React from 'react';
import {useTranslation} from 'react-i18next';

import {
    PATH_GENERAL_MANAGE_COMPANY,
    PATH_GENERAL_MANAGE_COMPANY_DOCUMENTS,
} from '../../router/paths';

import TabNav from '../TabNav';

export default function MainAccountManageCompanyTabNav({companyId}: {companyId: string}) {
  const {t} = useTranslation();

    return (
    <>
      <TabNav
        className="mb-5 mt-5"
        linkClassName="px-2"
        links={[
          {
            path: PATH_GENERAL_MANAGE_COMPANY,
            component: <>{t('common.Information')}</>,
          },
          {
            path: PATH_GENERAL_MANAGE_COMPANY_DOCUMENTS,
            component: <>{t('common.Document')}</>,
            params: {companyId},
          }
        ]}
      />
      <div className="mb-2" />
    </>
  );
}
