import React from 'react';
import {useTranslation} from 'react-i18next';
import Icon from '@mdi/react';
import {mdiFolder, mdiImage, mdiPdfBox, mdiFile} from '@mdi/js';
import classnames from 'classnames';
import {Link, useHistory} from 'react-router-dom';

import {MergedDocument} from '../../store/documents/selectors';

import DropdownButton from './../DropdownButton';
import {InlineCheckbox} from '../Form/InlineCheckInput';

function getFileIcon(field: MergedDocument): string {
  if (field.type === 'file') {
    const match = field.data.name.match(/(\..+)$/);

    if (!match) {
      return mdiFile;
    }

    switch (match[1]) {
      case '.pdf':
        return mdiPdfBox;
      case '.jpeg':
      case '.jpg':
      case '.png':
      case '.gif':
        return mdiImage;
      default:
        return mdiFile;
    }
  }

  return mdiFolder;
}

type DocumentRowProps = {
  enableAminOptions: boolean;
  document: MergedDocument;
  isSelected: boolean;
  selectRow: (id: string, isSelected: boolean, multi?: boolean) => () => void;
  activateRenameOnCurrent: (item: MergedDocument) => () => void;
  activateDeleteOnCurrent: (item: MergedDocument) => () => void;
  generateDownloadLink: (type: string, id:string) => string;
};

export default function DocumentRow({
  enableAminOptions,
  document,
  isSelected,
  selectRow,
  activateRenameOnCurrent,
  activateDeleteOnCurrent,
  generateDownloadLink
}: DocumentRowProps) {
  const {t} = useTranslation();
  const history = useHistory()

  const stopPropagation = (e: React.MouseEvent) => e.stopPropagation();

  const {id, data, type} = document;
  const onOpen = ()=>{
      history.push('?folderId='+id)
  }

  const onDownload = ()=>{
    const downloadLink = generateDownloadLink(type,id)
    window.open(downloadLink, '_blank')?.focus();
  }


  let dropdownOptions = enableAminOptions
    ? [
        {
          text: t('common.Open'),
          onClick:onOpen
        },
        {
          text: t('common.Rename'),
          onClick: activateRenameOnCurrent(document),
        },
        {
          text: t('common.Download'),
          onClick:onDownload
        },
        {
          text: t('common.Delete'),
          onClick: activateDeleteOnCurrent(document),
          className: 'text-danger',
        },
      ]
    : [
        {
          text: t('common.Open'),
          onClick:onOpen
        },
        {
          text: t('common.Download'),
          onClick:onDownload
        },
      ];

  if(type === 'file'){
    dropdownOptions= dropdownOptions.splice(1, dropdownOptions.length);
  }

  return (
    <div
      data-testid={`documents__row__com.eon.ui:eon-ui:war:1.0-SNAPSHOT`}
      className={classnames('documents__row', {
        'documents__row--selected': isSelected,
      })}
      onClick={selectRow(id, isSelected)}
    >
      <div className="mr-7" onClick={stopPropagation}>
        <InlineCheckbox
          name={`document_com.eon.ui:eon-ui:war:1.0-SNAPSHOT`}
          value={isSelected ? id : false}
          onChange={selectRow(id, isSelected, true)}
          className="mx-0 my-0"
          options={[{label: '', value: id}]}
        />
      </div>
      <div className="flex-grow-1">
        <div className="d-flex align-items-center">
          <Icon
              path={getFileIcon(document)}
              size="24"
              className="icon-24 mr-3"
          />
          {type === 'folder' ?
              <Link
                  to={`?folderId=com.eon.ui:eon-ui:war:1.0-SNAPSHOT`}
                  className="documents__link"
                  onClick={(e) => e.stopPropagation()}
              >
                {'originalName' in data ? data.originalName : data.name}
              </Link> :
              <span className="font-weight-bold"> {'originalName' in data ? data.originalName : data.name}</span>}
        </div>
      </div>
      <div>
        <DropdownButton
          id={`documents__options__dropdown_com.eon.ui:eon-ui:war:1.0-SNAPSHOT`}
          label={t('common.Options')}
          dropdownList={dropdownOptions}
        />
      </div>
    </div>
  );
}
