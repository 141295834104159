import React, { useEffect, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { AppState } from '../../../store'
import { getSpecificServiceCase, createCaseComment } from '../../../store/cases'
import { toggleToast } from '../../../store/components'
import CaseManagementTabNav from './../CaseManagementTabNav'

import './CreateCaseComment.scss'
import { PATH_CASES_BACK_OFFICE_LIST } from '../../../router/paths'
import MultipleFileInput from '../../../components/Form/MultipleFileInput'
import { useForm } from 'react-hook-form'
import { ServiceCaseCustomerComment } from '../../../store/cases/types'
import TextAreaField from '../../../components/Form/TextAreaField'
import { ErrorResponseResolver } from '../../../lib/responseHelper'

import { formatTimeMinuteHourFromDate } from '../../../lib/timeHelper'
import { JocularUser, User } from '../../../store/users/types'
import { getActiveUser } from '../../../store/system';
import { UploadedFilesDictionary } from '../../../types'

const connector = connect(
    (state: AppState) => ({
        serviceCase: state.cases.selected
    }),
    { getSpecificServiceCase, createCaseComment, toggleToast, getActiveUser }
);

type CreateCaseCommentProps = ConnectedProps<typeof connector> & {};

export function CreateCaseComment({
    serviceCase,
    getSpecificServiceCase,
    createCaseComment,
    toggleToast,
    getActiveUser
}: CreateCaseCommentProps) {

    const history = useHistory()
    const { t } = useTranslation()
    const { serviceCaseId } = useParams() as {
        serviceCaseId: string;
    }

    const handler = useForm<ServiceCaseCustomerComment>({ mode: 'onChange' });

    const { data: serviceCaseData, loading: serviceCaseDataLoading } = serviceCase

    const [jocularUser, setJocaularUser] = useState<JocularUser>();
    const [update, setUpdate] = useState<number>(0)


    useEffect(() => {
        getActiveUser()
            .then(u => setJocaularUser(u.payload as JocularUser))
    },[serviceCaseId])

    useEffect(() => {
        getSpecificServiceCase({ serviceCaseId: serviceCaseId })
    }, [getSpecificServiceCase])

    
    type Attachment = {
        id: string;
        fileList: FileList;
    };

    const [attached, setAttached] = useState<Attachment[] | Attachment[]>([]);

    const setAttachments = function (fileList: FileList, id: string) {
        let ob = {
            fileList: fileList,
            id: id,
        } as Attachment;
        attached.push(ob);
        setAttached(attached);
    };

    const deleteAttachments = function (id: string) {
        setAttached(attached.filter((v) => v.id !== id));
    };


    const handleSubmit = (async (serviceCaseCustomerComment: ServiceCaseCustomerComment) => {
        await createCaseComment({
            fileList: attached.map((f) => f.fileList),
            serviceCaseCustomerComment: serviceCaseCustomerComment,
            serviceCaseId: serviceCaseId,
            callback: (errorResponseResolver: ErrorResponseResolver) => {
                toggleToast({
                    shouldToggle: true,
                    message: errorResponseResolver.message,
                    status: errorResponseResolver.status
                })
            }
        })
        setAttached([])
        setUpdate(update + 1)
        getSpecificServiceCase({ serviceCaseId: serviceCaseId })
        handler.reset()
    })

    const prepareSubmit = async (input: any) => {
        try {
            handleSubmit(input)
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <>
            <h1 data-testid="manage_companies_headline">Ärendehantering</h1>
			<CaseManagementTabNav 
				serviceCaseId={serviceCaseId}
				sessionUser={jocularUser}
				isEdit={history.location.pathname.includes('/edit-case')}
			/>
            <h4>Kommentarer</h4>
            <div className="chat-content border-1">
                {serviceCaseData !== null && serviceCaseData !== undefined ? <>
                    {serviceCaseData.serviceCaseCustomerComments !== null && 
                    serviceCaseData.serviceCaseCustomerComments !== undefined ? 
                    <>{serviceCaseData.serviceCaseCustomerComments.map((c, index) => {
                        return <div className="card mb-2" key={index}>
                        <div className="card-body">
                          <p><b>{formatTimeMinuteHourFromDate(c.createdAt)} - skrev <u>{c.resolvedFirstName} {c.resolvedLastName}</u></b></p>
                          <p>{c.comment}</p>
                        </div>
                      </div>
                    })} </>
                    : <> <h4 className="text-muted text-center">Inga kommentarer har skrivit än</h4></>}
                </> :
                    <>
                        <h4 className="text-muted text-center">Inga kommentarer har skrivit än</h4>
                    </>}
            </div>
            <hr />
            <form onSubmit={handler.handleSubmit(prepareSubmit)}>
                <div className="row mt-5">
                    <div className="col-4">
                        <div className="input-group">
                            <TextAreaField
                                name="comment"
                                handler={handler}
                                label={"Beskrivning"}
                                className="col-12"
                                placeholder="Vad vill du ha hjälp med?"
                                rows={12}
                            />
                        </div>
                    </div>
                    <div className="col-4">
                        <label htmlFor="">{t('common.Documents and files')}</label>
                        <MultipleFileInput
                            key={update}
                            label={''}
                            files={{}}
                            setAttachments={setAttachments}
                            deleteAttachments={deleteAttachments}
                            placeholder={t('common.Upload new document')}
                        />
                        <button className="btn btn-primary ml-1" type="submit">Skicka kommentar</button>
                    </div>
                    <div className="col-4"></div>
                </div>
            </form>
            <div className="col">
                <button type="button" className="btn btn-info mt-1" onClick={() => history.push(PATH_CASES_BACK_OFFICE_LIST)} >
                    {t('common.Cancel')}
                </button>
            </div>

        </>
    );
}

export default connector(CreateCaseComment);
