import React, {useCallback, useEffect, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';

import ProductService from '../../services/products';

import {getProductCategories} from '../../store/productCategories';
import {
  getProduct,
  editProduct,
  deleteProductDocument,
} from '../../store/products';
import {getUnmappedArticles} from '../../store/articles';
import {AppState} from '../../store';
import {setShowLoader} from '../../store/system';

import ProductForm, {
  ProductFormInput,
} from '../../components/EShop/ProductForm';
import ProductSelectModal from '../../components/Products/ProductSelectModal';
import { InternalProduct, ProductCustomer, ProductReserved} from '../../store/products/types';
import {Article} from '../../store/articles/types';
import ProductFormTabNav from '../../components/EShop/ProductFormTabNav';
import {ErrorResponseResolver} from '../../lib/responseHelper';
import {toggleToast} from '../../store/components';

const connector = connect(
  (state: AppState) => ({
    productCategories: state.productCategories.list,
    articles: state.products.articles,
    product: state.products.selected,
  }),
  {
    getProductCategories,
    getUnmappedArticles,
    getProduct,
    editProduct,
    deleteProductDocument,
    toggleToast,
    setShowLoader,
  }
);

type EditProductProps = ConnectedProps<typeof connector> & {};

export function EditProduct({
  productCategories,
  articles,
  product,
  getProductCategories,
  getUnmappedArticles,
  getProduct,
  editProduct,
  deleteProductDocument,
  toggleToast,
  setShowLoader,
}: EditProductProps) {
  const {t} = useTranslation();
  const {productId} = useParams<{productId: string}>();

  const {data: unmappedArticlesData} = articles;
  const {data: productData, loading: productLoading} = product;
  const [productsModalVisible, setProductsModalVisible] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState<Article>();
  const onProductSelect = useCallback(
    (article: Article) => () => {
      setSelectedArticle(article);
      setProductsModalVisible(false);
    },
    []
  );

  const removeDocument = (fileId: string) => {
    const local = attached.find(a => a.id === fileId)
    if(!local) {
      deleteProductDocument({articleId: productId, fileId: fileId}).then(() => getProduct(productId));
    } else {
      setAttached(attached.filter(a => a.id !== fileId))
    }
  };

  type Attachment = {
    id: string;
    fileList: FileList;
  };
  const [attached, setAttached] = useState<Attachment[]>([]);
  const {data: categories, loading: categoriesLoading} = productCategories;

  const isLoading = categoriesLoading || productLoading;

  useEffect(() => {
    getProductCategories();
  }, [getProductCategories]);

  useEffect(() => {
    getProduct(productId);
  }, [getProduct, productId]);

  useEffect(() => {
    getUnmappedArticles();
  }, [getUnmappedArticles]);

  useEffect(() => {
    setShowLoader(isLoading);
  }, [isLoading, setShowLoader]);

  const handleSubmit = async (
    productFormInput: ProductFormInput,
    data?: Blob,
    productCustomers?: ProductCustomer[],
    productReserved?: ProductReserved[]
  ) => {
    const selectedArticleId =
      productData?.product.fnArticleId && !selectedArticle?.ArticleNumber
        ? productData?.product.fnArticleId
        : selectedArticle?.ArticleNumber;
    if (selectedArticleId) {
      let ob = {
        description: productFormInput.description,
        manufacturer: productFormInput.manufacturer,
        documents: attached.map((d) => d.fileList),
        image: data,
        articleName: productFormInput.name,
        noneStockProduct: productFormInput.noneStockProduct,
        inDeliveryOfMoreQuantity: productFormInput.inDeliveryOfMoreQuantity ? new Date(
          productFormInput.inDeliveryOfMoreQuantity
        ).toISOString() : productFormInput.inDeliveryOfMoreQuantity,
        soldOut: productFormInput.soldOut,
        maxOrderQuantity: productFormInput.maxOrderQuantity,
        productCustomers: productCustomers,
        productReserved: productReserved,
        associatedWithEmobilityPartner: productFormInput.associatedWithEmobilityPartner === '1'
      } as InternalProduct;
      
      if (productFormInput.category) {
        ob = {
          ...ob,
          categoryId: productFormInput.category.value,
        };
      }

      if (productFormInput.categorySecond) {
        ob = {
          ...ob,
          categorySecondId: productFormInput.categorySecond.value,
        };
      }else if(product.data?.product.productInformation.categorySecondId){
        ob = {
          ...ob,
          categorySecondId: '',
        };
      }
      
      if (productFormInput.categoryThird) {
        ob = {
          ...ob,
          categoryThirdId: productFormInput.categoryThird.value,
        };
      }else if(product.data?.product.productInformation.categoryThirdId){
        ob = {
          ...ob,
          categoryThirdId: '',
        };
      }

      await editProduct({
        internalArticleId: productId,
        internalProduct: ob,
        callbackOnSuccess: () => {
          // history.push(PATH_E_SHOP_MANAGE_PRODUCTS);
        },
        callbackOnFail: (errorResponseResolver: ErrorResponseResolver) => {
          toggleToast({
            shouldToggle: true,
            message: errorResponseResolver.message,
            status: errorResponseResolver.status,
          });
        },
      });
	
      await getProduct(productId);
	  setAttached([])
    }
  };

  const setAttachments = function (fileList: FileList, id: string) {
    const ids = attached.map(a => a.id)
    if(ids.includes(id)) return
      let ob = {
        fileList: fileList,
        id: id,
      } as Attachment;
      setAttached(a => [...a, ob]);
  };

  const deleteAttachments = function (id: string) {
    setAttached(attached.filter((v) => v.id !== id));
  };

  const articleIdFromData = productData?.product?.fnArticleId && !selectedArticle?.ArticleNumber
                              ? productData?.product?.fnArticleId
                              : selectedArticle?.ArticleNumber;

  const secondHeader = articleIdFromData !== "" && articleIdFromData != null && productData?.product?.productInformation?.name !== "" && productData?.product?.productInformation?.name != null;

  return (
    <div>
      <h1 className={secondHeader ? "mb-0 pb-0 border-bottom-0" : ""} data-testid="edit_product_headline">
        {articleIdFromData !== undefined && articleIdFromData  !== "" && articleIdFromData !== null
         ?  t('common.Edit') +  " " + articleIdFromData 
         :  t('common.Edit product')
        }
      </h1>
      {secondHeader
          ? <h3 className="border-bottom" > {productData?.product?.productInformation?.name} </h3>
          : ""}
      <ProductFormTabNav
        productId={productId}
        materialCalculatorEnabled={
          product.data?.product?.extendedProductInformation?.inMaterialCalculator
        }
      />
      <ProductForm        
	  	  fortNoxArticleId={
          productData?.product?.fnArticleId && !selectedArticle?.ArticleNumber
            ? productData?.product?.fnArticleId
            : selectedArticle?.ArticleNumber
        }
        setModalVisible={setProductsModalVisible}
        onSubmit={handleSubmit}
        setAttachments={setAttachments}
        deleteAttachments={deleteAttachments}
        categoriesData={categories}
        setShowLoader={setShowLoader}
        data={productData}
        removeDocument={removeDocument}
        isEdit={true}
		    attached={attached}
      />

      {unmappedArticlesData && (
        <ProductSelectModal
          modalVisible={productsModalVisible}
          onSelect={onProductSelect}
          setModalVisible={setProductsModalVisible}
        />
      )}
    </div>
  );
}

export default connector(EditProduct);
