import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import commentsService from '../../services/comments';
import {Comment, CommentState} from './types';
import {ListData} from '../types';
import {listFulfilled, listPending, listRejected, entityPending, entityFulfilled, entityRejected} from '../utils';

const name = 'comments';

export const getComments = createAsyncThunk<ListData<Comment>, string>(
    `eon-ui/getComments`,
    async function (customerId) {
        const {data, headers} = await commentsService.getComments(customerId);
        return {
            data: data as Comment[],
            total: +headers['x-total-count'],
        };
    }
);

export const resetComments = createAsyncThunk<Comment | null>( 
  `eon-ui/resetComments`, 
  async function () {
      return null;
    }
);

export const initialState: CommentState = {
  list: {
	  data: [], 
	  total: 0, 
	  loading: false, 
	  requestId: ''
	},
	selected: {
		data: null,
		loading: false,
		requestId: '',
	},
};

const priceListSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getComments.pending, (store, action) => {
      listPending(store.list, action);
    });
    builder.addCase(getComments.fulfilled, (store, action) => {
      listFulfilled(store.list, action);
    });
    builder.addCase(getComments.rejected, (store, action) => {
      listRejected(store.list, action);
    });

	builder.addCase(resetComments.pending, (state, action) => {
		entityPending(state.selected, action);
	  });
	builder.addCase(resetComments.fulfilled, (state, action) => {
		entityFulfilled(state.selected, action);
	});
	builder.addCase(resetComments.rejected, (state, action) => {
		entityRejected(state.selected, action);
	});
  },
});

export default priceListSlice.reducer;