
import React from 'react';


export default function Download() {

    return (
        <div>Downloading...</div>
    )

}