import { CustomerDetails, Customer} from '../companies/types';
import { Session } from '../system/types';
import {List, Entity} from '../types';

export enum UserType {
    SuperAdmin = 'SUPER_ADMIN',
    Admin = 'ADMIN',
    MainAccount = 'SUPERIOR_ACCOUNT',
    SubAccount = 'SUB_ACCOUNT',
}

export enum Module {
    ACCOUNT_MANAGEMENT = 'ACCOUNT_MANAGEMENT',
    E_SHOP_FRONT = 'E_SHOP_FRONT',
    E_SHOP_BACK = 'E_SHOP_BACK',
    MATERIAL_CALCULATOR = 'MATERIAL_CALCULATOR',
    OFFER_GENERATION = 'OFFER_GENERATION',
    ORDER_MANAGEMENT = 'ORDER_MANAGEMENT',
    CASE_MANAGEMENT = 'CASE_MANAGEMENT',
    ARCHIEVE = 'ARCHIEVE',
    DOCUMENTATION = 'DOCUMENTATION'
}

export type AccessModules = {
    [k in Module]?: true;
};

type UserAssociation = {
    companyId: string | null;
    customerNumber: string | null;
    organizationNumber: string | null;
};

export type UserClause = {
    id: string
    offerLimitation: number | null;
    offerMinCoverage: number | null
};

type UserCredential = {
    email: string;
    username: string;
};

type UserInformation = {
    companyId: string,
    name: string,
    email: string,
    phoneNumber: string,
    position: string,
    companyName: string,
    organizationNumber: string,
    userAccountType: string,
    address: string,
    lastName: string,
};

type UserAccessModules = Array<{ module: Module }>;

type UserPermission = {
    userType: UserType;
    belongsToCustomer: string | null;
    accessModules: UserAccessModules;
    resolvedCustomerName: string;
    resolvedCustomerFortnoxId: string;
};

export type CustomerUserInput = {
    belongsToCompany: string;
    email: string;
    name: string;
    phoneNumber: string;
    position: string;
    userType: UserType;
};

export type AccountInformation = {
    acceptedGDPR:string,
    notification:boolean,
    address: string,
    position: string,
    firstName: string,
    lastName: string,
    phoneNumber: string,
};


export type CustomerUserEditInput = {
    email: string;
    name: string;
    phoneNumber: string;
    position: string;
    belongsToCompany: string;
    userType:UserType;
};

export type SuperAdminUserInput = {
    belongsToCompany: string;
    email: string;
    name: string;
    phoneNumber: string;
    position: string;
    userType: UserType;
};
export type AdminUserInput = SuperAdminUserInput & {
    belongsToCompany: string;
    userType:UserType;
    accessModules: UserAccessModules;
};


export type User = {
    id: string;
    acceptedGDPR:string,
    receiveNotification: boolean;
    userCredential: UserCredential;
    userPermission: UserPermission;
    userClause: UserClause;
    userInformation?: UserInformation;
    userAssociation?: UserAssociation;
    user?: User | null
    addressFn?: string;
    cityFn?: string;
    countryFn?: string;
    deliveryAddress?: string;
    deliveryCity?: string;
    deliveryCountry?: string;
    deliveryPhone?: string;
    zipCode: string;
};


export type Session2 = {
    user: User;
    customer: Customer;
    fnCustomer: CustomerDetails
    externalSession: Session;
    permissionViewDescription: PermissionViewDescription
    permissionViewAccessModules: UserAccessModules
}

export type PermissionViewDescription = {
    tabs: Tab[]
}

export type Tab = {
    tabName: string;
    tabNameModule: string;
    sections: Section[]
}

export type Section = {
    sectionViewName: string;
    sectionViewModule: string
}

export type UserMocked = {
    companyId: string,
    name: string,
    email: string,
    phoneNumber: string,
    position: string,
    companyName: string,
    organizationNumber: string,
    userAccountType: string,
};

export type UserInfoExtended = {
    user: User | null
    addressFn: string;
    cityFn: string;
    countryFn: string;
    deliveryAddress: string;
    deliveryCity: string;
    deliveryCountry: string;
    deliveryPhone: string;
    zipCode: string;
}


export type JocularUser = {
    accessModules: AccessModules
    sessionUser: User
    sessionUserType: UserType
}

export type UsersState = {
    list: List<User>;
    selected: Entity<User>;
};
