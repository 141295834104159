import React, {useState, useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';
import {useParams} from 'react-router-dom';

import {AppState} from '../../store';
import {setShowLoader} from '../../store/system';
import {getCustomer} from '../../store/companies';
import {getUsers} from '../../store/users';
import {Customer} from '../../store/companies/types';
import {UserType} from '../../store/users/types';

import {
  isBackOfficeAccount,
  isCustomerAccount,
  isAdminAccount,
} from '../../lib/permissions';

import {
  AdminAccountForm,
  AdminAccountPrivileges,
  CustomerAccountForm,
  AssociatedCompanyForm,
  AdminAccountFieldsInput,
  AdminModulesFieldsInput,
  CustomerAccountFieldsInput,
} from '../../components/User/UserForm';
import {UserCompanyReferencesForm} from "../../components/User/UserCompanyReferencesForm";

const connector = connect(
  (state: AppState) => ({
    sessionUser: state.system.sessionUser,
    sessionUserType: state.system.sessionUserType,
    users: state.users.list,
    company: state.companies.selected,
    companies: state.companies.list,
  }),
  {setShowLoader, getCustomer, getUsers}
);

type ViewUserProps = ConnectedProps<typeof connector> & {};

export function ViewUser({
  sessionUser,
  sessionUserType,
  users,
  company,
  setShowLoader,
  getCustomer,
  getUsers,
}: ViewUserProps) {
  const {t} = useTranslation();
  const {userId} = useParams() as { 
    userId: string;
  }

  const {data: usersData, loading: userLoading, error: userError} = users;

  const [selectedCompany, setSelectedCompany] = useState<Customer | null>(null);

  const handleInput = useForm<any>({mode: 'onChange'});

  const {reset} = handleInput;

  const user = usersData?.find(u => u.id === userId)
  const editedUserUserType = user?.userPermission.userType

  const showLoader =
  user === null ||
    userLoading ||
    (editedUserUserType !== undefined &&
      isCustomerAccount(editedUserUserType) &&
      !!user?.userPermission.belongsToCustomer &&
      company.data == null && !company.error)

  useEffect(() => {
    if (!userLoading && !userError && user) {

      if (
          editedUserUserType &&
          user?.userPermission.belongsToCustomer
      ) {
        getCustomer(user.userPermission.belongsToCustomer);
      }

      if (editedUserUserType === UserType.SuperAdmin) {
        const data: Partial<AdminAccountFieldsInput> = {
          email: user.userCredential.email,
          name: user.userInformation?.name,
          phoneNumber: user.userInformation?.phoneNumber,
          position: user.userInformation?.position,
        };

        reset({...data});
      }

      if (editedUserUserType === UserType.Admin) {
        const data: Partial<
          AdminAccountFieldsInput & AdminModulesFieldsInput
        > = {
          email: user.userCredential.email,
          name: user.userInformation?.name,
          phoneNumber: user.userInformation?.phoneNumber,
          position: user.userInformation?.position,
          accessModules: user.userPermission.accessModules.map(
            ({module}) => module
          ),
        };

        reset({...data});
      }

      if (isCustomerAccount(editedUserUserType)) {
        const data: Partial<CustomerAccountFieldsInput> = {
          email: user.userCredential.email,
          name: user.userInformation?.name,
          phoneNumber: user.userInformation?.phoneNumber,
          position: user.userInformation?.position,
        };

        reset({...data});
      }
    }
  }, [user, userLoading, userError, editedUserUserType, getCustomer, reset]);

  const showUserCompanyReferencesForm = (): boolean => {
    return isBackOfficeAccount(sessionUserType)
        || sessionUser?.userPermission?.belongsToCustomer === selectedCompany?.id;
  }

  useEffect(() => {
    if (company.loading) return
	setSelectedCompany(company.data)
  }, [company, setSelectedCompany]);

  useEffect(() => {
    setShowLoader(showLoader);
  }, [showLoader, setShowLoader]);

  useEffect(() => {
    getUsers({});
  }, [getUsers]);

  if (!user) {
    return (
      <div>
        <h1 data-testid="edit_user_headline">
          {t('navigation.General__nav.Manage users')}
        </h1>
      </div>
    );
  }

  return (
    <div>
      <h1 data-testid="edit_user_headline">
        {t('navigation.General__nav.Manage users')}
      </h1>
      <form>
        {isBackOfficeAccount(editedUserUserType) && (
            <AdminAccountForm handleInput={handleInput} readOnly isEdit={true}/>
        )}
        {isCustomerAccount(editedUserUserType) && (
          <CustomerAccountForm handleInput={handleInput} readOnly isEdit={true} />
        )}
        <AssociatedCompanyForm
            handleInput={handleInput}
            company={selectedCompany}
        />
        {showUserCompanyReferencesForm() &&
          <UserCompanyReferencesForm {...selectedCompany?.InternalCustomer}/>
        }

        {isAdminAccount(editedUserUserType) && (
          <>
            <AdminAccountPrivileges handleInput={handleInput} readOnly />
          </>
        )}
      </form>
    </div>
  );
}

export default connector(ViewUser);
