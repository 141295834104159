
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import cartService from '../../services/cart';

import { CartCheckout, CheckoutInformation, CheckoutResponse, CheckoutState, WayOfDeliveryResponse } from './types';
import { entityPending, entityFulfilled, entityRejected } from '../utils';
import { Option } from '../../components/Form/SelectField';

const name = 'checkout';

export const checkout = createAsyncThunk<CheckoutResponse, CartCheckout>(
    `eon-ui/checkout`,
    async function (cartCheckout: CartCheckout) {
        const { data } = await cartService.checkout(cartCheckout);
        return data as CheckoutResponse
    }
);
//Refactor in the future. Remove and bindngs to redux 
export const getCheckoutInformation = createAsyncThunk<CheckoutInformation>(
	`eon-ui/checkoutInformation`,
    async function () {
        const { data } = await cartService.getCheckoutInformation();
        return data as CheckoutInformation
    }
);

export const getWayOfDeliveryOptions = createAsyncThunk<Option[]>(
	`eon-ui/getWayOfDeliveryOptions`,
    async function () {
        const { data } = await cartService.getWayOfDelivery()
        return (data as WayOfDeliveryResponse).WayOfDeliveries.map(d => ({ label: d.Description, value: d.Code })) as Option[]
    }
)

export const destroyCart = createAsyncThunk(
	`eon-ui/destroyCart`,
    async function () {
        const { data } = await cartService.destroyCart()
        return (data)
    }
)


const initialState: CheckoutState = {
    selected: {
        data: null,
        loading: false,
        requestId: '',
    },
    response: {
        data: null,
        loading: false,
        requestId: '',
    },
	deliveryOptions: {
		data: null,
        loading: false,
        requestId: '',
    },
};


const checkoutListSlice = createSlice({
    name,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(checkout.pending, (state, action) => {
            entityPending(state.response, action);
        });
        builder.addCase(checkout.fulfilled, (state, action) => {
            entityFulfilled(state.response, action);
        });
        builder.addCase(checkout.rejected, (state, action) => {
            entityRejected(state.response, action);
        });

        builder.addCase(getCheckoutInformation.pending, (state, action) => {
            entityPending(state.selected, action);
        });
        builder.addCase(getCheckoutInformation.fulfilled, (state, action) => {
            entityFulfilled(state.selected, action);
        });
        builder.addCase(getCheckoutInformation.rejected, (state, action) => {
            entityRejected(state.selected, action);
        });

        builder.addCase(getWayOfDeliveryOptions.pending, (state, action) => {
            entityPending(state.deliveryOptions, action);
        });
        builder.addCase(getWayOfDeliveryOptions.fulfilled, (state, action) => {
            entityFulfilled(state.deliveryOptions, action);
        });
        builder.addCase(getWayOfDeliveryOptions.rejected, (state, action) => {
            entityRejected(state.deliveryOptions, action);
        });

    },
});

export default checkoutListSlice.reducer;