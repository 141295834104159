import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {FormContextValues} from "react-hook-form"
import {useHistory} from 'react-router-dom'
import {PATH_BANNERMESSAGE_BACK_OFFICE_LIST} from '../../../router/paths'
import InputField from '../../../components/Form/InputField'
import SelectField from '../../../components/Form/SelectField'
import {BannerMessage, BannerMessageType, CreateBannerMessageFormType} from "../../../store/bannermessages/types";
import {formatTimeMinuteHourFromDate} from "../../../lib/timeHelper";
import DateTimePicker from "../../../components/Form/DateTimePicker";

type EditManagementFormProps = {
    handler: FormContextValues<CreateBannerMessageFormType>;
    handleSubmit: (input: CreateBannerMessageFormType) => void;
    isEdit: boolean
    selectedMessage: BannerMessage | undefined
};

export function EditMessageForm({
    handler,
    isEdit,
    handleSubmit,
    selectedMessage,
}: EditManagementFormProps) {

    const history = useHistory()
    const { t } = useTranslation()
    const { reset } = handler;

    const formColumnClass = 'col-md-12 col-lg-12';

    const messageTypes = [
        {label: "1 - Röd", value: BannerMessageType.ERROR_MESSAGE},
        {label: "2 - Gul", value: BannerMessageType.WARNING_MESSAGE},
        {label: "3 - Blå", value: BannerMessageType.INFO_MESSAGE},
        {label: "4 - Grön", value: BannerMessageType.SUCCESS_MESSAGE},
    ]

    useEffect(() => {
        if (selectedMessage !== undefined) {
            reset({
                message: selectedMessage.message,
                messageType: messageTypes.find(msg => msg.value == selectedMessage.messageType),
                header: selectedMessage.header,
                startDateTime: new Date(selectedMessage.startDateTime),
                endDateTime: new Date(selectedMessage.endDateTime),
            } as CreateBannerMessageFormType)
        }
    }, [selectedMessage])

    const prepareSubmit = async (input: any) => {
        try {
            handleSubmit(input)
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <>
            <form onSubmit={handler.handleSubmit(prepareSubmit)}>
                <div className="row">
                    <div className="col-6">
                        <SelectField
                            name="messageType"
                            handler={handler}
                            label={"Meddelandefärg"}
                            placeholder={"Meddelandefärg"}
                            className={formColumnClass}
                            options={messageTypes}
                            rules={{required: true}}
                            disabled={true}
                        />
                    </div>
                    <div className="col-6">
                        <InputField name="header"
                                    handler={handler}
                                    label={"Rubrik"}
                                    placeholder={"Rubrik"}
                                    className={formColumnClass}
                        />
                    </div>
                    <div className="col-12">
                        <InputField
                            name="message"
                            handler={handler}
                            label={"Meddelande"}
                            placeholder={"Meddelande"}
                            className={formColumnClass}
                            rules={{ required: true }}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <DateTimePicker
                            name="startDateTime"
                            handler={handler}
                            label={"Start Datum & Tid"}
                            placeholder={formatTimeMinuteHourFromDate(selectedMessage?.startDateTime)}
                            className={formColumnClass}
                            dateFormat={"yyyy-MM-dd | HH:mm"}
                            rules={{required: true}}
                        ></DateTimePicker>
                    </div>
                    <div className="col-6">
                        <DateTimePicker
                            name="endDateTime"
                            handler={handler}
                            label={"Slut Datum & Tid"}
                            placeholder={formatTimeMinuteHourFromDate(selectedMessage?.endDateTime)}
                            className={formColumnClass}
                            dateFormat={"yyyy-MM-dd | HH:mm"}
                            rules={{required: true}}
                        ></DateTimePicker>
                    </div>
                </div>
                {isEdit ? <button
                    type="submit"
                    className="btn btn-primary mr-2"
                    data-testid="submit__project_form"
                > {t('common.Save')}
                </button> : ""} 

                {!isEdit ? <button
                    type="submit"
                    className="btn btn-primary mr-2"
                    data-testid="submit__project_form"
                > {t('common.Create')}
                </button> : ""} 
             
                <button type="button" className="btn btn-info" onClick={() => history.push(PATH_BANNERMESSAGE_BACK_OFFICE_LIST)} >
                    {t('common.Cancel')}
                </button>
            </form>
        </>
    );
}