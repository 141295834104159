import React, {useMemo, useState, useEffect, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';

import {News, Position} from '../../store/news/types';

import {useCroppie} from '../../lib/hooks';

import InputField from '../../components/Form/InputField';
import SelectField, {Option} from '../../components/Form/SelectField';
import TextAreaField from '../../components/Form/TextAreaField';
import {InlineRadio} from '../../components/Form/InlineCheckInput';
import ToggleField from '../../components/Form/ToggleField';
import DatePicker from '../../components/Form/DatePicker';
import ImageCrop from '../../components/ImageCrop';
import TextSampleBox from '../../components/News/TextSampleBox';
import PreviewModal from '../../components/News/PreviewModal';
import {ListData} from "../../store/types";
import {ProductCategory} from "../../store/productCategories/types";
import {useHistory} from "react-router-dom";
import {PATH_NEWS_MANAGE_NEWS} from '../../router/paths';

export type NewsFormInput = {
  name: string;
  numberOf: string | number;
  unit: Option;
  publicationDate: Date;
  position: Position;
  headline: string;
  headlineSize: Option;
  text: string;
  visibleOnHomepage: boolean;
  data: string;
  category: Option;
};

type NewsFormProps = {
  news?: News | null;
  categories: ListData<ProductCategory>
  onSubmit: (input: NewsFormInput, data: Blob) => Promise<any>;
  setShowLoader: (set: boolean) => void;
};

const extraMargin: React.CSSProperties = {marginTop: '15px'};
const removeExtraMargin: React.CSSProperties = {marginTop: '-15px'};
const formColumnClass = 'col-md-12 col-lg-6';

const headlineSizeOptions = [
  {label: '42px', value: '42'},
  {label: '46px', value: '46'},
  {label: '50px', value: '50'},
];

export function NewsFrom({news, onSubmit, setShowLoader, categories}: NewsFormProps) {
  const {t} = useTranslation();
  const history = useHistory();

  const [categoryList, setCategoryList] = useState<Option[] | []>([]);

  const {
    croppie,
    sourcePicture,
    sourcePictureField,
    setSourcePicture,
  } = useCroppie();

  const [previewModalVisible, setPreviewModalVisible] = useState(false);
  const [previewDetails, setPreviewDetails] = useState<News | null>(null);
  const [imageSelected, setImageSelected] = useState<boolean>(false);
  const [validationTrigger, setValidationTrigger] = useState<boolean>(false);


  // keeping here so we have translation
  const positionOptions = useMemo(
    () => [
      {
        label: t('common.Justify left'),
        value: 'left',
        extraComponent: <TextSampleBox textAlign="left" />,
      },
      {
        label: t('common.Centered'),
        value: 'center',
        extraComponent: <TextSampleBox textAlign="center" />,
      },
      {
        label: t('common.Justify right'),
        value: 'right',
        extraComponent: <TextSampleBox textAlign="right" />,
      },
      {
        label: t('common.Full size'),
        value: 'justify',
        extraComponent: <TextSampleBox textAlign="justify" />,
      },
    ],
    [t]
  );

  // keeping here so we have translation
  const unitOptions = useMemo(
    () => [
      {label: t('common.Days'), value: 'Days'},
      {label: t('common.Weeks'), value: 'Weeks'},
      {label: t('common.Months'), value: 'Months'},
    ],
    [t]
  );

  const categoryOptions = useMemo(
      () => categoryList,
      [categoryList]
  );

  const handleInput = useForm<NewsFormInput>({
    mode: 'onChange',
    defaultValues: {
      publicationDate: new Date(),
      position: 'left',
      headlineSize: headlineSizeOptions[0],
      unit: unitOptions[0],
      category: {label: '', value: ''},
    },
  });

  const {
    formState: {isSubmitting,isSubmitted},
    reset,
  } = handleInput;

  useEffect(() => {
    setShowLoader(isSubmitting);
  }, [isSubmitting, setShowLoader]);

  useEffect(() => {
    const {data} = categories

    if(data.length){
      let categoryOptionsList: Option[] = [];
      data.forEach((category: any) => {
        categoryOptionsList.push({label: category.categoryName, value: category.id})
      })
      setCategoryList(categoryOptionsList)
    }
  }, [categories]);

  useEffect(() => {
    if (news && categoryOptions) {
      const selectedUnit = unitOptions.find(({value}) => value === news.unit);
      const selectedCategory = categoryOptions.find(({value}) => value === news.linkTo);
      const selectedHeadlineSize = headlineSizeOptions.find(
        ({value}) => value === `${news.headlineSize}`
      );

      const final: Partial<NewsFormInput> = {
        ...news,
        publicationDate: new Date(news.publicationDate),
        unit: selectedUnit,
        headlineSize: selectedHeadlineSize,
        numberOf: news.numberOf,
        headline: news.headline ?? '',
        text: news.text ?? '',
        category:selectedCategory
      };

      reset(final);

      news.url && setSourcePicture(news.url);
    }
  }, [news, unitOptions, setSourcePicture, reset, categoryOptions]);

  useEffect(() => {
    if (!previewModalVisible) {
      setPreviewDetails(null);
    }
  }, [previewModalVisible]);

  const setCroppieInstance = (inst: Croppie) => {
    croppie.current = inst;
  };

  const openFilePicker = () => {
    sourcePictureField.current?.click();
  };

  useEffect(() => {
    if(validationTrigger) {
      handleInput.triggerValidation()
    }
  }, [validationTrigger])

  const onPreview = () => {
    if (!croppie.current || !sourcePicture) {
      setValidationTrigger(true)
      setImageSelected(false)
      return;
    }
    setImageSelected(true)
    setPreviewModalVisible(true);

    const text = handleInput.getValues('text');
    const headline = handleInput.getValues('headline');
    const headlineSize = handleInput.getValues('headlineSize');
    const position = handleInput.getValues('position');

    croppie.current
      .result({
        size: 'original',
        type: 'base64',
        format: 'jpeg',
        quality: 0.9,
      })
      .then((result) =>
        setPreviewDetails({
          name: 'Preview',
          visibleOnHomepage: false,
          numberOf: 0,
          publicationDate: new Date() + '',
          unit: 'days',
          id: `${Date.now()}`,
          url: result,
          text: (text ?? '').trim(),
          headline,
          headlineSize: +headlineSize?.value,
          position,
        })
      )
      .catch((e) => console.error('failed', e));
  };

  const prepareSubmit = useCallback(
    async (input: any) => {
      if (!croppie.current || !sourcePicture) {
        setImageSelected(false)
        return;
      }

      setImageSelected(true)

      try {
        // todo:
        // if we editing and the image didn't change
        // skip this part
        const blob = await croppie.current.result({
          size: 'original',
          type: 'blob',
          format: 'jpeg',
          quality: 0.9,
        });

        const response = await onSubmit(input, blob);
        const {type, status} = response;

        if( status === 200 || type?.includes('fulfilled')) {
          history.push(PATH_NEWS_MANAGE_NEWS)
        }


        // only reset the form here when we don't have `news` object
        // otherwise we would overwrite the updated version
        if (!news) {
          reset();
          setSourcePicture(null);
        }
      } catch (e) {
        console.error(e);
      }
    },
    [news, sourcePicture, croppie, setSourcePicture, reset, onSubmit]
  );

  const onPublish = useCallback(() => {
    setPreviewModalVisible(false);
    handleInput.handleSubmit(prepareSubmit)();
  }, [handleInput, prepareSubmit]);

  //const category = handleInput.watch('category');
  //const subCategory = handleInput.watch('subcategory');
  
  return (
    <>
      <PreviewModal
        carouselItem={previewDetails}
        modalVisible={previewModalVisible}
        onPublish={onPublish}
        setModalVisible={setPreviewModalVisible}
      />
      <form onSubmit={handleInput.handleSubmit(prepareSubmit)}>
        <legend>{t('common.Information')}</legend>

        <div className="form-row">
          <InputField
            name="name"
            handler={handleInput}
            label={t('common.Name')}
            className={formColumnClass}
            rules={{required: true, minLength: 3}}
          />
          <DatePicker
            name="publicationDate"
            label={t('common.Publication date')}
            placeholder={t('common.Date')}
            className={formColumnClass}
            rules={{required: true}}
            handler={handleInput}
          />
          <InputField
            name="numberOf"
            type={'number'}
            handler={handleInput}
            label={t('common.Validity')}
            placeholder={t('common.Number')}
            className="col-md-3 col-lg-2"
            rules={{required: true}}
          />
          <SelectField
            name="unit"
            handler={handleInput}
            label=" "
            className="col-md-9 col-lg-4"
            placeholder={t('common.Unit')}
            rules={{required: true}}
            options={unitOptions}
          />
          <ToggleField
            switchText={[t('common.No'), t('common.Yes')]}
            name="visibleOnHomepage"
            handler={handleInput}
            label={t('common.Publish to the front page')}
            className={formColumnClass}
          />
        </div>

        <hr className="form-delimiter" />
        <legend>{t('common.Details')}</legend>

        <InlineRadio
          name="position"
          handler={handleInput}
          label={t('common.Layout')}
          className="col-md-6 col-lg-3 mx-0"
          options={positionOptions}
        />

        <div className="row">
          <div className="col-md-12 col-lg-6">
            <div className="form-row">
              <InputField
                name="headline"
                handler={handleInput}
                label={t('common.Headline')}
                className={'col-md-3 col-lg-8'}
              />
              <SelectField
                name="headlineSize"
                handler={handleInput}
                className={'col-md-9 col-lg-4'}
                label={t('common.Size')}
                rules={{required: true}}
                options={headlineSizeOptions}
              />
			  <SelectField
                name="category"
                handler={handleInput}
                label={t('common.Link to')}
                placeholder={t('common.Category')}
                className="col-4"
                options={categoryOptions}
              />
              <TextAreaField
                name="text"
                handler={handleInput}
                label={t('common.Text')}
                className="col-12"
                rows={20}
              />
            </div>
          </div>
          <div className="col-md-12 col-lg-6">
            <div className="form-row full-height">
              <div className="form-group col-12 d-flex flex-column">
                <label>{t('common.Picture')}</label>
                <ImageCrop
                  setCroppieInstance={setCroppieInstance}
                  sourcePicture={sourcePicture}
                  required={isSubmitted && !imageSelected || validationTrigger && !imageSelected}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row" style={extraMargin}>
          <div className="col-md-12 col-lg-6" style={removeExtraMargin}></div>
          <div className="col-md-12 col-lg-6 d-flex justify-content-end align-items-end">
            <input
              type="file"
              name="sourcePicture"
              accept=".jpg,.jpeg,.png"
              ref={(ref) => (sourcePictureField.current = ref)}
              onChange={(e) => {
                if (e.currentTarget.files?.length) {
                  setSourcePicture(e.currentTarget.files[0]);
                } else {
                  setSourcePicture(null);
                }
              }}
              className="invisible"
            />
            <button
              type="button"
              className="btn btn-info mb-4"
              onClick={openFilePicker}
            >
              {t('common.Upload picture')}
            </button>
          </div>
        </div>

        <hr className="form-delimiter" />

        <button type="button" className="btn btn-info mr-2" onClick={onPreview}>
          {t('common.Preview')}
        </button>
        <button
          type="submit"
          className="btn btn-primary "
          data-testid="submit__company_form"
        >
          {t('common.Publish')}
        </button>
      </form>
    </>
  );
}
