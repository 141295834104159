import {createSlice, createAsyncThunk, DeepPartial} from '@reduxjs/toolkit';

import {ListMeta} from '../../services/types';
import newsService from '../../services/news';

import {
  listFulfilled,
  listPending,
  listRejected,
  entityFulfilled,
  entityPending,
  entityRejected,
} from '../utils';
import {ListData} from '../types';

import {News, NewsState, NewsInput} from './types';

const name = 'news';

const mapBackendToFrontEnd = (n: any): News => ({
  id: n.id,
  name: n.name,
  numberOf: n.numberOf,
  position: n.position,
  publicationDate: n.publicationDate,
  unit: n.unit,
  url: n.urls[0]?.replace(/:8282/, ':3000'),
  visibleOnHomepage: n.visibleOnHomepage,
  isActive: n.isActive,
  headline: n.headline,
  headlineSize: +n.headlineSize,
  linkTo: n.linkTo,
  text: n.text,
});

export const getNewsList = createAsyncThunk<ListData<News>, ListMeta>(
  `eon-ui/getNewsList`,
  async function (meta) {
    const {data, headers} = await newsService.getNewsList(meta);
    return {
      data: (data as any[]).map(mapBackendToFrontEnd),
      total: +headers['x-total-count'],
    };
  }
);

export const getNews = createAsyncThunk<News, string>(
  `eon-ui/getNews`,
  async function (id) {
    const {data} = await newsService.getNews(id);

    return mapBackendToFrontEnd(data);
  }
);

export const updateNews = createAsyncThunk<
  News,
  {newsId: string; payload: DeepPartial<NewsInput>}
>(`eon-ui/updateNews`, async function ({newsId, payload}) {
  const {data} = await newsService.updateNewsById(newsId, payload);

  return mapBackendToFrontEnd(data);
});

export const createNews = createAsyncThunk<
  News,
  NewsInput
>(`eon-ui/createNews`, async function (payload) {
  const {data} = await newsService.createNews(payload);

  return mapBackendToFrontEnd(data);
});

export const deleteNews = createAsyncThunk<News, string>(
    `eon-ui/deleteNews`,
    async function (newsId) {
      const {data} = await newsService.deleteNews(newsId);
      return data as News;
    }
);

export const initialState: NewsState = {
  list: {
    data: [],
    loading: false,
    requestId: '',
    total: 0,
  },
  selected: {
    data: null,
    loading: false,
    requestId: '',
  },
};

const newsSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getNewsList.pending, (state, action) => {
      listPending(state.list, action);
    });
    builder.addCase(getNewsList.fulfilled, (state, action) => {
      listFulfilled(state.list, action);
    });
    builder.addCase(getNewsList.rejected, (state, action) => {
      listRejected(state.list, action);
    });

    builder.addCase(getNews.pending, (state, action) => {
      entityPending(state.selected, action);
    });
    builder.addCase(getNews.fulfilled, (state, action) => {
      entityFulfilled(state.selected, action);
    });
    builder.addCase(getNews.rejected, (state, action) => {
      entityRejected(state.selected, action);
    });

    builder.addCase(updateNews.pending, (state, action) => {
      entityPending(state.selected, action);
    });
    builder.addCase(updateNews.fulfilled, (state, action) => {
      entityFulfilled(state.selected, action);
    });
    builder.addCase(updateNews.rejected, (state, action) => {
      entityRejected(state.selected, action);
    });

    builder.addCase(createNews.pending, (state, action) => {
      entityPending(state.selected, action);
    });
    builder.addCase(createNews.fulfilled, (state, action) => {
      entityFulfilled(state.selected, action);
    });
    builder.addCase(createNews.rejected, (state, action) => {
      entityRejected(state.selected, action);
    });

    builder.addCase(deleteNews.pending, (state, action) => {
      entityPending(state.selected, action);
    });
    builder.addCase(deleteNews.fulfilled, (state, action) => {
      entityFulfilled(state.selected, action);
    });
    builder.addCase(deleteNews.rejected, (state, action) => {
      entityRejected(state.selected, action);
    });
  },
});

export default newsSlice.reducer;
