import axios, {Canceler} from 'axios';
import {generatePath} from 'react-router-dom';
import axiosInstance from '../lib/request';
import {FileCategory} from '../types';
import {ENDPOINTS} from '../endpoints';

export async function uploadFile(
  {
    endpoint,
    data,
    params,
  }: {
    endpoint: string;
    data?: {[k: string]: any};
    params?: {[k: string]: any};
  },
  file: File,
  onUploadProgress: (e: any) => void,
  cancel: (cancelFn: Canceler) => void
) {
  const formData = new FormData();
  const blob = new Blob([JSON.stringify(data)], {type: 'application/json'});

  formData.append('data', blob);
  formData.append('file', file);

  return await axiosInstance.post(generatePath(endpoint, params), formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress,
    cancelToken: new axios.CancelToken((cFn) => {
      cancel(cFn);
    }),
  });
}

export async function uploadFiles(
  {
    endpoint,
    data,
    params,
    responseType,
  }: {
    endpoint: string;
    data?: {[k: string]: any};
    params?: {[k: string]: any};
    responseType?: "arraybuffer" | "blob" | "document" | "json" | "text" | "stream"
  },
  file: File[],
) {
  const formData = new FormData();
  const blob = new Blob([JSON.stringify(data)], {type: 'application/json'});

  file.forEach(f =>{
    formData.append("file", f);
  });
  formData.append('data', blob);
  
  return await axiosInstance.post(generatePath(endpoint, params), formData, {
    headers: {'Content-Type': 'multipart/form-data', },
    responseType, 
  });
}

export async function uploadImg(
  {
    endpoint,
    data,
    params,
		responseType,
  }: {
    endpoint: string;
    data?: {[k: string]: any};
    params?: {[k: string]: any};
		responseType?: "arraybuffer" | "blob" | "document" | "json" | "text" | "stream"
  },
  file: File | undefined
) {
  const formData = new FormData();
  const blob = new Blob([JSON.stringify(data)], {type: 'application/json'});

  formData.append('data', blob);
  file && formData.append('image', file);

  return await axiosInstance.post(generatePath(endpoint, params), formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
		responseType,
  });
}

const methods = {
  uploadCompanyDocuments(companyId: string) {
    return uploadFile.bind(null, {
      endpoint: ENDPOINTS.ACCOUNT_MANAGEMENT_CUSTOMER_UPLOAD_FILE,
      params: {companyId},
      data: {fileCategory: FileCategory.DOCUMENT},
    });
  },

  uploadDocumentsFile(folderPath: string) {
    return uploadFile.bind(null, {
      endpoint: ENDPOINTS.DOCUMENTS_FILES_CREATE,
      data: {folderPath: folderPath},
    });
  },

  uploadFiles(folderPath: string) {
    return uploadFiles.bind(null, {
      endpoint: ENDPOINTS.DOCUMENTS_FILES_CREATE,
      data: {folderPath: folderPath},
    });
  },

  uploadCompanyResellerAgreement(companyId: string) {
    return uploadFile.bind(null, {
      endpoint: ENDPOINTS.ACCOUNT_MANAGEMENT_CUSTOMER_UPLOAD_AGREEMENT_FILE,
      params: {companyId},
      data: {fileCategory: FileCategory.RESALE_TERMS},
    });
  },
};

export default methods
