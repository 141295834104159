import { CircularProgress } from "@material-ui/core";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import useUsers from "../../hooks/useUsers";
import { User } from "../../store/users/types";
import InputField from "../Form/InputField";
import Modal from '../Modal';

export default function OrderUserPickerModal({
    modalVisible,
    setModalVisible,
    setUser,
  }: {
    modalVisible: boolean;
    setModalVisible: (f: boolean) => void;
    setUser: (f: User) => void;
    itemId?: string;
    loader: boolean;
  }) {
    const {t} = useTranslation();
    const {getUsers, users} = useUsers();

    useEffect(() =>{
        if(users !== undefined) return;

        getUsers({page: 1,filter: "", textSearch: "", onlyAdmins: 0})
    },[users])

    const [searchUser, setSearchUser] = useState<string>("")
    const [User, setSelectedUser] = useState<User>();

    useEffect(() => {
        setSearchUser("")
        setSelectedUser(undefined)
      },[])

    const onSearchChange = (e:any)=> {
        setSearchUser(e.target.value);
      }

      const setValues =(user: User) => {
        setUser(user)
      }

    const filteredItems = useMemo(() => {
        if (!users) return [];

        return users?.filter(user => (
            !searchUser
            || user.userCredential?.email?.toLowerCase().includes(searchUser.toLowerCase())
            || user.userPermission?.userType?.toLowerCase().includes(searchUser.toLowerCase())
            || user.userInformation?.position?.toLowerCase().includes(searchUser.toLocaleLowerCase())
            || user.userInformation?.phoneNumber?.toLowerCase().includes(searchUser.toLocaleLowerCase())
            || user.userPermission?.resolvedCustomerName?.toLocaleLowerCase().includes(searchUser.toLocaleLowerCase())
            || user.userInformation?.name?.toLocaleLowerCase().includes(searchUser.toLocaleLowerCase())
        ))
    }, [users, searchUser])

    return (
        <Modal
        name="UserPickerModal"
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        title="Sök användare"
        dialogClassName="modal-mw-small modal-dialog-centered"
        >
            {!User?.id && (
            <>
                <InputField
                    name="searchField"
                    placeholder="Sök användare"
                    onChange={onSearchChange}
                    className="mb-4"
                    value={searchUser}
                />
            
                <div className="card">
                    <ul className="list-group list-group-flush">
                    {searchUser.length > 2 && filteredItems?.map((user, index) => {
                        return(
                            <span className="list-group-item" 
                                key={index} 
                                onClick={() => {setValues(user); setModalVisible(false)}}
                                style={{cursor: "pointer", alignContent: "middle"}}
                                >
                                <p className="btn-link" >
                                    <span className="font-weight-bold">
                                        {user.userInformation?.name} ({user.userCredential?.email})
                                        <br />
                                        {user?.userPermission?.resolvedCustomerName}
                                    </span>
                                </p>
                            </span>
                        )
                        })}

                    </ul>
                </div>
                </>
            )}
            <div className="mt-7">
                <button
                type="button"
                className="btn btn-info mr-2 float-left"
                data-dismiss="modal"
                >
                    {t('common.Cancel')}
                </button>
            </div>
        </Modal>
    );
}

